//TODO: make a native app friendly version
import React from 'react';
import { KNOWButton as Button, Modal, Text, View } from '@know/ui';

interface SaveDraftModalProps {
  isOpen: boolean;
  cancel?: () => void;
  saveDraft?: () => void;
  deleteDraft?: () => void | Promise<void>;
}

export const SaveDraftModal = ({
  isOpen,
  cancel,
  saveDraft,
  deleteDraft,
}: SaveDraftModalProps) => {
  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={cancel} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
          Quit Editing?
        </Modal.Header>
        <Modal.Body py={0}>
          <View width="100%">
            <Text fontSize={'16px'} color={'gray.700'}>
              This form will not be submitted.
            </Text>
          </View>
        </Modal.Body>
        <Modal.Footer
          borderTopWidth={0}
          display={'flex'}
          flexDirection={'column'}
        >
          <Button
            colorScheme="primary"
            onPress={saveDraft}
            w={'100%'}
            mb={'10px'}
          >
            Save as Draft & Exit
          </Button>
          <Button
            colorScheme="secondary"
            onPress={cancel}
            w={'100%'}
            mb={'10px'}
          >
            Keep Editing
          </Button>
          <Button colorScheme="danger" onPress={deleteDraft} w={'100%'}>
            Exit Without Saving
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
