import React from 'react';
import { Avatar, HStack, IconButton, TimesIcon, Text } from '..';

interface IChipProps {
  label: string;
  avatar?: string;
  avatarString?: string;
  onRemove?: () => void;
}

export function Chip({ label, avatar, avatarString, onRemove }: IChipProps) {
  return (
    <HStack
      mb={2}
      pl={'6px'}
      pr={'4px'}
      py={'0px'}
      borderRadius={'md'}
      borderColor={'gray.300'}
      space={2}
      borderWidth={1}
      alignItems={'center'}
      mr={2}
    >
      <Avatar bg={'primary.800'} source={{ uri: avatar }} size={'xs'}>
        {avatarString}
      </Avatar>
      <Text color={'gray.900'} fontSize={'sm'} fontFamily={'OpenSans'}>
        {label}
      </Text>
      <IconButton size={'sm'} onPress={onRemove} icon={<TimesIcon />} />
    </HStack>
  );
}
