import { useMemo } from 'react';
import type { IShareContact } from '../ShareDialogue/types';

export function useContact(user: IShareContact) {
  const name = useMemo(() => {
    if (user.alias) {
      return user.alias;
    }
    return [user.firstName, user.lastName].filter(Boolean).join(' ');
  }, [user.alias, user.firstName, user.lastName]);

  const designationAndLocation = useMemo(() => {
    return [user.designation, user.location]
      .map((a) => a.trim())
      .filter(Boolean)
      .join(', ');
  }, [user.designation, user.location]);

  const avatarText = useMemo(() => {
    return `${user.firstName.split('').splice(0, 1)[0]}${
      user.lastName.split('').splice(0, 1)[0]
    }`;
  }, [user.firstName, user.lastName]);

  return {
    id: user.id,
    name,
    avatarText,
    avatar: user.avatar,
    designationAndLocation,
    department: user.department,
  };
}
