import React from 'react';
import { createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const CameraIcon = createIcon({
  viewBox: '0 0 32 32',
  path: [
    <Path
      d="M16.0001 20.2667C18.3565 20.2667 20.2667 18.3565 20.2667 16.0001C20.2667 13.6436 18.3565 11.7334 16.0001 11.7334C13.6436 11.7334 11.7334 13.6436 11.7334 16.0001C11.7334 18.3565 13.6436 20.2667 16.0001 20.2667Z"
      fill="#00C1B2"
    />,
    <Path
      d="M11.9998 2.6665L9.55984 5.33317H5.33317C3.8665 5.33317 2.6665 6.53317 2.6665 7.99984V23.9998C2.6665 25.4665 3.8665 26.6665 5.33317 26.6665H26.6665C28.1332 26.6665 29.3332 25.4665 29.3332 23.9998V7.99984C29.3332 6.53317 28.1332 5.33317 26.6665 5.33317H22.4398L19.9998 2.6665H11.9998ZM15.9998 22.6665C12.3198 22.6665 9.33317 19.6798 9.33317 15.9998C9.33317 12.3198 12.3198 9.33317 15.9998 9.33317C19.6798 9.33317 22.6665 12.3198 22.6665 15.9998C22.6665 19.6798 19.6798 22.6665 15.9998 22.6665Z"
      fill="#00C1B2"
    />,
  ],
});
