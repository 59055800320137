import React from 'react';
import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

export const UnSortedIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 14 15">
    <Path
      opacity="0.3"
      d="M3.81881 8.60071H11.0551C11.7277 8.60071 12.064 9.41555 11.5891 9.88835L7.9715 13.629C7.82357 13.7768 7.63033 13.8507 7.43748 13.8507C7.22146 13.8507 7.05193 13.7769 6.90756 13.6298L3.28451 9.88915C2.8101 9.41473 3.14615 8.60071 3.81881 8.60071Z"
      fill="#4E5656"
    />
    <Path
      opacity="0.3"
      d="M3.81883 6.85071H11.0567C11.7294 6.85071 12.0654 6.03642 11.591 5.56228L7.97344 1.82165C7.90343 1.75133 7.82021 1.69555 7.72856 1.6575C7.63691 1.61946 7.53865 1.59991 7.43942 1.59998C7.24651 1.59998 7.05414 1.67383 6.90758 1.82165L3.28451 5.56282C2.81015 6.03587 3.14604 6.85071 3.81883 6.85071Z"
      fill="#4E5656"
    />
  </Icon>
);

export const SortedAscIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 15 15">
    <Path
      opacity="0.3"
      d="M4.56881 8.60071H11.8051C12.4777 8.60071 12.814 9.41555 12.3391 9.88835L8.7215 13.629C8.57357 13.7768 8.38033 13.8507 8.18748 13.8507C7.97146 13.8507 7.80193 13.7769 7.65756 13.6298L4.03451 9.88915C3.5601 9.41473 3.89615 8.60071 4.56881 8.60071Z"
      fill="#4E5656"
    />
    <Path
      d="M4.56883 6.85071H11.8067C12.4794 6.85071 12.8154 6.03642 12.341 5.56228L8.72344 1.82165C8.65343 1.75133 8.57021 1.69555 8.47856 1.6575C8.38691 1.61946 8.28865 1.59991 8.18942 1.59998C7.99651 1.59998 7.80414 1.67383 7.65758 1.82165L4.03451 5.56282C3.56015 6.03587 3.89604 6.85071 4.56883 6.85071Z"
      fill="#4E5656"
    />
  </Icon>
);

export const SortedDescIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 15 15">
    <Path
      d="M4.56881 8.60071H11.8051C12.4777 8.60071 12.814 9.41555 12.3391 9.88835L8.7215 13.629C8.57357 13.7768 8.38033 13.8507 8.18748 13.8507C7.97146 13.8507 7.80193 13.7769 7.65756 13.6298L4.03451 9.88915C3.5601 9.41473 3.89615 8.60071 4.56881 8.60071Z"
      fill="#4E5656"
    />
    <Path
      opacity="0.3"
      d="M4.56883 6.85071H11.8067C12.4794 6.85071 12.8154 6.03642 12.341 5.56228L8.72344 1.82165C8.65343 1.75133 8.57021 1.69555 8.47856 1.6575C8.38691 1.61946 8.28865 1.59991 8.18942 1.59998C7.99651 1.59998 7.80414 1.67383 7.65758 1.82165L4.03451 5.56282C3.56015 6.03587 3.89604 6.85071 4.56883 6.85071Z"
      fill="#4E5656"
    />
  </Icon>
);
