//TODO: make a native app friendly version
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Modal,
  Pressable,
  Text,
  View,
  ErrorAlert,
  useIsMobile,
  useModalParam,
  ExpoMAIcon,
  ExpoFAIcon,
  useToast,
} from '@know/ui';
import { TakeVideoModal } from './TakeVideoModal';
import { checkForFileValidity } from './utils';

export interface UploadVideoModalRef {
  openCamera: () => any;
  openUpload: () => any;
}
interface UploadVideoModalProps {
  isOpen: boolean;
  close: () => void;
  limit?: number;
  restrictImagePicker?: boolean;
  sizeLimit?: number;
  onSubmit?: (response: File[]) => void;
  showAlertToast?: boolean;
  hideUploadButtonInRecordVideoModal?: boolean;
}

const UploadPressable: React.FC<any> = ({ children, ...props }) => (
  <Pressable
    background="gray.200"
    borderRadius="12px"
    flex={1}
    display={'flex'}
    flexDirection="column"
    justifyContent={'center'}
    alignItems={'center'}
    padding={'20px 12px'}
    {...props}
  >
    {children}
  </Pressable>
);

export const UploadVideoModal = forwardRef<
  UploadVideoModalRef,
  UploadVideoModalProps
>(
  (
    {
      isOpen,
      close,
      onSubmit,
      limit = 1,
      restrictImagePicker = false,
      sizeLimit = 25 * 1024 * 1024,
      showAlertToast,
      hideUploadButtonInRecordVideoModal,
    },
    ref
  ) => {
    const uploadFileInput = useRef<HTMLInputElement | null>(null);
    const cameraInput = useRef<HTMLInputElement | null>(null);
    const [isShowLimitAlert, setIsShowLimitAlert] = useState(false);
    const [isShowSizeAlert, setIsShowSizeAlert] = useState(false);
    const [isShowTypeAlert, setIsShowTypeAlert] = useState(false);
    const [
      isTakeVideoModalOpen,
      takeVideoModalParam,
      openTakeVideoModal,
      closeTakeVideoModal,
    ] = useModalParam<{ restrictImagePicker: boolean }>();

    const isMobile = useIsMobile();

    const resetAlerts = useCallback(() => {
      setIsShowLimitAlert(false);
      setIsShowSizeAlert(false);
      setIsShowTypeAlert(false);
    }, []);

    const toast = useToast();

    const onError = (type: 'size' | 'number' | 'type') => {
      const toastId = Date.now();
      const header =
        type === 'size'
          ? 'Video(s) exceeds size limit'
          : type === 'type'
          ? 'Invalid file type'
          : 'Too many videos uploaded';
      const subText =
        type === 'size'
          ? `Max file size is ${
              sizeLimit / 1024 ** 2
            } MB. Please choose a smaller video.`
          : type === 'type'
          ? 'Only videos are allowed. Please select valid video files to upload.'
          : `You can only upload up to ${limit} videos. Please select fewer videos and try again.`;
      toast.show({
        id: toastId,
        render: () => (
          <ErrorAlert
            header={header}
            subText={subText}
            onClose={() => toast.close(toastId)}
            containerProps={{ mt: '-30px', w: 'calc(100vw - 24px)' }}
          />
        ),
        placement: 'top',
        duration: null,
      });
    };

    const onClose = useCallback(() => {
      resetAlerts();
      close();
    }, [close, resetAlerts]);

    const onUploadInputChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      resetAlerts();
      const _files = e.target.files;
      if (_files) {
        const files = Array.from(e.target.files ?? []);
        if (files.length > limit) {
          if (showAlertToast) {
            return onError('number');
          } else {
            return setIsShowLimitAlert(true);
          }
        }
        try {
          checkForFileValidity(files, ['video/*'], sizeLimit);
          onSubmit && onSubmit(files);
          onClose();
        } catch (error: any) {
          if (error.message === 'FileTooBig') {
            if (showAlertToast) {
              onError('size');
            } else {
              setIsShowSizeAlert(true);
            }
          } else if (error.message === 'InvalidType') {
            if (showAlertToast) {
              onError('type');
            } else {
              setIsShowTypeAlert(true);
            }
          }
        }
      }
    };

    const onClickTakeVideoPressable = useCallback(() => {
      if (isMobile) {
        cameraInput.current?.click();
      } else {
        openTakeVideoModal({ restrictImagePicker });
      }
    }, [isMobile, openTakeVideoModal, restrictImagePicker]);

    const onClickUploadPressable = useCallback(() => {
      uploadFileInput.current?.click();
    }, []);

    const onUploadPhotoFromCamera = useCallback(
      (response: File) => {
        onSubmit && onSubmit([response]);
        onClose();
      },
      [onSubmit, onClose]
    );

    // useEffect(() => {
    //   if (isOpen && restrictImagePicker) {
    //     onClickTakeVideoPressable();
    //   }
    // }, [isOpen, onClickTakeVideoPressable, restrictImagePicker]);

    useImperativeHandle(ref, () => {
      return {
        openCamera() {
          onClickTakeVideoPressable();
        },
        openUpload() {
          onClickUploadPressable();
        },
      };
    });

    return (
      <>
        <Modal isOpen={isOpen} closeOnOverlayClick onClose={onClose} size="xl">
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header pb={0} borderBottomWidth={0}>
              Upload Video
            </Modal.Header>
            <Modal.Body display={'flex'} flexDirection={'column'}>
              <View display={'flex'} flexDirection={'row'} w={'100%'}>
                <UploadPressable onPress={onClickTakeVideoPressable}>
                  <ExpoFAIcon
                    name="video-camera"
                    color={'primary.500'}
                    mr={'6px'}
                    size={'32px'}
                    mt={'2px'}
                  />
                  <Text textAlign={'center'} fontWeight={600}>
                    Record Video
                  </Text>
                </UploadPressable>
                {restrictImagePicker ? null : (
                  <UploadPressable ml="16px" onPress={onClickUploadPressable}>
                    <ExpoMAIcon
                      name="video-collection"
                      color={'primary.500'}
                      mr={'6px'}
                      size={'32px'}
                      mt={'2px'}
                    />
                    <Text textAlign={'center'} fontWeight={600}>
                      Upload Video from Library
                    </Text>
                  </UploadPressable>
                )}
              </View>
              {isShowLimitAlert ? (
                <ErrorAlert
                  header="Too many videos uploaded"
                  subText={`You can only upload up to ${limit} videos. Please select fewer videos and try again.`}
                />
              ) : null}
              {isShowSizeAlert ? (
                <ErrorAlert
                  header="Video exceeds size limit"
                  subText={`Max file size is ${
                    sizeLimit / 1024 ** 2
                  } MB. Please choose a smaller video.`}
                />
              ) : null}
              {isShowTypeAlert ? (
                <ErrorAlert
                  header="Invalid file type"
                  subText={
                    'Only video files are allowed. Please select a valid video file to upload.'
                  }
                />
              ) : null}
            </Modal.Body>
          </Modal.Content>
          <input
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ display: 'none' }}
            ref={uploadFileInput}
            onChange={onUploadInputChange}
            accept="video/*"
            multiple={limit > 1}
            type="file"
          />
          <input
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ display: 'none' }}
            ref={cameraInput}
            onChange={onUploadInputChange}
            accept="video/*"
            type="file"
            capture="environment"
          />
        </Modal>
        {isTakeVideoModalOpen ? (
          <TakeVideoModal
            isOpen={isTakeVideoModalOpen}
            close={closeTakeVideoModal}
            onClickUpload={onClickUploadPressable}
            onSubmit={onUploadPhotoFromCamera}
            hideUploadButton={hideUploadButtonInRecordVideoModal}
            {...takeVideoModalParam}
          />
        ) : null}
      </>
    );
  }
);
