import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  WithKNOWLoader,
  convertToSeconds,
} from '@know/ui';
import YouTube from '@u-wave/react-youtube';
import { StyleSheet } from 'react-native';
import type { ITextYoutubeVideoPDFView } from './types';
import { PDFFileAttachment } from './PDFFileAttachment';
import { StyleInnerHTML } from './StyleInnerHTML';
import { Button, Image } from 'native-base';
import { Dimensions } from 'react-native';
import Images from '../../../Images';
import SeekBar from './SeekBar';

export const YoutubeVideoTextPDF: React.FC<ITextYoutubeVideoPDFView> = ({
  youtubeVideoTextPdfObj,
  isVideoAutoPlayed,
  handleVideoConsumed,
  handlePDFConsumed,
}) => {
  const youtubeVideoPayload = youtubeVideoTextPdfObj?.payload?.youtube;
  const [controlState, setControlState] = useState<{
    videoLength: number;
    currentLength: number;
    isFullScreen: boolean;
    isReplay: boolean;
    progress: number;
    isPause: boolean;
  }>({
    videoLength: 0,
    currentLength: 0,
    isFullScreen: false,
    isReplay: false,
    progress: 0,
    isPause: true,
  });
  const consumeTime = youtubeVideoPayload[0]?.consumeTime;
  const pdfPayload = youtubeVideoTextPdfObj?.payload?.pdf;
  const youtubePlayerRef = useRef<any>(null);
  const currentTime = useRef<number>(0);
  const [hideYoutubeComponent, setHideYoutubeComponent] = useState(false);
  let isConsumedCalled = useRef(false);
  const screenSize = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const player = await youtubePlayerRef.current?.player;
      if (player) {
        const newProgress =
          (player?.getCurrentTime() / player?.getDuration()) * 100;

        let isPause: boolean | undefined;

        const playerState = player.getPlayerState();

        if (playerState === 1) {
          isPause = false;
        } else if (playerState === 2) {
          isPause = true;
        }

        if (
          !isConsumedCalled?.current &&
          ((consumeTime && player?.getCurrentTime() > consumeTime) ||
            (player?.getDuration() > 30 && player?.getCurrentTime() > 30) ||
            player?.getDuration() === player?.getCurrentTime())
        ) {
          isConsumedCalled.current = true;
          handleVideoConsumed({
            consumeTime: player?.getCurrentTime(),
            isVideoEnd: false,
          });
        }
        setControlState((preState) => {
          return {
            ...preState,
            videoLength: player?.getDuration(),
            currentLength: player?.getCurrentTime(),
            progress: newProgress,
            isPause: isPause ?? preState.isPause,
          };
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumeTime, youtubePlayerRef]);

  useEffect(() => {
    setHideYoutubeComponent(true);
    document.addEventListener('fullscreenchange', () => {
      setControlState((preState) => {
        return {
          ...preState,
          isFullScreen: document.fullscreen,
        };
      });
    });
  }, []);

  const handlePlaying = (e: any) => {
    setControlState((preState) => {
      return {
        ...preState,
        isPause: false,
      };
    });
    currentTime.current = e.data;
  };

  const handleOnVideoEnd = async () => {
    const player = await youtubePlayerRef.current?.player;
    if (!isConsumedCalled?.current) {
      isConsumedCalled.current = true;
      handleVideoConsumed({
        consumeTime: 0,
        isVideoEnd: true,
      });
    }
    togglePlayPause();
    player.seekTo(0);
    setControlState((preState) => {
      return {
        ...preState,
        progress: 0,
        currentLength: 0,
        isPause: false,
        isReplay: true,
      };
    });
  };

  const togglePlayPause = async () => {
    const player = await youtubePlayerRef.current?.player;
    if (controlState.isPause) {
      player.playVideo();
      setControlState((preState) => {
        return {
          ...preState,
          isReplay: false,
          isPause: false,
        };
      });
    } else if (controlState.isReplay) {
      setControlState((preState) => {
        return {
          ...preState,
          isReplay: false,
        };
      });
    } else {
      player.pauseVideo();
      setControlState((preState) => {
        return {
          ...preState,
          isPause: true,
        };
      });
    }
  };

  const handleFullscreen = async () => {
    try {
      const element = document.getElementById('youtube-frame-id');
      setControlState((preState) => {
        return {
          ...preState,
          isFullScreen: !preState.isFullScreen,
        };
      });
      if (controlState.isFullScreen) {
        await document.exitFullscreen();
      } else {
        await element?.requestFullscreen();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPlayerHeight = () => {
    if (hideYoutubeComponent) {
      return 0;
    } else {
      if (controlState.isFullScreen) {
        return Math.ceil(screenSize.height / 100) * 100 - 20;
      } else {
        return 300;
      }
    }
  };

  const getControlStateUri = () => {
    if (controlState.isReplay) {
      return Images.ReplayButton;
    } else {
      if (controlState.isPause) {
        return Images.WhitePlayButton;
      } else {
        return Images.WhitePauseButton;
      }
    }
  };

  const styles = StyleSheet.create({
    // @ts-ignore -> For webview need to remove translateZ in transform
    scrollViewTransform: { transform: 'none' },
  });

  return (
    <ScrollView flex={1} style={styles.scrollViewTransform}>
      {youtubeVideoPayload?.length > 0 && (
        <>
          <WithKNOWLoader
            isLoading={hideYoutubeComponent}
            size="lg"
            height="100px"
            width="100%"
          />
          <div id="youtube-frame-id">
            <View bgColor="black" flex={hideYoutubeComponent ? 0 : 1}>
              <YouTube
                width="100%"
                disableKeyboard={true}
                playsInline
                showRelatedVideos={false}
                height={getPlayerHeight()}
                controls={false}
                video={youtubeVideoPayload?.[0]?.id}
                autoplay={isVideoAutoPlayed}
                onPlaying={handlePlaying}
                onReady={() => setHideYoutubeComponent(false)}
                onPause={() =>
                  setControlState((preState) => {
                    return {
                      ...preState,
                      isPause: true,
                    };
                  })
                }
                onEnd={handleOnVideoEnd}
                ref={youtubePlayerRef}
                allowFullscreen={false}
                className="yt-player"
              />

              {!hideYoutubeComponent && (
                <View
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  alignSelf="center"
                  position="absolute"
                  bottom={controlState.isFullScreen ? 10 : 0}
                  width="100%"
                  bgColor="trasparent.300"
                  textAlign="center"
                >
                  <Button
                    bgColor="transparent"
                    borderRadius="50%"
                    width="35px"
                    height="35px"
                    justifyContent="center"
                    alignItems="center"
                    onPress={togglePlayPause}
                  >
                    <View>
                      <Image
                        height="20px"
                        width="20px"
                        resizeMode="cover"
                        rounded={'full'}
                        alt="Play Video"
                        source={{
                          uri: getControlStateUri(),
                        }}
                      />
                    </View>
                  </Button>
                  <View paddingRight="12px" paddingY="8px">
                    <Text color="white">{`${
                      convertToSeconds(controlState.currentLength).min
                    }:${
                      convertToSeconds(controlState.currentLength).sec
                    }`}</Text>
                  </View>
                  <View flexGrow={1}>
                    <SeekBar
                      step={0.0166}
                      minValue={0}
                      value={+controlState.progress}
                      width="100%"
                    />
                  </View>
                  <View paddingLeft="12px" paddingY="8px">
                    <Text color="white">{`${
                      convertToSeconds(controlState.videoLength).min
                    }:${convertToSeconds(controlState.videoLength).sec}`}</Text>
                  </View>
                  <Button
                    onPress={handleFullscreen}
                    bgColor="transparent"
                    borderRadius="50%"
                    width="35px"
                    height="35px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <View>
                      <Image
                        height="20px"
                        width="20px"
                        resizeMode="cover"
                        rounded={'full'}
                        alt={'Full screen'}
                        source={{
                          uri: Images.FullScreen,
                        }}
                      />
                    </View>
                  </Button>
                </View>
              )}
            </View>
          </div>
        </>
      )}
      <View margin="15px">
        {pdfPayload?.length > 0 && (
          <PDFFileAttachment
            pdfPayload={pdfPayload}
            handlePDFConsumed={handlePDFConsumed}
          />
        )}
        <Text
          marginTop="10px"
          fontWeight="semibold"
          fontSize={['18px', '18px', '18px', '20px', '20px']}
        >
          {youtubeVideoTextPdfObj?.title}
        </Text>

        <Text
          fontSize={['14px', '14px', '14px', '15px', '15px']}
          color="gray.700"
        >
          <StyleInnerHTML data={youtubeVideoTextPdfObj?.notes} />
        </Text>
      </View>
    </ScrollView>
  );
};
