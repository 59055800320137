import { UploadFileResultType, createIssue as createIssueDB } from '@know/db';
import {
  OrgIssueTypeInterface,
  useKNOWDBContext,
  ExpoEntypoIcon,
  ExpoMCIcon,
  AngleDoubleUpIcon,
  useHideHeader,
  useKNOWValueContextProvider,
  UploadProgressType,
  UploadFileHandlerRef,
  useDisclose,
  onEmbedSubmitStatusPayloadInterface,
  FormWebviewRef,
  useNavigation,
  StackActions,
  CommonActions,
  useRecoilValue,
  useDebounceCallback,
} from '@know/ui';
import { keys, sortBy } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { issueLastOpenTabAtom, issueRouteAtom } from '../state';

export interface IssueTypeInterface extends OrgIssueTypeInterface {
  id: string;
  value: string;
}

interface IssueSeverityOption {
  value: 'Low' | 'Medium' | 'High' | 'Critical';
  icon: JSX.Element;
  color: string;
}

interface IssueValuesInterface {
  issueType?: IssueTypeInterface;
  name?: string;
  location?: {
    id: string;
    name: string;
    value: string;
  };
  notifyLeaders?: boolean;
  notes?: string;
  payload?: any[];
  severity?: IssueSeverityOption;
}

export const IMAGES_LIMIT = 10;
export const VIDEOS_LIMIT = 1;
export const PDF_FILES_LIMIT = 1;

export const issueSeverities: IssueSeverityOption[] = [
  {
    value: 'Low',
    icon: (
      <ExpoEntypoIcon
        color={'#198BC3'}
        name="chevron-thin-down"
        size={'14px'}
      />
    ),
    color: '#198BC3',
  },
  {
    value: 'Medium',
    icon: <ExpoMCIcon color={'#EA750A'} name="equal" size={'14px'} />,
    color: '#EA750A',
  },
  {
    value: 'High',
    icon: (
      <ExpoEntypoIcon color={'#F95757'} name="chevron-thin-up" size={'14px'} />
    ),
    color: '#F95757',
  },
  {
    value: 'Critical',
    icon: <AngleDoubleUpIcon color={'#F95757'} size={'14px'} />,
    color: '#F95757',
  },
];

export const useCreateIssue = () => {
  const {
    currentDBApi,
    // getCurrentUserUserId,
    getCurrentUserName,
    uploadFilesWithFilesDetails: uploadFilesDB,
    // userDetails,
    orgConfig,
  } = useKNOWDBContext();

  const { isTabletSize, isLargePhoneSize, isPhoneSize } =
    useKNOWValueContextProvider();

  const navigation = useNavigation();

  const issueRoutes = useRecoilValue(issueRouteAtom);
  const lastIssueTabOpened = useRecoilValue(issueLastOpenTabAtom);

  useHideHeader();

  const [showSpinnerModal, setShowSpinnerModal] = useState<boolean>(false);
  const [isTryingToSubmit, setIsTryingToSubmit] = useState<boolean>(false);

  const [fieldValues, setFieldValues] = useState<IssueValuesInterface>({
    severity: issueSeverities[0],
  });

  const [fieldsTouched, setFieldsTouched] = useState<{
    [key in keyof IssueValuesInterface]?: boolean;
  }>({});

  const [scrollViewHeight, setScrollViewHeight] = useState<number>(0);
  const [webviewHeight, setWebviewHeight] = useState<number>();

  const scrollViewRef = useRef<any>(null);

  const onFieldUpdateWithDebounce = useDebounceCallback(
    (fieldName: keyof IssueValuesInterface, fieldValue: any) => {
      setFieldValues((currentValues) => ({
        ...currentValues,
        [fieldName]: fieldValue,
      }));

      setFieldsTouched((currentFieldValue) => ({
        ...currentFieldValue,
        [fieldName]: true,
      }));
    },
    300
  );

  const onFieldUpdate = useCallback(
    (fieldName: keyof IssueValuesInterface, fieldValue: any) => {
      setFieldValues((currentFieldValue) => ({
        ...currentFieldValue,
        [fieldName]: fieldValue,
      }));

      setFieldsTouched((currentFieldValue) => ({
        ...currentFieldValue,
        [fieldName]: true,
      }));
    },
    []
  );

  const onFieldTouched = useCallback(
    (fieldName: keyof IssueValuesInterface) => {
      setFieldsTouched((currentFieldValue) => ({
        ...currentFieldValue,
        [fieldName]: true,
      }));
    },
    []
  );

  const errors = useMemo(() => {
    const { issueType, name, location } = fieldValues;

    const results: {
      [key in keyof IssueValuesInterface]?: boolean;
    } = {};

    if (!issueType) {
      results.issueType = true;
    }

    if (!name || !name.trim().length) {
      results.name = true;
    }

    if (!location) {
      results.location = true;
    }

    return results;
  }, [fieldValues]);

  const issueTypesOptions = useMemo<IssueTypeInterface[]>(() => {
    const issueTypes = orgConfig?.issues?.types ?? {};

    return sortBy(
      Object.keys(issueTypes).map((id) => ({
        ...issueTypes[id],
        value: issueTypes[id].name,
        id,
      })),
      ({ name }) => name.toLowerCase()
    );
  }, [orgConfig?.issues?.types]);

  const locationsOptions = useMemo<
    {
      id: string;
      name: string;
      value: string;
    }[]
  >(() => {
    const locations = orgConfig?.locations ?? {};

    return sortBy(
      Object.keys(locations).map((id) => ({
        ...locations[id],
        id,
        value: id,
        label: locations[id].name,
      })),
      ({ name }) => name.toLowerCase()
    );
  }, [orgConfig?.locations]);

  const defaultNotifyLeaders = fieldValues.issueType?.notifyLeaders;

  const isNotifyLeadersChecked =
    (defaultNotifyLeaders || fieldValues?.notifyLeaders) ?? false;

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadProgressProps, setUploadProgressProps] = useState<{
    type: UploadProgressType;
    numberOfFiles: number;
  }>({ type: 'signature', numberOfFiles: 1 });

  const {
    isOpen: isUploadImageModalOpen,
    onOpen: openUploadImageModal,
    onClose: closeUploadImageModal,
  } = useDisclose();

  const [uploadedImages, setUploadedImages] = useState<UploadFileResultType[]>(
    []
  );

  const {
    isOpen: isUploadVideoModalOpen,
    onOpen: openUploadVideoModal,
    onClose: closeUploadVideoModal,
  } = useDisclose();

  const [uploadedVideos, setUploadedVideos] = useState<UploadFileResultType[]>(
    []
  );

  const uploadFileHandlerRef = useRef<UploadFileHandlerRef | null>(null);

  const [uploadedPDFFiles, setUploadedPDFFiles] = useState<
    UploadFileResultType[]
  >([]);

  const allowedImagesLimit = IMAGES_LIMIT - uploadedImages.length;
  const allowedVideosLimit = VIDEOS_LIMIT - uploadedVideos.length;
  const allowedPDFFilesLimit = PDF_FILES_LIMIT - uploadedPDFFiles.length;

  const onClickUploadImage = useCallback(() => {
    if (allowedImagesLimit) {
      openUploadImageModal();
    }
  }, [allowedImagesLimit, openUploadImageModal]);

  const onClickUploadVideo = useCallback(() => {
    if (allowedVideosLimit) {
      openUploadVideoModal();
    }
  }, [allowedVideosLimit, openUploadVideoModal]);

  const uploadFiles = useCallback(
    async (files: File[], uploadType: UploadProgressType) => {
      setUploadProgress(0);
      setUploadProgressProps({ numberOfFiles: files.length, type: uploadType });
      setIsUploading(true);
      const path = `${currentDBApi?.organization ?? 'na'}/${
        currentDBApi?.getLoggedInUserId() ?? 'user-id'
      }/issue/newIssue`;
      const results = await uploadFilesDB(path, files, (progress) => {
        setUploadProgress(progress);
      });
      setUploadProgress(100);
      setIsUploading(false);
      setUploadProgress(0);

      return results ?? [];
    },
    [currentDBApi, uploadFilesDB]
  );

  const onAddAttachment = useCallback(
    async (files: File[], uploadType: UploadProgressType) => {
      if (currentDBApi && uploadType !== 'signature') {
        const uploadedFiles = await uploadFiles(files, uploadType);

        if (uploadType === 'image') {
          setUploadedImages((currentUploadedImages) => [
            ...currentUploadedImages,
            ...uploadedFiles,
          ]);
        }
        if (uploadType === 'video') {
          setUploadedVideos((currentUploadedVideos) => [
            ...currentUploadedVideos,
            ...uploadedFiles,
          ]);
        }
        if (uploadType === 'file') {
          setUploadedPDFFiles((currentUploadedPDFFiles) => [
            ...currentUploadedPDFFiles,
            ...uploadedFiles,
          ]);
        }
      }

      return [];
    },
    [currentDBApi, uploadFiles]
  );

  const onAddImages = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'image');
    },
    [onAddAttachment]
  );

  const onAddVideos = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'video');
    },
    [onAddAttachment]
  );

  const onAddFiles = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'file');
    },
    [onAddAttachment]
  );

  const onClickUploadFile = useCallback(() => {
    uploadFileHandlerRef.current?.onClick();
  }, []);

  const onDeleteImage = useCallback((imageIndex: number) => {
    setUploadedImages((currentUploadedImages) =>
      currentUploadedImages.filter((_, index) => index !== imageIndex)
    );
  }, []);

  const deleteAllImages = useCallback(() => {
    setUploadedImages([]);
  }, []);

  const onDeleteVideo = useCallback((videoIndex: number) => {
    setUploadedVideos((currentUploadedVideos) =>
      currentUploadedVideos.filter((_, index) => index !== videoIndex)
    );
  }, []);

  const onDeletePDFFile = useCallback((pdfFileIndex: number) => {
    setUploadedPDFFiles((currentUploadedPDFFiles) =>
      currentUploadedPDFFiles.filter((_, index) => index !== pdfFileIndex)
    );
  }, []);

  const formId = fieldValues.issueType?.customForms?.create?.id;

  const createIssue = useCallback(
    async (formData?: { formId: string; responseId: string }) => {
      if (currentDBApi) {
        const issueId = await createIssueDB(currentDBApi, {
          ...fieldValues,
          authorName: getCurrentUserName(),
          severity: fieldValues.severity?.value ?? 'Low',
          createForm: formData,
          images: uploadedImages,
          videos: uploadedVideos,
          pdfFiles: uploadedPDFFiles,
        });
        setShowSpinnerModal(false);
        setIsTryingToSubmit(false);

        // for auto share type dont show share dialogue
        if (fieldValues.issueType?.autoRecipients) {
          navigation.dispatch(
            StackActions.replace(
              'issues-details' as never,
              {
                issueId,
                share: false,
                shareId: Date.now(),
              } as never
            )
          );
        } else {
          navigation.dispatch(
            StackActions.replace(
              'issues-details' as never,
              {
                issueId,
                share: true,
                shareId: Date.now(),
              } as never
            )
          );
        }
      }
    },
    [
      currentDBApi,
      fieldValues,
      navigation,
      getCurrentUserName,
      uploadedImages,
      uploadedPDFFiles,
      uploadedVideos,
    ]
  );

  const scrollTo = useCallback((offset?: number) => {
    console.log('scroll to', offset);
    scrollViewRef.current?.scrollTo({
      y: (offset ?? 0) - 61,
      animated: true,
    });
  }, []);

  const isIssueValid = useCallback(() => {
    const errorKeys = keys(errors);
    if (!errorKeys.length) {
      return true;
    }

    scrollTo(0);

    return false;
  }, [errors, scrollTo]);

  const onSubmit = useCallback(
    (webview: FormWebviewRef | null) => {
      console.log('on submit running');
      setIsTryingToSubmit(true);
      setFieldsTouched((currentFieldsTouched) => ({
        ...currentFieldsTouched,
        issueType: true,
        name: true,
        location: true,
      }));

      if (isIssueValid()) {
        if (formId) {
          webview?.embedSubmit();
        } else {
          setShowSpinnerModal(true);
          createIssue();
        }
      } else {
        setIsTryingToSubmit(false);
      }
    },
    [createIssue, formId, isIssueValid]
  );

  const onFormWebviewSubmitStatus = useCallback(
    (payload?: onEmbedSubmitStatusPayloadInterface) => {
      if (!payload?.success) {
        setIsTryingToSubmit(false);
        setShowSpinnerModal(false);
        if (payload?.errorElement) {
          scrollTo(
            scrollViewHeight - (webviewHeight ?? 0) + payload.errorElement.top
          );
        }
      } else {
        setShowSpinnerModal(true);
      }
    },
    [scrollTo, scrollViewHeight, webviewHeight]
  );

  const onFormSubmission = useCallback(
    (
        submitFormFunction: (
          isRejected?: boolean
        ) => Promise<{ formId: string; responseId: string } | null>
      ) =>
      async (isRejected?: boolean) => {
        const results = await submitFormFunction(isRejected);
        if (results) {
          createIssue(results);
        }
      },
    [createIssue]
  );

  const goBack = useCallback(() => {
    const routes = issueRoutes.map((name: string) => ({
      name: name as never,
    }));
    const filteredRoutes = routes.filter(
      ({ name }) => name === lastIssueTabOpened
    );

    const resetPayload = {
      routes: [
        {
          name: 'Issues',
          state: {
            routes: [
              {
                name: 'issues-list',
                state: {
                  routes: filteredRoutes,
                },
              },
            ],
          },
        },
      ],
    };

    navigation.dispatch(CommonActions.reset(resetPayload));
  }, [issueRoutes, lastIssueTabOpened, navigation]);

  return {
    issueTypesOptions,
    fieldValues,
    fieldsTouched,
    errors,
    locationsOptions,
    isNotifyLeadersChecked,
    defaultNotifyLeaders,
    isTabletSize,
    isLargePhoneSize,
    isPhoneSize,
    isUploadImageModalOpen,
    isUploadVideoModalOpen,
    uploadFileHandlerRef,
    isUploading,
    uploadProgress,
    uploadProgressProps,
    uploadedImages,
    uploadedVideos,
    uploadedPDFFiles,
    allowedImagesLimit,
    allowedVideosLimit,
    allowedPDFFilesLimit,
    formId,
    showSpinnerModal,
    isTryingToSubmit,
    scrollViewRef,
    webviewHeight,
    onFieldUpdate,
    onFieldUpdateWithDebounce,
    onFieldTouched,
    onAddImages,
    onAddVideos,
    onAddFiles,
    onClickUploadImage,
    closeUploadImageModal,
    onClickUploadVideo,
    closeUploadVideoModal,
    onClickUploadFile,
    onDeleteImage,
    deleteAllImages,
    onDeleteVideo,
    onDeletePDFFile,
    onFormWebviewSubmitStatus,
    onSubmit,
    onFormSubmission,
    setScrollViewHeight,
    setWebviewHeight,
    goBack,
  };
};
