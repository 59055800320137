import React from 'react';
import { View, FlatList, Text, Pressable, useBreakpointValue } from '@know/ui';

import type { IQuizQuestion } from '../types';
import { HotspotQuestion } from './HotspotQuestion';
import { StyleSheet } from 'react-native';

const QuestionDetail = ({
  selectedIndex,
  questions,
  quizProgress,
  renderQuestion,
  isHotspotType,
  setHotspotIcons,
  hotspotIcons,
  hotspotIconsQuizRes,
  setHotspotIconsQuizRes,
  setQuizProgress,
  isTimer = false,
  setShowModal,
}: {
  selectedIndex: number;
  questions: IQuizQuestion[];
  quizProgress: string[];
  renderQuestion?: ({ item, index }: any) => JSX.Element;
  isHotspotType: boolean;
  setHotspotIcons: any;
  hotspotIcons: [];
  hotspotIconsQuizRes: any;
  setHotspotIconsQuizRes: any;
  setQuizProgress: any;
  isTimer?: boolean;
  showModal?: boolean;
  setShowModal?: any;
}) => {
  const handleClear = () => {
    setHotspotIcons([]);
    setHotspotIconsQuizRes([]);
    setQuizProgress((prevState: any) => {
      const state = [...prevState];
      state[selectedIndex] = [];
      return state;
    });
  };

  const flatLiatContainerPaddingVertical = useBreakpointValue({
    base: '4px',
    sm: '4px',
    md: '6px',
    lg: '6px',
    xl: '6px',
  });

  const styles = StyleSheet.create({
    flatListContainer: {
      paddingHorizontal: '15px',
      paddingVertical: flatLiatContainerPaddingVertical,
    },
  });

  return (
    <>
      <View
        flexDirection="row"
        alignItems="baseline"
        paddingTop={isTimer ? '0px' : '15px'}
        paddingX="15px"
      >
        <Text
          fontWeight="semibold"
          fontSize={['14px', '14px', '16px', '18px', '18px']}
        >
          Question {selectedIndex + 1}
        </Text>
        <Text
          color="gray.400"
          fontWeight="semibold"
          fontSize={['12px', '12px', '14px', '16px', '16px']}
        >
          /{questions.length}
        </Text>
        {isHotspotType && (
          <Pressable
            flex={1}
            alignItems="center"
            justifyContent="end"
            flexDirection="row"
            onPress={handleClear}
          >
            <Text marginLeft="5px" color="primary.500" fontWeight="semibold">
              Clear Markers
            </Text>
          </Pressable>
        )}
      </View>
      <View w="100%" h="1px" bgColor="gray.300" marginY="10px" />
      <Text
        paddingX="15px"
        fontWeight="semibold"
        fontSize={['18px', '18px', '20px', '20px', '20px']}
      >
        {questions[selectedIndex].text}
      </Text>
      {isHotspotType && questions[selectedIndex].allowMultiple && (
        <Text paddingX="15px" color="gray.800">
          You may indicate multiple answers.
        </Text>
      )}
      {isHotspotType ? (
        <HotspotQuestion
          hotspotQuestionObj={questions?.[selectedIndex] || {}}
          hotspotIcons={hotspotIcons}
          setHotspotIcons={setHotspotIcons}
          hotspotIconsQuizRes={hotspotIconsQuizRes}
          setHotspotIconsQuizRes={setHotspotIconsQuizRes}
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
          selectedIndex={selectedIndex}
          setShowModal={setShowModal}
        />
      ) : (
        <FlatList
          scrollEnabled={false}
          numColumns={2}
          contentContainerStyle={styles.flatListContainer}
          extraData={quizProgress}
          keyExtractor={(_evt, index) => `${index}`}
          data={questions[selectedIndex].options}
          renderItem={renderQuestion}
        />
      )}
    </>
  );
};

export default QuestionDetail;
