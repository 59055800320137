import {
  useBreakpointValue,
  useRecoilValue,
  useSetRecoilState,
  KNOWDBContext,
} from '@know/ui';

import { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { myCoursesSelector, userCoursesFeedAtom } from '../state';
import type { IDivInfo, IUserFeed } from '../types';

const LIMIT = 10;
export const useMyCourses = (loadMore: boolean, setLoadMore: any) => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const COURSE_TYPE = 'myCourseFeed';
  const userFeed = useRecoilValue(myCoursesSelector);
  const setUserFeedObject = useSetRecoilState<{
    [key: string]: IUserFeed;
  }>(userCoursesFeedAtom);

  const [isLoading, setIsLoading] = useState(true);

  const noOfColumns = useBreakpointValue({
    sm: 1,
    md: 4,
    lg: 4,
    xl: 4,
  });

  const parentDivInfo = useRef<IDivInfo>({
    height: null,
    width: null,
  });

  const childDivInfo = useRef<IDivInfo>({
    height: null,
    width: null,
  });

  const getUserFeed = useCallback(async () => {
    if (currentDBApi && loadMore) {
      const feed: {
        [key: string]: IUserFeed;
      } = await currentDBApi.getUserCourseFeedItems({
        limit: LIMIT,
        lastCreatedAt: userFeed[userFeed.length - 1]?.createdAt ?? Date.now(),
        courseType: COURSE_TYPE,
      });
      if (
        userFeed &&
        feed &&
        userFeed[userFeed.length - 1]?.createdAt ===
          Object.values(feed)[Object.values(feed).length - 1]?.createdAt
      ) {
        setLoadMore(false);
      }
      setUserFeedObject((existingFeed: any) => {
        const updatedFeed = {
          ...existingFeed,
          ...(feed || {}),
        };
        handleUserReceivedEvent(updatedFeed);
        return updatedFeed;
      });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDBApi, setUserFeedObject, userFeed]);

  useEffect(() => {
    getUserFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserReceivedEvent = async (_userFeed: IUserFeed[]) => {
    const payload = {
      dt: Date.now(),
      userFeed: _userFeed,
    };
    await currentDBApi?.setUserFeedCourseInfo('UserFeedReceived', payload);
  };
  return {
    userFeed,
    noOfColumns,
    parentDivInfo,
    childDivInfo,
    isLoading,
    getUserFeed,
  };
};
