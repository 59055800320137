import React from 'react';
import { createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const TimesIcon = createIcon({
  viewBox: '0 0 20 20',
  path: [
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7284 9.98867L14.8687 5.85475C14.9505 5.75936 14.9933 5.63665 14.9884 5.51115C14.9836 5.38565 14.9315 5.2666 14.8425 5.17779C14.7536 5.08898 14.6343 5.03695 14.5086 5.0321C14.3829 5.02726 14.26 5.06995 14.1645 5.15164L10.0241 9.28555L5.88378 5.14665C5.78973 5.05275 5.66218 5 5.52918 5C5.39618 5 5.26862 5.05275 5.17458 5.14665C5.08053 5.24055 5.02769 5.36791 5.02769 5.5007C5.02769 5.6335 5.08053 5.76085 5.17458 5.85475L9.31993 9.98867L5.17458 14.1226C5.12229 14.1673 5.07983 14.2223 5.04985 14.2842C5.01988 14.346 5.00303 14.4134 5.00037 14.4821C4.99772 14.5508 5.00931 14.6192 5.03441 14.6832C5.05952 14.7472 5.09761 14.8053 5.14628 14.8539C5.19496 14.9025 5.25316 14.9406 5.31725 14.9656C5.38135 14.9907 5.44993 15.0023 5.51872 14.9996C5.5875 14.997 5.65499 14.9802 5.71695 14.9502C5.77892 14.9203 5.83401 14.8779 5.87879 14.8257L10.0241 10.6918L14.1645 14.8257C14.26 14.9074 14.3829 14.9501 14.5086 14.9452C14.6343 14.9404 14.7536 14.8884 14.8425 14.7995C14.9315 14.7107 14.9836 14.5917 14.9884 14.4662C14.9933 14.3407 14.9505 14.218 14.8687 14.1226L10.7284 9.98867Z"
      fill="#4E5656"
      stroke="#4E5656"
      strokeWidth="0.5"
    />,
  ],
});
