import React from 'react';
import {
  Box,
  NoDataForms,
  Table,
  Text,
  WithKNOWLoader,
  // FlatListWithFilter,
  // TaskListRow,
} from '@know/ui';
import { useFollowingIssues } from './useFollowingIssues';

const FollowingIssuesPage = () => {
  const {
    isDataLoading,
    issueItems,
    onFilterTextChange,
    filterText,
    initialState,
    columns,
    onClick,
    // isSmallScreen,
  } = useFollowingIssues();

  return (
    <WithKNOWLoader
      isLoading={isDataLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      {/* {isSmallScreen ? (
        <Box h={'100%'} mt={'12px'}>
          <FlatListWithFilter
            keyExtractor={(item) => `${item.taskId}`}
            data={issueItems}
            hasFilterText={!!filterText}
            filterText={filterText ?? ''}
            renderItem={({ item }) => (
              <TaskListRow item={item} onPress={onClick} />
            )}
            onFilterChange={onFilterTextChange}
            NoDataComponent={
              <NoDataForms
                text="Looks like you are not following any issues yet."
                icon="issues"
              />
            }
            NoResultsFoundComponent={
              <Box
                my={'24px'}
                w={'100%'}
                display={'flex'}
                flexDir={'row'}
                justifyContent={'center'}
              >
                <Text textAlign={'center'}>No results found.</Text>
              </Box>
            }
          />
        </Box>
      ) : (
        <Table
          columns={columns}
          data={issueItems}
          onRowClick={onClick}
          filter={filterText ?? ''}
          initialState={initialState}
          enableGlobalFilter={true}
          onChangeGlobalFilter={onFilterTextChange}
          isSearching={!!filterText}
          NoDataComponent={
            <NoDataForms
              text="Looks like you are not following any issues yet."
              icon="issues"
            />
          }
          NoResultsFoundComponent={
            <Box
              my={'24px'}
              w={'100%'}
              display={'flex'}
              flexDir={'row'}
              justifyContent={'center'}
            >
              <Text textAlign={'center'}>No results found.</Text>
            </Box>
          }
          unreadRowStatus={true}
        />
      )} */}
      <Table
        columns={columns}
        data={issueItems}
        onRowClick={onClick}
        filter={filterText ?? ''}
        initialState={initialState}
        enableGlobalFilter={true}
        onChangeGlobalFilter={onFilterTextChange}
        isSearching={!!filterText}
        NoDataComponent={
          <NoDataForms
            text="Looks like you are not following any issues yet."
            icon="issues"
          />
        }
        NoResultsFoundComponent={
          <Box
            my={'24px'}
            w={'100%'}
            display={'flex'}
            flexDir={'row'}
            justifyContent={'center'}
          >
            <Text textAlign={'center'}>No results found.</Text>
          </Box>
        }
        unreadRowStatus={true}
      />
    </WithKNOWLoader>
  );
};

export default FollowingIssuesPage;
