//TODO: Make a react native friendly version of the table component
import React, { useEffect, useRef, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';
import {
  Box,
  // KNOWButton as Button,
  // Text,
  SortedAscIcon,
  SortedDescIcon,
  UnSortedIcon,
  LocationDateFilter,
  useDisclose,
} from '@know/ui';
import { InputFilter } from '../InputFilter';
// import { FilterIcon } from '../Icons/FilterIcon';
import { TableProps } from './types';
import './index.css';

interface TableWrapperProps {
  $clickable?: boolean;
  $hasScrollbar?: boolean;
  $columnFlex?: (number | undefined)[];
  $columnMinWidth?: (number | undefined)[];
  $hasGlobalFilter?: boolean;
  $customPadding?: string;
}

const Styles = styled.div<TableWrapperProps>`
  height: 100%;
  .table {
    width: 100%;
    height: ${(props) =>
      props.$hasGlobalFilter ? 'calc(100% - 42px)' : '100%'};
    border-spacing: 0;
    display: flex;
    flex-direction: column;
    .thead {
      margin-right: ${(props) => (props.$hasScrollbar ? '1em' : 'inherit')};
    }
    .tbody {
      max-height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .tr:hover {
        background: ${(props) => (props.$clickable ? '#eff2f2' : 'inherit')};
        cursor: ${(props) => (props.$clickable ? 'pointer' : 'inherit')};
      }
    }
    .tr {
      display: flex;
      border-bottom: 1px solid #d9dddd;
      padding: ${(props) => props.$customPadding};

      &.unread {
        border-left: 4px solid #c74646;
      }
      .th,
      .td {
        padding: 12px 0;
        display: flex;
        align-items: center;

        ${(props) =>
          props.$columnFlex
            ? props.$columnFlex.map((flex, index) =>
                flex
                  ? `&:nth-child(${index + 1}) {
            flex: ${flex};
          }`
                  : ''
              )
            : ''}
        ${(props) =>
          props.$columnMinWidth
            ? props.$columnMinWidth.map((minWidth, index) =>
                minWidth
                  ? `&:nth-child(${index + 1}) {
                min-width: ${minWidth}px;
              }`
                  : ''
              )
            : ''}
      }
    }
  }
`;

export const Table = ({
  columns,
  data,
  initialState,
  onRowClick,
  enableGlobalFilter,
  onChangeGlobalFilter,
  isSearching,
  NoDataComponent,
  NoResultsFoundComponent,
  unreadRowStatus,
  customPadding = '0 16px',
  filter,
  // hasDateRangeLocationFilter,
  dateRangeFilter,
  locationFilter,
  locations,
  onChangeLocationFilter,
  onChangeDateFilter,
}: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  const [hasScrollbar, setHasScrollbar] = useState(false);

  const tableBodyRef = useRef<HTMLDivElement | null>(null);

  const {
    isOpen: isLocationDateFilterOpen,
    // onOpen: onLocationDateFilterOpen,
    onClose: onLocationDateFilterClose,
  } = useDisclose();

  useEffect(() => {
    const currentElement = tableBodyRef.current;
    if (currentElement) {
      // eslint-disable-next-line no-undef
      const resizeObserver = new ResizeObserver(() => {
        const elementHasScrollbar =
          currentElement.scrollHeight > currentElement.clientHeight;
        setHasScrollbar(elementHasScrollbar);
      });

      resizeObserver.observe(currentElement);

      return () => {
        resizeObserver.unobserve(currentElement);
      };
    }
  }, []);

  //@ts-ignore
  const columnFlex = columns.map((col) => (col.flex ? +col.flex : 1));
  //@ts-ignore
  const columnMinWidth = columns.map((col) => col.minWidth);

  return (
    <Styles
      $clickable={!!onRowClick}
      $hasScrollbar={hasScrollbar}
      $columnFlex={columnFlex}
      $columnMinWidth={columnMinWidth}
      $hasGlobalFilter={enableGlobalFilter}
      $customPadding={customPadding}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        width={'100%'}
      >
        <InputFilter
          hasFilter={enableGlobalFilter}
          onChange={onChangeGlobalFilter}
          inputProps={{ mx: '14px', w: 'unset' }}
          defaultValue={filter}
        />
        {/* {hasDateRangeLocationFilter && (
          <Button
            colorScheme={'secondary'}
            onPress={onLocationDateFilterOpen}
            marginRight={'16px'}
          >
            <Box display={'flex'} flexDir={'row'} alignItems={'center'}>
              <FilterIcon size={'13px'} marginRight={'2px'} />
              <Text fontWeight={600} fontSize={'14px'}>
                {'Filters'}
              </Text>
            </Box>
          </Button>
        )} */}
      </Box>
      {isLocationDateFilterOpen && (
        <LocationDateFilter
          dateRangeFilterValue={dateRangeFilter ?? [null, null]}
          locationFilterValue={locationFilter ?? null}
          issueLocations={locations ?? {}}
          cancel={onLocationDateFilterClose}
          isOpen={isLocationDateFilterOpen}
          onChangeLocationFilter={onChangeLocationFilter}
          onChangeDateFilter={onChangeDateFilter}
        />
      )}
      {isSearching && !data.length ? NoResultsFoundComponent ?? null : null}
      {!isSearching && !data.length ? NoDataComponent ?? null : null}
      {data.length ? (
        <div className="table" {...getTableProps()}>
          <div className="thead">
            {headerGroups.map((headerGroup) => (
              <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const columnHeaderProps = column.getHeaderProps(
                    //@ts-ignore
                    column.getSortByToggleProps()
                  );

                  //@ts-ignore
                  const canSort = column.canSort;

                  //@ts-ignore
                  const sortIcon = column.isSorted ? (
                    //@ts-ignore
                    column.isSortedDesc ? (
                      <SortedDescIcon ml="2px" mb="-2px" size="14px" />
                    ) : (
                      <SortedAscIcon ml="2px" mb="-2px" size="14px" />
                    )
                  ) : (
                    <UnSortedIcon ml="2px" mb="-2px" size="14px" />
                  );

                  return (
                    <div className="th" {...columnHeaderProps}>
                      {column.render('Header')}
                      {canSort ? <span>{sortIcon}</span> : null}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div className="tbody" {...getTableBodyProps()} ref={tableBodyRef}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div
                  className={`tr ${
                    unreadRowStatus && !(row.original as any)?.isRead
                      ? 'unread'
                      : ''
                  }`}
                  {...row.getRowProps()}
                  onClick={() => onRowClick && onRowClick(row.original)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <div className="td" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </Styles>
  );
};
