import React from 'react';
import { Icon } from 'native-base';
import {
  Path,
  Circle,
  Rect,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';

export const UploadImageIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 40 40">
    <Circle cx="20" cy="20" r="20" fill="#00C1B2" />
    <Circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.8" />
    <Rect x="10" y="9" width="20" height="22" rx="3" fill="white" />
    <Path
      d="M18.3092 25.5412L18.7072 25.9559L19.0628 25.5044L23.6746 19.6495C23.6906 19.6377 23.7092 19.6298 23.7289 19.6267C23.743 19.6244 23.7574 19.6246 23.7713 19.6271L29.5 24.3605V29.75C29.5 29.8485 29.4806 29.946 29.4429 30.037C29.4052 30.128 29.35 30.2107 29.2803 30.2803C29.2107 30.35 29.128 30.4052 29.037 30.4429C28.946 30.4806 28.8485 30.5 28.75 30.5H11.25C11.0511 30.5 10.8603 30.421 10.7197 30.2803C10.579 30.1397 10.5 29.9489 10.5 29.75V27.4317L14.9208 22.1524C14.9396 22.1375 14.9623 22.1282 14.9864 22.1258C15.0036 22.1242 15.0209 22.1261 15.0371 22.1313L18.3092 25.5412ZM18.2475 14.125C18.2475 14.8212 17.9709 15.4889 17.4787 15.9812C16.9864 16.4734 16.3187 16.75 15.6225 16.75C14.9263 16.75 14.2586 16.4734 13.7663 15.9812C13.2741 15.4889 12.9975 14.8212 12.9975 14.125C12.9975 13.4288 13.2741 12.7611 13.7663 12.2688C14.2586 11.7766 14.9263 11.5 15.6225 11.5C16.3187 11.5 16.9864 11.7766 17.4787 12.2688C17.9709 12.7611 18.2475 13.4288 18.2475 14.125Z"
      fill="url(#paint0_linear_41_637)"
    />
    <Path
      d="M18.3092 25.5412L18.7072 25.9559L19.0628 25.5044L23.6746 19.6495C23.6906 19.6377 23.7092 19.6298 23.7289 19.6267C23.743 19.6244 23.7574 19.6246 23.7713 19.6271L29.5 24.3605V29.75C29.5 29.8485 29.4806 29.946 29.4429 30.037C29.4052 30.128 29.35 30.2107 29.2803 30.2803C29.2107 30.35 29.128 30.4052 29.037 30.4429C28.946 30.4806 28.8485 30.5 28.75 30.5H11.25C11.0511 30.5 10.8603 30.421 10.7197 30.2803C10.579 30.1397 10.5 29.9489 10.5 29.75V27.4317L14.9208 22.1524C14.9396 22.1375 14.9623 22.1282 14.9864 22.1258C15.0036 22.1242 15.0209 22.1261 15.0371 22.1313L18.3092 25.5412ZM18.2475 14.125C18.2475 14.8212 17.9709 15.4889 17.4787 15.9812C16.9864 16.4734 16.3187 16.75 15.6225 16.75C14.9263 16.75 14.2586 16.4734 13.7663 15.9812C13.2741 15.4889 12.9975 14.8212 12.9975 14.125C12.9975 13.4288 13.2741 12.7611 13.7663 12.2688C14.2586 11.7766 14.9263 11.5 15.6225 11.5C16.3187 11.5 16.9864 11.7766 17.4787 12.2688C17.9709 12.7611 18.2475 13.4288 18.2475 14.125Z"
      stroke="#00C1B2"
      fill={'none'}
    />
    <Path
      d="M18.3092 25.5412L18.7072 25.9559L19.0628 25.5044L23.6746 19.6495C23.6906 19.6377 23.7092 19.6298 23.7289 19.6267C23.743 19.6244 23.7574 19.6246 23.7713 19.6271L29.5 24.3605V29.75C29.5 29.8485 29.4806 29.946 29.4429 30.037C29.4052 30.128 29.35 30.2107 29.2803 30.2803C29.2107 30.35 29.128 30.4052 29.037 30.4429C28.946 30.4806 28.8485 30.5 28.75 30.5H11.25C11.0511 30.5 10.8603 30.421 10.7197 30.2803C10.579 30.1397 10.5 29.9489 10.5 29.75V27.4317L14.9208 22.1524C14.9396 22.1375 14.9623 22.1282 14.9864 22.1258C15.0036 22.1242 15.0209 22.1261 15.0371 22.1313L18.3092 25.5412ZM18.2475 14.125C18.2475 14.8212 17.9709 15.4889 17.4787 15.9812C16.9864 16.4734 16.3187 16.75 15.6225 16.75C14.9263 16.75 14.2586 16.4734 13.7663 15.9812C13.2741 15.4889 12.9975 14.8212 12.9975 14.125C12.9975 13.4288 13.2741 12.7611 13.7663 12.2688C14.2586 11.7766 14.9263 11.5 15.6225 11.5C16.3187 11.5 16.9864 11.7766 17.4787 12.2688C17.9709 12.7611 18.2475 13.4288 18.2475 14.125Z"
      stroke="black"
      strokeOpacity="0.05"
      fill={'none'}
    />
    <Rect
      x="10.5"
      y="9.5"
      width="19"
      height="21"
      rx="2.5"
      stroke="#00C1B2"
      fill={'none'}
    />
    <Rect
      x="10.5"
      y="9.5"
      width="19"
      height="21"
      rx="2.5"
      stroke="black"
      strokeOpacity="0.1"
      fill={'none'}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_41_637"
        x1="14.5956"
        y1="11.8929"
        x2="29.3278"
        y2="32.0268"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#B6FEF8" />
        <Stop offset="0.96875" stopColor="#14C9BB" />
      </LinearGradient>
    </Defs>
  </Icon>
);
