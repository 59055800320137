import React from 'react';
import { View, Image, Text, Button } from '@know/ui';
import Images from '../../../../Images';
import { ImageBackground, StyleSheet } from 'react-native';
export const SurveyTitle = ({
  isSubmitted,
  title,
  handleSurveyStart,
  handleSurveySubmit,
}: {
  isSubmitted: boolean;
  title: string;
  handleSurveyStart: () => void;
  handleSurveySubmit?: () => void;
}) => {
  const styles = StyleSheet.create({
    imageBg: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    imageStyle: { width: '100%', height: '100%' },
  });
  return (
    <>
      <ImageBackground
        style={styles.imageBg}
        resizeMode="cover"
        source={Images.SurveyTitle}
        imageStyle={styles.imageStyle}
      >
        <View
          bg="white"
          borderRadius={12}
          marginX={['16px', '32px', '60px', '60px', '80px']}
        >
          <View
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding="24px"
            justifyContent="center"
            height="100%"
            fontSize="14px"
            fontWeight="400"
            lineHeight="19.07px"
          >
            <Image w={'100px'} h={'100px'} source={Images.SurveyIcon} />
            <Text
              fontSize="20px"
              fontWeight="600"
              color="#222C2A"
              paddingTop="8px"
            >
              {isSubmitted ? 'Survey Submitted!' : title}
            </Text>
            <Text fontSize="16px" color="#4E5656" paddingBottom="12px">
              {isSubmitted
                ? 'Thanks for sharing your feedback.'
                : 'Please take some time to share your feedback.'}
            </Text>
            <Button
              onPress={isSubmitted ? handleSurveySubmit : handleSurveyStart}
              colorScheme={'primary'}
              padding="12px 23px"
              height="46px"
              borderRadius="8px"
              width="full"
            >
              <Text fontWeight="semibold" color="white" fontSize="16px">
                {isSubmitted ? 'OK' : 'Start Survey'}
              </Text>
            </Button>
          </View>
        </View>
      </ImageBackground>
    </>
  );
};
