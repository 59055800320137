import React, { useCallback, useState } from 'react';
import { Box, Text, Pressable, ExpoIonicIcon, useDisclose } from '@know/ui';
import { SelectItemModal, SelectItemType } from './Modals';

export type TypeAndSearchFieldWithModalType = {
  value?: SelectItemType;
  placeholder?: string;
  title?: string;
  options: SelectItemType[];
  isInvalid?: boolean;
  showFilter?: boolean;
  onChange: (newValue: SelectItemType) => any;
  getDisplayValue: (value: SelectItemType) => JSX.Element;
  getItemComponent: (
    value: SelectItemType,
    options?: { isSelected?: boolean; isHovered?: boolean }
  ) => JSX.Element;
  keyExtractor: (value: SelectItemType) => string;
  onTouched?: () => any;
  onClosed?: () => any;
};
export const TypeAndSearchFieldWithModal: React.FC<TypeAndSearchFieldWithModalType> =
  ({
    value: _value,
    placeholder,
    title,
    options,
    isInvalid,
    showFilter,
    onChange,
    getDisplayValue,
    getItemComponent,
    keyExtractor,
    onTouched,
    onClosed,
  }) => {
    const [value, setValue] = useState<SelectItemType | undefined>(_value);
    const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclose();

    const onSubmit = useCallback(
      (items: SelectItemType[]) => {
        const newValue = items[0];
        setValue(newValue);
        onChange(newValue);
      },
      [onChange]
    );

    const _getItemComponentWithSelectedCheck = useCallback(
      (item: SelectItemType, isHovered?: boolean) =>
        getItemComponent(item, {
          isSelected: item.value === value?.value,
          isHovered,
        }),
      [getItemComponent, value]
    );

    const onOpen = useCallback(() => {
      onTouched?.();
      _onOpen();
    }, [_onOpen, onTouched]);

    const onClose = useCallback(() => {
      onClosed?.();
      _onClose();
    }, [_onClose, onClosed]);

    return (
      <>
        <Pressable onPress={onOpen}>
          {({ isHovered }) => (
            <Box
              display={'flex'}
              flexDir={'row'}
              w={'100%'}
              px={'12px'}
              justifyContent={'space-between'}
              borderRadius={'6px'}
              borderWidth={'1px'}
              borderColor={
                isInvalid ? 'error.500' : isHovered ? 'primary.500' : '#DEDFE2'
              }
              borderStyle={'solid'}
              bgColor={'white'}
            >
              <Box py={'10px'}>
                {value
                  ? getDisplayValue(value)
                  : placeholder && (
                      <Text color={'gray.600'} fontSize={'14px'}>
                        {placeholder}
                      </Text>
                    )}
              </Box>
              <Box
                borderLeftColor={'#D9DDDD'}
                borderLeftWidth={'1px'}
                borderLeftStyle={'solid'}
                pl={'12px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <ExpoIonicIcon name="caret-down" size={'16px'} />
              </Box>
            </Box>
          )}
        </Pressable>
        <SelectItemModal
          isOpen={isOpen}
          title={title}
          options={options}
          showFilter={showFilter}
          onClose={onClose}
          getItemComponent={_getItemComponentWithSelectedCheck}
          onSubmit={onSubmit}
          keyExtractor={keyExtractor}
        />
      </>
    );
  };
