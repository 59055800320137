export default {
  // Main side bar menu
  'Learn': 'Learn',
  'Forms': 'Forms',
  'Shifts': 'Shifts',
  'Location QR': 'Location QR',
  'Sign Out': 'Sign Out',
  'Sign out': 'Sign Out',
  // Forms side bar menu
  'My Forms': 'My Forms',
  'Action Required': 'Action Required',
  'New': 'New',
  'Draft': 'Draft',
  'New Forms': 'New Forms',
  'Draft Forms': 'Draft Forms',
  'Sent Forms': 'Sent Forms',
  'Sent': 'Sent',
  'Received Forms': 'Received Forms',
  'Received': 'Received',
  'Completed': 'Completed',
  'Following': 'Following',
  'Open Issues': 'Open Issues',
  'Back': 'Back',
  'Tasks': 'Tasks',
  'Issues': 'Issues',
};
