import React, { useCallback, useEffect, useState } from 'react';
import { Input, KNOWIcon, Pressable, useDebounceCallback } from '@know/ui';
import type { GestureResponderEvent } from 'react-native';
import { IInputProps, FormControl } from 'native-base';
interface ICommonProps {
  hasFilter?: boolean;
  placeholder?: string;
}

export interface IInputFiilterProps extends ICommonProps {
  onChange?: (value: string) => void;
  filterChangeDelay?: number;
  inputRef?: any;
  inputProps?: IInputProps;
  defaultValue?: string;
}

const InputLeftElement = () => (
  <KNOWIcon
    ml={3}
    mr={1}
    color={'gray.700'}
    name="search1"
    size="xs"
    textAlign="center"
  />
);

const InputRightElement = ({
  onClearSearch,
}: {
  onClearSearch?: (event: GestureResponderEvent) => void;
}) => (
  <Pressable mx={2} onPress={onClearSearch}>
    <KNOWIcon color={'gray.900'} name="close" size="xs" textAlign="center" />
  </Pressable>
);

export function InputFilter(props: IInputFiilterProps) {
  const [value, setValue] = useState(props.defaultValue || '');

  const {
    hasFilter = false,
    placeholder = 'Search',
    onChange,
    filterChangeDelay = 1000,
    // inputRef,
    inputProps,
  } = props;

  useEffect(() => {
    setValue(props.defaultValue ?? '');
  }, [props.defaultValue]);

  const onFilterChange = useCallback(
    (searchValue: string) => onChange?.(searchValue),
    [onChange]
  );

  const debouncedOnChangeHandler = useDebounceCallback(
    onFilterChange,
    filterChangeDelay
  );

  function setText(text: string) {
    setValue(text);
    debouncedOnChangeHandler(text);
  }

  function handleOnChange(text: string) {
    setText(text);
  }

  function handleOnClearSearch() {
    setText('');
  }

  if (hasFilter === false) return null;

  return (
    <FormControl>
      <Input
        color={'gray.900'}
        value={value}
        onChangeText={handleOnChange}
        placeholder={placeholder}
        width="100%"
        borderRadius="8"
        py={3}
        px={1}
        InputLeftElement={<InputLeftElement />}
        InputRightElement={
          value ? (
            <InputRightElement onClearSearch={handleOnClearSearch} />
          ) : (
            <></>
          )
        }
        variant="filled"
        backgroundColor="gray.200"
        focusOutlineColor={'white'}
        borderWidth={1}
        borderColor="white"
        {...(inputProps ?? {})}
      />
    </FormControl>
  );
}
