import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  SetStateAction,
  useRef,
} from 'react';
import isMobile from 'is-mobile';
import UAParser from 'ua-parser-js';

export { useMediaQuery } from 'native-base';

function useModalParam<T>(
  defaultOpen: boolean = false,
  defaultParam: T | null = null
): [
  boolean,
  T | null,
  (param?: T | null) => void,
  () => void,
  (param: SetStateAction<T | null>) => void
] {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(defaultOpen);
  const [modalParam, setModalParam] = useState<T | null>(defaultParam);

  const openModal = useCallback((param: T | null = null) => {
    setModalParam(param);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setModalParam(null);
  }, []);

  const updateModalParam = useCallback((param: SetStateAction<T | null>) => {
    setModalParam(param);
  }, []);

  return [isModalOpen, modalParam, openModal, closeModal, updateModalParam];
}

function useDebounce<T>(value: T, debounceTime: number = 1000): T {
  const [debouncedValue, setDebounceValue] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceValue(value);
    }, debounceTime);

    return () => clearTimeout(timeout);
  }, [debounceTime, value]);

  return debouncedValue;
}

function useInitialRecordsCount({
  parentDivInfo,
  childDivInfo,
  noOfColumns,
}: {
  parentDivInfo: { height: null | number; width: null | number };
  childDivInfo: { height: null | number; width: null | number };
  noOfColumns: number;
}): number {
  return Math.ceil(parentDivInfo.height! / childDivInfo.height!) * noOfColumns;
}

function useIsMobile(): boolean {
  const _isMobile = useMemo(
    () => isMobile({ tablet: true, featureDetect: true }),
    []
  );
  return _isMobile;
}

function useIsOnIOSWeb(): boolean {
  const _isOnIOSWeb = useMemo(() => {
    const parser = new UAParser();
    const ua = parser.getResult();
    if (typeof ua.os === 'object' && ua.os.name) {
      if (ua.os.name === 'iOS') {
        return true;
      }
    }
    return false;
  }, []);
  return _isOnIOSWeb;
}

function useDebounceCallback(
  cb: (...args: any[]) => any,
  delay: number = 1000
) {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  return (...args: any) => {
    timeout.current !== null && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

function useDebounceSetData(
  defaultValue: any,
  cb: (...args: any[]) => any,
  delay: number = 1000,
  updateValue?: boolean
) {
  const [localItems, setLocalItems] = useState(defaultValue);

  const debouncedValue = useDebounce(localItems, delay);

  useEffect(() => {
    cb(debouncedValue);
  }, [cb, debouncedValue]);

  useEffect(() => {
    if (updateValue) {
      setLocalItems(defaultValue);
    }
  }, [defaultValue, updateValue]);

  return setLocalItems;
}

function useDebounceLoading(cb: (...args: any[]) => any, delay: number = 1000) {
  const [dataLastUpdated, setDataLastUpdated] = useState(0);

  const debouncedValue = useDebounce(dataLastUpdated, delay);

  useEffect(() => {
    if (debouncedValue) {
      cb();
    }
  }, [cb, debouncedValue]);

  return setDataLastUpdated;
}

export {
  useModalParam,
  useDebounce,
  useInitialRecordsCount,
  useIsMobile,
  useDebounceCallback,
  useIsOnIOSWeb,
  useDebounceSetData,
  useDebounceLoading,
};
