import { useCallback, useContext, useEffect, useState } from 'react';

import {
  KNOWDBContext,
  useRecoilValue,
  useSetRecoilState,
  useNavigation,
  useDebounceSetData,
  useDebounceLoading,
  useRecoilState,
  useKNOWValueContextProvider,
} from '@know/ui';

import type {
  IReceivedFormsItem,
  IReceivedResponseItem,
} from '@know/transformers';

import {
  cleanUpSubscribedReceivedForms,
  subscribeToUserReceivedForms,
} from '@know/db';

import {
  receivedListFilterTextAtom,
  receivedSectionsExpandStateAtom,
  userReceivedFormsAtom,
  userReceivedSectionDataSelectorWithToggleState,
} from '../store';

const EMPTY_FUNCTION = () => {};

export function useUserReceivedForms() {
  const navigation = useNavigation();
  const { currentDBApi } = useContext(KNOWDBContext);

  const { isTabletSize, isLargePhoneSize, isPhoneSize } =
    useKNOWValueContextProvider();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [filterText, onFilterTextChange] = useRecoilState(
    receivedListFilterTextAtom
  );
  const setUserReceivedFormAtom = useSetRecoilState(userReceivedFormsAtom);
  const setSectionsExpandState = useSetRecoilState(
    receivedSectionsExpandStateAtom
  );
  const receivedSectionData = useRecoilValue(
    userReceivedSectionDataSelectorWithToggleState
  );

  const setUserReceivedFormItems = useDebounceSetData(
    {},
    setUserReceivedFormAtom,
    500
  );

  const stopLoading = useCallback(() => {
    setIsDataLoading(false);
  }, []);

  const setDataLastUpdated = useDebounceLoading(stopLoading, 700);

  const subScriptionHandler = useCallback(
    (formId: string, sendItem: IReceivedFormsItem | null) => {
      setUserReceivedFormItems((feedItems: any) => {
        setDataLastUpdated(Date.now());
        const newItems = { ...feedItems };
        if (sendItem) {
          newItems[formId] = sendItem;
        } else {
          delete newItems[formId];
        }
        return newItems;
      });
      // To show in expanded state by default
      setSectionsExpandState((state) => {
        return {
          ...state,
          [formId]: true,
        };
      });
    },
    [setSectionsExpandState, setUserReceivedFormItems, setDataLastUpdated]
  );

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribe = subscribeToUserReceivedForms(
        currentDBApi,
        subScriptionHandler
      );
      setTimeout(() => setDataLastUpdated(Date.now()), 500);
      // Return statement has un listening callback.
      return () => {
        unSubScribe();
        cleanUpSubscribedReceivedForms();
      };
    }
    return EMPTY_FUNCTION;
  }, [currentDBApi, setDataLastUpdated, subScriptionHandler]);

  function onToggleSection(id: string) {
    setSectionsExpandState((state) => {
      return {
        ...state,
        [id]: !state[id],
      };
    });
  }

  function onClickItem(
    item: IReceivedResponseItem,
    _type: string,
    section?: any
  ) {
    if (item.type === 'hasMore') {
      return navigation.navigate(
        'Forms' as never,
        {
          screen: 'receivedFormItems',
          params: {
            formId: section.id,
          },
        } as never
      );
    }
    navigation.navigate(
      'Forms' as never,
      {
        screen: 'webview',
        params: {
          formId: section.id,
          responseId: item.id,
          initiatorId: item.userId,
          type: 'received',
        },
      } as never
    );
  }

  return {
    isDataLoading,
    filterText,
    receivedSectionData,
    isSmallScreen: isTabletSize || isLargePhoneSize || isPhoneSize,
    onToggleSection,
    onClickItem,
    onFilterTextChange,
  };
}
