import React, { useCallback } from 'react';
import type {
  AttendanceEventInterface,
  ShiftWithIdInterface,
  AttendanceEventType,
} from './state';
import { attendanceCacheState, isDoingAttendanceState } from './state';
import {
  Center,
  Box,
  HStack,
  VStack,
  Text,
  ExpoFAIcon,
  Button,
  useBreakpointValue,
  useRecoilValue,
  useRecoilState,
  useToast,
  Pressable,
} from '@know/ui';

import { get, keys } from 'lodash';
import moment from 'moment';
import { getShiftTimings } from './utils';

export const ShiftCard = ({
  item,
  openInfoModal,
  writeAttendanceEvent,
  closeInfoModal,
  openDropShiftConfim,
  openEndShiftConfim,
  dropShift,
  onClickCard,
}: {
  item: ShiftWithIdInterface;
  openInfoModal: (
    param: {
      shift: ShiftWithIdInterface;
      type: AttendanceEventType | 'dropShift';
      timestamp: number;
    } | null
  ) => void;
  closeInfoModal: () => void;
  writeAttendanceEvent: (
    item: ShiftWithIdInterface,
    type: AttendanceEventType
  ) => Promise<boolean>;
  openDropShiftConfim: (
    item: ShiftWithIdInterface,
    onConfirm: () => Promise<void>
  ) => void;
  openEndShiftConfim: (
    ciEvent: AttendanceEventInterface,
    onConfirm: () => Promise<void>
  ) => void;
  dropShift: (item: ShiftWithIdInterface) => Promise<void>;
  onClickCard: (item: ShiftWithIdInterface) => void;
}) => {
  const [isDoingAttendanceGlobal, setIsDoingAttendanceGlobal] =
    useRecoilState<boolean>(isDoingAttendanceState);

  const toast = useToast();

  const dateFontSize = useBreakpointValue({
    base: '10px',
    sm: '10px',
    md: '10px',
    lg: '12px',
  });

  const timeFontSize = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '14px',
    lg: '16px',
  });

  const detailFontSize = useBreakpointValue({
    base: '10px',
    sm: '10px',
    md: '12px',
    lg: '14px',
  });

  const cardSpacing = useBreakpointValue({
    base: '1px',
  });

  const cardMb = useBreakpointValue({
    base: '10px',
    lg: '20px',
  });

  const rowWidth = useBreakpointValue({
    base: '90%',
    sm: '90%',
    md: '80%',
    lg: '50%',
  });

  const onPressDropShift = useCallback(
    async (shift: ShiftWithIdInterface) => {
      openDropShiftConfim(shift, async () => {
        setIsDoingAttendanceGlobal(true);
        openInfoModal({ shift, type: 'dropShift', timestamp: Date.now() });
        try {
          await dropShift(shift);
        } catch (err) {
          console.log(err);
          toast.show({
            title: 'Something went wrong. Please refresh the page.',
            placement: 'bottom-left',
            ml: '20px',
          });
          closeInfoModal();
        } finally {
          setIsDoingAttendanceGlobal(false);
        }
      });
    },
    [
      openDropShiftConfim,
      setIsDoingAttendanceGlobal,
      openInfoModal,
      dropShift,
      toast,
      closeInfoModal,
    ]
  );

  const doAttendance = useCallback(
    async (shift: ShiftWithIdInterface, type: AttendanceEventType) => {
      const now = Date.now();
      openInfoModal({ shift, type, timestamp: now });
      setIsDoingAttendanceGlobal(true);
      try {
        const status = await writeAttendanceEvent(shift, type);
        if (!status) {
          closeInfoModal();
        }
      } catch (err) {
        console.log(err);
        closeInfoModal();
      } finally {
        setIsDoingAttendanceGlobal(false);
      }
    },
    [
      openInfoModal,
      writeAttendanceEvent,
      setIsDoingAttendanceGlobal,
      closeInfoModal,
    ]
  );

  const onPressEndShift = useCallback(
    async (shift: ShiftWithIdInterface, ciEvent: AttendanceEventInterface) => {
      openEndShiftConfim(ciEvent, () => doAttendance(shift, 'checkOut'));
    },
    [openEndShiftConfim, doAttendance]
  );

  return (
    <Center>
      <Pressable
        // @ts-ignore
        onPress={() => onClickCard(item)}
        width={rowWidth}
      >
        <Box
          w="100%"
          rounded="lg"
          shadow={3}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
          padding="2"
          mb={cardMb}
        >
          <HStack width="100%" justifyContent="space-between" space={5}>
            <VStack
              space={cardSpacing}
              alignItems="flex-start"
              maxWidth={item.leaveTypeId ? '100%' : '40%'}
            >
              <Text
                fontSize={dateFontSize}
                color="gray.700"
                lineHeight={timeFontSize}
              >
                {moment(item.startTime).format('ddd, MMM DD')}
              </Text>
              {item.leaveTypeId ? null : (
                <Text fontSize={timeFontSize} color="gray.900" fontWeight="500">
                  {getShiftTimings(item)}
                </Text>
              )}
              {item.leaveTypeId ? (
                <Text
                  fontSize={timeFontSize}
                  color="primary.600"
                  fontWeight={600}
                >{`${get(item, 'shiftSlot.slot.name', '-')}`}</Text>
              ) : (
                <HStack alignItems="center" maxW="100%">
                  <ExpoFAIcon
                    name="briefcase"
                    color="primary.500"
                    size={detailFontSize}
                    textAlign="center"
                    mr="5px"
                  />
                  <Text
                    isTruncated={true}
                    maxW="100%"
                    fontSize={detailFontSize}
                    color="gray.700"
                  >{`${get(item, 'shiftSlot.slot.name', '-')}`}</Text>
                </HStack>
              )}
              {item.leaveTypeId ? null : (
                <HStack alignItems="center" maxW="100%">
                  <ExpoFAIcon
                    name="map-marker"
                    color="primary.500"
                    size={detailFontSize}
                    textAlign="center"
                    mr="5px"
                  />
                  <Text
                    isTruncated={true}
                    fontSize={detailFontSize}
                    maxW="100%"
                    color="gray.700"
                  >
                    {item.location}
                  </Text>
                </HStack>
              )}
            </VStack>
            <ShiftCardAction
              item={item}
              doAttendance={doAttendance}
              dropShift={onPressDropShift}
              endShift={onPressEndShift}
              disabled={isDoingAttendanceGlobal}
            />
          </HStack>
        </Box>
      </Pressable>
    </Center>
  );
};

const ShiftCardAction = ({
  item,
  disabled,
  doAttendance,
  dropShift,
  endShift,
}: {
  item: ShiftWithIdInterface;
  doAttendance: (
    shift: ShiftWithIdInterface,
    type: AttendanceEventType
  ) => Promise<void>;
  disabled: boolean;
  dropShift: (shift: ShiftWithIdInterface) => Promise<void>;
  endShift: (
    shift: ShiftWithIdInterface,
    ciEvent: AttendanceEventInterface
  ) => Promise<void>;
}) => {
  const attendanceCache = useRecoilValue<{
    [key: string]: AttendanceEventInterface;
  } | null>(attendanceCacheState);

  const timeFontSize = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '14px',
    lg: '16px',
  });

  if (item.leaveTypeId) {
    return null;
  }

  if (item.isCheckedIn) {
    return (
      <HStack space={2} alignItems="center">
        <Button
          colorScheme="danger"
          onPress={() => {
            const { shiftId } = item;

            const ciRefKey = keys(attendanceCache).find(
              (eventId) =>
                get(attendanceCache, [eventId, 'shiftId'], '') === shiftId
            );

            if (ciRefKey) {
              endShift(item, (attendanceCache || {})[ciRefKey]);
            } else {
              console.log('No attendance cache found', shiftId);
            }
          }}
        >
          End Shift
        </Button>
      </HStack>
    );
  }
  const isPassed = item.endTime < +moment();
  const { clockin, clockout } = item;

  if (isPassed) {
    if (clockin && clockout) {
      return (
        <Center>
          <Text fontSize={timeFontSize} color="gray.900" fontWeight="500">
            {moment(clockin).format('h:mma')} -{' '}
            {moment(clockout).format('h:mma')}
          </Text>
        </Center>
      );
    } else {
      return null;
    }
  }

  if (keys(attendanceCache).length) {
    return null;
  }

  const startTime = item.startTime;

  const isToday =
    +moment(startTime).startOf('day') === +moment().startOf('day');

  if (!isToday) {
    return null;
  }

  if (clockin) {
    return (
      <HStack space={2} alignItems="center">
        <Button
          isDisabled={disabled}
          colorScheme="primary"
          onPress={() => doAttendance(item, 'checkIn')}
        >
          Start shift
        </Button>
      </HStack>
    );
  }

  return (
    <HStack space={2} alignItems="center">
      <Button
        isDisabled={disabled}
        colorScheme="primary"
        onPress={() => doAttendance(item, 'checkIn')}
      >
        Start shift
      </Button>
      <Button
        isDisabled={disabled}
        colorScheme="danger"
        onPress={() => dropShift(item)}
      >
        Drop shift
      </Button>
    </HStack>
  );
};
