import React, { useEffect, useMemo, useState } from 'react';
import { View, Image } from '@know/ui';
import { StyleSheet } from 'react-native';

const INIT_DIMENSIONS = {
  width: '100%',
  height: '100%',
};

export const HotspotQuestionView = ({
  question,
  quizResultAnswers,
  index,
  showAnswer,
  remainingAttempts,
}: any) => {
  const [isLanscapeMode, setIsLandscapeMode] = useState(true);
  const [imageDimensions, setImageDimensions] = useState(INIT_DIMENSIONS);
  const [hotspotIcons, setHotspotIcons] = useState<any>([]);
  const [imageWrapperDimensions, setImageWrapperDimensions] =
    useState(INIT_DIMENSIONS);

  const hotspotIconPosition = (htIcon: any) => {
    return {
      top: htIcon.y - 10,
      left: htIcon.x - 10,
    };
  };

  const HotspotIcon = ({
    htIcon,
    _index,
  }: {
    htIcon: { x: number; y: number };
    _index: number;
  }) => {
    return (
      <View
        key={`ht-icon-${_index}`}
        style={[styles.hotspotIcon, hotspotIconPosition(htIcon)]}
      >
        {({ hovered }: any) => {
          return (
            hovered && (
              <View
                width="125px"
                height="26px"
                borderRadius="md"
                backgroundColor="#222C2A"
                position="relative"
                bottom="-30px"
                left="-54.5px"
                display="flex"
                justifyContent="center"
                opacity={0.9}
              />
            )
          );
        }}
      </View>
    );
  };

  const aspectRatio = useMemo(() => {
    const width =
      (+imageWrapperDimensions.height * +imageDimensions.width) /
      +imageDimensions.height;
    const height =
      (+imageWrapperDimensions.width * +imageDimensions.height) /
      +imageDimensions.width;

    return {
      width: isLanscapeMode ? imageWrapperDimensions.width : width,
      height: isLanscapeMode ? height : imageWrapperDimensions.height,
    };
  }, [
    imageDimensions.height,
    imageDimensions.width,
    imageWrapperDimensions.height,
    imageWrapperDimensions.width,
    isLanscapeMode,
  ]);

  useEffect(() => {
    const initHotspotIcon = quizResultAnswers?.[index]?.r || [];
    const newWidth = aspectRatio.width;
    const newHeight = aspectRatio.height;
    const oldWidth = imageDimensions.width;
    const oldHeight = imageDimensions.height;

    let mappedHotspotIcons = initHotspotIcon.map((ht: any) => {
      return {
        x: ht?.pos?.x * (+newWidth / +oldWidth),
        y: ht?.pos?.y * (+newHeight / +oldHeight),
      };
    });
    setHotspotIcons(mappedHotspotIcons);
  }, [
    quizResultAnswers,
    aspectRatio,
    imageDimensions,
    imageWrapperDimensions.width,
    imageWrapperDimensions.height,
    index,
  ]);

  useEffect(() => {
    Image.getSize(
      question?.hotspotUrl,
      // @ts-ignore
      (width: string, height: string) => {
        setImageDimensions({ width, height });
        setIsLandscapeMode(+width > +height ? true : false);
      }
    );
  }, [question?.hotspotUrl]);

  useEffect(() => {
    if (showAnswer && remainingAttempts === 0) {
      Image.getSize(
        question?.preview,
        // @ts-ignore
        (width: string, height: string) => {
          setImageDimensions({ width, height });
          setIsLandscapeMode(+width > +height ? true : false);
        }
      );
    }
  }, [question?.preview, remainingAttempts, showAnswer]);

  const styles = StyleSheet.create({
    imageWrapper: {
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      width: aspectRatio.width,
      height: aspectRatio.height,
    },
    hotspotIcon: {
      borderRadius: 100,
      width: '20px',
      height: '20px',
      backgroundColor: 'black',
      borderColor: 'white',
      borderWidth: 2,
      position: 'absolute',
    },
  });

  return (
    <View
      flex={1}
      bgColor="white"
      justifyContent="center"
      alignItems="center"
      onLayout={(e) => {
        const { height, width }: any = e.nativeEvent.layout;
        setImageWrapperDimensions({ width, height });
      }}
    >
      <View style={styles.imageWrapper}>
        <Image
          alt="full screen image"
          width={aspectRatio.width}
          height={aspectRatio.height}
          source={{
            uri: question?.hotspotUrl,
          }}
          resizeMode="contain"
          position="relative"
        />
        {showAnswer && remainingAttempts === 0 ? (
          <Image
            alt="full screen image"
            width={aspectRatio.width}
            height={aspectRatio.height}
            source={{
              uri: question?.preview,
            }}
            resizeMode="contain"
            position="absolute"
            style={StyleSheet.absoluteFill}
          />
        ) : (
          <>
            {hotspotIcons.map((htIcon: any, _index: number) => (
              <HotspotIcon
                key={Math.random() + index}
                htIcon={htIcon}
                _index={_index}
              />
            ))}
          </>
        )}
      </View>
    </View>
  );
};
