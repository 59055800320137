import React, { forwardRef, useImperativeHandle, useState } from 'react';

import {
  Box,
  KNOWButton as Button,
  HStack,
  Modal,
  useDisclose,
  InputFilter,
  TabButton,
  UserContactListItem,
  // KNPressable,
  Text,
} from '..';

import { IShareDialogueProps } from './types';
import { useTranslation } from '@know/i18n';
import { useShareDilogueData } from './useShareDilogueData';
import { Divider, FlatList } from 'native-base';
import { Chip } from '../Chip';
import { GroupListItem } from '../GroupListItem';
import { GroupMembersWithFilter } from '../GroupMembersWithFilter';
import { NuggetClassificationType } from '@know/db';

const ItemSeparatorComponent = () => <Divider my={2} />;

export type IShareDialogueRef = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const getShareDialogTitle = (classificationType: NuggetClassificationType) => {
  switch (classificationType) {
    case 'tasks':
      return 'Share task';
    case 'tasklist':
      return 'Share Issue';
    default:
      return 'Share nugget';
  }
};

export const ShareDialogue = forwardRef<IShareDialogueRef, IShareDialogueProps>(
  ({ title, nuggetId, classificationType, authorId }, ref) => {
    const ListEmtpyMessage = ({ message }: { message: string }) => (
      <Box
        my={'24px'}
        w={'100%'}
        display={'flex'}
        flexDir={'row'}
        justifyContent={'center'}
      >
        <Text textAlign={'center'}>{message}</Text>
      </Box>
    );
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const [activeTab, setActiveTab] = useState('recent-contacts');

    const { t } = useTranslation('buttons');

    const { isOpen, onOpen, onClose: _onClose } = useDisclose(false);

    const {
      recentUsersFilterText,
      usersFilterText,
      groupsFilterText,
      recentContacts,
      users,
      groups,
      canShare,
      selectedContacts,
      selectedGroups,
      onSelectToggleRecentContact,
      onSelectToggleGroup,
      onRecentUsersFilterChange,
      onUsersFilterChange,
      onGroupFilterChange,
      onClickShareButtonHandler,
      groupToViewMembers,
      toggleGroupToViewMembers,
      currentDBApi,
      fetchGroups,
      isRecentContactsLoading,
      isUsersLoading,
      isGroupsLoading,
      clearData,
      emptyRecentUsersMessage,
      emptyUsersMessage,
      emptyGroupsMessage,
      isSharing,
    } = useShareDilogueData({
      onSubmitCB: onClose,
      nuggetId,
      classificationType,
      authorId,
    });

    // Expose isOpen, onOpen, and onClose using useImperativeHandle
    useImperativeHandle(ref, () => ({
      isOpen,
      onOpen,
      onClose,
    }));

    return (
      <>
        {/* <KNPressable flex={1} onPress={onOpen}>
          {children}
        </KNPressable> */}
        <Modal
          size={'xl'}
          isOpen={isOpen}
          onClose={onClose}
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          closeOnOverlayClick={false}
        >
          <Modal.Content>
            <Modal.CloseButton disabled={isSharing} />
            <Modal.Header borderBottomWidth="0">
              {title ?? getShareDialogTitle(classificationType)}
            </Modal.Header>
            <Modal.Body mt={0} pt={0} h={460} pb={0}>
              {renderDialogueBody()}
            </Modal.Body>
            <Modal.Footer
              borderTopWidth={0}
              display={'flex'}
              flexDirection={'column'}
              pt={'12px'}
            >
              <Button
                disabled={!canShare}
                flex={1}
                colorScheme={'primary'}
                isDisabled={!canShare}
                onPress={onClickShareButtonHandler}
                isLoading={isSharing}
              >
                {t('Share')}
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </>
    );

    function renderDialogueBody() {
      if (groupToViewMembers) {
        return (
          <>
            <GroupMembersWithFilter
              currentDBApi={currentDBApi}
              groupId={groupToViewMembers.id}
              groupName={groupToViewMembers.name}
              onGoBack={() => toggleGroupToViewMembers(groupToViewMembers)}
            />
          </>
        );
      }
      return (
        <>
          <HStack flexWrap={'wrap'} maxH={'100px'} overflow={'auto'}>
            {selectedContacts.map((contact) => (
              <Chip
                key={contact.id}
                avatar={contact.avatar}
                label={
                  contact.alias || `${contact.firstName} ${contact.lastName}`
                }
                onRemove={() => onSelectToggleRecentContact(contact)}
              />
            ))}
            {selectedGroups.map((group) => (
              <Chip
                key={group.id}
                avatar={group.avatar}
                label={`${group.name} (${group.members})`}
                onRemove={() => onSelectToggleGroup(group)}
              />
            ))}
          </HStack>
          <HStack justifyContent={'stretch'} mt={2}>
            <TabButton
              isLoading={isRecentContactsLoading}
              id="recent-contacts"
              title={t('Recent Contacts')}
              isActive={activeTab === 'recent-contacts'}
              onPress={setActiveTab}
            />
            <TabButton
              isLoading={isUsersLoading}
              id="users"
              title={t('Users')}
              isActive={activeTab === 'users'}
              onPress={setActiveTab}
            />
            <TabButton
              isLoading={isGroupsLoading}
              id="groups"
              title={t('Groups')}
              isActive={activeTab === 'groups'}
              onPress={(tab) => {
                fetchGroups();
                setActiveTab(tab);
              }}
            />
          </HStack>
          {activeTab === 'recent-contacts' && renderRecentContactsTabContent()}
          {activeTab === 'users' && renderUsersTabContent()}
          {activeTab === 'groups' && renderGroupsTabContent()}
        </>
      );
    }

    function renderRecentContactsTabContent() {
      return (
        <>
          <Box py={3}>
            <InputFilter
              defaultValue={recentUsersFilterText}
              hasFilter={true}
              filterChangeDelay={1000}
              inputRef={initialRef}
              onChange={onRecentUsersFilterChange}
              inputProps={{ fontSize: 'sm' }}
            />
          </Box>
          <FlatList
            ItemSeparatorComponent={ItemSeparatorComponent}
            flex={1}
            data={recentContacts}
            ListEmptyComponent={
              <ListEmtpyMessage message={emptyRecentUsersMessage} />
            }
            renderItem={({ item }: any) => (
              <UserContactListItem
                user={item}
                onToggle={onSelectToggleRecentContact}
              />
            )}
          />
        </>
      );
    }

    function renderUsersTabContent() {
      console.log(usersFilterText, users, 'filter text and adta')
      return (
        <>
          <Box py={3}>
            <InputFilter
              defaultValue={usersFilterText}
              hasFilter={true}
              filterChangeDelay={1000}
              inputRef={initialRef}
              onChange={onUsersFilterChange}
              inputProps={{ fontSize: 'sm' }}
            />
          </Box>
          <FlatList
            ItemSeparatorComponent={ItemSeparatorComponent}
            flex={1}
            data={usersFilterText.length ? users : []}
            ListEmptyComponent={
              <ListEmtpyMessage message={emptyUsersMessage} />
            }
            renderItem={({ item }: any) => (
              <UserContactListItem
                user={item}
                onToggle={onSelectToggleRecentContact}
              />
            )}
          />
        </>
      );
    }

    function renderGroupsTabContent() {
      return (
        <>
          <Box py={3}>
            <InputFilter
              defaultValue={groupsFilterText}
              hasFilter={true}
              filterChangeDelay={1000}
              inputRef={initialRef}
              onChange={onGroupFilterChange}
              inputProps={{ fontSize: 'sm' }}
            />
          </Box>
          <FlatList
            ItemSeparatorComponent={ItemSeparatorComponent}
            flex={1}
            data={groups}
            ListEmptyComponent={
              <ListEmtpyMessage message={emptyGroupsMessage} />
            }
            renderItem={({ item }: any) => (
              <GroupListItem
                group={item}
                onToggle={onSelectToggleGroup}
                onPressViewMembers={toggleGroupToViewMembers}
              />
            )}
          />
        </>
      );
    }
    function onClose() {
      setActiveTab('recent-contacts');
      _onClose();
      clearData();
    }
  }
);
