import React, { Fragment } from 'react';
import { Image, View, Text, Button } from '@know/ui';
import Images from '../../Images';

export const LessonCompleted = ({
  handleNextLesson,
  handleCoursePreview,
  hideNextLessonBtn,
}: {
  hideNextLessonBtn: boolean;
  handleNextLesson: () => void;
  handleCoursePreview: () => void;
}) => {
  return (
    <View flex={1} bgColor="white" justifyContent="center" alignItems="center">
      <Image
        resizeMode="contain"
        alt={
          hideNextLessonBtn
            ? 'Course completed banner'
            : 'Lesson completed banner'
        }
        source={
          hideNextLessonBtn
            ? { uri: Images.completedCourse }
            : { uri: Images.completedLesson }
        }
        w={['250px', '300px', '300px', '300px', '300px']}
        h={['250px', '300px', '300px', '300px', '300px']}
        mb="12px"
      />
      <Text
        fontSize={['20px', '24px', '32px', '32px', '32px']}
        fontWeight="semibold"
      >
        Congratulations!
      </Text>
      <Text
        marginTop="4px"
        marginBottom="16px"
        textAlign="center"
        fontSize={['14px', '16px', '16px', '16px', '16px']}
        color="gray.400"
      >
        You’ve successfully completed this{' '}
        {hideNextLessonBtn ? 'course' : 'lesson'}!
      </Text>
      <View flexDirection={['column', 'column', 'row', 'row', 'row']}>
        {!hideNextLessonBtn && (
          <Fragment>
            <Button
              onPress={handleNextLesson}
              size={'sm'}
              colorScheme={'primary'}
              minWidth="257px"
              borderRadius="8px"
            >
              <Text fontWeight="semibold" fontSize="16px" color="white">
                Next Lesson
              </Text>
            </Button>
            <View
              w={['0px', '0px', '15px', '15px', '15px']}
              h={['15px', '15px', '0px', '0px', '0px']}
            />
          </Fragment>
        )}
        <Button
          variant={hideNextLessonBtn ? 'solid' : 'outline'}
          colorScheme={hideNextLessonBtn ? 'primary' : 'secondary.500'}
          borderColor="primary.500"
          onPress={handleCoursePreview}
          width={'257px'}
          borderRadius="8px"
        >
          <Text
            fontWeight="semibold"
            fontSize="16px"
            color={hideNextLessonBtn ? 'white' : 'primary.500'}
          >
            Return to Course Overview
          </Text>
        </Button>
      </View>
    </View>
  );
};
