import {
  TaskFeedItem,
  TransformedIssueItem,
  prepareIssueFeed,
} from '@know/transformers';
import { selector, atom } from '@know/ui';
import { globalMutedIssueAtom } from '../state';

export const openIssueFilterTextAtom = atom<string | null>({
  key: 'openIssueFilterTextAtom',
  default: null,
});

export const openIssueAtom = atom<Record<string, TaskFeedItem>>({
  key: 'openIssueAtom',
  default: {},
});

export const openIssueDateRangeAtom = atom<[Date | null, Date | null]>({
  key: 'openIssueDateRangeAtom',
  default: [null, null],
});

export const openIssueLocationAtom = atom<string | null>({
  key: 'openIssueLocationAtom',
  default: null,
});

export const openIssuesItemsSelector = selector<TransformedIssueItem[]>({
  key: 'openIssuesItemsSelector',
  get: ({ get }) => {
    const openIssueFeed = get(openIssueAtom);
    const filterText = get(openIssueFilterTextAtom);
    const mutedIssues = get(globalMutedIssueAtom);

    return prepareIssueFeed(
      openIssueFeed,
      filterText,
      mutedIssues,
      'dateReported',
      'desc'
    );
  },
});
