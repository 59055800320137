import React, { useCallback } from 'react';
import { ErrorAlert, SuccessAlert } from '../Alerts';
import { useToast } from '@know/ui';

export const useKNOWToast = () => {
  const toast = useToast();

  const successToast = useCallback(
    ({ header, subText }: { header: string; subText?: string }) => {
      const toastId = Date.now();
      toast.show({
        id: toastId,
        render: () => (
          <SuccessAlert
            header={header}
            subText={subText}
            onClose={() => toast.close(toastId)}
            containerProps={{ mt: '-30px', w: 'calc(100vw - 24px)' }}
          />
        ),
        placement: 'top',
      });
    },
    [toast]
  );

  const errorToast = useCallback(
    ({ header, subText }: { header: string; subText?: string }) => {
      const toastId = Date.now();
      toast.show({
        id: toastId,
        render: () => (
          <ErrorAlert
            header={header}
            subText={subText}
            onClose={() => toast.close(toastId)}
            containerProps={{ mt: '-30px', w: 'calc(100vw - 24px)' }}
          />
        ),
        placement: 'top',
      });
    },
    [toast]
  );

  return { successToast, errorToast };
};
