import { DataSnapshot } from "firebase/database";
import { FirebaseAPI, raiseNuggetAnalytics } from "..";
import { IssueUpdateType } from "./types";
import { SeverityType } from "..";
import pLimit from "promise-limit";
import { map, uniq } from "lodash";
import { UploadFileResultType } from "..";
import { IssueNugget } from "@know/transformers";
import { capitalize } from 'lodash';

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";


function updateIssueNuggetStatus(
  fbAPI: FirebaseAPI,
  issueId: string,
  status: "open" | "Closed",
  options?: { userDetails?: any; remark?: string }
) {
  const issueNuggetStatusNodePath = `${fbAPI.getCommonPath(
    "nuggets"
  )}/tasklist/${issueId}`;
  let update: any = {
    status
  };

  if (status === "Closed") {
    update = {
      ...update,
      resolvedBy: fbAPI.getLoggedInUserId(),
      resolvedAt: fbAPI.getServerTimestamp(),
      resolvedByUserName: options?.userDetails?.userName ?? "-",
      resolvedByUserDepartment: options?.userDetails?.department ?? "-",
      resolvedByUserDesignation: options?.userDetails?.designation ?? "-",
      comment: options?.remark ?? "-",
    };
  } else if (status === "open") {
    update = {
      ...update,
      reopenedBy: fbAPI.getLoggedInUserId(),
      reopenedAt: fbAPI.getServerTimestamp(),
      reopenedByUserName: options?.userDetails?.userName ?? "-",
      reopenedByUserDepartment: options?.userDetails?.department ?? "-",
      reopenedByUserDesignation: options?.userDetails?.designation ?? "-",
      reopenedComment: options?.remark ?? "-",
    };
  }

  return fbAPI.fbUpdate(issueNuggetStatusNodePath, update);
}

export function subscribeToIssueUpdates(
  fbAPI: FirebaseAPI,
  issueId: string,
  cb: (issueUpdateId: string, issueUpdateItem: IssueUpdateType | null) => void
) {
  const userFeedRef = fbAPI.getNodeRef(
    fbAPI.getMultiPath(`issues/progress/${issueId}/updates`)
  );

  function listenerHandler(_listenerEvenType: string) {
    return async (issueUpdateSnapshot: DataSnapshot) => {
      const issueUpdate: IssueUpdateType = issueUpdateSnapshot.val() || null;
      const id = issueUpdateSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        return cb(id, null);
      }
      
      if (issueUpdate) {
        cb(id, issueUpdate);
      } else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userFeedRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userFeedRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userFeedRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export async function getIssueUsers(fbAPI: FirebaseAPI, issueId: string) {
  const path = fbAPI.getMultiPath(`issues/shareLogs/${issueId}`);

  const issueShareLogs = await fbAPI.getValue(fbAPI.getNodeRef(path));

  const taskUserIds = uniq(map(issueShareLogs, "userId"));

  const limit = pLimit<any>(20);

  return Promise.all(
    taskUserIds.map((userId) => limit(() => fbAPI.getUserDetails(userId)))
  );
}

export async function updateIssueSeverity(fbAPI: FirebaseAPI, issueId: string, severity: SeverityType) {
  const issueNuggetSeverityNodePath = `${fbAPI.getCommonPath(
    "nuggets"
  )}/tasklist/${issueId}`;

  const update = {
    severity: capitalize(severity)
  }

  return fbAPI.fbUpdate(issueNuggetSeverityNodePath, update);
}

export function raiseIssueStatusChangeRequest(
  fbAPI: FirebaseAPI,
  issueId: string,
  status: "updated" | "reopened" | "completed" | "notifyLeaders",
  userName: string
) {
  const statusChangeRequestsNodePath = "statusChangeRequests";
  const payload: any = {
    type: "issue",
    status,
    userId: fbAPI.getLoggedInUserId(),
    nuggetId: issueId,
    userName,
    organization: fbAPI.organization,
  };

  return fbAPI.pushToNode(statusChangeRequestsNodePath, payload);
}

export function addIssueDetailsProgressUpdate(
  fbAPI: FirebaseAPI,
  issueId: string,
  options: { location: string; userName: string, title: string }
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/updates`
  );
  const payload: any = {
    name: options?.userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "details",
    payload: [
      {
        location: options.location ?? null,
        title: options.title ?? null,
      },
    ],
    userId: fbAPI.getLoggedInUserId(),
  };

  return fbAPI.pushToNode(issueProgressStatusPath, payload);
}

export function addIssueFormProgressUpdate(
  fbAPI: FirebaseAPI,
  issueId: string,
  type: 'create_form' | 'close_form',
  options: { formId: string, responseId: string, title: string, userName: string }
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/updates`
  );
  const payload: any = {
    name: options?.userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type,
    payload: [
      {
        formId: options.formId,
        responseId: options.responseId,
        title: options.title,
      }
    ],
    userId: fbAPI.getLoggedInUserId(),
  };

  return fbAPI.pushToNode(issueProgressStatusPath, payload);
}

export function addIssueProgressRemark(
  fbAPI: FirebaseAPI,
  issueId: string,
  text: string,
  userName: string,
  cacheCB?: (updateId: string, taskUpdateItem: IssueUpdateType | null) => any
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/updates`
  );
  const payload: any = {
    name: userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "remark",
    userId: fbAPI.getLoggedInUserId(),
    payload: [
      {
        type: "text",
        value: text,
      },
    ],
  };

  const updateRef = fbAPI.pushToNode(issueProgressStatusPath);

  const updateId = updateRef.key;

  if (updateId) {
    cacheCB?.(updateId, {
      ...payload,
      timestamp: Date.now(),
      isSending: true,
    });
  }

  return fbAPI.set(updateRef, payload);
}

export function addIssueStatusProgressAttachment(
  fbAPI: FirebaseAPI,
  issueId: string,
  type: "image" | "video" | "audio" | "pdf",
  files: UploadFileResultType[],
  userName: string,
  cacheCB?: (updateId: string, issueUpdateItem: IssueUpdateType | null) => any
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/updates`
  );
  const payload: any = {
    name: userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: type,
    userId: fbAPI.getLoggedInUserId(),
    payload: files.map((f) => ({
      ...f,
      type,
      pdfTitle: type === "pdf" ? f.filename : null,
    })),
  };

  const updateRef = fbAPI.pushToNode(issueProgressStatusPath);

  const updateId = updateRef.key;

  if (updateId) {
    cacheCB?.(updateId, {
      ...payload,
      timestamp: Date.now(),
      isSending: true,
    });
  }
  return fbAPI.set(updateRef, payload);
}

export function addIssueSeverityUpdate(
  fbAPI: FirebaseAPI,
  issueId: string,
  severity: SeverityType,
  severityColor: string,
  userName: string,
  cacheCB?: (updateId: string, issueUpdateItem: IssueUpdateType | null) => any
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/updates`
  );
  const payload: any = {
    name: userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "severity",
    userId: fbAPI.getLoggedInUserId(),
    payload: [{
      color: severityColor,
      type: 'text',
      value: severity
    }]
  };

  const updateRef = fbAPI.pushToNode(issueProgressStatusPath);

  const updateId = updateRef.key;

  if (updateId) {
    cacheCB?.(updateId, {
      ...payload,
      timestamp: Date.now(),
      isSending: true,
    });
  }
  return fbAPI.set(updateRef, payload);
}

function updateIssueProgressStatus(
  fbAPI: FirebaseAPI,
  issueId: string,
  status: "open" | "Closed",
  remark?: string
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(`issues/progress/${issueId}`);
  const payload: any = { issue_status: status };
  if (remark) {
    payload.issue_remark = remark;
  }

  return fbAPI.fbUpdate(issueProgressStatusPath, payload);
}

function addIssueStatusProgressUpdate(
  fbAPI: FirebaseAPI,
  issueId: string,
  status: "reopened" | "completed",
  options: { remark?: string; userName: string }
) {
  const issueProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/updates`
  );
  const payload: any = {
    name: options?.userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "status",
    userId: fbAPI.getLoggedInUserId(),
    payload: [
      {
        type: "text",
        value: status,
        remark: options?.remark ?? null,
      },
    ],
  };

  return fbAPI.pushToNode(issueProgressStatusPath, payload);
}

const addIssueToClosedList = (
  fbAPI: FirebaseAPI,
  issueId: string,
  nugget: IssueNugget
) => {
  const update = {
    author: nugget.author ?? "-",
    classificationType: "tasklist",
    createdAt: nugget.createdAt ?? fbAPI.getServerTimestamp(),
    refreshedAt: fbAPI.getServerTimestamp(),
    consumedAt: fbAPI.getServerTimestamp(),
  };
  const completedTaskPath = `${fbAPI.getMultiPath(
    `completedTasks/${fbAPI.getLoggedInUserId()}/${issueId}`
  )}`;

  return fbAPI.fbSet(completedTaskPath, update);
};

function updateIssueProgressUserStatus(
  fbAPI: FirebaseAPI,
  issueId: string,
  status: "reopened" | "completed",
  options: { remark?: string; userName: string }
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `issues/progress/${issueId}/user_status/${fbAPI.getLoggedInUserId()}`
  );
  const payload: any = {
    name: options?.userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    status,
    remark: options.remark ?? null,
  };

  return fbAPI.fbUpdate(taskProgressStatusPath, payload);
}

const removeIssueFromFeed = (fbAPI: FirebaseAPI, issueId: string) => {
  const userFeedTaskPath = `${fbAPI.getCommonPath(
    "currentUserFeed"
  )}/tasklist/${issueId}`;

  return fbAPI.fbSet(userFeedTaskPath, null);
};

export async function closeIssue(
  fbAPI: FirebaseAPI,
  issueId: string,
  userDetails: any,
  nugget: IssueNugget,
  { remark, image }: { remark?: string; image?: UploadFileResultType }
) {
  const userName = fbAPI.getUserFullNameOrAlias(userDetails) || "-";

  await Promise.all([
    updateIssueProgressStatus(fbAPI, issueId, "Closed", remark),
    addIssueStatusProgressUpdate(fbAPI, issueId, "completed", {
      userName,
      remark,
    }),
    updateIssueProgressUserStatus(fbAPI, issueId, "completed", {
      userName,
      remark,
    }),

    addIssueToClosedList(fbAPI, issueId, nugget),
    removeIssueFromFeed(fbAPI, issueId),
    raiseNuggetAnalytics({
      fbAPI,
      type: "consumed",
      classificationType: "tasklist",
      nuggetId: issueId,
    }),
    image
      ? addIssueStatusProgressAttachment(
          fbAPI,
          issueId,
          "image",
          [image],
          userName
        )
      : null,
  ]);

  await updateIssueNuggetStatus(fbAPI, issueId, "Closed", {
    userDetails: {
      ...userDetails,
      userName,
    },
    remark,
  });

  await raiseIssueStatusChangeRequest(fbAPI, issueId, "completed", userName)
}

export async function reopenIssue(
  fbAPI: FirebaseAPI,
  issueId: string,
  userDetails: any,
  { remark, image }: { remark: string; image?: UploadFileResultType }
) {
  const userName = fbAPI.getUserFullNameOrAlias(userDetails) || "-";

  await Promise.all([
    updateIssueProgressStatus(fbAPI, issueId, "open", remark),
    addIssueStatusProgressUpdate(fbAPI, issueId, "reopened", {
      userName,
      remark,
    }),
    updateIssueProgressUserStatus(fbAPI, issueId, "reopened", {
      userName,
      remark,
    }),
    image
      ? addIssueStatusProgressAttachment(
          fbAPI,
          issueId,
          "image",
          [image],
          userName
        )
      : null,
  ]);

  await updateIssueNuggetStatus(fbAPI, issueId, "open", {
    userDetails: {
      ...userDetails,
      userName,
    },
    remark,
  });

  await raiseIssueStatusChangeRequest(fbAPI, issueId, "reopened", userName)
}

export const toggleIssueMuteStatus = async (fbAPI: FirebaseAPI, issueId: string, successToast: ({ header }: { header: string }) => void) => {
  const pathRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("mutedIssues")),
    `${issueId}`
  );
  
  const issueIsMuted = await fbAPI.getValue(pathRef)
  const valueToSet = issueIsMuted ? null : fbAPI.getServerTimestamp()

  await fbAPI.set(pathRef, valueToSet);

  if (issueIsMuted) {
    successToast({ header: "You have unmuted this issue!" })
  } else {
    successToast({ header: "You have muted this issue!" })
  }
};

export const getIssueCustomForms = async (fbAPI: FirebaseAPI, incidentId: string) => {
  const path = fbAPI.getMultiPath(`config/issues/types/${incidentId}/customForms`);
  
  return await fbAPI.getValue(fbAPI.getNodeRef(path));
}