import { selector } from '@know/ui';

import {
  courseLibrarySectionsAtom,
  courseLibrarySectionsItemsAtom,
  libraryFeedItemsAtom,
  libraryFilterTextAtom,
} from './atoms';

import {
  PreparedLibrarySection,
  prepareCourseLibraryFeed,
} from '@know/transformers';

export const courseLibrarySectionItemsSelector = selector<
  PreparedLibrarySection[]
>({
  key: 'users.courseLibrary.list',
  get: ({ get }: any) => {
    const feedItems = get(libraryFeedItemsAtom);
    const sectionItems = get(courseLibrarySectionsItemsAtom);
    const sections = get(courseLibrarySectionsAtom);

    return prepareCourseLibraryFeed({
      feedItems,
      sectionItems,
      sections,
    });
  },
});

export const courseLibrarySectionItemsWithFilterSelector = selector<
  PreparedLibrarySection[]
>({
  key: 'users.courseLibrary.filteredList',
  get: ({ get }: any) => {
    const filterText = get(libraryFilterTextAtom);
    const preparedData = get(courseLibrarySectionItemsSelector);
    // TODO
    console.log(filterText);
    return preparedData;
  },
});
