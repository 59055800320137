import React from 'react';
import { View, Text, ScrollView, Image } from '@know/ui';
import { StyleSheet } from 'react-native';
import Images from '../../../Images';

export const ContentNotSupported: React.FC<{}> = () => {
  const styles = StyleSheet.create({
    contentContainer: {
      justifyContent: 'center',
      height: '100%',
      padding: '15px',
    },
  });

  return (
    <ScrollView flex={1} contentContainerStyle={styles.contentContainer}>
      <View
        fontSize="2xl"
        fontWeight="600"
        padding="16px"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image
          src={Images.ContentNotSupported}
          alt="ContentNotSupported"
          height="50px"
          width="50px"
        />
        <Text color="gray.900" fontSize="20px" fontWeight="600">
          This content type is not supported.
        </Text>
        <Text color="gray.700" fontSize="16px">
          Please proceed to the next card.
        </Text>
      </View>
    </ScrollView>
  );
};
