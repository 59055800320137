import moment from 'moment';
import type { ShiftInterface } from './state';

export const getShiftDatesArray = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const shiftDates = [];
  let iDate = moment(startDate);

  while (iDate <= endDate) {
    shiftDates.push(iDate.format('YYYYMMDD'));
    iDate.add(1, 'day');
  }

  return shiftDates;
};

export const getShiftTimings = (
  shift: ShiftInterface | { startTime: number; endTime: number }
): string => {
  return `${moment(shift.startTime).format('h:mma')} - ${moment(
    shift.endTime
  ).format('h:mma')}`;
};

export const getShiftDuration = (shift: ShiftInterface): string => {
  const { startTime, endTime } = shift;
  const hour = Math.floor((endTime - startTime) / (1000 * 60 * 60));
  const minute = Math.floor(
    (endTime - startTime - hour * 1000 * 60 * 60) / (1000 * 60)
  );

  return `${hour ? `${hour} hour${hour > 1 ? 's' : ''}` : ''} ${
    minute ? `${minute} minute${minute > 1 ? 's' : ''}` : ''
  }`.trim();
};
