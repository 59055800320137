import React from 'react';
import { View, Text, Image, Button, Popover } from '@know/ui';
import image from '../../Images';

export const LJPopover = ({
  resumeButtonTooltip,
  isOpen,
  buttonText,
  onOpen,
}: any) => {
  return (
    <Popover
      isOpen={isOpen}
      trigger={(triggerProps: any) => {
        return (
          <Button
            small
            primary
            onPress={onOpen}
            opacity={resumeButtonTooltip ? 0.5 : 1}
            {...triggerProps}
          >
            <View
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                width="12px"
                height="12px"
                marginRight="5px"
                source={image.WhiteLockIcon}
              />
              <Text fontWeight="semibold" color="white">
                {buttonText}
              </Text>
            </View>
          </Button>
        );
      }}
    >
      <Popover.Content borderColor="white">
        <Popover.Arrow />
        <View
          borderRadius="6px"
          borderColor="gray.300"
          borderWidth="1px"
          bgColor="white"
          maxWidth="350px"
        >
          <View paddingX="18px" paddingY="12px">
            <Text color="gray.900" fontSize="16px" fontWeight="semibold">
              {resumeButtonTooltip?.title}
            </Text>
            <Text color="gray.700" fontSize="14px">
              {resumeButtonTooltip?.description}
            </Text>
          </View>
        </View>
      </Popover.Content>
    </Popover>
  );
};
