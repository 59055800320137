import { DataSnapshot } from "firebase/database";
import { TaskFeedItem, TaskNugget } from "@know/transformers";
import { FirebaseAPI, listenToTaskNugget } from "..";
import { FBTaskFeedItem } from "./types";

export function listenToCompletedTaskNugget(
  fbAPI: FirebaseAPI,
  nuggetId: string,
  cb: (data: TaskNugget) => any
) {
  const pathRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("nuggets")),
    `tasks/${nuggetId}`
  );

  const handler = (snap: DataSnapshot) => {
    const taskNugget = snap.val() ?? {};
    cb(taskNugget);
  };
  return fbAPI.listenOnNodeRef(pathRef, "value", handler);
}

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: {
  [id: string]: () => void;
} = {};

export function subscribeToUserCompletedTasks(
  fbAPI: FirebaseAPI,
  cb: (taskId: string, feedItem: TaskFeedItem | null) => void
) {
  const userFeedRef = fbAPI.getNodeRef(fbAPI.getCommonPath("completedTasks"));

  function listenerHandler(_listenerEvenType: string) {
    return async (userFeedSnapshot: DataSnapshot) => {
      const taskFeed: FBTaskFeedItem = (await userFeedSnapshot.val()) || null;
      const id = userFeedSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        cleanups[id]?.();
        return cb(id, null);
      }
      if (taskFeed?.createdAt) {
        const author = await fbAPI.getUserDetails(taskFeed.author);
        cleanups[id]?.();
        cleanups[id] = listenToTaskNugget(fbAPI, id, (nugget) => {
          if (nugget) {
            cb(id, {
              ...taskFeed,
              author,
              nugget,
              id,
            });
          } else {
            cb(id, null);
          }
        });
      }
      else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userFeedRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userFeedRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userFeedRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribeCompletedTasksNuggets = () => {
  for (const c of Object.keys(cleanups)) {
    cleanups[c]?.();
  }
  cleanups = {};
};

export const markCompletedTaskRead = async (
  fbAPI: FirebaseAPI,
  nuggetId: string
) => {
  const userFeedPath = `${fbAPI.getCommonPath("completedTasks")}/${nuggetId}`;
  await fbAPI.fbUpdate(userFeedPath, {
    consumedAt: fbAPI.getServerTimestamp(),
  });
};
