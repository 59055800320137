import { useMediaQuery } from 'native-base';
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import {
  IShareDialogueRef,
  ShareDialogue,
  useIsOnIOSWeb,
  useKNOWToast,
} from '..';
import { NuggetClassificationType } from '@know/db';
interface AppContextInterface {
  tabHeaderHeight: number;
  setTabHeaderHeight?: React.Dispatch<React.SetStateAction<number>>;
  isPhoneSize: boolean;
  isLargePhoneSize: boolean;
  isTabletSize: boolean;
  isLargeTabletSize: boolean;
  isDesktopSize: boolean;
  isLargeDesktopSize: boolean;
  isIOSOnWeb: boolean;
  errorToast: (prop: { header: string; subText?: string }) => void;
  successToast: (prop: { header: string; subText?: string }) => void;
  openShareDialog: (
    classificationType: NuggetClassificationType,
    nuggetId: string,
    authorId: string
  ) => void;
  closeShareDialog: () => void;
}

export const KNOWValueContext = createContext<AppContextInterface>({
  tabHeaderHeight: 0,
  isPhoneSize: false,
  isLargePhoneSize: false,
  isTabletSize: false,
  isLargeTabletSize: false,
  isDesktopSize: false,
  isLargeDesktopSize: false,
  isIOSOnWeb: false,
  errorToast: () => {},
  successToast: () => {},
  openShareDialog: () => {},
  closeShareDialog: () => {},
});

const KNOWValueContextProvider = KNOWValueContext.Provider;

export const useKNOWValueContextProvider = () => useContext(KNOWValueContext);

export const KNOWValueProvider: React.FC = ({ children }) => {
  const [tabHeaderHeight, setTabHeaderHeight] = useState<number>(0);
  const [
    isPhoneSize,
    isLargePhoneSize,
    isTabletSize,
    isLargeTabletSize,
    isDesktopSize,
    isLargeDesktopSize,
  ] = useMediaQuery([
    {
      maxWidth: 413,
    },
    {
      minWidth: 414,
      maxWidth: 767,
    },
    {
      minWidth: 768,
      maxWidth: 1023,
    },
    {
      minWidth: 1024,
      maxWidth: 1439,
    },
    {
      minWidth: 1440,
    },
    {
      minWidth: 1921,
    },
  ]);

  const isIOSOnWeb = useIsOnIOSWeb();

  const { errorToast, successToast } = useKNOWToast();

  const shareDialogRef = useRef<IShareDialogueRef | null>(null);
  const [shareDialogProps, setShareDialogProps] = useState<{
    classificationType: NuggetClassificationType;
    nuggetId: string;
    authorId: string;
  }>({ classificationType: 'general', nuggetId: '-', authorId: '' });

  const openShareDialog = useCallback(
    (
      classificationType: NuggetClassificationType,
      nuggetId: string,
      authorId: string
    ) => {
      setShareDialogProps({
        classificationType,
        nuggetId,
        authorId,
      });
      shareDialogRef?.current?.onOpen();
    },
    []
  );

  const closeShareDialog = useCallback(() => {
    shareDialogRef?.current?.onClose();
  }, []);

  return (
    <KNOWValueContextProvider
      value={{
        tabHeaderHeight,
        setTabHeaderHeight,
        isPhoneSize,
        isLargePhoneSize,
        isTabletSize,
        isLargeTabletSize,
        isDesktopSize,
        isLargeDesktopSize,
        isIOSOnWeb,
        errorToast,
        successToast,
        openShareDialog,
        closeShareDialog,
      }}
    >
      {children}
      <ShareDialogue ref={shareDialogRef} {...shareDialogProps} />
    </KNOWValueContextProvider>
  );
};
