export const getFileFromBase64 = async (
  base64: string,
  type: string,
  fileName?: string
) => {
  const fetchRes = await fetch(base64);
  const blob = await fetchRes.blob();
  // eslint-disable-next-line no-undef
  const file = new File([blob], fileName ?? `file-${Date.now()}.png`, { type });
  return file;
};

export const checkForFileValidity = (
  files: File[],
  allowTypes: string[],
  sizeLimit?: number
) => {
  return files.forEach((file) => {
    if (sizeLimit && file.size > sizeLimit) {
      throw new Error('FileTooBig');
    }
    const fileType = file.type;
    for (const type of allowTypes) {
      if (type === '*') {
        return;
      } else if (type.endsWith('/*')) {
        const generalType = type.toLowerCase().split('/')[0];
        if (!fileType.toLowerCase().match(`${generalType}.*`)) {
          throw new Error('InvalidType');
        }
      } else if (fileType.toLowerCase() !== type.toLowerCase()) {
        throw new Error('InvalidType');
      }
    }
  });
};
