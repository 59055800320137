import React from 'react';

import { KNOWRouterContenxt, IModuleProps } from '@know/ui';
import { IssuesNavigator, linking } from './IssuesNavigator';

export const IssuesScreen = () => {
  const { registerScreen } = React.useContext(KNOWRouterContenxt);

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'issuelist',
      name: 'Issues',
      priority: 1,
      component: IssuesNavigator,
      icon: 'warning',
      linking,
    };
    registerScreen(module);
  }, [registerScreen]);

  return null;
};
