import { DataSnapshot, EventType } from "firebase/database";
import { FirebaseAPI } from "../FirebaseAPI";
import { IFormActionOnItem } from "../types";

export const subscribeToUserActionRequired = (
  dbApi: FirebaseAPI,
  cb: (feedId: string, feedItem: IFormActionOnItem | null) => void
) => {
  const userActionRequiredRef = dbApi.getNodeRef(
    `${dbApi.getCommonPath("currentUserFormLookups")}/actionOn`
  );

  const listenerHandler = (type: EventType) => async (feedItemSnapShot: DataSnapshot) => {
    const feed: IFormActionOnItem | null =
      (await feedItemSnapShot.val()) || null;
    const id = feedItemSnapShot.key;
    if (!id){
      return
    }
    if (type === 'child_removed'){
      return cb(id, null);
    }
    else if (feed && id && feed.remindedAt && feed.completeBy) {
      const { lastFilled = 0, remindedAt, completeBy } = feed;
      const now = Date.now();

      if (
        !(lastFilled >= remindedAt && lastFilled <= completeBy) &&
        now <= completeBy
      ) {
        return cb(id, feed);
      }

      return cb(id, null);
    } else if (id) {
      return cb(id, null);
    }
  };

  const unSubCC = dbApi.listenOnNodeRef(
    userActionRequiredRef,
    "child_changed",
    listenerHandler("child_changed")
  );
  const unSubCA = dbApi.listenOnNodeRef(
    userActionRequiredRef,
    "child_added",
    listenerHandler("child_added")
  );
  const unSubCR = dbApi.listenOnNodeRef(
    userActionRequiredRef,
    "child_removed",
    listenerHandler("child_removed")
  );

  return () => {
    unSubCC();
    unSubCA();
    unSubCR();
  };
};
