import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Text,
  Divider,
  DrawerToggleButton,
  ReportButtonPlusIcon,
  KNOWAppDownloadView,
  createSideBarNavigator,
  useNavigation,
  useKNOWDBContext,
  useSetRecoilState,
  KNOWButton,
  StackActions,
} from '@know/ui';
import OpenIssuesFeed from './OpenIssuesPage';
import { CompletedIssuesPage } from './CompletedIssuesPage';
import { issueLastOpenTabAtom, issueRouteAtom } from './state';
import FollowingIssuesPage from './FollowingIssuesPage';
import SentIssuesPage from './SentIssuesPage';

const IssuesSideBarNavigator = createSideBarNavigator();

export const linking = {
  initialRouteName: 'issue-feed',
  screens: {
    'issue-feed': {
      path: 'issues/list/feed',
    },
    'issue-completed': {
      path: 'issues/list/completed',
    },
    'issue-following-feed': {
      path: 'issues/list/following',
    },
    'issue-sent': {
      path: 'issues/list/sent',
    },
  },
};

export const IssueListNavigator = () => {
  const navigation: any = useNavigation();
  const setIssueLastOpenTabAtom = useSetRecoilState(issueLastOpenTabAtom);
  const { canUserCreate } = useKNOWDBContext();

  const showReportIssueButton = useMemo(() => {
    return canUserCreate('tasklist');
  }, [canUserCreate]);

  React.useLayoutEffect(() => {
    const headerLeft = () => (
      <DrawerToggleButton onPress={() => navigation.toggleDrawer()} />
    );

    navigation.setOptions({
      headerLeft,
    });
  }, [navigation]);

  const setIssueRoute = useSetRecoilState(issueRouteAtom);

  const items = useMemo<any>(() => {
    return [
      {
        name: 'issue-feed',
        options: {
          title: 'Open Issues',
          drawerLabel: 'Open Issues',
        },
        component: OpenIssuesFeed,
      },
      {
        name: 'issue-following-feed',
        options: {
          title: 'Following Issues',
          drawerLabel: 'Following',
        },
        component: FollowingIssuesPage,
      },
      {
        name: 'issue-sent',
        options: {
          title: 'Sent Issues',
          drawerLabel: 'Sent',
        },
        component: SentIssuesPage,
      },
      {
        name: 'issue-completed',
        options: {
          title: 'Completed Issues',
          drawerLabel: 'Completed',
        },
        component: CompletedIssuesPage,
      },
    ];
  }, []);

  useEffect(() => {
    setIssueRoute(items.map(({ name }: { name: string }) => name));
  }, [items, setIssueRoute]);

  const module = 'Issues';

  const setIssueLastOpenTab = useCallback(
    (route) => {
      setIssueLastOpenTabAtom(route?.name ?? items[0].name);
    },
    [items, setIssueLastOpenTabAtom]
  );

  const onPressActionButton = useCallback(() => {
    navigation.dispatch(StackActions.replace('issues-create' as never));
  }, [navigation]);

  return (
    <React.Fragment>
      <KNOWAppDownloadView />
      <Divider />
      <IssuesSideBarNavigator
        module={module}
        items={items}
        isResponsive={true}
        onRouteChange={setIssueLastOpenTab}
        actionButton={
          showReportIssueButton ? (
            <KNOWButton
              paddingLeft={'8px'}
              paddingRight={'16px'}
              py={'6px'}
              colorScheme="primary"
              onPress={onPressActionButton}
            >
              <Box display={'flex'} flexDir={'row'} alignItems={'center'}>
                <ReportButtonPlusIcon size="24px" marginRight={'2px'} />
                <Text fontWeight={600} color={'#FFF'} fontSize={'14px'}>
                  Report Issue
                </Text>
              </Box>
            </KNOWButton>
          ) : null
        }
      />
    </React.Fragment>
  );
};
