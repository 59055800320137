import moment from 'moment';

export const getDateParts = (epoch: number) => {
  const mt = moment(epoch);
  const time = mt.format('hh:mm A');
  let day: string;

  if (
    moment(epoch).add(1, 'day').format('YYYYMMDD') ===
    moment().format('YYYYMMDD')
  ) {
    day = 'Yesterday';
  } else if (
    moment(epoch).add(-1, 'day').format('YYYYMMDD') ===
    moment().format('YYYYMMDD')
  ) {
    day = 'Tomorrow';
  } else if (moment(epoch).format('YYYYMMDD') === moment().format('YYYYMMDD')) {
    day = 'Today';
  } else if (moment(epoch).format('YYYY') === moment().format('YYYY')) {
    day = mt.format('ddd, DD MMM');
  } else {
    day = mt.format('DD MMM YYYY');
  }

  return [day, time];
};

export const getDateFormat = (epoch: number) => {
  const [day, time] = getDateParts(epoch);

  return `${day}${`, ${time}`}`;
};

const issueSeverityRankings = ['low', 'medium', 'high', 'critical'];

export const compareIssueSeverity = (rowA: any, rowB: any, id: string) => {
  let a = rowA.values[id];
  let b = rowB.values[id];

  const aIndex = issueSeverityRankings.findIndex((s) => s === a.toLowerCase());
  const bIndex = issueSeverityRankings.findIndex((s) => s === b.toLowerCase());

  if (aIndex > bIndex) return 1;
  if (aIndex < bIndex) return -1;
  return 0;
};
