import { useMediaQuery } from 'native-base';
import React from 'react';

export const StyleInnerHTML = ({ data }: { data: string }) => {
  const [media] = useMediaQuery({ maxWidth: 320 });
  const innerHTMLStyle = {
    color: '#51525A',
    fontSize: media ? '14px' : '16px',
    fontWeight: 400,
    fontFamily: 'Inter_400Regular, -apple-system, sans-serif',
  };

  return (
    <div style={innerHTMLStyle} dangerouslySetInnerHTML={{ __html: data }} />
  );
};
