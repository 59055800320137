import {
  KNOWDBContext,
  useDebounce,
  useDebounceSetData,
  useRecoilValue,
  useSetRecoilState,
} from '@know/ui';
import {
  actionRequiredItemsSelector,
  userFormActionRequiredItemsAtom,
  isLoadingActionRequiredAtom,
} from './store';
import { useContext, useEffect, useState } from 'react';
import { subscribeToUserActionRequired } from '@know/db';
import { omit } from 'lodash';

export const useSubscribeToFormActionRequired = () => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const setIsLoading = useSetRecoilState(isLoadingActionRequiredAtom);
  const [dataLastUpdated, setDataLastUpdated] = useState<number>(0);

  const debouncedDataLastUpdated = useDebounce(dataLastUpdated, 700);

  const [numberOfActionRequiredItems] = useRecoilValue(
    actionRequiredItemsSelector
  );

  const setUserFormActionRequiredItemsAtom = useSetRecoilState(
    userFormActionRequiredItemsAtom
  );

  const setUserFormActionRequiredItems = useDebounceSetData(
    {},
    setUserFormActionRequiredItemsAtom,
    500
  );

  useEffect(() => {
    if (debouncedDataLastUpdated) {
      setIsLoading(false);
    }
  }, [debouncedDataLastUpdated, setIsLoading]);

  useEffect(() => {
    if (currentDBApi) {
      const unsub = subscribeToUserActionRequired(currentDBApi, (id, item) => {
        setUserFormActionRequiredItems((currentData: any) => {
          setDataLastUpdated(Date.now());
          if (item) {
            return {
              ...currentData,
              [id]: item,
            };
          } else {
            const newData = omit(currentData, [id]);
            return newData;
          }
        });
      });

      setTimeout(() => {
        setDataLastUpdated(Date.now());
      }, 2000);

      return unsub;
    }

    return () => {};
  }, [currentDBApi, setIsLoading, setUserFormActionRequiredItems]);

  return {
    numberOfActionRequiredItems,
  };
};
