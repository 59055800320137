//TODO: make a native app friendly version
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Modal,
  QRScannerIcon,
  Box,
  Text,
  ExpoADIcon,
  IconButton,
  CloseIcon,
  KNOWQRScanner,
} from '@know/ui';
import { Animated } from 'react-native';

interface QRScannerOverlayProps {
  isOpen: boolean;
  close: () => void;
  onSubmit?: (response: string | undefined) => void;
}
export const QRScannerOverlay = ({
  isOpen,
  close,
  onSubmit,
}: QRScannerOverlayProps) => {
  const scannerAnim = useRef(new Animated.Value(10)).current;

  const [containerHeight, setContainerHeight] = useState<number>(0);
  // const [permissionAllowed, setIsPermissionAllowed] = useState(false);

  const [isScannerReady, setIsScannerReady] = useState<boolean>(false);

  // useEffect(() => {
  //   if (isOpen) {
  //     navigator.mediaDevices
  //       .getUserMedia({
  //         video: true,
  //       })
  //       .then(() => {
  //         setIsPermissionAllowed(true);
  //       });
  //   } else {
  //     setTimeout(() => {
  //       setIsPermissionAllowed(false);
  //     }, 1000);
  //   }
  // }, [isOpen, setIsPermissionAllowed]);

  useEffect(() => {
    if (containerHeight && isScannerReady) {
      const anim = Animated.loop(
        Animated.sequence([
          Animated.timing(scannerAnim, {
            toValue: containerHeight - 10,
            duration: 1000,
            useNativeDriver: true,
          }),
          Animated.timing(scannerAnim, {
            toValue: 10,
            duration: 1000,
            useNativeDriver: true,
          }),
        ])
      );
      anim.start();

      return () => anim.stop();
    }
  }, [scannerAnim, containerHeight, isScannerReady]);

  const AnimatedBox = Animated.createAnimatedComponent(Box);

  const onQRData = useCallback(
    (data: string) => {
      onSubmit && onSubmit(data);
      close();
    },
    [close, onSubmit]
  );

  const onScannerReady = useCallback(() => {
    setIsScannerReady(true);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      _backdrop={{
        bg: 'black',
        opacity: 0.8,
      }}
    >
      <Box
        w={'100%'}
        h={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDir={'column'}
      >
        {isOpen ? (
          <KNOWQRScanner
            onSubmit={onQRData}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            // eslint-disable-next-line react-native/no-inline-styles
            videoStyle={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            onReady={onScannerReady}
          />
        ) : null}
        <Box
          w={'310px'}
          h={'310px'}
          zIndex={1}
          onLayout={(e) => {
            setContainerHeight(e.nativeEvent.layout.height);
          }}
        >
          <QRScannerIcon size="100%" zIndex={1} />
          {isScannerReady ? (
            <AnimatedBox
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                width: '100%',
                position: 'absolute',
                transform: [{ translateY: scannerAnim }],
                height: '4px',
                top: 0,
              }}
              bg={'primary.500'}
            />
          ) : (
            <Box
              position={'absolute'}
              display={'flex'}
              top={0}
              left={0}
              justifyContent={'center'}
              alignItems={'center'}
              w="100%"
              h="100%"
            >
              <Box
                w={'100%'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box h={'100%'} mr={'8px'}>
                  <Box
                    bg={'white'}
                    borderRadius={'50%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    h={'40px'}
                    w={'40px'}
                  >
                    <ExpoADIcon
                      size={'18px'}
                      name="arrowleft"
                      color={'gray.900'}
                      style={{ transform: [{ rotate: '45deg' }] }}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  w={'220px'}
                >
                  <Text
                    fontWeight={600}
                    color={'white'}
                    fontSize={'18px'}
                    mb={'6px'}
                    mt={'6px'}
                  >
                    Allow Camera
                  </Text>
                  <Text color={'white'} fontSize={'14px'} mb={'6px'}>
                    To scan the QR, click 'Allow' above to give KNOW access to
                    your camera.
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {isScannerReady ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            flexDir={'column'}
            mt={'32px'}
          >
            <IconButton
              icon={<CloseIcon size="20px" />}
              borderRadius={'full'}
              borderWidth={'1.5px'}
              borderStyle={'solid'}
              borderColor={'white'}
              onPress={close}
              _icon={{
                color: 'white',
              }}
              width={'42px'}
              height={'42px'}
            />
            <Text fontSize={'16px'} color={'white'} mt={'6px'}>
              Close
            </Text>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};
