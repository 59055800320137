import React from 'react';
import {
  View,
  Text,
  Box,
  Image,
  Progress,
  useBreakpointValue,
  Button,
  Tag,
  Pressable,
  useNavigation,
  useRecoilValue,
} from '@know/ui';
import { Dimensions, StyleSheet } from 'react-native';
import { Ionicons, SimpleLineIcons } from '@expo/vector-icons';
import { useLanguageSwitch } from './useLanguageSwitch';
import { currentCourseTabAtom } from '../state';

const useDetailsHeaderSizes = () => {
  const smallScreenImageSizeWidth = Dimensions.get('window').width - 20;
  const topImageSize = useBreakpointValue({
    base: {
      height: `${Math.round((smallScreenImageSizeWidth * 3) / 5)}px`,
      width: `${smallScreenImageSizeWidth}px`,
    },
    sm: {
      height: `${Math.round((smallScreenImageSizeWidth * 3) / 5)}px`,
      width: `${smallScreenImageSizeWidth}px`,
    },
    md: { height: '120px', width: '200px' },
    lg: { height: '120px', width: '200px' },
    xl: { height: '120px', width: '200px' },
  });

  const topResponsiveFlexDirection = useBreakpointValue({
    base: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  });

  const topTitleTopPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  });

  const topResponsiveAlignDirection = useBreakpointValue({
    base: 'start',
    sm: 'start',
    md: 'center',
    lg: 'center',
    xl: 'center',
  });

  const topCourseInfoColumnPadding = useBreakpointValue({
    base: '0px',
    sm: '0px',
    md: '10px',
    lg: '10px',
    xl: '10px',
  });

  const topCourseInfoColumnWidth = useBreakpointValue({
    base: Dimensions.get('window').width - 20,
    sm: Dimensions.get('window').width - 20,
    md: Dimensions.get('window').width - 20,
    lg: '60%',
    xl: '60%',
  });

  const beginCourseWidth = useBreakpointValue({
    base: '100%',
    sm: '100%',
    md: 'auto',
    lg: 'auto',
    xl: 'auto',
  });

  const hPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '12px',
    lg: '15%',
    xl: '20%',
  });

  return {
    beginCourseWidth,
    hPadding,
    topCourseInfoColumnPadding,
    topCourseInfoColumnWidth,
    topImageSize,
    topResponsiveAlignDirection,
    topResponsiveFlexDirection,
    topTitleTopPadding,
  };
};

interface ICoursesDetailsScreenHeader {
  courseId: string;
  journeyId?: string;
  cardInfo?: any;
  courseCardInfo?: any;
  handleCourse?: () => Promise<void>;
  isCourseBegin?: number;
  progressLMS?: any;
  courseDetails?: any;
}

export const CoursesDetailsScreenHeader = ({
  courseId,
  courseCardInfo,
  journeyId,
  cardInfo,
  courseDetails,
  handleCourse,
  isCourseBegin,
  progressLMS,
}: ICoursesDetailsScreenHeader) => {
  const { handleToggleModal } = useLanguageSwitch(journeyId ?? courseId);
  const {
    beginCourseWidth,
    hPadding,
    topCourseInfoColumnPadding,
    topCourseInfoColumnWidth,
    topImageSize,
    topResponsiveAlignDirection,
    topResponsiveFlexDirection,
    topTitleTopPadding,
  } = useDetailsHeaderSizes();
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const navigation = useNavigation();
  const getProgress = () => {
    if (!journeyId) {
      return cardInfo?.progress;
    }
    const lessonsProgress = progressLMS?.[cardInfo?.shareId]?.[courseId] ?? {};
    const progressedLesson = Object.values(lessonsProgress).filter(
      (lessonProgress: any) => lessonProgress?.hasOwnProperty('consumedAt')
    );
    return Math.round(
      (progressedLesson.length / Object.keys(courseDetails)?.length || 0) * 100
    );
  };

  const { title: courseTitle } =
    courseCardInfo?.languages.find(
      (e: any) => e?.value === cardInfo.selectedLanguage
    ) ?? courseCardInfo;

  return (
    <Box bg="navbar.primary">
      <Box
        position="absolute"
        style={StyleSheet.absoluteFill}
        bg={{
          linearGradient: {
            colors: ['navbar.primary', 'navbar.secondary'],
            start: [0, 0],
            end: [0, 0.5],
          },
        }}
      />
      <View
        style={{ paddingHorizontal: hPadding }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {navigation.canGoBack() && (
          <Pressable onPress={navigation.goBack} marginY="16px">
            <View
              justifyContent={'center'}
              flexDirection={'row'}
              alignItems="center"
            >
              <Ionicons name="chevron-back" size={15} color="white" />
              <Text fontWeight="500" fontSize={'15px'} color="white">
                Back to Courses
              </Text>
            </View>
          </Pressable>
        )}

        {courseCardInfo?.languages?.length > 1 && (
          <Pressable onPress={() => handleToggleModal(false)} marginY="16px">
            <View
              justifyContent={'center'}
              flexDirection={'row'}
              alignItems="center"
            >
              {/* <Ionicons name="globe-outline" size={17} color="white" /> */}
              {/* <ExpoFAIcon name="globe" size={17} color="white" /> */}
              <SimpleLineIcons name="globe" size={17} color="white" />
              <Text
                fontWeight="500"
                fontSize={'14px'}
                marginLeft={'5px'}
                color="white"
              >
                LANG
              </Text>
            </View>
          </Pressable>
        )}
      </View>
      <View
        paddingX={hPadding}
        paddingBottom="15px"
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <View
          flexDirection={topResponsiveFlexDirection}
          alignItems={topResponsiveAlignDirection}
          flex={1}
        >
          <View
            borderColor={'white'}
            borderWidth="1px"
            borderRadius={'10px'}
            overflow="hidden"
          >
            <Image
              style={topImageSize}
              resizeMode="cover"
              source={{
                uri: courseCardInfo?.miniThumbnail,
              }}
              alt={'learn course'}
            />
          </View>
          <View
            w={topCourseInfoColumnWidth}
            flex={1}
            alignItems={'flex-start'}
            px={topCourseInfoColumnPadding}
          >
            <View
              w="full"
              flexDirection="row"
              alignItems="baseline"
              marginTop={topTitleTopPadding}
            >
              <Text
                fontWeight="600"
                marginRight="8px"
                fontSize={['18px', '18px', '20px', '20px', '20px']}
                color={'white'}
              >
                {courseTitle}
              </Text>
              {(currentCourseTab === 'completed-courses' ||
                getProgress() === 100) && (
                <Ionicons name="checkmark-circle" size={20} color="#00C1B2" />
              )}
            </View>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px']}
              color="white"
            >
              {courseCardInfo?.totalCount} Lessons
            </Text>
            {currentCourseTab !== 'completed-courses' && getProgress() < 100 && (
              <View
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                w={'full'}
              >
                <View flex={1} alignItems="center" justifyContent="center">
                  <Progress
                    width={'100%'}
                    size="1.5"
                    value={getProgress()}
                    bg="secondary.25"
                    _filledTrack={{
                      bg: 'primary.500',
                    }}
                  />
                </View>

                <Text paddingLeft={'12px'} color={'coolGray.100'}>
                  {getProgress()}%
                </Text>
              </View>
            )}
            <View
              marginTop={
                currentCourseTab === 'completed-courses'
                  ? '12px'
                  : ['8px', '8px', '8px', '12px', '12px']
              }
            >
              <Tag variant="trasparent" label={courseCardInfo?.type} />
            </View>
          </View>
        </View>
        {currentCourseTab !== 'completed-courses' && getProgress() < 100 && (
          <View
            w={beginCourseWidth}
            marginTop="12px"
            marginBottom="16px"
            marginLeft={[0, 0, '22px', '22px', '22px']}
          >
            <Button
              size={'sm'}
              minWidth="180px"
              bgColor="primary.500"
              onPress={handleCourse}
            >
              <Text fontWeight={'semibold'} color={'white'} marginX="4px">
                {(isCourseBegin ?? 0) > 0 ? 'Resume Course' : 'Begin Course'}
              </Text>
            </Button>
          </View>
        )}
      </View>
    </Box>
  );
};
