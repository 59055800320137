import React from 'react';
import { createStackNavigator } from '@know/ui';
import {
  TaskDetailsPage,
  linking as TaskDetailsLinking,
} from './TaskDetailsPage';
import {
  TasksListNavigator,
  linking as TasksListNavigatorLinking,
} from './TasksListNavigator';

const TasksStackNavigator = createStackNavigator();

export const linking = {
  initialRouteName: 'list',
  screens: {
    details: TaskDetailsLinking,
    list: TasksListNavigatorLinking,
  },
};

export function TasksNavigator() {
  return (
    <TasksStackNavigator.Navigator initialRouteName="list">
      <TasksStackNavigator.Screen
        name="details"
        component={TaskDetailsPage}
        options={{
          headerShown: false,
          title: 'Task',
        }}
      />
      <TasksStackNavigator.Screen
        name="list"
        component={TasksListNavigator}
        options={{
          headerShown: false,
        }}
      />
    </TasksStackNavigator.Navigator>
  );
}
