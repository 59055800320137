import React, { useContext, useEffect, useState } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import {
  KNOWDBContext,
  Text,
  WithKNOWLoader,
  useSetRecoilState,
} from '@know/ui';
import { IUserFeed, getAliasCourse } from '@know/db';
import { sessionIdAtom, userCoursesFeedAtom } from '../state';

export const linking = {
  path: 'lms/launch-course/:aliasId',
};

export const linkingWithKnow = {
  path: '/know/lms/launch-course/:aliasId',
};

export const LaunchPublicCourseScreen = ({
  route,
  navigation,
}: StackHeaderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { currentDBApi } = useContext(KNOWDBContext);
  const params: any = route?.params ?? {};
  const { aliasId } = params;
  const setSessionId = useSetRecoilState(sessionIdAtom);

  const setUserFeedObject = useSetRecoilState<{
    [key: string]: IUserFeed;
  }>(userCoursesFeedAtom);

  useEffect(() => {
    if (currentDBApi) {
      getAliasCourse({
        fbAPI: currentDBApi,
        aliasId,
      })
        .then(({ nuggetId, error, type, userCourseFeed }) => {
          setIsLoading(false);
          if (error) {
            navigation.navigate('list');
            return;
          }
          if (nuggetId) {
            setUserFeedObject((feed) => {
              return {
                ...feed,
                [nuggetId]: userCourseFeed,
              };
            });
          }
          const newSessionId = Date.now();
          setSessionId(`${newSessionId}`);
          if (type === 'learningPath') {
            navigation.navigate('journey-details' as never, {
              journeyId: nuggetId,
            });
          } else {
            navigation.navigate('course-details' as never, {
              courseId: nuggetId,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [
    aliasId,
    currentDBApi,
    navigation,
    setIsLoading,
    setSessionId,
    setUserFeedObject,
  ]);

  return (
    <WithKNOWLoader
      isLoading={isLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <Text>Loading course</Text>
    </WithKNOWLoader>
  );
};
