import moment from 'moment';

export const getDateParts = (epoch: number) => {
  const mt = moment(epoch);
  const time = mt.format('hh:mm A');
  let day: string;

  if (
    moment(epoch).add(1, 'day').format('YYYYMMDD') ===
    moment().format('YYYYMMDD')
  ) {
    day = 'Yesterday';
  } else if (
    moment(epoch).add(-1, 'day').format('YYYYMMDD') ===
    moment().format('YYYYMMDD')
  ) {
    day = 'Tomorrow';
  } else if (moment(epoch).format('YYYYMMDD') === moment().format('YYYYMMDD')) {
    day = 'Today';
  } else if (moment(epoch).format('YYYY') === moment().format('YYYY')) {
    day = mt.format('DD MMM');
  } else {
    day = mt.format('DD MMM YYYY');
  }

  return [day, time];
};
