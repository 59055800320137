import React, { useCallback } from 'react';
import { useCourseLibrary } from './useCourseLibrary';
import {
  HorizontalListView,
  Box,
  ScrollView,
  useKNOWValueContextProvider,
} from '@know/ui';
import { SkeletonItem } from './SkeletonItem';
import { CourseLibraryFeedItem } from './CourseLibraryFeedItem';
import { LibraryFeedItem } from '@know/transformers';

export const CourseLibrary = () => {
  const { isLoading, data, handleNavigatingToCourse } = useCourseLibrary();
  const {
    isPhoneSize,
    isDesktopSize,
    isTabletSize,
    isLargeTabletSize,
    isLargePhoneSize,
  } = useKNOWValueContextProvider();

  const getListHeight = useCallback(
    (w: number) => {
      if (isPhoneSize) {
        // in order to keep the image in aspect ratio
        // ratio is 5:3, so 3/5 of width + 8px margin + 58px height of the text container + 8px margin + 22px tag height
        return (3 * w) / 5 + 8 + 58 + 8 + 22;
      }

      // ratio is 5:3, so 3/5 of width + 8px margin + 66px height of the text container + 8px margin + 25px tag height
      return (3 * w) / 5 + 8 + 66 + 8 + 25;
    },
    [isPhoneSize]
  );

  return (
    <ScrollView bg={'white'} w={'full'} h={'full'} pt={'20px'}>
      {isLoading ? (
        <Box mt={'20px'} w={'100%'}>
          <HorizontalListView
            items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            keyExtractor={(i) => i + ''}
            renderItem={() => <SkeletonItem />}
            getListHeight={getListHeight}
            disableNavButtons={true}
          />
        </Box>
      ) : (
        data.map((item: { name: any; items: any }) => (
          <Box
            mb={
              isDesktopSize
                ? '40px'
                : isTabletSize || isLargeTabletSize
                ? '32px'
                : '24px'
            }
            w={'100%'}
            key={item.name}
          >
            <HorizontalListView
              items={item.items}
              keyExtractor={(i: LibraryFeedItem) => i.id}
              title={item.name}
              getListHeight={getListHeight}
              renderItem={(i: LibraryFeedItem) => (
                <CourseLibraryFeedItem
                  onPress={handleNavigatingToCourse}
                  item={i}
                />
              )}
              itemMargin={isPhoneSize || isLargePhoneSize ? 8 : 12}
            />
          </Box>
        ))
      )}
    </ScrollView>
  );
};
