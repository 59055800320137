import React from 'react';
import { StyleSheet } from 'react-native';
import { Pressable } from '.';
import { IPressableProps } from 'native-base';

export function KNPressable(props: IPressableProps) {
  return <Pressable style={styles.pointer} {...props} />;
}

const styles = StyleSheet.create({
  pointer: {
    //@ts-ignore
    cursor: 'pointer',
  },
});
