import { useEffect, useMemo, useState } from 'react';
import { IShareContact, IShareGroup } from './types';
import { useKNOWDBContext, useKNOWToast } from '@know/ui';
import {
  getRecentContacts,
  searchUsersRPC,
  getUserGroups,
  shareNugget,
  NuggetClassificationType,
} from '@know/db';

import { searchUsers, searchGroups } from '@know/transformers';

import { useTranslation } from '@know/i18n';

const getSuccessToastTitle = (classificationType: NuggetClassificationType) => {
  switch (classificationType) {
    case 'tasks':
      return 'Task shared successfully!';
    case 'tasklist':
      return 'Issue shared successfully';
    default:
      return 'Nugget shared successfully!';
  }
};

interface IUseShareDialogueDataProps {
  onSubmitCB?: () => void;
  nuggetId: string;
  classificationType: NuggetClassificationType;
  authorId: string;
}

export function useShareDilogueData({
  onSubmitCB,
  nuggetId,
  classificationType,
  authorId,
}: IUseShareDialogueDataProps) {
  const { t } = useTranslation();

  const [isRecentContactsLoading, setIsRecentContactsLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);

  const [recentUsersFilterText, setRecentUsersFilterText] = useState('');
  const [usersFilterText, setUserFilterText] = useState('');
  const [groupsFilterText, setGroupsFilterText] = useState('');
  const [groupToViewMembers, setGroupToViewMembers] = useState<IShareGroup>();

  const { currentDBApi, userDetails } = useKNOWDBContext();
  const [recentContactsWithoutIsSelected, setRecentContacts] = useState<
    IShareContact[]
  >([]);
  const [selectedContacts, setSelectedContacts] = useState<{
    [key: string]: IShareContact;
  }>({});

  const [selectedGroups, setSelectedGroups] = useState<{
    [key: string]: IShareGroup;
  }>({});

  const [usersWithoutIsSelected, setUsers] = useState<IShareContact[]>([]);
  const [groupsWithoutSelected, setGroups] = useState<IShareGroup[]>([]);

  const [isSharing, setIsSharing] = useState<boolean>(false);
  const { successToast } = useKNOWToast();

  const emptyRecentUsersMessage = useMemo(() => {
    if (isRecentContactsLoading) {
      return t('Loading');
    }
    if (recentUsersFilterText) {
      return t('No results found');
    }
    return t('You have no recent contacts');
  }, [isRecentContactsLoading, recentUsersFilterText, t]);

  const emptyUsersMessage = useMemo(() => {
    if (usersFilterText) {
      return t('No results found');
    }
    return t('Search by name, department or designation');
  }, [usersFilterText, t]);

  const emptyGroupsMessage = useMemo(() => {
    if (isGroupsLoading) {
      return t('Loading');
    }
    if (usersFilterText) {
      return t('No results found');
    }
    return t('You have no groups');
  }, [isGroupsLoading, usersFilterText, t]);

  useEffect(() => {
    if (userDetails && currentDBApi) {
      getRecentContacts(currentDBApi).then((res) => {
        setRecentContacts(res);
        setIsRecentContactsLoading(false);
      });
    }
  }, [currentDBApi, currentDBApi?.user, userDetails]);

  const recentContacts = useMemo(() => {
    const filteredUsers = recentUsersFilterText
      ? (searchUsers(
          recentUsersFilterText,
          recentContactsWithoutIsSelected as any[]
        ) as unknown as IShareContact[])
      : recentContactsWithoutIsSelected;

    return filteredUsers.map((contact) => ({
      ...contact,
      isSelected: !!selectedContacts[contact.id],
    }));
  }, [
    recentContactsWithoutIsSelected,
    selectedContacts,
    recentUsersFilterText,
  ]);

  const users = useMemo(() => {
    const filteredUsers = usersWithoutIsSelected;
    return filteredUsers.map((contact) => ({
      ...contact,
      isSelected: !!selectedContacts[contact.id],
    }));
  }, [usersWithoutIsSelected, selectedContacts]);

  const groups = useMemo(() => {
    const filteredGroups = groupsFilterText
      ? (searchGroups(
          groupsFilterText,
          groupsWithoutSelected as any[]
        ) as unknown as IShareContact[])
      : groupsWithoutSelected;
    return filteredGroups.map((group) => ({
      ...group,
      isSelected: !!selectedGroups[group.id],
    }));
  }, [groupsWithoutSelected, selectedGroups, groupsFilterText]);

  const selectedContactsArray = useMemo(
    () => Object.values(selectedContacts),
    [selectedContacts]
  );

  const selectedGroupsArray = useMemo(
    () => Object.values(selectedGroups),
    [selectedGroups]
  );

  return {
    currentDBApi,
    isRecentContactsLoading,
    isUsersLoading,
    isGroupsLoading,
    recentUsersFilterText,
    usersFilterText,
    groupsFilterText,
    emptyRecentUsersMessage,
    emptyUsersMessage,
    emptyGroupsMessage,
    recentContacts,
    users,
    groups,
    canShare:
      selectedContactsArray.length > 0 || selectedGroupsArray.length > 0,
    selectedContacts: selectedContactsArray,
    selectedGroups: selectedGroupsArray,
    isSharing,
    onSelectToggleRecentContact,
    onSelectToggleGroup,
    onRecentUsersFilterChange,
    onUsersFilterChange,
    onGroupFilterChange,
    onClickShareButtonHandler,
    groupToViewMembers,
    toggleGroupToViewMembers,
    fetchGroups,
    clearData,
  };

  async function fetchGroups() {
    if (groups.length === 0 && currentDBApi) {
      setIsGroupsLoading(true);
      const userGroups = await getUserGroups(currentDBApi, userDetails);
      setGroups(userGroups);
      setIsGroupsLoading(false);
    }
  }
  function toggleGroupToViewMembers(newGroup: IShareGroup) {
    setGroupToViewMembers((group) => (group ? undefined : newGroup));
  }

  function onSelectToggleRecentContact(contact: IShareContact) {
    setSelectedContacts((srcs) => {
      if (srcs[contact.id]) {
        delete srcs[contact.id];
        return { ...srcs };
      }
      return {
        ...srcs,
        [contact.id]: contact,
      };
    });
  }

  function onSelectToggleGroup(group: IShareGroup) {
    setSelectedGroups((srcs) => {
      if (srcs[group.id]) {
        delete srcs[group.id];
        return { ...srcs };
      }
      return {
        ...srcs,
        [group.id]: group,
      };
    });
  }

  async function onRecentUsersFilterChange(searchString: string) {
    setRecentUsersFilterText(searchString);
    // TODO: Filter Implementation
  }

  async function onUsersFilterChange(searchString: string) {
    setIsUsersLoading(true);
    setUserFilterText(searchString);
    if (searchString && currentDBApi) {
      const usersFromSearch = await searchUsersRPC(currentDBApi, searchString);
      setIsUsersLoading(false);
      return setUsers(usersFromSearch);
    }
    setUsers([]);
    setIsUsersLoading(false);
  }

  async function onGroupFilterChange(searchString: string) {
    setGroupsFilterText(searchString);
    // TODO: Filter Implementation
  }
  async function onClickShareButtonHandler() {
    const payload = {
      users: Object.keys(selectedContacts),
      groups: Object.keys(selectedGroups),
      // type: 'shared_task',
    };
    if (currentDBApi) {
      setIsSharing(true);
      await shareNugget(
        currentDBApi,
        classificationType as any,
        nuggetId,
        authorId,
        payload
      );

      if (payload.users.length > 0) {
        getRecentContacts(currentDBApi).then((res) => {
          setRecentContacts(res);
        });
      }

      onSubmitCB?.();
      setIsSharing(false);
      successToast({
        header: getSuccessToastTitle(classificationType),
      });
    }
  }
  function clearData() {
    // setUserFilterText('');
    onUsersFilterChange('');
    setGroupsFilterText('');
    setRecentUsersFilterText('');

    setSelectedContacts({});
    setSelectedGroups({});
    setGroupToViewMembers(undefined);
  }
}
