import React from 'react';
import { View, Text, Image, Progress } from '@know/ui';
import Images from '../../../../Images';
import type { IQuizQuestion } from '../types';

const Timer = ({
  remainingTime,
  questions,
  selectedIndex,
}: {
  remainingTime: number | undefined;
  questions: IQuizQuestion[];
  selectedIndex: number;
}) => {
  return (
    <View marginBottom="10px" padding="15px">
      <View
        marginBottom="5px"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
      >
        <View flexDirection="row" alignItems="center">
          <Image
            alt="image quiz timer"
            tintColor="gray.600"
            height="20px"
            width="20px"
            marginRight="10px"
            marginTop="2px"
            source={Images.QuizTimeLimit}
          />
          <Text
            color="gray.700"
            fontWeight="medium"
            fontSize={['14px', '14px', '16px', '16px', '16px']}
          >
            Time Remaining:
          </Text>
        </View>
        <Text
          fontWeight="medium"
          color="gray.700"
          fontSize={['14px', '14px', '16px', '16px', '16px']}
        >
          {remainingTime + 's'}
        </Text>
      </View>
      <Progress
        width="100%"
        size="sm"
        value={
          (remainingTime! / questions[selectedIndex].timeInSec! ?? 0) * 100
        }
        bg="gray.300"
        _filledTrack={{
          bg: `${remainingTime! <= 5 ? 'red.500' : 'primary.500'}`,
        }}
      />
    </View>
  );
};

export default Timer;
