import { FirebaseAPI } from "..";
import { DataSnapshot } from "firebase/database";

import { getNuggetsAnalyticsPayload } from "@know/transformers";
import {
  AnalyticsNuggetsClassificationTypes,
  IRaiseAnalyticsProps,
} from "./types";

export const ANALYTICS_NODE_PATH = "/analyticsRequest";

export function getUserFeedPath(
  fbAPI: FirebaseAPI,
  classificationType: AnalyticsNuggetsClassificationTypes,
  nuggetId: string
) {
  return `${fbAPI.getCommonPath(
    "currentUserFeed"
  )}/${classificationType}/${nuggetId}`;
}

export function getIssueDetails(
  fbAPI: FirebaseAPI,
  nuggetId: string
): Promise<any> {
  return new Promise((resolve) => {
    const pathRef = fbAPI.getChild(
      fbAPI.getNodeRef(fbAPI.getCommonPath("nuggets")),
      `tasklist/${nuggetId}`
    );

    const handler = (snap: DataSnapshot) => {
      const taskNugget = snap.val() ?? {};
      resolve(taskNugget);
    };

    fbAPI.listenOnNodeRef(pathRef, "value", handler);
  });
}
export async function raiseNuggetAnalytics({ fbAPI, ...props }: IRaiseAnalyticsProps) {
  const userId = fbAPI.getLoggedInUserId();
  const userFeedPath = getUserFeedPath(
    fbAPI,
    props.classificationType,
    props.nuggetId
  );
  if (userId !== null) {
    if (props.type === "received") {
      await fbAPI.fbUpdate(userFeedPath, {
        isReceived: true,
      });
    }

    if (props.type === "opened") {
      return await fbAPI.fbUpdate(userFeedPath, {
        consumedAt: fbAPI.getServerTimestamp(),
      });
    } else {
      if (props.classificationType === "tasklist") {
        const issueNuggetData = await getIssueDetails(fbAPI, props.nuggetId);

        const issuesAnalyticsPayload = {
          ...props,
          type: props.type,
          userId: userId || "",
          organization: fbAPI.organization,
          serverTimestamp: fbAPI.getServerTimestamp,
          incidentType: issueNuggetData.incidentID || "",
        };

        await fbAPI.pushToNode(
          ANALYTICS_NODE_PATH,
          getNuggetsAnalyticsPayload(issuesAnalyticsPayload)
        );
      } else {
        const taskAnalyticsPayload = {
          ...props,
          type: props.type,
          userId: userId || "",
          organization: fbAPI.organization,
          serverTimestamp: fbAPI.getServerTimestamp,
        };

        await fbAPI.pushToNode(
          ANALYTICS_NODE_PATH,
          getNuggetsAnalyticsPayload(taskAnalyticsPayload)
        );
      }
    }
  }
}
