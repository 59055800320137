import React from 'react';
import { View, Text } from '@know/ui';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tooltipTriangle: {
    transform: [{ rotate: '270deg' }],
  },
  tooltipInnerTriangle: {
    transform: [{ rotate: '0deg' }],
  },
  rightArrow: {
    position: 'absolute',
    backgroundColor: '#0078fe',
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomLeftRadius: 25,
    right: -10,
  },

  rightArrowOverlap: {
    position: 'absolute',
    backgroundColor: '#eeeeee',
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomLeftRadius: 18,
    right: -20,
  },
});

export const LockLessonTooltip = ({
  title = 'Previous lessons are incomplete',
  description = 'Please complete all the previous lessons in order to access this lesson.',
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <View
      flexDirection="row"
      top="10px"
      zIndex={3}
      alignItems="center"
      justifyItems="flex-end"
      justifyContent="center"
      overflow="visible"
      position="absolute"
      left={['110px', '110px', '110px', '110px', '110px']}
    >
      <View
        width={0}
        height={0}
        backgroundColor="transparent"
        borderStyle="solid"
        borderLeftWidth={8}
        borderRightWidth={8}
        borderBottomWidth={8}
        borderLeftColor="transparent"
        borderRightColor="transparent"
        borderBottomColor="gray.300"
        zIndex={22}
        right="-5px"
        alignItems="center"
        justifyItems="center"
        justifyContent="center"
        flex={1}
        style={styles.tooltipTriangle}
      >
        <View
          width={0}
          height={0}
          backgroundColor="transparent"
          borderStyle="solid"
          borderLeftWidth={8}
          borderRightWidth={8}
          borderBottomWidth={8}
          borderLeftColor="transparent"
          borderRightColor="transparent"
          borderBottomColor="white"
          zIndex={22}
          bottom="-6px"
          flex={1}
          style={styles.tooltipInnerTriangle}
        />
      </View>

      <View
        borderRadius="6px"
        borderColor="gray.300"
        borderWidth="1px"
        bgColor="white"
        maxWidth="315px"
      >
        <View paddingX="18px" paddingY="12px">
          <Text color="gray.900" fontSize="16px" fontWeight="semibold">
            {title}
          </Text>
          <Text color="gray.700" fontSize="14px">
            {description}
          </Text>
        </View>
      </View>
    </View>
  );
};
