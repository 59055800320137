import React from 'react';
import { Spinner, HStack, Heading, VStack, ISpinnerProps } from 'native-base';

interface KNOWLoaderProps extends ISpinnerProps {
  color?: string;
  type?: 'vertical' | 'horizontal';
  text?: string | undefined;
}

interface WithKNOWLoaderProps extends KNOWLoaderProps {
  isLoading: boolean;
}

export const KNOWLoader: React.FC<KNOWLoaderProps> = ({
  color = 'primary.500',
  type = 'vertical',
  text,
  ...restProps
}) => {
  return type === 'vertical' ? (
    <VStack flex={1} space={2} alignItems="center" justifyContent="center">
      <Spinner accessibilityLabel={text} {...restProps} />
      {text ? (
        <Heading color={color} fontSize="md">
          {text}
        </Heading>
      ) : null}
    </VStack>
  ) : (
    <HStack flex={1} space={2} alignItems="center" justifyContent="center">
      <Spinner accessibilityLabel={text} {...restProps} />
      {text ? (
        <Heading color={color} fontSize="md">
          {text}
        </Heading>
      ) : null}
    </HStack>
  );
};

export const WithKNOWLoader: React.FC<WithKNOWLoaderProps> = ({
  isLoading,
  children,
  ...props
}) => {
  return isLoading ? <KNOWLoader {...props} /> : <> {children} </>;
};
