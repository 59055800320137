import { noop } from 'lodash';
import { Slider } from '@know/ui';
import React from 'react';

function SeekBar({
  width,
  size,
  step,
  minValue,
  value,
  onChange,
  marginX,
  maxValue,
}: {
  width?: string | number;
  size?: string;
  step: number;
  minValue: number;
  value: number;
  onChange?: (arg?: any) => void;
  marginX?: string | number;
  maxValue?: number;
}) {
  return (
    <>
      <Slider
        width={width || '100%'}
        size={size || 'lg'}
        step={step}
        minValue={minValue}
        maxValue={maxValue || 100}
        accessibilityLabel="Audio seek"
        value={value}
        onChange={onChange || noop}
        marginX={marginX || ''}
      >
        <Slider.Track>
          <Slider.FilledTrack />
        </Slider.Track>
      </Slider>
    </>
  );
}

export default SeekBar;
