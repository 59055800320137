import type { FirebaseAPI } from "..";
import { getCourseUserFeed, setCourseUserFeedForPublicCourse } from "./Feed";

interface IGetAliasCourseProps {
  fbAPI: FirebaseAPI;
  aliasId: string;
}

export async function getAliasCourse({fbAPI, aliasId }: IGetAliasCourseProps) {
  const courseAliasPath = fbAPI.getMultiPathCommon(`/courseAlias/${aliasId}`);
  const courseId: string = await fbAPI.getValue(courseAliasPath) || aliasId;
  const courseDetails: any = await fbAPI.getLMSCourseDetails(courseId);

  if (courseId && courseDetails) {
    let userCourseFeed = await getCourseUserFeed({ fbAPI, courseId });
    if (!userCourseFeed?.shareId) {
      userCourseFeed = await setCourseUserFeedForPublicCourse({
        fbAPI, courseId,
        authorId: courseDetails.authorId,
        type: courseDetails.type
      });
    }
    return {
      nuggetId: courseId,
      type: courseDetails.type,
      userCourseFeed
    }
  }

  return {
    error: 'Course with alias ID is not found.'
  }
}
