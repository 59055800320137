import React, { useContext, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useShiftListState } from './useShiftListState';
import type {
  AttendanceEventInterface,
  AttendanceEventType,
  ShiftWithIdInterface,
} from './state';
import { isLoadingShiftCacheState } from './state';
import {
  KNOWDBContext,
  HStack,
  Heading,
  FlatList,
  View,
  Center,
  KNOWRouterContenxt,
  IModuleProps,
  useBreakpointValue,
  useModalParam,
  WithKNOWLoader,
  useRecoilValue,
  ButtonProps,
  Text,
  IconButton,
  ExpoFAIcon,
  GestureRecognizer,
} from '@know/ui';

import { InfoModal } from './InfoModal';
import { ShiftCard } from './ShiftCard';
import { get } from 'lodash';
import { getShiftTimings } from './utils';
import moment from 'moment';
import { ConfirmModal } from './ConfirmModal';
import { ShiftDetailsModal } from './ShiftDetailsModal';

const ShiftListPage = () => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const {
    shiftsList,
    isLoading,
    writeAttendanceEvent,
    dropShift,
    dateRange,
    goBackOneWeek,
    goForwardOneWeek,
    registerToListenOnDailyUserUpdateNode,
    unregisterListeningOnDailyUserUpdateNode,
  } = useShiftListState(currentDBApi);
  const [isInfoModalOpen, infoModalParam, openInfoModal, closeInfoModal] =
    useModalParam<{
      shift: ShiftWithIdInterface;
      type: AttendanceEventType | 'dropShift';
      timestamp: number;
    }>();

  const [
    isShiftDetailsModalOpen,
    shiftDetailsModalParam,
    openShiftDetailsModal,
    closeShiftDetailsModal,
  ] = useModalParam<{
    shift: ShiftWithIdInterface;
  }>();

  const [
    isConfirmModalOpen,
    confirmModalParam,
    openConfirmModal,
    closeConfirmModal,
  ] = useModalParam<{
    title: string;
    description: string;
    onCancel: () => void;
    onConfirm: () => void | Promise<void>;
    confirmButtonVariant?: ButtonProps['colorScheme'];
    confirmText?: string;
  }>();

  const rowWidth = useBreakpointValue({
    base: '90%',
    sm: '90%',
    md: '80%',
    lg: '50%',
  });

  const isLoadingCache = useRecoilValue<boolean>(isLoadingShiftCacheState);

  const openDropShiftConfim = useCallback(
    (shift: ShiftWithIdInterface, callback: () => Promise<void>) => {
      openConfirmModal({
        title: 'Confirm drop shift',
        description: `${get(
          shift,
          'shiftSlot.slot.name',
          ''
        ).trim()} (${getShiftTimings(shift)}) at ${get(shift, 'location', '')}`,
        onCancel: () => closeConfirmModal(),
        onConfirm: () => {
          closeConfirmModal();
          callback();
        },
        confirmButtonVariant: 'danger',
        confirmText: 'Drop Shift',
      });
    },
    [closeConfirmModal, openConfirmModal]
  );

  const openEndShiftConfim = useCallback(
    (ciEvent: AttendanceEventInterface, callback: () => Promise<void>) => {
      openConfirmModal({
        title: 'Confirm end shift',
        description: `You started this shift at ${moment(
          ciEvent.createdAt
        ).format('DD MMM h:mma')}`,
        onCancel: () => closeConfirmModal(),
        onConfirm: () => {
          closeConfirmModal();
          callback();
        },
        confirmButtonVariant: 'danger',
        confirmText: 'End Shift',
      });
    },
    [closeConfirmModal, openConfirmModal]
  );

  const onClickCard = useCallback(
    (shift: ShiftWithIdInterface) => {
      openShiftDetailsModal({ shift });
    },
    [openShiftDetailsModal]
  );

  const paddingDateNavigationButton = useBreakpointValue({
    base: '2px',
    md: '4px',
    lg: '4px',
  });

  const fontSizeDate = useBreakpointValue({
    base: '12px',
    md: '14px',
    lg: '14px',
  });

  const config = {
    velocityThreshold: 0.1,
    directionalOffsetThreshold: 80,
  };

  const styles = StyleSheet.create({
    view: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    dateBox: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      border: '1px solid rgb(212, 212, 216)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
  });
  const flatListStyle = { width: '100%' };

  return (
    <View flex={1} backgroundColor="white" style={styles.view}>
      <Center width="100%">
        <HStack
          width={rowWidth}
          justifyContent="space-between"
          mb={5}
          alignItems="center"
          // direction={headerDirection}
        >
          <Heading fontSize="xl" color="gray.700" fontWeight={500}>
            My schedule
          </Heading>
          <HStack>
            <IconButton
              icon={
                <ExpoFAIcon name="angle-left" size="sm" textAlign="center" />
              }
              bgColor="gray.300"
              borderLeftRadius="md"
              borderRightRadius="none"
              _icon={{
                color: 'gray.500',
              }}
              _pressed={{
                bg: 'gray.500:alpha.20',
              }}
              justifyContent="center"
              // @ts-ignore
              onPress={goBackOneWeek}
              padding={paddingDateNavigationButton}
            />
            <GestureRecognizer
              onSwipeLeft={goForwardOneWeek}
              onSwipeRight={goBackOneWeek}
              config={config}
              style={styles.dateBox}
            >
              <Text fontWeight={600} fontSize={fontSizeDate}>
                {dateRange.startDate.format('DD MMM')} -{' '}
                {dateRange.endDate.format('DD MMM')}
              </Text>
            </GestureRecognizer>
            <IconButton
              icon={
                <ExpoFAIcon name="angle-right" size="sm" textAlign="center" />
              }
              bgColor="gray.300"
              _icon={{
                color: 'gray.500',
              }}
              _pressed={{
                bg: 'gray.500:alpha.20',
              }}
              borderRightRadius="md"
              borderLeftRadius="none"
              justifyContent="center"
              // @ts-ignore
              onPress={goForwardOneWeek}
              padding={paddingDateNavigationButton}
            />
          </HStack>
        </HStack>
      </Center>
      <WithKNOWLoader
        isLoading={isLoading || isLoadingCache}
        color="primary"
        type="horizontal"
        size="lg"
      >
        <FlatList
          style={flatListStyle}
          data={shiftsList}
          keyExtractor={(item: ShiftWithIdInterface) => item.shiftId}
          renderItem={({ item }: { item: ShiftWithIdInterface }) => (
            <ShiftCard
              item={item}
              openInfoModal={openInfoModal}
              writeAttendanceEvent={writeAttendanceEvent}
              closeInfoModal={closeInfoModal}
              dropShift={dropShift}
              openDropShiftConfim={openDropShiftConfim}
              openEndShiftConfim={openEndShiftConfim}
              onClickCard={onClickCard}
            />
          )}
        />
      </WithKNOWLoader>
      {isInfoModalOpen ? (
        <InfoModal
          {...infoModalParam}
          closeModal={closeInfoModal}
          isOpen={isInfoModalOpen}
        />
      ) : null}
      {isConfirmModalOpen ? (
        <ConfirmModal {...confirmModalParam} isOpen={isConfirmModalOpen} />
      ) : null}
      {isShiftDetailsModalOpen ? (
        <ShiftDetailsModal
          {...shiftDetailsModalParam}
          isOpen={isShiftDetailsModalOpen}
          closeModal={closeShiftDetailsModal}
          listenOnDailyUserUpdate={registerToListenOnDailyUserUpdateNode}
          unListenOnDailyUserUpdate={unregisterListeningOnDailyUserUpdateNode}
        />
      ) : null}
    </View>
  );
};

export const ShiftScreen = () => {
  const { registerScreen } = React.useContext(KNOWRouterContenxt);

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'shifts',
      name: 'Shifts',
      priority: 1,
      component: ShiftListPage,
      icon: 'calendar',
    };
    registerScreen(module);
  }, [registerScreen]);

  return null;
};
