import {
  Text,
  HStack,
  KNPressable,
  VStack,
  Box,
  RepeatIcon,
  ClockIcon,
} from '..';

import React from 'react';
import { IFormActionRequiredFeedItems, getDateParts } from '@know/transformers';

interface IFormActionRequiredRepeatItemProps {
  item: IFormActionRequiredFeedItems;
  onPress: (item: IFormActionRequiredFeedItems) => void;
}

export function FormActionRequiredRepeatItem({
  item,
  onPress,
}: IFormActionRequiredRepeatItemProps) {
  const { name, dueOn } = item;

  function handleOnPress() {
    onPress && onPress(item);
  }

  const [day, time] = getDateParts(dueOn ?? Date.now());

  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        flex={1}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        pr={'20px'}
        pl={'24px'}
        py={'12px'}
        borderBottomColor={'gray.300'}
        borderBottomWidth={'1px'}
      >
        <RepeatIcon
          color={'gray.900'}
          mr={2}
          mt={'2px'}
          size="4"
          textAlign="center"
        />
        <VStack>
          <Text
            fontFamily={'open'}
            display={'flex'}
            alignItems={'center'}
            color={'gray.900'}
            fontWeight={600}
          >
            {name}
          </Text>
          <HStack alignItems={'center'} mt={'6px'}>
            <Box
              borderColor={'gray.300'}
              borderWidth={'1px'}
              borderRadius={'4px'}
              padding={'4px 8px'}
              mr={'6px'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <ClockIcon size="18px" mb="-2px" />
              <Text color={'red.400'} fontFamily={'tableCell'} fontWeight={600}>
                {day}
              </Text>
            </Box>
            <Box
              borderColor={'gray.300'}
              borderWidth={'1px'}
              padding={'4px 8px'}
              borderRadius={'4px'}
            >
              <Text color={'red.400'} fontFamily={'tableCell'} fontWeight={600}>
                {time}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </KNPressable>
  );
}
