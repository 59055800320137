import React, { useContext, useState } from 'react';
import { get } from 'lodash';
import { Avatar, Text, View, Menu, Box } from 'native-base';

import { ExpoFAIcon, KNOWLogoWithNameIcon } from '../Components';
import { WithKNOWLoader } from '../Components/Loader';
import { KNOWDBContext } from '../KNOWProvider/DBProvider';

import { Pressable } from 'react-native';
export {
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';

import { Ionicons, Entypo } from '@expo/vector-icons';
import { useKNOWValueContextProvider } from '..';
import { useTranslation } from '@know/i18n';

export const KNOWHeader = ({ navigation }: { navigation: any }) => {
  const { t } = useTranslation('menus');
  const { userDetails, isLoadingConfig, signUserOut } =
    useContext(KNOWDBContext);
  const [showDropDown, setShowDropDown] = useState(false);

  const { isPhoneSize } = useKNOWValueContextProvider();

  return (
    <View
      h="52px"
      bgColor="white"
      borderBottomColor={'gray.250'}
      borderBottomWidth={'1px'}
      justifyContent="center"
    >
      <View
        paddingX="16px"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <View
          flex={1}
          alignItems="center"
          justifyContent={'flex-start'}
          flexDirection="row"
        >
          <Pressable onPress={() => navigation.toggleDrawer()}>
            <Entypo
              name="menu"
              size={isPhoneSize ? 24 : 32}
              color="gray.700"
              marginLeft="15px"
            />
          </Pressable>
          <KNOWLogoWithNameIcon
            size={isPhoneSize ? '70px' : '90px'}
            marginLeft={isPhoneSize ? '8px' : '12px'}
          />
          {/* <View w="24px" /> */}
        </View>
        <Box>
          <Menu
            placement="bottom right"
            trigger={(triggerProps) => {
              return (
                <Pressable
                  onPress={() => setShowDropDown(true)}
                  accessibilityLabel="More options menu"
                  {...triggerProps}
                >
                  <View
                    flex={1}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Avatar
                      source={{
                        uri: get(userDetails, 'avatar', 'na'),
                      }}
                      size="sm"
                      mr={'6px'}
                    >
                      {`${get(
                        userDetails,
                        'firstName.0',
                        ''
                      ).toUpperCase()}${get(
                        userDetails,
                        'lastName.0',
                        ''
                      ).toUpperCase()}`}
                    </Avatar>
                    {!isPhoneSize ? (
                      <Text mr="6px" maxW={'150px'} noOfLines={1}>
                        {`${get(userDetails, 'firstName', '')} ${get(
                          userDetails,
                          'lastName',
                          ''
                        )}`}
                      </Text>
                    ) : null}

                    <Ionicons
                      name={showDropDown ? 'chevron-up' : 'chevron-down'}
                      size={16}
                      color="gray.700"
                    />
                  </View>
                </Pressable>
              );
            }}
          >
            <Menu.Item bgColor="white">
              <View
                flex={1}
                position="relative"
                justifyContent="space-around"
                flexDirection="row"
                alignItems="center"
                bg="white"
              >
                <WithKNOWLoader
                  isLoading={isLoadingConfig}
                  color="white"
                  size="sm"
                >
                  <ExpoFAIcon name="sign-out" color="primary.900" size="xs" />
                  <Pressable onPress={signUserOut}>
                    <Text color="primary.900">{t('Sign out')}</Text>
                  </Pressable>
                </WithKNOWLoader>
              </View>
            </Menu.Item>
          </Menu>
        </Box>
      </View>
    </View>
  );
};
