import React, { useState } from 'react';
import { View, ScrollView, Text, Box } from '@know/ui';
import type { IAudioImageTextPDF } from './types';
import { StyleSheet } from 'react-native';
import { PDFFileAttachment } from './PDFFileAttachment';
import { ArrowRoundedBtn } from './TextImagePDF';
import './styles.css';
import AudioJs from './AudioJS';
import { StyleInnerHTML } from './StyleInnerHTML';
import { ImageWithLoader } from './ImageWithLoader';

export const AudioImageTextPDF: React.FC<IAudioImageTextPDF> = ({
  audioImageTextPdfObj,
  handleAudioConsumed,
  handleImageConsumed,
  handlePDFConsumed,
}) => {
  const imagesPayload = audioImageTextPdfObj?.payload?.images;
  const pdfPayload = audioImageTextPdfObj?.payload?.pdf;
  const audioPayload = audioImageTextPdfObj?.payload?.audio;
  const url = audioPayload[0]?.url;
  const consumeTime = audioPayload[0]?.consumeTime;
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const styles = StyleSheet.create({
    // @ts-ignore -> For webview need to remove translateZ in transform
    scrollViewTransform: { transform: 'none' },
  });

  return (
    <ScrollView style={styles.scrollViewTransform} flex={1}>
      {imagesPayload?.length > 0 && (
        <View backgroundColor="black">
          {imagesPayload?.length > 1 && (
            <Box
              position="absolute"
              zIndex={1}
              right={0}
              left={0}
              bottom={0}
              h={'80px'}
              bg={{
                linearGradient: {
                  colors: ['trasparent.100', 'trasparent.300'],
                  start: [0, 0],
                  end: [0, 1],
                },
              }}
            />
          )}
          {imagesPayload?.length > 1 && (
            <Box
              position="absolute"
              zIndex={1}
              left={0}
              top={0}
              bottom={0}
              w={'80px'}
              bg={{
                linearGradient: {
                  colors: ['trasparent.100', 'trasparent.300'],
                  start: [1, 0],
                  end: [0, 0],
                },
              }}
            />
          )}
          {imagesPayload?.length > 1 && (
            <Box
              position="absolute"
              zIndex={1}
              top={0}
              right={0}
              bottom={0}
              w={'80px'}
              bg={{
                linearGradient: {
                  colors: ['trasparent.100', 'trasparent.300'],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
            />
          )}
          <ImageWithLoader
            alt="audio-image"
            w="100%"
            source={{
              uri: imagesPayload[selectedImageIndex]?.url,
            }}
            h="25vh"
            resizeMode="contain"
          />
          <View
            zIndex={2}
            style={StyleSheet.absoluteFill}
            position="absolute"
            justifyContent="center"
          >
            <View
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              {selectedImageIndex > 0 ? (
                <View marginLeft="10px">
                  <ArrowRoundedBtn
                    iconName="chevron-back"
                    onPress={() => {
                      setSelectedImageIndex(selectedImageIndex - 1);
                    }}
                  />
                </View>
              ) : (
                <View />
              )}

              {selectedImageIndex < imagesPayload.length - 1 ? (
                <View marginRight="10px">
                  <ArrowRoundedBtn
                    iconName="chevron-forward"
                    onPress={() => {
                      handleImageConsumed(selectedImageIndex + 1);
                      setSelectedImageIndex(selectedImageIndex + 1);
                    }}
                  />
                </View>
              ) : (
                <View />
              )}
            </View>
          </View>
          <View
            position="absolute"
            bottom="10px"
            flexDirection="row"
            w="100%"
            justifyContent="center"
            zIndex={2}
          >
            {imagesPayload.length > 1 &&
              imagesPayload.map((_item, index) => {
                return (
                  <View
                    marginX="4px"
                    shadow={3}
                    bgColor={
                      index === selectedImageIndex ? 'primary.500' : 'white'
                    }
                    w="8px"
                    h="8px"
                    borderRadius={10}
                  />
                );
              })}
          </View>
        </View>
      )}

      <View margin="15px">
        {pdfPayload?.length > 0 && (
          <PDFFileAttachment
            pdfPayload={pdfPayload}
            handlePDFConsumed={handlePDFConsumed}
          />
        )}
        <View
          marginTop="10px"
          marginBottom="10px"
          bgColor="gray.200"
          borderRadius="10px"
          w="100%"
        >
          <View paddingX="10px" paddingY="6px">
            <AudioJs
              url={url}
              handleAudioConsumed={handleAudioConsumed}
              consumeTime={consumeTime}
            />
          </View>
        </View>

        {!!audioImageTextPdfObj?.title && (
          <Text
            fontWeight="semibold"
            fontSize={['18px', '18px', '18px', '20px', '20px']}
          >
            {audioImageTextPdfObj?.title}
          </Text>
        )}

        {!!audioImageTextPdfObj?.notes && (
          <Text
            fontSize={['14px', '14px', '14px', '15px', '15px']}
            color="gray.700"
          >
            <StyleInnerHTML data={audioImageTextPdfObj?.notes} />
          </Text>
        )}
      </View>
    </ScrollView>
  );
};
