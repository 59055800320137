import React, { useEffect } from 'react';
import {
  StackHeaderProps,
  View,
  WithKNOWLoader,
  SectionsListViewWithFilter,
} from '@know/ui';
import { useFormReceivedItems } from './useFormReceivedItems';

import {
  ItemComponent,
  getSectionHeaderComponent,
  keyExtractor,
} from '../ReceivedFormsPage/renderers';

export function ReceivedItemsPage({ route, navigation }: StackHeaderProps) {
  //@ts-ignore
  const formId = route?.params?.formId!;
  const { title, receivedFormItems, isLoading, onClickItem } =
    useFormReceivedItems(formId);

  useEffect(() => navigation.setOptions({ title }), [navigation, title]);

  return (
    <WithKNOWLoader
      isLoading={isLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <View h={'100%'} pr={2} pl={5} bgColor={'white'}>
        <SectionsListViewWithFilter
          hasFilter={false}
          data={receivedFormItems as any}
          onToggleSection={() => {}}
          onClickItem={onClickItem}
          keyExtractor={keyExtractor}
          getSectionHeaderComponent={getSectionHeaderComponent}
          ItemComponent={ItemComponent as any}
        />
      </View>
    </WithKNOWLoader>
  );
}
