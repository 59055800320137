import React from 'react';
import { Icon } from 'native-base';
import {
  Path,
  Circle,
  Rect,
  Defs,
  LinearGradient,
  Stop,
  G,
  ClipPath,
} from 'react-native-svg';

export const UploadAudioIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 42 42">
    <Circle cx="21" cy="21" r="21" fill="#00C1B2" />
    <Circle cx="21" cy="21" r="21" fill="white" fillOpacity="0.8" />
    <G clipPath="url(#clip0_108_33117)" fill="#00C1B2">
      <Path
        d="M25.6786 16.625C27.3448 18.8475 27.3448 23.1525 25.6786 25.375M29.4286 12.25C34.4136 17.01 34.4436 25.0213 29.4286 29.75"
        stroke="#00C1B2"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path
        d="M25.6786 16.625C27.3448 18.8475 27.3448 23.1525 25.6786 25.375M29.4286 12.25C34.4136 17.01 34.4436 25.0213 29.4286 29.75"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={'none'}
      />
      <Path
        d="M13.9026 25.5H13.9011H9.42859C9.03336 25.5 8.67859 25.1604 8.67859 24.6988V17.3C8.67859 16.84 9.03306 16.5 9.42859 16.5L13.9111 16.5L13.9129 16.5C14.1458 16.4992 14.3762 16.4512 14.59 16.3589C14.8039 16.2667 14.9969 16.132 15.1573 15.9632L15.1634 15.9568L15.1692 15.9502L18.9116 11.7237C19.3583 11.2692 20.1786 11.5545 20.1786 12.3038V29.6963C20.1786 30.4519 19.3466 30.7338 18.9026 30.2663L15.1701 26.0606L15.1701 26.0605L15.1629 26.0527C15.002 25.879 14.8071 25.7403 14.5902 25.6452C14.3734 25.5502 14.1393 25.5007 13.9026 25.5Z"
        fill="url(#paint0_linear_108_33117)"
      />
      <Path
        d="M13.9026 25.5H13.9011H9.42859C9.03336 25.5 8.67859 25.1604 8.67859 24.6988V17.3C8.67859 16.84 9.03306 16.5 9.42859 16.5L13.9111 16.5L13.9129 16.5C14.1458 16.4992 14.3762 16.4512 14.59 16.3589C14.8039 16.2667 14.9969 16.132 15.1573 15.9632L15.1634 15.9568L15.1692 15.9502L18.9116 11.7237C19.3583 11.2692 20.1786 11.5545 20.1786 12.3038V29.6963C20.1786 30.4519 19.3466 30.7338 18.9026 30.2663L15.1701 26.0606L15.1701 26.0605L15.1629 26.0527C15.002 25.879 14.8071 25.7403 14.5902 25.6452C14.3734 25.5502 14.1393 25.5007 13.9026 25.5Z"
        stroke="#00C1B2"
      />
      <Path
        d="M13.9026 25.5H13.9011H9.42859C9.03336 25.5 8.67859 25.1604 8.67859 24.6988V17.3C8.67859 16.84 9.03306 16.5 9.42859 16.5L13.9111 16.5L13.9129 16.5C14.1458 16.4992 14.3762 16.4512 14.59 16.3589C14.8039 16.2667 14.9969 16.132 15.1573 15.9632L15.1634 15.9568L15.1692 15.9502L18.9116 11.7237C19.3583 11.2692 20.1786 11.5545 20.1786 12.3038V29.6963C20.1786 30.4519 19.3466 30.7338 18.9026 30.2663L15.1701 26.0606L15.1701 26.0605L15.1629 26.0527C15.002 25.879 14.8071 25.7403 14.5902 25.6452C14.3734 25.5502 14.1393 25.5007 13.9026 25.5Z"
        stroke="black"
        strokeOpacity="0.2"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_108_33117"
        x1="14.4286"
        y1="11"
        x2="14.4286"
        y2="30.9997"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#88F4EC" />
        <Stop offset="1" stopColor="#00C1B2" />
      </LinearGradient>
      <ClipPath id="clip0_108_33117">
        <Rect
          width="25.7143"
          height="20"
          fill="white"
          transform="translate(8 11)"
        />
      </ClipPath>
    </Defs>
  </Icon>
);
