import React, { useState } from 'react';
import { View, Text, Button } from '@know/ui';
import type { ISurveyQuestion } from '../types';
import { StyleSheet, TextInput } from 'react-native';
interface Iprops {
  index: string;
  data: ISurveyQuestion;
  answer: any;
}
export const TextBox = ({
  data,
  handleProceedButton,
  setResponse,
  sectionId,
}: {
  data: Iprops;
  handleProceedButton: (questionIndex?: string) => void;
  setResponse: (value: any) => void;
  sectionId: string | null;
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState<string>('');
  const styles = StyleSheet.create({
    backArrowStyle: {
      width: '21px',
      height: '13px',
    },
    textBox: {
      padding: '10px',
      borderColor: isFocus ? '#00C1B2' : '#151c2e1a',
      borderWidth: 1,
      borderRadius: 6,
      marginTop: 5,
      outlineColor: '#00C1B2',
    },
  });

  return (
    <View padding="15px">
      {data.data.text && (
        <Text fontWeight="semibold" fontSize="18px" color="gray.900">
          {data.data.text}
        </Text>
      )}

      <TextInput
        onBlur={() => {
          setIsFocus(false);
        }}
        onFocus={() => setIsFocus(true)}
        style={styles.textBox}
        onChange={(txt) => {
          setValue(txt.nativeEvent.text);
        }}
      />

      <Button
        marginTop="16px"
        size={'sm'}
        onPress={() => {
          setResponse((prevState: any) => {
            const state = [...prevState];
            state.push({
              question: data.index,
              response: value,
              sectionID: sectionId,
            });
            return state;
          });
          handleProceedButton(data.index);
          setValue('');
        }}
        disabled={!value}
        opacity={!value ? 0.3 : 1}
      >
        <Text fontWeight={'semibold'} color={'white'}>
          Proceed
        </Text>
      </Button>
    </View>
  );
};
