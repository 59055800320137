import React, { useMemo } from 'react';
import {
  DrawerToggleButton,
  useNavigation,
  KNOWAppDownloadView,
  Divider,
  createSideBarNavigator,
} from '@know/ui';

import { useSubscribeToFormActionRequired } from '../useSubscribeToFormActionRequired';
import { ActionRequiredPage } from '../ActionRequiredPage';
import { NewFormsPage } from '../NewFormsPage';
import { DraftsFormsPage } from '../DraftsFormsPage';
import { ReceivedFormsPage } from '../ReceivedFormsPage';
import { SentFormsPage } from '../SentFormsPage';
import { useSubscribeToFormFeed } from '../useSubscribeToFormFeed';

export const linking = {
  initialRouteName: 'action-required',
  screens: {
    'action-required': {
      path: 'forms/list/action-required',
    },
    'new-forms': {
      path: 'forms/list/new-forms',
    },
    'draft-forms': {
      path: 'forms/list/draft-forms',
    },
    'sent-forms': {
      path: 'forms/list/sent-forms',
    },
    'received-forms': {
      path: 'forms/list/received-forms',
    },
  },
};

const FormSideBarNavigator = createSideBarNavigator();

export function FormsListNavigator() {
  const navigation: any = useNavigation();

  React.useLayoutEffect(() => {
    const headerLeft = () => (
      <DrawerToggleButton onPress={() => navigation.toggleDrawer()} />
    );

    navigation.setOptions({
      headerLeft,
    });
  }, [navigation]);

  const { numberOfActionRequiredItems } = useSubscribeToFormActionRequired();

  useSubscribeToFormFeed();

  const items = useMemo<any>(() => {
    return [
      {
        name: 'action-required',
        options: {
          title: 'Action Required',
          drawerLabel: 'Action Required',
          badge: numberOfActionRequiredItems,
        },
        component: ActionRequiredPage,
      },
      {
        name: 'new-forms',
        options: {
          title: 'New Forms',
          drawerLabel: 'New',
        },
        component: NewFormsPage,
      },
      {
        name: 'draft-forms',
        options: {
          title: 'Draft Forms',
          drawerLabel: 'Draft',
        },
        component: DraftsFormsPage,
      },
      {
        name: 'sent-forms',
        options: {
          title: 'Sent Forms',
          drawerLabel: 'Sent',
        },
        component: SentFormsPage,
      },
      {
        name: 'received-forms',
        options: {
          title: 'Received Forms',
          drawerLabel: 'Received',
        },
        component: ReceivedFormsPage,
      },
    ];
  }, [numberOfActionRequiredItems]);

  const module = 'My Forms';

  return (
    <React.Fragment>
      <KNOWAppDownloadView />
      <Divider />
      <FormSideBarNavigator module={module} items={items} isResponsive={true} />
    </React.Fragment>
  );
}
