import { Box, Text } from 'native-base';
import React from 'react';

interface IIssueStatusConfig {
  [issueStatus: string]: {
    id: string;
    color: string;
    backgroundColor: string;
  };
}

const OPEN_CONFIG = {
  id: 'OPEN',
  color: '#F95757',
  backgroundColor: '#FEEEEE',
}

const issueStatusConfigObject: IIssueStatusConfig = {
  open: OPEN_CONFIG,
  reopened: OPEN_CONFIG,
  closed: {
    id: 'CLOSED',
    color: '#0EBF75',
    backgroundColor: '#E6F8F1',
  },
};

const getLabel = (type: string) => {
  return issueStatusConfigObject[type].id;
};

export const IssueStatus = ({ type }: { type: string }) => {
  return (
    <Box
      display={'flex'}
      borderRadius={'4px'}
      backgroundColor={issueStatusConfigObject[type].backgroundColor}
      paddingX={'8px'}
      paddingY={'4px'}
      textAlign={'center'}
    >
      <Text
        fontSize={'14px'}
        fontWeight={700}
        fontFamily={'tableCell'}
        textTransform={type ? undefined : 'uppercase'}
        color={issueStatusConfigObject[type].color}
      >
        {getLabel(type) || type}
      </Text>
    </Box>
  );
};
