import React, { useMemo } from 'react';

import { Badge, Flex, Text } from '.';

interface ITagProps {
  label: string;
  variant: 'normal' | 'trasparent' | 'primary';
}

export const Tag = ({ label, variant = 'normal' }: ITagProps) => {
  const colors = useMemo(() => {
    if (variant === 'trasparent') {
      return {
        bg: 'rgba(255, 255, 255, 0.2)',
        color: 'white',
      };
    }
    if (variant === 'normal') {
      return {
        bg: 'gray.300',
        color: 'gray.700',
      };
    }
    if (variant === 'primary') {
      return {
        bg: 'gray.100',
        color: 'white',
      };
    }
  }, [variant]);

  return (
    <Flex direction="row" justifyContent={'flex-start'} flexWrap="wrap">
      <Badge
        rounded="full"
        bg={colors?.bg}
        px={'12px'}
        py={'2px'}
        borderWidth={0}
      >
        <Text
          noOfLines={2}
          maxWidth={['150px', '100%']}
          fontSize={['12px', '12px', '14px', '14px', '14px']}
          color={colors?.color}
          textTransform="capitalize"
          style={{ color: colors?.color }}
        >
          {label}
        </Text>
      </Badge>
    </Flex>
  );
};
