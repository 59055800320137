import React from 'react';
import { useCallback } from 'react';
// import PhoneInput from 'react-native-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface PNIProps {
  value: string;
  handleChangeValue: (
    newValue: string,
    countryCode: string,
    phone: string
  ) => void;
  defaultCountry?: string;
  preferredCountries?: Array<string>;
  containerClass?: string;
  handleSubmitEditing?: () => void;
}

export const PhoneNumberInput: React.FC<PNIProps> = ({
  value,
  handleChangeValue,
  defaultCountry = 'sg',
  preferredCountries = ['sg', 'in', 'ae'],
  containerClass = '',
  handleSubmitEditing,
}) => {
  const onChange = useCallback(
    (newVal, country) => {
      handleChangeValue(
        `+${newVal}`,
        country.dialCode,
        (newVal || '').replace(country.dialCode || '', '')
      );
    },
    [handleChangeValue]
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;

      if (handleSubmitEditing && key === 'Enter') {
        handleSubmitEditing();
      }
    },
    [handleSubmitEditing]
  );

  return (
    <PhoneInput
      enableSearch
      value={value}
      onChange={onChange}
      country={defaultCountry}
      preferredCountries={preferredCountries}
      containerClass={containerClass}
      onKeyDown={handleOnKeyDown}
    />
  );
};
