import React from 'react';
import { View, Pressable, Text, Button } from '@know/ui';
import type { ISurveyQuestion } from '../types';
import { Image as RNImage, StyleSheet } from 'react-native';
import Images from '../../../../Images/index';
interface Iprops {
  index: string;
  data: ISurveyQuestion;
}
export const TitleDiscription = ({
  data,
  handleProceedButton,
}: {
  data: Iprops;
  handleProceedButton: (questionIndex?: string) => void;
}) => {
  const styles = StyleSheet.create({
    backArrowStyle: {
      width: '21px',
      height: '13px',
    },
  });

  return (
    <View padding="15px">
      <Pressable
        marginBottom="20px"
        width="21px"
        height="13px"
        onPress={() => {
          console.log('Click');
        }}
      >
        <RNImage source={Images.BackArrowIcon} style={styles.backArrowStyle} />
      </Pressable>

      <Text fontSize="20px" color="gray.900" fontWeight="semibold">
        {data.data?.text}
      </Text>
      <Text fontSize="14px" color="#4E5656">
        {data.data?.description}
      </Text>
      <Button
        marginTop="16px"
        size={'sm'}
        onPress={() => {
          console.log('clicked on Proceed');
          handleProceedButton();
        }}
      >
        <Text fontWeight={'semibold'} color={'white'}>
          Proceed
        </Text>
      </Button>
    </View>
  );
};
