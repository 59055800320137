import { serverTimestamp, update } from "firebase/database";
import type { FirebaseAPI } from "../FirebaseAPI"

const nodePath = 'recentShareContacts';

export async function getRecentContacts(dbAPI: FirebaseAPI) {
  const nodeRef = dbAPI.getMultiPathCommon(`${nodePath}/${dbAPI.getLoggedInUserId()}`)
  const recentContacts: {[userId: string]: number } = await dbAPI.getValue(nodeRef) || {};
  const users: any[] = await Promise.all(
    Object.keys(recentContacts)
      .sort((userId1, userId2) => recentContacts[userId2] - recentContacts[userId1])
      .map(dbAPI.getUserDetails.bind(dbAPI))
  )
  return users
    .filter(user => user.isActive)
    .map(user => ({ id: user.userId, ...user }));
}

export async function updateRecentContacts(dbAPI: FirebaseAPI, users: string[]) {
  if (users.length > 0) {
    try {
      const recentContactUpdates = Object.fromEntries(
        users.map(userId => [userId, serverTimestamp()])
      )
      const nodeRef = dbAPI.getMultiPathCommon(`${nodePath}/${dbAPI.getLoggedInUserId()}`)
      await update(nodeRef, recentContactUpdates);
    } catch(err: any) {
      console.log(err, err.stack);
    }
  }
}
