import { useMemo, useEffect, useState, useContext, useCallback } from 'react';
import { get } from 'lodash';

import {
  useRecoilValue,
  useRecoilState,
  KNOWDBContext,
  useNavigation,
  useSetRecoilState,
} from '@know/ui';

import {
  courseDetailsSelector,
  currentCourseTabAtom,
  sessionIdAtom,
  userCompletedCourseFeedSelector,
  userCourseFeedSelector,
  isVideoAutoPlayedAtom,
  journeyCoursePayloadAtom,
  userCoursesFeedAtom,
  userCompletedCoursesFeedAtom,
  lmsReportsPayloadAtom,
  quizCardsAttemptsCountAtom,
} from '../state';
import type { IUserFeed } from '../types';
import useLMSAnalyticsCallback from '../CourseDetailsPage/useLMSAnalyticsCallback';

export const useCourseFeed = (courseId: string) => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const navigation = useNavigation();
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const setJourneyCoursePayload = useSetRecoilState(journeyCoursePayloadAtom);
  const setSessionId = useSetRecoilState(sessionIdAtom);
  const setQuizCardsAttempts = useSetRecoilState(quizCardsAttemptsCountAtom);
  const userFeed = useRecoilValue(
    currentCourseTab === 'completed-courses'
      ? userCompletedCourseFeedSelector(courseId)
      : userCourseFeedSelector(courseId)
  );
  const [courseDetails, setCourseDetails] = useRecoilState(
    courseDetailsSelector(courseId)
  );
  const setIsVideoAutoPlayed = useSetRecoilState(isVideoAutoPlayedAtom);
  const sessionId = useRecoilValue(sessionIdAtom);
  const setUserFeedObject = useSetRecoilState<{
    [key: string]: IUserFeed;
  }>(
    currentCourseTab === 'completed-courses'
      ? userCompletedCoursesFeedAtom
      : userCoursesFeedAtom
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const listenForFeed = async () => {
      if (currentDBApi) {
        await currentDBApi?.listenForUserFeedChangedFinishedCount(
          ([_key, feed]: any) => {
            setUserFeedObject((existingFeed: any) => {
              if (feed && currentCourseTab !== 'completed-courses') {
                return {
                  ...existingFeed,
                  [courseId]: {
                    ...existingFeed?.[courseId],
                    ...feed,
                  },
                };
              }
              return existingFeed;
            });
          },
          courseId
        );
      }
    };
    listenForFeed();
  }, [courseId, currentDBApi, setUserFeedObject, currentCourseTab]);

  const cardInfo = useMemo(() => {
    const language =
      userFeed?.lang ??
      courseDetails?.details?.defaultLanguage ??
      Object.keys(courseDetails?.details?.languages || {})[0] ??
      'eng';
    const finishedLessonsCount = userFeed?.finishedCount ?? 0;
    const totalLessons = courseDetails?.details?.totalCount ?? 0;
    const type =
      courseDetails?.type === 'learningPath' ? 'Learning Journey' : 'course';
    const title = get(courseDetails, ['name'], '')
      ? get(courseDetails, ['name'], '')
      : get(courseDetails, ['details', 'languages', language, 'title'], '');

    const description = get(courseDetails, ['description'], '')
      ? get(courseDetails, ['description'], '')
      : get(
          courseDetails,
          ['details', 'languages', language, 'description'],
          ''
        );
    const miniThumbnail = get(courseDetails, ['details', 'miniThumbnail'], '')
      ? get(courseDetails, ['details', 'miniThumbnail'], '')
      : get(
          courseDetails,
          ['details', 'languages', language, 'miniThumbnail'],
          ''
        );
    const progress =
      totalLessons === 0
        ? 0
        : Math.round((finishedLessonsCount * 100) / totalLessons);
    const languages: any = get(courseDetails, ['details', 'languages'], {});
    const shareId = get(userFeed, ['shareId'], '');
    const isSequence = get(courseDetails, ['payload', language, 'isSequence']);
    const isLJPath = courseDetails?.type === 'learningPath';
    return {
      miniThumbnail,
      title,
      totalCount: totalLessons,
      progress,
      type,
      languages: Object.keys(languages)
        .map((lang: any) => {
          let langValue = languages?.[lang];
          return { ...langValue, value: langValue?.value || lang };
        })
        .sort((a: any, b: any) => a.seq - b.seq),
      courseId,
      journeyId: isLJPath ? courseId : null,
      shareId,
      description,
      selectedLanguage: language,
      hasDefaultLanguage: ['', null, undefined].some(
        (el: any) => userFeed?.lang?.includes(el) || false
      ),
      finishedLessonsCount,
      isSequence,
      userFeedCreatedAt: userFeed.createdAt,
    };
  }, [userFeed, courseId, courseDetails]);

  useEffect(() => {
    const getCourseDetails = async () => {
      if (currentDBApi) {
        const courseDetailsValue = await currentDBApi.getLMSCourseDetails(
          courseId
        );
        if (courseDetailsValue) {
          setCourseDetails({
            ...courseDetailsValue,
            details: courseDetailsValue?.details,
          });
        } else {
          // Remove delete courses feed.
          setUserFeedObject((existingFeed: any) => {
            return Object.fromEntries(
              Object.keys(existingFeed || {})
                .filter((cid) => cid !== courseId)
                .map((cid) => [cid, existingFeed[cid]])
            );
          });
        }
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    getCourseDetails();
  }, [userFeed, courseId, currentDBApi, setCourseDetails, setUserFeedObject]);

  useEffect(() => {
    const getIsAutoplayVideo = async () => {
      if (currentDBApi) {
        // Do we need live listener here?
        const audioplayVideo = await currentDBApi.getIsVideoAutoplayEnabled();
        setIsVideoAutoPlayed(audioplayVideo);
      }
    };
    getIsAutoplayVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLearningPath = courseDetails?.type === 'learningPath';

  // LMS Analytics Reports Callback
  const {
    getTotalCards,
    progressLMS: progressLMSReport,
    isCourseCompleted,
  } = useLMSAnalyticsCallback(cardInfo);

  const setReportsPayload = useSetRecoilState(lmsReportsPayloadAtom);

  const handleJourneyCourses = async (journeyDetails: any) => {
    const payload = {};
    const courses = Object.keys(journeyDetails.details.courses);

    for (const id of courses) {
      const courseDetailsById = await currentDBApi?.getLMSCourseDetails(id);
      const coursePayload = get(
        courseDetailsById,
        ['payload', cardInfo.selectedLanguage, 'modules'],
        {}
      );
      Object.assign(payload, { ...coursePayload });
    }
    return payload;
  };
  const handleReportsPayload = async () => {
    const { hasCourseProgress, totalCardsConsumed } = progressLMSReport(
      isLearningPath,
      courseId
    );
    let courseStatus: 'inProgress' | 'notStarted' | 'completed';
    switch (hasCourseProgress) {
      case true:
        courseStatus = 'inProgress';
        break;
      case false:
        courseStatus = 'notStarted';
        break;
      default:
        courseStatus = 'notStarted';
        break;
    }

    if (isCourseCompleted(courseId, isLearningPath)) {
      courseStatus = 'completed';
    }
    const coursePayload = get(
      courseDetails,
      ['payload', cardInfo?.selectedLanguage, 'modules'],
      {}
    );
    let totalCards = null;
    if (isLearningPath) {
      const data = await handleJourneyCourses(courseDetails);
      totalCards = getTotalCards(data);
    } else {
      totalCards = getTotalCards(coursePayload);
    }
    const newSessionId = Date.now();
    setSessionId(`${newSessionId}`);
    setQuizCardsAttempts({});
    const payload = {
      courseId: courseId ?? null,
      journeyId: cardInfo?.journeyId ?? null,
      shareId: cardInfo?.shareId,
      totalCards,
      languageSelected: cardInfo?.selectedLanguage,
      courseStatus, // inProgress, notStarted, completed
      totalCardsConsumed,
      sessionId: newSessionId,
      sessionStartTime: Date.now(),
      type: 'callback',
      cardsCompletedInCurrentSession: 0,
      cardsCompletedInCurrentSessionIds: {},
      dt: Date.now(),
      isWebApp: true,
    };
    setReportsPayload((prevState) => {
      const state = { ...prevState };
      state[courseId] = payload;
      return state;
    });
  };

  const navigateToCourseDetails = useCallback(async () => {
    setJourneyCoursePayload({});
    const courseIdObj = isLearningPath ? { journeyId: courseId } : { courseId };
    if (currentCourseTab !== 'completed-courses' && !isLearningPath) {
      // if (currentCourseTab === 0 && courseId && userFeed?.shareId) {
      // const payload = {
      //   ...courseIdObj,
      //   dt: Date.now(),
      //   shareId: userFeed?.shareId,
      //   sessionId,
      //   lang: cardInfo?.hasDefaultLanguage
      //     ? userFeed?.lang
      //     : cardInfo?.selectedLanguage,
      // };
      // await currentDBApi?.setUserFeedCourseInfo(
      //   isLearningPath ? 'JourneyStarted' : 'CourseStarted',
      //   payload
      // );
      handleReportsPayload();
    }
    navigation.navigate(
      (isLearningPath ? 'journey-details' : 'course-details') as never,
      courseIdObj as never
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, navigation, courseDetails]);

  return {
    cardInfo,
    isLoading,
    sessionId,
    navigateToCourseDetails,
    userFeed,
  };
};
