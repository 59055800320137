import React from 'react';
import { ExpoMAIcon, Pressable, Text, HStack, Box } from '..';
import type { StackHeaderProps } from '..';

const breadCrumbNameMap: Record<string, string> = {
  folder: 'Folders',
  sentFormItems: 'Sent Forms',
  receivedFormItems: 'Received Forms',
};

export function FormsBreadCrumbHeader(props: StackHeaderProps) {
  const breadCrumbName = breadCrumbNameMap[props.route.name] || '';
  return (
    <HStack px={4} py={3} alignItems={'center'} bgColor={'white'}>
      <Pressable onPress={props.navigation.goBack}>
        <HStack alignItems={'center'}>
          <Box
            borderRadius={'full'}
            bg={'gray.250'}
            w={'24px'}
            h="24px"
            mr="2"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <ExpoMAIcon name="chevron-left" size={'18px'} />
          </Box>
          <Text color={'gray.900'} bold>
            {breadCrumbName} /
          </Text>
        </HStack>
      </Pressable>
      <Text
        bold
        ml={2}
        color="primary.500"
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {props?.options?.title}
      </Text>
    </HStack>
  );
}
