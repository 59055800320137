import { NativeBaseProvider } from 'native-base';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { KNOWRouteProvider } from './RouteProvider';
import { KNOWDBProvider } from './DBProvider';
import theme from './theme';
import { RecoilRoot } from '..';

import { LinearGradient } from 'expo-linear-gradient';

import type { IKNOWRouterContextInterface } from './RouteProvider';
import { WithKNOWLoader } from '..';
import { useFontsLoader } from '../Hooks/useFontsLoader';
import { EventEmitterProvider } from './EventEmitter';
import { KNOWValueProvider } from './KNOWValueProvider';

// eslint-disable-next-line no-undef
const parent = globalThis.parent as any;

interface AppContextInterface {
  mode: string;
  isSDK: boolean;
  sdkBackButtonHomePage: () => void;
}

export const KNOWContext = createContext<AppContextInterface>({
  mode: 'dark',
  isSDK: false,
  sdkBackButtonHomePage: () => {},
});

const KNOWContextProvider = KNOWContext.Provider;
interface Props extends IKNOWRouterContextInterface {
  isSDK?: boolean;
  enableNotifications?: boolean;
  isDark: boolean;
  children: React.ReactNode;
  servingFrom?: string;
}

export const useKNOWContextProvider = () => useContext(KNOWContext);

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (payload: string) => void;
    };
  }
}

window.ReactNativeWebView =
  window.ReactNativeWebView || window.parent.ReactNativeWebView;

export const KNOWProvider: React.FC<Props> = ({
  servingFrom = '',
  enableNotifications,
  isDark = false,
  hasAccessToModule,
  children,
  isSDK = false,
}) => {
  const mode = useMemo(() => (isDark ? 'dark' : 'light'), [isDark]);

  const { isLoading: isFontLoaded } = useFontsLoader();

  const sdkBackButtonHomePage = useCallback(() => {
    window.ReactNativeWebView?.postMessage?.(
      JSON.stringify({
        action: 'exit',
      })
    );
    parent?.knowInterfaceCallBack?.({
      type: 'exit',
    });
  }, []);

  return (
    <KNOWContextProvider
      value={{
        mode,
        isSDK,
        sdkBackButtonHomePage,
      }}
    >
      <NativeBaseProvider theme={theme} config={config}>
        <RecoilRoot>
          <EventEmitterProvider>
            <KNOWDBProvider
              enableNotifications={!!enableNotifications}
              isSDK={isSDK}
            >
              <KNOWValueProvider>
                <KNOWRouteProvider
                  hasAccessToModule={hasAccessToModule}
                  servingFrom={servingFrom}
                >
                  <WithKNOWLoader isLoading={!isFontLoaded} size="lg">
                    {children}
                  </WithKNOWLoader>
                </KNOWRouteProvider>
              </KNOWValueProvider>
            </KNOWDBProvider>
          </EventEmitterProvider>
        </RecoilRoot>
      </NativeBaseProvider>
    </KNOWContextProvider>
  );
};

const config = {
  dependencies: {
    'linear-gradient': LinearGradient,
  },
};
