import { useNavigation, useRecoilValue } from '@know/ui';
import { formFolderNameSelector, formFolderFormsSelector } from '../store';
import type { Item } from '@know/transformers';

export function useFormFolders(folderId: string) {
  const navigation = useNavigation();

  const folderTitle = useRecoilValue(formFolderNameSelector(folderId));
  const folderFormSectionItems = useRecoilValue(
    formFolderFormsSelector(folderId)
  );
  function onToggleSection(id: string) {
    console.log(`${id} section is toggled`);
  }

  function onClickItem(item: Item, type: string) {
    if (type === 'folder') {
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'folder',
          params: {
            folderId: item.id,
          },
        } as never
      );
    } else if (type === ('feed' as never)) {
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'webview',
          params: {
            formId: item.id,
            type: 'new',
            responseId: '-',
            initiatorId: '-',
          },
        } as never
      );
    }
    // Handle onClickEvent
  }
  return {
    folderTitle,
    folderFormSectionItems,
    onToggleSection,
    onClickItem,
  };
}
