import { useEffect, useState, useContext, useCallback } from 'react';
import {
  getLibraryDetails,
  getLMSNuggetDetails,
} from '@know/db';

import {
  useRecoilValue,
  KNOWDBContext,
  useSetRecoilState,
  useNavigation,
} from '@know/ui';

import {
  courseLibrarySectionItemsWithFilterSelector,
  courseLibrarySectionsAtom,
  courseLibrarySectionsItemsAtom,
  libraryFeedItemsAtom,
  libraryFilterTextAtom,
} from './state';
import { LibraryFeedItem } from '@know/transformers';

export const useCourseLibrary = () => {
  const { currentDBApi, getCurrentUserUserId } = useContext(KNOWDBContext);
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(true);
  const data = useRecoilValue(courseLibrarySectionItemsWithFilterSelector);
  const setSectionsAtom = useSetRecoilState(courseLibrarySectionsAtom);
  const setSectionItemsAtom = useSetRecoilState(courseLibrarySectionsItemsAtom);
  const setFeedItems = useSetRecoilState(libraryFeedItemsAtom);
  const setFilterText = useSetRecoilState(libraryFilterTextAtom);

  useEffect(() => {
    async function getLibrarySections() {
      if (currentDBApi) {
        const userId = getCurrentUserUserId();
        const libraryDetails = await getLibraryDetails(currentDBApi, userId);
        const { sections, sectionItems } = libraryDetails;
        setSectionsAtom(sections);
        setSectionItemsAtom(sectionItems);

        const lmsNuggetIds: string[] = [];
        for (const sectionKey in sectionItems) {
          const section = sectionItems[sectionKey];
          for (const itemKey in section) {
            const item = section[itemKey];
            if (!lmsNuggetIds.includes(item.id)) {
              lmsNuggetIds.push(item.id);
            }
          }
        }

        const sectionsFeedItems = (
          await Promise.all(
            lmsNuggetIds.map((nuggetId) =>
              getLMSNuggetDetails({ fbAPI: currentDBApi, nuggetId })
            )
          )
        ).filter(Boolean);

        setFeedItems(
          Object.fromEntries(sectionsFeedItems.map((item) => [item.id, item]))
        );

        setTimeout(() => setIsLoading(false));
      }
    }
    setIsLoading(true);
    getLibrarySections();
  }, [
    currentDBApi,
    getCurrentUserUserId,
    setFeedItems,
    setSectionItemsAtom,
    setSectionsAtom,
  ]);

  const handleNavigatingToCourse = useCallback(
    (item: LibraryFeedItem) => {
      navigation.navigate(
        'LaunchPublicCourse' as never,
        { aliasId: item.id } as never
      );
    },
    [navigation]
  );

  return {
    isLoading,
    setFilterText,
    data,
    handleNavigatingToCourse,
  };
};
