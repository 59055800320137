import React from 'react';
import {
  Box,
  NoDataForms,
  Table,
  Text,
  WithKNOWLoader,
  // View,
  FlatListWithFilter,
  TaskListRow,
} from '@know/ui';
import { useTaskFeed } from './useTaskFeed';

// const dummyData = [
//   {
//     taskExtId: '1',
//     title: 'D',
//     issueType: 'low',
//     severity: 'Low',
//     reportedBy: 'Dc',
//   },
//   {
//     taskExtId: '1',
//     title: 'D',
//     issueType: 'low',
//     severity: 'Low',
//     reportedBy: 'Dc',
//   },
// ];

export const TasksFeedPage = () => {
  const {
    isDataLoading,
    taskItems,
    filterText,
    initialState,
    columns,
    isSmallScreen,
    menuOptions,
    onFilterTextChange,
    onClick,
  } = useTaskFeed();

  return (
    <WithKNOWLoader
      isLoading={isDataLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      {isSmallScreen ? (
        <Box h={'100%'} mt={'12px'}>
          <FlatListWithFilter
            keyExtractor={(item) => `${item.taskId}`}
            data={taskItems}
            hasFilterText={!!filterText}
            filterText={filterText ?? ''}
            renderItem={({ item }) => (
              <TaskListRow
                item={item}
                onPress={onClick}
                menuOptions={menuOptions}
              />
            )}
            onFilterChange={onFilterTextChange}
            NoDataComponent={
              <NoDataForms
                icon="tasks"
                text="Looks like you currently do not have any tasks."
              />
            }
            NoResultsFoundComponent={
              <Box
                my={'24px'}
                w={'100%'}
                display={'flex'}
                flexDir={'row'}
                justifyContent={'center'}
              >
                <Text textAlign={'center'}>No results found.</Text>
              </Box>
            }
          />
        </Box>
      ) : (
        <Table
          columns={columns}
          data={taskItems}
          onRowClick={onClick}
          initialState={initialState}
          enableGlobalFilter={true}
          onChangeGlobalFilter={onFilterTextChange}
          isSearching={!!filterText}
          filter={filterText ?? ''}
          NoDataComponent={
            <NoDataForms
              icon="tasks"
              text="Looks like you currently do not have any tasks."
            />
          }
          NoResultsFoundComponent={
            <Box
              my={'24px'}
              w={'100%'}
              display={'flex'}
              flexDir={'row'}
              justifyContent={'center'}
            >
              <Text textAlign={'center'}>No results found.</Text>
            </Box>
          }
          unreadRowStatus={true}
        />
      )}
    </WithKNOWLoader>
  );
};
