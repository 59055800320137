import React from 'react';
import { View, Text, ScrollView } from '@know/ui';
import type { ITextOnly } from './types';
import { PDFFileAttachment } from './PDFFileAttachment';
import { useMediaQuery } from 'native-base';
import { StyleInnerHTML } from './StyleInnerHTML';

export const TextOnly: React.FC<ITextOnly> = ({
  textOnlyObj,
  handlePDFConsumed,
}) => {
  const pdfPayload = textOnlyObj?.payload?.pdf;
  const [media] = useMediaQuery({ maxWidth: 320 });

  return (
    <ScrollView flex={1}>
      <View padding="16px">
        {!!textOnlyObj?.title && (
          <Text
            color="gray.900"
            fontSize={media ? '20px' : '24px'}
            fontWeight="600"
            marginBottom="10px"
          >
            {textOnlyObj?.title}
          </Text>
        )}
        {pdfPayload?.length > 0 && (
          <PDFFileAttachment
            pdfPayload={pdfPayload}
            handlePDFConsumed={handlePDFConsumed}
          />
        )}
        <StyleInnerHTML data={textOnlyObj?.notes} />
      </View>
    </ScrollView>
  );
};
