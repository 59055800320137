import React from 'react';
import { Animated } from 'react-native';
import { Text, View, Button, Pressable } from '@know/ui';
import { Ionicons } from '@expo/vector-icons';

interface INextPreviousButton {
  selectedIndex: number;
  handleNextQuestion: (payload: any, notSupportedContent: boolean) => void;
  timerRef: any;
  fadeAnim: any;
  setSelectedIndex: (index: any) => void;
  questions: any;
  quizProgress: any;
  handleSubmitQuiz: () => void;
  isDisabledNextButton: () => boolean;
  hotspotIconsQuizRes: any;
  isHotspotType: boolean;
  notSupportedContent: boolean;
  isTimer: boolean;
}

const NextPreviousButton = ({
  selectedIndex,
  handleNextQuestion,
  timerRef,
  fadeAnim,
  setSelectedIndex,
  questions,
  quizProgress,
  // handleSubmitQuiz,
  isDisabledNextButton,
  hotspotIconsQuizRes,
  isHotspotType,
  notSupportedContent,
  isTimer,
}: INextPreviousButton) => {
  const handleNext = () => {
    let payload = {
      selectedIndex,
      selectedOption: quizProgress[selectedIndex],
    };
    if (isHotspotType) {
      payload = { selectedIndex, selectedOption: hotspotIconsQuizRes };
    }
    handleNextQuestion(payload, notSupportedContent);
  };

  return (
    <View
      flexDirection="row"
      justifyContent={
        selectedIndex !== 0 && !isTimer ? 'space-between' : 'flex-end'
      }
      alignItems="center"
      marginY="8px"
      height="43px"
    >
      {selectedIndex !== 0 && !isTimer && (
        <Pressable
          onPress={() => {
            if (selectedIndex > 0) {
              clearInterval(timerRef.current as NodeJS.Timer);
              Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
              setTimeout(() => {
                setSelectedIndex(selectedIndex - 1);
                Animated.timing(fadeAnim, {
                  toValue: 1,
                  duration: 200,
                  useNativeDriver: true,
                }).start();
              }, 200);
            }
          }}
          bgColor="white"
        >
          <View
            flex={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
          >
            <Ionicons name="chevron-back" size={18} color="#00C1B2" />
            <Text marginLeft="5px" color="primary.500">
              Previous
            </Text>
          </View>
        </Pressable>
      )}

      {selectedIndex + 1 === questions.length &&
        (quizProgress.length === questions.length || notSupportedContent) && (
          <Button
            onPress={() => {
              handleNext();
              // handleSubmitQuiz();
            }}
            colorScheme={'primary'}
            marginX="5px"
            width="138px"
            height="43px"
            opacity={isDisabledNextButton() ? 0.3 : 1}
            disabled={isDisabledNextButton()}
          >
            <Text fontWeight="semibold" color="white">
              Submit
            </Text>
          </Button>
        )}
      {(selectedIndex < quizProgress.length ||
        notSupportedContent ||
        questions[selectedIndex].type === 'checkbox' ||
        questions[selectedIndex].type === 'hotspots') &&
      selectedIndex < questions.length - 1 ? (
        <View
          flexDirection="row"
          justifyContent="flex-end"
          marginY="17px"
          height="43px"
        >
          <Button
            onPress={handleNext}
            colorScheme={'primary'}
            marginX="5px"
            width="138px"
            height="43px"
            opacity={isDisabledNextButton() ? 0.3 : 1}
            disabled={isDisabledNextButton()}
          >
            <Text fontWeight="semibold" color="white">
              Next
            </Text>
          </Button>
        </View>
      ) : null}
    </View>
  );
};

export default NextPreviousButton;
