import React, { useCallback, useMemo } from 'react';
import {
  ResponseStatus,
  Text,
  useRecoilValue,
  HStack,
  RepeatIcon,
  WFFormIcon,
  VStack,
  Box,
  ClockIcon,
  useNavigation,
  useRecoilState,
  useKNOWValueContextProvider,
} from '@know/ui';
import {
  filteredActionRequiredFeedItemsSelector,
  formActionRequiredItemsSearchStringAtom,
  isLoadingActionRequiredAtom,
} from '../store';

import { getDateParts } from './utils';
import { ActionRequiredFlatListItemRender } from './renderers';

export const useFormActionRequired = () => {
  const isLoading = useRecoilValue(isLoadingActionRequiredAtom);
  const feedItems = useRecoilValue(filteredActionRequiredFeedItemsSelector);
  const [filterText, setFilterText] = useRecoilState(
    formActionRequiredItemsSearchStringAtom
  );

  const { isTabletSize, isPhoneSize, isLargePhoneSize } =
    useKNOWValueContextProvider();
  const isSmallScreen = isTabletSize || isPhoneSize || isLargePhoneSize;

  const navigation = useNavigation();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Name
          </Text>
        ),
        accessor: 'name', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => (
          <HStack alignItems={'flex-start'} flex={1}>
            {row?.original?.isRepeating ? (
              <RepeatIcon size="16px" mr="6px" mt="2px" />
            ) : (
              <WFFormIcon size="16px" mr="6px" mt="2px" />
            )}
            <VStack flex={1}>
              <Text fontFamily={'tableCell'} fontWeight={600} mr={'6px'}>
                {row?.values?.name}
              </Text>
              {row?.original?.sno ? (
                <Text
                  fontFamily={'tableCell'}
                  fontWeight={400}
                  color={'gray.700'}
                >
                  {row?.original?.sno}
                </Text>
              ) : null}
            </VStack>
          </HStack>
        ),
        flex: 2,
      },
      {
        id: 'dueOn',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Due On
          </Text>
        ),
        accessor: 'dueOn', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const dueOn = row?.values?.dueOn;
          if (!dueOn) {
            return (
              <Text
                fontFamily={'tableCell'}
                fontWeight={400}
                color={'gray.700'}
              >
                {''}
              </Text>
            );
          }

          const [day, time] = getDateParts(dueOn);

          return (
            <HStack flex={1} alignItems={'center'}>
              <Box
                borderColor={'gray.300'}
                borderWidth={'1px'}
                borderRadius={'4px'}
                padding={'4px 8px'}
                mr={'6px'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                <ClockIcon size="18px" mb="-2px" />
                <Text
                  color={'red.400'}
                  fontFamily={'tableCell'}
                  fontWeight={600}
                >
                  {day}
                </Text>
              </Box>
              <Box
                borderColor={'gray.300'}
                borderWidth={'1px'}
                padding={'4px 8px'}
                borderRadius={'4px'}
              >
                <Text
                  color={'red.400'}
                  fontFamily={'tableCell'}
                  fontWeight={600}
                >
                  {time}
                </Text>
              </Box>
            </HStack>
          );
        },
      },
      {
        id: 'receivedOn',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Date Received
          </Text>
        ),
        accessor: 'receivedOn', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const receivedOn = row?.values?.receivedOn;
          if (!receivedOn) {
            return (
              <Text
                fontFamily={'tableCell'}
                fontWeight={400}
                color={'gray.700'}
              >
                {''}
              </Text>
            );
          }

          const [day, time] = getDateParts(receivedOn);

          return (
            <Text fontFamily={'tableCell'} fontWeight={400} color={'gray.700'}>
              {day}, {time}
            </Text>
          );
        },
      },
      {
        id: 'status',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Status
          </Text>
        ),
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => (
          <ResponseStatus status={row?.values?.status} />
        ),
        enableSorting: false,
      },
      {
        id: 'senderName',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Sender
          </Text>
        ),
        accessor: 'senderName',
        Cell: ({ row }: { row: any }) => (
          <Text fontFamily={'tableCell'} fontWeight={400} color={'gray.700'}>
            {row?.values?.senderName}
          </Text>
        ),
      },
    ],
    []
  );

  const onClick = useCallback(
    (row: any) => {
      if (row.responseId && row.formId && row.initiatorId) {
        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId: row.formId,
              responseId: row.responseId,
              initiatorId: row.initiatorId,
              type: 'received',
            },
          } as never
        );
      } else if (row.formId) {
        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId: row.formId,
              type: 'new',
              responseId: '-',
              initiatorId: '-',
            },
            initial: false,
          } as never
        );
      }
    },
    [navigation]
  );

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'dueOn', desc: true }],
    }),
    []
  );

  const FlatListItem = useMemo(
    () => ActionRequiredFlatListItemRender(onClick),
    [onClick]
  );

  return {
    isLoading,
    feedItems,
    columns,
    onClick,
    initialState,
    setFilterText,
    filterText,
    isSmallScreen,
    FlatListItem,
  };
};
