import {
  FirebaseAPI,
  UploadFileResultType,
  addIssueDetailsProgressUpdate,
  addIssueFormProgressUpdate,
  addIssueStatusProgressAttachment,
} from "..";

export const issueSeverities = {
  Low: {
    name: "Low",
    color: "#5ac7db",
  },
  Medium: {
    name: "Medium",
    color: "#56aa4f",
  },
  High: {
    name: "High",
    color: "#f08c33",
  },
  Critical: {
    name: "Critical",
    color: "#d62a2a",
  },
};

export const createIssue = async (
  currentDbApi: FirebaseAPI,
  value: {
    name?: string;
    issueType?: {
      id: string;
      name: string;
      notifyLeaders?: boolean;
    };
    severity?: "Low" | "Medium" | "High" | "Critical";
    location?: {
      id: string;
      name: string;
    };
    notes?: string;
    notifyLeaders?: boolean;
    images?: UploadFileResultType[];
    videos?: UploadFileResultType[];
    pdfFiles?: UploadFileResultType[];
    authorName?: string;
    createForm?: {
      formId: string;
      responseId: string;
    };
  }
) => {
  const payload = {
    name: value.name ?? `${Date.now()}`,
    createdAt: currentDbApi.getServerTimestamp(),
    likes: 0,
    organization: currentDbApi.organization ?? "na",
    author: currentDbApi.getLoggedInUserId() ?? "na-user-id",
    authorName: value.authorName ?? "na-user-name",
    isReply: false,
    notes: value.notes ?? "",
    classificationType: "tasklist",
    incidentLocation: value.location?.name ?? null,
    incidentType: value.issueType?.name ?? null,
    incidentID: value.issueType?.id ?? null,
    status: "open",
    locationId: value.location?.id ?? null,
    published: true,
    private: false,
    notifyLeaders: (value.issueType?.notifyLeaders || value.notifyLeaders) ?? null,
    severity: value.severity ?? "Low",
    createForm: value.createForm ?? null,
    severityColor: issueSeverities[value.severity ?? "Low"].color,
    type: "incident-text-only",
    payload: {
      images: value.images ?? null,
      video: value.videos ?? null,
    },
  };

  let type = payload.type;
  if (value.images?.length) {
    type = "incident-image-text";
  }
  if (value.videos?.length) {
    type = "incident-video-only";
  }
  payload.type = type;

  const issueNuggetsPath = `${currentDbApi.getCommonPath("nuggets")}/tasklist`;

  const issueRef = await currentDbApi.pushToNode(issueNuggetsPath, payload);
  const issueId = issueRef.key ?? `${Date.now()}`;

  await Promise.all([
    addIssueDetailsProgressUpdate(currentDbApi, issueId, {
      location: value.location?.name ?? "location-name",
      title: value.name ?? `${Date.now()}`,
      userName: value.authorName ?? "author-name",
    }),
    value.createForm
      ? addIssueFormProgressUpdate(currentDbApi, issueId, "create_form", {
          ...value.createForm,
          title: value.name ?? `${Date.now()}`,
          userName: value.authorName ?? "author-name",
        })
      : null,
    value.images?.length
      ? addIssueStatusProgressAttachment(
          currentDbApi,
          issueId,
          "image",
          value.images,
          value.authorName ?? "author-name"
        )
      : null,
    value.videos?.length
      ? addIssueStatusProgressAttachment(
          currentDbApi,
          issueId,
          "video",
          value.videos,
          value.authorName ?? "author-name"
        )
      : null,
    value.pdfFiles?.length
      ? addIssueStatusProgressAttachment(
          currentDbApi,
          issueId,
          "pdf",
          value.pdfFiles,
          value.authorName ?? "author-name"
        )
      : null,
  ]);

  return issueId;
};
