//TODO: make a native app friendly version
import React, {
  forwardRef,
  useCallback,
  // useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

// import Geolocation, {
//   GeolocationResponse,
// } from '@react-native-community/geolocation';

// import { encode as base64_encode } from 'base-64';

import { WebView } from '../Webview';
import {
  Box,
  ExpoIonicIcon,
  Text,
  useKNOWEmitterContext,
  useModalParam,
  useToast,
  useWebOnVisibilityHidden,
} from '@know/ui';
import { SignatureCanvasModal } from './SignatureCanvasModal';
import type {
  RestrictToGroupType,
  RestrictToUserType,
  UploadProgressType,
  WebviewMessagePayload,
} from './types';
import { UploadImageModal } from './UploadImageModal';
import { UploadVideoModal } from './UploadVideoModal';
import { UploadFileHandler, UploadFileHandlerRef } from './UploadFileHandler';
import { UploadProgressModal } from './UploadProgressModal';
import { QRScannerOverlay } from './QRScannerOverlay';
import { SpinnerModal } from './SpinnerModal';
import {
  SelectItemModal,
  SelectItemType,
  SelectUsersItem,
  SelectUsersModal,
} from '../Modals';
import { Platform } from 'react-native';

import { prepareUsersDetailsForFormWebview } from '@know/transformers';

const FORM_WEBVIEW_PDF_HTML_EVENT = 'FORM_WEBVIEW_PDF_HTML_EVENT';

interface FormWebviewProps {
  url: string;
  userInfo: any;
  formDefinition?: any;
  buttonLabels: any;
  locations: any;
  reviewMode: boolean;
  formResponses?: any;
  embedded?: boolean;
  onHeightChange?: (newValue?: number) => any;
  getUsersOptions: (
    filter: string,
    options: { users?: RestrictToUserType[]; groups?: RestrictToGroupType[] }
  ) => Promise<SelectUsersItem[]>;
  uploadFiles: (
    files: File[],
    progressCallback: (progress: number) => void
  ) => Promise<string[] | undefined> | undefined;
  writeResponses: (data: any) => Promise<void> | void;
  sendToNext: (shareRequest: any, sectionId: string) => Promise<void> | void;
  submit: (isRejected?: boolean) => Promise<any> | any;
  onDataWrite?: () => void;
  openTask?: (taskId: string) => any;
  taskDetails?: {
    taskId: string;
    groupId: string;
    questionId: string;
  };
  onEmbedSubmitStatus?: (data?: onEmbedSubmitStatusPayloadInterface) => any;
}

export interface FormWebviewRef {
  writeData: (isCallback?: boolean) => void;
  showSpinner: () => void;
  closeSpinner: () => void;
  printPdf: (
    userInfo: any,
    companyInfo: any,
    location: any,
    paperTrail: any
  ) => Promise<string>;
  setFormDefinition: (formDefinition: any, formResponse: any) => void;
  embedSubmit: () => void;
}

export interface onEmbedSubmitStatusPayloadInterface {
  success: boolean;
  errorElement: DOMRect;
}

// function objToBase64(obj: object) {
//   return base64_encode(JSON.stringify(obj));
// }

export const FormWebview = forwardRef<FormWebviewRef, FormWebviewProps>(
  (props, ref) => {
    const [
      isSignatureModalOpen,
      signatureModalProps,
      openSignatureModal,
      closeSignatureModal,
    ] =
      useModalParam<{ onSubmit: (response: File | undefined) => void }>(false);

    const [
      isUploadImageModalOpen,
      uploadImageModalProps,
      openUploadImageModal,
      closeUploadImageModal,
    ] = useModalParam<{
      onSubmit?: (response: File[] | undefined) => void;
      limit: number;
      restrictImagePicker: boolean;
    }>(false, { limit: 1, restrictImagePicker: false });

    const [
      isUploadVideoModalOpen,
      uploadVideoModalProps,
      openUploadVideoModal,
      closeUploadVideoModal,
    ] = useModalParam<{
      onSubmit?: (response: File[] | undefined) => void;
      limit: number;
      restrictImagePicker: boolean;
    }>(false, { limit: 1, restrictImagePicker: false });

    const [uploadFileHandlerProps, setUploadFileHandlerProps] = useState<{
      onSubmit?: (response: File[] | undefined) => void;
      limit: number;
    }>({ limit: 1 });

    const [
      isQRScannerOverlayOpen,
      qrScannerOverlayProps,
      openQRScannerOverlay,
      closeQRScannerOverlay,
    ] = useModalParam<{
      onSubmit?: (response: string | undefined) => void;
    }>(false);

    const [
      isTypeAndSearchDialogOpen,
      typeAndSearchDialogProps,
      openTypeAndSearchDialog,
      closeTypeAndSearchDialog,
      updateTypeAndSearchDialogProps,
    ] = useModalParam<{
      options: SelectItemType[];
      onSubmit: (items: SelectItemType[]) => any;
      title: string;
      value?: string;
    }>(false, { options: [], onSubmit: () => {}, title: '' });

    const [webviewRef, setWebviewRef] = useState<HTMLIFrameElement | null>(
      null
    );
    // const [userLocation, setUserLocation] = useState<GeolocationResponse>();

    const uploadFileHandlerRef = useRef<UploadFileHandlerRef | null>(null);

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadProgressProps, setUploadProgressProps] = useState<{
      type: UploadProgressType;
      numberOfFiles: number;
    }>({ type: 'signature', numberOfFiles: 1 });

    const [
      isSelectContactModalOpen,
      selectContactModalProps,
      openSelectContactModal,
      closeSelectContactModal,
      updateSelectContactModalProps,
    ] = useModalParam<{
      title?: string;
      isLoading?: boolean;
      options?: SelectUsersItem[];
      onFilterChange?: (
        filter: string,
        additionalProps?: any
      ) => void | Promise<void>;
      onSubmit?: (items: SelectUsersItem[]) => void;
    }>(false, { isLoading: false, options: [] });

    const [showSpinnerModal, setShowSpinnerModal] = useState(false);

    const toast = useToast();
    const { registerListener, emitEvent } = useKNOWEmitterContext();

    useImperativeHandle(
      ref,
      () => {
        return {
          writeData(isCallback?: boolean) {
            const iframeWindow = webviewRef?.contentWindow?.window as any;
            iframeWindow.getLastResponseEdit &&
              iframeWindow.getLastResponseEdit({ isCallback });
          },
          showSpinner() {
            setShowSpinnerModal(true);
          },
          closeSpinner() {
            setShowSpinnerModal(false);
          },
          printPdf(
            userInfo: any,
            companyInfo: any,
            location: any,
            paperTrail: any
          ) {
            return new Promise((res, rej) => {
              try {
                const unsub = registerListener(
                  'FORM_WEBVIEW_PDF_HTML_EVENT',
                  (result) => {
                    res(result);
                    unsub();
                  }
                );
                const iframeWindow = webviewRef?.contentWindow?.window as any;
                iframeWindow.getHtmlNonB64 &&
                  iframeWindow.getHtmlNonB64(
                    userInfo,
                    companyInfo,
                    location,
                    paperTrail
                  );
              } catch (err: any) {
                console.log(err, err.stack);
                rej(err);
              }
            });
          },
          setFormDefinition(formDefinition: any, formResponses: any) {
            const formDefinitionData = {
              userInfo: props.userInfo,
              formDefinition: formDefinition,
              buttonLabels: props.buttonLabels,
              locations: props.locations,
              reviewMode: props.reviewMode,
              hasLocation: formDefinition?.captureLocation ?? false,
              formResponses: formResponses,
            };

            const iframeWindow = webviewRef?.contentWindow?.window as any;
            iframeWindow.updateFormDefinition &&
              iframeWindow.updateFormDefinition(formDefinitionData);
          },
          embedSubmit() {
            const iframeWindow = webviewRef?.contentWindow?.window as any;
            iframeWindow.embedSubmit?.();
          },
        };
      },
      [
        props.buttonLabels,
        props.locations,
        props.reviewMode,
        props.userInfo,
        registerListener,
        webviewRef?.contentWindow?.window,
      ]
    );

    // useEffect(() => {
    //   if (!webviewRef || !webviewRef.contentWindow || !userLocation?.coords) {
    //     return;
    //   }
    //   const window = webviewRef.contentWindow.window as any;
    //   window.updateLocation?.(
    //     objToBase64({
    //       currentLocation: {
    //         latitude: userLocation?.coords?.latitude,
    //         longitude: userLocation?.coords?.longitude,
    //       },
    //     })
    //   );
    // }, [userLocation, webviewRef]);

    // useEffect(() => {
    //   Geolocation.watchPosition((position: GeolocationResponse) => {
    //     console.log(position);
    //     setUserLocation(position);
    //   }, console.warn);
    // }, []);

    const onWebviewLoad = useCallback(
      (iframe: HTMLIFrameElement | null) => {
        const formDefinitionData = {
          userInfo: props.userInfo,
          formDefinition: props.formDefinition,
          buttonLabels: props.buttonLabels,
          locations: props.locations,
          reviewMode: props.reviewMode,
          hasLocation: props.formDefinition?.captureLocation ?? false,
          formResponses: props.formResponses ?? undefined,
          embedded: props.embedded,
        };

        if (!iframe || !iframe.contentWindow) return;
        setWebviewRef(iframe);
        const window = iframe.contentWindow.window as any;
        window.updateFormDefinition &&
          window.updateFormDefinition(formDefinitionData);

        if (props.taskDetails) {
          window.scrollToTask?.(
            props.taskDetails.groupId,
            props.taskDetails.questionId,
            props.taskDetails.taskId
          );
        }

        if (props.onHeightChange) {
          window.listenForHeightChange?.();
        }
      },
      [
        props.buttonLabels,
        props.formDefinition,
        props.formResponses,
        props.locations,
        props.reviewMode,
        props.taskDetails,
        props.userInfo,
        props.embedded,
        props.onHeightChange,
      ]
    );

    const {
      uploadFiles,
      writeResponses,
      submit,
      getUsersOptions,
      sendToNext,
      onDataWrite,
      openTask,
      onHeightChange,
      onEmbedSubmitStatus,
    } = props;

    const _uploadFiles = useCallback(
      async (files: File[], type: UploadProgressType) => {
        setUploadProgress(0);
        setUploadProgressProps({ numberOfFiles: files.length, type });
        setIsUploading(true);
        const results = await uploadFiles(files, (progress) => {
          setUploadProgress(progress);
        });
        setUploadProgress(100);
        setIsUploading(false);
        setUploadProgress(0);

        return results ?? [];
      },
      [uploadFiles]
    );

    const onVisibilityHidden = useCallback(() => {
      const iframeWindow = webviewRef?.contentWindow?.window as any;
      iframeWindow.getLastResponseEdit && iframeWindow.getLastResponseEdit();
    }, [webviewRef?.contentWindow?.window]);

    useWebOnVisibilityHidden(onVisibilityHidden);

    const onWebviewMessage = useCallback(
      async (e: MessageEvent) => {
        const data = e.data as WebviewMessagePayload;
        const iframeWindow = webviewRef?.contentWindow?.window as any;

        switch (data.type) {
          case 'signature':
            openSignatureModal({
              onSubmit: async (file: File | undefined) => {
                if (file) {
                  const fileUrls = await _uploadFiles([file], 'signature');
                  const answer = {
                    ...data,
                    response: fileUrls,
                  };

                  iframeWindow.setResponse &&
                    iframeWindow.setResponse(JSON.stringify(answer));
                }
              },
            });
            break;
          case 'upload_file':
            openUploadImageModal({
              limit: data.limit ?? 1,
              onSubmit: async (files: File[] | undefined) => {
                if (files && files.length) {
                  const fileUrls = await _uploadFiles(files, 'image');
                  const answer = {
                    ...data,
                    response: fileUrls,
                  };

                  iframeWindow.setResponse &&
                    iframeWindow.setResponse(JSON.stringify(answer));
                }
              },
              restrictImagePicker: data.restrictImagePicker ?? false,
            });
            break;
          case 'upload_video_file':
            openUploadVideoModal({
              limit: data.limit ?? 1,
              onSubmit: async (files: File[] | undefined) => {
                if (files && files.length) {
                  const fileUrls = await _uploadFiles(files, 'video');
                  const answer = {
                    ...data,
                    response: fileUrls,
                  };

                  iframeWindow.setResponse &&
                    iframeWindow.setResponse(JSON.stringify(answer));
                }
              },
              restrictImagePicker: data.restrictImagePicker ?? false,
            });
            break;
          case 'upload_mixed':
            setUploadFileHandlerProps({
              limit: data.limit ?? 1,
              onSubmit: async (files: File[] | undefined) => {
                if (files && files.length) {
                  const fileUrls = await _uploadFiles(files, 'file');
                  const response = files.map((f, index) => {
                    return {
                      url: fileUrls[index],
                      fileName: f.name,
                      mime: f.type,
                    };
                  });
                  const answer = {
                    ...data,
                    response,
                  };

                  iframeWindow.setResponse &&
                    iframeWindow.setResponse(JSON.stringify(answer));
                }
              },
            });
            uploadFileHandlerRef.current?.onClick();
            break;
          case 'qr_code':
            openQRScannerOverlay({
              onSubmit: async (response: string | undefined) => {
                if (response) {
                  const answer = {
                    ...data,
                    response: [response],
                  };

                  iframeWindow.setResponse &&
                    iframeWindow.setResponse(JSON.stringify(answer));
                }
              },
            });
            break;
          case 'submit-responses':
            const { response, isBlocking } = data;
            if (isBlocking) {
              setShowSpinnerModal(true);
            }
            await writeResponses(response);
            iframeWindow.setResponse &&
              iframeWindow.setResponse(
                JSON.stringify({ ...data, isPostMessageCallback: true })
              );
            break;
          case 'send-message':
            await writeResponses({ [data.id as string]: data.response });
            break;
          case 'submit-button':
            try {
              await submit();
            } catch (err: any) {
              if (err.message === 'LocationNotAllowed') {
                toast.show({
                  title: 'Location Not Allowed',
                  bg: 'red.500',
                  size: 'xl',
                });
              }
            } finally {
              setShowSpinnerModal(false);
            }
            break;
          case 'send_to_next':
            const { sectionId = 'section-1', shareRequest = {} } = data;
            await sendToNext(shareRequest, sectionId);
            break;
          case 'reject_form':
            try {
              await submit(true);
            } catch (err: any) {
              console.log(err);
            } finally {
              setShowSpinnerModal(false);
            }
            break;
          case 'contact':
            openSelectContactModal({
              title: 'Select Next Reviewer',
              isLoading: true,
              options: [],
              //TODO: make this works with cache - currently since this is passed when modal is opened, cache won't work since the function reference won't update
              onFilterChange: async (filter: string) => {
                updateSelectContactModalProps((currentProps) => ({
                  ...currentProps,
                  isLoading: true,
                }));

                const userOptions = await getUsersOptions(filter, {
                  users: data.users,
                  groups: data.groups,
                });

                updateSelectContactModalProps((currentProps) => ({
                  ...currentProps,
                  isLoading: false,
                  options: userOptions,
                }));
              },
              onSubmit: (items: SelectUsersItem[]) => {
                const formattedItems = items.map((item) =>
                  prepareUsersDetailsForFormWebview(item)
                );

                const answer = {
                  ...data,
                  response: formattedItems,
                };

                iframeWindow.setResponse &&
                  iframeWindow.setResponse(JSON.stringify(answer));
              },
            });

            const userOptions = await getUsersOptions('', {
              users: data.users,
              groups: data.groups,
            });

            updateSelectContactModalProps((currentProps) => ({
              ...currentProps,
              isLoading: false,
              options: userOptions,
            }));
            break;
          case 'finish-submit-response':
            onDataWrite && onDataWrite();
            break;
          case 'preview-mixed':
          case 'preview-video':
            if (Platform.OS === 'web') {
              try {
                window.open(data.url, '_blank');
              } catch (error) {
                console.log(e);
              }
            }
            break;
          case 'html-preview':
            const { html } = data;
            html && emitEvent(FORM_WEBVIEW_PDF_HTML_EVENT, html);
            break;
          case 'open-task':
            const { taskId } = data;
            taskId && openTask?.(taskId);
            break;
          case 'height-change':
            const { value } = data as { value: number };
            onHeightChange?.(value);
            break;
          case 'embed-submit-status':
            onEmbedSubmitStatus?.(
              data.payload as onEmbedSubmitStatusPayloadInterface
            );
            break;
          case 'dropdown_dialog':
            const {
              value: currentValue,
              items,
              dialogHeaderText,
            } = data as {
              value: string;
              items: SelectItemType[];
              dialogHeaderText: string;
            };

            let sortedOptions = items;
            const currentSelectedOption = items.filter(
              ({ value: optValue }) => optValue === currentValue
            );

            if (currentSelectedOption.length) {
              const withoutSelectedOption = items.filter(
                ({ value: optValue }) => optValue !== currentValue
              );

              sortedOptions = [
                ...currentSelectedOption,
                ...withoutSelectedOption,
              ];
            }

            openTypeAndSearchDialog({
              options: sortedOptions,
              value: currentValue,
              title: dialogHeaderText || 'Select Option',
              onSubmit: (selectedItems) => {
                const selectedValue = selectedItems?.[0]?.value;
                iframeWindow.setResponse &&
                  iframeWindow.setResponse(
                    JSON.stringify({
                      ...data,
                      value: selectedValue,
                      isDropdownValue: true,
                    })
                  );
              },
            });
            break;
          default:
            break;
        }
      },
      [
        webviewRef?.contentWindow?.window,
        openSignatureModal,
        openUploadImageModal,
        openUploadVideoModal,
        openQRScannerOverlay,
        writeResponses,
        sendToNext,
        openSelectContactModal,
        getUsersOptions,
        updateSelectContactModalProps,
        onDataWrite,
        emitEvent,
        openTask,
        onHeightChange,
        onEmbedSubmitStatus,
        openTypeAndSearchDialog,
        _uploadFiles,
        submit,
        toast,
      ]
    );

    const getTypeAndSearchDialogComponent = (
      item: SelectItemType,
      isHovered: boolean
    ) => {
      const isSelected = typeAndSearchDialogProps?.value === item.value;

      return (
        <Box
          bgColor={
            isSelected
              ? 'primary.500:alpha.20'
              : isHovered
              ? 'gray.200'
              : 'white'
          }
          w={'100%'}
          h={'100%'}
          p="12px"
          display={'flex'}
          flexDir={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text>{item.value}</Text>
          {isSelected ? (
            <ExpoIonicIcon
              name="checkmark-sharp"
              color={'primary.500'}
              size={'20px'}
            />
          ) : null}
        </Box>
      );
    };

    return (
      <>
        <WebView
          url={props.url}
          onLoad={onWebviewLoad}
          onMessage={onWebviewMessage}
          embedded={props.embedded}
        />
        {isSignatureModalOpen && (
          <SignatureCanvasModal
            isOpen={isSignatureModalOpen}
            close={closeSignatureModal}
            {...signatureModalProps}
          />
        )}
        {isUploadImageModalOpen && (
          <UploadImageModal
            isOpen={isUploadImageModalOpen}
            close={closeUploadImageModal}
            {...uploadImageModalProps}
          />
        )}
        {isUploadVideoModalOpen && (
          <UploadVideoModal
            isOpen={isUploadVideoModalOpen}
            close={closeUploadVideoModal}
            {...uploadVideoModalProps}
          />
        )}
        <UploadFileHandler
          {...uploadFileHandlerProps}
          ref={uploadFileHandlerRef}
        />
        {isQRScannerOverlayOpen && (
          <QRScannerOverlay
            isOpen={isQRScannerOverlayOpen}
            close={closeQRScannerOverlay}
            {...qrScannerOverlayProps}
          />
        )}
        {isSelectContactModalOpen && (
          <SelectUsersModal
            isOpen={isSelectContactModalOpen}
            onClose={closeSelectContactModal}
            {...selectContactModalProps}
          />
        )}
        {isUploading && (
          <UploadProgressModal
            isOpen={isUploading}
            progress={uploadProgress}
            {...uploadProgressProps}
          />
        )}
        <SelectItemModal
          isOpen={isTypeAndSearchDialogOpen}
          onClose={() => {
            closeTypeAndSearchDialog();
            updateTypeAndSearchDialogProps({
              options: [],
              onSubmit: () => {},
              title: '',
            });
          }}
          keyExtractor={(item) => item.value}
          getItemComponent={getTypeAndSearchDialogComponent}
          key={Date.now()}
          {...typeAndSearchDialogProps}
        />
        {showSpinnerModal && <SpinnerModal isOpen={showSpinnerModal} />}
      </>
    );
  }
);
