import React, { useState } from 'react';
import { View, Pressable, Text, FlatList, Button } from '@know/ui';
import type { ISurveyQuestion } from '../types';
import Images from '../../../../Images/index';
import { Image as RNImage, StyleSheet } from 'react-native';
interface Iprops {
  index: string;
  data: ISurveyQuestion;
  answer: any;
}
export const Radio = ({
  data,
  handleProceedButton,
  setResponse,
  sectionId,
}: {
  data: Iprops;
  handleProceedButton: (questionIndex?: string) => void;
  setResponse: (value: any) => void;
  sectionId: string | null;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    index: number | null;
    item: number | null | string;
  }>({
    index: 0,
    item: '',
  });

  const getRadioButton = (item: string) => {
    return item === selectedOptions.item
      ? Images.RadioBlackBtn
      : Images.RadioBtn;
  };
  const styles = StyleSheet.create({
    backArrowStyle: {
      width: '21px',
      height: '13px',
    },
    flatlistContainer: {
      paddingTop: 10,
    },
    radioSelectedImage: {
      height: '14px',
      width: '14px',
      marginRight: '8px',
    },
    radioImage: {
      height: '14px',
      width: '14px',
      marginRight: '8px',
    },
  });
  const selectedRadioButtonColor = (item: any) => {
    return { tintColor: item === selectedOptions.item ? '#00C1B2' : '' };
  };
  return (
    <View padding="15px">
      <Text fontWeight="semibold" fontSize="18px">
        {data.data.text}
      </Text>
      <FlatList
        keyExtractor={(_evt, index) => `${index}`}
        contentContainerStyle={styles.flatlistContainer}
        scrollEnabled={false}
        extraData={data.data.options}
        data={data.data.options}
        ItemSeparatorComponent={() => {
          return <View />;
        }}
        renderItem={({ item, index }) => {
          return (
            <Pressable
              onPress={() => {
                setSelectedOptions({
                  index: index + 1,
                  item,
                });
              }}
            >
              <View
                flexDirection="row"
                alignItems="center"
                paddingBottom="12px"
              >
                <RNImage
                  source={getRadioButton(item)}
                  style={[styles.radioImage, selectedRadioButtonColor(item)]}
                />
                <Text fontSize="14px" color="#4E5656">
                  {item}
                </Text>
              </View>
            </Pressable>
          );
        }}
      />
      <Button
        size={'sm'}
        onPress={() => {
          setResponse((prevState: any) => {
            const state = [...prevState];
            state.push({
              question: data.index,
              response: selectedOptions?.index?.toString(),
              sectionID: sectionId,
            });
            return state;
          });
          handleProceedButton(selectedOptions.index?.toString());
          setSelectedOptions({
            index: null,
            item: null,
          });
        }}
        disabled={!selectedOptions.item}
        opacity={!selectedOptions.item ? 0.3 : 1}
      >
        <Text fontWeight={'semibold'} color={'white'}>
          Proceed
        </Text>
      </Button>
    </View>
  );
};
