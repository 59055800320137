import React, { forwardRef } from 'react';
import { Box, Skeleton, useKNOWValueContextProvider } from '@know/ui';

export const SkeletonItem = forwardRef((_, ref) => {
  const { isPhoneSize } = useKNOWValueContextProvider();
  return (
    <Box
      h={'full'}
      w={'full'}
      display={'flex'}
      flexDir={'column'}
      justifyContent={'start'}
      ref={ref}
    >
      <Skeleton flex={1} rounded="lg" />
      <Skeleton
        w={'100%'}
        h={isPhoneSize ? '14px' : '16px'}
        rounded="full"
        mt={'8px'}
        mb={'6px'}
      />
      <Skeleton
        w={'95%%'}
        h={isPhoneSize ? '14px' : '16px'}
        mb={'6px'}
        rounded="full"
      />
      <Skeleton
        w={'100%'}
        h={isPhoneSize ? '14px' : '16px'}
        rounded="full"
        mb={'8px'}
      />
      <Skeleton w={'75px'} h={isPhoneSize ? '22px' : '25px'} rounded="full" />
    </Box>
  );
});
