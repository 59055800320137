import React from 'react';
import {
  Box,
  HStack,
  ErrorIcon,
  VStack,
  Text,
  IconButton,
  CloseIcon,
  SuccessIcon,
} from '@know/ui';

interface ErrorAlertProps {
  header: string;
  subText?: string;
  onClose?: () => void;
  containerProps?: any;
}

export const ErrorAlert = ({
  header,
  subText,
  onClose,
  containerProps = {},
}: ErrorAlertProps) => {
  return (
    <Box
      w="100%"
      background={'red.100'}
      borderWidth={'1px'}
      borderStyle={'solid'}
      borderColor={'red.400'}
      borderRadius="8px"
      mt="12px"
      padding="10px 12px"
      {...containerProps}
    >
      <HStack>
        <ErrorIcon size="20px" mr="6px" />
        <VStack flex={1}>
          <Text fontWeight="600" fontSize="14px" color={'gray.900'}>
            {header}
          </Text>
          {subText && (
            <Text fontSize={'12px'} color={'gray.700'}>
              {subText}
            </Text>
          )}
        </VStack>
        {onClose && (
          <IconButton
            size={'24px'}
            ml={'auto'}
            icon={<CloseIcon />}
            onPress={onClose}
            padding={'4px'}
            mt={'auto'}
            mb={'auto'}
            _icon={{
              color: 'gray.900',
            }}
          />
        )}
      </HStack>
    </Box>
  );
};

export const SuccessAlert = ({
  header,
  subText,
  onClose,
  containerProps = {},
}: ErrorAlertProps) => {
  return (
    <Box
      w="100%"
      background={'green.100'}
      borderWidth={'1px'}
      borderStyle={'solid'}
      borderColor={'green.400'}
      borderRadius="8px"
      mt="12px"
      padding="10px 12px"
      {...containerProps}
    >
      <HStack>
        <SuccessIcon size="20px" mr="6px" mt="2px" />
        <VStack flex={1} mt={'2px'}>
          <Text fontWeight="600" fontSize="14px" color={'gray.900'}>
            {header}
          </Text>
          {subText && (
            <Text fontSize={'12px'} color={'gray.700'}>
              {subText}
            </Text>
          )}
        </VStack>
        {onClose && (
          <IconButton
            size={'24px'}
            ml={'auto'}
            icon={<CloseIcon />}
            onPress={onClose}
            padding={'4px'}
            mt={'auto'}
            mb={'auto'}
            _icon={{
              color: 'gray.900',
            }}
          />
        )}
      </HStack>
    </Box>
  );
};
