import { DataSnapshot } from "firebase/database";
import { TaskFeedItem } from "@know/transformers";
import { FirebaseAPI, listenToIssueNugget } from "..";

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: {
  [id: string]: () => void;
} = {};

export function subscribeToUserCompletedIssues(
  fbAPI: FirebaseAPI,
  cb: (nugId: string, feedItem: TaskFeedItem | null) => void
) {
  const userFeedRef = fbAPI.getNodeRef(fbAPI.getCommonPath("completedIssues"));

  function listenerHandler(_listenerEvenType: string) {
    return async (userFeedSnapshot: DataSnapshot) => {
      const completedIssueFeed: any = (await userFeedSnapshot.val()) || null;

      const id = userFeedSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        cleanups[id]?.();
        return cb(id, null);
      }
      if (completedIssueFeed?.createdAt) {
        const author = await fbAPI.getUserDetails(completedIssueFeed.author);
        cleanups[id]?.();
        cleanups[id] = listenToIssueNugget(fbAPI, id, (nugget) => {
          if (nugget) {
            cb(id, {
              ...completedIssueFeed,
              author,
              nugget,
              id,
            });
          } else {
            cb(id, null);
          }
        });
      } else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userFeedRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userFeedRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userFeedRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribeCompletedIssuesNuggets = () => {
  for (const c of Object.keys(cleanups)) {
    cleanups[c]?.();
  }
  cleanups = {};
};

export const markCompletedIssueRead = async (
  fbAPI: FirebaseAPI,
  nuggetId: string
) => {
  const userFeedPath = `${fbAPI.getCommonPath("completedTasks")}/${nuggetId}`;
  await fbAPI.fbUpdate(userFeedPath, {
    consumedAt: fbAPI.getServerTimestamp(),
  });
};

export const fetchNuggetDetailsFromId = async (
  fbAPI: FirebaseAPI | undefined,
  nuggetId: string
) => {
  try {
    const userFeedRef = fbAPI?.getChild(
      fbAPI?.getNodeRef(fbAPI?.getCommonPath("nuggets")),
      `tasklist/${nuggetId}`
    );

    function listenerHandler() {
      return async (userFeedSnapshot: DataSnapshot) => {
        const completedIssueFeed: any = (await userFeedSnapshot.val()) || null;
        console.log("inside get nugget deatils from id", completedIssueFeed);
      };
    }
    listenerHandler();
  } catch (error) {
    console.error("Error fetching nugget details:", error);
    throw error;
  }
};
