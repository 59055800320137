import React from 'react';

import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';

import { Heading } from 'native-base';

import type {
  CustomDrawerContentProps,
  CustomDrawerItemListProps,
} from './types';
import { CustomDrawerItem } from './CustomDrawerItem';
import { Box, ScrollView } from '@know/ui';
import { useTranslation } from '@know/i18n';

function CustomDrawerItemList(props: CustomDrawerItemListProps) {
  const { navigation, state, descriptors, isSmallScreen, onRouteChange } =
    props;
  const { t } = useTranslation('menus');

  return (
    <>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          (options.drawerLabel as string) ||
          options.title ||
          (route.name as string); // Get the label from options or use route name as fallback
        const isRouteActive = state.index === index;
        const isLastRoute = index === state.routes.length - 1;

        //@ts-ignore
        const badge = options.badge;

        return (
          <CustomDrawerItem
            key={route.key}
            label={t(label)}
            badge={badge}
            isActive={isRouteActive}
            isSmallScreen={isSmallScreen}
            onPress={() => {
              onRouteChange?.(route);
              navigation.navigate(route.name);
            }}
            isLastRoute={isLastRoute}
          />
        );
      })}
      {isSmallScreen ? (
        <Box
          flex={1}
          borderBottomColor={'#E5E9E8'}
          borderBottomWidth={'1px'}
          // mb={isSmallScreen ? '1px' : 0}
        />
      ) : null}
    </>
  );
}

export function CustomDrawerContent(allProps: CustomDrawerContentProps) {
  const { module, isSmallScreen, onRouteChange, actionButton, ...props } =
    allProps;
  const { t } = useTranslation('menus');
  return (
    <Box ml={2} maxW={'100%'} mr={isSmallScreen ? 2 : undefined}>
      <Box ml={2} justifyContent={'center'} mt={5} mb={'14px'}>
        <Heading fontWeight={'bolder'} color={'primary.800'} fontSize={'xl'}>
          {t(module)}{' '}
        </Heading>
        {isSmallScreen ? (
          <Box position={'absolute'} right={0}>
            {actionButton}
          </Box>
        ) : null}
      </Box>
      {isSmallScreen ? (
        <ScrollView
          display={'flex'}
          flexDir={'row'}
          maxW={'100%'}
          overflow={'auto'}
          mx={isSmallScreen ? '8px' : undefined}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          // eslint-disable-next-line react-native/no-inline-styles
          contentContainerStyle={{ width: '100%' }}
        >
          <CustomDrawerItemList
            {...props}
            isSmallScreen={isSmallScreen}
            onRouteChange={onRouteChange}
          />
        </ScrollView>
      ) : (
        <DrawerContentScrollView {...props}>
          <CustomDrawerItemList {...props} onRouteChange={onRouteChange} />
        </DrawerContentScrollView>
      )}
    </Box>
  );
}

export function withModuleName(
  module: string,
  options?: {
    isSmallScreen?: boolean;
    onRouteChange?: (route: any) => any;
    actionButton?: JSX.Element | null;
  }
) {
  return function DrawerContent(props: DrawerContentComponentProps) {
    return (
      <CustomDrawerContent
        {...props}
        module={module}
        isSmallScreen={options?.isSmallScreen}
        onRouteChange={options?.onRouteChange}
        actionButton={options?.actionButton}
      />
    );
  };
}
