import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Box,
  Text,
  Avatar,
  VStack,
  FlatList,
  WithKNOWLoader,
  // useIsMobile,
  InputFilter,
  View,
  ExtendedUserDetailsInterface,
} from '@know/ui';
import { searchUsers } from '@know/transformers';

import type { SelectUsersItem } from './types';

interface SelectUsersModalProps {
  isOpen: boolean;
  title?: string;
  isLoading?: boolean;
  options?: ExtendedUserDetailsInterface[];
  isMulti?: boolean;
  onClose: () => void;
  onFilterChange?: (
    filter: string,
    filterChangeAdditionalProps?: any
  ) => void | Promise<void>;
}

const SelectUsersItemComponent = ({ item }: { item: SelectUsersItem }) => {
  return (
    <View
      display={'flex'}
      flexDir={'row'}
      alignItems={'center'}
      py={'10px'}
      px={'16px'}
    >
      <Avatar
        size={'48px'}
        source={{ uri: item.avatar }}
        bg={'primary.500'}
        mr={'10px'}
      >
        <Text color={'white'} fontWeight={600}>
          {item.firstName.charAt(0).toUpperCase()}
          {item.lastName.charAt(0).toUpperCase()}
        </Text>
      </Avatar>
      <VStack>
        <Text fontWeight={600} fontSize={'14px'}>
          {item.userName}
        </Text>
        <Text fontSize={'12px'}>
          {item.designation}, {item.department}
        </Text>
        <Text fontSize={'12px'}>{item.location ?? 'No Location'}</Text>
      </VStack>
    </View>
  );
};

const Separator = () => <Box h={'1px'} w={'100%'} bg={'gray.300'} />;

export const ViewUsersModal = ({
  isOpen,
  title,
  options = [],
  isLoading = false,
  onClose: _onClose,
}: SelectUsersModalProps) => {
  // const isMobile = useIsMobile();
  const [filter, setFilter] = useState<string>('');

  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (filter) {
      const filteredUsers = searchUsers(filter, options);

      setFilteredOptions(filteredUsers);
    } else {
      setFilteredOptions(options);
    }
  }, [filter, options]);

  const renderItem = ({ item }: { item: SelectUsersItem }) => {
    return <SelectUsersItemComponent item={item} />;
  };

  const focusRef = useRef<any>(null);

  const onClose = useCallback(() => {
    _onClose();
    setFilter('');
  }, [_onClose]);

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
      closeOnOverlayClick
      initialFocusRef={focusRef}
    >
      <Modal.Content maxH={'80%'}>
        <Modal.CloseButton top={'8px'} />
        <Modal.Header
          borderBottomWidth={0}
          paddingTop={'12px'}
          paddingBottom={'12px'}
        >
          {title || 'Search users'}
        </Modal.Header>
        <Modal.Footer>
          <Box w={'100%'}>
            <InputFilter
              hasFilter={true}
              onChange={setFilter}
              filterChangeDelay={300}
              inputRef={focusRef}
            />
          </Box>
        </Modal.Footer>
        <Modal.Body px={0} pt={0}>
          <WithKNOWLoader isLoading={isLoading} py={'12px'}>
            {!filteredOptions.length ? (
              <Box
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
                px={'16px'}
              >
                {filter ? (
                  <Text textAlign={'center'} flex={1}>
                    No results found.
                  </Text>
                ) : (
                  <Text fontWeight={600} textAlign={'center'} flex={1}>
                    Search by name, department or designation
                  </Text>
                )}
              </Box>
            ) : (
              <Box w={'100%'}>
                <FlatList
                  data={filteredOptions}
                  renderItem={renderItem}
                  keyExtractor={(item, index) =>
                    item.userId + Date.now() + index
                  }
                  ItemSeparatorComponent={Separator}
                />
              </Box>
            )}
          </WithKNOWLoader>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
