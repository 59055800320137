import React from 'react';
import { createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const ShareIcon = createIcon({
  viewBox: '0 0 17 16',
  path: [
    <Path
      d="M4.16669 9.99998C4.7498 9.99824 5.31084 9.77687 5.73802 9.37998L9.91136 11.7646C9.77153 12.311 9.83317 12.8895 10.085 13.3942C10.3368 13.8989 10.762 14.296 11.2827 14.5128C11.8033 14.7296 12.3847 14.7516 12.9203 14.5749C13.4559 14.3981 13.9099 14.0343 14.1992 13.5502C14.4885 13.066 14.5938 12.4938 14.4957 11.9384C14.3976 11.383 14.1028 10.8815 13.6652 10.5257C13.2276 10.1698 12.6764 9.98355 12.1127 10.0009C11.549 10.0182 11.0103 10.238 10.5954 10.62L6.42202 8.23531C6.46602 8.06864 6.49002 7.89598 6.49402 7.72331L10.594 5.37998C10.9888 5.73916 11.4951 5.95165 12.028 5.98179C12.5609 6.01193 13.0879 5.85789 13.5207 5.54552C13.9534 5.23315 14.2656 4.78144 14.4048 4.26619C14.544 3.75094 14.5018 3.20348 14.2852 2.71567C14.0687 2.22785 13.6909 1.82936 13.2154 1.58704C12.7398 1.34472 12.1954 1.27332 11.6734 1.38482C11.1515 1.49631 10.6838 1.78392 10.3487 2.19939C10.0137 2.61486 9.83172 3.13292 9.83336 3.66664C9.83602 3.85864 9.86202 4.04998 9.91136 4.23531L6.12202 6.39998C5.90228 6.06002 5.59787 5.78308 5.2387 5.59636C4.87954 5.40964 4.47798 5.31958 4.07347 5.33501C3.66897 5.35044 3.27544 5.47084 2.93155 5.68438C2.58766 5.89792 2.30523 6.19726 2.11203 6.55297C1.91882 6.90869 1.82148 7.30855 1.82957 7.71326C1.83765 8.11798 1.95089 8.51363 2.15815 8.86134C2.36542 9.20906 2.65957 9.49687 3.01172 9.6965C3.36387 9.89613 3.76189 10.0007 4.16669 9.99998Z"
      fill="white"
    />,
  ],
});
