import React, { useRef, useState, useEffect } from 'react';
import {
  View,
  Text,
  useBreakpointValue,
  StackHeaderProps,
  LanguageSelectorModal,
  WithKNOWLoader,
  Pressable,
  ScrollView,
  LessonList,
  useRecoilValue,
} from '@know/ui';
import { useCourseFeed } from '../MyCoursesPage/useCourseFeed';
import { useCourseDetailsFeed } from './useCourseDetailsFeed';
import { useLanguageSwitch } from './useLanguageSwitch';
import { get } from 'lodash';

import type { ILessonCard } from '../types';
import { currentCourseTabAtom } from '../state';
import { CoursesDetailsScreenHeader } from './CoursesDetailsScreenHeader';

export const courseLinking = {
  path: 'lms/course-details/course/:courseId',
};
export const LJCourseLinking = {
  path: 'lms/course-details/journey/:journeyId/course/:courseId',
};

export const CoursesDetailsScreen = ({
  route,
  navigation,
}: StackHeaderProps) => {
  const params: any = route?.params ?? {};
  const courseId = params.courseId;
  const journeyId = params.journeyId;
  // const journeyLanguage = params?.journeyLanguage ?? null;
  const [tooltipClickedIndex, setTooltipClickedIndex] = useState<number | null>(
    null
  );
  const showLockFlag = useRef<number>(0);
  const { cardInfo }: any = useCourseFeed(journeyId ?? courseId);
  const { cardInfo: courseInfo }: any = useCourseFeed(courseId);
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);

  useEffect(() => {
    navigation.setOptions({
      title: courseInfo?.title,
    });
  }, [courseInfo?.title, navigation]);

  const {
    handleResumeCard,
    courseDetails,
    isLoading,
    progressLMS,
    handleCourse,
    isCourseBegin,
    isSequence,
  }: // resetCourseProgress,
  any = useCourseDetailsFeed(courseId, cardInfo, journeyId);
  const {
    modalVisible,
    handleChangeLanguage,
    handleToggleModal,
    selectedLanguage,
    isLoading: isLangSwitchLoading,
  } = useLanguageSwitch(journeyId || courseId, cardInfo);
  const lessonsProgress = journeyId
    ? progressLMS?.[cardInfo?.shareId]?.[courseId]
    : progressLMS?.[cardInfo?.shareId];
  const lessons: any =
    Object.values(courseDetails)?.sort(
      (a: any, b: any): number => a?.seq - b?.seq
    ) || [];

  const hPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '12px',
    lg: '15%',
    xl: '20%',
  });

  const showLockTooltip = (index: number) => {
    setTooltipClickedIndex(index);
    setTimeout(() => {
      setTooltipClickedIndex(null);
    }, 3000);
  };

  const showLock = (lesson: ILessonCard, isCompleted: boolean) => {
    const { seq } = lesson;
    if (isSequence) {
      if (seq === 0) {
        if (!isCompleted) {
          showLockFlag.current = 1;
        }
        return false;
      }
      if (!isCompleted) {
        if (showLockFlag.current === 1) {
          return true;
        } else {
          showLockFlag.current = 1;
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  };

  const handleShowLock = () => {
    let mappedLessons = lessons;
    for (const lesson of lessons) {
      const lessonProgress = get(lessonsProgress, lesson?.id, {});
      const isCompleted =
        currentCourseTab === 'completed-courses' ||
        lessonProgress?.hasOwnProperty('consumedAt');
      mappedLessons[lesson?.seq] = {
        ...lesson,
        showLock: showLock(lesson, isCompleted),
      };
    }
    showLockFlag.current = 0;
    return mappedLessons;
  };

  // useEffect(() => {
  //   console.log('courseInfo?.selectedLanguage', courseInfo?.selectedLanguage);
  //   console.log('cardInfo?.selectedLanguage', cardInfo?.selectedLanguage);
  //   console.log('journeyLanguage', journeyLanguage);
  //   if (journeyLanguage && cardInfo?.selectedLanguage !== journeyLanguage) {
  //     resetCourseProgress();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <WithKNOWLoader
      isLoading={isLoading || isLangSwitchLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <ScrollView flex={1} bgColor="white">
        <CoursesDetailsScreenHeader
          courseId={courseId}
          journeyId={journeyId}
          courseCardInfo={courseInfo}
          cardInfo={cardInfo}
          courseDetails={courseDetails}
          handleCourse={handleCourse}
          isCourseBegin={isCourseBegin}
          progressLMS={progressLMS}
        />
        <View flex={1} bg="white">
          <View style={{ paddingHorizontal: hPadding }}>
            {!!courseInfo?.description && (
              <View marginTop="15px" marginBottom="15px">
                <Text fontWeight="semibold" color="gray.900" fontSize="20px">
                  About the course
                </Text>
                <Text color="gray.400" fontSize="16px">
                  {courseInfo?.description}
                </Text>
              </View>
            )}

            <View marginTop="15px" marginBottom="15px">
              <Text fontWeight="semibold" color="gray.900" fontSize="20px">
                Lessons
              </Text>
            </View>
            {handleShowLock().map((lesson: any, index: number) => {
              const lessonProgress = get(lessonsProgress, lesson?.id, {});
              const cardProgress = get(lessonProgress, 'cardProgress', {});
              const progress = Math.round(
                (Object.values(cardProgress)?.length * 100) /
                  Object.values(lesson?.cards)?.length
              );
              const isCompleted =
                currentCourseTab === 'completed-courses' ||
                lessonProgress?.hasOwnProperty('consumedAt');

              return (
                <Pressable
                  key={lesson.id}
                  onPress={() => {
                    lesson?.showLock !== true
                      ? handleResumeCard(
                          {
                            lessons: { [lesson?.id]: lesson },
                            cardProgress,
                          },
                          isCompleted
                        )
                      : lesson?.isSequence && showLockTooltip(index);
                  }}
                >
                  <LessonList
                    index={index}
                    lesson={lesson}
                    completed={isCompleted}
                    progress={progress}
                    showLockTooltipIndex={tooltipClickedIndex}
                    type="courses"
                  />
                  {index !== lessons.length - 1 && (
                    <View
                      w="100%"
                      h="1px"
                      bgColor="gray.300"
                      marginBottom="12px"
                    />
                  )}
                </Pressable>
              );
            })}
          </View>
          <LanguageSelectorModal
            modalVisible={modalVisible}
            handleToggleModal={handleToggleModal}
            data={courseInfo.languages}
            selectedLanguage={selectedLanguage}
            handleChangeLanguage={handleChangeLanguage}
          />
        </View>
      </ScrollView>
    </WithKNOWLoader>
  );
};
