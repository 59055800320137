import React, { useCallback } from 'react';
import { View, Pressable, Text, Menu, IconButton, HStack } from '..';
import { StyleSheet } from 'react-native';
import {
  TaskDeadline,
  TaskStatus,
  UserIcon,
  VerticalEllipsisIcon,
} from './index';
import { TaskListRowType } from './types';
import { getDateParts, TitleCase } from './utils';

export function TaskListRow({ item, onPress, menuOptions }: TaskListRowType) {
  const {
    title,
    authorName,
    deadline,
    status,
    isRead,
    resolvedAt = 0,
    taskExtId,
  } = item;

  const onClickTask = useCallback(() => {
    onPress(item);
  }, [item, onPress]);

  return (
    <Pressable
      style={styles.parentWrapper}
      onPress={onClickTask}
      borderLeftWidth={isRead ? 0 : 4}
      borderLeftColor={isRead ? '' : '#c74646'}
    >
      <View style={styles.taskNameAndAuthorWrapper}>
        <View style={styles.taskTitleAndEllipsisiconWrapper}>
          {taskExtId ? (
            <Text style={styles.taskTitle}>
              {taskExtId}: {title}
            </Text>
          ) : (
            <Text style={styles.taskTitle}>{title}</Text>
          )}
          {menuOptions?.length ? (
            <Menu
              placement="left"
              trigger={(triggerProps) => {
                return (
                  <IconButton
                    icon={
                      <VerticalEllipsisIcon size={'20px'} color={'gray.950'} />
                    }
                    {...triggerProps}
                    py={'0px'}
                  />
                );
              }}
            >
              {menuOptions.map(({ id, label, onClick, Icon }) => (
                <Menu.Item onPress={() => onClick(item)} key={id}>
                  <HStack alignItems={'center'} mx={'-12px'}>
                    {Icon}
                    <Text ml={'8px'}>{label}</Text>
                  </HStack>
                </Menu.Item>
              ))}
            </Menu>
          ) : null}
        </View>
        <View
          style={styles.authorNameAndIconWrapper}
          marginBottom={status === 'completed' ? '1px' : '4px'}
        >
          <UserIcon size={'13px'} color={'#4E5656'} />
          <Text style={styles.authorName}>{authorName}</Text>
        </View>
      </View>

      {status === 'completed' ? (
        <View style={styles.deadlineAndStatusWrapperForCompletedTasks}>
          <View style={styles.taskCompletedStatusWrapper}>
            <TaskStatus
              status={status}
              text={`${TitleCase(status)}: `}
              background="transparent"
              paddingX="0px"
              paddingY="0px"
            />
            <Text style={styles.completedDateAndTime}>
              {getDateParts(resolvedAt).join(', ')}
            </Text>
          </View>
          <TaskDeadline deadline={deadline} isCompleted={true} />
        </View>
      ) : (
        <View style={styles.deadlineAndStatusWrapper}>
          <TaskDeadline deadline={deadline} />
          <TaskStatus status={status} />
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  parentWrapper: {
    borderBottomColor: '#D9DDDD',
    borderBottomWidth: 1,
    paddingVertical: 10,
    paddingLeft: 24,
    paddingRight: 16,
  },
  taskNameAndAuthorWrapper: {
    // backgroundColor: 'crimson',
  },

  taskTitleAndEllipsisiconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  authorNameAndIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 1.5,
  },

  taskTitle: {
    fontSize: 14,
    fontWeight: '600',
  },

  authorName: {
    fontSize: 14,
    fontWeight: '400',
    marginLeft: 4,
  },

  deadlineAndStatusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  deadlineAndStatusWrapperForCompletedTasks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  taskCompletedStatusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
  },

  completedDateAndTime: {
    fontSize: 14,
    fontWeight: '400',
    color: '#4E5656',
  },
});
