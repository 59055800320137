import React, { useState } from 'react';
import { View, Pressable, Text, FlatList, Button } from '@know/ui';
import type { ISurveyQuestion } from '../types';
import { Image as RNImage, StyleSheet } from 'react-native';
import Images from '../../../../Images/index';
interface Iprops {
  index: string;
  data: ISurveyQuestion;
  answer: any;
}
export const Checkbox = ({
  data,
  handleProceedButton,
  setResponse,
  sectionId,
}: {
  data: Iprops;
  handleProceedButton: (questionIndex?: string) => void;
  setResponse: (value: any) => void;
  sectionId: string | null;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const getCheckBoxButton = (item: string) => {
    return selectedOptions.includes(item)
      ? Images.SelectedCheckboxButton
      : Images.CheckboxButton;
  };

  const optionClicked = (option: string) => {
    if (selectedOptions.includes(option)) {
      const newOptions = selectedOptions.filter((e) => e !== option);
      setSelectedOptions(newOptions);
      return;
    }
    setSelectedOptions([...selectedOptions, option]);
  };
  console.log(selectedOptions);
  const styles = StyleSheet.create({
    backArrowStyle: {
      width: '21px',
      height: '13px',
    },
    flatlistContainer: {
      paddingTop: 10,
    },
    checkboxImage: {
      height: '14px',
      width: '14px',
      marginRight: '8px',
    },
  });

  return (
    <View padding="15px">
      <Text fontWeight="semibold" fontSize="18px">
        {data.data.text}
      </Text>
      <FlatList
        keyExtractor={(_evt, index) => `${index}`}
        contentContainerStyle={styles.flatlistContainer}
        scrollEnabled={false}
        extraData={selectedOptions}
        data={data.data.options}
        ItemSeparatorComponent={() => {
          return <View />;
        }}
        renderItem={({ item, index }) => {
          return (
            <Pressable
              onPress={() => {
                optionClicked((index + 1).toString());
              }}
            >
              <View
                flexDirection="row"
                alignItems="center"
                paddingBottom="12px"
              >
                <RNImage
                  source={getCheckBoxButton((index + 1).toString())}
                  style={styles.checkboxImage}
                />
                <Text fontSize="14px" color="#4E5656">
                  {item}
                </Text>
              </View>
            </Pressable>
          );
        }}
      />
      <Button
        size={'sm'}
        onPress={() => {
          setResponse((prevState: any) => {
            const state = [...prevState];
            state.push({
              question: data.index,
              response: selectedOptions,
              sectionID: sectionId,
            });
            return state;
          });
          handleProceedButton(data.index);
          setSelectedOptions([]);
        }}
        opacity={selectedOptions?.length === 0 ? 0.3 : 1}
        disabled={selectedOptions?.length === 0}
      >
        <Text fontWeight={'semibold'} color={'white'}>
          Proceed
        </Text>
      </Button>
    </View>
  );
};
