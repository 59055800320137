import React from 'react';
import { Svg, G, Path } from 'react-native-svg';
import { Icon } from 'native-base';
import { Octicons } from '@expo/vector-icons';

export const UserIcon = (props: any) => {
  return (
    <Icon {...props} viewBox="0 0 12 12" fill="none">
      <G id="Group">
        <Path
          id="Vector"
          d="M1.625 11.25C1.625 11.25 0.75 11.25 0.75 10.375C0.75 9.5 1.625 6.875 6 6.875C10.375 6.875 11.25 9.5 11.25 10.375C11.25 11.25 10.375 11.25 10.375 11.25H1.625ZM6 6C6.69619 6 7.36387 5.72344 7.85616 5.23116C8.34844 4.73887 8.625 4.07119 8.625 3.375C8.625 2.67881 8.34844 2.01113 7.85616 1.51884C7.36387 1.02656 6.69619 0.75 6 0.75C5.30381 0.75 4.63613 1.02656 4.14384 1.51884C3.65156 2.01113 3.375 2.67881 3.375 3.375C3.375 4.07119 3.65156 4.73887 4.14384 5.23116C4.63613 5.72344 5.30381 6 6 6Z"
          fill="currentColor"
        />
      </G>
    </Icon>
  );
};

export const VerticalEllipsisIcon = (props: any) => {
  return (
    <Icon {...props} viewBox="0 0 20 20" fill="none">
      <Path
        d="M11.6666 5.00001C11.6666 4.08334 10.9166 3.33334 9.99996 3.33334C9.08329 3.33334 8.33329 4.08334 8.33329 5.00001C8.33329 5.91668 9.08329 6.66668 9.99996 6.66668C10.9166 6.66668 11.6666 5.91668 11.6666 5.00001ZM11.6666 15C11.6666 14.0833 10.9166 13.3333 9.99996 13.3333C9.08329 13.3333 8.33329 14.0833 8.33329 15C8.33329 15.9167 9.08329 16.6667 9.99996 16.6667C10.9166 16.6667 11.6666 15.9167 11.6666 15ZM11.6666 10C11.6666 9.08334 10.9166 8.33334 9.99996 8.33334C9.08329 8.33334 8.33329 9.08334 8.33329 10C8.33329 10.9167 9.08329 11.6667 9.99996 11.6667C10.9166 11.6667 11.6666 10.9167 11.6666 10Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const PDFIcon = (props: any) => {
  return (
    <Icon {...props} fill="none" viewBox="0 0 17 16">
      <Path
        d="M6.01118 9.78711C5.88852 9.78711 5.80585 9.79911 5.76318 9.81111V10.5964C5.81385 10.6084 5.87718 10.6118 5.96452 10.6118C6.28385 10.6118 6.48052 10.4504 6.48052 10.1778C6.48052 9.93378 6.31118 9.78711 6.01118 9.78711ZM8.33585 9.79511C8.20252 9.79511 8.11585 9.80711 8.06452 9.81911V11.5591C8.11585 11.5711 8.19852 11.5711 8.27318 11.5711C8.81785 11.5751 9.17252 11.2751 9.17252 10.6404C9.17652 10.0871 8.85318 9.79511 8.33585 9.79511Z"
        fill="currentColor"
      />
      <Path
        d="M9.83317 1.33398H4.49984C4.14622 1.33398 3.80708 1.47446 3.55703 1.72451C3.30698 1.97456 3.1665 2.3137 3.1665 2.66732V13.334C3.1665 13.6876 3.30698 14.0267 3.55703 14.2768C3.80708 14.5268 4.14622 14.6673 4.49984 14.6673H12.4998C12.8535 14.6673 13.1926 14.5268 13.4426 14.2768C13.6927 14.0267 13.8332 13.6876 13.8332 13.334V5.33398L9.83317 1.33398ZM6.83184 10.794C6.62584 10.9873 6.32184 11.074 5.96784 11.074C5.8992 11.0747 5.83059 11.0707 5.7625 11.062V12.0127H5.1665V9.38865C5.43547 9.34853 5.70725 9.33025 5.97917 9.33398C6.3505 9.33398 6.6145 9.40465 6.7925 9.54665C6.96184 9.68132 7.0765 9.90198 7.0765 10.162C7.07584 10.4233 6.98917 10.644 6.83184 10.794ZM9.36984 11.6973C9.08984 11.93 8.66384 12.0407 8.14317 12.0407C7.83117 12.0407 7.6105 12.0207 7.4605 12.0007V9.38932C7.72957 9.35005 8.00126 9.33155 8.27317 9.33398C8.77784 9.33398 9.10584 9.42465 9.36184 9.61798C9.6385 9.82332 9.81184 10.1507 9.81184 10.6207C9.81184 11.1293 9.62584 11.4807 9.36984 11.6973ZM11.8332 9.84732H10.8118V10.4547H11.7665V10.944H10.8118V12.0133H10.2078V9.35398H11.8332V9.84732ZM9.83317 6.00065H9.1665V2.66732L12.4998 6.00065H9.83317Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const AudioIcon = (props: any) => {
  return (
    <Icon {...props} fill="none" viewBox="0 0 17 16">
      <Path
        d="M8.5 9.75C9.96719 9.75 11.1562 8.575 11.1562 7.125V3.625C11.1562 2.175 9.96719 1 8.5 1C7.03281 1 5.84375 2.175 5.84375 3.625V7.125C5.84375 8.575 7.03281 9.75 8.5 9.75ZM13.6562 7.09375C13.6562 7.025 13.6 6.96875 13.5312 6.96875H12.5938C12.525 6.96875 12.4688 7.025 12.4688 7.09375C12.4688 9.28594 10.6922 11.0625 8.5 11.0625C6.30781 11.0625 4.53125 9.28594 4.53125 7.09375C4.53125 7.025 4.475 6.96875 4.40625 6.96875H3.46875C3.4 6.96875 3.34375 7.025 3.34375 7.09375C3.34375 9.72969 5.32188 11.9047 7.875 12.2125V13.8125H5.60469C5.39063 13.8125 5.21875 14.0359 5.21875 14.3125V14.875C5.21875 14.9438 5.2625 15 5.31563 15H11.6844C11.7375 15 11.7812 14.9438 11.7812 14.875V14.3125C11.7812 14.0359 11.6094 13.8125 11.3953 13.8125H9.0625V12.2203C11.6453 11.9391 13.6562 9.75156 13.6562 7.09375Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const VideoIcon = (props: any) => {
  return (
    <Icon {...props} fill="none" viewBox="0 0 17 16">
      <Path
        d="M12.5002 4.66732C12.5002 3.93198 11.9022 3.33398 11.1668 3.33398H3.16683C2.4315 3.33398 1.8335 3.93198 1.8335 4.66732V11.334C1.8335 12.0693 2.4315 12.6673 3.16683 12.6673H11.1668C11.9022 12.6673 12.5002 12.0693 12.5002 11.334V9.11198L15.1668 11.334V4.66732L12.5002 6.88932V4.66732Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const ExternalLinkIcon = ({
  size = 13,
  color,
}: {
  size?: string | number;
  color: string;
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 13 13" fill="none">
      <Path
        d="M7.25 5.75L11 2M11 2H8.5M11 2V4.5M11 7.5V10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11H3C2.73478 11 2.48043 10.8946 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export const ExpoEvilIcon = (props: any) => {
  return <Icon as={Octicons} {...props} />;
};
