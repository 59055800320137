import React, { forwardRef, useCallback } from 'react';
import { Box, Image, Text, useKNOWValueContextProvider } from '@know/ui';

import { LibraryFeedItem } from '@know/transformers';
import { StyleSheet, TouchableWithoutFeedback } from 'react-native';

interface ICourseFeedItemProps {
  item: LibraryFeedItem;
  onPress?: (i: LibraryFeedItem) => void;
}

const noop = (i: any) => {
  console.log(i);
};

export const CourseLibraryFeedItem = forwardRef(
  (props: ICourseFeedItemProps, ref) => {
    const { isPhoneSize } = useKNOWValueContextProvider();
    const { item, onPress = noop } = props;
    const defaultLanguage = item.defaultLanguage;
    const details = item.languages?.[defaultLanguage];

    const thumbnail = details.miniThumbnail;
    const title = details.title;
    const type = item.subType === 'level-4' ? 'Learning Journey' : 'Course';
    const count = item.totalCount;
    const countType = item.subType === 'level-4' ? 'Course' : 'Lesson';

    const handleOnPress = useCallback(() => {
      onPress?.(item);
    }, [item, onPress]);

    return (
      <TouchableWithoutFeedback onPress={handleOnPress}>
        <Box
          // display={'flex'}
          flexDir={'column'}
          justifyContent={'start'}
          alignItems={'start'}
          w={'full'}
          h={'full'}
          borderColor={'gray.500'}
          borderRadius={'8px'}
          ref={ref}
        >
          <Box
            flex={1}
            borderRadius={'8px'}
            w={'100%'}
            borderColor={'gray.300'}
            borderWidth={'1px'}
          >
            <Image
              source={{
                uri: thumbnail,
              }}
              style={styles.image}
              borderRadius={'8px'}
            />
          </Box>
          <Box h={isPhoneSize ? '58px' : '66px'} my={'8px'}>
            <Text
              color={'gray.900'}
              fontWeight={600}
              noOfLines={2}
              w={'100%'}
              fontSize={isPhoneSize ? '14px' : '16px'}
              fontFamily={'body'}
              lineHeight={'1.3em'}
            >
              {title}
            </Text>
            <Text
              color={'gray.700'}
              fontSize={isPhoneSize ? '14px' : '16px'}
              fontFamily={'body'}
            >
              {`${count} ${countType}${count > 1 ? 's' : ''}`}
            </Text>
          </Box>
          <Box
            display={'flex'}
            flexDir={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            px={'12px'}
            py={'2px'}
            borderRadius={'12px'}
            bg={'rgba(21, 28, 46, 0.10)'}
          >
            <Text
              color={'gray.700'}
              fontSize={isPhoneSize ? '12px' : '14px'}
              fontWeight={'500'}
              fontFamily={'body'}
            >
              {type}
            </Text>
          </Box>
        </Box>
      </TouchableWithoutFeedback>
    );
  }
);

const styles = StyleSheet.create({
  image: {
    width: undefined,
    height: '100%',
    aspectRatio: 1,
    resizeMode: 'contain',
  },
});
