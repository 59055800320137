import { FirebaseAPI } from "../FirebaseAPI";

export * from "./feed";
export * from "./sent";
export * from "./completed";
export * from "./following";
export * from './details'
export * from "./types";
export * from './create';

export const markIssueRead = async (fbAPI: FirebaseAPI,
    type: 'feed' | 'completed',
    nuggetId: string) => {
    let path = `${fbAPI.getCommonPath("currentUserFeed")}/tasklist/${nuggetId}`;

    switch (type) {
        case 'feed':
            path = `${fbAPI.getCommonPath("currentUserFeed")}/tasklist/${nuggetId}`;
            break;
        case 'completed':
            path = `${fbAPI.getCommonPath("completedIssues")}/${nuggetId}`;
            break;
        default:
            break;
    }
    await fbAPI.fbUpdate(path, {
        consumedAt: fbAPI.getServerTimestamp(),
    });
}