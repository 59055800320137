import { fromPairs, get, keys, map } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect } from 'react';

import {
  FirebaseAPI,
  useDebounce,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useToast,
} from '@know/ui';

import {
  attendanceCacheState,
  AttendanceEventInterface,
  DailyUserUpdateInterface,
  dateRangeState,
  isLoadingShiftCacheState,
  isLoadingShiftsByDatesState,
  isLoadingShiftsState,
  isWritingAttendanceState,
  ShiftByDatesInterface,
  ShiftInterface,
  shiftListState,
  shiftsByDatesState,
  shiftsFromAttendanceCacheState,
  ShiftWithIdInterface,
} from './state';
import { getShiftDatesArray } from './utils';

export const useShiftListState = (dbAPI: FirebaseAPI | undefined) => {
  const setShiftsByDates =
    useSetRecoilState<ShiftByDatesInterface>(shiftsByDatesState);
  const [dateRange, setDateRange] =
    useRecoilState<{ startDate: moment.Moment; endDate: moment.Moment }>(
      dateRangeState
    );

  const debouncedDateRange = useDebounce<{
    startDate: moment.Moment;
    endDate: moment.Moment;
  }>(dateRange, 1000);

  const shiftsList =
    useRecoilValue<Array<ShiftWithIdInterface>>(shiftListState);

  const setShiftAttendanceCache = useSetRecoilState<{
    [key: string]: AttendanceEventInterface;
  } | null>(attendanceCacheState);

  const setShiftsFromAttendanceCache = useSetRecoilState<{
    [key: string]: ShiftWithIdInterface;
  }>(shiftsFromAttendanceCacheState);

  const setIsLoadingShiftsByDates = useSetRecoilState<{
    [key: string]: boolean;
  }>(isLoadingShiftsByDatesState);

  const setIsLoadingCacheShift = useSetRecoilState<boolean>(
    isLoadingShiftCacheState
  );

  const isLoading = useRecoilValue<boolean>(isLoadingShiftsState);
  const setIsWritingAttendance = useSetRecoilState<boolean>(
    isWritingAttendanceState
  );

  const toast = useToast();

  useEffect(() => {
    if (dbAPI) {
      const shiftDates = getShiftDatesArray(
        moment(debouncedDateRange.startDate).add(-1, 'day'),
        moment(debouncedDateRange.endDate).add(1, 'day')
      );

      const newShiftLoadingStates = shiftDates.reduce<{
        [key: string]: boolean;
      }>(
        (acc, shiftDate) => ({
          ...acc,
          [shiftDate]: true,
        }),
        {}
      );

      setIsLoadingShiftsByDates(newShiftLoadingStates);

      for (const shiftDate of shiftDates) {
        dbAPI.listenOnNodeRef(
          dbAPI.queryValue(
            dbAPI.queryOrderByChild(
              dbAPI.getPublishedShiftNode(shiftDate),
              'userId'
            ),
            'equalTo',
            dbAPI.user?.uid ?? null
          ),
          'value',
          (snapshot: any) => {
            const value = snapshot.val();

            setShiftsByDates((currentShifts) => {
              return {
                ...currentShifts,
                [shiftDate]: value,
              };
            });

            setIsLoadingShiftsByDates((currentState) => ({
              ...currentState,
              [shiftDate]: false,
            }));
          }
        );
      }

      return () => {
        for (const shiftDate of shiftDates) {
          dbAPI.offNodeRef(dbAPI.getPublishedShiftNode(shiftDate), 'value');
        }
      };
    }

    return;
  }, [debouncedDateRange, dbAPI, setShiftsByDates, setIsLoadingShiftsByDates]);

  useEffect(() => {
    if (dbAPI) {
      setIsLoadingCacheShift(true);

      dbAPI.listenOnNodeRef(
        dbAPI.getCurrentUserAttendanceCacheNode(),
        'value',
        async (snapshot: any) => {
          const cache: { [key: string]: AttendanceEventInterface } | null =
            snapshot.val();

          const shiftsFromCachePairs = await Promise.all(
            map(cache, async (event) => {
              const shiftId = event.shiftId;
              const shiftDay = event.shiftDay;

              if (!shiftId || !shiftDay) return [];

              const shift = await dbAPI.getValue(
                dbAPI.getMultiPathCommon(`shifts/days/${shiftDay}/${shiftId}`)
              );

              return [shiftId, { ...shift, shiftId }];
            })
          );

          const shiftsFromCache = fromPairs(
            shiftsFromCachePairs.filter((pair) => !!pair.length)
          );

          setShiftsFromAttendanceCache(shiftsFromCache);

          // console.log(cache, shiftsFromCache);

          setShiftAttendanceCache(cache);
          setIsLoadingCacheShift(false);
        }
      );

      return () =>
        dbAPI.offNodeRef(dbAPI.getCurrentUserAttendanceCacheNode(), 'value');
    }

    return;
  }, [
    dbAPI,
    setIsLoadingCacheShift,
    setShiftAttendanceCache,
    setShiftsFromAttendanceCache,
  ]);

  const writeAttendanceEvent = useCallback(
    async (item: ShiftWithIdInterface, type: 'checkIn' | 'checkOut') => {
      if (dbAPI) {
        setIsWritingAttendance(true);

        if (type === 'checkIn' && item.endTime < +moment()) {
          setIsWritingAttendance(false);

          console.log('Shift passed!');

          toast.show({
            title: 'Something went wrong. Please refresh the page.',
            placement: 'bottom-left',
            ml: '20px',
          });

          return false;
        }

        const cacheData: { [key: string]: AttendanceEventInterface } =
          await dbAPI.getValue(dbAPI.getCurrentUserAttendanceCacheNode());

        if (cacheData && type === 'checkIn') {
          console.log('User has existing cache!');

          toast.show({
            title: 'Something went wrong. Please refresh the page.',
            placement: 'bottom-left',
            ml: '20px',
          });

          return false;
        } else if (!cacheData && type === 'checkOut') {
          console.log('User has no cache!');

          toast.show({
            title: 'Something went wrong. Please refresh the page.',
            placement: 'bottom-left',
            ml: '20px',
          });

          return false;
        }

        const { shiftId, shiftDay } = item;

        const shift: ShiftInterface = await dbAPI.getValue(
          dbAPI.getMultiPathCommon(`shifts/days/${shiftDay}/${shiftId}`)
        );

        if (!shift) {
          console.log('Shift does not exist!');

          toast.show({
            title: 'Something went wrong. Please refresh the page.',
            placement: 'bottom-left',
            ml: '20px',
          });

          return false;
        }

        let clockInReferenceKey = null;

        if (type === 'checkOut') {
          clockInReferenceKey = keys(cacheData).find(
            (eventId) => get(cacheData, [eventId, 'shiftId']) === shiftId
          );

          if (!clockInReferenceKey) {
            console.log(`Cannot find cache for shift ${shiftId}!`);

            toast.show({
              title: 'Something went wrong. Please refresh the page.',
              placement: 'bottom-left',
              ml: '20px',
            });

            return false;
          }
        }

        const attendanceData: AttendanceEventInterface = {
          createdAt: dbAPI.getServerTimestamp(),
          clockInReferenceKey,
          event: type,
          deviceTime: Date.now(),
          st: dbAPI.getServerTimestamp(),
          shiftId,
          shiftDay,
          actionDate: +moment().startOf('day'),
        };

        const newEventId = dbAPI.getPushKeyRef(
          dbAPI.getCurrentUserAttendanceNode()
        );

        const updates: { [key: string]: any } = {
          [`/${dbAPI.getCommonPath(
            'currentUserAttendanceEvents'
          )}/${newEventId}`]: attendanceData,
        };

        if (type === 'checkIn') {
          updates[
            `/${dbAPI.getCommonPath(
              'currentUserAttendanceCache'
            )}/${newEventId}`
          ] = attendanceData;
          updates[
            `/${dbAPI.getCommonPath(
              'shifts'
            )}/days/${shiftDay}/${shiftId}/isCheckedIn`
          ] = true;

          if (!shift.clockin && !shift.attInRefKey) {
            updates[
              `/${dbAPI.getCommonPath(
                'shifts'
              )}/days/${shiftDay}/${shiftId}/clockin`
            ] = attendanceData.createdAt;
            updates[
              `/${dbAPI.getCommonPath(
                'shifts'
              )}/days/${shiftDay}/${shiftId}/attInRefKey`
            ] = newEventId;
          }
        } else {
          updates[
            `/${dbAPI.getCommonPath(
              'currentUserAttendanceCache'
            )}/${clockInReferenceKey}`
          ] = null;
          updates[
            `/${dbAPI.getCommonPath(
              'shifts'
            )}/days/${shiftDay}/${shiftId}/isCheckedIn`
          ] = false;

          updates[
            `/${dbAPI.getCommonPath(
              'shifts'
            )}/days/${shiftDay}/${shiftId}/clockout`
          ] = attendanceData.createdAt;
          updates[
            `/${dbAPI.getCommonPath(
              'shifts'
            )}/days/${shiftDay}/${shiftId}/attOutRefKey`
          ] = newEventId;
        }

        await dbAPI.fbUpdate('/', updates);

        return true;
      }

      return false;
    },
    [setIsWritingAttendance, toast, dbAPI]
  );

  const dropShift = useCallback(
    async (shift: ShiftWithIdInterface) => {
      if (dbAPI) {
        const payload = {
          organization: dbAPI.organization,
          shiftDay: shift.shiftDay,
          userId: shift.userId,
          shiftDetails: shift,
          reason: 'Drop from KNOW web app',
          convertToOpenShift: false,
          isMobile: true,
        };

        await dbAPI.fbUpdate(
          `/shiftRPC/drop/request/${dbAPI.getPushKey()}`,
          payload
        );

        await dbAPI.fbUpdate(
          `${dbAPI.getCommonPath('shifts')}/days/${shift.shiftDay}`,
          { [shift.shiftId]: null }
        );
      }
    },
    [dbAPI]
  );

  const goBackOneWeek = useCallback(() => {
    setIsLoadingShiftsByDates({ default: true });
    setDateRange({
      startDate: moment(dateRange.startDate).add(-1, 'week').startOf('week'),
      endDate: moment(dateRange.startDate).add(-1, 'week').endOf('week'),
    });
  }, [dateRange.startDate, setDateRange, setIsLoadingShiftsByDates]);

  const goForwardOneWeek = useCallback(() => {
    setIsLoadingShiftsByDates({ default: true });
    setDateRange({
      startDate: moment(dateRange.startDate).add(1, 'week').startOf('week'),
      endDate: moment(dateRange.startDate).add(1, 'week').endOf('week'),
    });
  }, [dateRange.startDate, setDateRange, setIsLoadingShiftsByDates]);

  const registerToListenOnDailyUserUpdateNode = useCallback(
    (
      shift: ShiftWithIdInterface,
      cb: (newDailyUserUpdate: DailyUserUpdateInterface) => any
    ): void => {
      if (dbAPI) {
        const { shiftDay, shiftSlot, locationId } = shift;
        try {
          dbAPI.listenOnNodeRef(
            dbAPI.getNodeRef(
              `${dbAPI.getCommonPath(
                'shifts'
              )}/daily-user-updates/${shiftDay}/${locationId}/${shiftSlot.id}`
            ),
            'value',
            (snapshot: any) => {
              const value: DailyUserUpdateInterface = snapshot.val();
              cb(value);
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    [dbAPI]
  );

  const unregisterListeningOnDailyUserUpdateNode = useCallback(
    (shift: ShiftWithIdInterface): void => {
      if (dbAPI) {
        const { shiftDay, shiftSlot, locationId } = shift;
        try {
          dbAPI.offNodeRef(
            dbAPI.getNodeRef(
              `${dbAPI.getCommonPath(
                'shifts'
              )}/daily-user-updates/${shiftDay}/${locationId}/${shiftSlot.id}`
            ),
            'value'
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    [dbAPI]
  );

  return {
    shiftsList,
    dateRange,
    goBackOneWeek,
    goForwardOneWeek,
    isLoading,
    writeAttendanceEvent,
    dropShift,
    registerToListenOnDailyUserUpdateNode,
    unregisterListeningOnDailyUserUpdateNode,
  };
};
