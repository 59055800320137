import React, { Fragment, useState } from 'react';
import { View, Image, Text, Pressable, FlatList } from '@know/ui';
import { StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import Images from '../../../Images';
import type { Pdf } from './types';

export const PDFFileAttachment = ({
  pdfPayload,
  handlePDFConsumed,
}: {
  pdfPayload: Pdf[];
  handlePDFConsumed: (index: number) => void;
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const styles = StyleSheet.create({
    contentContainer: {
      borderColor: '#F4F4F4',
      borderWidth: 1,
      borderBottomEndRadius: 3,
      borderBottomStartRadius: 3,
    },
  });

  const handlePDFDownload = (url: any, name: any, index: number) => {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    handlePDFConsumed(index);
  };

  const singlePDFName = pdfPayload?.[0]?.name ?? 'Document 1.PDF';

  return (
    <Fragment>
      <Pressable
        onPress={() => setShowDropDown(!showDropDown)}
        borderTopRadius="3px"
        borderBottomRadius={showDropDown ? 0 : '3px'}
        overflow="hidden"
        marginTop="10px"
      >
        <View
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingX="10px"
          paddingY="8px"
          bgColor="gray.200"
        >
          <View flexDirection="row" alignItems="center">
            <Image
              source={Images.FileIcon}
              w={['16px', '16px', '17px', '18px', '18px']}
              h={['16px', '16px', '17px', '18px', '18px']}
              alt="file-icon"
            />
            <Text
              marginLeft="6px"
              fontSize={['13px', '13px', '13px', '14px', '14px']}
              fontWeight="semibold"
            >
              {pdfPayload?.length === 1 ? (
                <Text
                  onPress={() => {
                    handlePDFDownload(pdfPayload?.[0]?.url, singlePDFName, 0);
                  }}
                >
                  {singlePDFName ?? 'Document 1.PDF'}
                </Text>
              ) : (
                `File Attachments{' '} ${pdfPayload.length}`
              )}
            </Text>
          </View>
          {pdfPayload?.length !== 1 && (
            <Ionicons
              name={showDropDown ? 'chevron-up' : 'chevron-forward'}
              size={16}
              color="gray.700"
            />
          )}
        </View>
      </Pressable>
      {showDropDown && pdfPayload?.length !== 1 && (
        <FlatList
          scrollEnabled={false}
          data={pdfPayload}
          keyExtractor={(item) => item?.url}
          contentContainerStyle={styles.contentContainer}
          ItemSeparatorComponent={() => {
            return <View h="1px" w="100%" bgColor="gray.200" />;
          }}
          renderItem={({ item, index }) => {
            const pdfName = item?.name
              ? item?.name
              : `Document ${index + 1}.PDF`;
            return (
              <Pressable
                onPress={() => {
                  handlePDFDownload(item?.url, pdfName, index);
                }}
                padding="10px"
              >
                <Text color="gray.700" numberOfLines={1}>
                  {pdfName}
                </Text>
              </Pressable>
            );
          }}
        />
      )}
    </Fragment>
  );
};
