import React from 'react';
import { Image as ImageFromReactNative } from 'react-native';
import { View, Pressable, Text } from '@know/ui';
import Images from '../../../../Images';

type ICheckbox = {
  multiSelectQuizRes: any;
  setMultiSelectQuizRes: (res: any) => void;
  selectedIndex: number;
  handleQuizResponses: (params: {
    selectedIndex: number;
    selectedOption: number | any[];
  }) => void;
  setQuizProgress: (progress: any) => void;
  quizProgress: string[];
  styles: any;
  isSelected: (
    selectedOption: any,
    item: any,
    isHovered: boolean,
    isCheckbox?: boolean
  ) => 'primary.500' | 'gray.400' | 'gray.300';
  index: any;
  item: any;
  selectedQuestion: any;
  setShowModal: (value: boolean) => void;
};
export const CheckBox = ({
  multiSelectQuizRes,
  setMultiSelectQuizRes,
  selectedIndex,
  setQuizProgress,
  quizProgress,
  styles,
  index,
  item,
  selectedQuestion,
  setShowModal,
}: ICheckbox) => {
  return (
    <Pressable
      w="50%"
      onPress={() => {
        let result = [...multiSelectQuizRes];
        if (result.includes(index + 1)) {
          result = result.filter((rs) => rs !== index + 1);
          setMultiSelectQuizRes(result);
        } else if (
          selectedQuestion.isLimit &&
          result.length >= selectedQuestion.answer.split(',').length
        ) {
          setShowModal(true);
          return;
        } else {
          result.push(index + 1);
          setMultiSelectQuizRes(result);
        }

        setQuizProgress((prevState: any) => {
          const state = [...prevState];
          state[selectedIndex] = result;
          return state;
        });

        // const payload = {
        //   selectedIndex,
        //   selectedOption: result,
        // };
        // handleQuizResponses(payload);
      }}
    >
      {({ isHovered }) => {
        const selectedOption: any = quizProgress[selectedIndex] || [];

        return (
          <View
            flex={1}
            marginY={['4px', '4px', '6px', '6px', '6px']}
            marginLeft={
              index % 2 === 0 ? '0' : ['4px', '4px', '6px', '6px', '6px']
            }
            marginRight={
              index % 2 === 0 ? ['4px', '4px', '6px', '6px', '6px'] : '0'
            }
            borderColor={
              selectedOption.includes(index + 1) || isHovered
                ? 'primary.500'
                : 'gray.300'
            }
            borderWidth="1px"
            borderRadius="5px"
          >
            <View
              flexDirection="row"
              alignItems="center"
              paddingY="10px"
              paddingX="8px"
            >
              <ImageFromReactNative
                style={styles.imageFromReactNative}
                source={
                  selectedOption.includes(index + 1)
                    ? Images.SelectedCheckboxButton
                    : Images.CheckboxButton
                }
              />
              <Text marginLeft="5px" color="gray.900">
                {item}
              </Text>
            </View>
          </View>
        );
      }}
    </Pressable>
  );
};
