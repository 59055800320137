import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  Center,
  Logo,
  VStack,
  PhoneNumberInput,
  Button,
  KNOWDBContext,
  WithKNOWLoader,
  Input,
  IModuleProps,
  useRouterContext,
  useToast,
  ExpoFAIcon,
  Link,
  // HStack,
} from '@know/ui';

export const LoginPage = () => {
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);

  const [code, setCode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [otp, setOTP] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState<boolean>(false);
  const {
    sendOTP,
    verifyOTP,
    isLoading: isLoadingAuth,
  } = useContext(KNOWDBContext);

  const OTPRef = useRef();

  useEffect(() => {
    OTPRef && OTPRef.current && OTPRef.current;
  }, [OTPRef]);

  useEffect(() => {
    const valueValid = /^\+\d{6,}$/.test(value.trim());
    setIsValid(valueValid);
  }, [value]);

  const handleChangeValue = useCallback((newValue, newCode, newPhone) => {
    setValue(newValue);
    setCode(newCode);
    setPhone(newPhone);
  }, []);

  const toast = useToast();

  const handleSendOTP = useCallback(async () => {
    try {
      setIsLoading(true);
      await sendOTP(code, phone);
      setIsVerifyingOTP(true);
    } catch (err: any) {
      toast.show({
        title: err.message || 'Something went wrong. Please refresh the page!',
        placement: 'bottom-left',
        ml: '20px',
      });
    } finally {
      setIsLoading(false);
    }
  }, [code, phone, sendOTP, toast]);

  const handleVerifyOTP = useCallback(async () => {
    try {
      setIsLoading(true);
      await verifyOTP(code, phone, otp);
    } catch (err: any) {
      toast.show({
        title: err.message || 'Something went wrong. Please refresh the page!',
        placement: 'bottom-left',
        ml: '20px',
      });
    } finally {
      setIsLoading(false);
    }
  }, [code, phone, verifyOTP, otp, toast]);

  return isLoadingAuth ? null : (
    <Center
      flex={1}
      bg="primary.600"
      width={{ base: '100%' }}
      position="relative"
    >
      <VStack space={3} alignItems="center" justifyContent="center">
        <Logo size="lg" />
        <WithKNOWLoader isLoading={isLoading} color="primary" type="horizontal">
          {isVerifyingOTP ? (
            <>
              <Input
                placeholder="Enter OTP"
                value={otp}
                onChangeText={(text: string) => setOTP(text)}
                keyboardType="numeric"
                variant="filled"
                backgroundColor="white"
                maxLength={6}
                onSubmitEditing={handleVerifyOTP}
                InputLeftElement={
                  <ExpoFAIcon
                    ml={2}
                    color="muted.700"
                    name="arrow-circle-o-left"
                    size="sm"
                    onPress={() => {
                      setIsVerifyingOTP(false);
                      setOTP('');
                    }}
                  />
                }
                _focus={{
                  borderWidth: 0,
                  borderColor: 'white',
                }}
                borderWidth={0}
                borderColor="white"
              />
              <Button
                colorScheme="secondary"
                isDisabled={otp.length !== 6}
                onPress={handleVerifyOTP}
                marginTop="12px"
              >
                Enter
              </Button>
              <Link
                onPress={handleSendOTP}
                _text={{ color: 'white', fontWeight: 500 }}
                mt="12px"
              >
                Resend OTP
              </Link>
            </>
          ) : (
            <>
              <PhoneNumberInput
                value={value}
                handleChangeValue={handleChangeValue}
                handleSubmitEditing={handleSendOTP}
              />
              <Button
                colorScheme="secondary"
                isDisabled={!isValid}
                onPress={handleSendOTP}
                marginTop="12px"
              >
                Login
              </Button>
            </>
          )}
        </WithKNOWLoader>
      </VStack>
    </Center>
  );
};

export const LoginScreen = () => {
  const { registerScreen } = useRouterContext();

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'login',
      name: 'Login',
      priority: 0,
      component: LoginPage,
      icon: 'calendar',
      isPrivate: false,
    };

    registerScreen(module);
  }, [registerScreen]);

  return null;
};
