import { selector, selectorFamily } from '@know/ui';
import {
  ISentResponseItem,
  prepareUserSentFormsListData,
} from '@know/transformers';

import {
  userSentFormsAtom,
  sentListFilterTextAtom,
  sentSectionsExpandStateAtom,
} from './atoms';

import type { ISectionDataProps } from '@know/transformers';

// Define the selector
// export const userFormSentFormsDataSelector = selector<
//   ISectionDataProps<ISentResponseItem>[]
// >({
//   key: 'userFormSentFormsDataSelector',
//   get: ({ get }) => {
//     const userSentForms = get(userSentFormsAtom);
//     const filterText = get(sentListFilterTextAtom);

//     return prepareUserSentFormsListData({
//       userSentForms,
//       filterText,
//     });
//   },
// });

export const userSentSectionDataSelectorWithToggleState = selector<
  ISectionDataProps<ISentResponseItem>[]
>({
  key: 'userSentSectionDataSelectorWithToggleState',
  get: ({ get }) => {
    const userSentForms = get(userSentFormsAtom);
    const filterText = get(sentListFilterTextAtom);

    const userSentSectionData = prepareUserSentFormsListData({
      userSentForms,
      filterText,
    });
    const toggleState = get(sentSectionsExpandStateAtom);

    return userSentSectionData.map((sectionItem) => {
      return {
        ...sectionItem,
        isExpanded: toggleState[sectionItem.id || sectionItem.type],
      };
    });
  },
});

export const sentFormSelector = selectorFamily({
  key: 'getSentForm',
  get:
    (formId: string) =>
    ({ get }) => {
      const userSentForms = get(userSentFormsAtom);
      return userSentForms[formId];
    },
});
