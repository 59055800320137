import React from 'react';
import { SectionsListViewWithFilter, View, WithKNOWLoader } from '@know/ui';

import { useUserReceivedForms } from './useUserReceivedForms';

import {
  keyExtractor,
  getSectionHeaderComponent,
  ItemComponent,
} from './renderers';

export function ReceivedFormsPage() {
  const {
    isDataLoading,
    receivedSectionData,
    filterText,
    isSmallScreen,
    onToggleSection,
    onClickItem,
    onFilterTextChange,
  } = useUserReceivedForms();

  return (
    <WithKNOWLoader
      isLoading={isDataLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <View px={'16px'} h={'100%'} mt={isSmallScreen ? '12px' : 0}>
        <SectionsListViewWithFilter
          onFilterChange={onFilterTextChange}
          data={receivedSectionData as any}
          onToggleSection={onToggleSection}
          onClickItem={onClickItem}
          keyExtractor={keyExtractor}
          getSectionHeaderComponent={getSectionHeaderComponent}
          ItemComponent={ItemComponent as any}
          hasFilterText={!!filterText}
        />
      </View>
    </WithKNOWLoader>
  );
}
