import React from 'react';
import { FlatList, Flex, Box } from '@know/ui';
import { InputFilter } from '../InputFilter';
import { FlatListWithFilterProps } from './types';

export function FlatListWithFilter(props: FlatListWithFilterProps<any>) {
  const {
    onFilterChange,
    hasFilter = true,
    hasFilterText,
    NoDataComponent = null,
    NoResultsFoundComponent = null,
    filterText,
    ...rest
  } = props;

  return (
    <Flex direction="column" flex={1}>
      <Box px={'16px'}>
        <InputFilter
          defaultValue={filterText}
          hasFilter={hasFilter}
          onChange={onFilterChange}
        />
      </Box>
      {!hasFilterText && !props.data?.length ? NoDataComponent : null}
      {hasFilterText && !props.data?.length ? NoResultsFoundComponent : null}
      <Box w={'100%'} flex={1} mt={'6px'}>
        <FlatList w={'full'} mb="4" ml="2" {...rest} />
      </Box>
    </Flex>
  );
}
