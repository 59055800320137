import { useState, useCallback, useContext, useEffect } from 'react';
import { KNOWDBContext, useRecoilState, useRecoilValue } from '@know/ui';

import {
  currentCourseTabAtom,
  ljLanguageModalAtom,
  userCompletedCourseFeedSelector,
  userCourseFeedSelector,
} from '../state';

export const useLanguageSwitch = (
  journeyId: string,
  cardInfo: {
    hasDefaultLanguage: boolean;
    languages: string[];
    selectedLanguage: string;
  } = {
    hasDefaultLanguage: false,
    languages: [],
    selectedLanguage: '',
  }
) => {
  const { currentDBApi } = useContext(KNOWDBContext);

  const [ljModalVisible, setLjModalVisible] =
    useRecoilState<boolean>(ljLanguageModalAtom);

  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const [userFeed, setUserFeedObject] = useRecoilState(
    currentCourseTab === 'completed-courses'
      ? userCompletedCourseFeedSelector(journeyId)
      : userCourseFeedSelector(journeyId)
  );

  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    userFeed?.lang
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleModal = async (isAPICall: boolean) => {
    let isProgressReset = false;
    setIsLoading(true);
    const payload = {
      journeyId,
      selectedLanguage,
      feedType:
        currentCourseTab === 'completed-courses' ? 'completedFeed' : 'userFeed',
    };
    if (currentDBApi && isAPICall) {
      await currentDBApi?.setUserFeedCourseInfo('SetLanguage', payload);
      if (userFeed?.lang !== selectedLanguage) {
        isProgressReset = true;
        await currentDBApi.resetCourseProgress({
          courseId: journeyId,
          isLanguageSwitch: isProgressReset,
        });
      }
      setUserFeedObject((prevState) => ({
        ...prevState,
        lang: selectedLanguage,
        finishedCount: isProgressReset ? 0 : prevState.finishedCount,
      }));
    }
    setLjModalVisible((prevState) => !prevState);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!cardInfo?.hasDefaultLanguage && cardInfo?.languages.length > 1) {
      setLjModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo?.hasDefaultLanguage]);

  const handleChangeLanguage = useCallback(
    (value: string) => {
      setSelectedLanguage(value);
    },
    [setSelectedLanguage]
  );

  return {
    ljModalVisible,
    selectedLanguage,
    handleToggleModal,
    handleChangeLanguage,
    isLoading,
  };
};
