import { atom, selector } from '@know/ui';
import { IFormActionOnItem } from '@know/db';
import { keys, values } from 'lodash';
import {
  prepareActionRequiredFormsData,
  filterActionRequiredFormsData,
  IFormActionRequiredFeedItems,
} from '@know/transformers';

export const userFormActionRequiredItemsAtom = atom<
  Record<string, IFormActionOnItem>
>({
  key: 'userFormActionRequiredItemsAtom',
  default: {},
});

export const formActionRequiredItemsSearchStringAtom = atom<string>({
  key: 'formActionRequiredItemsSearchStringAtom',
  default: '',
});

export const actionRequiredItemsSelector = selector<
  [number, IFormActionRequiredFeedItems[]]
>({
  key: 'actionRequiredItemsSelector',
  get: ({ get }) => {
    const formActionRequiredItems = get(userFormActionRequiredItemsAtom);
    const formattedData = prepareActionRequiredFormsData(
      values(formActionRequiredItems)
    );

    return [keys(formActionRequiredItems).length, formattedData];
  },
});

// export const actionRequiredFeedItemsSelector = selector<
//   IFormActionRequiredFeedItems[]
// >({
//   key: 'actionRequiredFeedItemsSelector',
//   get: ({ get }) => {
//     const formActionRequiredItems = get(userFormActionRequiredItemsAtom);

//     console.log(
//       'actionRequiredFeedItemsSelector formActionRequiredItems',
//       formActionRequiredItems
//     );

//     const formattedData = prepareActionRequiredFormsData(
//       values(formActionRequiredItems)
//     );

//     return formattedData;
//   },
// });

export const filteredActionRequiredFeedItemsSelector = selector<
  IFormActionRequiredFeedItems[]
>({
  key: 'filteredActionRequiredFeedItemsSelector',
  get: ({ get }) => {
    const [, formActionRequiredFeedItems] = get(actionRequiredItemsSelector);
    const filterText = get(formActionRequiredItemsSearchStringAtom);

    return filterActionRequiredFormsData({
      items: formActionRequiredFeedItems,
      filterText,
    });
  },
});

export const isLoadingActionRequiredAtom = atom<boolean>({
  key: 'isLoadingActionRequiredAtom',
  default: true,
});
