import { IFormActionRequiredFeedItems } from '@know/transformers';
import React from 'react';
import {
  FormActionRequiredRepeatItem,
  FormActionRequiredWorkFlowItem,
} from '@know/ui';

export const ActionRequiredFlatListItemRender =
  (onClickItem: (item: IFormActionRequiredFeedItems) => void) =>
  ({ item }: { item: IFormActionRequiredFeedItems }) => {
    if (item.isRepeating) {
      return <FormActionRequiredRepeatItem item={item} onPress={onClickItem} />;
    } else {
      return (
        <FormActionRequiredWorkFlowItem item={item} onPress={onClickItem} />
      );
    }
  };
