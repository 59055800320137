import React from 'react';
import { StyleSheet } from 'react-native';

import { View, Image } from '@know/ui';
import Images from '../../Images';

export const LockLesson = () => {
  return (
    <View
      position="absolute"
      style={StyleSheet.absoluteFill}
      alignItems="center"
      justifyItems="center"
      justifyContent="center"
    >
      <Image
        source={Images.LockOverlay}
        alt="lock-lesson"
        h={['28px', '28px', '30px', '30px', '30px']}
        w={['28px', '28px', '30px', '30px', '30px']}
      />
    </View>
  );
};
