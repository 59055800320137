import React, { useMemo } from 'react';
import {
  TaskAuditLinkPayloadType,
  TaskFormDraftPayloadType,
  TaskFormLinkPayloadType,
  TaskFormSubmissionPayloadType,
  TaskUpdateType,
} from '@know/db';
import { Box, Pressable, View } from '@know/ui';
import {
  AssignFormIcon,
  AuditLinkIcon,
  ExpoEntypoIcon,
  ExpoFAIcon,
  ExpoIonicIcon,
  ExpoMCIcon,
  FormSubmissionIcon,
  StartTaskIcon,
  Text,
  Image,
  FormDraftIcon,
  TaskUpdateCommentIcon,
} from '@know/ui';
import { PDFIcon, AudioIcon, VideoIcon, ExpoEvilIcon } from './index';
import moment from 'moment';
import { useTranslation } from '@know/i18n';

export type TaskStatusOnClickPayloadType = (
  payload:
    | TaskAuditLinkPayloadType
    | TaskFormDraftPayloadType
    | TaskFormLinkPayloadType
    | TaskFormSubmissionPayloadType
) => any;

type ITaskUpdateProps = {
  update: TaskUpdateType;
  isFirst?: boolean;
  onClickPayload?: TaskStatusOnClickPayloadType;
  onClickImage?: (url: string) => any;
  onClickPdf?: (url: string) => any;
};

export const TaskUpdate: React.FC<ITaskUpdateProps> = ({
  update,
  isFirst,
  onClickImage,
  onClickPayload,
  onClickPdf,
}) => {
  const { t } = useTranslation(['forms', 'messages']);

  const updateIcon = useMemo(() => {
    switch (update.type) {
      case 'no_leaders':
      case 'share':
        return (
          <ExpoIonicIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="share-social-sharp"
          />
        );
      case 'form_link':
        if (update?.payload?.[0]?.type === 'form_submission') {
          return (
            <FormSubmissionIcon
              color="primary.500"
              size="18px"
              mt="2px"
              mr="4px"
            />
          );
        }

        if (update?.payload?.[0]?.type === 'form_draft') {
          return (
            <FormDraftIcon color="primary.500" size="18px" mt="2px" mr="4px" />
          );
        }

        return (
          <AssignFormIcon color="primary.500" size="18px" mt="2px" mr="4px" />
        );
      case 'audit_link':
        return (
          <AuditLinkIcon color="primary.500" size="18px" mt="2px" mr="4px" />
        );
      case 'status':
        if (update?.payload?.[0].value === 'inprogress') {
          return (
            <StartTaskIcon color="primary.500" size="18px" mt="2px" mr="4px" />
          );
        }

        if (update?.payload?.[0].value === 'completed') {
          return (
            <ExpoIonicIcon
              color="primary.500"
              size="18px"
              mt="2px"
              mr="4px"
              name="checkbox"
            />
          );
        }

        if (update?.payload?.[0].value === 'reopened') {
          return (
            <ExpoMCIcon
              color="primary.500"
              size="18px"
              mt="2px"
              mr="4px"
              name="reload"
            />
          );
        }

        return (
          <StartTaskIcon color="primary.500" size="18px" mt="2px" mr="4px" />
        );

      case 'remark':
        return (
          <TaskUpdateCommentIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="image"
          />
        );
      case 'image':
        return (
          <ExpoIonicIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="image"
          />
        );
      case 'audio':
        return (
          <AudioIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      case 'video':
        return (
          <VideoIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      case 'pdf':
        return (
          <PDFIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      default:
        return (
          <StartTaskIcon color="primary.500" size="18px" mt="2px" mr="4px" />
        );
    }
  }, [update]);

  const updateMessage = useMemo(() => {
    switch (update.type) {
      case 'share':
        return 'shared this task to';
      case 'no_leaders':
        return 'tried to share this task to location leaders:';
      case 'form_link':
        const formLinkType = update?.payload?.[0]?.type;

        if (formLinkType === 'form_link') {
          return 'assigned a form to be completed with this task.';
        }
        if (formLinkType === 'form_draft') {
          return 'drafted a form response assigned to this task.';
        }

        if (formLinkType === 'form_submission') {
          return 'submitted the form assigned to this task.';
        }

        return '';

      case 'audit_link':
        return 'created the task from an Audit form.';
      case 'status':
        if (update?.payload?.[0].value === 'inprogress') {
          return 'started the task.';
        }

        if (update?.payload?.[0].value === 'completed') {
          return `completed the task ${
            update?.payload?.[0].remark ? 'with a remark' : '.'
          }`;
        }

        if (update?.payload?.[0].value === 'reopened') {
          return `re-opened the task ${
            update?.payload?.[0].remark ? 'with a remark' : '.'
          }`;
        }

        return '';
      case 'remark':
        return 'added a remark.';
      case 'image':
        return 'added image(s)';
      case 'audio':
        return 'added an audio';
      case 'video':
        return 'added a video';
      case 'pdf':
        return 'added a pdf';
      default:
        return (update as TaskUpdateType).type;
    }
  }, [update]);

  const updateComponent = useMemo(() => {
    switch (update.type) {
      case 'share':
        return (
          <View
            display={'inline-flex'}
            flexDir={'row'}
            maxW={'100%'}
            flexWrap={'wrap'}
          >
            {update.payload.map(({ id, value }) => {
              return (
                <Box
                  key={id}
                  py={'4px'}
                  px={'8px'}
                  bg={'gray.200'}
                  mt={'8px'}
                  mr={'12px'}
                >
                  <Text
                    color={'gray.1000'}
                    fontWeight={600}
                    fontSize={'14px'}
                    fontFamily={'tableCell'}
                  >
                    {value}
                  </Text>
                </Box>
              );
            })}
          </View>
        );
      case 'no_leaders':
        return (
          <Text fontSize={'14px'} fontWeight={400} fontFamily={'tableCell'}>
            {'Location leaders have not been assigned.'}
          </Text>
        );
      case 'form_link':
      case 'audit_link':
        return (
          <Box w={'100%'} mt="8px">
            {update?.payload?.map((_payload) => {
              let payload: any = {
                ..._payload,
                updateUserId: update.userID,
                updateType: update.type,
              };
              const { value, type } = payload;
              if (update.type === 'form_link') {
                // NOTE: Form links UserID should be the initiator
                payload.value.submitterId = payload.updateUserId;

                if (type === 'form_submission') {
                  let text = '';
                  let incharge = '';
                  let status = value?.status?.toLowerCase();

                  if (value.isWorkFlow) {
                    if (status === 'pending') {
                      text = 'With';
                      incharge = value?.receiverName ?? '-';
                    } else if (
                      status === 'submitted' ||
                      status === 'approved' ||
                      status === 'rejected'
                    ) {
                      text = 'By';
                      incharge = value?.senderName ?? '-';
                    } else if (status === 'suspended') {
                      text = 'By';
                      incharge =
                        value?.suspendReleaseDetails?.byUserName ?? '-';
                    } else if (status === 'returned') {
                      text = 'From';
                      incharge = value?.receiverName ?? '-';
                    }
                  }
                  return (
                    <Pressable
                      key={`response-${value.formId}-${value.responseId}`}
                      onPress={() => onClickPayload?.(payload)}
                    >
                      <View
                        w="100%"
                        display={'flex'}
                        flexDir={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        bg={'rgba(0, 193, 178, 0.1)'}
                        borderColor={'primary.500'}
                        borderWidth={'1px'}
                        borderRadius={'6px'}
                        py={'6px'}
                        px={'12px'}
                      >
                        <View display={'flex'} flex={1} flexDir={'column'}>
                          <Text
                            fontFamily={'tableCell'}
                            fontWeight={600}
                            fontSize={'14px'}
                            flex={1}
                          >
                            {value?.name ?? '-'}
                          </Text>
                          <Text
                            fontFamily={'tableCell'}
                            fontWeight={400}
                            fontSize={'14px'}
                            flex={1}
                          >
                            {t('Response ID')}: {value.sno ?? '-'}
                          </Text>
                          {value.isWorkFlow ? (
                            <View
                              display={'flex'}
                              flexDir={'row'}
                              alignItems={'center'}
                              justifyContent={'flex-start'}
                              my={'4px'}
                            >
                              <Box
                                py={'4px'}
                                px={'10px'}
                                borderRadius={'30px'}
                                mr={'6px'}
                                w="100px"
                                textAlign={'center'}
                                bgColor={
                                  value.status?.toLowerCase() === 'pending'
                                    ? 'orange.400'
                                    : value.status?.toLowerCase() ===
                                        'approved' ||
                                      value.status?.toLowerCase() ===
                                        'submitted'
                                    ? 'green.400'
                                    : 'red.400'
                                }
                              >
                                <Text
                                  textTransform={'capitalize'}
                                  fontSize={'13px'}
                                  fontWeight={600}
                                  color={
                                    value.status?.toLowerCase() === 'pending'
                                      ? 'gray.1000'
                                      : 'white'
                                  }
                                >
                                  {value.status}
                                </Text>
                              </Box>
                              <Text color={'#363F3D'} fontFamily={'tableCell'}>
                                {text} <Text fontWeight={600}>{incharge}</Text>
                              </Text>
                            </View>
                          ) : null}
                        </View>
                        <ExpoEntypoIcon
                          name="chevron-right"
                          size={'16px'}
                          ml={'6px'}
                        />
                      </View>
                    </Pressable>
                  );
                }

                if (type === 'form_link') {
                  return (
                    <Pressable
                      key={`form-${value.formId}`}
                      onPress={() => onClickPayload?.(payload)}
                    >
                      <View
                        w="100%"
                        display={'flex'}
                        flexDir={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        bg={'rgba(0, 193, 178, 0.1)'}
                        borderColor={'primary.500'}
                        borderWidth={'1px'}
                        borderRadius={'6px'}
                        py={'6px'}
                        px={'12px'}
                      >
                        <Text
                          fontFamily={'tableCell'}
                          fontWeight={600}
                          fontSize={'14px'}
                          flex={1}
                        >
                          {value?.name ?? '-'}
                        </Text>
                        <ExpoEntypoIcon
                          name="chevron-right"
                          size={'16px'}
                          ml={'6px'}
                        />
                      </View>
                    </Pressable>
                  );
                }

                if (type === 'form_draft') {
                  return (
                    <Pressable
                      key={`draft-${value.formId}-${value.draftId}`}
                      onPress={() => onClickPayload?.(payload)}
                    >
                      <View
                        w="100%"
                        display={'flex'}
                        flexDir={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        bg={'rgba(255, 181, 70, .2)'}
                        borderColor={'orange.500'}
                        borderWidth={'1px'}
                        borderRadius={'6px'}
                        py={'6px'}
                        px={'12px'}
                      >
                        <View display={'flex'} flex={1} flexDir={'column'}>
                          <Text
                            fontFamily={'tableCell'}
                            fontWeight={600}
                            fontSize={'14px'}
                            flex={1}
                          >
                            {value?.name ?? '-'}
                          </Text>
                          <Text
                            fontFamily={'tableCell'}
                            fontWeight={400}
                            fontSize={'14px'}
                            flex={1}
                            textTransform={'uppercase'}
                          >
                            {`[${t('draft')}]`}
                          </Text>
                        </View>
                        <ExpoEntypoIcon
                          name="chevron-right"
                          size={'16px'}
                          ml={'6px'}
                        />
                      </View>
                    </Pressable>
                  );
                }
              } else {
                if (type === 'form_response') {
                  return (
                    <Pressable
                      key={`audit-${value.formId}-${value.responseId}`}
                      onPress={() => onClickPayload?.(payload)}
                    >
                      <View
                        w="100%"
                        display={'flex'}
                        flexDir={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        bg={'rgba(0, 193, 178, 0.1)'}
                        borderColor={'primary.500'}
                        borderWidth={'1px'}
                        borderRadius={'6px'}
                        py={'6px'}
                        px={'12px'}
                      >
                        <View display={'flex'} flex={1} flexDir={'column'}>
                          <View display={'flex'} flex={1} flexDir={'column'}>
                            <Text
                              fontFamily={'tableCell'}
                              fontSize={'12px'}
                              flex={1}
                            >
                              {t('Audit Form')}
                            </Text>
                            <Text
                              fontFamily={'tableCell'}
                              fontWeight={600}
                              fontSize={'14px'}
                              flex={1}
                            >
                              {value.formName ?? '-'}: {value.sno ?? '-'}
                            </Text>
                          </View>
                          <View
                            display={'flex'}
                            flex={1}
                            flexDir={'column'}
                            mt={'10px'}
                          >
                            <Text
                              fontFamily={'tableCell'}
                              fontSize={'12px'}
                              flex={1}
                            >
                              {value.groupName ?? '-'}
                            </Text>
                            <Text
                              fontFamily={'tableCell'}
                              fontWeight={600}
                              fontSize={'14px'}
                              flex={1}
                            >
                              {value.questionName ?? '-'}
                            </Text>
                          </View>
                          <View
                            display={'flex'}
                            flex={1}
                            flexDir={'row'}
                            mt={'10px'}
                          >
                            <View display={'flex'} flex={1} flexDir={'column'}>
                              <Text
                                fontFamily={'tableCell'}
                                fontSize={'12px'}
                                flex={1}
                              >
                                {t('Submitted by')}
                              </Text>
                              <Text
                                fontFamily={'tableCell'}
                                fontWeight={600}
                                fontSize={'14px'}
                                flex={1}
                              >
                                {value.submitterName ?? '-'}
                              </Text>
                            </View>
                            <View display={'flex'} flex={1} flexDir={'column'}>
                              <Text
                                fontFamily={'tableCell'}
                                fontSize={'12px'}
                                flex={1}
                              >
                                {t('Date')}
                              </Text>
                              <Text
                                fontFamily={'tableCell'}
                                fontWeight={600}
                                fontSize={'14px'}
                                flex={1}
                              >
                                {value.submittedAt
                                  ? moment(value.submittedAt).format(
                                      'D MMM YYYY'
                                    )
                                  : ''}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <ExpoEntypoIcon
                          name="chevron-right"
                          size={'16px'}
                          ml={'6px'}
                        />
                      </View>
                    </Pressable>
                  );
                }
              }

              return null;
            })}
          </Box>
        );
      case 'status':
        if (update?.payload?.[0].remark) {
          return (
            <Text
              color={'gray.700'}
              fontSize={'14px'}
              fontWeight={400}
              fontFamily={'tableCell'}
            >
              "{update?.payload?.[0].remark}"
            </Text>
          );
        }

        return null;
      case 'remark':
        return (
          <Text
            color={'gray.700'}
            fontSize={'14px'}
            fontWeight={400}
            fontFamily={'tableCell'}
          >
            "{update?.payload?.[0].value}"
          </Text>
        );
      case 'image':
        return (
          <View display={'flex'} flexDir={'row'} flexWrap={'wrap'} w={'100%'}>
            {update?.payload.map(({ url }, index) => (
              <Pressable key={index} onPress={() => onClickImage?.(url)}>
                <Image
                  source={{
                    uri: url,
                  }}
                  borderRadius={'4px'}
                  resizeMode="cover"
                  w="120px"
                  h="120px"
                  m={'10px'}
                  ml={0}
                />
              </Pressable>
            ))}
          </View>
        );
      case 'audio': {
        return update?.payload.map((item) => {
          return (
            <audio controls style={{ width: '100%', marginTop: '8px' }}>
              <source src={item?.url} type={`${item?.contentType}`} />
            </audio>
          );
        });
      }
      case 'video': {
        return update?.payload.map((item) => {
          return (
            <video
              controls
              style={{ width: 'auto', height: '300px', marginTop: '8px' }}
            >
              <source src={item?.url} type={`${item?.contentType}`} />
            </video>
          );
        });
      }
      case 'pdf': {
        return update?.payload.map((item, index) => {
          return (
            <Pressable
              key={index}
              onPress={() => onClickPdf?.(item.url)}
              marginTop={'2px'}
            >
              {({ isHovered }) => (
                <View
                  display={'flex'}
                  flexDir={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <Text
                    fontSize={14}
                    fontWeight={'400'}
                    color={isHovered ? 'gray.900' : 'gray.700'}
                    marginRight={'7px'}
                    textDecorationLine={'underline'}
                  >
                    {item?.pdfTitle}
                  </Text>

                  <ExpoEvilIcon
                    name={'link-external'}
                    size={'15px'}
                    color={isHovered ? 'gray.900' : 'gray.700'}
                  />
                </View>
              )}
            </Pressable>
          );
        });
      }
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickImage, onClickPayload, t, update]);

  return (
    <Box position={'relative'} mt={isFirst ? undefined : '18px'}>
      <ExpoFAIcon
        color={'primary.500'}
        position={'absolute'}
        top={'4px'}
        name="circle"
        size={'14px'}
      />
      <Text display={'inline'} ml={'24px'} opacity={update.isSending ? 0.5 : 1}>
        {moment(update.timestamp).format('D MMM YYYY, h:mm A')}
      </Text>
      <Box
        w={'100%'}
        position={'relative'}
        mt={'6px'}
        opacity={update.isSending ? 0.5 : 1}
      >
        <Box
          borderLeftColor={'gray.500'}
          borderLeftWidth={'1px'}
          height={'auto'}
          position={'absolute'}
          top={'-10px'}
          bottom={'-24px'}
          left={'1px'}
          w={'12px'}
          ml={'4px'}
        />
        <Box
          ml={'24px'}
          borderRadius={'6px'}
          bg={'white'}
          borderColor={'gray.300'}
          borderWidth={'1px'}
          pt={'8px'}
          pr={'12px'}
          pb={'12px'}
          pl={'8px'}
        >
          {update.type === 'form_link' &&
          update.payload?.[0]?.type === 'form_draft' ? (
            <View
              display={'flex'}
              flexDir={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              mb={'4px'}
            >
              <ExpoIonicIcon
                name="eye"
                color={'gray.700'}
                size={'16px'}
                mr={'4px'}
                mt={'-1px'}
              />
              <Text color={'gray.700'} fontSize={'12px'}>
                {t('Only you can see this')}
              </Text>
            </View>
          ) : null}
          <View
            display={'flex'}
            flexDir={'row'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            {updateIcon}
            <View flex={1} display={'inline-flex'} flexDir={'column'}>
              <Text fontFamily={'tableCell'}>
                <Text fontSize={'14px'} fontWeight={600}>
                  {update.name}
                </Text>{' '}
                {t(updateMessage, { ns: 'messages' })}
              </Text>
              {updateComponent}
            </View>
          </View>
        </Box>
      </Box>
    </Box>
  );
};
