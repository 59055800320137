import React from 'react';
import { View, Text, ScrollView } from '@know/ui';
import { StyleSheet } from 'react-native';
import { PDFFileAttachment } from './PDFFileAttachment';
import type { ITextVideoPDFView } from './types';
import './styles.css';
import VideoJS from './VideoJS';
import { StyleInnerHTML } from './StyleInnerHTML';

export const VideoTextPDF: React.FC<ITextVideoPDFView> = ({
  videoTextPdfObj,
  isVideoAutoPlayed,
  handleVideoConsumed,
  handlePDFConsumed,
}) => {
  const videoPayload = videoTextPdfObj?.payload?.video;
  const pdfPayload = videoTextPdfObj?.payload?.pdf;

  const styles = StyleSheet.create({
    // @ts-ignore -> For webview need to remove translateZ in transform
    scrollViewTransform: { transform: 'none' },
  });

  return (
    <ScrollView flex={1} style={styles.scrollViewTransform}>
      {videoPayload?.length > 0 && (
        <View bgColor={'black'}>
          <VideoJS
            src={videoPayload[0]?.url}
            type={videoPayload[0]?.type || 'video/mp4'}
            isVideoAutoPlayed={isVideoAutoPlayed}
            consumeTime={videoPayload[0]?.consumeTime}
            handleVideoConsumed={handleVideoConsumed}
          />
        </View>
      )}
      <View margin="15px">
        {pdfPayload?.length > 0 && (
          <PDFFileAttachment
            pdfPayload={pdfPayload}
            handlePDFConsumed={handlePDFConsumed}
          />
        )}
        <Text
          marginTop="10px"
          fontWeight="semibold"
          fontSize={['18px', '18px', '18px', '20px', '20px']}
        >
          {videoTextPdfObj?.title}
        </Text>

        <Text
          fontSize={['14px', '14px', '14px', '15px', '15px']}
          color="gray.700"
        >
          <StyleInnerHTML data={videoTextPdfObj?.notes} />
        </Text>
      </View>
    </ScrollView>
  );
};
