import React from 'react';
import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

export const FilterIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 14 13">
    <Path
      d="M5.00003 6.17801V12.5C5.00003 12.5853 5.02184 12.6691 5.06338 12.7436C5.10492 12.818 5.16481 12.8806 5.23736 12.9254C5.30991 12.9702 5.39271 12.9957 5.47789 12.9995C5.56307 13.0033 5.6478 12.9852 5.72403 12.947L8.72403 11.447C8.80698 11.4054 8.87672 11.3416 8.92547 11.2627C8.97421 11.1837 9.00003 11.0928 9.00003 11V6.17801L13.387 0.817014C13.4287 0.766192 13.4599 0.707669 13.4789 0.644785C13.4979 0.581901 13.5043 0.515889 13.4978 0.450516C13.4913 0.385144 13.472 0.321692 13.441 0.263783C13.41 0.205874 13.3679 0.154643 13.317 0.113014C13.2662 0.0713847 13.2077 0.0401735 13.1448 0.0211618C13.0819 0.00215018 13.0159 -0.00428946 12.9505 0.00221065C12.8852 0.00871075 12.8217 0.0280233 12.7638 0.0590456C12.7059 0.0900678 12.6547 0.132192 12.613 0.183014L8.11303 5.68301C8.03987 5.77245 7.99994 5.88446 8.00003 6.00001V10.691L6.00003 11.691V6.00001C6.00012 5.88446 5.96019 5.77245 5.88703 5.68301L2.05503 1.00001H10C10.1326 1.00001 10.2598 0.947335 10.3536 0.853567C10.4474 0.759799 10.5 0.632622 10.5 0.500014C10.5 0.367406 10.4474 0.240229 10.3536 0.146461C10.2598 0.0526924 10.1326 1.38572e-05 10 1.38572e-05H1.00003C0.905308 -4.93343e-05 0.812518 0.0267943 0.732461 0.0774193C0.652405 0.128044 0.588375 0.200368 0.547826 0.28597C0.507278 0.371573 0.491879 0.466933 0.503422 0.560947C0.514965 0.654962 0.552976 0.743763 0.613029 0.817014L5.00003 6.17801Z"
      fill="currentColor"
    />
  </Icon>
);
