import React from 'react';
import { Icon } from 'native-base';
import { Path, Circle, Defs, LinearGradient, Stop } from 'react-native-svg';

export const UploadFileIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 40 40">
    <Circle cx="20" cy="20" r="20" fill="#00C1B2" />
    <Circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.8" />
    <Path
      d="M19.8839 15.1558L20.0279 15.2902H20.225H29.2C29.3781 15.2902 29.5 15.4267 29.5 15.5707V27.2195C29.5 27.3635 29.3781 27.5 29.2 27.5H10.8C10.6219 27.5 10.5 27.3635 10.5 27.2195V12.7805C10.5 12.6365 10.6219 12.5 10.8 12.5H17.0379L19.8839 15.1558Z"
      fill="url(#paint0_linear_41_899)"
    />
    <Path
      d="M19.8839 15.1558L20.0279 15.2902H20.225H29.2C29.3781 15.2902 29.5 15.4267 29.5 15.5707V27.2195C29.5 27.3635 29.3781 27.5 29.2 27.5H10.8C10.6219 27.5 10.5 27.3635 10.5 27.2195V12.7805C10.5 12.6365 10.6219 12.5 10.8 12.5H17.0379L19.8839 15.1558Z"
      stroke="#00C1B2"
      fill={'none'}
    />
    <Path
      d="M19.8839 15.1558L20.0279 15.2902H20.225H29.2C29.3781 15.2902 29.5 15.4267 29.5 15.5707V27.2195C29.5 27.3635 29.3781 27.5 29.2 27.5H10.8C10.6219 27.5 10.5 27.3635 10.5 27.2195V12.7805C10.5 12.6365 10.6219 12.5 10.8 12.5H17.0379L19.8839 15.1558Z"
      stroke="black"
      strokeOpacity="0.2"
      fill={'none'}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_41_899"
        x1="20"
        y1="12"
        x2="20"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#5AE4DA" />
        <Stop offset="1" stopColor="#21D7C9" />
      </LinearGradient>
    </Defs>
  </Icon>
);
