import React from 'react';
import { KNOWButton as Button, Divider, Modal, View } from '@know/ui';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { OrgIssueLocationsInterface } from '@know/ui';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

interface LocationDateFilterProps {
  isOpen: boolean;
  issueLocations: OrgIssueLocationsInterface;
  locationFilterValue: string | null;
  dateRangeFilterValue: [Date | null, Date | null];
  cancel: () => void;
  onChangeLocationFilter?: (location: string) => void;
  onChangeDateFilter?: (dateRange: [Date | null, Date | null]) => void;
}

export const LocationDateFilter = ({
  isOpen,
  dateRangeFilterValue,
  locationFilterValue,
  issueLocations,
  cancel,
  onChangeLocationFilter = () => {},
  onChangeDateFilter = () => {},
}: LocationDateFilterProps) => {
  const locationOptions = Object.values(issueLocations).map(
    (location) => location.name
  );

  const handleLocationChange = (_event: any, newValue: any) => {
    onChangeLocationFilter(newValue);
  };

  console.log(dateRangeFilterValue, 'date reange');

  const resetFilters = () => {
    onChangeLocationFilter('');
    onChangeDateFilter([null, null]);
    cancel();
  };

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={cancel} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
          {'Filters'}
        </Modal.Header>
        <Divider />
        <Modal.Body py={0}>
          <View width="100%" mt={'16px'}>
            <InputLabel id="issue-location" style={{ marginBottom: '6px' }}>
              {'Location'}
            </InputLabel>
            <Autocomplete
              id="issue-location"
              options={locationOptions}
              value={locationFilterValue}
              onChange={handleLocationChange}
              renderInput={(params) => (
                <TextField {...params} label="All Locations" />
              )}
            />
            <InputLabel
              id="issue-location"
              style={{ marginBottom: '6px', marginTop: '16px' }}
            >
              {'Date'}
            </InputLabel>
          </View>
        </Modal.Body>
        <Modal.Footer
          borderTopWidth={0}
          display={'flex'}
          flexDirection={'column'}
        >
          <Button colorScheme="primary" onPress={cancel} mb={'10px'}>
            {'Apply filter'}
          </Button>
          <Button colorScheme="secondary" onPress={resetFilters} mb={'10px'}>
            {'Reset All'}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
