import { PathConfigMap } from '@react-navigation/native';

type LinkProps = PathConfigMap<ReactNavigation.RootParamList>;

export function transformLinkProps(
  linkProps: LinkProps,
  servingFrom: string
): LinkProps {
  function prependPath(obj: any, prefix: string): void {
    if (obj.path) {
      obj.path = prefix + '/' + obj.path;
    }
    if (obj.screens) {
      Object.values(obj.screens).forEach((screen) =>
        prependPath(screen, prefix)
      );
    }
  }
  if (servingFrom) {
    const linkPropsModified = JSON.parse(JSON.stringify(linkProps));
    prependPath(linkPropsModified, servingFrom);
    return linkPropsModified;
  }
  return linkProps;
}
