import { useEffect, useState } from 'react';
import {
  UserDetailsInterface,
  OrgConfigInterface,
  useToast,
  FirebaseAPI,
} from '@know/ui';
import moment from 'moment';
import { keys } from 'lodash';
import md5 from 'md5';

const QR_CODE_REFRESH_INTERVAL = 30;
const BUFFER_EXPIRE_TIME = 10 * 1000;

export const useAttendanceQRValue = (
  userDetails: UserDetailsInterface | null,
  orgConfig: OrgConfigInterface | null
) => {
  const [qrValue, setQRValue] = useState<string>('');
  const [rawStringComponent, setRawStringComponent] = useState<string>('');
  const [remainingSecond, setRemainingSecond] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const [currentUserLocationId, setCurrentUserLocationId] =
    useState<string>('');
  const [currentSecretKey, setCurrentSecretKey] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    if (userDetails && orgConfig && !isError) {
      const userLocation = userDetails.location;
      const secretKey = orgConfig.attendanceQRSecretKey;
      const locations = orgConfig.locations;
      const userLocationId =
        keys(locations).find(
          (locationId) => locations[locationId].name === userLocation
        ) || '';
      setCurrentUserLocationId(userLocationId);
      setCurrentSecretKey(secretKey);
    }
  }, [isError, orgConfig, userDetails]);

  useEffect(() => {
    if (currentUserLocationId && currentSecretKey && !isError) {
      const timeout = setInterval(() => {
        const currentMoment = moment();
        const currentSecond =
          Number(currentMoment.format('ss')) % QR_CODE_REFRESH_INTERVAL;

        if (
          currentSecond === 0 ||
          !qrValue ||
          rawStringComponent !== `${currentUserLocationId}-${currentSecretKey}`
        ) {
          if (currentUserLocationId && currentUserLocationId) {
            const nextTimeStamp = +moment(currentMoment).add(
              QR_CODE_REFRESH_INTERVAL - currentSecond + BUFFER_EXPIRE_TIME,
              'second'
            );

            const value = `${currentUserLocationId}-${nextTimeStamp}-${currentSecretKey}`;
            const hashString = md5(value);
            setRawStringComponent(
              `${currentUserLocationId}-${currentSecretKey}`
            );
            setQRValue(
              FirebaseAPI.encodeBase64(
                `${currentUserLocationId}.${nextTimeStamp}.${hashString}`
              )
            );
          } else {
            setIsError(true);
            return toast.show({
              title:
                'Attendance QR is not configured properly. Please contact KNOW Support.',
              placement: 'bottom-left',
              ml: '20px',
            });
          }
        }
        setRemainingSecond(QR_CODE_REFRESH_INTERVAL - currentSecond);
      }, 1000);

      return () => clearInterval(timeout);
    }

    return;
  }, [
    currentUserLocationId,
    qrValue,
    currentSecretKey,
    toast,
    isError,
    rawStringComponent,
  ]);

  return {
    qrValue,
    remainingSecond,
    isError,
  };
};
