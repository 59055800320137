import React from 'react';
import { View } from '@know/ui';

import type { IFullScreenVideoView } from './types';
import './styles.css';
import VideoJS from './VideoJS';

export const FullScreenVideo: React.FC<IFullScreenVideoView> = ({
  fullScreenVideoObj,
  isVideoAutoPlayed,
  handleVideoConsumed,
}) => {
  const videoPayload = fullScreenVideoObj?.payload?.video;
  return (
    <View flex={1} bgColor="black" alignItems="center" justifyContent="center">
      <View bgColor={'black'} width="100%" height="100%">
        <VideoJS
          src={videoPayload[0]?.url}
          isVideoAutoPlayed={isVideoAutoPlayed}
          consumeTime={videoPayload[0]?.consumeTime}
          type={videoPayload[0]?.type || 'video/mp4'}
          handleVideoConsumed={handleVideoConsumed}
          isFullScreenVideo={true}
        />
      </View>
    </View>
  );
};
