import {
  CheckForLocationPermissionSource,
  ICheckForLocationPermission,
  NuggetClassificationType,
} from "./types";

export const getLocation = () =>
  new Promise<{ lat: number; lng: number }>((res, rej) => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (result) => {
            res({
              lat: result.coords.latitude,
              lng: result.coords.longitude,
            });
          },
          (err) => {
            console.log(err);
            if (err.code === 1) {
              rej(new Error("LocationNotAllowed"));
            }
            rej(err);
          }
        );
      } else {
        throw new Error("LocationNotAvailable");
      }
    } catch (err) {
      console.log(err);
      rej(err);
    }
  });

export const checkForLocationPermission = async (
  props?: ICheckForLocationPermission
): Promise<[PermissionState, () => void]> => {
  const { onGranted, onPrompt, onDenied } = props ?? {};
  const permissionResultHandler = (
    result: PermissionStatus,
    source: CheckForLocationPermissionSource = "initial"
  ) => {
    if (result.state === "granted") {
      onGranted && onGranted(source);
    } else if (result.state === "prompt") {
      onPrompt && onPrompt(source);
    } else if (result.state === "denied") {
      onDenied && onDenied(source);
    }

    return result.state;
  };
  const handleChange = function (this: PermissionStatus) {
    permissionResultHandler(this, "onchange");
  };
  const permissionResult = await navigator.permissions.query({
    name: "geolocation",
  });
  const currentState = permissionResultHandler(permissionResult);

  permissionResult.addEventListener("change", handleChange);

  return [
    currentState,
    () => permissionResult.removeEventListener("change", handleChange),
  ];
};

export const getTimezoneIdentifier = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getUtcOffsetSec = () => new Date().getTimezoneOffset() * -60;

export const getGamificationNuggetTypes = (
  classificationType: NuggetClassificationType
) => {
  switch (classificationType) {
    case "form":
      return { type: "forms", subType: "forms" };
    default:
      return null;
  }
};
