import { useMemo } from 'react';
import type { IShareGroup } from '../ShareDialogue/types';

export function useGroup(group: IShareGroup) {
  const avatarText = useMemo(() => {
    return group.name
      .split(/\s+/)
      .map((part) => part.split('').splice(0, 1)[0])
      .splice(0, 2)
      .join('');
  }, [group.name]);

  return {
    id: group.id,
    name: group.name,
    avatarText,
    noOfUsers: group.members,
    avatar: group.avatar,
  };
}
