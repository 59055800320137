import React from 'react';
import { Box, Text } from '.';

const textStatusColors: any = {
  Pending: '#EE8F00',
  pending: '#EE8F00',
  Returned: 'red.400',
  returned: 'red.400',
  Rejected: 'red.400',
  rejected: 'red.400',
  Approved: 'green.400',
  approved: 'green.400',
  submitted: 'green.400',
  Submitted: 'teal.500',
  suspended: 'red.400',
  Suspended: 'red.400',
};

const boxBGColors: any = {
  Pending: 'rgba(255, 181, 70, 0.2)',
  Returned: 'rgba(249, 87, 87, 0.15)',
  Rejected: 'rgba(249, 87, 87, 0.15)',
  Approved: 'rgba(14, 191, 117, 0.15)',
  pending: 'rgba(255, 181, 70, 0.2)',
  returned: 'rgba(249, 87, 87, 0.15)',
  rejected: 'rgba(249, 87, 87, 0.15)',
  suspended: 'rgba(249, 87, 87, 0.15)',
  Suspended: 'rgba(249, 87, 87, 0.15)',
  approved: 'rgba(14, 191, 117, 0.15)',
  submitted: 'rgba(14, 191, 117, 0.15)',
  Submitted: 'rgba(0, 193, 178, 0.15)',
};

export function ResponseStatus({ status }: { status: string }) {
  return (
    <Box
      w={'90px'}
      py={1}
      borderRadius={30}
      bg={boxBGColors[status]}
      alignItems={'center'}
    >
      <Text
        textTransform={'capitalize'}
        fontFamily="openSemiBold"
        color={textStatusColors[status]}
        fontWeight={600}
        fontSize={'14px'}
      >
        {status}
      </Text>
    </Box>
  );
}
