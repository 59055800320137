import React from 'react';
import {
  Box,
  NoDataForms,
  Table,
  Text,
  WithKNOWLoader,
  FlatListWithFilter,
  TaskListRow,
} from '@know/ui';
import { useCompletedTask } from './useCompletedTask';

export const CompletedTasksPage = () => {
  const {
    isDataLoading,
    taskItems,
    onFilterTextChange,
    filterText,
    initialState,
    columns,
    onClick,
    isSmallScreen,
  } = useCompletedTask();

  return (
    <WithKNOWLoader
      isLoading={isDataLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      {isSmallScreen ? (
        <Box h={'100%'} mt={'12px'}>
          <FlatListWithFilter
            keyExtractor={(item) => `${item.taskId}`}
            data={taskItems}
            hasFilterText={!!filterText}
            filterText={filterText ?? ''}
            renderItem={({ item }) => (
              <TaskListRow item={item} onPress={onClick} />
            )}
            onFilterChange={onFilterTextChange}
            NoDataComponent={
              <NoDataForms
                text="Looks like you have not completed any tasks yet."
                icon="tasks"
              />
            }
            NoResultsFoundComponent={
              <Box
                my={'24px'}
                w={'100%'}
                display={'flex'}
                flexDir={'row'}
                justifyContent={'center'}
              >
                <Text textAlign={'center'}>No results found.</Text>
              </Box>
            }
          />
        </Box>
      ) : (
        <Table
          columns={columns}
          data={taskItems}
          onRowClick={onClick}
          filter={filterText ?? ''}
          initialState={initialState}
          enableGlobalFilter={true}
          onChangeGlobalFilter={onFilterTextChange}
          isSearching={!!filterText}
          NoDataComponent={
            <NoDataForms
              text="Looks like you have not completed any tasks yet."
              icon="tasks"
            />
          }
          NoResultsFoundComponent={
            <Box
              my={'24px'}
              w={'100%'}
              display={'flex'}
              flexDir={'row'}
              justifyContent={'center'}
            >
              <Text textAlign={'center'}>No results found.</Text>
            </Box>
          }
          unreadRowStatus={true}
        />
      )}
    </WithKNOWLoader>
  );
};
