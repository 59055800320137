import { KNOWDBContext, useNavigation, useRecoilValue } from '@know/ui';
import { receivedFormSelector } from '../store';
import { useContext, useEffect, useState } from 'react';
import {
  ISectionDataProps,
  IReceivedFormsItem,
  IReceivedResponseItem,
  prepareUserReceivedFormsListData,
} from '@know/transformers';
import {
  cleanUpSubscribedReceivedForms,
  getUserReceivedResponses,
} from '@know/db';
const EMPTY_FUNCTION = () => {};

export function useFormReceivedItems(formId: string) {
  const navigation = useNavigation();
  const { currentDBApi } = useContext(KNOWDBContext);

  const [isLoading, setIsLoading] = useState(true);

  const receivedForm = useRecoilValue(receivedFormSelector(formId));
  const [receivedFormItems, setReceivedFormItems] = useState<
    ISectionDataProps<IReceivedResponseItem>[]
  >([]);

  function onToggleSection(id: string) {
    console.log(`${id} section is toggled`);
  }

  useEffect(() => {
    if (currentDBApi && receivedForm) {
      getUserReceivedResponses(currentDBApi, formId, false, (data) => {
        const userReceivedForm: IReceivedFormsItem = {
          ...receivedForm,
          id: formId,
          hasMoreData: false,
          data,
        } as unknown as IReceivedFormsItem;

        const userReceivedForms = {
          [formId]: userReceivedForm,
        };
        setReceivedFormItems(
          prepareUserReceivedFormsListData({
            userReceivedForms,
            filterText: '',
          }).map((a) => ({ ...a, disableToggle: true }))
        );
        setTimeout(() => setIsLoading(false), 1000);
      });

      return cleanUpSubscribedReceivedForms;
    }
    return EMPTY_FUNCTION;
  }, [formId, currentDBApi, receivedForm, setReceivedFormItems]);

  function onClickItem(
    item: IReceivedResponseItem,
    _type: string,
    section?: any
  ) {
    navigation.navigate(
      'Forms' as never,
      {
        screen: 'webview',
        params: {
          formId: section.id,
          responseId: item.id,
          initiatorId: item.userId,
          type: 'received',
        },
      } as never
    );
  }

  return {
    title: receivedForm.name,
    folderFormSectionItems: [],
    onToggleSection,
    onClickItem,
    receivedFormItems,
    isLoading,
  };
}
