import React from 'react';
import {
  ConfirmDeleteFormDraftModal,
  SectionsListViewWithFilter,
  View,
  WithKNOWLoader,
} from '@know/ui';
import { useUserDrafts } from './useUserDrafts';
import {
  ItemComponent,
  keyExtractor,
  getSectionHeaderComponent,
} from './renderers';

export function DraftsFormsPage() {
  const {
    isDataLoading,
    userDrafts,
    isConfirmDeleteModalOpen,
    confirmDeleteModalProps,
    isDeleting,
    filterText,
    isSmallScreen,
    closeConfirmDeleteModalProps,
    onToggleSection,
    onClickItem,
    onFilterChange,
    onClickDeleteItem,
  } = useUserDrafts();

  return (
    <WithKNOWLoader
      isLoading={isDataLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <View px={'16px'} h={'100%'} mt={isSmallScreen ? '12px' : 0}>
        <SectionsListViewWithFilter
          onFilterChange={onFilterChange}
          // selectable
          data={userDrafts}
          onToggleSection={onToggleSection}
          onClickItem={onClickItem}
          keyExtractor={keyExtractor}
          getSectionHeaderComponent={getSectionHeaderComponent}
          ItemComponent={ItemComponent}
          onClickDelete={onClickDeleteItem}
          hasFilterText={!!filterText}
        />
      </View>
      {isConfirmDeleteModalOpen ? (
        <ConfirmDeleteFormDraftModal
          isOpen={isConfirmDeleteModalOpen}
          close={closeConfirmDeleteModalProps}
          isLoading={isDeleting}
          {...confirmDeleteModalProps}
        />
      ) : null}
    </WithKNOWLoader>
  );
}
