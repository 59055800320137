import React from 'react';
// import { QRCode as ReactQRCode } from 'qrcode.react';
import QRCode from 'qrcode.react';
import images from '../Images';

interface KNOWQRCodeProps {
  value: string;
  size?: number;
  color?: string;
  bgColor?: string;
  withLogo?: boolean;
  logoSrc?: string;
}

export const KNOWQRCode = ({
  value,
  size = 128,
  color,
  withLogo,
  logoSrc,
  bgColor = '#fff',
}: KNOWQRCodeProps) => {
  return (
    <QRCode
      value={value}
      size={size}
      fgColor={color || '#00c1b2'}
      renderAs={'svg'}
      bgColor={bgColor}
      imageSettings={
        withLogo
          ? {
              src: logoSrc || images.logoGreen,
              height: Math.ceil(size / 5.5),
              width: Math.ceil(size / 5.5),
              excavate: true,
            }
          : undefined
      }
    />
  );
};
