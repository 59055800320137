import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Button, PresenceTransition } from '@know/ui';
import type { ISurveyQuestion } from '../types';
import { StyleSheet } from 'react-native';

const INIT_DIMENSIONS = {
  width: 0,
  height: 0,
};
interface Iprops {
  index: string;
  data: ISurveyQuestion;
  answer: any;
}

export const RatingScale = ({
  data,
  handleProceedButton,
  setResponse,
  sectionId,
}: {
  data: Iprops;
  handleProceedButton: (questionIndex?: string) => void;
  setResponse: (value: any) => void;
  sectionId: string | null;
}) => {
  const [selectedRating, setSelectedRating] = useState<{
    index: number | null;
    item: number | null;
  }>({
    index: null,
    item: null,
  });
  const [parentDivInfo, setParentDivInfo] = useState(INIT_DIMENSIONS);
  const [cellWidth, setCellWidth] = useState(0);
  const ratingScaleLength = () => {
    if (data.data.rating?.minRating === 0) {
      return data.data.rating?.maxRating - data.data.rating?.minRating + 1;
    } else {
      return (
        data.data.rating?.maxRating ?? 0 - (data.data.rating?.minRating ?? 0)
      );
    }
  };

  useEffect(() => {
    if (parentDivInfo.height) {
      const widthP =
        +parentDivInfo.width /
        (data.data.rating?.minRating === 0
          ? data.data.rating?.maxRating! - data.data.rating?.minRating! + 1
          : data.data.rating?.maxRating!);
      setCellWidth(widthP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parentDivInfo.width,
    data.data.rating?.maxRating,
    data.data.rating?.minRating,
  ]);

  return (
    <View
      margin="15px"
      flex="1"
      // onLayout={(event: any) => {
      //   let { width, height } = event.nativeEvent.layout;

      //   setParentDivInfo({ width, height });
      // }}
    >
      <Text fontWeight="semibold" fontSize="18px" marginBottom="12px">
        {data.data.text}
      </Text>
      <View
        onLayout={(event: any) => {
          let { width, height } = event.nativeEvent.layout;
          setParentDivInfo({ width, height });
        }}
        flexDirection={'row'}
        alignItems="center"
        justifyContent={'center'}
      >
        {[...Array(ratingScaleLength()).keys()].map((item, index) => {
          return (
            <Pressable
              onPress={() => {
                setSelectedRating({
                  index,
                  item,
                });
              }}
              zIndex={item !== selectedRating.item ? -1 : 0}
              flex={1}
              h="50px"
            >
              <View
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
                w="100%"
                h="100%"
              >
                <PresenceTransition
                  visible={item === selectedRating.item}
                  initial={{
                    opacity: 1,
                    scale: 1,
                  }}
                  animate={{
                    opacity: 1,
                    scale: ratingScaleLength() > 4 ? 1.1 : 1.05,
                    transition: {
                      duration: 250,
                    },
                  }}
                  style={styles.fullSize}
                >
                  <View
                    w={cellWidth}
                    h="50px"
                    borderRightWidth="1px"
                    borderLeftWidth={index === 0 ? '1px' : 0}
                    borderBottomWidth="1px"
                    borderTopWidth="1px"
                    alignSelf="center"
                    alignItems="center"
                    justifyContent="center"
                    borderColor={
                      item === selectedRating.item ? 'transparent' : 'gray.300'
                    }
                    zIndex={item === selectedRating.item ? 2 : -1}
                    borderLeftRadius={
                      (data.data.rating?.minRating! === 0 ||
                        data.data.rating?.minRating! === 1) &&
                      item === 0
                        ? '6px'
                        : item === selectedRating.item
                        ? '6px'
                        : 0
                    }
                    borderRightRadius={
                      data.data.rating?.minRating! === 0 &&
                      item === data.data.rating?.maxRating!
                        ? '6px'
                        : data.data.rating?.minRating! > 0 &&
                          item === data.data.rating?.maxRating! - 1
                        ? '6px'
                        : item === selectedRating.item
                        ? '6px'
                        : 0
                    }
                    borderRadius={item === selectedRating.item ? '6px' : 0}
                    bgColor={
                      item === selectedRating.item
                        ? 'primary.500'
                        : 'transparent'
                    }
                  >
                    <Text
                      color={
                        selectedRating.item === item ? 'white' : 'gray.500'
                      }
                      fontSize="16px"
                    >
                      {data.data.rating?.minRating === 0 ? item : item + 1}
                    </Text>
                  </View>
                </PresenceTransition>
              </View>
            </Pressable>
          );
        })}
      </View>
      {data.data.rating && (
        <View
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="10px"
        >
          <Text fontSize="16px" color="primary.500" fontWeight={600}>
            {data.data?.rating?.labelMinRating}
          </Text>
          <Text fontSize="16px" color="primary.500" fontWeight={600}>
            {data.data?.rating?.labelMaxRating}
          </Text>
        </View>
      )}
      <Button
        marginTop="16px"
        size={'sm'}
        onPress={() => {
          setResponse((prevState: any) => {
            const state = [...prevState];
            state.push({
              question: data.index,
              response:
                data?.data?.rating?.minRating === 0
                  ? selectedRating.item?.toString()
                  : (selectedRating.item! + 1).toString(),
              sectionID: sectionId,
            });
            return state;
          });
          handleProceedButton(selectedRating.index?.toString());
          setSelectedRating({
            index: null,
            item: null,
          });
        }}
        disabled={!selectedRating.item?.toString()}
        opacity={!selectedRating.item?.toString() ? 0.3 : 1}
      >
        <Text fontWeight={'semibold'} color={'white'}>
          Proceed
        </Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  fullSize: {
    width: '100%',
    height: '100%',
  },
});
