import type { FirebaseAPI } from "../FirebaseAPI"

async function getGroupDetails(dbAPI: FirebaseAPI, groupId: string) {
  const nodeRef = dbAPI.getMultiPathCommon(`groups/${groupId}`);
  const group = await dbAPI.getValue(nodeRef);
  if (group) {
    return {
      ...group,
      id: groupId,
    };
  }
  return group;
}

async function getAllActiveGroups(dbAPI: FirebaseAPI) {
  const nodeRef = dbAPI.getMultiPathCommon('groups')
  const groups: {[groupId: string]: any} = await dbAPI.getValue(nodeRef) || {}
  if (groups) {
    return Object.keys(groups)
      .filter(groupId => groups[groupId].members)
      .map(groupId => {
        return {
          ...(groups?.[groupId] ?? {}),
          id: groupId
        }
      })
  }
  return groups;
}

export async function getUserGroups(dbAPI: FirebaseAPI, userDetails: any) {
  let groups: any[] = []
  if (userDetails.isSuperAdmin) {
    groups = await getAllActiveGroups(dbAPI);
  } else {
    const aclGroupIds: string[] = userDetails?.adminGroupAcl ?? [];
    groups = await Promise.all(
      aclGroupIds
        .map(groupId => getGroupDetails(dbAPI, groupId))
    );
  }

  return groups
    .filter(group => group && !group.archived);
}
