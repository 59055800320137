import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { checkForFileValidity } from './utils';
import { ErrorAlert, useToast } from '@know/ui';

export interface UploadFileHandlerRef {
  onClick: () => void;
}

interface UploadFileHandlerProps {
  limit?: number;
  sizeLimit?: number;
  onSubmit?: (response: File[]) => void;
  accept?: string;
  acceptName?: string;
}

export const UploadFileHandler = forwardRef<
  UploadFileHandlerRef,
  UploadFileHandlerProps
>(
  (
    { limit = 1, sizeLimit = 25 * 1024 * 1024, onSubmit, accept, acceptName },
    ref
  ) => {
    const uploadFileInput = useRef<HTMLInputElement | null>(null);

    const toast = useToast();

    const onError = (type: 'size' | 'number' | 'type') => {
      const toastId = Date.now();
      const header =
        type === 'size'
          ? 'File(s) exceed size limit'
          : type === 'type'
          ? 'Invalid file type'
          : 'Too many files uploaded';
      const subText =
        type === 'size'
          ? `Max file size is ${
              sizeLimit / 1024 ** 2
            } MB. Please try again with a smaller file.`
          : type === 'type'
          ? `Only ${
              acceptName ?? accept
            } are allowed. Please select a valid file type to upload.`
          : `You can only upload up to ${limit} files. Please try again with fewer files.`;
      toast.show({
        id: toastId,
        render: () => (
          <ErrorAlert
            header={header}
            subText={subText}
            onClose={() => toast.close(toastId)}
            containerProps={{ mt: '-30px', w: 'calc(100vw - 24px)' }}
          />
        ),
        placement: 'top',
        duration: null,
      });
    };

    useImperativeHandle(ref, () => {
      return {
        onClick() {
          uploadFileInput.current?.click();
        },
      };
    });

    const onUploadInputChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const _files = e.target.files;
      if (_files) {
        const files = Array.from(e.target.files ?? []);
        if (files.length > limit) {
          return onError('number');
        }
        try {
          // const base64Files = await Promise.all(
          //   files.map((f) => readAsBase64(f, sizeLimit))
          // );
          checkForFileValidity(
            files,
            accept ? accept.split(',') : ['*'],
            sizeLimit
          );
          onSubmit && onSubmit(files);
        } catch (error: any) {
          if (error.message === 'FileTooBig') {
            return onError('size');
          }
          if (error.message === 'InvalidType') {
            return onError('type');
          }
        }
      }
    };

    return (
      <input
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ display: 'none' }}
        ref={uploadFileInput}
        accept={accept ?? '*'}
        type="file"
        multiple={limit > 1}
        onChange={onUploadInputChange}
      />
    );
  }
);
