//TODO: make a native app friendly version
import React from 'react';
import {
  KNOWButton as Button,
  Modal,
  // ShareIcon,
  Text,
  View,
} from '@know/ui';

interface SuccessModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  close?: () => void;
  onConfirm?: () => void | Promise<void>;
}

export const ConfirmDeleteFormDraftModal = ({
  isOpen,
  isLoading,
  close,
  onConfirm,
}: SuccessModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={!isLoading}
      onClose={close}
      size="xl"
    >
      <Modal.Content>
        {isLoading ? null : <Modal.CloseButton />}
        <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
          Delete Draft Form
        </Modal.Header>
        <Modal.Body py={0}>
          <View width="100%">
            <Text fontSize={'16px'} color={'gray.700'}>
              Once you delete, the action cannot be undone.
            </Text>
          </View>
        </Modal.Body>
        <Modal.Footer
          borderTopWidth={0}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
        >
          <Button
            colorScheme="secondary"
            onPress={close}
            mr={'12px'}
            _text={{
              color: 'gray.900',
              fontWeight: 600,
            }}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            colorScheme="dangerFilled"
            onPress={onConfirm}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
