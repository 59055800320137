import type { FirebaseAPI } from "./FirebaseAPI";

const LMSAnalyticRequestsPath = `/LMSAnalyticRequests`;
const LMSAnalyticRequestsInvalidPath = `/LMSAnalyticRequestsInvalid`;

const parent = globalThis.parent as any;

export async function raiseLMSAnalytics({
  type,
  fbAPI,
  payload,
}: {
  type: string;
  fbAPI: FirebaseAPI;
  payload: any;
}) {
  payload.journeyId = payload.journeyId || null;
  const path = `${LMSAnalyticRequestsPath}/${fbAPI.getPushKey()}`;
  if (
    process?.env?.NODE_ENV === "development" ||
    (globalThis as any).enableDebug
  ) {
    console.group("raiseLMSAnalytics");
    console.debug(type, path, payload);
    console.groupEnd();
  }
  try {
    let calls: any = [];
    if (parent?.knowInterfaceCallBack) {
      calls = [
        Promise.resolve(
          parent?.knowInterfaceCallBack({
            type,
            payload,
          })
        ),
      ];
    }
    if ((window as any).ReactNativeWebView?.postMessage) {
      calls = [
        ...calls,
        Promise.resolve(
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              action: type,
              payload,
            })
          )
        ),
      ];
    }
    calls.push(fbAPI.fbSet(path, payload));
    await Promise.all(calls);
  } catch (err: any) {
    const pathInvalid = `${LMSAnalyticRequestsInvalidPath}/${fbAPI.getPushKey()}`;
    const payloadPrepared = JSON.parse(JSON.stringify(payload));
    await fbAPI.fbSet(pathInvalid, payloadPrepared);
    console.log(err, err.stack);
  }
}
