import React from 'react';
import {
  Box,
  NoDataForms,
  Table,
  Text,
  WithKNOWLoader,
  // FlatListWithFilter,
  // TaskListRow,
} from '@know/ui';
import { useOpenIssues } from './useOpenIssuesFeed';

const OpenIssuesFeed = () => {
  const {
    isDataLoading,
    issueItems,
    orgIssueLocations,
    filterDateRange,
    filterLocation,
    onFilterTextChange,
    filterText,
    initialState,
    columns,
    onClick,
    onFilterDateRangeChange,
    onFilterLocationChange,
    // isSmallScreen,
  } = useOpenIssues();
  return (
    <WithKNOWLoader
      isLoading={isDataLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <Table
        columns={columns}
        data={issueItems}
        onRowClick={onClick}
        filter={filterText ?? ''}
        initialState={initialState}
        enableGlobalFilter={true}
        onChangeGlobalFilter={onFilterTextChange}
        isSearching={!!filterText}
        locations={orgIssueLocations}
        hasDateRangeLocationFilter={true}
        dateRangeFilter={filterDateRange}
        locationFilter={filterLocation ?? ''}
        onChangeLocationFilter={onFilterLocationChange}
        onChangeDateFilter={onFilterDateRangeChange}
        NoDataComponent={
          <NoDataForms
            text="Looks like you currently don't have any open issues."
            icon="issues"
          />
        }
        NoResultsFoundComponent={
          <Box
            my={'24px'}
            w={'100%'}
            display={'flex'}
            flexDir={'row'}
            justifyContent={'center'}
          >
            <Text textAlign={'center'}>No results found.</Text>
          </Box>
        }
        unreadRowStatus={true}
      />
    </WithKNOWLoader>
  );
};

export default OpenIssuesFeed;
