import React, { useMemo } from 'react';
// import { DrawerItem } from '@react-navigation/drawer';
import type { ICustomDrawerItemProps } from './types';
import { StyleSheet } from 'react-native';
import { Box, Pressable, Text } from 'native-base';

export const CustomDrawerItem = ({
  label,
  isActive,
  isSmallScreen,
  containerStyle,
  onPress,
  badge,
  isLastRoute,
}: ICustomDrawerItemProps) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      drawerItemLabel: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 14,
        color: '#4E5656',
        fontWeight: '400',
      },
      activeDrawerItemLabel: {
        color: '#00C1B2',
        fontWeight: '700',
      },
      drawerItemContainer: {
        marginBottom: 0,
        marginTop: 0,
        marginHorizontal: isSmallScreen ? 0 : undefined,
        borderRadius: 0,
        fontFamily: 'Inter',
        // alignItems: isSmallScreen ? 'center' : undefined,
        ...(containerStyle ?? {}),
      },
      activeItem: {
        borderLeftColor: isSmallScreen ? undefined : '#00C1B2',
        borderLeftWidth: isSmallScreen ? undefined : 2,
        borderBottomColor: isSmallScreen ? '#00C1B2' : undefined,
        borderBottomWidth: isSmallScreen ? 2 : undefined,
        fontWeight: 'bolder',
      },
      inActiveItem: {
        borderLeftWidth: isSmallScreen ? undefined : StyleSheet.hairlineWidth,
        borderColor: '#E5E9E8',
        borderBottomWidth: isSmallScreen ? StyleSheet.hairlineWidth : undefined,
      },
    });
  }, [containerStyle, isSmallScreen]);

  return (
    <>
      <Box
        style={[
          styles.drawerItemContainer,
          isActive ? styles.activeItem : styles.inActiveItem,
        ]}
        pl={isSmallScreen ? undefined : '10px'}
        pb={!isSmallScreen ? undefined : '10px'}
      >
        <Pressable onPress={onPress} flex={1}>
          {({ isHovered, isPressed }) => (
            <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
              <Text
                fontWeight={isActive ? 700 : 400}
                fontFamily={'body'}
                color={
                  isPressed
                    ? 'gray.500'
                    : isHovered
                    ? 'primary.500'
                    : isActive
                    ? 'primary.500'
                    : 'gray.700'
                }
              >
                {label}
              </Text>

              {badge ? (
                <Box
                  px={'10px'}
                  bg={'red.400'}
                  borderRadius={'16px'}
                  ml={'8px'}
                >
                  <Text
                    color={'white'}
                    fontFamily={'OpenSans'}
                    fontWeight={700}
                  >
                    {badge}
                  </Text>
                </Box>
              ) : null}
            </Box>
          )}
        </Pressable>
      </Box>
      {!isLastRoute ? (
        <Box
          h={isSmallScreen ? 0 : '24px'}
          w={isSmallScreen ? '24px' : 0}
          borderColor={'gray.250'}
          borderLeftWidth={!isSmallScreen ? '1px' : 0}
          borderBottomWidth={isSmallScreen ? '1px' : 0}
          mt={isSmallScreen ? 'auto' : 0}
          // mb={isSmallScreen ? '1px' : 0}
        />
      ) : null}
    </>
    // <DrawerItem
    //   label={label}
    //   onPress={onPress}
    //   style={[
    //     styles.drawerItemContainer,
    //     isActive ? styles.activeItem : styles.inActiveItem,
    //   ]}
    // />
  );
};
