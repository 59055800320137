import React, { useRef } from 'react';
import {
  subscribeToTaskUpdates,
  listenToTaskNugget,
  TaskUpdateType,
  startTask,
  completeTask,
  reopenTask,
  addTaskProgressRemark,
  addTaskStatusProgressAttachment,
  getTaskUsers as getTaskUsersFromDB,
  raiseStatusChangeRequest,
  UploadFileResultType,
} from '@know/db';
import {
  TaskNugget,
  getTaskStatus,
  prepareUsersDetails,
} from '@know/transformers';
import {
  useDebounceLoading,
  useHideHeader,
  useKNOWDBContext,
  useKNOWValueContextProvider,
  useNavigation,
  useKNOWToast,
  useDebounceSetData,
  TaskStatusOnClickPayloadType,
  UploadProgressType,
  EllipsisMenuOption,
  ExpoFAIcon,
  UploadImageModalRef,
  ExpoIonicIcon,
  ExpoMCIcon,
  UploadVideoModalRef,
  UploadFileHandlerRef,
  useDisclose,
  // useRecoilValue,
  UploadVideoPlayButtonIcon,
  ExtendedUserDetailsInterface,
  useRecoilValue,
  CommonActions,
} from '@know/ui';
import { chunk, keys, map, omit, orderBy, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { Dimensions } from 'react-native';
import { taskLastOpenTabAtom, taskRoutesAtom } from '../state';

const windowDimensions = Dimensions.get('window');

const EMPTY_FUNCTION = () => {};

export const useTaskDetails = (
  taskId: string,
  shareId: string | undefined,
  options?: { formId?: string; responseId?: string; initiatorId?: string }
) => {
  const {
    currentDBApi,
    getCurrentUserUserId,
    getCurrentUserName,
    uploadFilesWithFilesDetails: uploadFilesDB,
    userDetails,
  } = useKNOWDBContext();
  const { isTabletSize, isLargePhoneSize, isPhoneSize, openShareDialog } =
    useKNOWValueContextProvider();
  const [isLoading, setIsLoading] = useState(true);
  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const lastTaskTabOpened = useRecoilValue(taskLastOpenTabAtom);

  const isSmallScreen = isTabletSize || isLargePhoneSize || isPhoneSize;

  const [screenHeight, setScreenHeight] = useState(windowDimensions.height);

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [topBarHeight, setTopBarHeight] = useState<number>(0);

  const [detailsContainerWidth, setDetailsContainerWidth] = useState<number>(0);
  const [detailsContainerHeight, setDetailsContainerHeight] =
    useState<number>(0);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setScreenHeight(window.height);
    });
    return () => subscription?.remove();
  }, []);

  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);

  const setDataLastUpdated = useDebounceLoading(stopLoading, 700);

  const [taskUpdates, setTaskUpdates] = useState<
    Record<string, TaskUpdateType>
  >({});
  const [taskNugget, setTaskNugget] = useState<TaskNugget | null>(null);

  const setTaskUpdatesDebounce = useDebounceSetData(
    taskUpdates,
    setTaskUpdates,
    200,
    true
  );

  const [raisedErrors, setRaisedErrors] = useState<boolean>(false);

  const navigation = useNavigation();

  const taskRoutes = useRecoilValue(taskRoutesAtom);

  const { errorToast } = useKNOWToast();

  const {
    isOpen: isCompleteTaskDialogOpen,
    onClose: closeCompleteTaskDialog,
    onOpen: openCompleteTaskDialog,
  } = useDisclose();

  const {
    isOpen: isReopenTaskDialogOpen,
    onClose: closeReopenTaskDialog,
    onOpen: openReopenTaskDialog,
  } = useDisclose();

  const listRef = useRef<any>(null);
  const containerListRef = useRef<any>(null);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadProgressProps, setUploadProgressProps] = useState<{
    type: UploadProgressType;
    numberOfFiles: number;
  }>({ type: 'signature', numberOfFiles: 1 });

  const uploadImageModalRef = useRef<UploadImageModalRef | null>(null);
  const uploadVideoModalRef = useRef<UploadVideoModalRef | null>(null);
  const uploadFileHandlerRef = useRef<UploadFileHandlerRef | null>(null);

  const [taskUsers, setTaskUsers] = useState<ExtendedUserDetailsInterface[]>(
    []
  );
  const [isFetchedTasksUsers, setIsFetchedTasksUsers] =
    useState<boolean>(false);
  const [isTaskUsersLoading, setIsTaskUsersLoading] = useState<boolean>(true);

  const {
    isOpen: isTaskUsersDialogOpen,
    onClose: closeTaskUsersDialog,
    onOpen: _openTaskUsersDialog,
  } = useDisclose(false);

  const onNewTaskUpdateWithDebounced = useCallback(
    (taskUpdateId, taskUpdateItem) => {
      setDataLastUpdated(Date.now());
      setTaskUpdatesDebounce((currentTaskUpdates: any) => {
        if (taskUpdateItem) {
          return {
            ...currentTaskUpdates,
            [taskUpdateId]: taskUpdateItem,
          };
        } else {
          return omit(currentTaskUpdates, [taskUpdateId]);
        }
      });
    },
    [setDataLastUpdated, setTaskUpdatesDebounce]
  );

  const onNewTaskUpdate = useCallback(
    (taskUpdateId, taskUpdateItem) => {
      setDataLastUpdated(Date.now());
      setTaskUpdates((currentTaskUpdates: any) => {
        if (taskUpdateItem) {
          return {
            ...currentTaskUpdates,
            [taskUpdateId]: taskUpdateItem,
          };
        } else {
          return omit(currentTaskUpdates, [taskUpdateId]);
        }
      });
    },
    [setDataLastUpdated]
  );

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribeTaskUpdates = subscribeToTaskUpdates(
        currentDBApi,
        taskId,
        onNewTaskUpdateWithDebounced
      );

      const unSubToTaskNugget = listenToTaskNugget(
        currentDBApi,
        taskId,
        (task) => {
          setTaskNugget(task);
          setIsActionLoading(false);
        }
      );
      setTimeout(() => setDataLastUpdated(Date.now()), 2000);
      // Return statement has un listening callback.
      return () => {
        unSubScribeTaskUpdates();
        unSubToTaskNugget();
      };
    }
    return EMPTY_FUNCTION;
  }, [currentDBApi, taskId, onNewTaskUpdateWithDebounced, setDataLastUpdated]);

  useEffect(() => {
    if (
      (!taskNugget || !keys(taskNugget).length) &&
      !isLoading &&
      !raisedErrors
    ) {
      setRaisedErrors(true);
      errorToast({ header: "Task doesn't exist or was deleted!" });
    }
  }, [isLoading, taskNugget, raisedErrors, errorToast]);

  useHideHeader();

  const goBack = useCallback(() => {
    if (options?.formId && options?.responseId && options?.initiatorId) {
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'webview',
          params: {
            formId: options?.formId,
            responseId: options?.responseId,
            initiatorId: options?.initiatorId,
            type: 'sent',
            parentId: taskId,
            parentShareId: shareId,
          },
          initial: false,
        } as never
      );
    } else {
      // navigation.goBack();
      const routes = taskRoutes.map((name: string) => ({
        name: name as never,
      }));
      const filteredRoutes = routes.filter(
        ({ name }) => name === lastTaskTabOpened
      );

      const resetPayload = {
        routes: [
          {
            name: 'Tasks',
            state: {
              routes: [
                {
                  name: 'list',
                  state: {
                    routes: filteredRoutes,
                  },
                },
              ],
            },
          },
        ],
      };

      navigation.dispatch(CommonActions.reset(resetPayload));
    }
  }, [
    lastTaskTabOpened,
    navigation,
    options?.formId,
    options?.initiatorId,
    options?.responseId,
    shareId,
    taskId,
    taskRoutes,
  ]);

  const taskStatus = useMemo(
    () => (taskNugget ? getTaskStatus(taskNugget) : 'notStarted'),
    [taskNugget]
  );

  const isFormUnSubmitted = useMemo(
    () => taskNugget?.linkedFormId && !taskNugget?.isFormSubmitted,
    [taskNugget?.isFormSubmitted, taskNugget?.linkedFormId]
  );

  const actionButtonText = useMemo(() => {
    if (taskStatus === 'notStarted') {
      return 'Start Task';
    }
    if (taskStatus === 'inProgress') {
      if (isFormUnSubmitted) {
        return 'Submit Form';
      }
      return 'Complete Task';
    }

    if (taskStatus === 'completed') {
      return 'Reopen Task';
    }

    return 'Start Task';
  }, [isFormUnSubmitted, taskStatus]);

  const sortedTaskUpdates = useMemo(
    () =>
      orderBy(
        map(taskUpdates, (u, taskUpdateId) => ({ ...u, taskUpdateId })),
        'timestamp',
        'desc'
      ),
    [taskUpdates]
  );

  const onClickPayload: TaskStatusOnClickPayloadType = useCallback(
    (payload) => {
      if (payload.type === 'form_link') {
        const formId = payload.value?.formId;

        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId: formId,
              type: 'new',
              responseId: '-',
              initiatorId: '-',
              parentId: taskId,
              parentType: 'tasks',
              parentShareId: shareId,
            },
            initial: false,
          } as never
        );
      } else if (payload.type === 'form_submission') {
        const formId = payload.value?.formId;
        const responseId = payload.value?.responseId;
        const initiatorId = payload.value?.submitterId;

        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId,
              responseId,
              initiatorId,
              type: 'sent',
              parentId: taskId,
              parentType: 'tasks',
              parentShareId: shareId,
            },
          } as never
        );
      } else if (payload.type === 'form_draft') {
        const formId = payload.value?.formId;
        const responseId = payload.value?.draftId;

        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId,
              responseId,
              initiatorId: getCurrentUserUserId(),
              type: 'draft',
              parentId: taskId,
              parentType: 'tasks',
              parentShareId: shareId,
            },
          } as never
        );
      } else if (payload.type === 'form_response') {
        const formId = payload.value?.formId;
        const responseId = payload.value?.responseId;
        const initiatorId = payload.value?.submitterId;

        const groupId = payload.value?.groupId;
        const questionId = payload.value?.questionId;

        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId,
              responseId,
              initiatorId,
              type: 'sent',
              parentId: taskId,
              parentType: 'tasks',
              parentShareId: shareId,
              groupId,
              questionId,
            },
          } as never
        );
      }
    },
    [getCurrentUserUserId, navigation, shareId, taskId]
  );

  const onClickImage = useCallback((url: string) => {
    if (Platform.OS === 'web') {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const onClickPdf = useCallback((url: string) => {
    if (Platform.OS === 'web') {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const onClickActionButton = useCallback(async () => {
    if (currentDBApi && taskNugget) {
      if (taskStatus === 'notStarted') {
        setIsActionLoading(true);
        await startTask(currentDBApi, taskId, getCurrentUserName());
      } else {
        if (isFormUnSubmitted && taskNugget?.linkedFormId) {
          navigation.navigate(
            'Forms' as never,
            {
              screen: 'webview',
              params: {
                formId: taskNugget?.linkedFormId,
                type: 'new',
                responseId: '-',
                initiatorId: '-',
                parentId: taskId,
                parentType: 'tasks',
                parentShareId: shareId,
              },
              initial: false,
            } as never
          );
        } else {
          if (taskStatus === 'inProgress') {
            openCompleteTaskDialog();
          } else if (taskStatus === 'completed') {
            openReopenTaskDialog();
          }
        }
      }
    }
  }, [
    currentDBApi,
    taskNugget,
    taskStatus,
    taskId,
    getCurrentUserName,
    isFormUnSubmitted,
    navigation,
    shareId,
    openCompleteTaskDialog,
    openReopenTaskDialog,
  ]);

  const onCompleteTask = useCallback(
    async (params: {
      remark: string;
      image?: UploadFileResultType | undefined;
    }) => {
      if (currentDBApi && taskNugget) {
        setIsActionLoading(true);
        closeCompleteTaskDialog();
        await completeTask(
          currentDBApi,
          taskId,
          userDetails,
          taskNugget,
          params
        );
      }
    },
    [closeCompleteTaskDialog, currentDBApi, taskId, taskNugget, userDetails]
  );

  const onReopenTask = useCallback(
    async (params: {
      remark: string;
      image?: UploadFileResultType | undefined;
    }) => {
      if (currentDBApi && taskNugget) {
        setIsActionLoading(true);
        closeReopenTaskDialog();
        await reopenTask(currentDBApi, taskId, userDetails, params);
      }
    },
    [closeReopenTaskDialog, currentDBApi, taskId, taskNugget, userDetails]
  );

  const uploadFiles = useCallback(
    async (files: File[], uploadType: UploadProgressType) => {
      setUploadProgress(0);
      setUploadProgressProps({ numberOfFiles: files.length, type: uploadType });
      setIsUploading(true);
      const path = `${currentDBApi?.organization ?? 'na'}/${
        currentDBApi?.getLoggedInUserId() ?? 'user-id'
      }/${taskId}`;
      const results = await uploadFilesDB(path, files, (progress) => {
        setUploadProgress(progress);
      });
      setUploadProgress(100);
      setIsUploading(false);
      setUploadProgress(0);

      return results ?? [];
    },
    [currentDBApi, taskId, uploadFilesDB]
  );

  const onSendRemark = useCallback(
    async (text: string) => {
      if (currentDBApi) {
        await addTaskProgressRemark(
          currentDBApi,
          taskId,
          text,
          getCurrentUserName(),
          onNewTaskUpdate
        );

        await raiseStatusChangeRequest(
          currentDBApi,
          taskId,
          'updated',
          getCurrentUserName()
        );

        if (isSmallScreen) {
          containerListRef.current?.scrollTo({
            x: 0,
            y: detailsContainerHeight,
            animated: true,
          });
        } else {
          listRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }
      }
    },
    [
      currentDBApi,
      detailsContainerHeight,
      getCurrentUserName,
      isSmallScreen,
      onNewTaskUpdate,
      taskId,
    ]
  );

  const menuOptions = useMemo<EllipsisMenuOption[][]>(() => {
    return [
      [
        {
          id: ' take-photo',
          Icon: <ExpoFAIcon name="camera" size={'18px'} color="gray.700" />,
          label: 'Take Photo',
          onClick: () => {
            uploadImageModalRef.current?.openCamera?.();
          },
        },
        {
          id: ' upload-photo',
          Icon: <ExpoIonicIcon size="18px" name="image" color="gray.700" />,
          label: 'Upload Photo',
          onClick: () => {
            uploadImageModalRef.current?.openUpload?.();
          },
        },
      ],
      [
        {
          id: ' record-video',
          Icon: (
            <ExpoIonicIcon
              name="videocam-sharp"
              size={'18px'}
              color="gray.700"
            />
          ),
          label: 'Record Video',
          onClick: () => {
            uploadVideoModalRef.current?.openCamera?.();
          },
        },
        {
          id: ' upload-video',
          Icon: <UploadVideoPlayButtonIcon size="18px" color="gray.700" />,
          label: 'Upload Video',
          onClick: () => {
            uploadVideoModalRef.current?.openUpload?.();
          },
        },
      ],
      [
        {
          id: ' upload-pdf',
          Icon: <ExpoMCIcon name="file" size="18px" color="gray.700" />,
          label: 'Upload Document',
          onClick: () => {
            uploadFileHandlerRef.current?.onClick?.();
          },
        },
      ],
    ];
  }, []);

  const onAddAttachment = useCallback(
    async (files: File[], uploadType: UploadProgressType) => {
      if (currentDBApi && uploadType !== 'signature') {
        const uploadedFiles = await uploadFiles(files, uploadType);
        const chunkLength = uploadType === 'image' ? 10 : 1;
        const chunks = chunk(uploadedFiles, chunkLength);

        await Promise.all(
          chunks.map(async (chunkedFiles) => {
            await addTaskStatusProgressAttachment(
              currentDBApi,
              taskId,
              uploadType === 'file' ? 'pdf' : uploadType,
              chunkedFiles,
              getCurrentUserName(),
              onNewTaskUpdate
            );
          })
        );

        await raiseStatusChangeRequest(
          currentDBApi,
          taskId,
          'updated',
          getCurrentUserName()
        );

        if (isSmallScreen) {
          containerListRef.current?.scrollTo({
            x: 0,
            y: detailsContainerHeight,
            animated: true,
          });
        } else {
          listRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }
      }

      return [];
    },
    [
      currentDBApi,
      uploadFiles,
      isSmallScreen,
      taskId,
      getCurrentUserName,
      onNewTaskUpdate,
      detailsContainerHeight,
    ]
  );

  const onAddImages = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'image');
    },
    [onAddAttachment]
  );

  const onAddVideos = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'video');
    },
    [onAddAttachment]
  );

  const onAddFiles = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'file');
    },
    [onAddAttachment]
  );

  const onClickShare = useCallback(() => {
    if (taskId) {
      openShareDialog(
        'tasks',
        taskId,
        taskNugget?.author ?? getCurrentUserUserId() ?? '-'
      );
    }
  }, [getCurrentUserUserId, openShareDialog, taskId, taskNugget?.author]);

  const getTasksUsers = useCallback(async () => {
    if (currentDBApi && taskId) {
      setIsTaskUsersLoading(true);
      setIsFetchedTasksUsers(true);
      const users = await getTaskUsersFromDB(currentDBApi, taskId);
      setTaskUsers(sortBy(users.map(prepareUsersDetails), 'userName'));
      setIsTaskUsersLoading(false);
    }
  }, [currentDBApi, taskId]);

  const openTaskUsersDialog = useCallback(() => {
    _openTaskUsersDialog();
    if (!isFetchedTasksUsers) {
      getTasksUsers();
    }
  }, [getTasksUsers, isFetchedTasksUsers, _openTaskUsersDialog]);

  const onClickAuditDetails = useCallback(() => {
    if (taskNugget?.auditDetails) {
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'webview',
          params: {
            formId: taskNugget.auditDetails.formId,
            responseId: taskNugget.auditDetails.responseId,
            initiatorId: taskNugget.auditDetails.userId,
            type: 'sent',
            parentId: taskId,
            parentType: 'tasks',
            parentShareId: shareId,
            groupId: taskNugget.auditDetails.questionGroupId,
            questionId: taskNugget.auditDetails.questionId,
          },
        } as never
      );
    }
  }, [navigation, shareId, taskId, taskNugget?.auditDetails]);

  return {
    isLoading,
    screenHeight,
    taskUpdates: sortedTaskUpdates,
    taskNugget,
    isSmallScreen,
    taskStatus,
    actionButtonText,
    isActionLoading,
    isUploading,
    uploadProgress,
    uploadProgressProps,
    isFormUnSubmitted,
    isPhoneSize,
    menuOptions,
    uploadImageModalRef,
    uploadVideoModalRef,
    uploadFileHandlerRef,
    taskUsers,
    isTaskUsersLoading,
    isTaskUsersDialogOpen,
    listRef,
    containerListRef,
    containerHeight,
    topBarHeight,
    detailsContainerWidth,
    detailsContainerHeight,
    isCompleteTaskDialogOpen,
    isReopenTaskDialogOpen,
    setContainerHeight,
    setTopBarHeight,
    setDetailsContainerWidth,
    setDetailsContainerHeight,
    closeTaskUsersDialog,
    openTaskUsersDialog,
    closeCompleteTaskDialog,
    closeReopenTaskDialog,
    openCompleteTaskDialog,
    openReopenTaskDialog,
    goBack,
    onClickImage,
    onClickPayload,
    onClickActionButton,
    uploadFiles,
    onSendRemark,
    onClickPdf,
    onAddImages,
    onAddVideos,
    onAddFiles,
    onClickShare,
    onClickAuditDetails,
    onCompleteTask,
    onReopenTask,
  };
};
