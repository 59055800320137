//TODO: make a native app friendly version
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  ExpoADIcon,
  ExpoFAIcon,
  Menu,
  Modal,
  Pressable,
  Text,
  KNOWButton as Button,
  UploadIcon,
  UndoIcon,
  ExpoMCIcon,
  getFormattedTime,
  ExpoMAIcon,
} from '@know/ui';
import Webcam from 'react-webcam';
import { getFileFromBase64 } from './utils';

interface TakeVideoModalProps {
  isOpen: boolean;
  restrictImagePicker?: boolean;
  close: () => void;
  onSubmit?: (response: File) => void;
  onClickUpload?: () => void;
  maxRecordLengthSec?: number;
  hideUploadButton?: boolean;
}

export const TakeVideoModal = ({
  isOpen,
  close,
  onSubmit,
  onClickUpload: _onClickUpload,
  restrictImagePicker,
  hideUploadButton,
  maxRecordLengthSec = 60 * 10, // 10 minutes default
}: TakeVideoModalProps) => {
  const [permissionAllowed, setIsPermissionAllowed] = useState(false);
  const [noCameraFound, setNoCameraFound] = useState(false);
  const [webcamSize, setWebcamSize] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoDevice, setSelectedVideoDevice] =
    useState<MediaDeviceInfo | null>(null);

  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioDevice, setSelectedAudioDevice] =
    useState<MediaDeviceInfo | null>(null);

  const webcamRef = useRef<Webcam | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isPausedRecording, setIsPausedRecording] = useState<boolean>(false);
  const [takenVideoData, setTakenVideoData] = useState<Blob[] | null>(null);
  const [takenVideoLengthSec, setTakenVideoLengthSec] = useState<number>(0);
  const [takenVideoUrl, setTakenVideoUrl] = useState<string | null>(null);

  const [isConvertingVideo, setIsConvertingVideo] = useState<boolean>(false);

  const [countDownUntilRecording, setCountDownUntilRecording] =
    useState<number>(0);

  const onClose = useCallback(() => {
    close();
  }, [close]);

  const startRecording = useCallback(() => {
    try {
      mediaRecorderRef.current?.start(1000);
      setIsRecording(true);
    } catch (e: any) {
      console.log(e);
      setIsPermissionAllowed(false);
    }
  }, []);

  const pauseRecording = useCallback(() => {
    setIsPausedRecording(true);
    mediaRecorderRef.current?.pause();
  }, []);

  const resumeRecording = useCallback(() => {
    setIsPausedRecording(false);
    mediaRecorderRef.current?.resume();
  }, []);

  const startCountDown = useCallback(
    (countDown: number = 3) => {
      setCountDownUntilRecording(countDown);
      if (countDown) {
        const newCountDown = countDown - 1;
        setTimeout(() => startCountDown(newCountDown), 1000);
      } else {
        startRecording();
      }
    },
    [startRecording]
  );

  const onClickRecordVideo = useCallback(() => {
    if (webcamRef.current?.stream) {
      // eslint-disable-next-line no-undef
      const mediaRecorder = new MediaRecorder(webcamRef.current?.stream, {
        mimeType: 'video/webm',
      });
      console.log(mediaRecorder.mimeType);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorderRef.current.ondataavailable = (e) => {
        const data = e.data;
        setTakenVideoData((currentVideoData) => [
          ...(currentVideoData ?? []),
          data,
        ]);
        setTakenVideoLengthSec((currentVideoLength) => currentVideoLength + 1);
      };
      setTakenVideoData(null);
      setTakenVideoLengthSec(0);

      startCountDown();
    }
  }, [startCountDown]);

  const stopRecording = useCallback(() => {
    setIsRecording(false);
    setIsPausedRecording(false);
    mediaRecorderRef.current?.pause();
    mediaRecorderRef.current?.requestData();
    setTakenVideoData(null);
    setTakenVideoLengthSec(0);

    if (takenVideoData) {
      // eslint-disable-next-line no-undef
      const videoBlob = new Blob(takenVideoData);
      setTakenVideoUrl(URL.createObjectURL(videoBlob));
    }
  }, [takenVideoData]);

  useEffect(() => {
    if (
      isRecording &&
      !isPausedRecording &&
      takenVideoLengthSec &&
      takenVideoLengthSec > maxRecordLengthSec
    ) {
      stopRecording();
    }
  }, [
    isPausedRecording,
    isRecording,
    maxRecordLengthSec,
    stopRecording,
    takenVideoLengthSec,
  ]);

  useEffect(() => {
    if (permissionAllowed) {
      navigator.mediaDevices.enumerateDevices().then((_devices) => {
        const foundVideoDevices = _devices.filter(
          ({ kind }) => kind === 'videoinput'
        );
        if (!foundVideoDevices.length) {
          setNoCameraFound(true);
        } else {
          setSelectedVideoDevice(foundVideoDevices[0]);
          setVideoDevices(foundVideoDevices);
        }

        const foundAudioDevices = _devices.filter(
          ({ kind }) => kind === 'audioinput'
        );
        if (foundAudioDevices.length) {
          setSelectedAudioDevice(foundAudioDevices[0]);
          setAudioDevices(foundAudioDevices);
        }
      });
    }
  }, [permissionAllowed]);

  const onClickSubmit = useCallback(async () => {
    if (takenVideoUrl) {
      setIsConvertingVideo(true);
      const file = await getFileFromBase64(
        takenVideoUrl,
        'video/webm',
        `captured-image-${Date.now()}.webm`
      );
      setIsConvertingVideo(false);
      onSubmit && onSubmit(file);
    }
    onClose();
  }, [onClose, onSubmit, takenVideoUrl]);

  const onClickUpload = useCallback(() => {
    onClose();
    _onClickUpload && _onClickUpload();
  }, [_onClickUpload, onClose]);

  const onClickRetake = useCallback(() => {
    URL.revokeObjectURL(takenVideoUrl ?? '');
    setTakenVideoUrl(null);
  }, [takenVideoUrl]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={!isConvertingVideo}
      onClose={onClose}
      size="xl"
    >
      <Modal.Content>
        <Modal.CloseButton isDisabled={isConvertingVideo} />
        <Modal.Header borderBottomWidth={0}>Record Video</Modal.Header>
        <Modal.Body display={'flex'} flexDirection={'column'}>
          <Box
            onLayout={(e) => {
              setWebcamSize({
                width: e.nativeEvent.layout.width,
                height: Math.round(e.nativeEvent.layout.width / 1.7),
              });
            }}
            w={'100%'}
            display={'flex'}
            position={'relative'}
            bg={!permissionAllowed || countDownUntilRecording ? 'black' : null}
            borderRadius={'6px'}
          >
            {takenVideoUrl ? (
              <video
                src={takenVideoUrl}
                controls
                // eslint-disable-next-line react-native/no-inline-styles
                style={{ borderRadius: '6px ' }}
              />
            ) : (
              <Webcam
                audio={true}
                ref={webcamRef}
                onUserMedia={() => setIsPermissionAllowed(true)}
                onUserMediaError={() => setIsPermissionAllowed(false)}
                width={webcamSize.width}
                muted={true}
                videoConstraints={
                  selectedVideoDevice
                    ? { deviceId: selectedVideoDevice.deviceId }
                    : true
                }
                audioConstraints={
                  selectedAudioDevice
                    ? { deviceId: selectedAudioDevice.deviceId }
                    : true
                }
                // eslint-disable-next-line react-native/no-inline-styles
                style={{ borderRadius: '6px' }}
              />
            )}
            {noCameraFound ? (
              <Box
                position={'absolute'}
                display={'flex'}
                top={0}
                left={0}
                justifyContent={'center'}
                alignItems={'center'}
                w="100%"
                h="100%"
              >
                <Text
                  fontWeight={600}
                  color={'white'}
                  fontSize={'18px'}
                  mb={'6px'}
                  mt={'6px'}
                >
                  No Camera Available
                </Text>
              </Box>
            ) : null}
            {permissionAllowed || noCameraFound ? null : (
              <Box
                position={'absolute'}
                display={'flex'}
                top={0}
                left={0}
                justifyContent={'center'}
                alignItems={'center'}
                w="100%"
                h="100%"
              >
                <Box
                  w={'100%'}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Box h={'100%'} mr={'8px'}>
                    <Box
                      bg={'gray.900'}
                      borderRadius={'50%'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      h={'40px'}
                      w={'40px'}
                    >
                      <ExpoADIcon
                        size={'18px'}
                        name="arrowleft"
                        color={'white'}
                        style={{ transform: [{ rotate: '45deg' }] }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    w={'50%'}
                  >
                    <Text
                      fontWeight={600}
                      color={'white'}
                      fontSize={'18px'}
                      mb={'6px'}
                      mt={'6px'}
                    >
                      Allow Camera & Microphone
                    </Text>
                    <Text color={'white'} fontSize={'14px'} mb={'6px'}>
                      To record videos, click 'Allow' above to give KNOW access
                      to your camera and microphone.
                    </Text>
                    <Text color={'white'} fontSize={'14px'} mb={'10px'}>
                      If you accidentally denied permission, go to your browser
                      settings to change it.
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}
            {countDownUntilRecording || isPausedRecording ? (
              <Box
                position={'absolute'}
                display={'flex'}
                top={0}
                left={0}
                justifyContent={'center'}
                alignItems={'center'}
                w="100%"
                h="100%"
                bg={'gray.900'}
                borderRadius={'6px'}
              >
                <Box
                  w={'100%'}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  {countDownUntilRecording ? (
                    <Text fontWeight={600} fontSize={'16px'} color={'white'}>
                      Recording starts in {countDownUntilRecording}...
                    </Text>
                  ) : null}
                  {isPausedRecording ? (
                    <Text fontWeight={600} fontSize={'16px'} color={'white'}>
                      Click on resume to continue recording
                    </Text>
                  ) : null}
                </Box>
              </Box>
            ) : null}
          </Box>
          {permissionAllowed &&
          !isRecording &&
          !takenVideoUrl &&
          !countDownUntilRecording ? (
            <Box
              display={'flex'}
              flexDir={'row'}
              w={'100%'}
              alignItems={'center'}
              maxW={'100%'}
            >
              <Box mt={'10px'} alignSelf={'flex-start'} flex={1}>
                <Menu
                  trigger={(triggerProps) => (
                    <Pressable {...triggerProps}>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        bg={'gray.200'}
                        borderRadius={'17px'}
                        padding="10px 12px"
                        w={'100%'}
                        h={'100%'}
                        flex={1}
                      >
                        <ExpoFAIcon
                          name="video-camera"
                          color={'gray.700'}
                          mr={'6px'}
                          size={'14px'}
                          mt={'2px'}
                        />{' '}
                        <Text
                          fontSize={'14px'}
                          color={'gray.700'}
                          isTruncated={true}
                          flex={1}
                          numberOfLines={1}
                        >
                          {selectedVideoDevice?.label}
                        </Text>
                        <ExpoFAIcon
                          size={'14px'}
                          name="caret-down"
                          color={'gray.700'}
                          ml={'6px'}
                          mt={'2px'}
                        />
                      </Box>
                    </Pressable>
                  )}
                >
                  {videoDevices.map((dev) => (
                    <Menu.Item
                      key={dev.deviceId}
                      onPress={() => setSelectedVideoDevice(dev)}
                    >
                      <Box
                        display={'flex'}
                        flexDir={'row'}
                        justifyContent={'space-between'}
                        w={'100%'}
                        alignItems={'center'}
                      >
                        <Text
                          color={
                            dev.deviceId === selectedVideoDevice?.deviceId
                              ? 'primary.500'
                              : 'gray.900'
                          }
                          fontSize={'14px'}
                          flex={1}
                          numberOfLines={1}
                        >
                          {dev.label}
                        </Text>
                        {dev.deviceId === selectedVideoDevice?.deviceId ? (
                          <ExpoADIcon
                            name="check"
                            ml={'40px'}
                            color={'primary.500'}
                            size={'18px'}
                            mt={'2px'}
                          />
                        ) : null}
                      </Box>
                    </Menu.Item>
                  ))}
                </Menu>
              </Box>
              <Box mt={'10px'} alignSelf={'flex-start'} ml={'8px'} flex={1}>
                <Menu
                  trigger={(triggerProps) => (
                    <Pressable {...triggerProps}>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        bg={'gray.200'}
                        borderRadius={'17px'}
                        padding="10px 12px"
                        w={'100%'}
                        h={'100%'}
                        flex={1}
                      >
                        <ExpoMCIcon
                          name="microphone"
                          color={'gray.700'}
                          mr={'6px'}
                          size={'18px'}
                          mt={'2px'}
                        />{' '}
                        <Text
                          fontSize={'14px'}
                          color={'gray.700'}
                          isTruncated={true}
                          flex={1}
                          numberOfLines={1}
                        >
                          {selectedAudioDevice?.label}
                        </Text>
                        <ExpoFAIcon
                          size={'14px'}
                          name="caret-down"
                          color={'gray.700'}
                          ml={'6px'}
                          mt={'2px'}
                        />
                      </Box>
                    </Pressable>
                  )}
                >
                  {audioDevices.map((dev) => (
                    <Menu.Item
                      key={dev.deviceId}
                      onPress={() => setSelectedAudioDevice(dev)}
                    >
                      <Box
                        display={'flex'}
                        flexDir={'row'}
                        justifyContent={'space-between'}
                        w={'100%'}
                        alignItems={'center'}
                      >
                        <Text
                          color={
                            dev.deviceId === selectedAudioDevice?.deviceId
                              ? 'primary.500'
                              : 'gray.900'
                          }
                          fontSize={'14px'}
                        >
                          {dev.label}
                        </Text>
                        {dev.deviceId === selectedAudioDevice?.deviceId ? (
                          <ExpoADIcon
                            name="check"
                            ml={'40px'}
                            color={'primary.500'}
                            size={'18px'}
                            mt={'2px'}
                          />
                        ) : null}
                      </Box>
                    </Menu.Item>
                  ))}
                </Menu>
              </Box>
            </Box>
          ) : null}
          {takenVideoUrl ? (
            <Box mt={'12px'} display={'flex'} flexDir={'row'}>
              <Pressable
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                onPress={onClickRetake}
                ml={'auto'}
                mr={'auto'}
              >
                <UndoIcon mr="4px" size="24px" />
                <Text fontWeight={600} color={'gray.900'} fontSize={'16px'}>
                  Retake
                </Text>
              </Pressable>
            </Box>
          ) : null}
        </Modal.Body>
        <Modal.Footer
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          {isRecording ? (
            <>
              <Text>
                {getFormattedTime(takenVideoLengthSec, 'm:ss')} /{' '}
                {getFormattedTime(maxRecordLengthSec, 'm:ss')}
              </Text>
              <Pressable
                h={'100%'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                onPress={isPausedRecording ? resumeRecording : pauseRecording}
                ml={'auto'}
                mr={'24px'}
              >
                <ExpoMAIcon
                  name={isPausedRecording ? 'play-arrow' : 'pause'}
                  mr="4px"
                  size="24px"
                />
                <Text fontWeight={600} color={'gray.900'} fontSize={'16px'}>
                  Pause
                </Text>
              </Pressable>
              <Button
                _text={{ fontSize: '16px', color: 'white' }}
                colorScheme="danger"
                isDisabled={!permissionAllowed}
                onPress={stopRecording}
                flex={restrictImagePicker ? 1 : undefined}
                bg={'red.700'}
                _hover={{
                  bg: 'red.900',
                }}
              >
                Stop
              </Button>
            </>
          ) : takenVideoUrl ? (
            <Button
              _text={{ fontSize: '16px' }}
              colorScheme="primary"
              isDisabled={!permissionAllowed}
              flex={1}
              onPress={onClickSubmit}
              isLoading={isConvertingVideo}
            >
              Upload Video
            </Button>
          ) : (
            <>
              {restrictImagePicker ||
              countDownUntilRecording ||
              hideUploadButton ? null : (
                <Pressable
                  h={'100%'}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  onPress={onClickUpload}
                >
                  <UploadIcon mr="4px" size="24px" />
                  <Text fontWeight={600} color={'gray.900'} fontSize={'16px'}>
                    Upload Video
                  </Text>
                </Pressable>
              )}
              <Button
                _text={{
                  fontSize: '16px',
                  color: 'white',
                  fontFamily: 'OpenSans',
                }}
                colorScheme="danger"
                isDisabled={!permissionAllowed || !!countDownUntilRecording}
                onPress={onClickRecordVideo}
                flex={restrictImagePicker ? 1 : undefined}
                ml={'auto'}
                bg={'red.700'}
                _hover={{
                  bg: 'red.900',
                }}
              >
                Record
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
