import React from 'react';

import { KNOWRouterContenxt, IModuleProps } from '@know/ui';
import { FormsNavigator, linking } from './FormsNavigator';

export const FormsScreen = () => {
  const { registerScreen } = React.useContext(KNOWRouterContenxt);

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'forms',
      name: 'Forms',
      priority: 1,
      component: FormsNavigator,
      icon: 'wpforms',
      linking,
    };
    registerScreen(module);
  }, [registerScreen]);

  return null;
};
