import React from 'react';
import {
  View,
  Text,
  Box,
  Image,
  Progress,
  useBreakpointValue,
  Tag,
  Pressable,
  useNavigation,
  useRecoilValue,
  Button,
  LJPopover,
  useDisclose,
} from '@know/ui';
import { Dimensions, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useLanguageSwitch } from './useLanguageSwitch';
import { currentCourseTabAtom } from '../state';

const useDetailsHeaderSizes = () => {
  const smallScreenImageSizeWidth = Dimensions.get('window').width - 56;
  const topImageSize = useBreakpointValue({
    base: {
      height: `${Math.round((smallScreenImageSizeWidth * 3) / 5)}px`,
      width: `${smallScreenImageSizeWidth}px`,
    },
    sm: {
      height: `${Math.round((smallScreenImageSizeWidth * 3) / 5)}px`,
      width: `${smallScreenImageSizeWidth}px`,
    },
    md: { height: '150px', width: '250px' },
    lg: { height: '150px', width: '250px' },
    xl: { height: '150px', width: '250px' },
  });

  const topResponsiveFlexDirection = useBreakpointValue({
    base: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  });

  const topTitleTopPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  });

  const topResponsiveAlignDirection = useBreakpointValue({
    base: 'start',
    sm: 'start',
    md: 'center',
    lg: 'center',
    xl: 'center',
  });

  const topCourseInfoColumnPadding = useBreakpointValue({
    base: '0px',
    sm: '0px',
    md: '10px',
    lg: '10px',
    xl: '10px',
  });

  const topCourseInfoColumnWidth = useBreakpointValue({
    base: Dimensions.get('window').width - 56,
    sm: Dimensions.get('window').width - 56,
    md: Dimensions.get('window').width - 36,
    lg: '60%',
    xl: '60%',
  });

  const beginCourseWidth = useBreakpointValue({
    base: '100%',
    sm: '100%',
    md: 'auto',
    lg: 'auto',
    xl: 'auto',
  });

  const hPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '12px',
    lg: '15%',
    xl: '20%',
  });

  return {
    beginCourseWidth,
    hPadding,
    topCourseInfoColumnPadding,
    topCourseInfoColumnWidth,
    topImageSize,
    topResponsiveAlignDirection,
    topResponsiveFlexDirection,
    topTitleTopPadding,
  };
};

interface IJourneyDetailsScreenHeader {
  journeyId: string;
  cardInfo: any;
  langauges: { description: string; locale: string; seq: 0; title: string }[];
  isJourneyBegin?: number;
  handleJourney?: () => void;
  resumeButtonTooltip: any;
}

export const JourneyDetailsScreenHeader = ({
  journeyId,
  cardInfo,
  langauges,
  handleJourney,
  isJourneyBegin,
  resumeButtonTooltip,
}: IJourneyDetailsScreenHeader) => {
  const { handleToggleModal } = useLanguageSwitch(journeyId);
  const {
    beginCourseWidth,
    hPadding,
    topCourseInfoColumnPadding,
    topCourseInfoColumnWidth,
    topImageSize,
    topResponsiveAlignDirection,
    topResponsiveFlexDirection,
    topTitleTopPadding,
  } = useDetailsHeaderSizes();
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const navigation = useNavigation();

  const { isOpen, onOpen, onClose } = useDisclose();
  setTimeout(() => {
    if (isOpen) {
      onClose();
    }
  }, 3000);

  const buttonText =
    (isJourneyBegin ?? 0) > 0
      ? 'Resume Learning Journey'
      : 'Begin Learning Journey';

  return (
    <Box>
      <View
        style={{ paddingHorizontal: hPadding }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {navigation.canGoBack() && (
          <Pressable onPress={navigation.goBack} marginY="16px">
            <View
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
            >
              <Ionicons name="chevron-back" size={15} color="black" />
              <Text fontWeight="500" fontSize="15px" color="gray.900">
                Back to Courses
              </Text>
            </View>
          </Pressable>
        )}

        {langauges?.length > 1 && (
          <Pressable marginY="16px" onPress={() => handleToggleModal(false)}>
            <View
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
            >
              <Ionicons name="globe-outline" size={17} color="black" />
              <Text
                fontWeight="500"
                fontSize="14px"
                marginLeft="5px"
                color="gray.900"
              >
                LANG
              </Text>
            </View>
          </Pressable>
        )}
      </View>
      <View
        marginX={hPadding}
        bgColor="white"
        borderRadius="8px"
        padding="16px"
        shadow={2}
        flexDirection={['column', 'column', 'row', 'row', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <View
          flexDirection={topResponsiveFlexDirection}
          alignItems={topResponsiveAlignDirection}
          flex={1}
        >
          <View
            borderColor="gray.350"
            borderWidth="1px"
            borderRadius="10px"
            overflow="hidden"
          >
            <Image
              style={topImageSize}
              resizeMode="cover"
              source={{
                uri: cardInfo.miniThumbnail,
              }}
              alt="learn journey"
            />
          </View>
          <View
            flex={1}
            w={topCourseInfoColumnWidth}
            alignItems="flex-start"
            px={topCourseInfoColumnPadding}
          >
            <View
              w="full"
              flexDirection="row"
              alignItems="baseline"
              marginTop={topTitleTopPadding}
            >
              {!!cardInfo?.title && (
                <Text
                  fontWeight="600"
                  marginRight="8px"
                  fontSize={['18px', '18px', '20px', '20px', '20px']}
                  color="gray.900"
                >
                  {cardInfo.title}
                </Text>
              )}
              {(currentCourseTab === 'completed-courses' ||
                cardInfo.progress === 100) && (
                <Ionicons name="checkmark-circle" size={20} color="#00C1B2" />
              )}
            </View>
            {!!cardInfo?.totalCount && (
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px']}
                color="gray.700"
              >
                {cardInfo?.totalCount} Courses
              </Text>
            )}
            {currentCourseTab !== 'completed-courses' &&
              cardInfo?.progress < 100 && (
                <View
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  w={'full'}
                >
                  <View flex={1} alignItems="center" justifyContent="center">
                    <Progress
                      width="100%"
                      size="1.5"
                      value={cardInfo?.progress}
                      bg="secondary.25"
                      _filledTrack={{
                        bg: 'primary.500',
                      }}
                    />
                  </View>
                  <Text paddingLeft="12px" color="gray.700">
                    {cardInfo?.progress}%
                  </Text>
                </View>
              )}
            <View
              marginTop={
                currentCourseTab !== 'completed-courses'
                  ? '4px'
                  : ['8px', '8px', '8px', '12px', '12px']
              }
            >
              <Tag variant="normal" label={cardInfo?.type} />
            </View>
          </View>
        </View>
        {currentCourseTab !== 'completed-courses' && cardInfo?.progress < 100 && (
          <View
            w={beginCourseWidth}
            marginTop="12px"
            marginLeft={[0, 0, '22px', '22px', '22px']}
          >
            {!resumeButtonTooltip ? (
              <Button
                size={'sm'}
                minWidth="180px"
                onPress={resumeButtonTooltip ? onOpen : handleJourney}
                backgroundColor="primary.500"
              >
                <Text fontWeight="semibold" color="white" marginX="4px">
                  {buttonText}
                </Text>
              </Button>
            ) : (
              <LJPopover
                resumeButtonTooltip={resumeButtonTooltip}
                isOpen={isOpen}
                onOpen={onOpen}
                buttonText={buttonText}
              />
            )}
          </View>
        )}
      </View>

      <Box position="absolute" style={StyleSheet.absoluteFill} zIndex={-1}>
        <Box flex={0.6}>
          <Box
            position="absolute"
            style={StyleSheet.absoluteFill}
            bg={{
              linearGradient: {
                colors: ['#c9edef', '#b1e5e8', '#9bb6e5', '#d5dae4'],
                start: [0, 0],
                end: [1, 0],
              },
            }}
          />
        </Box>
        <Box flex={0.4} bgColor="white" />
      </Box>
    </Box>
  );
};
