import React, { Fragment } from 'react';
import {
  ScrollView,
  View,
  Text,
  Image,
  getFormattedTime,
  getTimeString,
} from '@know/ui';

import Images from '../../../Images';
import type { ILessonCard } from './types';

export const QuizIntro: React.FC<{
  card: ILessonCard;
  attempted: number;
}> = ({ card, attempted }) => {
  const {
    title,
    isLimited,
    attempts,
    passPercent,
    totalSec,
    isTimer,
    description,
  } = card;
  const timeLimit: string = `${getFormattedTime(totalSec)} ${getTimeString(
    totalSec
  )}`;
  const availableAttempts = attempts - (attempted ?? 0);

  return (
    <View bgColor={'white'} flex={1}>
      <ScrollView flex={1} padding={'15px'}>
        <Text
          fontWeight={'semibold'}
          fontSize={['20px', '20px', '22px', '22px', '22px']}
        >
          {title}
        </Text>
        <View alignItems="center" flexDirection="row" marginTop="15px">
          {isLimited ? (
            <ColumnIconWithInfo
              description={`${
                availableAttempts < 0 ? 0 : availableAttempts
              } Attempts Left`}
              iconPath={Images.QuizAttempt}
            />
          ) : (
            <ColumnIconWithInfo
              description="Unlimited Attempts"
              iconPath={Images.QuizAttemptInfinite}
            />
          )}

          <View marginX="12px" h="95%" w="1px" bgColor="secondary.500" />
          {isTimer ? (
            <ColumnIconWithInfo
              description={timeLimit}
              iconPath={Images.QuizTimeLimit}
            />
          ) : (
            <ColumnIconWithInfo
              description="No Time Limit"
              iconPath={Images.QuizNoTimeLimit}
            />
          )}

          <View marginX="12px" h="95%" w="1px" bgColor="secondary.500" />
          <ColumnIconWithInfo
            description={`${passPercent}% to pass`}
            iconPath={Images.QuizPass}
          />
        </View>
        <View w="100%" marginY="10px" h="1px" bgColor="secondary.500" />

        <QuizDetails
          description={description}
          isLimited={isLimited}
          availableAttempts={availableAttempts}
          passPercent={passPercent}
          timeLimit={timeLimit}
          isTimer={isTimer}
        />
      </ScrollView>
    </View>
  );
};

const ColumnIconWithInfo: React.FC<{
  iconPath: any;
  description: string;
}> = ({ iconPath, description }) => {
  return (
    <View flex={1}>
      <Image
        alt="quiz intro image"
        source={iconPath}
        w={['30px', '30px', '35px', '35px', '35px']}
        h={['30px', '30px', '35px', '35px', '35px']}
        marginBottom="10px"
      />
      <Text
        fontSize={['13px', '13px', '14px', '14px', '14px']}
        fontWeight="semibold"
      >
        {description}
      </Text>
    </View>
  );
};

const QuizDetails: React.FC<{
  isLimited: boolean;
  availableAttempts: number;
  passPercent: number;
  timeLimit: string;
  description: string;
  isTimer: boolean;
}> = ({
  isLimited,
  availableAttempts,
  passPercent,
  timeLimit,
  description,
  isTimer,
}) => (
  <Fragment>
    {description && <Text color="secondary.700">{description}</Text>}

    <View
      flexDirection="row"
      alignItems="center"
      fontSize="16px"
      marginTop="12px"
    >
      <View bgColor={'primary.500'} w="3px" h="3px" borderRadius={10} />
      {isLimited ? (
        <Fragment>
          <Text
            underline
            marginLeft="10px"
            marginRight="5px"
            fontSize={['13px', '13px', '14px', '14px', '14px']}
            fontWeight="semibold"
          >
            {availableAttempts < 0 ? 0 : availableAttempts}
          </Text>
          <Text>attempts left.</Text>
        </Fragment>
      ) : (
        <Text color="secondary.900" marginLeft="10px">
          <Text
            underline
            marginRight="5px"
            fontSize={['13px', '13px', '14px', '14px', '14px']}
            fontWeight="semibold"
          >
            Unlimited
          </Text>
          attempts left.
        </Text>
      )}
    </View>
    <View flexDirection="row" alignItems="center">
      <View bgColor={'primary.500'} w="3px" h="3px" borderRadius={10} />
      {!isTimer ? (
        <Text color="secondary.900" marginLeft="10px">
          No time limit.
        </Text>
      ) : (
        <Fragment>
          <Text
            underline
            marginLeft="10px"
            marginRight="5px"
            fontSize={['13px', '13px', '14px', '14px', '14px']}
            fontWeight="semibold"
          >
            {timeLimit}
          </Text>
          <Text>time limit</Text>
        </Fragment>
      )}
    </View>
    <View flexDirection="row" alignItems="center">
      <View bgColor={'primary.500'} w="3px" h="3px" borderRadius={10} />
      <Text color="secondary.900" marginLeft="10px">
        You must have an overall score of
        <Text
          underline
          marginLeft="5px"
          marginRight="5px"
          fontSize={['13px', '13px', '14px', '14px', '14px']}
          fontWeight="semibold"
        >
          {passPercent}%
        </Text>
        to pass
      </Text>
    </View>
  </Fragment>
);
