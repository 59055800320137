import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  ExpoMAIcon,
  InputFilter,
  UserContactListItem,
  Text,
  FirebaseAPI,
  WithKNOWLoader,
} from '@know/ui';

import { searchUsers } from '@know/transformers';

import { FlatList, HStack, Pressable } from 'native-base';

const ItemSeparatorComponent = () => <Divider my={2} />;

import { useTranslation } from '@know/i18n';

export function GroupBreadCrumbHeader({
  onGoBack,
  title,
}: {
  title: string;
  onGoBack: () => void;
}) {
  const { t } = useTranslation('buttons');
  return (
    <HStack alignItems={'center'} bgColor={'white'}>
      <Pressable onPress={onGoBack}>
        <HStack alignItems={'center'}>
          <Box
            borderRadius={'full'}
            bg={'gray.250'}
            w={'24px'}
            h="24px"
            mr="2"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <ExpoMAIcon name="chevron-left" size={'18px'} />
          </Box>
          <Text color={'gray.900'} bold>
            {t('Groups')} /
          </Text>
        </HStack>
      </Pressable>
      <Text
        bold
        ml={2}
        color="primary.500"
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {title}
      </Text>
    </HStack>
  );
}

export function GroupMembersWithFilter({
  groupId,
  groupName,
  onGoBack,
  currentDBApi,
}: {
  groupId: string;
  groupName: string;
  onGoBack: () => void;
  currentDBApi?: FirebaseAPI;
}) {
  const [groupMembers, setGroupMembers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [filterText, setFilterText] = useState('');

  const filteredGroupMembers = useMemo(() => {
    return filterText
      ? (searchUsers(filterText, groupMembers as any[]) as unknown as any[])
      : groupMembers;
  }, [groupMembers, filterText]);

  useEffect(() => {
    async function fetchGroupMemberts() {
      const groupMembersDetails: any[] =
        (await currentDBApi?.getGroupMembers(groupId)) || [];
      setGroupMembers(groupMembersDetails);
      setIsLoading(false);
    }
    if (currentDBApi && (groupMembers?.length ?? 0) === 0) {
      setIsLoading(true);
      fetchGroupMemberts();
    }
  }, [groupId, currentDBApi, groupMembers?.length, setGroupMembers]);

  return (
    <>
      <GroupBreadCrumbHeader title={groupName} onGoBack={onGoBack} />
      <Box py={3}>
        <InputFilter
          defaultValue={filterText}
          hasFilter={true}
          filterChangeDelay={1000}
          onChange={setFilterText}
          inputProps={{ fontSize: 'sm' }}
        />
      </Box>
      <WithKNOWLoader isLoading={isLoading}>
        <FlatList
          ItemSeparatorComponent={ItemSeparatorComponent}
          flex={1}
          data={filteredGroupMembers}
          renderItem={({ item }: any) => <UserContactListItem user={item} />}
        />
      </WithKNOWLoader>
    </>
  );
}
