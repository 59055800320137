import React, { createContext, useContext, useMemo } from 'react';
import type { ContextType, ISectionsListViewWithFilterProps } from './types';
import { SectionsListView } from './SectionsListView';

import { renderItem, renderSectionHeader } from './renderers';
import { useKNOWValueContextProvider } from '@know/ui';

const SelectableSectionsListViewWithFilterContext = createContext<
  ContextType<any>
>({
  data: [],
  keyExtractor: (item: any, _index: number) => item.id,
  getSectionHeaderComponent: () => null,
  ItemComponent: () => null,
  renderSectionHeader: () => null,
  renderItem: () => null,
  onToggleSection: () => {},
  onClickItem: () => {},
  onChangeSelectedItems: () => {},
  onClickDelete: () => {},
});

export const useSelectableSectionsView = () =>
  useContext(SelectableSectionsListViewWithFilterContext);

export function SectionsListViewWithFilter<Item>(
  props: ISectionsListViewWithFilterProps<Item>
) {
  const { tabHeaderHeight } = useKNOWValueContextProvider();

  const providerValue = useMemo(
    () =>
      ({
        ...props,
        additionalHeaderHeight: tabHeaderHeight,
        renderSectionHeader,
        renderItem,
      } as unknown as ContextType<Item>),
    [props, tabHeaderHeight]
  );

  return (
    <SelectableSectionsListViewWithFilterContext.Provider value={providerValue}>
      <SectionsListView />
    </SelectableSectionsListViewWithFilterContext.Provider>
  );
}
