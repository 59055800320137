import React from 'react';

import type { IFormItem, ISectionDataProps, Item } from '@know/transformers';
import {
  CollapsibleSectionHeader,
  FormFeedItem,
  FormFolderItem,
  HStack,
  useSelectableSectionsView,
} from '@know/ui';

import type { JSXElementConstructor, ReactElement } from 'react';

export const keyExtractor = function (item: Item): string {
  return item.id;
};

export const getSectionHeaderComponent = (
  info: {
    section: ISectionDataProps<Item>;
  },
  hasCustomPadding?: boolean,
  customPadding?: string
): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return (
    <CollapsibleSectionHeader
      id={info.section.id || info.section.type}
      title={info.section.title}
      hasCustomPadding={hasCustomPadding}
      customPadding={customPadding}
    />
  );
};

export const ItemComponent = (info: {
  item: Item;
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  const { onClickItem } = useSelectableSectionsView();

  return (
    <HStack alignItems={'center'} flex={1} justifyContent={'space-between'}>
      {info.section.type === 'folder' ? (
        <FormFolderItem
          item={info.item}
          type={info.section.type}
          onPress={onClickItem}
        />
      ) : (
        <FormFeedItem
          item={info.item as IFormItem}
          type={info.section.type}
          onPress={onClickItem}
        />
      )}
    </HStack>
  );
};
