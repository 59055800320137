import React from 'react';
import {
  useDebounceLoading,
  useDebounceSetData,
  useKNOWDBContext,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  Text,
  useNavigation,
  useKNOWValueContextProvider,
  StackActions,
} from '@know/ui';
import {
  openIssueAtom,
  openIssueFilterTextAtom,
  openIssuesItemsSelector,
  openIssueDateRangeAtom,
  openIssueLocationAtom,
} from './state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  cleanUpSubscribeIssuesNuggets,
  markIssueRead,
  subscribeToUserIssuesFeed,
  subscribeToUserMutedIssues,
} from '@know/db';
import { TaskFeedItem, TransformedIssueItem } from '@know/transformers';
import { omit } from 'lodash';
import { compareIssueSeverity, getDateParts } from '../utils';

import { IssueSeverityType } from '@know/ui';
import { globalMutedIssueAtom } from '../state';

const EMPTY_FUNCTION = () => {};

export const useOpenIssues = () => {
  const { currentDBApi, orgIssueLocations } = useKNOWDBContext();
  const navigation = useNavigation();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [filterText, onFilterTextChange] = useRecoilState(
    openIssueFilterTextAtom
  );
  const [filterDateRange, onFilterDateRangeChange] = useRecoilState(
    openIssueDateRangeAtom
  );
  const [filterLocation, onFilterLocationChange] = useRecoilState(
    openIssueLocationAtom
  );

  const setOpenIssuesFeedItemsAtom = useSetRecoilState(openIssueAtom);
  const setMutedIssueFeedItemsAtom = useSetRecoilState(globalMutedIssueAtom);

  const issueItems = useRecoilValue(openIssuesItemsSelector);

  const setOpenIssueFeedItems = useDebounceSetData(
    {},
    setOpenIssuesFeedItemsAtom,
    500
  );

  const setMutedIssueFeedItems = useDebounceSetData(
    {},
    setMutedIssueFeedItemsAtom,
    500
  );

  const stopLoading = useCallback(() => {
    setIsDataLoading(false);
  }, []);

  const setDataLastUpdated = useDebounceLoading(stopLoading, 700);

  const { isTabletSize, isPhoneSize, isLargePhoneSize } =
    useKNOWValueContextProvider();
  const isSmallScreen = isTabletSize || isPhoneSize || isLargePhoneSize;

  const mutedIssueSubscriptionHandler = useCallback(
    (taskId: string, taskFeedItem: TaskFeedItem | null) => {
      setMutedIssueFeedItems((feedItems: any) => {
        setDataLastUpdated(Date.now());
        let newItems;
        if (taskFeedItem) {
          newItems = {
            ...feedItems,
            [taskId]: taskFeedItem,
          };
        } else {
          newItems = omit(feedItems, [taskId]);
        }
        return newItems;
      });
    },
    [setDataLastUpdated, setMutedIssueFeedItems]
  );

  const subScriptionHandler = useCallback(
    (nugId: string, issueFeedItem: TaskFeedItem | null) => {
      setOpenIssueFeedItems((feedItems: any) => {
        setDataLastUpdated(Date.now());
        let newItems;
        if (issueFeedItem) {
          newItems = {
            ...feedItems,
            [nugId]: issueFeedItem,
          };
        } else {
          newItems = omit(feedItems, [nugId]);
        }
        return newItems;
      });
    },
    [setOpenIssueFeedItems, setDataLastUpdated]
  );

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribe = subscribeToUserIssuesFeed(
        currentDBApi,
        subScriptionHandler
      );
      const unSubScribeToMutedIssues = subscribeToUserMutedIssues(
        currentDBApi,
        mutedIssueSubscriptionHandler
      );
      setTimeout(() => setDataLastUpdated(Date.now()), 2000);
      // Return statement has un listening callback.
      return () => {
        unSubScribe();
        unSubScribeToMutedIssues();
        cleanUpSubscribeIssuesNuggets();
      };
    }
    return EMPTY_FUNCTION;
  }, [
    currentDBApi,
    setDataLastUpdated,
    subScriptionHandler,
    mutedIssueSubscriptionHandler,
  ]);

  const columns = useMemo(
    () => [
      {
        id: 'issueId',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            ID
          </Text>
        ),
        accessor: 'issueId', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const id = row?.values?.issueId;
          if (!id) {
            return (
              <Text fontFamily={'tableCell'} fontWeight={400}>
                {''}
              </Text>
            );
          }

          const fontWeight = row?.original?.isRead ? 400 : 700;

          return (
            <Text fontFamily={'tableCell'} fontWeight={fontWeight} mr={'12px'}>
              {id}
            </Text>
          );
        },
      },
      {
        id: 'title',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Title
          </Text>
        ),
        accessor: 'title', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const fontWeight = row?.original?.isRead ? 400 : 700;

          return (
            <Text fontFamily={'tableCell'} fontWeight={fontWeight} mr={'12px'}>
              {row?.values?.title}
            </Text>
          );
        },
        flex: 4,
      },
      {
        id: 'issueType',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Issue Type
          </Text>
        ),
        accessor: 'issueType', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const issueType = row?.values?.issueType;
          return (
            <Text fontFamily={'tableCell'} mr={'12px'} maxW={'100%'}>
              {issueType}
            </Text>
          );
        },
        flex: 2,
        minWidth: 160,
      },
      {
        id: 'severity',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Severity
          </Text>
        ),
        accessor: 'severity', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => (
          <IssueSeverityType type={row?.values?.severity?.toLowerCase()} />
        ),
        enableSorting: false,
        flex: 2,
        minWidth: 110,
        sortType: compareIssueSeverity,
      },
      {
        id: 'authorName',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Reported By
          </Text>
        ),
        accessor: 'authorName', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          // const resolvedAt = row?.values?.resolvedAt;
          return (
            <Text
              fontFamily={'tableCell'}
              fontWeight={400}
              color={'gray.700'}
              mr={'12px'}
            >
              {row?.values?.authorName}
            </Text>
          );
        },
        flex: 2,
      },
      {
        id: 'dateReported',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Date Reported
          </Text>
        ),
        accessor: 'dateReported',
        Cell: ({ row }: { row: any }) => {
          const [day, time] = getDateParts(row?.values?.dateReported);

          return (
            <Text fontFamily={'tableCell'} fontWeight={400} color={'gray.700'}>
              {day}, {time}
            </Text>
          );
        },
        flex: 2,
      },
    ],
    []
  );

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'dateReported', desc: true }],
    }),
    []
  );

  const onClick = useCallback(
    (item: TransformedIssueItem) => {
      if (currentDBApi && !item.isRead) {
        markIssueRead(currentDBApi, 'feed', item.nuggetId);
      }
      // we have to do this to fix a weird bug related to focus ref
      // https://knownuggets.atlassian.net/browse/KNOW-1279
      // 9. From Task list page - filter form A and open it - now tap on complete button - control is not passing to alert ( without filter if i directly open the task means, i can able to complete the task) --> Same for re-open task also from completed tab
      navigation.dispatch(
        StackActions.replace(
          'issues-details' as never,
          {
            issueId: item.nuggetId,
            shareId: item.shareId,
          } as never
        )
      );
    },
    [currentDBApi, navigation]
  );

  return {
    isDataLoading,
    orgIssueLocations,
    filterText,
    filterDateRange,
    filterLocation,
    onFilterTextChange,
    issueItems,
    initialState,
    columns,
    onClick,
    isSmallScreen,
    onFilterDateRangeChange,
    onFilterLocationChange,
  };
};
