import React, { useState, useEffect } from 'react';
import {
  Modal,
  VStack,
  Text,
  useBreakpointValue,
  HStack,
  ExpoFAIcon,
  KNOWLoader,
  Center,
  FlatList,
  Avatar,
} from '@know/ui';
import type { DailyUserUpdateInterface, ShiftWithIdInterface } from './state';
import moment from 'moment';
import { getShiftTimings, getShiftDuration } from './utils';
import { get, map } from 'lodash';

export interface ShiftDetailsModalProps {
  shift?: ShiftWithIdInterface;
  isOpen: boolean;
  closeModal: () => void;
  listenOnDailyUserUpdate: (
    shift: ShiftWithIdInterface,
    cb: (newValue: DailyUserUpdateInterface) => void
  ) => void;
  unListenOnDailyUserUpdate: (shift: ShiftWithIdInterface) => void;
}

export const ShiftDetailsModal = ({
  isOpen,
  shift,
  closeModal,
  listenOnDailyUserUpdate,
  unListenOnDailyUserUpdate,
}: ShiftDetailsModalProps) => {
  const dateFontSize = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '12px',
    lg: '14px',
  });

  const timeFontSize = useBreakpointValue({
    base: '14px',
    sm: '14px',
    md: '16px',
    lg: '18px',
  });

  const detailFontSize = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '14px',
    lg: '16px',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dailyUserUpdate, setDailyUserUpdate] = useState<
    Array<{
      userName: string;
      startTime: number;
      endTime: number;
      userId: string;
    }>
  >([]);

  useEffect(() => {
    if (shift) {
      console.log('Listen');
      setIsLoading(true);
      listenOnDailyUserUpdate(shift, (newValue) => {
        setDailyUserUpdate(
          map(
            newValue,
            (
              update: {
                userName: string;
                startTime: number;
                endTime: number;
              },
              userId: string
            ) => ({ ...update, userId })
          )
        );
        setIsLoading(false);
      });

      return () => unListenOnDailyUserUpdate(shift);
    }

    return;
  }, [shift, listenOnDailyUserUpdate, unListenOnDailyUserUpdate]);

  const avatarSize = useBreakpointValue({
    base: 'xs',
    md: 'xs',
    lg: 'xs',
  });

  return (
    <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick size="xl">
      {shift ? (
        <>
          <Modal.Content maxH="50%" borderBottomRadius="none" borderWidth={0}>
            <Modal.CloseButton />
            <Modal.Body px="20px" pt="20px" flex={1}>
              {isLoading ? (
                <Center>
                  <KNOWLoader />
                </Center>
              ) : (
                <VStack space={1}>
                  <Text
                    fontSize={dateFontSize}
                    color="gray.700"
                    lineHeight={timeFontSize}
                  >
                    {moment(shift.startTime).format('ddd, MMM DD')}
                  </Text>
                  {shift.leaveTypeId ? null : (
                    <Text
                      fontSize={timeFontSize}
                      color="gray.900"
                      fontWeight="500"
                    >
                      {getShiftTimings(shift)}
                    </Text>
                  )}
                  {shift.leaveTypeId ? (
                    <Text
                      fontSize={timeFontSize}
                      color="primary.600"
                      fontWeight={600}
                    >{`${get(shift, 'shiftSlot.slot.name', '-')}`}</Text>
                  ) : (
                    <VStack>
                      <Text
                        isTruncated={true}
                        maxW="100%"
                        fontSize={detailFontSize}
                        color="gray.700"
                      >
                        {get(shift, 'shiftSlot.slot.name', '-')},{' '}
                        {shift.location}
                      </Text>
                      <HStack justifyContent="space-between" w="100%">
                        <HStack alignItems="center">
                          <ExpoFAIcon
                            size={dateFontSize}
                            textAlign="center"
                            name="clock-o"
                            color="primary.500"
                            mr="5px"
                          />
                          <Text
                            isTruncated={true}
                            maxW="100%"
                            color="gray.700"
                            fontSize={dateFontSize}
                          >
                            {getShiftDuration(shift)}
                          </Text>
                        </HStack>
                        {shift.breakDuration ? (
                          <HStack justifyContent="flex-end" alignItems="center">
                            <ExpoFAIcon
                              textAlign="center"
                              size={dateFontSize}
                              name="coffee"
                              color="primary.500"
                              mr="5px"
                            />
                            <Text
                              isTruncated={true}
                              maxW="100%"
                              color="gray.700"
                              fontSize={dateFontSize}
                            >
                              {shift.breakDuration} mins
                            </Text>
                          </HStack>
                        ) : null}
                      </HStack>
                    </VStack>
                  )}
                  {shift.notes ? (
                    <VStack width="100%">
                      <Text fontWeight="600" fontSize={timeFontSize}>
                        Notes
                      </Text>
                      <Text fontSize={detailFontSize}>{shift.notes}</Text>
                    </VStack>
                  ) : null}
                </VStack>
              )}
            </Modal.Body>
          </Modal.Content>
          {isLoading ? null : (
            <Modal.Content maxH="40%" borderTopRadius="none">
              <Modal.Header px="20px" borderWidth={0} mt="-1px" py="10px">
                <Text fontWeight="600" fontSize={timeFontSize}>
                  Staff
                </Text>
              </Modal.Header>
              <Modal.Body px="20px" pb="20px">
                <VStack>
                  <FlatList
                    data={dailyUserUpdate}
                    keyExtractor={(item) => item.userId}
                    renderItem={({ item }) => {
                      const userNames = get(item, 'userName', '').split(' ');
                      return (
                        <HStack alignItems="center" space={3} mb="5px">
                          <Avatar
                            source={{
                              uri: get(item, 'avatar', 'na'),
                            }}
                            size={avatarSize}
                          >
                            {`${get(userNames, '0.0', '').toUpperCase()}${get(
                              userNames,
                              '1.0',
                              ''
                            ).toUpperCase()}`}
                          </Avatar>
                          <VStack>
                            <Text fontWeight="600" fontSize={dateFontSize}>
                              {get(item, 'userName', '')}
                            </Text>
                            {shift.leaveTypeId ? null : (
                              <Text fontSize={dateFontSize}>
                                {getShiftTimings({
                                  startTime: get(item, 'startTime', 0),
                                  endTime: get(item, 'starendTimetTime', 0),
                                })}
                              </Text>
                            )}
                          </VStack>
                        </HStack>
                      );
                    }}
                  />
                </VStack>
              </Modal.Body>
            </Modal.Content>
          )}
        </>
      ) : null}
    </Modal>
  );
};
