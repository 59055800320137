import React from 'react';
import { FlatList, View, Text, Image } from '@know/ui';
import { ViewStyle, Dimensions } from 'react-native';
import { useBreakpointValue } from 'native-base';
import Images from '../Images';
import { useTranslation } from '@know/i18n';

interface KNOWListProps {
  data?: Array<any>;
  component: React.FC<any>;
  ItemSeparatorComponent?: React.FC<any>;
  endReachedThreshold?: number;
  onEndReached?: () => void;
  childDivInfo: React.MutableRefObject<IDivInfo>;
  noOfColumns?: number;
  isCompleted?: boolean;
  uniqueKey?: number;
  contentContainerStyle?: ViewStyle;
  style?: ViewStyle;
}
interface IDivInfo {
  height: null | number;
  width: null | number;
}

export const KNOWList: React.FC<KNOWListProps> = ({
  data,
  component: Component,
  endReachedThreshold,
  onEndReached,
  childDivInfo,
  noOfColumns = 1,
  ItemSeparatorComponent,
  isCompleted,
}) => {
  const { t } = useTranslation('messages');
  const hPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '8px',
    xl: '16px',
    lg: '16px',
  });
  const noRecordText = isCompleted
    ? 'You’ve not completed a course yet.'
    : 'No courses found.';
  const flatListStyles = {
    paddingBottom: '15px',
    paddingHorizontal: (Dimensions.get('window').width - 1400) / 2,
  };
  let onEndReachedCalledDuringMomentum = false;

  return Array.isArray(data) && data.length === 0 ? (
    <View
      h={'100%'}
      bgColor="white"
      flex={1}
      alignContent="center"
      justifyContent="center"
      justifyItems="center"
      paddingTop="24px"
    >
      <View
        alignSelf="center"
        borderRadius="full"
        w={['80px', '80px', '90px', '100px', '100px']}
        h={['80px', '80px', '90px', '100px', '100px']}
        alignItems="center"
        justifyContent="center"
      >
        <Image
          w="full"
          h="full"
          resizeMode="cover"
          source={{
            uri: Images.NoCourses,
          }}
          alt={t('No courses found')}
        />
      </View>
      <View>
        <Text
          color="gray.900"
          fontSize={['18px', '18px', '18px', '20px', '20px']}
        >
          {t(noRecordText)}
        </Text>
      </View>
    </View>
  ) : (
    <FlatList
      contentContainerStyle={{
        paddingHorizontal: hPadding,
      }}
      key={noOfColumns}
      style={flatListStyles}
      data={data}
      numColumns={noOfColumns}
      initialNumToRender={10}
      onMomentumScrollBegin={() => {
        onEndReachedCalledDuringMomentum = false;
      }}
      onEndReached={() => {
        if (!onEndReachedCalledDuringMomentum) {
          onEndReached && onEndReached(); // LOAD MORE DATA
          onEndReachedCalledDuringMomentum = true;
        }
      }}
      onEndReachedThreshold={endReachedThreshold}
      ItemSeparatorComponent={ItemSeparatorComponent}
      keyExtractor={(_evt, index) => `${index}`}
      renderItem={({ item, index }) => (
        <Component
          {...item}
          noOfColumn={noOfColumns}
          childDivInfo={childDivInfo}
          isCompleted={isCompleted}
          index={index}
        />
      )}
    />
  );
};
