import { DataSnapshot } from "firebase/database";
import { FirebaseAPI, UploadFileResultType, raiseNuggetAnalytics } from "..";
import { TaskUpdateType } from "./types";
import { TaskNugget } from "@know/transformers";
import pLimit from "promise-limit";
import { map, uniq } from "lodash";

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

export function subscribeToTaskUpdates(
  fbAPI: FirebaseAPI,
  taskId: string,
  cb: (taskUpdateId: string, taskUpdateItem: TaskUpdateType | null) => void
) {
  const userFeedRef = fbAPI.getNodeRef(
    fbAPI.getMultiPath(`tasks/progress/${taskId}/updates`)
  );

  function listenerHandler(_listenerEvenType: string) {
    return async (taskUpdateSnapshot: DataSnapshot) => {
      const taskUpdate: TaskUpdateType = taskUpdateSnapshot.val() || null;
      const id = taskUpdateSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        return cb(id, null);
      }
      if (taskUpdate) {
        if (taskUpdate.type === "audit_link") {
          const payload = await Promise.all(
            taskUpdate?.payload?.map(async (p) => {
              const {
                formId,
                responseId,
                formName: _formName,
                sno: _sno,
                submitterId,
                submitterName: _submitterName,
              } = p?.value ?? {};
              let formName = _formName;
              let sno = _sno;
              let submitterName = _submitterName;
              if (!formName) {
                formName = await fbAPI.getValue(
                  fbAPI.getNodeRef(
                    `${fbAPI.getCommonPath("formNugget")}/${formId}/name`
                  )
                );
              }
              if (!sno) {
                sno = await fbAPI.getValue(
                  fbAPI.getNodeRef(
                    `${fbAPI.getCommonPath(
                      "formUserResponses"
                    )}/${submitterId}/${formId}/${responseId}/sno`
                  )
                );
              }

              if (!submitterName) {
                submitterName = await fbAPI.getUserFullNameFromFB(submitterId);
              }

              return {
                ...p,
                value: {
                  ...(p.value ?? {}),
                  formName,
                  sno,
                  submitterName,
                },
              };
            })
          );

          cb(id, {
            ...taskUpdate,
            payload,
          });
        } else if (
          taskUpdate.type === "form_link" &&
          taskUpdate.payload?.[0]?.type === "form_draft"
        ) {
          const { formId, draftId, userId } = taskUpdate.payload?.[0]?.value;
          if (userId && userId === fbAPI.getLoggedInUserId()) {
            cb(id, taskUpdate);
          } else {
            const userDraft = await fbAPI.getValue(
              fbAPI.getNodeRef(
                `${fbAPI.getCommonPath(
                  "currentUserFormDrafts"
                )}/${formId}/${draftId}`
              )
            );

            if (userDraft) {
              cb(id, taskUpdate);
            } else {
              cb(id, null);
            }
          }
        } else {
          cb(id, taskUpdate);
        }
      } else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userFeedRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userFeedRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userFeedRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

function updateTaskProgressStatus(
  fbAPI: FirebaseAPI,
  taskId: string,
  status: "notstarted" | "inprogress" | "completed",
  remark?: string
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(`tasks/progress/${taskId}`);
  const payload: any = { issue_status: status };
  if (remark) {
    payload.issue_remark = remark;
  }

  return fbAPI.fbUpdate(taskProgressStatusPath, payload);
}

function addTaskStatusProgressUpdate(
  fbAPI: FirebaseAPI,
  taskId: string,
  status: "reopened" | "inprogress" | "completed",
  options: { remark?: string; userName: string }
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `tasks/progress/${taskId}/updates`
  );
  const payload: any = {
    name: options?.userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "status",
    userId: fbAPI.getLoggedInUserId(),
    payload: [
      {
        type: "text",
        value: status,
        remark: options?.remark ?? null,
      },
    ],
  };

  return fbAPI.pushToNode(taskProgressStatusPath, payload);
}

function updateTaskProgressUserStatus(
  fbAPI: FirebaseAPI,
  taskId: string,
  status: "reopened" | "inprogress" | "completed",
  options: { remark?: string; userName: string }
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `tasks/progress/${taskId}/user_status/${fbAPI.getLoggedInUserId()}`
  );
  const payload: any = {
    name: options?.userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    status,
    remark: options.remark ?? null,
  };

  return fbAPI.fbUpdate(taskProgressStatusPath, payload);
}

export function raiseStatusChangeRequest(
  fbAPI: FirebaseAPI,
  taskId: string,
  status: "reopened" | "started" | "completed" | "updated",
  userName: string
) {
  const statusChangeRequestsNodePath = "statusChangeRequests";
  const payload: any = {
    type: "shared_task",
    status,
    userId: fbAPI.getLoggedInUserId(),
    nuggetId: taskId,
    userName,
    organization: fbAPI.organization,
  };

  return fbAPI.pushToNode(statusChangeRequestsNodePath, payload);
}

function updateTaskNuggetStatus(
  fbAPI: FirebaseAPI,
  taskId: string,
  status: "reopened" | "inprogress" | "completed",
  options?: { userDetails?: any; remark?: string }
) {
  const taskNuggetStatusNodePath = `${fbAPI.getCommonPath(
    "nuggets"
  )}/tasks/${taskId}`;
  let update: any = {
    progress: status,
  };

  if (status === "completed") {
    update = {
      ...update,
      resolvedBy: fbAPI.getLoggedInUserId(),
      resolvedAt: fbAPI.getServerTimestamp(),
      resolvedByUserName: options?.userDetails?.userName ?? "-",
      resolvedByUserDepartment: options?.userDetails?.department ?? "-",
      resolvedByUserDesignation: options?.userDetails?.designation ?? "-",
      comment: options?.remark ?? "-",
    };
  } else if (status === "reopened") {
    update = {
      ...update,
      progress: "notstarted",
      reopenedBy: fbAPI.getLoggedInUserId(),
      reopenedAt: fbAPI.getServerTimestamp(),
      reopenedByUserName: options?.userDetails?.userName ?? "-",
      reopenedByUserDepartment: options?.userDetails?.department ?? "-",
      reopenedByUserDesignation: options?.userDetails?.designation ?? "-",
      reopenedComment: options?.remark ?? "-",
    };
  }

  return fbAPI.fbUpdate(taskNuggetStatusNodePath, update);
}

export async function startTask(
  fbAPI: FirebaseAPI,
  taskId: string,
  userName: string
) {
  await Promise.all([
    updateTaskProgressStatus(fbAPI, taskId, "inprogress"),
    addTaskStatusProgressUpdate(fbAPI, taskId, "inprogress", { userName }),
    updateTaskProgressUserStatus(fbAPI, taskId, "inprogress", { userName }),
    raiseNuggetAnalytics({
      fbAPI,
      type: "started",
      classificationType: "tasks",
      nuggetId: taskId,
    }),
  ]);

  await updateTaskNuggetStatus(fbAPI, taskId, "inprogress");
  await raiseStatusChangeRequest(fbAPI, taskId, "started", userName)
}

const addTaskToCompletedList = (
  fbAPI: FirebaseAPI,
  taskId: string,
  nugget: TaskNugget
) => {
  const update = {
    author: nugget.author ?? "-",
    classificationType: "tasks",
    createdAt: nugget.createdAt ?? fbAPI.getServerTimestamp(),
    refreshedAt: fbAPI.getServerTimestamp(),
    consumedAt: fbAPI.getServerTimestamp(),
  };
  const completedTaskPath = `${fbAPI.getMultiPath(
    `finishedNewTasks/${fbAPI.getLoggedInUserId()}/${taskId}`
  )}`;

  return fbAPI.fbSet(completedTaskPath, update);
};

const removeTaskFromFeed = (fbAPI: FirebaseAPI, taskId: string) => {
  const userFeedTaskPath = `${fbAPI.getCommonPath(
    "currentUserFeed"
  )}/tasks/${taskId}`;

  return fbAPI.fbSet(userFeedTaskPath, null);
};

export function addTaskStatusProgressAttachment(
  fbAPI: FirebaseAPI,
  taskId: string,
  type: "image" | "video" | "audio" | "pdf",
  files: UploadFileResultType[],
  userName: string,
  cacheCB?: (updateId: string, taskUpdateItem: TaskUpdateType | null) => any
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `tasks/progress/${taskId}/updates`
  );
  const payload: any = {
    name: userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: type,
    userId: fbAPI.getLoggedInUserId(),
    payload: files.map((f) => ({
      ...f,
      type,
      pdfTitle: type === "pdf" ? f.filename : null,
    })),
  };

  const updateRef = fbAPI.pushToNode(taskProgressStatusPath);

  const updateId = updateRef.key;

  if (updateId) {
    cacheCB?.(updateId, {
      ...payload,
      timestamp: Date.now(),
      isSending: true,
    });
  }
  return fbAPI.set(updateRef, payload);
}

export async function completeTask(
  fbAPI: FirebaseAPI,
  taskId: string,
  userDetails: any,
  nugget: TaskNugget,
  { remark, image }: { remark: string; image?: UploadFileResultType }
) {
  const userName = fbAPI.getUserFullNameOrAlias(userDetails) || "-";

  await Promise.all([
    updateTaskProgressStatus(fbAPI, taskId, "completed", remark),
    addTaskStatusProgressUpdate(fbAPI, taskId, "completed", {
      userName,
      remark,
    }),
    updateTaskProgressUserStatus(fbAPI, taskId, "completed", {
      userName,
      remark,
    }),
    addTaskToCompletedList(fbAPI, taskId, nugget),
    removeTaskFromFeed(fbAPI, taskId),
    raiseNuggetAnalytics({
      fbAPI,
      type: "consumed",
      classificationType: "tasks",
      nuggetId: taskId,
    }),
    image
      ? addTaskStatusProgressAttachment(
          fbAPI,
          taskId,
          "image",
          [image],
          userName
        )
      : null,
  ]);

  await updateTaskNuggetStatus(fbAPI, taskId, "completed", {
    userDetails: {
      ...userDetails,
      userName,
    },
    remark,
  });

  await raiseStatusChangeRequest(fbAPI, taskId, "completed", userName)
}

export async function reopenTask(
  fbAPI: FirebaseAPI,
  taskId: string,
  userDetails: any,
  { remark, image }: { remark: string; image?: UploadFileResultType }
) {
  const userName = fbAPI.getUserFullNameOrAlias(userDetails) || "-";

  await Promise.all([
    updateTaskProgressStatus(fbAPI, taskId, "notstarted", remark),
    addTaskStatusProgressUpdate(fbAPI, taskId, "reopened", {
      userName,
      remark,
    }),
    updateTaskProgressUserStatus(fbAPI, taskId, "reopened", {
      userName,
      remark,
    }),
    image
      ? addTaskStatusProgressAttachment(
          fbAPI,
          taskId,
          "image",
          [image],
          userName
        )
      : null,
  ]);

  await updateTaskNuggetStatus(fbAPI, taskId, "reopened", {
    userDetails: {
      ...userDetails,
      userName,
    },
    remark,
  });

  await raiseStatusChangeRequest(fbAPI, taskId, "reopened", userName)
}

export function addTaskProgressFormDraft(
  fbAPI: FirebaseAPI,
  taskId: string,
  formId: string,
  formName: string,
  responseId: string,
  userName: string
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `tasks/progress/${taskId}/updates/${responseId}`
  );
  const payload: any = {
    name: userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "form_link",
    userId: fbAPI.getLoggedInUserId(),
    payload: [
      {
        type: "form_draft",
        value: {
          draftId: responseId,
          formId,
          name: formName,
          userId: fbAPI.getLoggedInUserId(),
        },
      },
    ],
  };

  return fbAPI.fbSet(taskProgressStatusPath, payload);
}

export function removeTaskProgressUpdate(
  fbAPI: FirebaseAPI,
  taskId: string,
  updateId: string
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `tasks/progress/${taskId}/updates/${updateId}`
  );

  return fbAPI.fbSet(taskProgressStatusPath, null);
}

export function updateTaskNuggetFormSubmitStatus(
  fbAPI: FirebaseAPI,
  taskId: string,
  isSubmitted: boolean
) {
  const taskNuggetStatusNodePath = `${fbAPI.getCommonPath(
    "nuggets"
  )}/tasks/${taskId}/isFormSubmitted`;

  return fbAPI.fbSet(taskNuggetStatusNodePath, isSubmitted);
}

export function addTaskProgressRemark(
  fbAPI: FirebaseAPI,
  taskId: string,
  text: string,
  userName: string,
  cacheCB?: (updateId: string, taskUpdateItem: TaskUpdateType | null) => any
) {
  const taskProgressStatusPath = fbAPI.getMultiPath(
    `tasks/progress/${taskId}/updates`
  );
  const payload: any = {
    name: userName ?? "-",
    timestamp: fbAPI.getServerTimestamp(),
    type: "remark",
    userId: fbAPI.getLoggedInUserId(),
    payload: [
      {
        type: "text",
        value: text,
      },
    ],
  };

  const updateRef = fbAPI.pushToNode(taskProgressStatusPath);

  const updateId = updateRef.key;

  if (updateId) {
    cacheCB?.(updateId, {
      ...payload,
      timestamp: Date.now(),
      isSending: true,
    });
  }

  return fbAPI.set(updateRef, payload);
}

export async function getTaskUsers(fbAPI: FirebaseAPI, taskId: string) {
  const path = fbAPI.getMultiPath(`tasks/shareLogs/${taskId}`);

  const taskShareLogs = await fbAPI.getValue(fbAPI.getNodeRef(path));

  const taskUserIds = uniq(map(taskShareLogs, "userId"));

  const limit = pLimit<any>(20);

  return Promise.all(
    taskUserIds.map((userId) => limit(() => fbAPI.getUserDetails(userId)))
  );
}
