import {
  Box,
  Divider,
  FormControl,
  HStack,
  IconButton,
  Input,
  Text,
} from 'native-base';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SendIcon } from '../Icons';
import { EllipsisMenuOption } from '../EllipsisMenu';
import { ExpoEntypoIcon, ExpoMCIcon } from '../ExpoIcon';
//using Menu from MUI since menu from Nativebase has a weird bug where it will jump randomly
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './index.css';

const InputWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 13px 27px -5px rgba(138, 165, 163, 0.1),
    0px 8px 16px -8px rgba(59, 121, 121, 0.15);
`;

interface TaskRemarkInput {
  onSend: (text: string) => any;
  menuOptions?: EllipsisMenuOption[][];
}

export const TaskRemarkInput = ({ onSend, menuOptions }: TaskRemarkInput) => {
  const [value, setValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const inputRef = useRef<any>(null);

  const isValid = useMemo(() => {
    return value.trim();
  }, [value]);

  const onClickSend = useCallback(() => {
    if (isValid) {
      setValue('');
      onSend?.(value);
      // setTimeout(() => {
      //   inputRef.current?.focus?.();
      // }, 100);
    }
  }, [onSend, value, isValid]);

  return (
    <InputWrapper>
      <FormControl>
        <Input
          // ref={inputRef}
          value={value}
          onChangeText={setValue}
          borderColor={'gray.300'}
          borderRadius={'8px'}
          bg={'white'}
          focusOutlineColor={'gray.300'}
          _focus={{
            bg: 'white',
            borderWidth: '1px',
            borderColor: 'gray.300',
          }}
          _hover={{
            backgroundColor: 'white',
            borderColor: 'gray.300',
          }}
          paddingLeft={'6px'}
          placeholder="Add a remark"
          fontSize={'14px'} //applied to both input val and placeholder
          onSubmitEditing={onClickSend}
          InputRightElement={
            <IconButton
              my={'6px'}
              mr={'6px'}
              icon={<SendIcon size="20px" />}
              p={'6px'}
              bg={value ? 'primary.500' : 'white'}
              isDisabled={!isValid}
              disabled={!isValid}
              borderRadius={'6px'}
              _icon={{
                color: value ? 'white' : 'gray.900',
              }}
              _hover={{
                bg: value ? 'primary.700' : 'white',
              }}
              onPress={onClickSend}
            />
          }
          InputLeftElement={
            menuOptions ? (
              <Box position={'relative'}>
                <IconButton
                  my={'6px'}
                  ml={'6px'}
                  icon={
                    open ? (
                      <ExpoMCIcon name="close-circle" size={'20px'} />
                    ) : (
                      <ExpoEntypoIcon name="attachment" size={'20px'} />
                    )
                  }
                  p={'6px'}
                  _icon={{
                    color: 'gray.700',
                  }}
                  _hover={{
                    bg: 'gray.100',
                  }}
                  onPress={(e) => setAnchorEl(e.currentTarget)}
                />
                {/* <Menu
                placement="top"
                onOpen={() => setIsMenuOpen(true)}
                onClose={() => setIsMenuOpen(false)}
                py={'4px'}
                trigger={(triggerProps) => {
                  return (
                    <IconButton
                      my={'6px'}
                      ml={'6px'}
                      icon={
                        isMenuOpen ? (
                          <ExpoMCIcon name="close-circle" size={'20px'} />
                        ) : (
                          <ExpoEntypoIcon name="attachment" size={'20px'} />
                        )
                      }
                      p={'6px'}
                      _icon={{
                        color: 'gray.700',
                      }}
                      _hover={{
                        bg: 'gray.100',
                      }}
                      onPress={e => e.currentTarget}
                      {...triggerProps}
                    />
                  );
                }}
              >
                {menuOptions.map((options, index) => {
                  return (
                    <React.Fragment key={index}>
                      {options.map(({ id, label, onClick, Icon }) => (
                        <Menu.Item onPress={onClick} key={id}>
                          <HStack alignItems={'center'} mx={'-12px'}>
                            {Icon}
                            <Text ml={'8px'}>{label}</Text>
                          </HStack>
                        </Menu.Item>
                      ))}
                      {index === menuOptions.length - 1 ? null : <Divider />}
                    </React.Fragment>
                  );
                })}
              </Menu> */}
              </Box>
            ) : undefined
          }
        />
      </FormControl>
      {menuOptions?.length ? (
        <Menu
          id="task-remark-add-attachment"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'add-attachment',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {menuOptions.map((options, index) => {
            return (
              <React.Fragment key={index}>
                {options.map(({ id, label, onClick, Icon }) => (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onClick?.();
                    }}
                    key={id}
                  >
                    <HStack alignItems={'center'}>
                      {Icon}
                      <Text ml={'8px'}>{label}</Text>
                    </HStack>
                  </MenuItem>
                ))}
                {index === menuOptions.length - 1 ? null : <Divider />}
              </React.Fragment>
            );
          })}
        </Menu>
      ) : null}
    </InputWrapper>
  );
};
