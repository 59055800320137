import { get } from 'lodash';
import {
  Avatar,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useTheme,
  VStack,
} from 'native-base';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';

import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer';
import {
  NavigationContainer,
  NavigationState,
  useNavigation,
  EventArg,
} from '@react-navigation/native';

import { ExpoFAIcon, KNOWHeader, KNOWLogoWithNameIcon } from '../Components';
import { WithKNOWLoader } from '../Components/Loader';
import { Logo } from '../Components/Logo';
import { KNOWDBContext } from '../KNOWProvider/DBProvider';
import { useRoutes } from './useRoutes';

import type { ViewStyle } from 'react-native';
export { useNavigation } from '@react-navigation/native';

import type {
  DrawerNavigationOptions,
  DrawerContentComponentProps,
  // DrawerScreenProps,
} from '@react-navigation/drawer';
import { OUTER_MOST_NAVIGATION } from '@know/constants';
import { transformLinkProps } from './transformLinkProps';
import { useKNOWValueContextProvider } from '..';
import { useTranslation } from '@know/i18n';

const Drawer = createDrawerNavigator();

interface CustomDrawerContentProps extends DrawerContentComponentProps {
  version: string;
  isMobileApp: boolean;
}

const getActiveRouteState = function (route: NavigationState): NavigationState {
  if (
    !route.routes ||
    route.routes.length === 0 ||
    route.index >= route.routes.length
  ) {
    return route;
  }

  const childActiveRoute: any = route.routes?.[route.index];
  return getActiveRouteState(childActiveRoute);
};

const CustomDrawerContent = (_props: CustomDrawerContentProps) => {
  const { t } = useTranslation('menus');

  const { userDetails, orgDetails, signUserOut } = useContext(KNOWDBContext);
  const { version, isMobileApp, ...props } = _props;

  const onNavigate = {
    dispatch: (event: any) => {
      let routeName = '';
      if (event?.type === 'CLOSE_DRAWER' && !event.hasOwnProperty('payload')) {
        const route: any = getActiveRouteState(_props.navigation.getState());
        routeName = route?.name;
      } else {
        routeName = event?.payload?.name;
      }
      if (routeName) {
        return props.navigation.reset({
          index: 0,
          routes: [{ name: routeName }],
        });
      }
      return props.navigation.closeDrawer();
    },
  };

  const icon = ({ color }: any) => (
    <ExpoFAIcon name="sign-out" color={color} size="xs" />
  );

  const handleSignOut = useCallback(() => {
    (async () => {
      await signUserOut();
    })();
  }, [signUserOut]);

  return (
    <Flex flex="1" justifyContent="space-between">
      <VStack alignItems="flex-start" mx="10px" my="20px">
        <Avatar
          source={{
            uri: get(userDetails, 'avatar', 'na'),
          }}
          size="md"
          mb="10px"
        >
          {`${get(userDetails, 'firstName.0', '').toUpperCase()}${get(
            userDetails,
            'lastName.0',
            ''
          ).toUpperCase()}`}
        </Avatar>
        <Heading
          fontSize="xl"
          color="gray.700"
          fontWeight="600"
          isTruncated
          maxW="100%"
        >
          {get(userDetails, 'firstName', '')} {get(userDetails, 'lastName', '')}
        </Heading>
        <Text
          color="gray.700"
          fontWeight="600"
          fontSize={isMobileApp ? 'xs' : '14px'}
          isTruncated
          maxW="100%"
        >
          {get(orgDetails, 'name', '')}
        </Text>
        <Text
          color="gray.700"
          fontSize={isMobileApp ? 'xs' : '14px'}
          isTruncated
          maxW="100%"
        >
          {get(userDetails, 'designation', '')},{' '}
          {get(userDetails, 'department', '')}
        </Text>
      </VStack>
      <DrawerContentScrollView {...props}>
        {/* @ts-ignore */}
        <DrawerItemList {...props} navigation={onNavigate} />
      </DrawerContentScrollView>
      <Divider bgColor="#C4CAC9" width="100%" />
      <VStack mb="30px">
        <DrawerItem
          label={t('Sign Out')}
          // eslint-disable-next-line react-native/no-inline-styles
          labelStyle={{ fontFamily: 'Inter_400Regular' }}
          icon={icon}
          onPress={handleSignOut}
          inactiveTintColor="gray.700"
        />
      </VStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        mx="10px"
        my="20px"
      >
        <KNOWLogoWithNameIcon size="90px" />
        <Text color="gray.700" fontSize="xs">
          {t(`v${version}`)}
        </Text>
      </HStack>
    </Flex>
  );
};

const screenOptions = (
  backgroundColor: string,
  color: string,
  // drawerType: 'front' | 'back' | 'slide' | 'permanent',
  drawerWidth: ViewStyle,
  headerFontSize: number,
  isMobileApp: boolean
): DrawerNavigationOptions => ({
  drawerStyle: {
    ...drawerWidth,
    backgroundColor,
  },
  drawerContentStyle: {
    margin: 0,
  },
  drawerItemStyle: {
    margin: 0,
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  drawerLabelStyle: {
    fontWeight: '600',
    fontSize: isMobileApp ? 14 : 16,
    marginLeft: '-20px',
    fontFamily: 'Inter_400Regular',
  },
  drawerActiveTintColor: '#00C1B2',
  drawerActiveBackgroundColor: 'rgba(0, 193, 178, 0.1)',
  drawerInactiveTintColor: '#4E5656',
  overlayColor: '#ffffff00',
  headerStyle: {
    backgroundColor: '#fff',
  },
  drawerType: 'front',
  headerTintColor: color,
  headerTitleAlign: 'center',
  headerTitleStyle: {
    fontWeight: '400',
    fontSize: headerFontSize,
    alignSelf: 'center',
    fontFamily: 'Inter',
  },
  unmountOnBlur: true,
});

export const KNOWRoutes = ({
  version,
  showHeader = true,
  examples = false,
}: {
  version: string;
  showHeader?: boolean;
  examples?: boolean;
}): ReactElement => {
  const { userRoutes, servingFrom = '' } = useRoutes(showHeader);
  const { colors } = useTheme();
  // const [media] = useMediaQuery({ maxWidth: 320 });
  const { isPhoneSize, isLargePhoneSize } = useKNOWValueContextProvider();

  const { t } = useTranslation('menus');

  const isMobileApp = isPhoneSize || isLargePhoneSize;

  const { isLoadingConfig, signUserOut, userDetails } =
    useContext(KNOWDBContext);

  const CustomDrawerWrapper = useMemo(() => {
    return (props: DrawerContentComponentProps) =>
      showHeader ? (
        <CustomDrawerContent
          {...props}
          version={version}
          isMobileApp={isMobileApp}
        />
      ) : (
        <></>
      );
  }, [isMobileApp, version, showHeader]);

  const headerFontSize = useBreakpointValue({
    base: '18px',
    md: '20px',
    lg: '22px',
  });

  const drawerWidth: ViewStyle = useBreakpointValue({
    base: { maxWidth: 200 },
    sm: { maxWidth: 200 },
    md: { maxWidth: 250 },
    lg: { maxWidth: 250 },
  });

  const titleColor = useColorModeValue(
    colors.primary['500'],
    colors.dark['500']
  );
  const drawerBackgroundColor = 'white';

  if (userRoutes.length === 0) {
    return (
      <Center
        flex="1"
        position="relative"
        bg={isLoadingConfig ? 'white' : 'primary.500'}
      >
        <VStack space={4} alignItems="center">
          <WithKNOWLoader isLoading={isLoadingConfig} size="lg">
            <Logo />
            <Link
              onPress={signUserOut}
              _text={{ color: 'white', fontWeight: 600 }}
              mt="12px"
            >
              {t('Sign out')}
            </Link>
          </WithKNOWLoader>
        </VStack>
      </Center>
    );
  }
  const linking = {
    prefixes: [
      'https://knownuggets.com',
      'know://',
      'https://app.knownuggets.com',
    ],
    config: {
      screens: {
        ...userRoutes.reduce(
          (acc, route) => ({
            ...acc,
            [route.name]: route.linking
              ? transformLinkProps(route.linking, servingFrom)
              : `/${route.id}`,
          }),
          {}
        ),
        // NotFound: '*',
      },
    },
  };

  return (
    <WithKNOWLoader isLoading={isLoadingConfig && examples === false} size="lg">
      <NavigationContainer linking={linking}>
        <Drawer.Navigator
          //@ts-ignore
          id={OUTER_MOST_NAVIGATION}
          drawerContent={CustomDrawerWrapper}
          screenOptions={screenOptions(
            drawerBackgroundColor,
            titleColor,
            // drawerType,
            drawerWidth,
            headerFontSize,
            isMobileApp
          )}
        >
          {userRoutes.map((route) => (
            <Drawer.Screen
              key={route.id}
              name={route.name}
              component={route.component}
              options={{
                headerStyle: {
                  elevation: 5,
                  shadowOpacity: 1,
                },
                title: route.name,
                drawerIcon: ({ color }) => (
                  <ExpoFAIcon name={route.icon} color={color} size="xs" />
                ),
                header: (props) =>
                  showHeader ? <KNOWHeader {...props} /> : <></>,
                headerTitle:
                  route.id === 'attendanceQR'
                    ? `${t(route.name)} ${
                        userDetails?.location
                          ? `(${userDetails?.location})`
                          : ''
                      }`
                    : t(route.name),
              }}
            />
          ))}
          {/* <Drawer.Screen name="NotFound" component={NotFoundScreen} /> */}
        </Drawer.Navigator>
      </NavigationContainer>
    </WithKNOWLoader>
  );
};

export const getOuterMostNavigations = (navigation: any) => {
  return navigation.getParent(OUTER_MOST_NAVIGATION);
};

export const useHideHeader = () => {
  const navigation = useNavigation();

  useLayoutEffect(() => {
    if (!navigation) return;

    const outerMostNavigator = getOuterMostNavigations(navigation);

    let timeoutId: NodeJS.Timeout;
    if (outerMostNavigator) {
      outerMostNavigator.setOptions({
        headerShown: false,
      });

      // need to do this if we are navigating between 2 screens that both hide header
      timeoutId = setTimeout(() => {
        outerMostNavigator.setOptions({
          headerShown: false,
        });
      }, 1000);
    }

    // Turn header back on when unmount
    return outerMostNavigator
      ? () => {
          clearTimeout(timeoutId);
          outerMostNavigator.setOptions({
            headerShown: true,
          });
        }
      : undefined;
  }, [navigation]);
};

export const useOnScreenBlur = (
  cb: (event: EventArg<'blur', undefined, undefined>) => void
) => {
  const navigation = useNavigation();

  useEffect(() => {
    const unsub = navigation.addListener('blur', cb);

    return unsub;
  }, [navigation, cb]);
};

export const useWebOnVisibilityHidden = (cb: () => void) => {
  useEffect(() => {
    const handler = () => {
      if (document.visibilityState === 'hidden') {
        cb();
      }
    };
    document.addEventListener('visibilitychange', handler);

    return () => document.removeEventListener('visibilitychange', handler);
  }, [cb]);
};

export const useWebOnBeforeUnload = (isEditing: boolean) => {
  useEffect(() => {
    if (isEditing) {
      const handler = (event: BeforeUnloadEvent) => {
        const confirmationMessage = 'Are you sure you want to exit?';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      };
      window.addEventListener('beforeunload', handler);

      return () => window.removeEventListener('beforeunload', handler);
    }
  }, [isEditing]);
};

export type OnScreenBeforeRemoveEvent = EventArg<
  'beforeRemove',
  true,
  {
    action: Readonly<{
      type: string;
      payload?: object | undefined;
      source?: string | undefined;
      target?: string | undefined;
    }>;
  }
>;

export const useOnScreenBeforeRemove = (
  cb: (event: OnScreenBeforeRemoveEvent) => void
) => {
  const navigation = useNavigation();

  useEffect(() => {
    const unsub = navigation.addListener('beforeRemove', cb);

    return unsub;
  }, [navigation, cb]);
};
