import React, { useState } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  Button,
  PresenceTransition,
} from '@know/ui';
import type { ISurveyQuestion } from '../types';
import Images from '../../../../Images/index';
import { Image as RNImage, StyleSheet } from 'react-native';

const INIT_DIMENSIONS = {
  width: '100%',
  height: '100%',
};
interface Iprops {
  index: string;
  data: ISurveyQuestion;
  answer: any;
}

export const NpsRatings = ({
  data,
  handleProceedButton,
  setResponse,
  sectionId,
}: {
  data: Iprops;
  handleProceedButton: (questionIndex?: string) => void;
  setResponse: (value: any) => void;
  sectionId: string | null;
}) => {
  const [parentDivInfo, setParentDivInfo] = useState(INIT_DIMENSIONS);
  const [selectedRating, setSelectedRating] = useState<{
    index: number | null;
    item: number | null;
  }>({
    index: null,
    item: null,
  });

  const getSectionColor = (value: number) => {
    if (value < 7) {
      return '#E53554';
    } else if (value < 9) {
      return '#E88B00';
    } else if (value < 11) {
      return '#0EBF75';
    }
    return 'white';
  };

  const getImagePath = () => {
    if (selectedRating.item !== null && selectedRating.item < 7) {
      return Images.SadEmoji;
    } else if (selectedRating.item !== null && selectedRating.item < 9) {
      return Images.NeutralEmoji;
    } else if (selectedRating.item !== null && selectedRating.item < 11) {
      return Images.HappyEmoji;
    }
  };

  const styles = StyleSheet.create({
    emojiImage: { height: '70%', width: '70%' },
  });

  return (
    <View
      padding="15px"
      flex="1"
      onLayout={(event: any) => {
        let { width, height } = event.nativeEvent.layout;

        setParentDivInfo({ width, height });
      }}
    >
      <View
        h="40%"
        w="100%"
        maxHeight="259px"
        alignItems="center"
        justifyContent="center"
      >
        {selectedRating.item === null ? (
          <Image
            h="100%"
            w="100%"
            source={Images.NPSIllustration}
            // style={{ height: '80%', width: '80%' }}
            resizeMode="contain"
          />
        ) : (
          <RNImage
            style={styles.emojiImage}
            source={getImagePath()}
            resizeMode="contain"
          />
        )}
      </View>
      <Text fontWeight="semibold" fontSize="18px" marginY="12px">
        {data.data.text}
      </Text>
      <View flexDirection={'row'} alignItems="center">
        {[...Array(11).keys()].map((item, index) => {
          return (
            <Pressable
              onPress={() => {
                setSelectedRating({
                  index,
                  item,
                });
              }}
              zIndex={item !== selectedRating.item ? -1 : 0}
            >
              <PresenceTransition
                visible={item === selectedRating.item}
                initial={{
                  opacity: 1,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  scale: 1.3,
                  transition: {
                    duration: 250,
                  },
                }}
              >
                <View
                  w={+parentDivInfo.width / 11 - 6 / 2}
                  h="50px"
                  borderRightWidth="1px"
                  borderLeftWidth={item === 0 ? '1px' : 0}
                  borderBottomWidth="1px"
                  borderTopWidth="1px"
                  alignSelf="center"
                  alignItems="center"
                  justifyContent="center"
                  borderColor={
                    item === selectedRating.item ? 'transparent' : 'gray.300'
                  }
                  zIndex={item === selectedRating.item ? 2 : -1}
                  borderLeftRadius={
                    item === 0 || item === selectedRating.item ? '6px' : 0
                  }
                  borderRightRadius={
                    item === 10 || item === selectedRating.item ? '6px' : 0
                  }
                  borderRadius={item === selectedRating.item ? '6px' : 0}
                  bgColor={
                    item === selectedRating.item
                      ? getSectionColor(item)
                      : 'transparent'
                  }
                  position="static"
                >
                  <Text
                    color={selectedRating.item === item ? 'white' : 'gray.500'}
                    fontSize="16px"
                  >
                    {item}
                  </Text>
                </View>
              </PresenceTransition>
            </Pressable>
          );
        })}
      </View>
      {data.data.rating && (
        <View
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="10px"
        >
          <Text fontSize="16px" color="#E90202" fontWeight={600}>
            {data.data?.rating?.labelMinRating}
          </Text>
          <Text fontSize="16px" color="#0EBF75" fontWeight={600}>
            {data.data?.rating?.labelMaxRating}
          </Text>
        </View>
      )}
      <Button
        marginTop="16px"
        size={'sm'}
        onPress={() => {
          setResponse((prevState: any) => {
            const state = [...prevState];
            state.push({
              question: data.index,
              response: selectedRating.item?.toString(),
              sectionID: sectionId,
            });
            return state;
          });
          handleProceedButton(selectedRating.index?.toString());
          setSelectedRating({
            index: null,
            item: null,
          });
        }}
        disabled={!selectedRating.item?.toString()}
        opacity={!selectedRating.item?.toString() ? 0.3 : 1}
      >
        <Text fontWeight={'semibold'} color={'white'}>
          Proceed
        </Text>
      </Button>
    </View>
  );
};
