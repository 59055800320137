import React, { useMemo, useState } from 'react';
import {
  SectionList,
  Flex,
  // Dimensions,
  Checkbox,
  NoDataForms,
  Box,
  Text,
} from '@know/ui';
import { InputFilter } from '../../InputFilter';
import { useSelectableSectionsView } from '..';

// const headerHeight = 52;

const SectionSeparatorComponent = (props: any) => {
  return !!props.leadingItem && !!props.trailingSection ? (
    <Box h={'12px'} />
  ) : null;
};

export function SectionsListView() {
  const {
    onFilterChange,
    hasFilter = true,
    placeholder,
    data,
    // additionalHeaderHeight = 0,
    keyExtractor,
    renderSectionHeader,
    renderItem,
    onChangeSelectedItems,
    hasFilterText,
    ...rest
  } = useSelectableSectionsView();

  // const windowHeight = Dimensions.get('window').height;
  // const scrolViewHeight = windowHeight - headerHeight - additionalHeaderHeight;
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const isDataEmpty = useMemo(() => {
    return !data.reduce((acc: any[], item) => [...acc, ...item.data], [])
      .length;
  }, [data]);

  return (
    <Flex direction="column" flex={1}>
      <InputFilter
        hasFilter={hasFilter}
        onChange={onFilterChange}
        placeholder={placeholder}
      />
      {!hasFilterText && isDataEmpty ? <NoDataForms /> : null}
      {hasFilterText && isDataEmpty ? (
        <Box
          my={'24px'}
          w={'100%'}
          display={'flex'}
          flexDir={'row'}
          justifyContent={'center'}
        >
          <Text textAlign={'center'}>No results found.</Text>
        </Box>
      ) : null}
      {!isDataEmpty ? (
        <Box w={'100%'} flex={1} mt={'6px'}>
          <Checkbox.Group
            defaultValue={selectedItems}
            onChange={handleOnChangeSelectedItems}
            accessibilityLabel="To Select multiple items"
            flex={1}
          >
            <SectionList
              w={'full'}
              mb="4"
              ml="2"
              sections={data}
              keyExtractor={keyExtractor}
              renderSectionHeader={renderSectionHeader}
              renderItem={renderItem}
              removeClippedSubviews
              SectionSeparatorComponent={SectionSeparatorComponent}
              {...(rest as any)}
            />
          </Checkbox.Group>
        </Box>
      ) : null}
    </Flex>
  );

  function handleOnChangeSelectedItems(newSelectedItems?: any[]) {
    onChangeSelectedItems && onChangeSelectedItems(newSelectedItems || []);
    setSelectedItems(newSelectedItems || []);
  }
}
