import React, { useEffect, useState } from 'react';
import {
  View,
  Topbar,
  FormControl,
  Box,
  Input,
  TypeAndSearchFieldWithModal,
  Text,
  Checkbox,
  TextArea,
  Divider,
  UploadImageIcon,
  KNOWButton as Button,
  AttachImageIcon,
  AttachVideoIcon,
  UploadVideoIcon,
  AttachPDFIcon,
  Pressable,
  UploadImageModal,
  UploadVideoModal,
  UploadFileHandler,
  UploadProgressModal,
  ImageCard,
  VideoCard,
  PDFFileCard,
  FormWebview,
  WithKNOWLoader,
  LocationPermissionModal,
  SpinnerModal,
  ExpoADIcon,
  UploadPDFIcon,
  ExpoIonicIcon,
  DeleteAttachmentIcon,
  ScrollView,
} from '@know/ui';
import { issueSeverities, useCreateIssue } from './useCreateIssue';
import { useFormWebView } from '../../Forms/FormWebViewPage/useFormWebview';

export const linking = {
  path: 'issues/create',
};

export const CreateIssuePage = () => {
  const {
    issueTypesOptions,
    fieldValues,
    formId,
    errors,
    fieldsTouched,
    locationsOptions,
    isNotifyLeadersChecked,
    defaultNotifyLeaders,
    isTabletSize,
    isPhoneSize,
    isLargePhoneSize,
    isUploading,
    uploadProgress,
    uploadProgressProps,
    isUploadImageModalOpen,
    isUploadVideoModalOpen,
    uploadFileHandlerRef,
    uploadedImages,
    uploadedPDFFiles,
    uploadedVideos,
    allowedImagesLimit,
    allowedVideosLimit,
    allowedPDFFilesLimit,
    showSpinnerModal,
    isTryingToSubmit,
    scrollViewRef,
    webviewHeight,
    onFieldUpdate,
    onFieldUpdateWithDebounce,
    onFieldTouched,
    onAddImages,
    onAddVideos,
    onAddFiles,
    onClickUploadImage,
    onClickUploadVideo,
    onClickUploadFile,
    closeUploadImageModal,
    closeUploadVideoModal,
    onDeleteImage,
    deleteAllImages,
    onDeleteVideo,
    onDeletePDFFile,
    onSubmit,
    onFormWebviewSubmitStatus,
    onFormSubmission,
    setScrollViewHeight,
    setWebviewHeight,
    goBack,
  } = useCreateIssue();

  const {
    formNugget,
    isLoading: isFormNuggetLoading,
    buttonLabels,
    locations,
    userInfo,
    webViewUrl,
    formWebViewRef,
    reviewMode,
    formResponse,
    isLocationPermissionModalOpen,
    locationPermissionModalProps,
    uploadFiles,
    submitResponse,
    writeResponses,
    searchUsers,
    sendToNextResponse,
    onDataWrite,
  } = useFormWebView(formId, {
    type: 'new',
    skipDraft: true,
  });

  const [containerWidth, setContainerWidth] = useState<number>(0);

  // reset the webview height so it will give minimum height needed.
  useEffect(() => {
    setWebviewHeight(0);
  }, [formId, setWebviewHeight]);

  return (
    <View bg={'white'} position={'relative'} h={'100vh'}>
      <Topbar
        goBack={goBack}
        title="Report An Issue"
        actionButton={
          <Button
            colorScheme="primary"
            onPress={() => onSubmit(formWebViewRef.current)}
            isDisabled={isTryingToSubmit}
            disabled={isTryingToSubmit}
          >
            Report Issue
          </Button>
        }
      />
      <ScrollView
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        bg={'white'}
        position={'relative'}
        w={'100%'}
        p={'16px'}
        overflow={'auto'}
        ref={scrollViewRef}
        onContentSizeChange={(_, h) => {
          setScrollViewHeight(h);
        }}
      >
        <View
          display={'flex'}
          flexDirection={'column'}
          bg={'white'}
          position={'relative'}
          maxW={'940px'}
          w={'100%'}
          mx={'auto'}
        >
          <FormControl
            w="100%"
            isRequired
            isInvalid={errors.issueType && fieldsTouched.issueType}
          >
            <FormControl.Label m={0} mb={'6px'}>
              Issue Type
            </FormControl.Label>
            <TypeAndSearchFieldWithModal
              options={issueTypesOptions}
              value={fieldValues?.issueType}
              placeholder="Select Issue Type"
              title="Select Issue Type"
              onChange={(newValue) => onFieldUpdate('issueType', newValue)}
              getDisplayValue={(item) => (
                <Box>
                  <Text>{item.name}</Text>
                </Box>
              )}
              getItemComponent={(item, { isHovered, isSelected } = {}) => (
                <Box
                  bgColor={
                    isSelected
                      ? 'primary.500:alpha.20'
                      : isHovered
                      ? 'gray.200'
                      : 'white'
                  }
                  w={'100%'}
                  h={'100%'}
                  p="12px"
                  display={'flex'}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text>{item.name}</Text>
                  {isSelected ? (
                    <ExpoIonicIcon
                      name="checkmark-sharp"
                      color={'primary.500'}
                      size={'20px'}
                    />
                  ) : null}
                </Box>
              )}
              keyExtractor={(item) => item.id}
              isInvalid={errors.issueType && fieldsTouched.issueType}
              onClosed={() => onFieldTouched('issueType')}
            />
            <FormControl.ErrorMessage>
              Please select issue type
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl w="100%" isRequired mt={'16px'}>
            <FormControl.Label m={0}>Issue Title</FormControl.Label>
            <Input
              placeholder="Enter title"
              mt="6px"
              fontSize={'14px'}
              py={'12px'}
              placeholderTextColor={'gray.600'}
              shadow={'none'}
              onBlur={() => onFieldTouched('name')}
              onChangeText={(value) => onFieldUpdateWithDebounce('name', value)}
              borderColor={
                errors.name && fieldsTouched.name ? 'error.500' : '#DEDFE2'
              }
              borderRadius={'6px'}
            />
            {errors.name && fieldsTouched.name ? (
              <Text color={'error.500'} fontSize={'12px'} mt={'8px'}>
                Please enter issue title
              </Text>
            ) : null}
          </FormControl>

          <FormControl w="100%" isRequired mt={'16px'}>
            <FormControl.Label m={0} mb={'6px'}>
              Severity
            </FormControl.Label>
            <TypeAndSearchFieldWithModal
              options={issueSeverities}
              value={fieldValues?.severity}
              placeholder="Select Severity"
              title="Select Severity"
              onChange={(newValue) => onFieldUpdate('severity', newValue)}
              getDisplayValue={(item) => (
                <Box display={'flex'} flexDir={'row'} alignItems={'center'}>
                  {item.icon}
                  <Text
                    ml={'6px'}
                    color={item.color}
                    fontWeight={600}
                    fontSize={'14px'}
                  >
                    {item.value}
                  </Text>
                </Box>
              )}
              getItemComponent={(item, { isHovered, isSelected } = {}) => (
                <Box
                  bgColor={isHovered ? 'gray.200' : 'white'}
                  w={'100%'}
                  h={'100%'}
                  p="12px"
                  display={'flex'}
                  flexDir={'row'}
                  alignItems={'center'}
                >
                  {item.icon}
                  <Text
                    ml={'6px'}
                    color={item.color}
                    fontWeight={600}
                    fontSize={'14px'}
                  >
                    {item.value}
                  </Text>
                  {isSelected ? (
                    <ExpoIonicIcon
                      name="checkmark-sharp"
                      color={'primary.500'}
                      size={'20px'}
                      ml={'auto'}
                    />
                  ) : null}
                </Box>
              )}
              keyExtractor={(item) => item.value}
              showFilter={false}
            />
          </FormControl>

          <FormControl
            w="100%"
            isRequired
            isInvalid={errors.location && fieldsTouched.location}
            mt="16px"
          >
            <FormControl.Label m={0} mb={'6px'}>
              Location
            </FormControl.Label>
            <TypeAndSearchFieldWithModal
              options={locationsOptions}
              value={fieldValues?.location}
              placeholder="Select Location"
              title="Select Location"
              onChange={(newValue) => onFieldUpdate('location', newValue)}
              getDisplayValue={(item) => (
                <Box>
                  <Text>{item.name}</Text>
                </Box>
              )}
              getItemComponent={(item, { isHovered, isSelected } = {}) => (
                <Box
                  bgColor={
                    isSelected
                      ? 'primary.500:alpha.20'
                      : isHovered
                      ? 'gray.200'
                      : 'white'
                  }
                  w={'100%'}
                  h={'100%'}
                  p="12px"
                  display={'flex'}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text>{item.name}</Text>
                  {isSelected ? (
                    <ExpoIonicIcon
                      name="checkmark-sharp"
                      color={'primary.500'}
                      size={'20px'}
                      ml={'auto'}
                    />
                  ) : null}
                </Box>
              )}
              keyExtractor={(item) => item.id}
              isInvalid={errors.location && fieldsTouched.location}
              onClosed={() => onFieldTouched('location')}
            />
            <FormControl.ErrorMessage>
              Please select a location
            </FormControl.ErrorMessage>
          </FormControl>

          <Checkbox
            isChecked={isNotifyLeadersChecked}
            value={'true'}
            onChange={(isSelected) =>
              onFieldUpdate('notifyLeaders', isSelected)
            }
            isDisabled={defaultNotifyLeaders}
            _text={{
              fontSize: '14px',
              fontWeight: 600,
              ml: 0,
            }}
            _icon={{
              size: '10px',
            }}
            _interactionBox={{
              p: 0,
              borderColor: 'error.500',
            }}
            mt={'9px'}
            _stack={{
              space: '8px',
            }}
            bgColor={'white'}
            borderColor={'gray.900'}
            borderWidth={'1px'}
            borderRadius={'2px'}
            _checked={{
              borderColor: 'primary.500',
              bgColor: 'primary.500',
              _hover: {
                borderColor: 'primary.500',
              },
            }}
            _hover={{
              borderColor: 'gray.900',
            }}
          >
            Notify location leaders
          </Checkbox>

          <FormControl w="100%" mt={'16px'}>
            <FormControl.Label m={0}>Description</FormControl.Label>
            <TextArea
              autoCompleteType={false}
              placeholder="Enter Description"
              mt="6px"
              // borderColor={
              //   errors.name && fieldsTouched.name ? 'error.500' : undefined
              // }
              fontSize={'14px'}
              py={'12px'}
              placeholderTextColor={'gray.600'}
              shadow={'none'}
              onBlur={() => onFieldTouched('notes')}
              onChangeText={(value) =>
                onFieldUpdateWithDebounce('notes', value)
              }
              borderRadius={'6px'}
              borderColor={'gray.300'}
            />
          </FormControl>

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text mt={'16px'} fontWeight={600} fontSize={'14px'}>
              Attachments
            </Text>

            {uploadedImages.length > 0 && (
              <Button
                colorScheme={'secondary'}
                mt={'16px'}
                onPress={deleteAllImages}
              >
                <Box display={'flex'} flexDir={'row'} alignItems={'center'}>
                  <DeleteAttachmentIcon size="20px" marginRight={'2px'} />
                  <Text fontWeight={600} color={'#F95757'} fontSize={'14px'}>
                    Delete All
                  </Text>
                </Box>
              </Button>
            )}
          </Box>

          <Divider my={'12px'} />

          <Box
            display={'flex'}
            flexDir={
              isTabletSize || isLargePhoneSize || isPhoneSize ? 'column' : 'row'
            }
            w={'100%'}
          >
            <Box
              display={'flex'}
              flexDir={isPhoneSize || isLargePhoneSize ? 'column' : 'row'}
              flex={1}
              // mr={
              //   isTabletSize || isLargePhoneSize || isPhoneSize
              //     ? undefined
              //     : '24px'
              // }
            >
              {uploadedVideos.length || uploadedPDFFiles.length ? null : (
                <Pressable onPress={onClickUploadImage} flex={1}>
                  <Box
                    display={'flex'}
                    flexDir={'column'}
                    flex={1}
                    mr={
                      isPhoneSize || isLargePhoneSize || uploadedImages.length
                        ? undefined
                        : '24px'
                    }
                    mb={
                      (isPhoneSize || isLargePhoneSize) &&
                      !uploadedImages.length
                        ? '16px'
                        : undefined
                    }
                  >
                    <Box display={'flex'} flexDir={'row'}>
                      <UploadImageIcon size="42px" />
                      <Box display={'flex'} flexDir={'column'} ml={'8px'}>
                        <Text
                          fontWeight={700}
                          color={'gray.1000'}
                          fontSize={'14px'}
                        >
                          JPEG/ PNG /GIF Images
                        </Text>
                        <Text color={'gray.700'} fontSize={'14px'}>
                          Max 2MB each.
                        </Text>
                      </Box>
                    </Box>
                    {isPhoneSize ||
                    isLargePhoneSize ||
                    !allowedImagesLimit ? null : (
                      <Button
                        colorScheme="hollow"
                        leftIcon={
                          uploadedImages.length ? (
                            <ExpoADIcon name="plus" />
                          ) : (
                            <AttachImageIcon />
                          )
                        }
                        mt={'12px'}
                        onPress={onClickUploadImage}
                      >
                        {uploadedImages.length ? 'Add Image' : 'Attach Image'}
                      </Button>
                    )}
                  </Box>
                </Pressable>
              )}

              {uploadedImages.length || uploadedPDFFiles.length ? null : (
                <Pressable onPress={onClickUploadVideo} flex={1}>
                  <Box
                    display={'flex'}
                    flexDir={'column'}
                    flex={1}
                    mr={
                      isPhoneSize || isLargePhoneSize || uploadedVideos.length
                        ? undefined
                        : '24px'
                    }
                    mb={
                      (isPhoneSize || isLargePhoneSize) &&
                      !uploadedVideos.length
                        ? '16px'
                        : undefined
                    }
                  >
                    <Box display={'flex'} flexDir={'row'}>
                      <UploadVideoIcon size="42px" />
                      <Box display={'flex'} flexDir={'column'} ml={'8px'}>
                        <Text
                          fontWeight={700}
                          color={'gray.1000'}
                          fontSize={'14px'}
                        >
                          MP4 Video
                        </Text>
                        <Text color={'gray.700'} fontSize={'14px'}>
                          Max 25MB.
                        </Text>
                      </Box>
                    </Box>
                    {isPhoneSize ||
                    isLargePhoneSize ||
                    !allowedVideosLimit ? null : (
                      <Button
                        colorScheme="hollow"
                        leftIcon={<AttachVideoIcon />}
                        mt={'12px'}
                        onPress={onClickUploadVideo}
                      >
                        Attach Video
                      </Button>
                    )}
                  </Box>
                </Pressable>
              )}

              {uploadedImages.length || uploadedVideos.length ? null : (
                <Pressable onPress={onClickUploadFile} flex={1}>
                  <Box
                    display={'flex'}
                    flexDir={'column'}
                    flex={1}
                    // mr={isPhoneSize || isLargePhoneSize ? undefined : '24px'}
                  >
                    <Box display={'flex'} flexDir={'row'}>
                      <UploadPDFIcon size="42px" />
                      <Box display={'flex'} flexDir={'column'} ml={'8px'}>
                        <Text
                          fontWeight={700}
                          color={'gray.1000'}
                          fontSize={'14px'}
                        >
                          PDF File
                        </Text>
                        <Text color={'gray.700'} fontSize={'14px'}>
                          Max 25MB.
                        </Text>
                      </Box>
                    </Box>
                    {isPhoneSize ||
                    isLargePhoneSize ||
                    !allowedPDFFilesLimit ? null : (
                      <Button
                        colorScheme="hollow"
                        leftIcon={<AttachPDFIcon />}
                        mt={'12px'}
                        onPress={onClickUploadFile}
                      >
                        Upload PDF
                      </Button>
                    )}
                  </Box>
                </Pressable>
              )}
            </Box>
          </Box>

          {uploadedImages.length || uploadedVideos.length ? (
            <Box
              w={'100%'}
              onLayout={(e) => {
                setContainerWidth(e.nativeEvent.layout.width);
              }}
              display={'flex'}
              flexWrap={'wrap'}
              flexDir={'row'}
              mt={'-6px'}
            >
              {uploadedImages.map((image, imageIndex) => (
                <Box
                  w={
                    isPhoneSize || isLargePhoneSize
                      ? '100%'
                      : `${(containerWidth - 24) / 3}px`
                  }
                  mr={imageIndex % 3 === 2 ? undefined : '12px'}
                  mt={'18px'}
                  key={image.url}
                >
                  <ImageCard
                    url={image.url}
                    onDelete={() => onDeleteImage(imageIndex)}
                  />
                </Box>
              ))}

              {uploadedVideos.map((video, videoIndex) => (
                <Box
                  w={
                    isPhoneSize || isLargePhoneSize
                      ? '100%'
                      : `${(containerWidth - 24) / 3}px`
                  }
                  mr={videoIndex % 3 === 2 ? undefined : '12px'}
                  mt={'18px'}
                  key={video.url}
                >
                  <VideoCard
                    url={video.url}
                    onDelete={() => onDeleteVideo(videoIndex)}
                  />
                </Box>
              ))}
            </Box>
          ) : null}

          {uploadedPDFFiles.map((pdfFile, pdfFileIndex) => (
            <Box w={'100%'} mt={'12px'} key={pdfFile.url}>
              <PDFFileCard
                name={pdfFile.filename}
                url={pdfFile.url}
                onDelete={() => onDeletePDFFile(pdfFileIndex)}
              />
            </Box>
          ))}

          {formId ? (
            <FormControl w="100%" mt={'24px'} isRequired>
              <FormControl.Label m={0}>Additional Info</FormControl.Label>
            </FormControl>
          ) : null}

          {formId ? (
            <Box
              h={isFormNuggetLoading ? '200px' : `${webviewHeight}px`}
              w={'100%'}
              borderRadius={'12px'}
              mt={isFormNuggetLoading ? undefined : '12px'}
              key={formId}
            >
              <WithKNOWLoader isLoading={isFormNuggetLoading}>
                <FormWebview
                  key={formId}
                  url={webViewUrl}
                  userInfo={userInfo}
                  formDefinition={formNugget}
                  formResponses={formResponse}
                  buttonLabels={buttonLabels}
                  locations={locations}
                  reviewMode={reviewMode}
                  uploadFiles={uploadFiles}
                  writeResponses={writeResponses}
                  submit={onFormSubmission(submitResponse)}
                  getUsersOptions={searchUsers}
                  sendToNext={sendToNextResponse}
                  ref={formWebViewRef}
                  onDataWrite={onDataWrite}
                  onHeightChange={setWebviewHeight}
                  embedded
                  onEmbedSubmitStatus={onFormWebviewSubmitStatus}
                />
              </WithKNOWLoader>
            </Box>
          ) : null}
        </View>
      </ScrollView>

      <UploadImageModal
        isOpen={isUploadImageModalOpen}
        close={closeUploadImageModal}
        limit={allowedImagesLimit}
        onSubmit={onAddImages}
        showAlertToast={true}
        sizeLimit={2 * 2 ** 20} //2 MB
      />
      <UploadVideoModal
        isOpen={isUploadVideoModalOpen}
        close={closeUploadVideoModal}
        limit={allowedVideosLimit}
        onSubmit={onAddVideos}
        showAlertToast={true}
        sizeLimit={25 * 2 ** 20} //25 MB
      />
      <UploadFileHandler
        ref={uploadFileHandlerRef}
        limit={allowedPDFFilesLimit}
        onSubmit={onAddFiles}
        accept="application/pdf"
        acceptName="PDF"
        sizeLimit={25 * 2 ** 20} //25 MB
      />
      <UploadProgressModal
        isOpen={isUploading}
        progress={uploadProgress}
        {...uploadProgressProps}
      />
      <LocationPermissionModal
        isOpen={isLocationPermissionModalOpen}
        {...locationPermissionModalProps}
      />
      <SpinnerModal isOpen={showSpinnerModal} />
    </View>
  );
};
