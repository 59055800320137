export default {
  'QRCodeExpireText_one':
    'క్యూ.ఆర్ కోడ్ గడువు <1>{{remainingSeconds}}</1> సెకనులో ముగుస్తుంది',
  'QRCodeExpireText_other':
    'క్యూ.ఆర్ కోడ్ గడువు <1>{{remainingSeconds}}</1> సెకన్లలో ముగుస్తుంది',

  'download-app-text': 'మెరుగైన అనుభవం కోసం మా యాప్‌ని పొందండి!',
  'No courses found': 'కోర్సులు ఏవీ కనుగొనబడలేదు',

  'You’ve not completed a course yet.': 'మీరు ఇంకా కోర్సు పూర్తి చేయలేదు.',
  "Looks like you don't have any forms yet.":
    'మీకు ఇంకా ఫారమ్‌లు ఏవీ లేనట్లు కనిపిస్తోంది.',
};
