import type { DrawerNavigationOptions } from '@react-navigation/drawer';

import { Header } from './Header';
import { useKNOWValueContextProvider } from '@know/ui';
import React, { useEffect, useMemo } from 'react';

export const useDrawerScreenOptions = (options: {
  isResponsive?: boolean;
  actionButton?: JSX.Element | null;
}) => {
  const { isResponsive, actionButton } = options;
  const { isTabletSize, isLargePhoneSize, isPhoneSize } =
    useKNOWValueContextProvider();

  const isSmallScreen = isTabletSize || isLargePhoneSize || isPhoneSize;

  const { setTabHeaderHeight } = useKNOWValueContextProvider();

  useEffect(() => {
    if (isResponsive && isSmallScreen) {
      setTabHeaderHeight && setTabHeaderHeight(86);
    } else {
      setTabHeaderHeight && setTabHeaderHeight(0);
    }
  }, [isResponsive, isSmallScreen, setTabHeaderHeight]);

  const screenOptions = useMemo<DrawerNavigationOptions>(() => {
    if (isResponsive && isSmallScreen) {
      return {
        drawerType: 'permanent',
        header: () => null,
        drawerStyle: {
          backgroundColor: 'white',
          borderRightColor: 'white',
          width: '100%',
          top: 0,
          height: '92px',
          position: 'absolute',
        },
        sceneContainerStyle: {
          backgroundColor: 'white',
          marginTop: 91,
        },
      };
    }

    return {
      drawerType: 'permanent',
      header: (props) => <Header {...props} actionButton={actionButton} />,
      drawerStyle: {
        backgroundColor: 'white',
        borderRightColor: 'white',
        width: '220px',
      },
      sceneContainerStyle: {
        backgroundColor: 'white',
      },
    };
  }, [isResponsive, isSmallScreen, actionButton]);

  return {
    screenOptions,
    isSmallScreen: (isResponsive && isSmallScreen) ?? false,
  };
};
