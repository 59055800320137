import React from 'react';
import { Button, View, Text, HStack } from '.';
import { KNOW_APP_DOWNLOAD_URL } from '@know/constants';
import { useKNOWValueContextProvider } from '..';
import { useTranslation } from '@know/i18n';

export function KNOWAppDownloadView() {
  const { isPhoneSize } = useKNOWValueContextProvider();
  const { t } = useTranslation(['buttons', 'messages']);

  return (
    <View bgColor="white" flexDirection="row" justifyContent="center">
      <HStack
        px={4}
        py={'6px'}
        alignItems="center"
        justifyContent="space-between"
        w={'full'}
      >
        <Text
          flex={1}
          fontFamily={'heading'}
          fontSize={isPhoneSize ? '14px' : 'md'}
          lineHeight={'1.2em'}
          mr="2"
          fontWeight="bold"
          color="primary.800"
        >
          {t('download-app-text', { ns: 'messages' })}
        </Text>
        <Button
          p="8px 12px"
          borderRadius={'8px'}
          onPress={() => (window.location.href = KNOW_APP_DOWNLOAD_URL)}
          bgColor="purple.400"
        >
          <Text fontSize={'sm'} fontWeight="semibold" color="white">
            {t('Download Now', { ns: 'buttons' })}
          </Text>
        </Button>
      </HStack>
    </View>
  );
}
