import React, { useEffect, useMemo, useState } from 'react';
import { View, Image, Text } from '@know/ui';
import { StyleSheet, Pressable, Dimensions } from 'react-native';

import Images from '../../../../Images/index';
import type { IHotspotQuestion } from '..';

const INIT_DIMENSIONS = {
  width: '100%',
  height: '100%',
};
export const HotspotQuestion = ({
  hotspotQuestionObj,
  setHotspotIcons,
  hotspotIcons,
  hotspotIconsQuizRes,
  setHotspotIconsQuizRes,
  setQuizProgress,
  selectedIndex,
  quizProgress,
  setShowModal,
}: IHotspotQuestion) => {
  const [isLanscapeMode, setIsLandscapeMode] = useState(true);
  const [imageDimensions, setImageDimensions] = useState(INIT_DIMENSIONS);
  const [imageWrapperDimensions, setImageWrapperDimensions] =
    useState(INIT_DIMENSIONS);
  const [hotspotOptionsCords, setHotspotOptionsCords] = useState(
    hotspotQuestionObj?.options
  );

  const [showInstruction, setShowInstruction] = useState(true);

  const handlePress = (evt: any) => {
    if (showInstruction) {
      setShowInstruction(false);
      return;
    }
    if (
      Array.isArray(hotspotIcons) &&
      Array.isArray(hotspotQuestionObj?.options) &&
      hotspotIcons.length >= hotspotQuestionObj?.options.length
    ) {
      setShowModal(true);
      return;
    }

    const xCord = evt.nativeEvent.offsetX;
    const yCord = evt.nativeEvent.offsetY;
    setHotspotIcons([...hotspotIcons, { x: +xCord, y: +yCord }]);
    setQuizProgress((prevState: any) => {
      let state = [...prevState];
      state[selectedIndex] = [...hotspotIcons, { x: +xCord, y: +yCord }];
      return state;
    });
  };

  useEffect(() => {
    const hotspotQuizCoordinates = [];
    for (const hotspotPost of hotspotIcons) {
      const { x, y } = hotspotPost;
      const newQuizResCoordinates = getResQuizCordinates(x, y);
      hotspotQuizCoordinates.push(newQuizResCoordinates);
    }

    setHotspotIconsQuizRes(hotspotQuizCoordinates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotspotOptionsCords, hotspotIcons]);

  const getResQuizCordinates = (xCord: number, yCord: number) => {
    let answerObj: { isCorrectAns: boolean; index: number } = {
      isCorrectAns: false,
      index: 0,
    };
    let selectedOption: { hotspot?: number; pos: { x: number; y: number } } = {
      pos: {
        x: 0,
        y: 0,
      },
    };
    for (const [index, hotspotOption] of Object.entries(hotspotOptionsCords)) {
      let xCords: any = [];
      let yCords: any = [];
      let options: any = hotspotOption;

      if (options?.length) {
        options?.forEach((ht: any) => {
          if (!xCords.includes(ht.x)) {
            xCords.push(ht.x);
          }
          if (!yCords.includes(ht.y)) {
            yCords.push(ht.y);
          }
        });

        xCords.sort((a: number, b: number) => a - b);
        yCords.sort((a: number, b: number) => a - b);
        if (
          xCords[0] <= xCord &&
          xCords[1] >= xCord &&
          yCords[0] <= yCord &&
          yCords[1] >= yCord
        ) {
          answerObj = {
            isCorrectAns: true,
            index: +index,
          };
        }
      }
    }

    if (answerObj.isCorrectAns) {
      selectedOption = {
        hotspot: answerObj.index,
        pos: {
          x: +xCord * (+imageDimensions.width / +aspectRatio.width),
          y: +yCord * (+imageDimensions.height / +aspectRatio.height),
        },
      };
    } else {
      selectedOption = {
        pos: {
          x: +xCord * (+imageDimensions.width / +aspectRatio.width),
          y: +yCord * (+imageDimensions.height / +aspectRatio.height),
        },
      };
    }
    return selectedOption;
  };

  const aspectRatio = useMemo(() => {
    const width = Math.ceil(
      (+imageWrapperDimensions.height * +imageDimensions.width) /
        +imageDimensions.height
    );
    const height = Math.ceil(
      (+imageWrapperDimensions.width * +imageDimensions.height) /
        +imageDimensions.width
    );

    return {
      width: isLanscapeMode ? imageWrapperDimensions.width : width,
      height: isLanscapeMode ? height : Dimensions.get('screen').height / 2,
    };
  }, [
    imageDimensions.height,
    imageDimensions.width,
    imageWrapperDimensions.height,
    imageWrapperDimensions.width,
    isLanscapeMode,
  ]);

  const removeHotspot = (htIcon: { x: number; y: number }) => {
    setHotspotIconsQuizRes(
      hotspotIconsQuizRes?.filter(
        (htRes: any) =>
          +htRes?.selectedOption?.pos?.x !== +htIcon.x &&
          +htRes?.selectedOption?.pos?.y !== +htIcon.y
      )
    );
    setHotspotIcons((_prev: any) => {
      const newHotspots = hotspotIcons.filter(
        (htI: any) => +htI?.x !== +htIcon.x && +htI?.y !== +htIcon.y
      );
      quizProgress[selectedIndex] = newHotspots;
      setQuizProgress(quizProgress);
      return newHotspots;
    });
  };

  useEffect(() => {
    Image.getSize(
      hotspotQuestionObj?.hotspotUrl,
      // @ts-ignore
      (width: string, height: string) => {
        setImageDimensions({ width, height });
        setIsLandscapeMode(+width > +height ? true : false);

        const newWidth = aspectRatio.width;
        const newHeight = aspectRatio.height;
        const oldWidth = imageDimensions.width;
        const oldHeight = imageDimensions.height;

        let newHotspotOptions = [];
        if (hotspotQuestionObj?.options?.length > 0) {
          for (const hotspotOption of hotspotQuestionObj?.options) {
            const mappedCords = hotspotOption?.map((ht: any) => {
              const x = ht.x * (+newWidth / +oldWidth);
              const y = ht.y * (+newHeight / +oldHeight);
              return {
                x,
                y,
              };
            });
            newHotspotOptions.push(mappedCords);
          }
          setHotspotOptionsCords(newHotspotOptions);
        }
      }
    );
  }, [
    aspectRatio.height,
    aspectRatio.width,
    hotspotQuestionObj?.hotspotUrl,
    hotspotQuestionObj?.options,
    imageDimensions.height,
    imageDimensions.width,
  ]);

  const styles = StyleSheet.create({
    imageWrapper: {
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
      width: aspectRatio.width,
      height: aspectRatio.height,
    },
    hotspotIcon: {
      borderRadius: 100,
      width: '20px',
      height: '20px',
      backgroundColor: 'black',
      borderColor: 'white',
      borderWidth: 2,
      position: 'absolute',
    },
    tooltipTriangle: {
      transform: [{ rotate: '0' }],
    },
  });

  const hotspotIconPosition = (htIcon: any) => {
    return {
      top: htIcon.y - 10,
      left: htIcon.x - 10,
    };
  };

  const HotspotIcon = ({
    htIcon,
    index,
  }: {
    htIcon: { x: number; y: number };
    index: number;
  }) => {
    return (
      <Pressable
        onPress={() => removeHotspot(htIcon)}
        key={`ht-icon-${index}`}
        style={[styles.hotspotIcon, hotspotIconPosition(htIcon)]}
      >
        {({ hovered }: any) => {
          return (
            hovered && (
              <View
                width="125px"
                height="26px"
                borderRadius="md"
                backgroundColor="#222C2A"
                position="relative"
                bottom="-30px"
                left="-54.5px"
                display="flex"
                justifyContent="center"
                opacity={0.9}
              >
                <View
                  width={0}
                  height={0}
                  backgroundColor="transparent"
                  borderStyle="solid"
                  borderLeftWidth={10}
                  borderRightWidth={10}
                  borderBottomWidth={10}
                  borderLeftColor="transparent"
                  borderRightColor="transparent"
                  borderBottomColor="#222C2A"
                  zIndex={1}
                  position="absolute"
                  top="-10px"
                  alignItems="center"
                  alignSelf="center"
                  flex={1}
                  opacity={0.9}
                  style={styles.tooltipTriangle}
                />
                <Text
                  fontSize="sm"
                  width="full"
                  color="white"
                  paddingY="2px"
                  paddingX="6px"
                  display="flex"
                  justifyContent="center"
                >
                  Click to remove
                </Text>
              </View>
            )
          );
        }}
      </Pressable>
    );
  };

  const InstructionModal = () => {
    return (
      <View
        position={'absolute'}
        zIndex={3}
        style={StyleSheet.absoluteFill}
        alignItems="center"
        justifyContent="center"
      >
        <View borderRadius="md" bgColor="white" opacity={0.7}>
          <View
            paddingX="32px"
            paddingY="24px"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              height="35px"
              width="25px"
              marginBottom="10px"
              source={Images.ClickHandIcon}
            />
            <Text fontWeight="semibold" fontSize="16px" textAlign="center">
              Click on the image to answer{'\n'}the question.
            </Text>
          </View>
        </View>
      </View>
    );
  };

  useEffect(() => {
    if (quizProgress[selectedIndex]) {
      setShowInstruction(false);
    } else {
      setShowInstruction(true);
    }
    // setHotspotIcons(quizProgress[selectedIndex] || hotspotIcons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizProgress[selectedIndex]]);

  return (
    <View flex={1}>
      <View
        flex={1}
        bgColor="black"
        justifyContent="center"
        alignItems="center"
        marginTop="8px"
        onLayout={(e) => {
          const { height, width }: any = e.nativeEvent.layout;
          setImageWrapperDimensions((_pre: any) => {
            return { width, height };
          });
        }}
      >
        <Pressable
          style={styles.imageWrapper}
          onPress={(evt) => {
            handlePress(evt);
          }}
        >
          <Image
            alt="full screen image"
            width={aspectRatio.width}
            height={aspectRatio.height}
            source={{
              uri: hotspotQuestionObj?.hotspotUrl,
            }}
            resizeMode="contain"
            position="relative"
          />
          {showInstruction && <InstructionModal />}
          {hotspotIcons.map((htIcon: any, index: number) => {
            return (
              <HotspotIcon
                key={`hotspot-icon${index}`}
                htIcon={htIcon}
                index={index}
              />
            );
          })}
        </Pressable>
      </View>
    </View>
  );
};
