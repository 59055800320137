import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import {
  Box,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
  useMediaQuery,
  useRouterContext,
  useKNOWContextProvider,
} from '@know/ui';

import images from '../../Images';
import { LeftArrowIcon } from '@know/ui';

import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';

import { KNOWAppDownloadView } from '../KNOWAppDownloadView';

const Tab = createMaterialTopTabNavigator();

type ScreenType = typeof Tab['Screen'];

interface ITabProps extends ScreenType {
  name: string;
}

export interface ITabComponentProbs {
  tabs: Array<ITabProps>;
  swipeEnabled?: boolean;
}
const styles = StyleSheet.create({
  tabText: {
    lineHeight: 17,
    fontSize: 16,
  },
  labelView: {
    borderBottomColor: '#FFF',
    borderBottomWidth: 4,
  },
  childView: {
    flexDirection: 'row',
    // gap: 15,
    // marginTop: getOS() === 'Windows' ? '34px' : '39px',
    zIndex: 1,
  },
  tabHeaderText: {
    fontWeight: '700',
    color: '#FFFFFF',
    width: 'max-content',
    // marginTop: 28,
    zIndex: 1,
  },
  leftArrowIconWrapper: {
    height: '32px',
    width: '32px',
    borderRadius: 50,
    backgroundColor: '#fff',
  },
});

function KNOWTabBar({
  state,
  descriptors,
  navigation,
}: MaterialTopTabBarProps) {
  const { isSDK = false, sdkBackButtonHomePage } = useKNOWContextProvider();
  // sdkBackButtonHomePage
  // for loacl dev use this var, delete this once done
  // prod will use isSDK from useKNOWContextProvider
  // const isSDK = true;
  const [media] = useMediaQuery({ maxWidth: 480 });
  const { hideDownload = false } = useRouterContext();

  const getLabel = (options: any, route: any) => {
    if (options.tabBarLabel !== undefined) {
      return options.tabBarLabel;
    } else {
      if (options.title !== undefined) {
        return options.title;
      }
      return route.name;
    }
  };

  return (
    <Box bg="navbar.primary">
      {hideDownload ? null : <KNOWAppDownloadView />}
      <Box
        height={isSDK ? '180px' : '140px'}
        maxWidth="1400px"
        width="full"
        marginX="auto"
        justifyContent={'space-between'}
      >
        {media && (
          <Box
            position="absolute"
            zIndex={1}
            style={StyleSheet.absoluteFill}
            bg={{
              linearGradient: {
                colors: [
                  'navbar.secondoryTransparent',
                  'navbar.primaryTransparent',
                  'navbar.primaryTransparent',
                ],
                start: [0, 0],
                end: [0, 1],
              },
            }}
          />
        )}
        {isSDK && (
          <Pressable
            zIndex={10}
            marginLeft={{
              base: '10px',
              sm: '10px',
              md: '32px',
              lg: '32px',
              xl: '32px',
            }}
            marginTop={'12px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            padding={'6px'}
            style={styles.leftArrowIconWrapper}
            onPress={sdkBackButtonHomePage}
          >
            <LeftArrowIcon color="teal.700" size="4" />
          </Pressable>
        )}
        <Text
          marginLeft={{
            base: '10px',
            sm: '10px',
            md: '32px',
            lg: '32px',
            xl: '32px',
          }}
          fontSize={{
            base: '24px',
            sm: '24px',
            md: '32px',
            lg: '32px',
            xl: '32px',
          }}
          marginTop={
            isSDK
              ? ''
              : {
                  base: '42px',
                  sm: '42px',
                  md: '28px',
                  lg: '28px',
                  xl: '28px',
                }
          }
          style={styles.tabHeaderText}
        >
          Learn
        </Text>
        <Image
          source={{
            uri: images.logoLearn,
          }}
          resizeMode="contain"
          height={['100%', '100%', '100%', '100%', '140%']}
          alignSelf="flex-end"
          width={['380px', '380px', '380px', '380px', '500px']}
          right={['0px', '0px', '0px', '0px', '10px']}
          bottom={['0px', '0px', '0px', '0px', '-30%']}
          position="absolute"
          alt="tabs"
        />
        <ScrollView
          marginLeft={{
            base: '10px',
            sm: '10px',
            md: '32px',
            lg: '32px',
            xl: '32px',
          }}
          style={styles.childView}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          flexGrow={'initial'}
        >
          {state.routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const label = getLabel(options, route);

            const isFocused = state.index === index;

            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              });

              if (!isFocused && !event.defaultPrevented) {
                // The `merge: true` option makes sure that the params inside the tab screen are preserved
                navigation.navigate({ merge: true, ...route });
              }
            };

            const onLongPress = () => {
              navigation.emit({
                type: 'tabLongPress',
                target: route.key,
              });
            };

            return (
              <TouchableOpacity
                key={index}
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                onLongPress={onLongPress}
              >
                <View
                  borderTopLeftRadius={'4px'}
                  borderTopRightRadius={'4px'}
                  bg={isFocused ? 'white' : 'rgba(255, 255, 255, 0.30)'}
                  padding={'10px'}
                  marginRight={
                    index === state.routes.length - 1
                      ? {
                          base: '10px',
                          sm: '10px',
                          md: '20px',
                          lg: '32px',
                          xl: '32px',
                        }
                      : '10px'
                  }
                >
                  <Text
                    style={styles.tabText}
                    color={isFocused ? 'teal.700' : 'white'}
                    fontWeight={isFocused ? 600 : 400}
                  >
                    {label}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </Box>
    </Box>
  );
}

export function Tabs({ tabs, swipeEnabled = true }: ITabComponentProbs) {
  return (
    <Tab.Navigator
      screenOptions={{ lazy: true, swipeEnabled }}
      tabBar={KNOWTabBar}
    >
      {tabs.map((tab: any) => (
        <Tab.Screen key={tab.name} {...tab} />
      ))}
    </Tab.Navigator>
  );
}
