/* eslint-disable react-native/no-inline-styles */
import { Box, Text } from 'native-base';
import React from 'react';
import { ExpoEntypoIcon, ExpoMCIcon, AngleDoubleUpIcon } from '@know/ui';
import { SeverityType } from '@know/db';

const IssueTypeConfigObject = {
  low: {
    id: 'Low',
    color: '#198BC3',
    icon: () => (
      <ExpoEntypoIcon
        color={'#198BC3'}
        name="chevron-thin-down"
        size={'14px'}
      />
    ),
  },
  medium: {
    id: 'Medium',
    color: '#EA750A',
    icon: () => <ExpoMCIcon color={'#EA750A'} name="equal" size={'14px'} />,
  },
  high: {
    id: 'High',
    color: '#F95757',
    icon: () => (
      <ExpoEntypoIcon color={'#F95757'} name="chevron-thin-up" size={'14px'} />
    ),
  },
  critical: {
    id: 'Critical',
    color: '#F95757',
    icon: () => <AngleDoubleUpIcon color={'#F95757'} size={'14px'} />,
  },
};

const getLabel = (type: SeverityType) => {
  return IssueTypeConfigObject[type].id;
};

export const IssueSeverityType = ({
  type,
  marginTop,
}: {
  type: SeverityType;
  marginTop?: string;
}) => {
  const IssueTypeIcon = IssueTypeConfigObject[type].icon;
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      marginTop={marginTop}
    >
      <IssueTypeIcon />
      <Text
        fontSize={'14px'}
        fontWeight={600}
        fontFamily={'tableCell'}
        textTransform={type ? undefined : 'uppercase'}
        color={IssueTypeConfigObject[type].color}
        ml={'6px'}
      >
        {getLabel(type) || type}
      </Text>
    </Box>
  );
};
