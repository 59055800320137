import { FirebaseAPI } from "./FirebaseAPI";
import { get } from "lodash";

type ScoobyReturnedOrgsArray = Array<{
  env: string,
  orgId: string,
  orgName: string
}>

type ScoobyReturnedEnvs = {
  [key: string]: object
}

type ScoobyVerifyOTPType = {
  orgs: ScoobyReturnedOrgsArray,
  envs: ScoobyReturnedEnvs,
  newToken: string,
  token: string
}
class ScoobyAPI extends FirebaseAPI {
  constructor() {
    const scoobyConfig = {
      apiKey: "AIzaSyCrPLZZwUq6YmZXJCb7AKSTzVRQNfg1vXQ",
      authDomain: "scooby-89755.firebaseapp.com",
      databaseURL: "https://scooby-89755.firebaseio.com",
      projectId: "scooby-89755",
      storageBucket: "scooby-89755.appspot.com",
      messagingSenderId: "849775180202",
      appId: "1:849775180202:web:429368ca053882f4a353d3",
      measurementId: "G-9RV60J8REY"
    };

    super(scoobyConfig, "scooby")
  }

  async sendOTP({ code, phone }: { code: string, phone: string }): Promise<boolean> {
    const payload = {
      code,
      phone,
    }

    const results = await this.callRPC("RPC/secureSendOTP", payload)

    return Boolean(get(results, "result", false))
  }

  async verifyOTP({ code, phone, otp }: { code: string, phone: string, otp: string }): Promise<boolean | ScoobyVerifyOTPType> {
    const payload = {
      code,
      phone,
      otp,
    }

    const results = await this.callRPC("RPC/secureVerifyOTP", payload)
    const success = get(results, "result", false)

    if (!success) return false

    const token = get(results, "token", "")
    const decodedToken = FirebaseAPI.decodeJWT(token);

    const { orgs = [], envs = {} } = get(decodedToken, "claims", {})

    const creds = await this.signInWithCustomToken(token)
    const newToken = await creds.user.getIdToken(true)

    return { orgs, envs, newToken, token }

  }

}

export { ScoobyAPI, ScoobyReturnedOrgsArray, ScoobyReturnedEnvs, ScoobyVerifyOTPType };
