import React, { useMemo } from 'react';
import { createStackNavigator, useKNOWDBContext } from '@know/ui';
import {
  IssueDetailsPage,
  linking as IssueDetailsLinking,
} from './IssuesDetailsPage';
import {
  IssueListNavigator,
  linking as IssueListNavigatorLinking,
} from './IssuesListNavigator';

import {
  CreateIssuePage,
  linking as CreateIssueLinking,
} from './CreateIssuePage';
const IssuesStackNavigator = createStackNavigator();

export const linking = {
  initialRouteName: 'issues-list',
  screens: {
    'issues-details': IssueDetailsLinking,
    'issues-list': IssueListNavigatorLinking,
    'issues-create': CreateIssueLinking,
  },
};

export function IssuesNavigator() {
  const { canUserCreate } = useKNOWDBContext();

  const canUserCreateIssue = useMemo(() => {
    return canUserCreate('tasklist');
  }, [canUserCreate]);
  return (
    <IssuesStackNavigator.Navigator initialRouteName="issues-list">
      <IssuesStackNavigator.Screen
        name="issues-details"
        component={IssueDetailsPage}
        options={{
          headerShown: false,
          title: 'Issue',
        }}
      />
      <IssuesStackNavigator.Screen
        name="issues-list"
        component={IssueListNavigator}
        options={{
          headerShown: false,
        }}
      />
      {canUserCreateIssue ? (
        <IssuesStackNavigator.Screen
          name="issues-create"
          component={CreateIssuePage}
          options={{
            headerShown: false,
            title: 'Report Issue',
          }}
        />
      ) : null}
    </IssuesStackNavigator.Navigator>
  );
}
