import {
  Text,
  HStack,
  KNOWIcon,
  KNPressable,
  VStack,
  ResponseStatus,
  View,
  Box,
} from '..';

import { useKNOWValueContextProvider } from '@know/ui';

import React from 'react';
import { IFormActionRequiredFeedItems } from '@know/transformers';
import { DateTime } from '../DateTime';

interface IFormActionRequiredWorkFlowItemProps {
  item: IFormActionRequiredFeedItems;
  onPress: (item: IFormActionRequiredFeedItems) => void;
}

export function FormActionRequiredWorkFlowItem({
  item,
  onPress,
}: IFormActionRequiredWorkFlowItemProps) {
  const { name, sno, receivedOn, status, senderName } = item;

  function handleOnPress() {
    onPress && onPress(item);
  }

  const { isPhoneSize, isLargePhoneSize } = useKNOWValueContextProvider();

  const isMobile = isPhoneSize || isLargePhoneSize;

  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        flex={1}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        pr={'20px'}
        pl={'24px'}
        py={'12px'}
        borderBottomColor={'gray.300'}
        borderBottomWidth={'1px'}
      >
        <KNOWIcon
          color={'gray.900'}
          mr={2}
          mt={'2px'}
          name={'filetext1'}
          size="4"
          textAlign="center"
        />
        <Box
          flex={1}
          display={'flex'}
          justifyContent={isMobile ? 'flex-start' : 'space-between'}
          flexDir={isMobile ? 'column' : 'row'}
        >
          <VStack flex={1}>
            <HStack>
              <Text
                fontFamily={'open'}
                display={'flex'}
                alignItems={'center'}
                color={'gray.900'}
                fontWeight={600}
              >
                {name}
              </Text>
            </HStack>
            <Box
              flex={1}
              display={'flex'}
              justifyContent={'flex-start'}
              flexDir={isPhoneSize ? 'column' : 'row'}
              mt={'6px'}
              alignItems={isPhoneSize ? 'flex-start' : 'center'}
            >
              <Text
                fontFamily={'open'}
                display={'flex'}
                alignItems={'center'}
                fontSize={'14px'}
                color={'gray.700'}
              >
                {sno}
              </Text>
              {isPhoneSize ? null : (
                <Box
                  w={'6px'}
                  h={'6px'}
                  bg={'gray.500'}
                  mx={'8px'}
                  borderRadius={'50%'}
                />
              )}
              <HStack
                alignItems={'center'}
                mt={isPhoneSize ? '8px' : undefined}
              >
                <View>
                  <ResponseStatus status={status} />
                </View>
                <Text
                  color={'gray.700'}
                  ml={'4px'}
                  fontFamily={'open'}
                  fontSize={'14px'}
                >
                  Sender:{' '}
                  <Text color={'gray.700'} fontWeight={600} mx={1}>
                    {senderName}
                  </Text>
                </Text>
              </HStack>
            </Box>
          </VStack>
          <Text
            fontFamily={'open'}
            ml={isMobile ? undefined : '10px'}
            mt={isMobile ? '6px' : undefined}
            color={'gray.700'}
            fontSize={'14px'}
          >
            {receivedOn ? <DateTime time={receivedOn} /> : null}
          </Text>
        </Box>
      </HStack>
    </KNPressable>
  );
}
