import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  KNOWDBContext,
  useNavigation,
  useToast,
  useRecoilState,
  RestrictToUserType,
  RestrictToGroupType,
  UserDetailsInterface,
  // OnScreenBeforeRemoveEvent,
  useHideHeader,
  // useOnScreenBeforeRemove,
  useWebOnBeforeUnload,
  useModalParam,
  FormWebviewRef,
  useKNOWToast,
  EllipsisMenuOption,
  LocationLeaderIcon,
  ExpoFA5Icon,
  ReturnIcon,
  ReturnFormSectionInfo,
  // useKNOWValueContextProvider,
} from '@know/ui';
import {
  getFormNugget,
  getFormResponse,
  initiateNewResponse,
  deleteDraft,
  sendToNextFormResponse,
  submitFormResponses,
  writeFormResponses,
  getLocation,
  getFormPaperTrail,
  raiseShareFormResponseToLocationLeaderRequest,
  raiseReturnFormResponseRequest,
  isResponseStillInUserReceivedTab,
  isFormResponseDraft,
  addDraftForParentNugget,
} from '@know/db';
import {
  FormNugget,
  getFormRequireLocation,
  getIsFormCanShare,
  getIsFormResponseSubmitted,
  getSubmittedSectionId,
  // getWebviewUrl,
  prepareUsersDetails,
  prepareUsersDetailsForFormWebview,
  searchUsers as searchUsersKT,
} from '@know/transformers';
import { groupMembersAtom, userDetailsAtom } from '../store';
import { flatten, uniqBy } from 'lodash';
import { openPDFFromHTML } from './utils';
import Constant from 'expo-constants';

const EMPTY_OBJECT: any = {};
const RETURN_FORM_OPTION = 'RETURN_FORM_OPTION';
const LOCATION_LEADERS_OPTION = 'LOCATION_LEADERS_OPTION';
const PRINT_PDF_OPTION = 'PRINT_PDF_OPTION';

export const useFormWebView = (
  formId: string,
  webViewOptions?: {
    responseId?: string;
    initiatorId?: string;
    type?: string;
    parentId?: string;
    parentType?: string;
    parentShareId?: string;
    groupId?: string;
    questionId?: string;
    skipDraft?: boolean;
  }
) => {
  const {
    currentDBApi,
    orgConfig,
    userDetails,
    // envConfig,
    getCurrentUserName,
    getCurrentUserUserId,
    getCurrentUserDetails,
  } = useContext(KNOWDBContext);

  // const { errorToast } = useKNOWValueContextProvider();

  const {
    parentId,
    parentType,
    parentShareId,
    responseId: _responseId,
    initiatorId,
    type,
    groupId,
    questionId,
    skipDraft,
  } = webViewOptions ?? EMPTY_OBJECT;
  const [isLoading, setIsLoading] = useState(true);
  const [formNugget, setFormNugget] = useState<FormNugget | null>(null);
  const [reviewMode, setReviewMode] = useState<boolean>(type === 'sent');

  const [responseId, setResponseId] = useState<string | undefined>(_responseId);
  const [formResponse, setFormResponse] = useState<any | undefined>();

  const [groupMembers, setGroupMembers] = useRecoilState(groupMembersAtom);
  const [userDetailsCache, setUserDetailsCache] =
    useRecoilState(userDetailsAtom);

  // const [
  //   currentOnScreenBeforeRemoveEvent,
  //   setCurrentOnScreenBeforeRemoveEvent,
  // ] = useState<OnScreenBeforeRemoveEvent | null>();

  const [
    isDraftModalOpen,
    draftModalParams,
    openDraftModalParams,
    closeDraftModalParams,
  ] = useModalParam<{
    cancel: () => void;
    saveDraft: () => void;
    deleteDraft: () => void;
  }>(false);

  const [
    isSuccessModalOpen,
    ,
    openSuccessModalParams,
    closeSuccessModalParams,
  ] = useModalParam<{}>(false);

  const [
    isLocationPermissionModalOpen,
    locationPermissionModalProps,
    openLocationPermissionModalParams,
    closeLocationPermissionModalParams,
  ] = useModalParam<{ close?: () => void }>(false);

  const [
    isReturnFormModalOpen,
    returnFormModalProps,
    openReturnFormModalParams,
    closeReturnFormModalParams,
  ] = useModalParam<{
    submit?: (message: string, sectionId: string) => void | Promise<void>;
    sections?: ReturnFormSectionInfo[];
  }>();
  const [isReturningFormResponse, setIsReturningFormResponse] = useState(false);
  const [isSharingToLocationLeader, setIsSharingToLocationLeader] =
    useState(false);

  const toast = useToast();
  const { successToast } = useKNOWToast();
  const navigation = useNavigation();

  const formWebViewRef = useRef<FormWebviewRef | null>(null);

  const isWF = type === 'received';
  const isNewForm = type === 'new';

  const isEditing = useMemo(() => {
    if (type === 'new' || type === 'draft') {
      return true;
    }
    return !getIsFormResponseSubmitted(formResponse ?? {});
  }, [formResponse, type]);

  const [isGoBack, setIsGoBack] = useState(false);

  const navigateBack = useCallback(() => {
    if (parentId && parentType === 'tasks') {
      navigation.navigate(
        'Tasks' as never,
        {
          screen: 'details',
          params: {
            taskId: parentId,
            shareId: parentShareId,
          },
          initial: false,
        } as never
      );
    } else if (parentId && parentType === 'issues') {
      navigation.navigate(
        'Issues' as never,
        {
          screen: 'issues-details',
          params: {
            issueId: parentId,
            shareId: parentShareId,
          },
          initial: false,
        } as never
      );
    } else {
      navigation.goBack();
    }
  }, [navigation, parentId, parentType, parentShareId]);

  useEffect(() => {
    if (isGoBack) {
      navigateBack();
      // errorToast({ header: "Form response doesn't exist!" });
    }
  }, [isGoBack, navigateBack]);

  if (isSuccessModalOpen && parentType === 'issues') {
    navigateBack();
  }

  useEffect(() => {
    const initiateForm = async () => {
      if (currentDBApi && formId) {
        const nugget = (await getFormNugget(
          currentDBApi,
          formId
        )) as FormNugget;
        setFormNugget(nugget);
        if (!formResponse) {
          if (!isNewForm && initiatorId && _responseId && !formResponse) {
            if (type === 'draft') {
              const isResponseStillInDraft = await isFormResponseDraft(
                currentDBApi,
                formId,
                _responseId
              );

              if (!isResponseStillInDraft) {
                setFormResponse({});
                setIsGoBack(true);

                return;
              }
            }
            const formResponseFB = await getFormResponse(
              currentDBApi,
              formId,
              initiatorId,
              _responseId
            );

            if (!formResponseFB) {
              setFormResponse({});
              setIsGoBack(true);

              return;
            }
            setFormResponse(formResponseFB);

            if (type === 'received') {
              // check whether form is in received tab
              const isFormStillInReceivedTab =
                await isResponseStillInUserReceivedTab(
                  currentDBApi,
                  formId,
                  _responseId
                );

              if (!isFormStillInReceivedTab) {
                setReviewMode(true);
              }
            }
          } else if (isNewForm && formId) {
            const newResponseId = await initiateNewResponse(
              currentDBApi,
              formId,
              {
                parentId: webViewOptions?.parentId,
                parentType: webViewOptions?.parentType,
                skipDraft: skipDraft,
              }
            );
            setResponseId(newResponseId);
            setFormResponse({});
          }
        }

        setIsLoading(false);
      }
    };
    setIsLoading(true);
    initiateForm();
  }, [
    currentDBApi,
    formId,
    formResponse,
    initiatorId,
    isNewForm,
    _responseId,
    type,
    webViewOptions?.parentId,
    webViewOptions?.parentType,
    skipDraft,
  ]);

  const buttonLabels = {
    preview: 'Next',
    submit: parentType === 'issues' ? 'Close Issue' : 'Submit',
    edit: 'Edit',
    approve: 'Approve',
    reject: 'Reject',
    type: 'form_workflow',
    send_to_next: 'Send to Next',
  };

  const uploadFiles = useCallback(
    async (files: File[], progressCB: (progress: number) => void) => {
      try {
        const path = `${currentDBApi?.organization ?? 'na'}/${
          currentDBApi?.getLoggedInUserId() ?? 'user-id'
        }/${formId}/${responseId}`;
        const results = await currentDBApi?.uploadMultipleFiles(
          files,
          path,
          progressCB
        );
        return results;
      } catch (e: any) {
        console.log(e);
        toast.show({
          title: 'Some files failed to upload',
          bg: 'red.500',
        });
        return;
      }
    },
    [currentDBApi, formId, responseId, toast]
  );

  const writeResponses = useCallback(
    async (response: any) => {
      if (currentDBApi && responseId) {
        await writeFormResponses(
          currentDBApi,
          formId,
          responseId,
          initiatorId ?? currentDBApi.getLoggedInUserId(),
          response
        );
      }
    },
    [currentDBApi, formId, responseId, initiatorId]
  );

  const canShareForm = useMemo(
    () => formNugget && getIsFormCanShare(formNugget),
    [formNugget]
  );

  const submitResponse = useCallback(
    async (isRejected: boolean = false) => {
      if (currentDBApi && responseId && formNugget) {
        try {
          await submitFormResponses(currentDBApi, formId, responseId, {
            requireLocation: getFormRequireLocation(formNugget),
            isRejected,
            isWF,
            initiatorId,
            parentId: webViewOptions?.parentId ?? formResponse?.parentId,
            parentType: webViewOptions?.parentType ?? formResponse?.parentType,
            formName: formNugget.name,
            authorDetails: getCurrentUserDetails(),
            onLocationAttempt: openLocationPermissionModalParams,
            onLocationAcquired: closeLocationPermissionModalParams,
            onLocationRejected: () => {
              openLocationPermissionModalParams({
                close: closeLocationPermissionModalParams,
              });
            },
          });
          openSuccessModalParams();

          return { formId, responseId };
        } catch (err: any) {
          console.log(err);

          return null;
        }
      }
      return null;
    },
    [
      currentDBApi,
      responseId,
      formNugget,
      formId,
      isWF,
      initiatorId,
      webViewOptions?.parentId,
      webViewOptions?.parentType,
      formResponse?.parentId,
      formResponse?.parentType,
      openLocationPermissionModalParams,
      closeLocationPermissionModalParams,
      openSuccessModalParams,
      getCurrentUserDetails,
    ]
  );

  const onConfirmSuccessModal = useCallback(() => {
    closeSuccessModalParams();
    navigateBack();
  }, [closeSuccessModalParams, navigateBack]);

  const sendToNextResponse = useCallback(
    async (shareRequest: any, sectionId?: string) => {
      if (currentDBApi && responseId && formNugget) {
        try {
          await sendToNextFormResponse(
            currentDBApi,
            formId,
            responseId,
            shareRequest,
            {
              requireLocation: getFormRequireLocation(formNugget),
              sectionId,
              initiatorId,
              isWF,
              parentId: webViewOptions?.parentId ?? formResponse?.parentId,
              parentType:
                webViewOptions?.parentType ?? formResponse?.parentType,
              onLocationAttempt: openLocationPermissionModalParams,
              onLocationAcquired: closeLocationPermissionModalParams,
              onLocationRejected: () => {
                openLocationPermissionModalParams({
                  close: closeLocationPermissionModalParams,
                });
              },
            }
          );
          successToast({ header: 'Form has been sent!' });
          navigateBack();
        } catch (err: any) {
          console.log(err);
        }
      }
    },
    [
      currentDBApi,
      responseId,
      formNugget,
      formId,
      initiatorId,
      isWF,
      webViewOptions?.parentId,
      webViewOptions?.parentType,
      formResponse?.parentId,
      formResponse?.parentType,
      openLocationPermissionModalParams,
      closeLocationPermissionModalParams,
      successToast,
      navigateBack,
    ]
  );

  const searchUsers = useCallback(
    async (
      filter: string,
      options: {
        users?: RestrictToUserType[];
        groups?: RestrictToGroupType[];
      }
    ) => {
      const { users = [], groups = [] } = options;
      if (users.length || groups.length) {
        const usersFromGroups = await Promise.all(
          groups.map(async ({ id }) => {
            let members = groupMembers[id];

            if (!members) {
              members =
                (await currentDBApi?.getGroupMembers(id))?.map(
                  prepareUsersDetails
                ) ?? [];
              setGroupMembers((currentGroupMembers) => ({
                ...currentGroupMembers,
                [id]: members,
              }));
            }

            return members;
          })
        );

        const restrictedUserDetails = await Promise.all(
          users.map(async ({ userId }) => {
            let user = userDetailsCache[userId];

            if (!user) {
              user = prepareUsersDetails(
                await currentDBApi?.getUserDetails(userId)
              );

              setUserDetailsCache((currentUserDetailsCache) => ({
                ...currentUserDetailsCache,
                [userId]: user,
              }));
            }

            return user;
          })
        );

        const usersList = uniqBy(
          [...restrictedUserDetails, ...flatten(usersFromGroups)],
          'userId'
        );

        return filter ? searchUsersKT(filter, usersList) : usersList;
      } else {
        if (filter) {
          const usersList = (await currentDBApi?.searchUsersRPC(filter, {
            include: ['avatar', 'hasPhone', 'location'],
            view: 'form_sent_next',
          })) as UserDetailsInterface[];

          const transformedList = usersList.map(prepareUsersDetails);
          return transformedList;
        }

        return [];
      }
    },
    [
      currentDBApi,
      groupMembers,
      setGroupMembers,
      setUserDetailsCache,
      userDetailsCache,
    ]
  );

  const webViewUrl =
    Constant.expoConfig?.extra?.useLocalWebview === '1'
      ? '/api/web-view.html'
      : '/web-view.html';

  const saveDraft = useCallback(() => {
    formWebViewRef.current?.showSpinner();
    setTimeout(() => {
      formWebViewRef.current?.writeData(true);
    });
  }, []);

  const goBack = () => {
    if (parentType === 'issues') {
      navigateBack();
    } else if (
      (type === 'draft' ||
        (type === 'received' && !getIsFormResponseSubmitted(formResponse))) &&
      !reviewMode
    ) {
      saveDraft();
    } else if (type === 'new') {
      openDraftModalParams({
        cancel: closeDraftModalParams,
        saveDraft: async () => {
          if (
            currentDBApi &&
            responseId &&
            webViewOptions?.parentId &&
            webViewOptions.parentType
          ) {
            await addDraftForParentNugget(
              currentDBApi,
              formId,
              formNugget?.name ?? '-',
              responseId,
              webViewOptions?.parentId,
              webViewOptions.parentType,
              getCurrentUserName()
            );
          }
          saveDraft();
        },
        deleteDraft: async () => {
          formWebViewRef.current?.showSpinner();
          if (currentDBApi && responseId) {
            await deleteDraft(currentDBApi, formId, responseId);
          }
          formWebViewRef.current?.closeSpinner();
          closeDraftModalParams();
          navigateBack();
        },
      });
    } else {
      navigateBack();
    }
  };

  const onDataWrite = useCallback(() => {
    formWebViewRef.current?.closeSpinner();
    closeDraftModalParams();
    navigateBack();
  }, [closeDraftModalParams, navigateBack]);

  const onPrintPdfRequest = useCallback(
    async (submit?: boolean) => {
      if (formWebViewRef.current && currentDBApi && responseId) {
        formWebViewRef.current?.showSpinner();
        const [location, formPapterTrail, currentFormResponse] =
          await Promise.all([
            formNugget?.captureLocation && submit ? getLocation() : null,
            getFormPaperTrail(currentDBApi, formId, responseId),
            submit
              ? getFormResponse(currentDBApi, formId, initiatorId, responseId)
              : null,
          ]);

        if (submit && currentFormResponse) {
          formWebViewRef.current.setFormDefinition(
            formNugget,
            currentFormResponse
          );
        }

        setTimeout(
          async () => {
            if (formWebViewRef.current) {
              const htmlString = await formWebViewRef.current.printPdf(
                userDetails,
                orgConfig?.companyInfo ?? {},
                location ?? {},
                formPapterTrail ?? {}
              );

              openPDFFromHTML(htmlString);

              formWebViewRef.current?.closeSpinner();
              // submit && onConfirmSuccessModal();
            }
          },
          submit && currentFormResponse ? 2000 : 1
        );
      }
    },
    [
      currentDBApi,
      formId,
      formNugget,
      initiatorId,
      orgConfig?.companyInfo,
      responseId,
      userDetails,
    ]
  );

  const onPrintSuccessModal = useCallback(() => {
    onPrintPdfRequest(true);
  }, [onPrintPdfRequest]);

  const shareToLocationLeader = useCallback(async () => {
    if (currentDBApi && formId && responseId) {
      setIsSharingToLocationLeader(true);
      await raiseShareFormResponseToLocationLeaderRequest(
        currentDBApi,
        formId,
        responseId,
        {
          userId: getCurrentUserUserId() ?? 'user-id',
          userName: getCurrentUserName(),
        }
      );
      successToast({ header: 'Form has been shared to location leaders!' });
      setIsSharingToLocationLeader(false);
    }
  }, [
    currentDBApi,
    formId,
    getCurrentUserName,
    getCurrentUserUserId,
    responseId,
    successToast,
  ]);

  const returnFormRequest = useCallback(() => {
    if (currentDBApi && formId && responseId && formNugget && formResponse) {
      const submittedSections = getSubmittedSectionId(formNugget, formResponse);
      openReturnFormModalParams({
        submit: async (returnMessage, sectionId) => {
          setIsReturningFormResponse(true);
          await raiseReturnFormResponseRequest(
            currentDBApi,
            formId,
            responseId,
            {
              userId: initiatorId ?? 'user-id',
              returnMessage,
              sectionId,
            }
          );
          setIsReturningFormResponse(false);
          navigateBack();
          successToast({ header: 'Form has been returned!' });
        },
        sections: submittedSections.map((sectionId) => ({
          sectionId,
          senderName: formResponse.responses?.[sectionId].sender.userName,
          name: formNugget.questions.sections[sectionId].title,
        })),
      });
    }
  }, [
    currentDBApi,
    formId,
    formNugget,
    formResponse,
    initiatorId,
    navigateBack,
    openReturnFormModalParams,
    responseId,
    successToast,
  ]);

  const ellipsisOptions = useMemo<EllipsisMenuOption[]>(() => {
    if (!isNewForm && formResponse) {
      let options: string[] = [];
      let currentSectionId: string;
      const isSubmitted = getIsFormResponseSubmitted(formResponse);

      if (type === 'sent') {
        if (isSubmitted) {
          options = [LOCATION_LEADERS_OPTION, PRINT_PDF_OPTION];
        } else {
          options = [PRINT_PDF_OPTION];
        }
      } else if (type === 'received') {
        if (!isSubmitted) {
          currentSectionId = formResponse.sectionId;
          const senderId =
            formResponse.responses?.[currentSectionId]?.sender?.userId;
          if (senderId !== getCurrentUserUserId()) {
            options = [RETURN_FORM_OPTION];
          }
        } else {
          options = [LOCATION_LEADERS_OPTION, PRINT_PDF_OPTION];
        }
      }

      return options
        .filter((key) => {
          if (!canShareForm) {
            return key !== LOCATION_LEADERS_OPTION && key !== PRINT_PDF_OPTION;
          }

          return true;
        })
        .map((key: string) => {
          switch (key) {
            case LOCATION_LEADERS_OPTION:
              return {
                id: key,
                label: 'Share to Location Leaders',
                Icon: <LocationLeaderIcon size="20px" />,
                onClick: shareToLocationLeader,
              };
            case PRINT_PDF_OPTION:
              return {
                id: key,
                label: 'Make PDF',
                Icon: (
                  <ExpoFA5Icon name="file-pdf" color="gray.700" size="20px" />
                ),
                onClick: () => onPrintPdfRequest(),
              };
            case RETURN_FORM_OPTION:
              return {
                id: key,
                label: 'Return',
                Icon: <ReturnIcon size="20px" />,
                onClick: returnFormRequest,
              };
            default:
              return {
                id: key,
                label: 'Share to Location Leaders',
                Icon: <LocationLeaderIcon size="20px" />,
                onClick: shareToLocationLeader,
              };
          }
        });
    }

    return [];
  }, [
    isNewForm,
    formResponse,
    type,
    getCurrentUserUserId,
    shareToLocationLeader,
    onPrintPdfRequest,
    returnFormRequest,
    canShareForm,
  ]);

  const openTask = useCallback(
    (taskId: string) => {
      navigation.navigate(
        'Tasks' as never,
        {
          screen: 'details',
          params: {
            taskId,
            formId,
            responseId,
            initiatorId,
          },
        } as never
      );
    },
    [formId, initiatorId, navigation, responseId]
  );

  const taskDetails = useMemo(() => {
    if (parentId && groupId && questionId) {
      return {
        groupId,
        questionId,
        taskId: parentId,
      };
    }

    return undefined;
  }, [groupId, parentId, questionId]);

  // const onBeforeRemove = useCallback(
  //   (e: OnScreenBeforeRemoveEvent) => {
  //     e.preventDefault();
  //     setCurrentOnScreenBeforeRemoveEvent(e);
  //     openDraftModalParams({
  //       cancel: closeDraftModalParams,
  //       saveDraft: () => {
  //         formWebViewRef.current?.showSpinner();
  //         setTimeout(() => {
  //           formWebViewRef.current?.writeData(true);
  //         });
  //       },
  //       deleteDraft: async () => {
  //         formWebViewRef.current?.showSpinner();
  //         if (currentDBApi && responseId) {
  //           await deleteDraft(currentDBApi, formId, responseId);
  //         }
  //         formWebViewRef.current?.closeSpinner();
  //         closeDraftModalParams();
  //         navigation.dispatch(e.data.action);
  //       },
  //     });
  //   },
  //   [
  //     closeDraftModalParams,
  //     currentDBApi,
  //     formId,
  //     navigation,
  //     openDraftModalParams,
  //     responseId,
  //   ]
  // );

  useHideHeader();
  // useOnScreenBeforeRemove(onBeforeRemove);
  useWebOnBeforeUnload(isEditing);

  return {
    isLoading,
    formNugget,
    formResponse,
    buttonLabels,
    locations: orgConfig?.locations ?? {},
    userInfo: userDetails
      ? prepareUsersDetailsForFormWebview(prepareUsersDetails(userDetails))
      : null,
    webViewUrl,
    isDraftModalOpen,
    draftModalParams,
    formWebViewRef,
    isSuccessModalOpen,
    reviewMode,
    isLocationPermissionModalOpen,
    locationPermissionModalProps,
    ellipsisOptions,
    isReturnFormModalOpen,
    returnFormModalProps,
    isReturningFormResponse,
    canShareForm,
    isSharingToLocationLeader,
    taskDetails,
    closeReturnFormModalParams,
    shareToLocationLeader,
    goBack,
    uploadFiles,
    writeResponses,
    submitResponse,
    searchUsers,
    sendToNextResponse,
    onDataWrite,
    onConfirmSuccessModal,
    onPrintPdfRequest,
    onPrintSuccessModal,
    openTask,
  };
};
