import { get, toPairs, values } from 'lodash';
import { useToast } from 'native-base';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  FirebaseAPI,
  ScoobyAPI,
  ScoobyVerifyOTPType,
  UploadFileResultType,
  User,
  registerNotificationToken,
  NuggetClassificationType,
} from '@know/db';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { CategoryType, prepareUsersDetails } from '@know/transformers';

export interface KNOWDBContextInterface {
  sendOTP: (code: string, phone: string) => Promise<boolean>;
  verifyOTP: (
    code: string,
    phone: string,
    otp: string
  ) => Promise<boolean | undefined>;
  initializedLastCacheEnv: (cb?: (user: User | null) => Promise<void>) => void;
  currentUser: User | null;
  currentDBApi: FirebaseAPI | undefined;
  isLoading: boolean;
  userDetails: UserDetailsInterface | null;
  orgDetails: OrgDetailsInterface | null;
  orgDetailsPreferences: OrgDetailsPreferenceInterface | null;
  orgConfig: OrgConfigInterface | null;
  orgIssueLocations: OrgIssueLocationsInterface | null;
  envConfig: EnvConfigInterface | null;
  signUserOut: () => Promise<void>;
  getCurrentUserUserId: () => string | undefined;
  getCurrentUserName: () => string;
  getCurrentUserDetails: () => ExtendedUserDetailsInterface | null;
  isLoadingConfig: boolean;
  uploadFiles: (
    path: string,
    files: File[],
    progressCB: (progress: number) => void
  ) => Promise<string[]>;
  uploadFilesWithFilesDetails: (
    path: string,
    files: File[],
    progressCB: (progress: number) => void
  ) => Promise<UploadFileResultType[]>;
  canUserCreate: (module: NuggetClassificationType) => boolean;
}
export interface OrgDetailsInterface {
  preferences: {
    appBottomTab: Array<string>;
    moreTabConfig: {
      [key: string]: Array<string>;
    };
  };
  name: string;
}

export interface OrgDetailsPreferenceInterface {
  isIssueReport: boolean;
  isContentCreation: boolean;
}

export interface EnvConfigInterface {
  webview_form_url_v5: string;
}

export type ConfigFormCategoriesType = Record<string, CategoryType>;

export type CompanyInfoType = {
  companyAddress?: string[];
  companyLogo?: string;
  companyName?: string;
};

export interface OrgIssueLocation {
  gps: any;
  isActive: boolean;
  name: string;
}

export interface OrgIssueLocationsInterface {
  [locationTag: string]: OrgIssueLocation;
}

export interface OrgIssueTypeInterface {
  author: string;
  autoRecipients?: {
    groups?: string[];
    users?: string[];
  };
  createdAt: number;
  customForms?: {
    close?: any;
    create?: any;
  };
  dashUserACL?: {
    groups?: string[];
    users?: string[];
  };
  isAutoSharing: boolean;
  name: string;
  notifyLeaders?: boolean;
  serialPrefix: string;
}
export interface OrgConfigInterface {
  attendanceQRSecretKey: string;
  locations: {
    [locationId: string]: {
      name: string;
      isActive: boolean;
    };
  };
  formCategories?: ConfigFormCategoriesType;
  companyInfo?: CompanyInfoType;
  issues?: {
    types: {
      [issueTypeId: string]: OrgIssueTypeInterface;
    };
  };
}

export interface UserDetailsInterface {
  avatar?: string;
  adminGroupAcl?: Array<string>;
  alias?: string;
  androidPreferences?: {
    [key: string]: any;
  };
  appAcl?: {
    [key: string]: any;
  };
  createdAt: number;
  department: string;
  designation: string;
  division: string;
  email?: string;
  faceId?: string;
  faceRecognitionImage?: string;
  firstName: string;
  hasPhone: boolean;
  identifier: string;
  isActive: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isSupervisor: boolean;
  jobType?: string;
  lastName: string;
  location?: string;
  organization: string;
  otherDetails: object;
  preferences: {
    appBottomTab: Array<string>;
    moreTabConfig: {
      [key: string]: Array<string>;
    };
  };
  subDivision?: string;
  uuid: string;
}

export interface ExtendedUserDetailsInterface extends UserDetailsInterface {
  userId: string;
  userName: string;
}

export const KNOWDBContext = createContext<KNOWDBContextInterface>({
  sendOTP: async () => false,
  verifyOTP: async () => false,
  currentUser: null,
  currentDBApi: undefined,
  isLoading: true,
  userDetails: null,
  orgDetails: null,
  orgDetailsPreferences: null,
  orgConfig: null,
  envConfig: null,
  orgIssueLocations: null,
  signUserOut: async () => {},
  getCurrentUserUserId: () => {
    return '';
  },
  getCurrentUserName: () => '',
  getCurrentUserDetails: () => null,
  isLoadingConfig: true,
  initializedLastCacheEnv: async () => {},
  uploadFiles: async () => [],
  uploadFilesWithFilesDetails: async () => [],
  canUserCreate: () => false,
});

export const useKNOWDBContext = () => useContext(KNOWDBContext);

const KNOWDBContextProvider = KNOWDBContext.Provider;

const getUserPreferences = (
  userDetails: UserDetailsInterface | null,
  orgDetails: OrgDetailsInterface | null,
  preference: string
): boolean => {
  const userPreference = get(userDetails, ['preferences', preference]);
  if (userPreference !== undefined) {
    return userPreference;
  }
  return get(orgDetails, ['preferences', preference]);
};

//TODO: move this to KNOW DB
export const KNOWDBProvider: React.FC<{
  isSDK?: boolean;
  enableNotifications: boolean;
}> = ({ children, enableNotifications, isSDK = false }) => {
  const scoobyAPI = useMemo(() => {
    return new ScoobyAPI();
  }, []);

  const [dbAPIMappings, setDbAPIMappings] = useState<{
    [key: string]: FirebaseAPI;
  }>({});
  const [currentDBApi, setCurrentDbApi] = useState<FirebaseAPI | undefined>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetailsInterface | null>(
    null
  );
  const [orgDetails, setOrgDetails] = useState<OrgDetailsInterface | null>(
    null
  );
  const [orgDetailsPreferences, setOrgDetailsPreferences] =
    useState<OrgDetailsPreferenceInterface | null>(null);
  const [orgConfig, setOrgConfig] = useState<OrgConfigInterface | null>(null);
  const [envConfig, setEnvConfig] = useState<EnvConfigInterface | null>(null);
  const [orgIssueLocations, setOrgIssueLocations] =
    useState<OrgIssueLocationsInterface | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingConfig, setIsLoadingConfig] = useState<{
    [key: string]: boolean;
  }>({
    userDetails: true,
    orgDetails: true,
    orgDetailsPreferences: true,
    orgConfig: true,
    envConfig: true,
    orgIssueLocations: true,
  });

  const [previousIsConnected, setIsPreviousConnected] = useState<
    boolean | null | undefined
  >(true);

  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      const currentIsConnected = state.isConnected || isSDK;

      if (previousIsConnected !== currentIsConnected) {
        setIsPreviousConnected(currentIsConnected);
        if (!currentIsConnected) {
          toastIdRef.current = toast.show({
            title: 'No internet connection detected!',
            placement: 'bottom-left',
            ml: '20px',
            duration: null,
          });
        } else {
          toast.close(toastIdRef.current);
        }
      }
    });

    return unsubscribe;
  }, [isSDK, previousIsConnected, toast]);

  const initializedLastCacheEnv = useCallback(
    async (cb?: (user: User | null) => Promise<void>) => {
      try {
        const cachedEnv = await AsyncStorage.getItem(
          'KNOWLastLoggedInEnvironment'
        );
        if (cachedEnv) {
          const [env, cachedEnvConfig]: [string, any] =
            toPairs(JSON.parse(cachedEnv))[0] || {};

          if (env && cachedEnvConfig) {
            const fbAPI = new FirebaseAPI(cachedEnvConfig, env, (user: any) => {
              if (cb) {
                cb(user);
              }
              setCurrentUser(user);
              setIsLoading(false);
              if (enableNotifications) {
                registerNotificationToken(scoobyAPI, fbAPI);
              }
            });

            setDbAPIMappings((currentMapping) => {
              return {
                ...currentMapping,
                [env]: fbAPI,
              };
            });

            setCurrentDbApi(fbAPI);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        console.log('Restoring last env config failed.');
        console.log(err);
        setIsLoading(false);
      }
    },
    [enableNotifications, scoobyAPI]
  );

  useEffect(() => {
    if (scoobyAPI && !isSDK) {
      (async () => {
        await initializedLastCacheEnv();
      })();
    }
  }, [initializedLastCacheEnv, scoobyAPI, isSDK]);

  // live listener on config data
  useEffect(() => {
    if (currentUser && currentDBApi) {
      currentDBApi.listenOnNodeRef(
        currentDBApi.getNodeRef(
          `${currentDBApi.getCommonPath('users')}/${currentUser.uid}`
        ),
        'value',
        (snapshot: any) => {
          const value = snapshot.val();
          setUserDetails(value);
          setIsLoadingConfig((currentIsLoadingConfig) => ({
            ...currentIsLoadingConfig,
            userDetails: false,
          }));
        }
      );

      currentDBApi.listenOnNodeRef(
        currentDBApi.getMultiPathCommon('details'),
        'value',
        (snapshot: any) => {
          const value = snapshot.val();
          setOrgDetails(value);
          setIsLoadingConfig((currentIsLoadingConfig) => ({
            ...currentIsLoadingConfig,
            orgDetails: false,
          }));
        }
      );

      currentDBApi.listenOnNodeRef(
        currentDBApi.getMultiPathCommon('details/preferences'),
        'value',
        (snapshot: any) => {
          const value = snapshot.val();
          setOrgDetailsPreferences(value);
          setIsLoadingConfig((currentIsLoadingConfig) => ({
            ...currentIsLoadingConfig,
            orgDetailsPreferences: false,
          }));
        }
      );

      currentDBApi.listenOnNodeRef(
        currentDBApi.getMultiPathCommon('config'),
        'value',
        (snapshot: any) => {
          const value = snapshot.val();
          setOrgConfig(value);
          setIsLoadingConfig((currentIsLoadingConfig) => ({
            ...currentIsLoadingConfig,
            orgConfig: false,
          }));
        }
      );

      currentDBApi.listenOnNodeRef(
        currentDBApi.getMultiPathCommon('config/locations'),
        'value',
        (snapshot: any) => {
          const value: OrgIssueLocationsInterface = snapshot.val();
          const issueLocations: OrgIssueLocationsInterface = {};
          Object.entries(value).forEach(([locationTag, locationDetails]) => {
            const { isActive } = locationDetails;
            if (isActive) {
              issueLocations[locationTag] = locationDetails;
            }
          });

          setOrgIssueLocations(issueLocations);
          setIsLoadingConfig((currentIsLoadingConfig) => ({
            ...currentIsLoadingConfig,
            orgIssueLocations: false,
          }));
        }
      );

      currentDBApi.listenOnNodeRef(
        currentDBApi.getNodeRef('config'),
        'value',
        (snapshot: any) => {
          const value = snapshot.val();
          setEnvConfig(value);
          setIsLoadingConfig((currentIsLoadingConfig) => ({
            ...currentIsLoadingConfig,
            envConfig: false,
          }));
        }
      );

      return () => {
        currentDBApi.offNodeRef(
          currentDBApi.getNodeRef(
            `${currentDBApi.getCommonPath('users')}/${currentUser.uid}`
          ),
          'value'
        );

        currentDBApi.offNodeRef(
          currentDBApi.getMultiPathCommon('details'),
          'value'
        );

        currentDBApi.offNodeRef(
          currentDBApi.getMultiPathCommon('details/preferences'),
          'value'
        );

        currentDBApi.offNodeRef(
          currentDBApi.getMultiPathCommon('config'),
          'value'
        );

        currentDBApi.offNodeRef(
          currentDBApi.getMultiPathCommon('config/locations'),
          'value'
        );

        currentDBApi.offNodeRef(currentDBApi.getNodeRef('config'), 'value');
      };
    } else {
      setUserDetails(null);
      setOrgDetails(null);
      setOrgConfig(null);
      setEnvConfig(null);
      setOrgIssueLocations(null);
      setOrgDetailsPreferences(null);
    }
  }, [currentUser, currentDBApi]);

  const sendOTP = useCallback(
    async (code: string, phone: string) => {
      const sendOTPResult = await scoobyAPI.sendOTP({ code, phone });
      if (!sendOTPResult) {
        throw new Error('Invaild Phone number!');
      }

      return true;
    },
    [scoobyAPI]
  );

  const verifyOTP = useCallback(
    async (code: string, phone: string, otp: string) => {
      const verifyScoobyOTP = await scoobyAPI.verifyOTP({ code, phone, otp });

      if (typeof verifyScoobyOTP === 'boolean') {
        if (!verifyScoobyOTP) throw new Error('Invalid OTP!');
      } else {
        const {
          orgs = [],
          envs,
          newToken,
          token,
        }: ScoobyVerifyOTPType = verifyScoobyOTP;

        if (!orgs[0]) throw new Error('No organization');

        const { env, orgId }: { env: string; orgId: string } = orgs[0];

        const envFBConfig = get(envs, [env, 'web']);

        if (!envFBConfig) {
          console.log(`No env config found for ${env}`);
          throw new Error('Internal Error: NoEnv');
        }

        let fbAPI: FirebaseAPI = dbAPIMappings[env];

        if (!fbAPI) {
          fbAPI = new FirebaseAPI(envFBConfig, env, setCurrentUser);

          setDbAPIMappings((currentMapping) => {
            return {
              ...currentMapping,
              [env]: fbAPI,
            };
          });
        }

        setCurrentDbApi(fbAPI);

        await AsyncStorage.setItem(
          'KNOWLastLoggedInEnvironment',
          JSON.stringify({ [env]: envFBConfig })
        );

        const signInToken = await fbAPI.getSignInToken(token, orgId, newToken);

        if (!signInToken) {
          console.log('No sign in token');
          throw new Error('Internal Error: NT');
        }

        await fbAPI.signInWithCustomToken(signInToken);

        return true;
      }
    },
    [dbAPIMappings, scoobyAPI]
  );

  const signUserOut = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([currentDBApi?.signOut(), scoobyAPI.signOut()]);
    setIsLoading(false);
  }, [currentDBApi, scoobyAPI]);

  // detect inactive and sign user out
  useEffect(() => {
    if (currentUser && currentDBApi) {
      currentDBApi.listenOnNodeRef(
        currentDBApi.getNodeRef(
          `${currentDBApi.getCommonPath('users')}/${currentUser.uid}/isActive`
        ),
        'value',
        (snapshot: any) => {
          const value = snapshot.val();
          if (!value) {
            signUserOut();
          }
        }
      );

      return () => {
        currentDBApi.offNodeRef(
          currentDBApi.getNodeRef(
            `${currentDBApi.getCommonPath('users')}/${currentUser.uid}/isActive`
          ),
          'value'
        );
      };
    }
  }, [currentUser, currentDBApi, signUserOut]);

  const getCurrentUserUserId = useCallback(() => {
    return currentUser?.uid;
  }, [currentUser?.uid]);

  const getCurrentUserName = useCallback(() => {
    return userDetails
      ? prepareUsersDetails(userDetails).alias ??
          prepareUsersDetails(userDetails).userName
      : '';
  }, [userDetails]);

  const getCurrentUserDetails = useCallback(() => {
    return userDetails ? prepareUsersDetails(userDetails) : null;
  }, [userDetails]);

  const uploadFiles = useCallback(
    async (
      path: string,
      files: File[],
      progressCB: (progress: number) => void
    ) => {
      try {
        const results = await currentDBApi?.uploadMultipleFiles(
          files,
          path,
          progressCB
        );
        return results ?? [];
      } catch (e: any) {
        console.log(e);
        toast.show({
          title: 'Some files failed to upload',
          bg: 'red.500',
        });
        return [];
      }
    },
    [currentDBApi, toast]
  );

  const uploadFilesWithFilesDetails = useCallback(
    async (
      path: string,
      files: File[],
      progressCB: (progress: number) => void
    ) => {
      try {
        const results = await currentDBApi?.uploadMultipleFilesWithFileDetails(
          files,
          path,
          progressCB
        );
        return results ?? [];
      } catch (e: any) {
        console.log(e);
        toast.show({
          title: 'Some files failed to upload',
          bg: 'red.500',
        });
        return [];
      }
    },
    [currentDBApi, toast]
  );

  const canUserCreate = useCallback(
    (module: NuggetClassificationType) => {
      const canUserCreateContent = getUserPreferences(
        userDetails,
        orgDetails,
        'isContentCreation'
      );
      if (!canUserCreateContent) {
        return false;
      }

      switch (module) {
        case 'tasklist':
          return getUserPreferences(userDetails, orgDetails, 'isIssueReport');
        default:
          return false;
      }
    },
    [orgDetails, userDetails]
  );

  return (
    <KNOWDBContextProvider
      value={{
        sendOTP,
        verifyOTP,
        initializedLastCacheEnv,
        getCurrentUserUserId,
        getCurrentUserName,
        getCurrentUserDetails,
        currentUser,
        currentDBApi,
        isLoading: isLoading,
        userDetails,
        orgDetails,
        orgDetailsPreferences,
        orgIssueLocations,
        signUserOut,
        orgConfig,
        envConfig,
        isLoadingConfig: !!values(isLoadingConfig).filter(Boolean).length,
        uploadFiles,
        uploadFilesWithFilesDetails,
        canUserCreate,
      }}
    >
      {children}
    </KNOWDBContextProvider>
  );
};

export { FirebaseAPI };
