//TODO: make a native app friendly version
import React, {
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Pressable,
  Text,
  CameraIcon,
  View,
  ErrorAlert,
  useIsMobile,
  useModalParam,
  useToast,
} from '@know/ui';
import { GalleryIcon } from '../Icons/GalleryIcon';
import { TakePhotoModal } from './TakePhotoModal';
import { checkForFileValidity } from './utils';

export interface UploadImageModalRef {
  openCamera: () => any;
  openUpload: () => any;
}

interface UploadImageModalProps {
  isOpen: boolean;
  close: () => void;
  limit?: number;
  restrictImagePicker?: boolean;
  sizeLimit?: number;
  onSubmit?: (response: File[]) => void;
  showAlertToast?: boolean;
  hideUploadButtonInTakePhotoModal?: boolean;
}

const UploadPressable: React.FC<any> = ({ children, ...props }) => (
  <Pressable
    background="gray.200"
    borderRadius="12px"
    flex={1}
    display={'flex'}
    flexDirection="column"
    justifyContent={'center'}
    alignItems={'center'}
    padding={'20px 12px'}
    {...props}
  >
    {children}
  </Pressable>
);

// const readAsBase64 = async (file: File, sizeLimit?: number) => {
//   if (sizeLimit && file.size > sizeLimit) {
//     throw new Error('FileTooBig');
//   }
//   return new Promise<string>((res, rej) => {
//     try {
//       // eslint-disable-next-line no-undef
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onloadend = () => {
//         // Here, you can access the uploaded photo via the reader's `result` property.
//         res(reader.result as string);
//       };
//     } catch (e: any) {
//       rej(e);
//     }
//   });
// };

export const UploadImageModal = forwardRef<
  UploadImageModalRef,
  UploadImageModalProps
>(
  (
    {
      isOpen,
      close,
      onSubmit,
      limit = 1,
      restrictImagePicker = false,
      sizeLimit = 25 * 1024 * 1024,
      showAlertToast,
      hideUploadButtonInTakePhotoModal,
    },
    ref
  ) => {
    const uploadFileInput = useRef<HTMLInputElement | null>(null);
    const cameraInput = useRef<HTMLInputElement | null>(null);
    const [isShowLimitAlert, setIsShowLimitAlert] = useState(false);
    const [isShowSizeAlert, setIsShowSizeAlert] = useState(false);
    const [isShowTypeAlert, setIsShowTypeAlert] = useState(false);
    const [
      isTakePhotoModalOpen,
      takePhotoModalParam,
      openTakePhotoModal,
      closeTakePhotoModal,
    ] = useModalParam<{ restrictImagePicker: boolean }>();

    const isMobile = useIsMobile();

    const resetAlerts = useCallback(() => {
      setIsShowLimitAlert(false);
      setIsShowSizeAlert(false);
      setIsShowTypeAlert(false);
    }, []);

    const onClose = useCallback(() => {
      resetAlerts();
      close();
    }, [close, resetAlerts]);

    const toast = useToast();

    const onError = (type: 'size' | 'number' | 'type') => {
      const toastId = Date.now();
      const header =
        type === 'size'
          ? 'Image(s) exceeds size limit'
          : type === 'type'
          ? 'Invalid file type'
          : 'Too many files uploaded';
      const subText =
        type === 'size'
          ? `Max file size is ${
              sizeLimit / 1024 ** 2
            } MB. Please try again with a smaller image.`
          : type === 'type'
          ? 'Only images are allowed. Please select valid image files to upload.'
          : `You can only upload up to ${limit} images. Please select fewer images and try again.`;
      toast.show({
        id: toastId,
        render: () => (
          <ErrorAlert
            header={header}
            subText={subText}
            onClose={() => toast.close(toastId)}
            containerProps={{ mt: '-30px', w: 'calc(100vw - 24px)' }}
          />
        ),
        placement: 'top',
        duration: null,
      });
    };

    const onUploadInputChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      resetAlerts();
      const _files = e.target.files;
      if (_files) {
        const files = Array.from(e.target.files ?? []);
        if (files.length > limit) {
          if (showAlertToast) {
            return onError('number');
          } else {
            return setIsShowLimitAlert(true);
          }
        }
        try {
          // const base64Files = await Promise.all(
          //   files.map((f) => readAsBase64(f, sizeLimit))
          // );
          checkForFileValidity(files, ['image/*'], sizeLimit);
          onSubmit && onSubmit(files);
          onClose();
        } catch (error: any) {
          if (error.message === 'FileTooBig') {
            if (showAlertToast) {
              onError('size');
            } else {
              setIsShowSizeAlert(true);
            }
          } else if (error.message === 'InvalidType') {
            if (showAlertToast) {
              onError('type');
            } else {
              setIsShowTypeAlert(true);
            }
          }
        }
      }
    };

    const onClickTakePhotoPressable = useCallback(() => {
      if (isMobile) {
        cameraInput.current?.click();
      } else {
        openTakePhotoModal({ restrictImagePicker });
      }
    }, [isMobile, openTakePhotoModal, restrictImagePicker]);

    const onClickUploadPressable = useCallback(() => {
      uploadFileInput.current?.click();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        openCamera() {
          onClickTakePhotoPressable();
        },
        openUpload() {
          onClickUploadPressable();
        },
      };
    });

    const onUploadPhotoFromCamera = useCallback(
      async (file: File) => {
        onSubmit && onSubmit([file]);
        onClose();
      },
      [onSubmit, onClose]
    );

    // useEffect(() => {
    //   if (isOpen && restrictImagePicker) {
    //     onClickTakePhotoPressable();
    //   }
    // }, [isOpen, onClickTakePhotoPressable, restrictImagePicker]);

    return (
      <>
        <Modal isOpen={isOpen} closeOnOverlayClick onClose={onClose} size="xl">
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header pb={0} borderBottomWidth={0}>
              Upload Image
            </Modal.Header>
            <Modal.Body display={'flex'} flexDirection={'column'}>
              <View display={'flex'} flexDirection={'row'} w={'100%'}>
                <UploadPressable onPress={onClickTakePhotoPressable}>
                  <CameraIcon size="32px" />
                  <Text textAlign={'center'} fontWeight={600}>
                    Take Photo
                  </Text>
                </UploadPressable>
                {restrictImagePicker ? null : (
                  <UploadPressable ml="16px" onPress={onClickUploadPressable}>
                    <GalleryIcon size="32px" />
                    <Text textAlign={'center'} fontWeight={600}>
                      Upload Photo from Library
                    </Text>
                  </UploadPressable>
                )}
              </View>
              {isShowLimitAlert ? (
                <ErrorAlert
                  header="Too many images uploaded"
                  subText={`You can only upload up to ${limit} images. Please select fewer images and try again.`}
                />
              ) : null}
              {isShowSizeAlert ? (
                <ErrorAlert
                  header="Image exceeds size limit"
                  subText={`Max file size is ${
                    sizeLimit / 1024 ** 2
                  } MB. Please choose a smaller image.`}
                />
              ) : null}
              {isShowTypeAlert ? (
                <ErrorAlert
                  header="Invalid file type"
                  subText={
                    'Only images are allowed. Please select a valid image file to upload.'
                  }
                />
              ) : null}
            </Modal.Body>
          </Modal.Content>
          <input
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ display: 'none' }}
            ref={uploadFileInput}
            onChange={onUploadInputChange}
            accept="image/*"
            multiple={limit > 1}
            type="file"
          />
          <input
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ display: 'none' }}
            ref={cameraInput}
            onChange={onUploadInputChange}
            accept="image/*"
            type="file"
            capture="environment"
          />
        </Modal>
        {isTakePhotoModalOpen ? (
          <TakePhotoModal
            isOpen={isTakePhotoModalOpen}
            close={closeTakePhotoModal}
            onClickUpload={onClickUploadPressable}
            onSubmit={onUploadPhotoFromCamera}
            hideUploadButton={hideUploadButtonInTakePhotoModal}
            {...takePhotoModalParam}
          />
        ) : null}
      </>
    );
  }
);
