import React from 'react';
import { Modal } from '@know/ui';
import type { ThemeComponentSizeType } from 'native-base/lib/typescript/components/types';

interface IStyles {
  minHeight: string;
  bgColor: string;
  marginTop: string;
  borderRadius: number;
}

interface IBodyStyle {
  margin?: number;
  padding?: number;
}

interface IBackDropCss {
  opacity: number;
}
export interface IKNOWModal {
  children: React.ReactChild;
  modalVisible: boolean;
  backdropVisible: boolean;
  size: ThemeComponentSizeType<'Modal'>;
  handleToggleModal: (isAPICall: boolean) => void;
  styles?: IStyles | IBodyStyle;
  bodyStyle?: IBodyStyle;
  minHeight?: string;
  bgColor: string;
  marginTop?: string;
  borderRadius: number;
  backDropCss?: IBackDropCss;
}

export const KNOWModal: React.FC<IKNOWModal> = ({
  children,
  size,
  modalVisible,
  backdropVisible,
  handleToggleModal,
  styles,
  minHeight,
  bgColor,
  marginTop,
  bodyStyle,
  borderRadius,
  backDropCss = {},
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={modalVisible}
        onClose={handleToggleModal}
        size={size}
        backdropVisible={backdropVisible}
        _backdrop={backDropCss}
      >
        <Modal.Content
          {...styles}
          minHeight={minHeight}
          bgColor={bgColor}
          marginTop={marginTop}
          borderRadius={borderRadius}
        >
          <Modal.Body {...bodyStyle}>{children}</Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};
