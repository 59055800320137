import { Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { FirebaseAPI } from "./FirebaseAPI";

const vapidKey = 'BOB6VrV7DCRw4v92PA3tn8VN3ZSyOvop8QgZhXmxY3R27K5wJ2CVgHvCEmKHkjyWpmelYxwPoGjx7z2FQkNFNCs';

async function requestPermission() {
  console.log('Requesting permission...');
  return new Promise((resolve, _reject) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  })
}

export async function registerNotificationToken(scoobyAPI: FirebaseAPI, fbAPI: FirebaseAPI) {
  const messaging = getMessaging(scoobyAPI.app);
  try {
    const token = await getToken(messaging, { vapidKey });
    if (token) {
      console.log(token);
      // Update to FB with web push notification token
      await fbAPI.fbSet(`/deviceIds/${fbAPI.getLoggedInUserId()}/web`, token);
      listenForMessages(messaging)
    } else {
      const granted = await requestPermission();
      if (granted) {
        await registerNotificationToken(scoobyAPI, fbAPI);
      }
    }
  } catch (err: any) {
    console.log(err, err?.stack)
  }
}

export function listenForMessages(messaging: Messaging) {
  onMessage(messaging, (payload) => {
    const { data: { title, body } = {} } = payload
    const notificationTitle = title;

    // new Notification(notificationTitle, { data: body });
  })
}
