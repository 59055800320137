import React from 'react';

import type { DrawerHeaderProps } from '@react-navigation/drawer';
import { getHeaderTitle } from '@react-navigation/elements';
import { Box, Heading } from 'native-base';
import { Trans } from '@know/i18n';
interface CustomHeaderProps extends DrawerHeaderProps {
  actionButton?: JSX.Element | null;
}

export function Header({ route, options, actionButton }: CustomHeaderProps) {
  const title = getHeaderTitle(options, route.name);

  return (
    <Box mt={5} mb={3} mx={'16px'} justifyContent={'center'}>
      <Heading
        fontFamily={'heading'}
        fontWeight={'bolder'}
        color={'gray.950'}
        fontSize={'md'}
      >
        <Trans ns="menus">{title}</Trans>
      </Heading>
      {actionButton ? (
        <Box position={'absolute'} right={0}>
          {actionButton}
        </Box>
      ) : null}
    </Box>
  );
}
