import React, { useState } from 'react';
import { View, Box, Image } from '@know/ui';
import { ArrowRoundedBtn } from './TextImagePDF';
import { StyleSheet } from 'react-native';
import type { IFullScreenImage } from './types';

export const FullScreenImage: React.FC<IFullScreenImage> = ({
  fullScreenImageObj,
  handleImageConsumed,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const imagesPayload = fullScreenImageObj?.payload?.images;

  return (
    <View flex={1} bgColor="black">
      {imagesPayload?.length > 1 && (
        <Box
          position="absolute"
          zIndex={1}
          right={0}
          left={0}
          bottom={0}
          h={'80px'}
          bg={{
            linearGradient: {
              colors: ['trasparent.100', 'trasparent.300'],
              start: [0, 0],
              end: [0, 1],
            },
          }}
        />
      )}
      {imagesPayload?.length > 1 && (
        <Box
          position="absolute"
          zIndex={1}
          left={0}
          top={0}
          bottom={0}
          w={'80px'}
          bg={{
            linearGradient: {
              colors: ['trasparent.100', 'trasparent.300'],
              start: [1, 0],
              end: [0, 0],
            },
          }}
        />
      )}
      {imagesPayload?.length > 1 && (
        <Box
          position="absolute"
          zIndex={1}
          top={0}
          right={0}
          bottom={0}
          w={'80px'}
          bg={{
            linearGradient: {
              colors: ['trasparent.100', 'trasparent.300'],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        />
      )}
      <Image
        alt="full screen image"
        w="100%"
        source={{
          uri: imagesPayload[selectedImageIndex].url,
        }}
        h="100%"
        resizeMode="contain"
      />
      <View
        style={StyleSheet.absoluteFill}
        position="absolute"
        justifyContent="center"
        zIndex={2}
      >
        <View
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          {selectedImageIndex > 0 ? (
            <View marginLeft="10px">
              <ArrowRoundedBtn
                iconName="chevron-back"
                onPress={() => {
                  setSelectedImageIndex(selectedImageIndex - 1);
                }}
              />
            </View>
          ) : (
            <View />
          )}

          {selectedImageIndex < imagesPayload?.length - 1 ? (
            <View marginRight="10px">
              <ArrowRoundedBtn
                iconName="chevron-forward"
                onPress={() => {
                  handleImageConsumed(selectedImageIndex + 1);
                  setSelectedImageIndex(selectedImageIndex + 1);
                }}
              />
            </View>
          ) : (
            <View />
          )}
        </View>
      </View>
      <View
        position="absolute"
        bottom="10px"
        flexDirection="row"
        w="100%"
        justifyContent="center"
        zIndex={2}
      >
        {imagesPayload.length > 1 &&
          imagesPayload?.map((_, index) => {
            return (
              <View
                marginX="4px"
                bgColor={index === selectedImageIndex ? 'primary.500' : 'white'}
                w="8px"
                h="8px"
                borderRadius={10}
              />
            );
          })}
      </View>
    </View>
  );
};
