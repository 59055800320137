import React, { useMemo } from 'react';

import { DATE_TIME_FORMAT } from '@know/constants';
import moment from 'moment';

export function DateTime({
  time,
  format = DATE_TIME_FORMAT,
}: {
  time: number;
  format?: string;
}) {
  const dateString = useMemo(() => {
    if (time) {
      return moment(time).format(format);
    }
    return '';
  }, [time, format]);
  return <React.Fragment>{dateString}</React.Fragment>;
}
