import React, { useCallback, useState } from 'react';
import {
  Modal,
  Box,
  Pressable,
  FlatList,
  WithKNOWLoader,
  // useIsMobile,
  InputFilter,
} from '@know/ui';

import { SelectItemType } from './types';
import { searchItems } from '@know/transformers';

interface SelectItemModalProps {
  isOpen: boolean;
  title?: string;
  isLoading?: boolean;
  options?: SelectItemType[];
  isMulti?: boolean;
  showFilter?: boolean;
  onClose: () => void;
  getItemComponent: (item: SelectItemType, isHovered: boolean) => JSX.Element;
  onSubmit?: (items: SelectItemType[]) => void;
  keyExtractor: (item: SelectItemType, index: number) => string;
}

function SelectItemComponent({
  item,
  onPress,
  getItemComponent,
}: Readonly<{
  item: SelectItemType;
  onPress: () => void;
  getItemComponent: (item: SelectItemType, isHovered: boolean) => JSX.Element;
}>) {
  return (
    <Pressable
      display={'flex'}
      flexDir={'row'}
      alignItems={'center'}
      onPress={onPress}
    >
      {({ isHovered }) => getItemComponent(item, isHovered)}
    </Pressable>
  );
}

const Separator = () => <Box h={'1px'} w={'100%'} bg={'gray.300'} />;

export function SelectItemModal({
  isOpen,
  title,
  options = [],
  isLoading = false,
  isMulti = false,
  showFilter = true,
  onClose,
  getItemComponent,
  onSubmit,
  keyExtractor,
}: Readonly<SelectItemModalProps>) {
  const [filteredOptions, setFilteredOptions] =
    useState<SelectItemType[]>(options);

  const _onFilterChange = useCallback(
    (f: string) => {
      const results = searchItems(f, options);
      setFilteredOptions(results);
    },
    [options]
  );

  const onPressItem = (item: SelectItemType) => {
    if (!isMulti) {
      onSubmit?.([item]);
      onClose();
    }
  };

  const renderItem = ({ item }: { item: SelectItemType }) => {
    return (
      <SelectItemComponent
        getItemComponent={getItemComponent}
        item={item}
        onPress={() => onPressItem(item)}
      />
    );
  };

  console.log('know-ui/src/Components/Modals/SelectItemModal.tsx', { options });

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} closeOnOverlayClick>
      <Modal.Content maxH={'80%'}>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0}>
          {title ?? 'Search users'}
        </Modal.Header>
        {showFilter ? (
          <Modal.Footer>
            <Box w={'100%'}>
              <InputFilter
                hasFilter={true}
                onChange={_onFilterChange}
                filterChangeDelay={300}
              />
            </Box>
          </Modal.Footer>
        ) : null}
        <Modal.Body px={0} pt={0}>
          <WithKNOWLoader isLoading={isLoading} py={'12px'}>
            <Box w={'100%'}>
              <FlatList
                data={filteredOptions}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                ItemSeparatorComponent={Separator}
              />
            </Box>
          </WithKNOWLoader>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
