import React from 'react';
import { createStackNavigator, FormsBreadCrumbHeader } from '@know/ui';

import { FolderItemsPage } from './FolderItemsPage';
import {
  FormWebViewPage,
  linking as FormWebViewLinking,
} from './FormWebViewPage';
import { SentsItemsPage } from './SentsItemsPage';
import { ReceivedItemsPage } from './ReceivedItemsPage';
import {
  FormsListNavigator,
  linking as FormsListNavigatorLinking,
} from './FormsListNavigator';

const FormsStackNavigator = createStackNavigator();

export const linking = {
  initialRouteName: 'list',
  screens: {
    webview: FormWebViewLinking,
    list: FormsListNavigatorLinking,
  },
};

export function FormsNavigator() {
  return (
    <FormsStackNavigator.Navigator initialRouteName="list">
      <FormsStackNavigator.Screen
        name="webview"
        component={FormWebViewPage}
        options={{
          headerShown: false,
          title: 'Form',
        }}
      />
      <FormsStackNavigator.Screen
        name="folder"
        component={FolderItemsPage}
        options={{
          headerShown: true,
          header: FormsBreadCrumbHeader,
          title: '',
        }}
      />
      <FormsStackNavigator.Screen
        name="sentFormItems"
        component={SentsItemsPage}
        options={{
          headerShown: true,
          header: FormsBreadCrumbHeader,
          title: '',
        }}
      />
      <FormsStackNavigator.Screen
        name="receivedFormItems"
        component={ReceivedItemsPage}
        options={{
          headerShown: true,
          header: FormsBreadCrumbHeader,
          title: '',
        }}
      />
      <FormsStackNavigator.Screen
        name="list"
        component={FormsListNavigator}
        options={{
          headerShown: false,
        }}
      />
    </FormsStackNavigator.Navigator>
  );
}
