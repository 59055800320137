import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import {
  useNavigation,
  KNOWDBContext,
  useRecoilValue,
  IQuizQuestion,
  useRecoilState,
  IScoreCard,
  useSetRecoilState,
  StackActions,
} from '@know/ui';
import {
  currentCourseTabAtom,
  isLessonConsumedAtom,
  lessonDetailsAtom,
  lmsReportsPayloadAtom,
  progressLMSOfCourseShareSelector,
  progressQuizLMSOfCourseShareSelector,
  quizCardsAttemptsCountAtom,
  quizResultAtom,
  scromProgressAtom,
  sessionIdAtom,
} from '../state';
import type {
  ILessonCardType,
  ILessonDetails,
  ILMSProgressCourse,
  IQuizAnalyticsResponse,
} from '../types';
import { get, isEqual, sortBy } from 'lodash';
import { useCourseFeed } from '../MyCoursesPage/useCourseFeed';
import useLMSAnalyticsCallback from '../CourseDetailsPage/useLMSAnalyticsCallback';

const scoreCardState = {
  correct: 0,
  skipped: 0,
  wrong: 0,
};
const VIDEO_TOOLTIP_TEXT = 'Please watch the video completely to proceed.';
const VIDEO_PDF_TOOLTIP_TEXT =
  'Please watch video completely and view all of the PDF to proceed.';
const AUDIO_PDF_TOOLTIP_TEXT =
  'Please finish listening to the entire audio clip and view all of the PDF to proceed.';
const AUDIO_IMAGE_TOOLTIP_TEXT =
  'Please view all images & finish listening to the entire audio clip to proceed.';
const AUDIO_PDF_IMAGE_TOOLTIP_TEXT =
  'Please finish listening to the entire audio clip, view all of the PDF and images to proceed.';
const AUDIO_TOOLTIP_TEXT =
  'Please finish listening to the entire audio clip to proceed.';
const PDF_TOOLTIP_TEXT = 'Please view all of the PDF to proceed.';
const SCORM_TOOLTIP_TEXT = 'Please complete the SCORM course to proceed.';
const IMAGES_TOOLTIP_TEXT = 'Please view all of the images to proceed.';
const IMAGES_PDF_TOOLTIP_TEXT =
  'Please view all of the images and PDF to proceed.';
const HOTSPOT_TOOLTIP_TEXT =
  'Tap on the correct area of the image to proceed to the next card.';

export const useLessonCard = (
  courseId: string,
  lessonId: string,
  cardId: string,
  cards: any,
  journeyId: string,
  lastSeenCardId: string,
  setLastSeenCardId: any
) => {
  const navigation = useNavigation();
  const currentSeq = cards?.find((cD: any) => cD?.id === cardId)?.seq!;
  const totalSteps = cards?.length || 0;
  const { currentDBApi } = useContext(KNOWDBContext);
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const [quizCardsAttempts, setQuizCardsAttempts] = useRecoilState(
    quizCardsAttemptsCountAtom
  );
  const setIsLessonConsumed = useSetRecoilState(isLessonConsumedAtom);
  const [isQuizIntroSeen, setIsQuizIntroSeen] = useState<boolean>(false);
  const [consumedImages, setConsumedImages] = useState<any>([0]);
  const [hotspotConsumed, setHotspotConsumed] = useState<any>(false);
  const [consumedPDF, setConsumedPDF] = useState<any>([]);
  const [videoConsume, setVideoConsume] = useState<{
    consumeTime: number;
    isVideoConsumed: boolean;
    isVideoEnd: boolean;
  }>({
    consumeTime: 0,
    isVideoConsumed: false,
    isVideoEnd: false,
  });
  const [audioConsume, setAudioConsume] = useState<{
    consumeTime: number;
    isAudioConsumed: boolean;
    isAudioEnd: boolean;
  }>({
    consumeTime: 0,
    isAudioConsumed: false,
    isAudioEnd: false,
  });
  const [disableNextBtn, setDisableNextBtn] = useState<boolean>(true);
  const [isHidePrevNext, setHidePrevNext] = useState<boolean>(false);
  const isFirstCard = cardId === cards[0]?.id;
  const progressSecond = useRef<number>(0);
  const [hideTooltip, setHideTooltip] = useState<{
    isHidden: boolean;
    text: string;
  }>({
    isHidden: true,
    text: '',
  });
  const [isQuizFinish, setIsQuizFinish] = useState<boolean>(false);
  const [scoreCard, setScoreCard] = useState<IScoreCard>(scoreCardState);
  const [isScormCompleted, setIsScormCompleted] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const sessionId = useRecoilValue(sessionIdAtom);
  const { cardInfo } = useCourseFeed(journeyId ?? courseId);
  const lessons: any =
    useRecoilValue<{ [key: string]: ILessonDetails[] }>(lessonDetailsAtom);

  const progressLMS = useRecoilValue<ILMSProgressCourse>(
    progressLMSOfCourseShareSelector(journeyId ?? courseId)
  );

  const [progressQuizLMS, setProgressQuizLMS] =
    useRecoilState<ILMSProgressCourse>(
      progressQuizLMSOfCourseShareSelector(journeyId ?? courseId)
    );
  const [quizAttemptedCount, setQuizAttemptedCount] = useState(
    get(
      progressQuizLMS,
      journeyId
        ? `${cardInfo?.shareId}.${courseId}.${lessonId}.cardProgress.${cardId}.attempted`
        : `${cardInfo?.shareId}.${lessonId}.cardProgress.${cardId}.attempted`,
      0
    )
  );
  const [surveyConsumed, setSurveyConsumed] = useState<{
    isSurveyConsumed: boolean;
    surveyAnswer: any;
  }>({
    isSurveyConsumed: false,
    surveyAnswer: {},
  });

  const [quizResult, setQuizResults] = useRecoilState(quizResultAtom);
  const [scromProgress, setScromProgress] = useRecoilState(scromProgressAtom);
  const setReportsPayload = useSetRecoilState(lmsReportsPayloadAtom);
  const isCardConsumed = useMemo(() => {
    return get(
      progressLMS,
      journeyId
        ? `${cardInfo?.shareId}.${courseId}.${lessonId}.cardProgress.${cardId}`
        : `${cardInfo?.shareId}.${lessonId}.cardProgress.${cardId}`,
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressLMS]);

  const currentCard = cards?.find((card: any) => card?.id === cardId);
  const cardImages = get(currentCard, `payload.images`, {});
  const cardPDF = get(currentCard, `payload.pdf`, {});

  const isLearningPath = cardInfo?.type === '"Learning Journey"';

  // LMS Analytics Reports Callback
  const {
    progressLMS: progressLMSReport,
    isCourseCompleted,
    totalCourseCards,
  } = useLMSAnalyticsCallback(cardInfo);

  const handleQuizPreviousResponses = (data: any, activeShareId: string) => {
    const quizAnalyticsPayload: IQuizAnalyticsResponse[] = [];

    for (const shareId in data) {
      if (data?.hasOwnProperty(shareId) && shareId === activeShareId) {
        const courses = data?.[shareId];
        for (const _courseId in courses) {
          if (courses?.hasOwnProperty(_courseId)) {
            const lesson = courses?.[_courseId];
            if (typeof lesson === 'object') {
              for (const _cardId in lesson?.cardProgress) {
                if (lesson?.cardProgress.hasOwnProperty(_cardId)) {
                  const _currentCard =
                    cards?.find((card: any) => card?.id === _cardId) ??
                    currentCard;
                  const currentProgress = lesson?.cardProgress[_cardId];
                  const noOfAttemptsMade = quizCardsAttempts?.[_cardId] ?? 0;
                  const noOfQuestionAnswered = currentProgress?.res?.filter(
                    (i: any) => i?.r !== ''
                  )?.length;
                  const noOfCorrectAnswer =
                    +currentProgress?.s?.split('/')?.[0];
                  const isQuizPass =
                    noOfCorrectAnswer >= +_currentCard.passMark;
                  if (quizCardsAttempts?.[_cardId]) {
                    quizAnalyticsPayload.push({
                      cardId: _cardId,
                      noOfAttemptsMade,
                      quizStatus: isQuizPass ? 'pass' : 'fail',
                      noOfQuestionAnswered,
                      noOfCorrectAnswer,
                    });
                  }
                }
              }
            }
          }
        }
      }
    }
    return quizAnalyticsPayload;
  };

  const getQuizCards = async (shareId: string) => {
    const { courseProgressQuiz } = await handleGetProgressQuizLMS();
    const quizCards = handleQuizPreviousResponses(courseProgressQuiz, shareId);
    return quizCards;
  };

  const handleReportsPayload = async (_courseId: string) => {
    const {
      hasCourseProgress,
      totalCardsConsumed: _totalCardsConsumed,
      progress,
    } = progressLMSReport(isLearningPath, _courseId);

    const quizCards = await getQuizCards(cardInfo?.shareId);
    let courseStatus: 'inProgress' | 'notStarted' | 'completed';
    switch (hasCourseProgress) {
      case true:
        courseStatus = 'inProgress';
        break;
      case false:
        courseStatus = 'notStarted';
        break;
      default:
        courseStatus = 'notStarted';
        break;
    }

    if (isCourseCompleted(_courseId, isLearningPath)) {
      courseStatus = 'completed';
    }

    let totalCardsConsumed = _totalCardsConsumed;

    if (!progress?.cardProgress?.[cardId]?.consumedAt) {
      totalCardsConsumed += 1;
    }

    const payload = {
      courseId: _courseId,
      journeyId: cardInfo?.journeyId || null,
      shareId: cardInfo?.shareId,
      languageSelected: cardInfo?.selectedLanguage,
      courseStatus, // inProgress, notStarted, completed
      totalCardsConsumed,
      sessionId,
      type: 'callback',
      dt: Date.now(),
    };
    setReportsPayload((prevState: any) => {
      const state = { ...prevState };
      const stateCardsCompletedInCurrentSession =
        state?.[_courseId]?.cardsCompletedInCurrentSession ?? 0;
      const cardsCompletedInCurrentSessionIds =
        state?.[_courseId]?.cardsCompletedInCurrentSessionIds ?? {};
      const isCardExists = cardsCompletedInCurrentSessionIds?.[cardId] ?? false;

      state[_courseId] = {
        sessionStartTime: Date.now(),
        ...prevState[_courseId],
        totalCards: totalCourseCards,
        ...payload,
        quizCards:
          currentCard.type === 'quiz'
            ? quizCards
            : prevState?.[_courseId]?.quizCards?.length > 0
            ? prevState?.[_courseId]?.quizCards
            : [],
        cardsCompletedInCurrentSessionIds: {
          ...cardsCompletedInCurrentSessionIds,
          [cardId]: true,
        },
        cardsCompletedInCurrentSession: !isCardExists
          ? +stateCardsCompletedInCurrentSession + 1
          : +stateCardsCompletedInCurrentSession,
      };

      if (
        (state[_courseId]?.cardsCompletedInCurrentSessionIds > 0 ||
          state[_courseId]?.totalCardsConsumed > 0) &&
        state[_courseId]?.courseStatus === 'notStarted'
      ) {
        state[_courseId].courseStatus = 'inProgress';
      } else if (
        state[_courseId]?.totalCardsConsumed === state[_courseId]?.totalCards
      ) {
        state[_courseId].courseStatus = 'completed';
      }
      return state;
    });
  };

  const consumeCard = async (
    action: 'consumed' | 'completed',
    progSec?: number
  ) => {
    if (
      currentCourseTab !== 'completed-courses' &&
      !isCardConsumed.hasOwnProperty('consumedAt')
    ) {
      const shareId = cardInfo?.shareId;
      if (courseId && lessonId && shareId && cardId) {
        const payload = {
          cardId,
          courseId,
          dt: Date.now(),
          lang: cardInfo.selectedLanguage,
          shareId,
          sessionId,
          lessonId,
          progSec: progSec ?? 0,
          journeyId,
        };
        if (action === 'consumed') {
          if (currentSeq + 1 === cards?.length) {
            setLastSeenCardId(cardId);
          }
          currentDBApi?.setUserFeedCourseInfo('CardConsumed', payload);
          if (currentCard.type !== 'quiz') {
            setTimeout(() => handleReportsPayload(courseId), 1000);
          }
          if (currentSeq + 1 === cards?.length) {
            setIsLessonConsumed(true);
            currentDBApi?.setUserFeedCourseInfo('CardCompleted', payload);
            currentDBApi?.setUserFeedCourseInfo('LessonConsumed', payload);
            currentDBApi?.setUserFeedCourseInfo('LessonCompleted', payload);
            if (
              !journeyId &&
              cardInfo?.finishedLessonsCount < Object.keys(lessons)?.length
            ) {
              currentDBApi?.setUserFeedCourseInfo('UpdateFinishCountUserFeed', {
                courseId,
              });
            }
          }
        }
      }
    }
  };

  const showTooltipBasedOnType = () => {
    let isHidden = true;
    if (
      !isCardConsumed?.hasOwnProperty('consumedAt') &&
      currentCourseTab !== 'completed-courses'
    ) {
      switch (currentCard?.type) {
        case 'scorm-lms':
          if (!isScormCompleted) {
            isHidden = false;
            setHideTooltip({ isHidden, text: SCORM_TOOLTIP_TEXT });
            setTimeout(() => {
              setHideTooltip({ isHidden: true, text: '' });
            }, 3000);
          }
          break;
        case 'text-only':
          if (
            Array.isArray(cardPDF) &&
            consumedPDF?.length !== cardPDF.length
          ) {
            isHidden = false;
            setHideTooltip({ isHidden, text: PDF_TOOLTIP_TEXT });
            setTimeout(() => {
              setHideTooltip({ isHidden: true, text: '' });
            }, 3000);
          }
          break;
        case 'hotspot':
          if (!hotspotConsumed) {
            isHidden = false;
            setHideTooltip({ isHidden, text: HOTSPOT_TOOLTIP_TEXT });
            setTimeout(() => {
              setHideTooltip({ isHidden: true, text: '' });
            }, 3000);
          }
          break;
        case 'image-text':
          if (Array.isArray(cardImages)) {
            if (consumedImages?.length >= cardImages.length) {
              if (Array.isArray(cardPDF)) {
                if (consumedPDF?.length < cardPDF.length) {
                  isHidden = false;
                  setHideTooltip({ isHidden, text: PDF_TOOLTIP_TEXT });
                  setTimeout(() => {
                    setHideTooltip({ isHidden: true, text: '' });
                  }, 3000);
                }
              }
            } else {
              isHidden = false;
              setHideTooltip({
                isHidden,
                text:
                  Array.isArray(cardPDF) && consumedPDF?.length < cardPDF.length
                    ? IMAGES_PDF_TOOLTIP_TEXT
                    : IMAGES_TOOLTIP_TEXT,
              });
              setTimeout(() => {
                setHideTooltip({ isHidden: true, text: '' });
              }, 3000);
            }
          }
          break;
        case 'audio-only':
        case 'image-audio':
          let toolTipText = '';
          const tooltipTexts = audioConsume?.isAudioConsumed
            ? {
                ImagesPDFText: IMAGES_PDF_TOOLTIP_TEXT,
                ImagesText: IMAGES_TOOLTIP_TEXT,
                PDFText: PDF_TOOLTIP_TEXT,
              }
            : {
                ImagesPDFText: AUDIO_PDF_IMAGE_TOOLTIP_TEXT,
                ImagesText: AUDIO_IMAGE_TOOLTIP_TEXT,
                PDFText: AUDIO_PDF_TOOLTIP_TEXT,
                OnlyAudioText: AUDIO_TOOLTIP_TEXT,
              };

          if (
            Array.isArray(cardPDF) &&
            consumedPDF?.length < cardPDF.length &&
            Array.isArray(cardImages) &&
            consumedImages?.length < cardImages.length
          ) {
            toolTipText = tooltipTexts.ImagesPDFText;
            isHidden = false;
          } else if (
            Array.isArray(cardImages) &&
            consumedImages?.length < cardImages.length
          ) {
            toolTipText = tooltipTexts.ImagesText;
            isHidden = false;
          } else if (
            Array.isArray(cardPDF) &&
            consumedPDF?.length < cardPDF.length
          ) {
            isHidden = false;
            toolTipText = tooltipTexts.PDFText;
          } else if (!audioConsume?.isAudioConsumed) {
            toolTipText = tooltipTexts.OnlyAudioText!;
            isHidden = false;
          }

          setHideTooltip({
            isHidden,
            text: toolTipText,
          });
          setTimeout(() => {
            setHideTooltip({ isHidden: true, text: '' });
          }, 3000);
          break;
        case 'banner-image':
          if (
            Array.isArray(cardImages) &&
            consumedImages?.length < cardImages.length
          ) {
            isHidden = false;
            setHideTooltip({ isHidden, text: IMAGES_TOOLTIP_TEXT });
            setTimeout(() => {
              setHideTooltip({ isHidden: true, text: '' });
            }, 3000);
          }
          break;
        case 'video-only':
          if (!videoConsume?.isVideoConsumed) {
            isHidden = false;
            setHideTooltip({ isHidden, text: VIDEO_TOOLTIP_TEXT });
            setTimeout(() => {
              setHideTooltip({ isHidden: true, text: '' });
            }, 3000);
          }
          break;
        case 'video-text':
        case 'youtube':
          if (videoConsume?.isVideoConsumed) {
            if (
              Array.isArray(cardPDF) &&
              consumedPDF?.length < cardPDF.length
            ) {
              isHidden = false;
              setHideTooltip({ isHidden, text: PDF_TOOLTIP_TEXT });
              setTimeout(() => {
                setHideTooltip({ isHidden: true, text: '' });
              }, 3000);
            }
          } else {
            isHidden = false;
            setHideTooltip({
              isHidden,
              text:
                Array.isArray(cardPDF) && consumedPDF?.length < cardPDF.length
                  ? VIDEO_PDF_TOOLTIP_TEXT
                  : VIDEO_TOOLTIP_TEXT,
            });
            setTimeout(() => {
              setHideTooltip({ isHidden: true, text: '' });
            }, 3000);
          }
          break;
        default:
          setHideTooltip({ isHidden, text: '' });
          break;
      }
    }
    return isHidden;
  };

  const handlePreviousCard = (seq: number, _cardId: string): void => {
    navigation.navigate(
      'card' as never,
      {
        courseId,
        lessonId,
        cardId: cards?.find((cD: any) => cD?.seq === seq - 1)?.id!,
        journeyId,
      } as never
    );
  };

  const handleScoreCard = (courseProgressQuiz: any, correctCount: number) => {
    setScoreCard(scoreCardState);
    currentCard?.payload?.questions?.map((q: IQuizQuestion, index: number) => {
      const submittedQuestionAnswer = get(
        courseProgressQuiz,
        journeyId
          ? `${cardInfo?.shareId}.${courseId}.${lessonId}.cardProgress.${cardId}.res.${index}.r`
          : `${cardInfo?.shareId}.${lessonId}.cardProgress.${cardId}.res.${index}.r`,
        null
      );

      if (!submittedQuestionAnswer) {
        setScoreCard((prevState: IScoreCard) => ({
          ...prevState,
          skipped: prevState.skipped + 1,
        }));
      } else {
        switch (q.type) {
          case 'checkbox':
            const stringAnsArr = q?.answer?.split(',');
            const numberAnsArr = stringAnsArr.map(function (b: any) {
              return isNaN(Number(b)) ? b : Number(b);
            });
            const _iscorrect = isEqual(
              sortBy(submittedQuestionAnswer),
              sortBy(numberAnsArr)
            );
            if (_iscorrect) {
              setScoreCard((prevState: IScoreCard) => {
                correctCount = prevState.correct + 1;
                return {
                  ...prevState,
                  correct: prevState.correct + 1,
                };
              });
            } else {
              setScoreCard((prevState: IScoreCard) => ({
                ...prevState,
                wrong: prevState.wrong + 1,
              }));
            }
            break;
          case 'hotspots':
            let correctHotspot = [];
            q.options?.forEach((_opt: any, _index: number) => {
              const hasCorrectHotspot = submittedQuestionAnswer.find(
                (subA: any) => subA.hotspot === _index
              );
              if (hasCorrectHotspot) {
                correctHotspot.push(hasCorrectHotspot);
              }
            });
            if (correctHotspot.length === q.options.length) {
              setScoreCard((prevState: IScoreCard) => {
                correctCount = prevState.correct + 1;
                return {
                  ...prevState,
                  correct: prevState.correct + 1,
                };
              });
            } else {
              setScoreCard((prevState: IScoreCard) => ({
                ...prevState,
                wrong: prevState.wrong + 1,
              }));
            }
            break;
          case 'mcq':
          case 'mcq-image':
            const iscorrect = String(submittedQuestionAnswer) === q.answer;
            if (iscorrect) {
              setScoreCard((prevState: IScoreCard) => {
                correctCount = prevState.correct + 1;
                return {
                  ...prevState,
                  correct: prevState.correct + 1,
                };
              });
            } else {
              setScoreCard((prevState: IScoreCard) => ({
                ...prevState,
                wrong: prevState.wrong + 1,
              }));
            }
            break;

          default:
            setScoreCard((prevState: IScoreCard) => ({
              ...prevState,
              wrong: prevState.wrong + 1,
            }));
            break;
        }
      }
    });
    return correctCount;
  };

  const handleSubmitQuizCard = async (action?: 'retry') => {
    let correctCount = 0;
    if (action !== 'retry') {
      await handleQuizAttempt();
    }
    const { courseProgressQuiz } = await handleGetProgressQuizLMS();
    correctCount = handleScoreCard(courseProgressQuiz, correctCount);
    const attemptCount = get(
      courseProgressQuiz,
      journeyId
        ? `${cardInfo?.shareId}.${courseId}.${lessonId}.cardProgress.${cardId}.attempted`
        : `${cardInfo?.shareId}.${lessonId}.cardProgress.${cardId}.attempted`,
      0
    );
    setQuizAttemptedCount(attemptCount);
    setIsQuizFinish((prevState) => {
      (async () => {
        const payload = {
          courseId,
          lessonId,
          cardId,
          shareId: cardInfo?.shareId,
          scoreCard: `${correctCount}/${currentCard?.payload?.questions?.length}`,
          journeyId,
        };
        await currentDBApi?.setUserFeedCourseInfo('QuizScoreUpdate', payload);
      })();
      return !prevState;
    });
  };

  const handleNextCard = (
    seq: number,
    _currentCardId: string,
    currentCardType: ILessonCardType,
    forceToNextCard: boolean = false
  ) => {
    setIsQuizIntroSeen(currentCardType === 'quiz' ? true : false);
    const isNextEnable = showTooltipBasedOnType();
    if (
      isNextEnable &&
      (disableNextBtn === false || forceToNextCard === true) &&
      (currentCardType !== 'quiz' || isQuizFinish)
    ) {
      /* When going to next card, current card is completed */
      handleLessonNavigation(seq);
    }
  };

  const handleImageConsumed = (_index: any) => {
    setConsumedImages((prevState: []) => [...prevState, _index]);
  };

  const handleHotspotConsumed = () => {
    setHotspotConsumed(true);
  };

  const handlePDFConsumed = (_index: any) => {
    setConsumedPDF((prevState: []) => [...prevState, _index]);
  };

  const handleQuizResponses = async ({
    selectedIndex,
    selectedOption,
  }: {
    selectedIndex: number;
    selectedOption: number | any[];
  }) => {
    const shareId = cardInfo?.shareId;
    if (courseId && lessonId && cardId && shareId) {
      const payload = {
        cardId,
        courseId,
        dt: Date.now(),
        sessionId,
        lessonId,
        shareId,
        selectedIndex,
        selectedOption,
        attempt: +quizAttemptedCount + 1,
        journeyId,
      };
      /* @ts-ignore */
      await currentDBApi?.setUserFeedCourseInfo('QuizAnswer', payload);
    }
  };

  const handleQuizAttempt = async () => {
    const shareId = cardInfo?.shareId;

    if (
      quizAttemptedCount < currentCard?.attempts &&
      courseId &&
      lessonId &&
      shareId &&
      cardId
    ) {
      const _cardId = currentCard?.id;
      const payload = {
        courseId,
        lessonId,
        cardId,
        shareId,
        journeyId,
      };
      setQuizCardsAttempts((current) => ({
        ...current,
        [_cardId]: (current[_cardId] ?? 0) + 1,
      }));
      await currentDBApi?.setUserFeedCourseInfo('QuizAttemptInc', payload);
    }
  };

  const handleVideoConsumed = async ({
    consumeTime,
    isVideoEnd,
  }: {
    consumeTime: number;
    isVideoEnd: boolean;
  }) => {
    if (!isCardConsumed?.hasOwnProperty('consumedAt')) {
      progressSecond.current = consumeTime;
      setVideoConsume({
        consumeTime: consumeTime,
        isVideoConsumed: true,
        isVideoEnd: isVideoEnd,
      });
    }
  };

  const handleAudioConsumed = async ({
    consumeTime,
    isAudioEnd,
  }: {
    consumeTime: number;
    isAudioEnd: boolean;
  }) => {
    if (!isCardConsumed?.hasOwnProperty('consumedAt')) {
      progressSecond.current = consumeTime;
      setAudioConsume({
        consumeTime: consumeTime,
        isAudioConsumed: true,
        isAudioEnd: isAudioEnd,
      });
    }
  };

  const handleGetProgressQuizLMS = async () => {
    const courseProgressQuiz = await currentDBApi?.getProgressQuizLMS(
      journeyId ?? courseId
    );
    setProgressQuizLMS(courseProgressQuiz);
    handleScoreCard(courseProgressQuiz, 0);

    const quizResultAnswers = get(
      courseProgressQuiz,
      journeyId
        ? `${cardInfo?.shareId}.${courseId}.${lessonId}.cardProgress.${cardId}.res`
        : `${cardInfo?.shareId}.${lessonId}.cardProgress.${cardId}.res`
    );
    setQuizResults(quizResultAnswers);
    return { courseProgressQuiz, quizResultAnswers };
  };

  const handleLessonNavigation = (
    seq: number,
    disableNavigation: boolean = false
  ) => {
    const nextCardId = cards?.find((cD: any) => cD?.seq === seq + 1)?.id;
    if (!disableNavigation) {
      if (seq + 1 === cards?.length) {
        if (currentCourseTab === 'completed-courses') {
          navigation.navigate(
            'course-details' as never,
            {
              courseId,
              journeyId,
            } as never
          );
        } else {
          navigation.dispatch(
            StackActions.replace(
              'lesson-completed' as never,
              {
                courseId,
                lessonId,
                cardId: nextCardId,
                journeyId,
              } as never
            )
          );
        }
      } else {
        navigation.navigate(
          'card' as never,
          {
            courseId,
            lessonId,
            cardId: nextCardId,
            journeyId,
          } as never
        );
      }
    }
  };

  const handleScromProgress = async (event: {
    data: { eventType: 'progress' | 'completed'; progress: string };
  }) => {
    const payload = {
      scromProgress: {
        event: {
          eventType: event?.data?.eventType,
          progress: event?.data?.progress,
        },
        courseId,
        lessonId,
        cardId,
        dt: Date.now(),
        sessionId,
        shareId: cardInfo?.shareId,
      },
    };
    await currentDBApi?.setUserFeedCourseInfo('ScromProgress', payload);
    const progress = JSON.parse(event?.data?.progress ?? '{}');
    const status = progress?.core?.lesson_status || progress?.completion_status;
    setIsScormCompleted(
      (status !== 'incomplete' && status !== 'unknown') ||
        (status === 'completed' && status === 'passed')
    );
  };

  const handleSurveyResponses = (data: any) => {
    if (currentCourseTab !== 'completed-courses') {
      setSurveyConsumed({
        isSurveyConsumed: true,
        surveyAnswer: {
          cardId,
          courseId,
          dt: Date.now(),
          sessionId,
          lessonId,
          shareId: cardInfo?.shareId,
          data,
          journeyId,
        },
      });
    } else {
      setTimeout(() => {
        setDisableNextBtn(false);
        consumeCard('consumed');
        handleNextCard(
          currentCard?.seq,
          currentCard?.id,
          currentCard?.type,
          true
        );
      });
    }
  };

  useEffect(() => {
    const hideTooltipOnCardConsumption = async () => {
      if (
        !isCardConsumed?.hasOwnProperty('consumedAt') &&
        currentCourseTab !== 'completed-courses'
      ) {
        switch (currentCard?.type) {
          case 'scorm-lms':
            if (isScormCompleted) {
              setHideTooltip({ isHidden: true, text: '' });
              setDisableNextBtn(false);
              consumeCard('consumed');
            } else {
              setDisableNextBtn(true);
            }
            break;
          case 'text-only':
            if (Array.isArray(cardPDF)) {
              if (consumedPDF?.length >= cardPDF?.length) {
                setHideTooltip({ isHidden: true, text: '' });
                setDisableNextBtn(false);
                consumeCard('consumed');
              } else {
                setDisableNextBtn(true);
              }
            } else {
              setHideTooltip({ isHidden: true, text: '' });
              setTimeout(() => setDisableNextBtn(false), 2000);
            }
            break;
          case 'image-text':
            if (Array.isArray(cardImages)) {
              if (consumedImages?.length >= cardImages?.length) {
                if (Array.isArray(cardPDF)) {
                  if (consumedPDF?.length >= cardPDF.length) {
                    setHideTooltip({ isHidden: true, text: '' });
                    setDisableNextBtn(false);
                    consumeCard('consumed');
                  } else {
                    setDisableNextBtn(true);
                  }
                } else {
                  consumeCard('consumed');
                  setHideTooltip({ isHidden: true, text: '' });
                  setTimeout(() => setDisableNextBtn(false), 2000);
                }
              } else {
                setDisableNextBtn(true);
              }
            } else {
              setHideTooltip({ isHidden: true, text: '' });
              setTimeout(() => setDisableNextBtn(false), 2000);
            }
            break;
          case 'audio-only':
          case 'image-audio':
            if (audioConsume?.isAudioConsumed) {
              if (Array.isArray(cardPDF)) {
                if (consumedPDF?.length >= cardPDF?.length) {
                  if (Array.isArray(cardImages)) {
                    if (consumedImages?.length >= cardImages?.length) {
                      setHideTooltip({ isHidden: true, text: '' });
                      setDisableNextBtn(false);
                      consumeCard('consumed');
                    } else {
                      setDisableNextBtn(true);
                    }
                  } else {
                    setHideTooltip({ isHidden: true, text: '' });
                    setDisableNextBtn(false);
                    consumeCard('consumed');
                  }
                } else {
                  setDisableNextBtn(true);
                }
              } else if (Array.isArray(cardImages)) {
                if (consumedImages?.length >= cardImages?.length) {
                  setHideTooltip({ isHidden: true, text: '' });
                  setDisableNextBtn(false);
                  consumeCard('consumed');
                } else {
                  setDisableNextBtn(true);
                }
              } else {
                setHideTooltip({ isHidden: true, text: '' });
                setDisableNextBtn(false);
                consumeCard('consumed');
              }
            } else {
              setDisableNextBtn(true);
            }
            break;
          case 'banner-image':
            if (Array.isArray(cardImages)) {
              if (consumedImages?.length >= cardImages?.length) {
                setHideTooltip({ isHidden: true, text: '' });
                setDisableNextBtn(false);
                consumeCard('consumed');
              } else {
                setDisableNextBtn(true);
              }
            } else {
              setHideTooltip({ isHidden: true, text: '' });
              setTimeout(() => setDisableNextBtn(false), 2000);
            }
            break;
          case 'video-only':
            if (videoConsume?.isVideoConsumed) {
              setHideTooltip({ isHidden: true, text: '' });
              setDisableNextBtn(false);
              consumeCard('consumed');
              if (videoConsume?.isVideoEnd) {
                handleNextCard(
                  currentCard?.seq,
                  currentCard?.id,
                  currentCard?.type
                );
              }
            } else {
              setDisableNextBtn(true);
            }
            break;
          case 'hotspot':
            if (hotspotConsumed) {
              setHideTooltip({ isHidden: true, text: '' });
              setDisableNextBtn(false);
              consumeCard('consumed');
              handleNextCard(
                currentCard?.seq,
                currentCard?.id,
                currentCard?.type
              );
            } else {
              setDisableNextBtn(true);
            }
            break;
          case 'video-text':
          case 'youtube':
            if (videoConsume?.isVideoConsumed) {
              if (Array.isArray(cardPDF)) {
                if (consumedPDF?.length >= cardPDF?.length) {
                  setHideTooltip({ isHidden: true, text: '' });
                  setDisableNextBtn(false);
                  consumeCard('consumed');
                } else {
                  setDisableNextBtn(true);
                }
              } else {
                setHideTooltip({ isHidden: true, text: '' });
                setDisableNextBtn(false);
                consumeCard('consumed');
              }
            } else {
              setDisableNextBtn(true);
            }
            break;
          case 'survey':
            if (surveyConsumed.isSurveyConsumed) {
              currentDBApi?.setUserFeedCourseInfo(
                'SurveyAnswer',
                surveyConsumed.surveyAnswer
              );
              setTimeout(() => {
                setDisableNextBtn(false);
                consumeCard('consumed');
                handleNextCard(
                  currentCard?.seq,
                  currentCard?.id,
                  currentCard?.type,
                  true
                );
              });
            }
            break;
          default:
            setHideTooltip({ isHidden: true, text: '' });
            setDisableNextBtn(false);
            break;
        }
      } else if (currentCard?.type === 'survey') {
        setTimeout(() => {
          setDisableNextBtn(false);
          handleNextCard(
            currentCard?.seq,
            currentCard?.id,
            currentCard?.type,
            true
          );
        });
      } else {
        setHideTooltip({ isHidden: true, text: '' });
        setDisableNextBtn(false);
      }
    };
    hideTooltipOnCardConsumption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    consumedImages,
    consumedPDF,
    videoConsume,
    audioConsume,
    hotspotConsumed,
    isQuizFinish,
    surveyConsumed,
    isScormCompleted,
  ]);

  useEffect(() => {
    const quizButtonsAndQuizConsume = async () => {
      if (currentCard.type === 'survey') {
        // Hide prev, next btns for survey
        setIsQuizIntroSeen(true);
        setHidePrevNext(true);
      }
      if (currentCard.type === 'quiz') {
        const isQuizPass = scoreCard?.correct >= currentCard.passMark;
        const hasCompletedAttempts =
          quizAttemptedCount === currentCard?.attempts;

        if (isQuizIntroSeen && !isQuizFinish) {
          // Quiz mcq render
          setHidePrevNext(true);
        }

        //prevent rendering next button when into is off
        if (currentCard.disableInstruction) {
          setIsQuizIntroSeen(true);
        }
        if (isQuizFinish) {
          // LMS Analytics For Quiz
          handleReportsPayload(courseId);
          // Quiz result render
          if (isQuizPass || hasCompletedAttempts) {
            if (!isCardConsumed.hasOwnProperty('consumedAt')) {
              consumeCard('consumed');
            }
            setHidePrevNext(false);
          } else {
            setHidePrevNext(true);
          }
        }
      }
    };
    quizButtonsAndQuizConsume();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizAttemptedCount, isQuizFinish, isQuizIntroSeen]);

  useEffect(() => {
    const cardStarted = () => {
      if (
        currentCourseTab !== 'completed-courses' &&
        cardId &&
        !isCardConsumed.hasOwnProperty('consumedAt') &&
        lastSeenCardId !== cardId &&
        cardInfo?.shareId
      ) {
        const prevCardId =
          cards.find((card: any) => card.seq === currentSeq - 1)?.id || null;
        const payload = {
          cardId,
          courseId,
          lessonId,
          sessionId,
          prevCardId,
          dt: Date.now(),
          lang: cardInfo?.selectedLanguage,
          shareId: cardInfo?.shareId,
          journeyId,
        };
        currentDBApi?.setUserFeedCourseInfo('CardStarted', payload);

        /* When new card opens consider as consumed */
        if (
          (currentCard?.type === 'text-only' ||
            ((currentCard?.type === 'banner-image' ||
              currentCard?.type === 'image-text') &&
              cardImages?.length === 1)) &&
          !currentCard?.payload?.hasOwnProperty('pdf')
        ) {
          consumeCard('consumed');
        }
      }
    };
    cardStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, lastSeenCardId, cardInfo?.shareId]);

  useEffect(() => {
    /* for Limited Attempts */
    const getQuizProgress = async () => {
      setIsLoading(true);
      const { quizResultAnswers } = await handleGetProgressQuizLMS();
      if (quizResultAnswers) {
        setIsQuizFinish(true);
      }
      setIsLoading(false);
    };
    if (currentCard?.isLimited && quizAttemptedCount >= currentCard?.attempts) {
      getQuizProgress();
    } else if (currentCourseTab === 'completed-courses') {
      getQuizProgress();
      setIsQuizFinish(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard, quizAttemptedCount]);

  useEffect(() => {
    if (currentCard.type === 'scorm-lms') {
      const getScromProgress = async () => {
        const progress = await currentDBApi?.getScromProgress(
          courseId,
          cardInfo?.shareId
        );
        setScromProgress({ progress, isScromLoaded: true });
      };
      getScromProgress();
    }
    return () => {
      setScromProgress({ progress: null, isScromLoaded: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard.type, setScromProgress]);

  return {
    handlePreviousCard,
    handleNextCard,
    handleSubmitQuizCard,
    handleImageConsumed,
    handleQuizResponses,
    handleQuizAttempt,
    handleVideoConsumed,
    handleAudioConsumed,
    handlePDFConsumed,
    handleScromProgress,
    handleHotspotConsumed,
    handleSurveyResponses,
    currentSeq,
    totalSteps,
    isQuizIntroSeen,
    hideTooltip,
    disableNextBtn,
    isQuizFinish,
    attempted: quizAttemptedCount,
    scoreCard,
    isLoading,
    quizResultAnswers: quizResult,
    scromProgress,
    currentCard,
    isHidePrevNext,
    showPrevBtnFirstTime: isFirstCard,
    currentCourseTab,
    courseTitle: cardInfo.title,
  };
};
