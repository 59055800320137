import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from '@know/ui';
import type { ISurvey } from '../types';
import { TextBox } from './TextBox';
import { Radio } from './Radio';
import { Checkbox } from './Checkbox';
import { RatingScale } from './RatingScale';
import { NpsRatings } from './NpsRatings';
import { TitleDiscription } from './TitleDiscription';
import { SurveyTitle } from './SurveyTitle';

export const Survey: React.FC<ISurvey> = ({
  surveyObj,
  isCompletedTab,
  handleSurveyResponses,
}) => {
  const [startSurveyFlag, SetStartSurveyFlag] = useState<boolean | null>(false); //first slide
  const [endSurveyFlag, SetEndSurveyFlag] = useState(false); //last slide
  const [currentSectionData, setCurrentSectionData] = useState<any>({
    id: null,
    data: {},
  });
  const [currentQuestionData, setCurrentQuestionData] = useState<any>({
    index: null,
    data: {},
    answer: null,
  });
  const [nextSectionId, setNextSectionId] = useState<any>('');
  const [response, setResponse] = useState<any>([]);
  useEffect(() => {
    if (isCompletedTab) {
      SetEndSurveyFlag(true);
      SetStartSurveyFlag(null);
    }
  }, [isCompletedTab]);

  // const handlerBackButton = useCallback(() => {
  //   if (startSurveyFlag) {
  //     const previousQuestion =
  //       response.length > 0 ? response[response.length - 1] : null;
  //     //set currentQuestion to previous question
  //     setCurrentQuestionData({
  //       index: previousQuestion.question,
  //       data: surveyObj.payload.sections[previousQuestion.sectionID].questions[
  //         previousQuestion.question
  //       ],
  //       answer: previousQuestion.response,
  //     });
  //     setCurrentSectionData({
  //       id: previousQuestion.sectionID,
  //       data: surveyObj?.payload?.sections?.[previousQuestion.sectionID],
  //     });
  //     setNextSectionId(
  //       surveyObj.payload.sections[previousQuestion.sectionID].nextSectionID
  //     );
  //     //delete the response array last element
  //   }
  // }, [
  //   startSurveyFlag,
  //   setCurrentQuestionData,
  //   setCurrentSectionData,
  //   setNextSectionId,
  // ]);
  useEffect(() => {
    if (startSurveyFlag) {
      if (surveyObj.payload?.initSection && surveyObj.payload?.sections) {
        setCurrentSectionData({
          id: surveyObj.payload?.initSection,
          data: surveyObj.payload.sections[surveyObj.payload?.initSection],
        });
        setCurrentQuestionData({
          index: 0,
          data: surveyObj.payload.sections[surveyObj.payload?.initSection]
            .questions[0],
        });
        setNextSectionId(
          surveyObj.payload.sections[surveyObj.payload?.initSection]
            .nextSectionID || null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSurveyFlag]);

  const handleSurveyStart = useCallback(() => {
    SetStartSurveyFlag(true);
  }, [SetStartSurveyFlag]);

  const handleSurveySubmit = () => {
    handleSurveyResponses(response);
  };

  const handleProceedButton = useCallback(
    (optionSelectedIndex?: string) => {
      if (
        currentSectionData.data.questions[currentQuestionData.index].type ===
          'rating' ||
        currentSectionData.data.questions[currentQuestionData.index].type ===
          'mcq' ||
        currentSectionData.data.questions[currentQuestionData.index].type ===
          'nps'
      ) {
        // conditionally session question with optionSelected

        if (
          currentSectionData.data.questions[currentQuestionData.index]
            ?.condition?.[`-${optionSelectedIndex}`]
        ) {
          let tempNextSession =
            currentSectionData.data.questions[currentQuestionData.index]
              ?.condition?.[`-${optionSelectedIndex}`];
          if (tempNextSession === 'next') {
            setCurrentSectionData({
              id: nextSectionId,
              data: surveyObj?.payload?.sections?.[nextSectionId],
            });
            setNextSectionId(
              surveyObj?.payload?.sections?.[nextSectionId]?.nextSectionID ||
                null
            );
            setCurrentQuestionData({
              index: 0,
              data: surveyObj?.payload?.sections?.[nextSectionId]?.questions[0],
            });
          } else if (
            tempNextSession == null ||
            tempNextSession === '' ||
            tempNextSession === 'submit'
          ) {
            // submit the survey and show last slide
            SetEndSurveyFlag(true);
          } else {
            setCurrentSectionData({
              id: tempNextSession,
              data: surveyObj?.payload?.sections?.[tempNextSession],
            });
            setNextSectionId(
              surveyObj?.payload?.sections?.[tempNextSession]?.nextSectionID ||
                null
            );
            setCurrentQuestionData({
              index: 0,
              data: surveyObj?.payload?.sections?.[tempNextSession]
                ?.questions[0],
            });
          }
        } else if (
          currentSectionData.data.questions.length ===
          currentQuestionData.index + 1
        ) {
          //next session question
          if (
            nextSectionId !== 'undefined' &&
            nextSectionId !== null &&
            nextSectionId !== '' &&
            nextSectionId !== 'next' &&
            nextSectionId !== 'submit'
          ) {
            setCurrentSectionData({
              id: nextSectionId,
              data: surveyObj?.payload?.sections?.[nextSectionId],
            });
            setNextSectionId(
              surveyObj?.payload?.sections?.[nextSectionId]?.nextSectionID ||
                null
            );
            setCurrentQuestionData({
              index: 0,
              data: surveyObj?.payload?.sections?.[nextSectionId]?.questions[0],
            });
          } else {
            //submit the survey and show last slide
            SetEndSurveyFlag(true);
          }
        } else {
          //current session question index++
          setCurrentQuestionData({
            index: currentQuestionData.index + 1,
            data: surveyObj.payload.sections[currentSectionData.id].questions[
              currentQuestionData.index + 1
            ],
          });
        }
      } else if (
        currentSectionData.data.questions.length ===
        currentQuestionData.index + 1
      ) {
        //next session question
        if (
          nextSectionId !== 'undefined' &&
          nextSectionId !== null &&
          nextSectionId !== '' &&
          nextSectionId !== 'next' &&
          nextSectionId !== 'submit'
        ) {
          setCurrentSectionData({
            id: nextSectionId,
            data: surveyObj?.payload?.sections?.[nextSectionId],
          });
          setNextSectionId(
            surveyObj?.payload?.sections?.[nextSectionId]?.nextSectionID || null
          );
          setCurrentQuestionData({
            index: 0,
            data: surveyObj?.payload?.sections?.[nextSectionId]?.questions[0],
          });
        } else {
          //submit the survey and show last slide
          SetEndSurveyFlag(true);
        }
      } else {
        //current session question index++
        setCurrentQuestionData({
          index: currentQuestionData.index + 1,
          data: surveyObj.payload.sections[currentSectionData.id]?.questions[
            currentQuestionData.index + 1
          ],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentSectionData,
      setCurrentSectionData,
      currentQuestionData,
      setCurrentQuestionData,
      setNextSectionId,
    ]
  );

  let renderQuizQuestion;
  switch (currentQuestionData?.data?.type) {
    case 'text':
      renderQuizQuestion = (
        <TextBox
          data={currentQuestionData}
          handleProceedButton={handleProceedButton}
          setResponse={setResponse}
          sectionId={currentSectionData.id}
        />
      );
      break;
    case 'title-discription-survey':
      renderQuizQuestion = (
        <TitleDiscription
          data={currentQuestionData}
          handleProceedButton={handleProceedButton}
        />
      );
      break;
    case 'mcq':
      renderQuizQuestion = (
        <Radio
          data={currentQuestionData}
          handleProceedButton={handleProceedButton}
          setResponse={setResponse}
          sectionId={currentSectionData.id}
        />
      );
      break;
    case 'mcq-multi':
      renderQuizQuestion = (
        <Checkbox
          data={currentQuestionData}
          handleProceedButton={handleProceedButton}
          setResponse={setResponse}
          sectionId={currentSectionData.id}
        />
      );
      break;
    case 'rating':
      renderQuizQuestion = (
        <RatingScale
          data={currentQuestionData}
          handleProceedButton={handleProceedButton}
          setResponse={setResponse}
          sectionId={currentSectionData.id}
        />
      );
      break;
    case 'nps':
      renderQuizQuestion = (
        <NpsRatings
          data={currentQuestionData}
          handleProceedButton={handleProceedButton}
          setResponse={setResponse}
          sectionId={currentSectionData.id}
        />
      );
      break;

    default:
      renderQuizQuestion = <Text>Content not supported</Text>;
  }

  return (
    <View flex="1">
      {/* Survey Card */}
      {(startSurveyFlag === false || endSurveyFlag === true) && (
        <SurveyTitle
          isSubmitted={
            startSurveyFlag === false
              ? false
              : endSurveyFlag === true
              ? true
              : false
          }
          title={surveyObj.title ? surveyObj.title : ''}
          handleSurveyStart={handleSurveyStart}
          handleSurveySubmit={handleSurveySubmit}
        />
      )}
      {renderQuizQuestion}
    </View>
  );
};
