import React, { useState } from 'react';
import { View, Image, WithKNOWLoader } from '@know/ui';
import { StyleSheet } from 'react-native';
import type { IImageProps } from 'native-base';

export const ImageWithLoader = (imageProps: IImageProps) => {
  const [loading, setLoading] = useState(true);
  return (
    <View bgColor="black">
      {loading && (
        <View
          alignItems="center"
          justifyContent="center"
          style={StyleSheet.absoluteFill}
          zIndex={1}
        >
          <WithKNOWLoader
            isLoading={loading}
            size="lg"
            height={imageProps?.h}
            width={imageProps?.w}
          />
        </View>
      )}
      <Image
        onLoad={() => setLoading(true)}
        onLoadEnd={() => setLoading(false)}
        {...imageProps}
      />
    </View>
  );
};
