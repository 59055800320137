import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { useKNOWValueContextProvider } from '..';

type KNOWQRScannerProps = {
  onSubmit?: (result: string) => void;
  videoStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onReady?: () => void;
};

export const KNOWQRScanner = ({
  onSubmit,
  videoStyle,
  containerStyle,
  onReady,
}: KNOWQRScannerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { isIOSOnWeb } = useKNOWValueContextProvider();

  const [resultString, setResultString] = useState<string | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [onReadyFired, setOnReadyFired] = useState<boolean>(false);

  useEffect(() => {
    if (resultString && onSubmit) {
      onSubmit(resultString);
    }
  }, [resultString, onSubmit]);

  useEffect(() => {
    if (isReady && !onReadyFired) {
      setOnReadyFired(true);
      onReady && onReady();
    }
  }, [isReady, onReady, onReadyFired]);

  useEffect(() => {
    if (videoRef.current) {
      const qrScanner = new QrScanner(
        videoRef.current,
        (result) => {
          setResultString(result.data);
        },
        { returnDetailedScanResult: true }
      );

      if (!isIOSOnWeb) {
        QrScanner.listCameras(true).then((devices) => {
          if (devices.length) {
            const deviceId = devices[devices.length - 1].id;
            qrScanner.setCamera(deviceId);
            qrScanner.start();
          }
        });
      } else {
        qrScanner.start();
      }

      setIsReady(true);

      return () => {
        qrScanner.stop();
        qrScanner.destroy();
      };
    }
  }, [isIOSOnWeb, onSubmit]);

  return (
    <div style={containerStyle}>
      <video
        ref={videoRef}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ display: 'block', ...(videoStyle ?? {}) }}
      />
    </div>
  );
};
