import React from 'react';
import { EllipsisMenuProps } from './types';
import {
  Box,
  Divider,
  ExpoIonicIcon,
  HStack,
  Menu,
  Pressable,
  Text,
} from '@know/ui';

export { EllipsisMenuOption } from './types';

export const EllipsisMenu = ({ options }: EllipsisMenuProps) => {
  if (!options.length) return null;

  if (options.length === 1) {
    const { Icon, onClick } = options[0];
    return (
      <Pressable onPress={onClick}>
        <HStack alignItems={'center'}>
          {Icon}
          {/* <Text ml={'8px'}>{label}</Text> */}
        </HStack>
      </Pressable>
    );
  } else {
    return (
      <Box alignSelf={'flex-start'}>
        <Menu
          shouldOverlapWithTrigger={true}
          placement="left"
          trigger={(triggerProps) => {
            return (
              <Pressable
                accessibilityLabel="More options menu"
                py={'6px'}
                px={'10px'}
                {...triggerProps}
              >
                <ExpoIonicIcon name="ellipsis-vertical" size={'20px'} />
              </Pressable>
            );
          }}
        >
          {options.map(({ id, label, onClick, Icon }, index) => (
            <React.Fragment key={id}>
              <Menu.Item onPress={onClick}>
                <HStack alignItems={'center'} mx={'-12px'}>
                  {Icon}
                  <Text ml={'8px'}>{label}</Text>
                </HStack>
              </Menu.Item>
              {index === options.length - 1 ? null : <Divider mx={'12px'} />}
            </React.Fragment>
          ))}
        </Menu>
      </Box>
    );
  }
};
