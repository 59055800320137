import React, { useMemo } from 'react';
import {
  IssueStatusPayloadType,
  IssueUpdateType,
  IssueDetailsPayloadType,
  IssueFormPayloadType,
  IssueSeverityPayloadType,
  SeverityType,
} from '@know/db';
import { Box, Pressable, View, IssueSeverityType } from '@know/ui';
import { IssueNugget } from '@know/transformers';
import {
  ExpoEntypoIcon,
  ExpoFAIcon,
  ExpoIonicIcon,
  Text,
  Image,
  IssueUpdateCommentIcon,
  IssueAdditionalInfoIcon,
  UpdateSeverityIcon,
} from '@know/ui';
import { PDFIcon, AudioIcon, VideoIcon, ExpoEvilIcon } from '../Tasks/icons';
import moment from 'moment';
import { useTranslation } from '@know/i18n';

export type IssueStatusOnClickPayloadType = (
  payload:
    | IssueDetailsPayloadType
    | IssueFormPayloadType
    | IssueSeverityPayloadType,
  payloadType: string
) => any;

type IIssueUpdateProps = {
  update: IssueUpdateType;
  issue: IssueNugget;
  isFirst?: boolean;
  isLastUpdate: boolean;
  onClickPayload?: IssueStatusOnClickPayloadType;
  onClickImage?: (url: string) => any;
  onClickPdf?: (url: string) => any;
};

export const IssueUpdate: React.FC<IIssueUpdateProps> = ({
  update,
  issue,
  isFirst,
  isLastUpdate,
  onClickImage,
  onClickPayload,
  onClickPdf,
}) => {
  const { t } = useTranslation(['forms', 'messages']);

  const updateIcon = useMemo(() => {
    switch (update.type) {
      case 'details':
        return (
          <ExpoIonicIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="warning"
          />
        );
      case 'status':
        if (update?.payload?.[0].value === 'reopened') {
          return (
            <ExpoIonicIcon
              color="primary.500"
              size="18px"
              mt="2px"
              mr="4px"
              name="refresh"
            />
          );
        }

        if (update?.payload?.[0].value === 'completed') {
          return (
            <ExpoIonicIcon
              color="primary.500"
              size="18px"
              mt="2px"
              mr="4px"
              name="checkbox"
            />
          );
        }

        return (
          <ExpoIonicIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="checkbox"
          />
        );
      case 'severity':
        return (
          <UpdateSeverityIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      case 'no_leaders':
      case 'share':
        return (
          <ExpoIonicIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="share-social-sharp"
          />
        );
      case 'create_form':
      case 'close_form':
        return (
          <IssueAdditionalInfoIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
          />
        );

      case 'remark':
        return (
          <IssueUpdateCommentIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="image"
          />
        );
      case 'image':
        return (
          <ExpoIonicIcon
            color="primary.500"
            size="18px"
            mt="2px"
            mr="4px"
            name="image"
          />
        );
      case 'audio':
        return (
          <AudioIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      case 'video':
        return (
          <VideoIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      case 'pdf':
        return (
          <PDFIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
      default:
        // TODO: change this
        return (
          <PDFIcon
            marginRight={'4px'}
            marginTop={'2px'}
            size={18}
            color="primary.500"
          />
        );
    }
  }, [update]);

  const updateMessage = useMemo(() => {
    switch (update.type) {
      case 'share':
        return 'shared this issue to';
      case 'no_leaders':
        return 'tried to share this issue to location leaders:';
      case 'create_form':
        return 'added Additional Info';
      case 'close_form':
        return 'added a closure report';
      case 'status':
        if (update?.payload?.[0].value === 'reopened') {
          return 'reopened this issue.';
        }

        if (update?.payload?.[0].value === 'completed') {
          return `resolved the issue${
            update?.payload?.[0].remark ? ' with a remark' : '.'
          }`;
        }

        return '';
      case 'remark':
        return 'added a remark.';
      case 'severity':
        return 'updated the severity to:';
      case 'image':
        return 'added image(s)';
      case 'audio':
        return 'added an audio';
      case 'video':
        return 'added a video';
      case 'pdf':
        return 'added a pdf';
      case 'details':
        return 'reported an issue';
      default:
        return (update as IssueUpdateType).type;
    }
  }, [update]);

  const updateComponent = useMemo(() => {
    switch (update.type) {
      case 'share':
        if (!update.payload) {
          return null;
        }

        return (
          <View
            display={'inline-flex'}
            flexDir={'row'}
            maxW={'100%'}
            flexWrap={'wrap'}
          >
            {update.payload.map(({ id, value }) => {
              return (
                <Box
                  key={id}
                  py={'4px'}
                  px={'8px'}
                  bg={'gray.200'}
                  mt={'8px'}
                  mr={'12px'}
                >
                  <Text
                    color={'gray.1000'}
                    fontWeight={600}
                    fontSize={'14px'}
                    fontFamily={'tableCell'}
                  >
                    {value}
                  </Text>
                </Box>
              );
            })}
          </View>
        );
      case 'no_leaders':
        return (
          <Text fontSize={'14px'} fontWeight={400} fontFamily={'tableCell'}>
            {'Location leaders for this location have not been assigned.'}
          </Text>
        );
      case 'create_form':
      case 'close_form':
        if (!update.payload?.[0]) {
          return null;
        }

        const payload = update.payload[0] ?? {};
        return (
          <Pressable
            key={`form-${payload.formId}`}
            onPress={() =>
              onClickPayload?.(
                { ...payload, userId: update.userID ?? update.userId },
                'form'
              )
            }
            mt={'6px'}
          >
            <View
              w="100%"
              display={'flex'}
              flexDir={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg={'rgba(0, 193, 178, 0.1)'}
              borderColor={'primary.500'}
              borderWidth={'1px'}
              borderRadius={'6px'}
              py={'6px'}
              px={'12px'}
            >
              <Text
                fontFamily={'tableCell'}
                fontWeight={600}
                fontSize={'14px'}
                flex={1}
              >
                {update.type === 'create_form'
                  ? 'Additional Info'
                  : 'Closure Report'}{' '}
                - {payload?.title ?? '-'}
              </Text>
              <ExpoEntypoIcon name="chevron-right" size={'16px'} ml={'6px'} />
            </View>
          </Pressable>
        );
      case 'status':
        const statusPayload = update.payload as IssueStatusPayloadType[];
        if (!statusPayload) {
          return null;
        }
        if (statusPayload[0].remark) {
          return (
            <Text
              color={'gray.700'}
              fontSize={'14px'}
              fontWeight={400}
              fontFamily={'tableCell'}
            >
              "{statusPayload[0].remark}"
            </Text>
          );
        }

        return null;
      case 'details':
        if (update?.payload?.[0]) {
          return (
            <>
              <Text fontFamily={'tableCell'}>
                <Text fontSize={'14px'} fontWeight={600}>
                  {'Title: '}
                </Text>
                {update.payload[0].title}
              </Text>
              <Text fontFamily={'tableCell'}>
                <Text fontSize={'14px'} fontWeight={600}>
                  {'Description: '}
                </Text>
                {issue.notes}
              </Text>
              <Text fontFamily={'tableCell'}>
                <Text fontSize={'14px'} fontWeight={600}>
                  {'Location: '}
                </Text>
                {update.payload[0].location}
              </Text>
            </>
          );
        }

        return null;
      case 'remark':
        return (
          <Text
            color={'gray.700'}
            fontSize={'14px'}
            fontWeight={400}
            fontFamily={'tableCell'}
          >
            "{update?.payload?.[0].value}"
          </Text>
        );
      case 'severity':
        if (update.payload?.[0]) {
          const severityType = update.payload[0].value.toLowerCase();
          return (
            <IssueSeverityType
              marginTop={'2px'}
              type={severityType as SeverityType}
            />
          );
        }
        return null;
      case 'image':
        return (
          <View display={'flex'} flexDir={'row'} flexWrap={'wrap'} w={'100%'}>
            {update?.payload.map(({ url }, index) => (
              <Pressable key={index} onPress={() => onClickImage?.(url)}>
                <Image
                  source={{
                    uri: url,
                  }}
                  borderRadius={'4px'}
                  resizeMode="cover"
                  w="120px"
                  h="120px"
                  m={'10px'}
                  ml={0}
                />
              </Pressable>
            ))}
          </View>
        );
      case 'audio': {
        return update?.payload.map((item) => {
          return (
            <audio controls style={{ width: '100%', marginTop: '8px' }}>
              <source src={item?.url} type={`${item?.contentType}`} />
            </audio>
          );
        });
      }
      case 'video': {
        return update?.payload.map((item) => {
          return (
            <video
              controls
              style={{ width: 'auto', height: '300px', marginTop: '8px' }}
            >
              <source src={item?.url} type={`${item?.contentType}`} />
            </video>
          );
        });
      }
      case 'pdf': {
        return update?.payload.map((item, index) => {
          return (
            <Pressable
              key={index}
              onPress={() => onClickPdf?.(item.url)}
              marginTop={'2px'}
            >
              {({ isHovered }) => (
                <View
                  display={'flex'}
                  flexDir={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <Text
                    fontSize={14}
                    fontWeight={'400'}
                    color={isHovered ? 'gray.900' : 'gray.700'}
                    marginRight={'7px'}
                    textDecorationLine={'underline'}
                  >
                    {item?.pdfTitle}
                  </Text>

                  <ExpoEvilIcon
                    name={'link-external'}
                    size={'15px'}
                    color={isHovered ? 'gray.900' : 'gray.700'}
                  />
                </View>
              )}
            </Pressable>
          );
        });
      }
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickImage, onClickPayload, t, update]);

  return (
    <Box position={'relative'} mt={isFirst ? undefined : '18px'}>
      <ExpoFAIcon
        color={'primary.500'}
        position={'absolute'}
        top={'4px'}
        name="circle"
        size={'14px'}
      />
      <Text display={'inline'} ml={'24px'} opacity={update.isSending ? 0.5 : 1}>
        {moment(update.timestamp).format('D MMM YYYY, h:mm A')}
      </Text>
      <Box
        w={'100%'}
        position={'relative'}
        mt={'6px'}
        opacity={update.isSending ? 0.5 : 1}
      >
        <Box
          borderLeftColor={'gray.500'}
          borderLeftWidth={'1px'}
          position={'absolute'}
          top={'-10px'}
          bottom={isLastUpdate ? '0px' : '-24px'}
          left={'1px'}
          ml={'4px'}
        />
        <Box
          ml={'24px'}
          borderRadius={'6px'}
          bg={'white'}
          borderColor={'gray.300'}
          borderWidth={'1px'}
          pt={'8px'}
          pr={'12px'}
          pb={'12px'}
          pl={'8px'}
        >
          <View
            display={'flex'}
            flexDir={'row'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            {updateIcon}
            <View flex={1} display={'inline-flex'} flexDir={'column'}>
              <Text fontFamily={'tableCell'}>
                <Text fontSize={'14px'} fontWeight={600}>
                  {update.name}
                </Text>{' '}
                {t(updateMessage, { ns: 'messages' })}
              </Text>
              {updateComponent}
            </View>
          </View>
        </Box>
      </Box>
    </Box>
  );
};
