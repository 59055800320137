import React from 'react';
import { Image as ImageFromReactNative } from 'react-native';
import { View, Pressable, Text } from '@know/ui';
import Images from '../../../../Images';

type ISingleText = {
  selectedIndex: any;
  setQuizProgress: (progress: any) => void;
  quizProgress: string[];
  styles: any;
  isSelected: (
    selectedOption: any,
    item: any,
    isHovered: boolean
  ) => 'primary.500' | 'gray.400' | 'gray.300';
  index: any;
  item: any;
  handleNextQuestion: (payload: any) => void;
};
export const SingleSelectText = ({
  selectedIndex,
  setQuizProgress,
  quizProgress,
  styles,
  isSelected,
  index,
  item,
  handleNextQuestion,
}: ISingleText) => {
  return (
    <Pressable
      w="50%"
      onPress={() => {
        const payload = {
          selectedIndex,
          selectedOption: String(index + 1),
        };
        setQuizProgress((prevState: any) => {
          const state = [...prevState];
          state[selectedIndex] = String(index + 1);
          return state;
        });
        handleNextQuestion(payload);
      }}
    >
      {({ isHovered }) => {
        const selectedOption = quizProgress[selectedIndex];
        return (
          <View
            flex={1}
            marginY={['4px', '4px', '6px', '6px', '6px']}
            marginLeft={
              index % 2 === 0 ? '0' : ['4px', '4px', '6px', '6px', '6px']
            }
            marginRight={
              index % 2 === 0 ? ['4px', '4px', '6px', '6px', '6px'] : '0'
            }
            borderColor={isSelected(
              selectedOption,
              String(index + 1),
              isHovered
            )}
            borderWidth="1px"
            borderRadius="5px"
          >
            <View
              flexDirection="row"
              alignItems="center"
              paddingY="10px"
              paddingX="8px"
            >
              <ImageFromReactNative
                style={styles.imageFromReactNative}
                source={
                  selectedOption === String(index + 1)
                    ? Images.RadioSelectedBtn
                    : Images.RadioBtn
                }
              />
              <Text marginLeft="5px" color="gray.900">
                {item}
              </Text>
            </View>
          </View>
        );
      }}
    </Pressable>
  );
};
