import { FirebaseAPI } from '..';
import { raiseLMSAnalytics } from '../LMSAnalytics';

export function getCourseUserFeed({ fbAPI, courseId }: {
  fbAPI: FirebaseAPI;
  courseId: string;
})  {
  const feedRef = fbAPI.getLMSDetails('myCourseFeedById', courseId);
  return fbAPI.getValue(feedRef);
}

export async function setCourseUserFeedForPublicCourse({ fbAPI, courseId, authorId, type }: {
  fbAPI: FirebaseAPI;
  courseId: string;
  authorId: string;
  type: string;
})  {
  const isLJ = type === 'learningPath';
  const userFeedPath = fbAPI.getMultiPath(`/userFeed/${fbAPI.getLoggedInUserId()}/courses/${courseId}`);
  const shareId = fbAPI.getPushKey();
  const directLaunchPayload = {
    "isReceived": true,
    "author": authorId,
    "classificationType": "courses",
    "createdAt": fbAPI.getServerTimestamp(),
    shareId: `wdl-${shareId}`,
    "shareType": 'WEB-DirectLaunch'
  };
  const userFeedReceivedPayload = {
    journeyId: isLJ ? courseId : null,
    courseId: isLJ ? null : courseId,
    dt: Date.now(),
    orgId: fbAPI.organization,
    shareId: directLaunchPayload?.shareId,
    st: fbAPI.getServerTimestamp(),
    type: "received",
    uuid: fbAPI.getLoggedInUserId(),
  };
  await fbAPI.fbUpdate(userFeedPath, directLaunchPayload);
  await raiseLMSAnalytics({ type: "Received", fbAPI, payload: userFeedReceivedPayload});
}
