import { Text, HStack, KNOWIcon, KNPressable } from '.';

import React from 'react';

export interface IFormItem {
  id: string;
  name: string;
  createdAt: number;
  receivedAt?: number;
  isRead?: boolean;
  isReceived?: boolean;
  isShared?: boolean;
}

export interface IFormFeedItemProps {
  type: string;
  item: IFormItem;
  onPress?: (item: IFormItem, type: string) => void;
}

export function FormFeedItem({ onPress, item, type }: IFormFeedItemProps) {
  function handleOnPress() {
    onPress && onPress(item, type);
  }
  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        pr={'20px'}
        pl={'24px'}
        py={'12px'}
      >
        <KNOWIcon
          color={'gray.900'}
          mr={2}
          name={'filetext1'}
          size="4"
          textAlign="center"
          mt={'2px'}
        />
        <Text
          fontFamily={'openSemiBold'}
          fontWeight={'semibold'}
          display={'flex'}
          alignItems={'center'}
          color={'gray.900'}
        >
          {item.name}
        </Text>
      </HStack>
    </KNPressable>
  );
}
