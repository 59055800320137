import { IReceivedResponseItem } from '@know/transformers';
import moment from 'moment';
import { useMemo } from 'react';

export function useReceivedFormItemData(
  item: IReceivedResponseItem,
  _section: any
) {
  const statusLabel = useMemo(() => {
    const status = item.status?.toLowerCase();
    if (
      status === 'accepted' ||
      status === 'submitted' ||
      status === 'approved'
    ) {
      return status === 'submitted' ? 'Submitted' : 'Approved';
    } else if (status === 'suspended' || status === 'suspend') {
      return 'Suspended';
    } else {
      return status || '';
    }
  }, [item.status]);

  const byLineData = useMemo(() => {
    const status = item.status?.toLowerCase();
    const senderName = item.senderName;
    const userName = item.userName;
    if (senderName || userName) {
      return [
        status === 'pending' ? 'Sender:' : 'From',
        senderName || userName,
      ];
    }
    return ['', ''];
  }, [item.senderName, item.userName, item.status]);

  const displayText = useMemo(() => {
    return (
      item.sno || `${moment(item.createdAt).format('DD/MM/YYYY')} Form response`
    );
  }, [item.sno, item.createdAt]);

  return {
    isWorkflow: Boolean(item.status),
    editedText: '',
    displayText,
    status: statusLabel,
    byText: byLineData[0],
    userName: byLineData[1],
    time: item.createdAt,
  };
}
