import React, { useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = ({
  type,
  src,
  isVideoAutoPlayed,
  handleVideoConsumed,
  consumeTime,
  isFullScreenVideo = false,
}: any) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef<any>(null);
  let [consumedObj, setConsumedObj] = React.useState<any>(false);
  var currentTime = React.useRef(0);
  const options = {
    autoplay: false,
    controls: true,
    responsive: true,
    volumeMenuButton: false,
    fluid: !isFullScreenVideo,
    sources: [
      {
        src: src,
        type: type,
      },
    ],
  };

  useEffect(() => {
    if (consumedObj) {
      let vals = consumedObj.split('_');
      handleVideoConsumed({
        consumeTime: vals[0],
        isVideoEnd: vals[1] === 'false' ? false : true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumedObj]);

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
    let isConsumedCalled = false;

    player.on('waiting', () => {
      player.log('player is waiting');
    });

    player.on('dispose', () => {
      player.log('player will dispose');
    });

    player.on('timeupdate', function () {
      if (!player?.paused()) {
        if (
          !isConsumedCalled &&
          !consumedObj &&
          ((consumeTime && player?.currentTime() > consumeTime) ||
            (player?.duration() > 30 && player?.currentTime() > 30))
        ) {
          isConsumedCalled = true;
          if (!consumedObj) {
            setConsumedObj(`${player?.currentTime()}_false`);
          }
        }
        if (!player.seeking()) {
          currentTime.current = player.currentTime();
        }
      }
    });

    player.on('ended', function () {
      if (!isConsumedCalled) {
        isConsumedCalled = true;
        if (!consumedObj) {
          setConsumedObj(`${player?.currentTime()}_true`);
        }
      }
    });

    player.on('seeking', function () {
      if (currentTime.current! < player.currentTime()) {
        player.currentTime(currentTime.current);
      }
    });
  };

  React.useEffect(() => {
    let player: any;
    if (!playerRef.current) {
      const videoElement: any = videoRef.current;
      player = videojs(videoElement, options, () => {
        if (!videoElement) return;
        player.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      });
    } else {
      player?.autoplay(options.autoplay);
      player?.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player?.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        autoPlay={isVideoAutoPlayed}
        className={`video-js vjs-big-play-centered ${
          isFullScreenVideo ? 'full-screen' : ''
        }`}
      />
    </div>
  );
};

export default VideoJS;
