import React from 'react';

import { Text, Image, Center } from '@know/ui';
import Images from '../../Images';
import { useTranslation } from '@know/i18n';

type IconType = 'tasks' | 'forms' | 'issues';

export function NoDataForms({
  text,
  icon = 'forms',
}: {
  text?: string;
  icon?: IconType;
}) {
  const { t } = useTranslation('messages');

  let imageSrc = Images.Forms;

  switch (icon) {
    case 'tasks':
      imageSrc = Images.ChecklistCircle;
      break;
    case 'forms':
      imageSrc = Images.Forms;
      break;
    case 'issues':
      imageSrc = Images.Issues;
      break;
    default:
      break;
  }

  return (
    <Center my={3} justifyContent={'flex-start'} mx={'20px'}>
      <Image
        size={'sm'}
        resizeMode="cover"
        source={{
          uri: imageSrc,
        }}
        alt={t('No courses found')}
      />
      <Text
        h={5}
        mt={3}
        textAlign={'center'}
        color={'gray.700'}
        fontSize={'md'}
      >
        {t(text ?? "Looks like you don't have any forms yet.")}
      </Text>
    </Center>
  );
}
