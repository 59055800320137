import React from 'react';
import {
  Modal,
  Heading,
  VStack,
  Text,
  Button,
  Center,
  KNOWLoader,
  useRecoilValue,
} from '@know/ui';
import type { ShiftWithIdInterface, AttendanceEventType } from './state';
import { isDoingAttendanceState } from './state';
import { get } from 'lodash';
import moment from 'moment';

export interface InfoModalProps {
  shift?: ShiftWithIdInterface;
  timestamp?: number;
  type?: AttendanceEventType | 'dropShift';
  closeModal: () => void;
  isOpen: boolean;
  isLoading?: boolean;
}

export const InfoModal = ({
  shift,
  timestamp,
  type,
  closeModal,
  isOpen,
}: InfoModalProps) => {
  const isLoading = useRecoilValue<boolean>(isDoingAttendanceState);

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={closeModal}>
      {shift && type && timestamp ? (
        //@ts-ignore
        <Modal.Content>
          <Modal.Body p="20px">
            <Center>
              {isLoading ? (
                <KNOWLoader />
              ) : (
                <VStack space={3} alignItems="center">
                  <Heading fontWeight="500" color="gray.700" textAlign="center">
                    {type === 'checkIn'
                      ? `Started shift ${
                          get(shift, 'location')
                            ? `at ${get(shift, 'location')}`
                            : ''
                        }`
                      : type === 'checkOut'
                      ? `Ended shift ${
                          get(shift, 'location')
                            ? `at ${get(shift, 'location')}`
                            : ''
                        }`
                      : `Shift dropped ${
                          get(shift, 'location')
                            ? `at ${get(shift, 'location')}`
                            : ''
                        }`}
                  </Heading>
                  <Text textAlign="center">
                    {moment(timestamp).format('DD MMM h:mma')}
                  </Text>
                </VStack>
              )}
            </Center>
          </Modal.Body>
          {isLoading ? null : (
            <Modal.Footer justifyContent="center">
              <Button colorScheme="primary" onPress={closeModal} px="20px">
                OK
              </Button>
            </Modal.Footer>
          )}
        </Modal.Content>
      ) : null}
    </Modal>
  );
};
