import React from 'react';
import {
  useDebounceLoading,
  useDebounceSetData,
  useKNOWDBContext,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  Text,
  TaskStatus,
  useNavigation,
  TaskDeadline,
  useKNOWValueContextProvider,
  ExpoIonicIcon,
  TaskListRowEllipsisMenuOption,
  View,
  Menu,
  IconButton,
  VerticalEllipsisIcon,
  HStack,
  StackActions,
} from '@know/ui';
import {
  taskFeedAtom,
  taskFeedFilterTextAtom,
  taskFeedItemsSelector,
} from './state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  cleanUpSubscribeTasksNuggets,
  raiseNuggetAnalytics,
  subscribeToUserTasksFeed,
} from '@know/db';
import { TaskFeedItem, TransformedTaskItem } from '@know/transformers';
import { omit } from 'lodash';

const EMPTY_FUNCTION = () => {};

export const useTaskFeed = () => {
  const { currentDBApi } = useKNOWDBContext();
  const navigation = useNavigation();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [filterText, onFilterTextChange] = useRecoilState(
    taskFeedFilterTextAtom
  );

  const setTaskFeedItemsAtom = useSetRecoilState(taskFeedAtom);

  const taskItems = useRecoilValue(taskFeedItemsSelector);

  const setTaskFeedItems = useDebounceSetData({}, setTaskFeedItemsAtom, 500);

  const stopLoading = useCallback(() => {
    setIsDataLoading(false);
  }, []);

  const setDataLastUpdated = useDebounceLoading(stopLoading, 700);

  const subScriptionHandler = useCallback(
    (taskId: string, taskFeedItem: TaskFeedItem | null) => {
      setTaskFeedItems((feedItems: any) => {
        setDataLastUpdated(Date.now());
        let newItems;
        if (taskFeedItem) {
          newItems = {
            ...feedItems,
            [taskId]: taskFeedItem,
          };
        } else {
          newItems = omit(feedItems, [taskId]);
        }
        return newItems;
      });
    },
    [setDataLastUpdated, setTaskFeedItems]
  );

  const { isTabletSize, isPhoneSize, isLargePhoneSize, openShareDialog } =
    useKNOWValueContextProvider();
  const isSmallScreen = isTabletSize || isPhoneSize || isLargePhoneSize;

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribe = subscribeToUserTasksFeed(
        currentDBApi,
        subScriptionHandler
      );
      setTimeout(() => setDataLastUpdated(Date.now()), 2000);
      // Return statement has un listening callback.
      return () => {
        unSubScribe();
        cleanUpSubscribeTasksNuggets();
      };
    }
    return EMPTY_FUNCTION;
  }, [currentDBApi, setDataLastUpdated, subScriptionHandler]);

  const menuOptions = useMemo<TaskListRowEllipsisMenuOption[]>(() => {
    return [
      {
        id: ' share-nugget',
        Icon: (
          <ExpoIonicIcon
            color="gray.700"
            size="18px"
            name="share-social-sharp"
          />
        ),
        label: 'Share',
        onClick: (item: TransformedTaskItem) => {
          openShareDialog('tasks', item.taskId, item.author.userId);
        },
      },
    ];
  }, [openShareDialog]);

  const columns = useMemo(
    () => [
      {
        id: 'taskExtId',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            ID
          </Text>
        ),
        accessor: 'taskExtId', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const taskExtId = row?.values?.taskExtId;
          if (!taskExtId) {
            return (
              <Text fontFamily={'tableCell'} fontWeight={400}>
                {''}
              </Text>
            );
          }

          const fontWeight = row?.original?.isRead ? 400 : 700;

          return (
            <Text fontFamily={'tableCell'} fontWeight={fontWeight}>
              {taskExtId}
            </Text>
          );
        },
      },
      {
        id: 'title',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Title
          </Text>
        ),
        accessor: 'title', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const fontWeight = row?.original?.isRead ? 400 : 700;

          return (
            <Text fontFamily={'tableCell'} fontWeight={fontWeight}>
              {row?.values?.title}
            </Text>
          );
        },
        flex: 4,
      },
      {
        id: 'deadline',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Due Date
          </Text>
        ),
        accessor: 'deadline', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const deadline = row?.values?.deadline;
          if (!deadline) {
            return (
              <Text
                fontFamily={'tableCell'}
                fontWeight={400}
                color={'gray.700'}
              >
                {''}
              </Text>
            );
          }

          return <TaskDeadline deadline={deadline} />;
        },
        flex: 2,
      },
      {
        id: 'status',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Status
          </Text>
        ),
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => (
          <TaskStatus status={row?.values?.status} />
        ),
        enableSorting: false,
        flex: 2,
      },
      {
        id: 'authorName',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Created By
          </Text>
        ),
        accessor: 'authorName',
        Cell: ({ row }: { row: any }) => {
          const item = row?.original;

          return (
            <View
              display={'flex'}
              flexDir={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flex={1}
              w={'100%'}
            >
              <Text
                fontFamily={'tableCell'}
                fontWeight={400}
                color={'gray.700'}
              >
                {row?.values?.authorName}
              </Text>
              {menuOptions?.length ? (
                <Menu
                  placement="left"
                  trigger={(triggerProps) => {
                    return (
                      <IconButton
                        icon={
                          <VerticalEllipsisIcon
                            size={'20px'}
                            color={'gray.950'}
                          />
                        }
                        {...triggerProps}
                        p={'4px'}
                      />
                    );
                  }}
                >
                  {menuOptions.map(({ id, label, onClick, Icon }) => (
                    <Menu.Item onPress={() => onClick(item)} key={id}>
                      <HStack alignItems={'center'} mx={'-12px'}>
                        {Icon}
                        <Text ml={'8px'}>{label}</Text>
                      </HStack>
                    </Menu.Item>
                  ))}
                </Menu>
              ) : null}
            </View>
          );
        },
        flex: 2,
      },
    ],
    [menuOptions]
  );

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'deadline', desc: false }],
    }),
    []
  );

  const onClick = useCallback(
    (item: TransformedTaskItem) => {
      if (currentDBApi && !item.isRead) {
        raiseNuggetAnalytics({
          fbAPI: currentDBApi,
          classificationType: 'tasks',
          shareId: item.shareId ?? '-',
          type: 'opened',
          nuggetId: item.taskId,
        });
      }

      // we have to do this to fix a weird bug related to focus ref
      // https://knownuggets.atlassian.net/browse/KNOW-1279
      // 9. From Task list page - filter form A and open it - now tap on complete button - control is not passing to alert ( without filter if i directly open the task means, i can able to complete the task) --> Same for re-open task also from completed tab
      navigation.dispatch(
        StackActions.replace(
          'details' as never,
          {
            taskId: item.taskId,
            shareId: item.shareId,
          } as never
        )
      );

      // navigation.navigate(
      //   'Tasks' as never,
      //   {
      //     screen: 'details',
      //     params: {
      //       taskId: item.taskId,
      //       shareId: item.shareId,
      //     },
      //   } as never
      // );
    },
    [currentDBApi, navigation]
  );

  return {
    isDataLoading,
    filterText,
    taskItems,
    initialState,
    columns,
    isSmallScreen,
    menuOptions,
    onFilterTextChange,
    onClick,
  };
};
