import mitt from 'mitt';

import React, { createContext, useCallback, useContext } from 'react';

const emitter = mitt<any>();

interface KNOWEmitterContextInterface {
  registerListener: (
    eventType: string,
    cb: (...args: any[]) => any
  ) => () => void;
  emitEvent: (eventType: string, payload: any) => void;
}

const KNOWEmitterContext = createContext<KNOWEmitterContextInterface>({
  registerListener: () => {
    return () => {};
  },
  emitEvent: () => {},
});

export const useKNOWEmitterContext = () => useContext(KNOWEmitterContext);

const KNOWEmitterContextProvider = KNOWEmitterContext.Provider;

export const EventEmitterProvider: React.FC<{}> = ({ children }) => {
  const registerListener = useCallback(
    (eventType: string, cb: (...args: any[]) => any) => {
      emitter.on(eventType, cb);

      return () => emitter.off(eventType, cb);
    },
    []
  );

  const emitEvent = useCallback((eventType: string, payload: any) => {
    emitter.emit(eventType, payload);
  }, []);
  return (
    <KNOWEmitterContextProvider
      value={{
        registerListener,
        emitEvent,
      }}
    >
      {children}
    </KNOWEmitterContextProvider>
  );
};
