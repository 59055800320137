import React, { useMemo } from 'react';

import type { ISectionDataProps, Item } from '@know/transformers';
import {
  Checkbox,
  Divider,
  HStack,
  KNOWIcon,
  KNPressable,
  useKNOWValueContextProvider,
  useSelectableSectionsView,
} from '@know/ui';

import type { JSXElementConstructor, ReactElement } from 'react';

export const keyExtractor = function (item: Item): string {
  return item.id;
};

const CollapsibleSectionHeaderWrapper = (info: {
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  const { onToggleSection, getSectionHeaderComponent, selectable } =
    useSelectableSectionsView();

  const iconName = useMemo(
    () => (info.section.isExpanded ? 'caretdown' : 'caretright'),
    [info.section.isExpanded]
  );

  const headerComponent = getSectionHeaderComponent(info, handleOnToggle);

  return (
    <KNPressable
      onPress={handleOnToggle}
      testID={`SectionHeader-${info.section.title}`}
    >
      <HStack alignItems={'center'} w={'full'} flex={1} py={'12px'} pr={'12px'}>
        {!info.section.disableToggle && (
          <KNOWIcon
            color={'gray.900'}
            mr={2}
            name={iconName}
            size="3"
            textAlign="center"
          />
        )}
        {selectable && (
          <Checkbox
            borderRadius={1}
            borderWidth={1}
            isIndeterminate={true}
            mr={'2'}
            boxSize={4}
            value={info.section.id || info.section.title}
          />
        )}
        {headerComponent}
      </HStack>
    </KNPressable>
  );

  function handleOnToggle() {
    onToggleSection && onToggleSection(info.section.id || info.section.type);
  }
};

export const renderSectionHeader = (info: {
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return info.section.data.length ? (
    <>
      <CollapsibleSectionHeaderWrapper {...info} />
      <Divider />
    </>
  ) : null;
};

const ItemWrapper = (info: {
  item: Item;
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  const { ItemComponent, selectable } = useSelectableSectionsView();
  const { isPhoneSize } = useKNOWValueContextProvider();
  if (info.section.isExpanded) {
    return (
      <>
        <HStack alignItems={isPhoneSize ? 'flex-start' : 'center'}>
          {selectable && (
            <Checkbox
              borderRadius={1}
              borderWidth={1}
              boxSize={'4'}
              mr={'2'}
              value={info.item.id}
              mt={isPhoneSize ? '3px' : undefined}
            />
          )}
          <ItemComponent {...info} />
        </HStack>
        <Divider />
        {/* // sent && drafts */}
        {/* {(info.item as any).type === 'hasMore' ? <Divider /> : null} */}
      </>
    );
  } else {
    return null;
  }
};

export const renderItem = (info: {
  item: Item;
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return <ItemWrapper {...info} />;
};
