import { DataSnapshot } from "firebase/database";
import { TaskFeedItem } from "@know/transformers";
import { FirebaseAPI, listenToIssueNugget } from "..";

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: {
  [id: string]: () => void;
} = {};

let cleanupsFeed: {
  [id: string]: () => void;
} = {};

function listenToSpecificIssueFeed(
  fbAPI: FirebaseAPI,
  nuggetId: string,
  cb: (data: any | null) => any
) {
  const pathRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("currentUserFeed")),
    `tasklist/${nuggetId}`
  );

  const handler = (snap: DataSnapshot) => {
    const issueNuggetFeed = snap.val() ?? {};
    cb(issueNuggetFeed);
  };
  return fbAPI.listenOnNodeRef(pathRef, "value", handler);
}

export function subscribeToUserMutedIssues(
  fbAPI: FirebaseAPI,
  cb: (nugId: string, feedItem: TaskFeedItem | null) => void
) {
  const userFeedRef = fbAPI.getNodeRef(fbAPI.getCommonPath("mutedIssues"));

  function listenerHandler(_listenerEvenType: string) {
    return async (userFeedSnapshot: DataSnapshot) => {
      const mutedIssueFeed: any = (await userFeedSnapshot.val()) || null;

      const id = userFeedSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        cleanups[id]?.();
        return cb(id, null);
      }
      if (mutedIssueFeed) {
        cleanupsFeed[id]?.()
        cleanupsFeed[id] = listenToSpecificIssueFeed(fbAPI, id, async (feed) => {
          if (feed){
            const author = await fbAPI.getUserDetails(feed.author);
            cleanups[id]?.();
            cleanups[id] = listenToIssueNugget(fbAPI, id, (nugget) => {
              if (nugget && nugget.status?.toLowerCase() !== 'closed') {
                cb(id, {
                  ...feed,
                  author,
                  nugget,
                  id,
                });
              } else {
                cb(id, null);
              }
            });
          }
          else {
            cb(id, null);
          }
        })
      } else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userFeedRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userFeedRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userFeedRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribeMutedIssuesNuggets = () => {
  for (const c of Object.keys(cleanups)) {
    cleanups[c]?.();
  }
  cleanups = {};

  for (const c of Object.keys(cleanupsFeed)) {
    cleanupsFeed[c]?.();
  }
  cleanupsFeed = {};
};
