import { useEffect, useContext, useState, useMemo } from 'react';
import {
  useRecoilState,
  KNOWDBContext,
  useSetRecoilState,
  useRecoilValue,
  useNavigation,
} from '@know/ui';
import { get } from 'lodash';

import {
  coursePayloadSelector,
  currentCourseTabAtom,
  lessonDetailsAtom,
  progressLMSOfCourseShareSelector,
  progressQuizLMSOfCourseShareSelector,
  sessionIdAtom,
  userCompletedCourseFeedSelector,
  userCourseFeedSelector,
  lmsReportsPayloadAtom,
  languageModalAtom,
  getProgressBeforeResetAtom,
  isLessonConsumedAtom,
  journeyCoursePayloadAtom,
  isJourneyConsumedAtom,
} from '../state';

import type {
  ICoursePayload,
  ILessonDetails,
  ILMSProgressCourse,
} from '../types';
import useLMSAnalyticsCallback from './useLMSAnalyticsCallback';

export const useCourseDetailsFeed = (
  courseId: string,
  cardInfo: {
    miniThumbnail: string;
    title: string;
    totalCount: number;
    progress: number;
    type: string;
    languages: { description: string; locale: string; seq: 0; title: string }[];
    courseId: string;
    journeyId: string | null;
    description: string;
    selectedLanguage: string;
    shareId: string;
    finishedLessonsCount: number;
  },
  journeyId: string
) => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const navigation = useNavigation();
  const [coursePayload, setCoursePayload] = useRecoilState<ICoursePayload>(
    coursePayloadSelector(courseId)
  );
  const [isLoading, setIsLoading] = useState(true);
  const setLessonDetails =
    useSetRecoilState<{ [key: string]: ILessonDetails[] }>(lessonDetailsAtom);
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const userFeed = useRecoilValue(
    currentCourseTab === 'completed-courses'
      ? userCompletedCourseFeedSelector(journeyId ?? courseId)
      : userCourseFeedSelector(journeyId ?? courseId)
  );
  const [isJourneyConsumed, setIsJourneyConsumed] = useRecoilState<boolean>(
    isJourneyConsumedAtom
  );
  const [progressLMS, setProgressLMS] = useRecoilState<ILMSProgressCourse>(
    progressLMSOfCourseShareSelector(journeyId ?? courseId)
  );
  const setProgressQuizLMS = useSetRecoilState<ILMSProgressCourse>(
    progressQuizLMSOfCourseShareSelector(journeyId ?? courseId)
  );
  const setReportsPayload = useSetRecoilState(lmsReportsPayloadAtom);
  const isOpenLanguageModal = useRecoilValue(languageModalAtom);
  const setProgressBeforeReset = useSetRecoilState<ILMSProgressCourse>(
    getProgressBeforeResetAtom
  );
  const [isLessonConsumed, setIsLessonConsumed] =
    useRecoilState(isLessonConsumedAtom);
  const journeyCoursePayload = useRecoilValue(journeyCoursePayloadAtom);
  // LMS Analytics Reports Callback
  const { progressLMS: progressLMSReport, isCourseCompleted } =
    useLMSAnalyticsCallback(cardInfo);

  const courseDetails = useMemo(() => {
    const selectedLanguageDetails = get(
      coursePayload,
      ['payload', cardInfo.selectedLanguage, 'modules'],
      {}
    );

    return Object.keys(selectedLanguageDetails).length > 0
      ? selectedLanguageDetails
      : get(
          coursePayload,
          [
            'payload',
            Object.keys(coursePayload?.payload || {})[0] ?? 'eng',
            'modules',
          ],
          {}
        );
  }, [coursePayload, cardInfo?.selectedLanguage]);
  setLessonDetails(courseDetails);

  const lessons: any =
    useRecoilValue<{ [key: string]: ILessonDetails[] }>(lessonDetailsAtom);
  const sessionId = useRecoilValue(sessionIdAtom);
  const lessonsProgress: any = journeyId
    ? get(progressLMS, `${cardInfo?.shareId}.${courseId}`, {})
    : get(progressLMS, cardInfo?.shareId, {});
  const isCourseBegin = Object.keys(lessonsProgress).length ?? 0;
  const isSequence = get(
    coursePayload,
    ['payload', cardInfo.selectedLanguage, 'isSequence'],
    false
  );

  const resetCourseProgress = async () => {
    if (currentDBApi) {
      currentDBApi?.resetCourseProgress({
        courseId,
        journeyId,
        shareId: cardInfo?.shareId,
        journeyFinishCount: cardInfo?.finishedLessonsCount,
        isConsumed:
          progressLMS?.[cardInfo?.shareId]?.[courseId]?.hasOwnProperty(
            'consumedAt'
          ),
        isLanguageSwitch: false,
      });
    }
  };

  const handleCourse = async () => {
    // Begin course
    if (isCourseBegin === 0) {
      let firstLesson: any = Object.values(lessons)
        .sort((a: any, b: any) => a.seq - b.seq)
        ?.find((ln: any) => ln.seq === 0);
      const firstCard: any = Object.values(firstLesson?.cards).find(
        (card: any) => card.seq === 0
      );
      navigateCard(firstLesson?.id, firstCard?.id);
    } else {
      // Resume Course
      let lessonCardProgressObj: any = { lessons: {}, cardProgress: {} };
      for (const [key, lessonValue] of Object.entries(lessons) as any) {
        const progressValue = lessonsProgress[key];
        const lesson = lessonValue;
        const cardProgress = get(progressValue, 'cardProgress', {});

        // if lesson not started yet
        if (progressValue === undefined) {
          lessonCardProgressObj = {
            lessons: {
              ...lessonCardProgressObj?.lessons,
              [key]: lesson,
            },
            cardProgress: {
              ...lessonCardProgressObj?.cardProgress,
            },
          };
        } else {
          // if lesson is not completed
          if (!progressValue?.hasOwnProperty('consumedAt')) {
            lessonCardProgressObj = {
              lessons: {
                ...lessonCardProgressObj?.lessons,
                [key]: lesson,
              },
              cardProgress: {
                ...lessonCardProgressObj?.cardProgress,
                ...cardProgress,
              },
            };
          }
        }
      }
      handleResumeCard(lessonCardProgressObj);
    }
  };

  const handleResumeCard = async (
    lessonCardProgressObj: any,
    isCompleted?: boolean
  ) => {
    let navigateToCard: any = {};
    let navigateToLesson: any = {};
    let lastConsumedCardId =
      Object.keys(lessonCardProgressObj?.cardProgress).length > 0
        ? Object.keys(lessonCardProgressObj?.cardProgress)?.reduce((a, b) =>
            lessonCardProgressObj?.cardProgress[a]?.consumedAt >
            lessonCardProgressObj?.cardProgress[b]?.consumedAt
              ? a
              : b
          )
        : undefined;

    if (isCompleted) {
      lastConsumedCardId = undefined;
    }
    if (lastConsumedCardId !== undefined) {
      navigateToLesson = Object.values(lessonCardProgressObj?.lessons).find(
        (lesson: any) => {
          let cards: any = Object.values(lesson?.cards);
          for (let card of cards) {
            if (lastConsumedCardId && card?.id === lastConsumedCardId) {
              const cardIndex = cards.findIndex(
                (_cd: any) => card?.seq + 1 === _cd?.seq
              );
              navigateToCard = cards[cardIndex < 0 ? 0 : cardIndex];
            }
          }
          return cards?.find((card: any) => card?.id === navigateToCard?.id);
        }
      );
    } else {
      const sortedLessons = Object.values(lessonCardProgressObj?.lessons).sort(
        (a: any, b: any) => a?.seq - b?.seq
      );
      navigateToLesson = sortedLessons?.[0] ?? {};
      const sortedCards = Object.values(navigateToLesson?.cards)?.sort(
        (a: any, b: any) => a?.seq - b?.seq
      );
      navigateToCard = sortedCards?.[0] ?? {};
    }

    if (navigateToLesson === undefined || navigateToCard === undefined) {
      navigateToLesson = Object.values(lessons)
        .sort((a: any, b: any) => a.seq - b.seq)
        ?.find((ln: any) => ln.seq === 0);
      navigateToCard = Object.values(navigateToLesson?.cards).find(
        (card: any) => card.seq === 0
      );
      navigateCard(navigateToLesson?.id, navigateToCard?.id);
    } else {
      navigateCard(navigateToLesson?.id, navigateToCard?.id);
    }
  };

  const navigateCard = async (lessonId: string, cardId: string) => {
    navigation.navigate(
      'card' as never,
      {
        courseId,
        lessonId,
        cardId,
        journeyId,
      } as never
    );
  };

  useEffect(() => {
    const getCoursePayload = async () => {
      if (currentDBApi && !coursePayload?.payload) {
        // Do we need live listener here?
        const coursePayloadValue = await currentDBApi.getLMSCoursePayload(
          courseId
        );
        setCoursePayload({
          payload: coursePayloadValue,
        });
      }
      setIsLoading(false);
    };
    getCoursePayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, currentDBApi]);

  useEffect(() => {
    const fetchByCourseId = journeyId || courseId;
    const getProgressLMS = async () => {
      if (fetchByCourseId) {
        await currentDBApi?.listenForProgressLMS(async (data: any) => {
          const [progressType, progress] = data;
          if (progressType === 'progressLMS') {
            setProgressLMS(progress);
            if (Object.keys(progress || {}).length > 0 && !journeyId) {
              setProgressBeforeReset(progress);
            }
            const lessonProgress = get(
              progress,
              journeyId
                ? `${[cardInfo?.shareId]}.${[courseId]}`
                : [cardInfo?.shareId],
              {}
            );
            const totalLessons = Object.keys(courseDetails)?.length || 0;
            const consumedLessons =
              Object.values(lessonProgress).filter((lesson: any) =>
                lesson.hasOwnProperty('consumedAt')
              )?.length || 0;
            const consumedCourses =
              Object.values(progress?.[cardInfo?.shareId] || {}).filter(
                (course: any) => course.hasOwnProperty('consumedAt')
              )?.length || 0;
            const totalCourses =
              Object.keys(journeyCoursePayload || {})?.length || 0;

            if (
              isLessonConsumed &&
              currentCourseTab !== 'completed-courses' &&
              totalLessons === consumedLessons &&
              totalLessons > 0 &&
              (journeyId
                ? !progress?.[cardInfo?.shareId]?.[courseId]?.hasOwnProperty(
                    'consumedAt'
                  )
                : !progress?.[cardInfo?.shareId]?.hasOwnProperty('consumedAt'))
            ) {
              const payload = {
                courseId,
                dt: Date.now(),
                lang: cardInfo?.selectedLanguage,
                sessionId,
                shareId: cardInfo?.shareId,
                journeyId,
              };
              currentDBApi?.setUserFeedCourseInfo('CourseConsumed', payload);
              currentDBApi?.setUserFeedCourseInfo('CourseCompleted', payload);

              if (!journeyId) {
                handleReportsPayload(true);
                currentDBApi?.setUserFeedCourseInfo(
                  'MoveUserFeedToCompletedFeed',
                  {
                    courseId,
                    userFeed,
                  }
                );
              } else {
                currentDBApi?.setUserFeedCourseInfo(
                  'UpdateFinishCountUserFeed',
                  { journeyId }
                );
              }
              setIsLessonConsumed(false);
              setIsJourneyConsumed(false);
            }

            if (
              !isJourneyConsumed &&
              journeyId &&
              Object.keys(progress || {}).length > 0 &&
              totalCourses === consumedCourses &&
              totalCourses > 0 &&
              !progress?.hasOwnProperty('consumedAt')
            ) {
              setIsJourneyConsumed(true);
            }
          } else {
            setProgressQuizLMS(progress);
          }
        }, fetchByCourseId);
      }
    };
    getProgressLMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCoursePayload, isOpenLanguageModal]);

  const handleReportsPayload = (isCompleted: boolean) => {
    const { hasCourseProgress, totalCardsConsumed } = progressLMSReport(
      journeyId ? true : false,
      courseId
    );
    let courseStatus: 'inProgress' | 'notStarted' | 'completed';
    switch (hasCourseProgress) {
      case true:
        courseStatus = 'inProgress';
        break;
      case false:
        courseStatus = 'notStarted';
        break;
      default:
        courseStatus = 'notStarted';
        break;
    }
    if (isCompleted) {
      courseStatus = 'completed';
    }
    setReportsPayload((prevState: any) => {
      const state = { ...prevState };
      const currentReport = state[courseId];
      if (
        currentReport?.courseStatus === 'completed' &&
        courseStatus !== 'completed'
      ) {
        return prevState;
      }
      state[courseId] = {
        sessionStartTime: Date.now(),
        ...currentReport,
        courseStatus,
        totalCardsConsumed,
      };
      return state;
    });
  };

  useEffect(() => {
    const fetchByCourseId = journeyId || courseId;
    const isCourseComplete = isCourseCompleted(
      fetchByCourseId,
      journeyId ? true : false
    );
    handleReportsPayload(isCourseComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, journeyId]);

  return {
    coursePayload,
    isLoading,
    courseDetails,
    progressLMS,
    isCourseBegin,
    handleCourse,
    handleResumeCard,
    resetCourseProgress,
    isSequence,
  };
};
