import {
  useBreakpointValue,
  useRecoilValue,
  useSetRecoilState,
  KNOWDBContext,
} from '@know/ui';

import { useRef, useState, useContext, useEffect, useCallback } from 'react';
import {
  completedCoursesSelector,
  userCompletedCoursesFeedAtom,
} from '../state';
import type { IDivInfo, IUserFeed } from '../types';

const LIMIT = 10;
export const useCompletedCourses = (loadMore: boolean, setLoadMore: any) => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const COURSE_TYPE = 'completedCourseFeed';
  const userFeed = useRecoilValue(completedCoursesSelector);
  const setUserFeedObject = useSetRecoilState<{
    [key: string]: IUserFeed;
  }>(userCompletedCoursesFeedAtom);

  const [isLoading, setIsLoading] = useState(true);

  const noOfColumns = useBreakpointValue({
    sm: 1,
    md: 4,
    lg: 4,
    xl: 4,
  });

  const parentDivInfo = useRef<IDivInfo>({
    height: null,
    width: null,
  });

  const childDivInfo = useRef<IDivInfo>({
    height: null,
    width: null,
  });

  const getUserFeed = useCallback(async () => {
    if (currentDBApi && loadMore) {
      const feed: IUserFeed = await currentDBApi.getUserCourseFeedItems({
        limit: LIMIT,
        lastCreatedAt:
          userFeed?.[userFeed?.length - 1]?.createdAt ?? Date.now(),
        courseType: COURSE_TYPE,
      });
      if (
        userFeed &&
        feed &&
        userFeed?.[userFeed?.length - 1]?.createdAt ===
          Object.values(feed)[Object.values(feed)?.length - 1]?.createdAt
      ) {
        setLoadMore(false);
      } else {
        setUserFeedObject((existingFeed: any) => {
          return {
            ...existingFeed,
            ...(feed || {}),
          };
        });
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDBApi, setUserFeedObject, userFeed]);

  useEffect(() => {
    getUserFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    userFeed,
    noOfColumns,
    parentDivInfo,
    childDivInfo,
    isLoading,
    getUserFeed,
  };
};
