export default {
  // Main side bar menu
  'Learn': 'నేర్చుకో',
  'Forms': 'ఫారమ్‌లు',
  'Shifts': 'షిఫ్ట్‌లు',
  'Location QR': 'క్యూఆర్ స్థానం',
  'Sign Out': 'సైన్ అవుట్',
  'Sign out': 'సైన్ అవుట్',
  // Forms side bar menu
  'My Forms': 'నా ఫారమ్‌లు',
  'Action Required': 'చర్య అవసరం',
  'New Forms': 'కొత్త ఫారమ్‌లు',
  'New': 'కొత్త',
  'Draft Forms': 'డ్రాఫ్ట్‌ ఫారమ్‌లు',
  'Draft': 'డ్రాఫ్ట్‌',
  'Sent Forms': 'పంపిన ఫారమ్‌లు',
  'Sent': 'పంపిన',
  'Received Forms': 'అందుకున్న ఫారమ్‌లు',
  'Received': 'అందుకున్న',
};
