import { useCallback, useMemo } from 'react';
import { get } from 'lodash';

import { useRecoilValue } from '@know/ui';
import type { ILessonDetails, ILMSProgressCourse } from '../types';
import {
  getProgressBeforeResetAtom,
  lessonDetailsAtom,
  progressLMSOfCourseShareSelector,
} from '../state';

interface ICardInfo {
  miniThumbnail: string;
  title: string;
  totalCount: number;
  progress: number;
  type: string;
  languages: { description: string; locale: string; seq: 0; title: string }[];
  courseId: string;
  journeyId: string | null;
  description: string;
  selectedLanguage: string;
  shareId: string;
  finishedLessonsCount: number;
}
const useLMSAnalyticsCallback = (cardInfo: ICardInfo) => {
  const lessons: any =
    useRecoilValue<{ [key: string]: ILessonDetails[] }>(lessonDetailsAtom);
  const progressLMS = useRecoilValue<ILMSProgressCourse>(
    progressLMSOfCourseShareSelector(cardInfo?.courseId)
  );
  const progressBeforeResetLMS = useRecoilValue<ILMSProgressCourse>(
    getProgressBeforeResetAtom
  );
  const totalLessons =
    Object.values(lessons || {}).sort((a: any, b: any) => a.seq - b.seq)
      ?.length || 0;

  const progressLMSState = useMemo(() => {
    return Object.keys(progressLMS || {})?.length === 0
      ? progressBeforeResetLMS
      : progressLMS;
  }, [progressLMS, progressBeforeResetLMS]);

  const isCourseCompleted = useCallback(
    (_courseId: string, isLJ: boolean = false) => {
      if (!progressLMSState || isLJ) {
        return false;
      }
      const shareId =
        Object.keys(progressLMSState || {})?.length &&
        Object.keys(progressLMSState)?.[0];
      if (progressLMSState?.hasOwnProperty('consumedAt')) {
        return true;
      }
      const lessonProgress = get(progressLMSState, [shareId], {});
      const consumedLessons =
        Object.values(lessonProgress || {})?.filter((item: any) => {
          if (typeof item === 'object') {
            return item?.hasOwnProperty('consumedAt');
          }
          return 0;
        })?.length || 0;
      if (totalLessons === consumedLessons && totalLessons > 0) {
        return true;
      } else {
        return false;
      }
    },
    [progressLMSState, totalLessons]
  );

  const progressLMSReport = useCallback(
    (isLJ: boolean, _courseId: string) => {
      if (!progressLMSState || isLJ) {
        return {
          hasCourseProgress: false,
          totalCardsConsumed: 0,
        };
      }
      let shareId = cardInfo?.shareId;
      if (isLJ) {
        shareId = Object.keys(progressLMSState || {})?.[0];
      }
      const progress = get(
        progressLMSState,
        isLJ ? [shareId, _courseId] : [shareId],
        {}
      );
      const cardProgressCount = Object.values(progress || {})?.map((i: any) => {
        if (typeof i === 'object') {
          return (
            Object.values(i?.cardProgress || {})?.filter((lesson: any) => {
              if (typeof lesson === 'object') {
                return lesson?.hasOwnProperty('consumedAt');
              }
              return 0;
            })?.length || 0
          );
        }
        return 0;
      });
      const totalCardsConsumed = cardProgressCount?.reduce(function (a, b) {
        return a + b;
      }, 0);
      const hasCourseProgress = Object.keys(progress || {})?.length > 0;
      return {
        hasCourseProgress,
        totalCardsConsumed,
        progress,
      };
    },
    [cardInfo?.shareId, progressLMSState]
  );

  const totalCourseCards = useMemo(() => {
    return Object.keys(lessons).reduce((acc: number, lessonId: any) => {
      return acc + Object.keys(lessons[lessonId]?.cards ?? {}).length;
    }, 0);
  }, [lessons]);

  const getTotalCards = (course: any) => {
    let totalCards = 0;
    const data = Object.values(course)?.map((i: any) => {
      return Object.keys(i?.cards)?.length || 0;
    });
    if (data?.length > 0) {
      totalCards = data?.reduce(function (a, b) {
        return a + b;
      }, 0);
    }
    return totalCards;
  };

  return {
    progressLMS: progressLMSReport,
    getTotalCards,
    isCourseCompleted,
    totalCourseCards,
  };
};

export default useLMSAnalyticsCallback;
