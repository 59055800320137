import {
  TaskFeedItem,
  TransformedTaskItem,
  prepareTaskFeed,
} from '@know/transformers';
import { selector, atom } from '@know/ui';

export const taskFeedFilterTextAtom = atom<string | null>({
  key: 'taskFeedFilterTextAtom',
  default: null,
});

export const taskFeedAtom = atom<Record<string, TaskFeedItem>>({
  key: 'taskFeedAtom',
  default: {},
});

export const taskFeedItemsSelector = selector<TransformedTaskItem[]>({
  key: 'taskFeedItemsSelector',
  get: ({ get }) => {
    const taskFeed = get(taskFeedAtom);
    const filterText = get(taskFeedFilterTextAtom);

    return prepareTaskFeed(taskFeed, filterText, 'deadline', 'asc');
  },
});
