import { Edited, UnSavedEdits } from '@know/constants';
import { ISentResponseItem } from '@know/transformers';
import moment from 'moment';
import { useMemo } from 'react';

export function useSentFormItemData(item: ISentResponseItem, _section: any) {
  const statusLabel = useMemo(() => {
    const status = item.status?.toLowerCase();
    if (
      status === 'accepted' ||
      status === 'submitted' ||
      status === 'approved'
    ) {
      return status === 'submitted' ? 'Submitted' : 'Approved';
    } else if (status === 'suspended' || status === 'suspend') {
      return 'Suspended';
    } else {
      return status || '';
    }
  }, [item.status]);

  const byLineData = useMemo(() => {
    const status = item.status?.toLowerCase();
    if (status === 'pending') {
      const receiverName = item.receiverName;
      if (receiverName) {
        return ['With', receiverName];
      }
      return ['', ''];
    } else if (status) {
      const senderName = item.senderName;
      if (senderName) {
        return ['By', senderName];
      }
      return ['', ''];
    }
    return ['', ''];
  }, [item.receiverName, item.senderName, item.status]);

  const editedText = useMemo(() => {
    const editStatus = item.editStatus;
    const isEdited = item.isEdited || false;

    if (editStatus === 'editing') {
      return `[${UnSavedEdits}]`;
    }
    if (editStatus === 'edited') {
      return `[${Edited}]`;
    }
    if (!editStatus && isEdited) {
      return `[${Edited}]`;
    }
    return '';
  }, [item.editStatus, item.isEdited]);

  const displayText = useMemo(() => {
    return (
      item.sno || `${moment(item.createdAt).format('DD/MM/YYYY')} Form response`
    );
  }, [item.sno, item.createdAt]);

  return {
    isWorkflow: Boolean(item.status),
    editedText,
    displayText,
    status: statusLabel,
    byText: byLineData[0],
    userName: byLineData[1],
    time: item.createdAt,
  };
}
