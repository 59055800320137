//TODO: make a native app friendly version
import React, { useEffect, useRef } from 'react';

interface WebViewProps {
  url: string;
  embedded?: boolean;
  onLoad?: (iframe: HTMLIFrameElement | null) => void;
  onMessage?: (e: MessageEvent) => void;
}

export const WebView = ({ onMessage, onLoad, url, embedded }: WebViewProps) => {
  const ref = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (onMessage) {
      const _onMessage = (e: MessageEvent) => {
        console.log(e);
        onMessage?.(e);
      };
      window.addEventListener('message', _onMessage);

      return () => window.removeEventListener('message', _onMessage);
    }
  }, [onMessage]);

  const _onLoad = () => {
    const iframe = ref.current;
    if (!iframe) return;
    const document = iframe.contentWindow?.document;

    const window = iframe.contentWindow?.window as any;
    if (window) {
      window.isWebapp = true;
    }

    if (!document) return;

    const head = document.head;
    const script = document.createElement('script');
    script.innerText = 'window.isWebapp = true';
    script.type = 'text/javascript';
    head.appendChild(script);

    setTimeout(() => {
      onLoad?.(ref.current);
    }, 100);
  };

  return (
    <iframe
      width="calc(100% - 10px)"
      height="100%"
      ref={(_ref) => {
        ref.current = _ref;
      }}
      src={url}
      onLoad={_onLoad}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ border: 0, borderRadius: embedded ? 12 : undefined }}
    />
  );
};
