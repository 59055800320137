import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Divider,
  DrawerToggleButton,
  KNOWAppDownloadView,
  createSideBarNavigator,
  useNavigation,
  useSetRecoilState,
} from '@know/ui';
import { TasksFeedPage } from './TasksFeedPage';
import { CompletedTasksPage } from './CompletedTasksPage';
import { taskLastOpenTabAtom, taskRoutesAtom } from './state';

const TasksSideBarNavigator = createSideBarNavigator();

export const linking = {
  initialRouteName: 'task-feed',
  screens: {
    'task-feed': {
      path: 'tasks/list/feed',
    },
    'task-completed': {
      path: 'tasks/list/completed',
    },
  },
};

export const TasksListNavigator = () => {
  const navigation: any = useNavigation();
  const setTaskLastOpenTabAtom = useSetRecoilState(taskLastOpenTabAtom);

  React.useLayoutEffect(() => {
    const headerLeft = () => (
      <DrawerToggleButton onPress={() => navigation.toggleDrawer()} />
    );

    navigation.setOptions({
      headerLeft,
    });
  }, [navigation]);

  const setTaskRoutes = useSetRecoilState(taskRoutesAtom);

  const items = useMemo<any>(() => {
    return [
      {
        name: 'task-feed',
        options: {
          title: 'To Do',
          drawerLabel: 'To Do',
        },
        component: TasksFeedPage,
      },
      {
        name: 'task-completed',
        options: {
          title: 'Completed Tasks',
          drawerLabel: 'Completed',
        },
        component: CompletedTasksPage,
      },
    ];
  }, []);

  useEffect(() => {
    setTaskRoutes(items.map(({ name }: { name: string }) => name));
  }, [items, setTaskRoutes]);

  const module = 'Tasks';

  const setTaskLastOpenTab = useCallback(
    (route) => {
      setTaskLastOpenTabAtom(route?.name ?? items[0].name);
    },
    [items, setTaskLastOpenTabAtom]
  );

  return (
    <React.Fragment>
      <KNOWAppDownloadView />
      <Divider />
      <TasksSideBarNavigator
        module={module}
        items={items}
        isResponsive={true}
        onRouteChange={setTaskLastOpenTab}
      />
    </React.Fragment>
  );
};
