import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  KNOWDBContext,
  Tabs,
  useSetRecoilState,
  useIsFocused,
  useRecoilState,
  useRouterContext,
} from '@know/ui';

import { CompletedCoursesPage } from './CompletedCoursesListPage';
import { MyCoursesPage } from './MyCoursesPage';
import {
  currentCourseTabAtom,
  getProgressBeforeResetAtom,
  lmsReportsPayloadAtom,
  userCompletedCoursesFeedAtom,
  userCoursesFeedAtom,
} from './state';
import { ILMSProgressCourse, IUserFeed } from './types';
import { CourseLibrary } from './CourseLibrary';

export const linking = {
  initialRouteName: 'my-courses',
  screens: {
    'course-library': {
      path: 'lms/list/course-library',
    },
    'my-courses': {
      path: 'lms/list/my-courses',
    },
    'completed-courses': {
      path: 'lms/list/completed-courses',
    },
  },
};

export const ListPageNavigator = (props: any) => {
  const navigationState = props.navigation.getState();
  const { currentDBApi } = useContext(KNOWDBContext);
  const currentState =
    Array.isArray(navigationState?.routes) && navigationState?.routes[0]?.state;
  const [currentCourseTab, setCurrentCourseTab] =
    useRecoilState(currentCourseTabAtom);
  const [reportsPayload, setReportsPayload] = useRecoilState(
    lmsReportsPayloadAtom
  );
  const setUserFeedObject = useSetRecoilState<{
    [key: string]: IUserFeed;
  }>(userCoursesFeedAtom);
  const setCompletedUserFeedObject = useSetRecoilState<{
    [key: string]: IUserFeed;
  }>(userCompletedCoursesFeedAtom);
  const isPageFocused = useIsFocused();
  const setProgressBeforeReset = useSetRecoilState<ILMSProgressCourse>(
    getProgressBeforeResetAtom
  );
  useEffect(() => {
    // 0 -> my-course tab/1 -> completed-course tab
    setCurrentCourseTab(
      (currentState?.routeNames ?? [])[currentState?.index] ??
        navigationState?.routes[0]?.state?.routes[currentState?.index ?? 0]
          ?.name
    );
  }, [
    currentState?.index,
    currentState?.routeNames,
    navigationState?.routes,
    setCurrentCourseTab,
  ]);

  // mycourses child_added/removed
  useEffect(() => {
    return currentDBApi?.listenForAddRemoveCourseFeedItems((data: any) => {
      const [courseId, feed]: [string, any] = data;
      setUserFeedObject((existingFeed: any) => {
        if (!feed) {
          return Object.fromEntries(
            Object.keys(existingFeed || {})
              .filter((cid) => cid !== courseId)
              .map((cid) => [cid, existingFeed[cid]])
          );
        } else {
          const updatedFeed: any = {
            ...existingFeed,
            [courseId]: feed,
          };
          handleUserReceivedEvent(updatedFeed);
          return updatedFeed;
        }
      });
    }, 'myCourseFeed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDBApi, setUserFeedObject]);

  // completedCourse child_added/removed
  useEffect(() => {
    return currentDBApi?.listenForAddRemoveCourseFeedItems((data: any) => {
      const [courseId, feed]: [string, any] = data;
      setCompletedUserFeedObject((existingFeed: any) => {
        if (!feed) {
          return Object.fromEntries(
            Object.keys(existingFeed || {})
              .filter((cid) => cid !== courseId)
              .map((cid) => [cid, existingFeed[cid]])
          );
        } else {
          return {
            ...existingFeed,
            [courseId]: feed,
          };
        }
      });
    }, 'completedCourseFeed');
  }, [currentDBApi, setCompletedUserFeedObject]);

  const handleUserReceivedEvent = async (_userFeed: IUserFeed[]) => {
    const payload = {
      dt: Date.now(),
      userFeed: _userFeed,
    };
    await currentDBApi?.setUserFeedCourseInfo('UserFeedReceived', payload);
  };

  const handleLMSReportsPayload = useCallback(async () => {
    if (Object.keys(reportsPayload)?.length) {
      for (const iterator in reportsPayload) {
        // @ts-ignore
        if (currentCourseTab !== 'completed-courses') {
          await currentDBApi?.setUserFeedCourseInfo('LMSAnalyticsCallback', {
            analyticsCBPayload: {
              ...reportsPayload[iterator],
              cardsCompletedInCurrentSessionIds: {},
              sessionEndTime: Date.now(),
            },
          });
        }
        setReportsPayload((prevState: any) => {
          const state = { ...prevState };
          delete state[iterator];
          return state;
        });
        setProgressBeforeReset({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsPayload]);

  useEffect(() => {
    if (isPageFocused) {
      const routeIndex = navigationState?.index;
      const currentRouteName =
        navigationState?.routes[routeIndex]?.name ?? 'no-name';
      if (currentRouteName === props.route.name) {
        handleLMSReportsPayload();
      }
    }
  }, [
    isPageFocused,
    navigationState,
    handleLMSReportsPayload,
    props.route.name,
  ]);

  const { hasAccessToModule } = useRouterContext();

  const tabsConfig = useMemo<any>(() => {
    const tabs = [
      hasAccessToModule('courseLibrary')
        ? {
            name: 'course-library',
            options: {
              title: 'Library',
            },
            component: CourseLibrary,
          }
        : null,
      {
        name: 'my-courses',
        options: {
          title: 'My Courses',
        },
        component: MyCoursesPage,
      },
      {
        name: 'completed-courses',
        options: {
          title: 'Completed',
        },
        component: CompletedCoursesPage,
      },
    ].filter(Boolean);
    return {
      tabs,
    };
  }, [hasAccessToModule]);

  return <Tabs {...tabsConfig} swipeEnabled={false} />;
};
