import { FirebaseAPI } from "../FirebaseAPI";
import { NuggetClassificationType } from "../types";
import { updateRecentContacts } from "./recentContacts";


export function shareNugget (dbAPI: FirebaseAPI, classificationType: NuggetClassificationType, nuggetId: string, authorId: string, _payload?: any) {
    const payload: any = {
      nuggetId,
      senderId: dbAPI.getLoggedInUserId(),
      organization: dbAPI.organization,
      createdAt: dbAPI.getServerTimestamp(),
      classificationType,
      authorId,
      ...(_payload ?? {})
    };

    if (classificationType === 'tasklist'){
      payload.type = 'issue'
    }

    const users: string[] = payload.users || [];
    updateRecentContacts(dbAPI, users);

    return dbAPI.pushToNode(
      dbAPI.getCommonPath("shareRequests"),
      payload
    );
}
