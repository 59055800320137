import { FirebaseAPI } from "..";
import { FormDraftsDataType } from "@know/transformers";

function getUserFormDraftsNodeRef(fbAPI: FirebaseAPI) {
  const userId = fbAPI.getLoggedInUserId();
  const path = fbAPI.getMultiPath(`forms/UserDrafts/${userId}`);
  return fbAPI.getNodeRef(path);
}

export async function getUserFormDraft(fbAPI: FirebaseAPI) {
  const nodeRef = getUserFormDraftsNodeRef(fbAPI);
  const drafts: FormDraftsDataType = (await fbAPI.getValue(nodeRef)) || {};
  return drafts;
}

export const listenToUserFormDrafts = (
  fbAPI: FirebaseAPI,
  cb: (drafts: FormDraftsDataType) => void
) => {
  const nodeRef = getUserFormDraftsNodeRef(fbAPI);

  return fbAPI.listenOnNodeRef(nodeRef, "value", (snap) => {
    const value = snap.val() as FormDraftsDataType;
    cb(value);
  });
};

export const isFormResponseDraft = async (
  fbAPI: FirebaseAPI,
  formId: string,
  responseId: string
) => {
  const path = `${fbAPI.getCommonPath(
    "currentUserFormDrafts"
  )}/${formId}/${responseId}`;

  const value = await fbAPI.getValue(fbAPI.getNodeRef(path));

  return !!value;
};
