import React, { useEffect } from 'react';
import { StackHeaderProps, View, SectionsListViewWithFilter } from '@know/ui';
import { useFormFolders } from './useFormFolders';
import {
  ItemComponent,
  getSectionHeaderComponent,
  keyExtractor,
} from '../NewFormsPage/renderers';

export function FolderItemsPage({ route, navigation }: StackHeaderProps) {
  //@ts-ignore
  const folderId = route?.params?.folderId!;
  const { folderTitle, folderFormSectionItems, onToggleSection, onClickItem } =
    useFormFolders(folderId);
  useEffect(
    () => navigation.setOptions({ title: folderTitle }),
    [folderId, folderTitle, navigation]
  );

  return (
    <View h={'100%'} pr={2} pl={5} bgColor={'white'}>
      <SectionsListViewWithFilter
        hasFilter={false}
        data={folderFormSectionItems}
        keyExtractor={keyExtractor as any}
        ItemComponent={ItemComponent}
        getSectionHeaderComponent={(info) =>
          getSectionHeaderComponent(info, true, '20px')
        }
        onClickItem={onClickItem}
        onToggleSection={onToggleSection}
      />
    </View>
  );
}
