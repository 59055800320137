import React, { useContext } from 'react';
import {
  KNOWQRCode,
  KNOWRouterContenxt,
  IModuleProps,
  Center,
  KNOWDBContext,
  WithKNOWLoader,
  VStack,
  useBreakpointValue,
  QRCodeHelperText,
} from '@know/ui';

import { useAttendanceQRValue } from './useAttendanceQRValue';

const AttendanceQRPage = () => {
  const { userDetails, orgConfig } = useContext(KNOWDBContext);
  const { qrValue, remainingSecond, isError } = useAttendanceQRValue(
    userDetails,
    orgConfig
  );

  const qrCodeSize = useBreakpointValue({
    base: 200,
    md: 300,
    lg: 400,
  });

  return (
    <Center flex={1} width={{ base: '100%' }} bg="white">
      {isError ? null : (
        <WithKNOWLoader isLoading={!qrValue} size="lg">
          <VStack space={4} alignItems="center">
            <KNOWQRCode size={qrCodeSize} withLogo value={qrValue} />
            {/* <Progress
              size="xs"
              value={progressValue}
              colorScheme={progressValue < 80 ? 'primary' : 'warning'}
            /> */}
            <QRCodeHelperText remainingSeconds={remainingSecond} />
          </VStack>
        </WithKNOWLoader>
      )}
    </Center>
  );
};

export const AttendanceQRScreen = () => {
  const { registerScreen } = React.useContext(KNOWRouterContenxt);

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'attendanceQR',
      name: 'Location QR',
      priority: 2,
      component: AttendanceQRPage,
      icon: 'qrcode',
    };
    registerScreen(module);
  }, [registerScreen]);

  return null;
};
