import { DataSnapshot } from "firebase/database";
import { FirebaseAPI } from "..";

export function listenToSentIssueNugget(
  fbAPI: FirebaseAPI,
  _nuggetId: string,
  cb: (data: any | null) => any
) {
  const pathRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("nuggets")),
    `tasklist`
  );

  const handler = (snap: DataSnapshot) => {
    const taskNugget = snap.val() ?? {};
    cb(taskNugget);
  };
  return fbAPI.listenOnNodeRef(pathRef, "value", handler);
}

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: {
  [id: string]: () => void;
} = {};

export function subscribeToUserSentIssuesFeed(
  fbAPI: FirebaseAPI,
  cb: (nugId: string, feedItem: any | null) => void
) {
  const userFeedRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("nuggets")),
    "tasklist"
  );

  const orderByQueryRef = fbAPI.queryOrderByChild(userFeedRef, "author");
  const userSentIssuesFeed = fbAPI.queryValue(
    orderByQueryRef,
    "equalTo",
    fbAPI.getLoggedInUserId()
  );

  function listenerHandler(_listenerEvenType: string) {
    return async (userFeedSnapshot: DataSnapshot) => {
      const issueFeed: any = (await userFeedSnapshot.val()) || null;
      const id = userFeedSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        cleanups[id]?.();
        return cb(id, null);
      }
      if (issueFeed?.createdAt) {
        // const author = await fbAPI.getUserDetails(issueFeed.author);
        cb(id, {
          ...issueFeed,
          // author,
          nugget: issueFeed,
          id,
        });
      } else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userSentIssuesFeed, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userSentIssuesFeed, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userSentIssuesFeed, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribeToSentIssuesNuggets = () => {
  for (const c of Object.keys(cleanups)) {
    cleanups[c]?.();
  }
  cleanups = {};
};
