import React from 'react';
import {
  View,
  Text,
  Image,
  Progress,
  useBreakpointValue,
  Badge,
} from '@know/ui';
import { Ionicons } from '@expo/vector-icons';
import { StyleSheet } from 'react-native';
import { LockLessonTooltip } from './LessonTooltip';
import { LockLesson } from './LockLesson';

export const LessonList: React.FC<any> = ({
  lesson,
  completed,
  index,
  progress,
  showLockTooltipIndex,
  type,
}) => {
  const id = lesson.id;
  const image = lesson.miniThumbnail;
  const lessonTitle = lesson.title;
  const isLessonLock = lesson?.showLock ?? false;

  const imageSize = useBreakpointValue({
    'base': { height: '78px', width: '130px' },
    'sm': { height: '78px', width: '130px' },
    'md': { height: '108px', width: '180px' },
    'lg': { height: '108px', width: '180px' },
    'xl': { height: '108px', width: '180px' },
    '2xl': { height: '108px', width: '180px' },
  });

  return (
    <View mb="12px" flexDir="row" flexWrap="wrap" alignItems="center" key={id}>
      {type === 'journey' && (
        <View
          position="absolute"
          left="-20px"
          w="16px"
          h="16px"
          bgColor={completed ? 'gray.300' : 'primary.500'}
          borderRadius="full"
          borderWidth="1.5px"
          borderColor="white"
          shadow={1}
        />
      )}

      {isLessonLock && (
        <View
          style={StyleSheet.absoluteFill}
          bgColor="white"
          opacity={0.5}
          position="absolute"
          zIndex={2}
        />
      )}
      {isLessonLock && index === showLockTooltipIndex && <LockLessonTooltip />}
      <View
        borderColor="secondary.500"
        borderWidth="1"
        borderRadius="10"
        overflow="hidden"
      >
        <View>
          <Image
            style={imageSize}
            resizeMode="cover"
            source={{
              uri: image,
            }}
            blurRadius={isLessonLock ? 1 : 0}
            alt={lessonTitle}
          />
          {isLessonLock && <LockLesson />}
        </View>
      </View>
      <View flex={1} alignItems="flex-start" pl="10px" lineHeight="20px">
        <Text fontSize="14px" color="gray.400">
          {type === 'journey' ? `Courses ${index + 1}` : `Lesson ${index + 1}`}
        </Text>
        <View flexDirection="row" alignItems="center">
          <Text
            marginRight="8px"
            fontWeight="600"
            fontSize="16px"
            color="gray.900"
          >
            {lessonTitle}
          </Text>
          {completed && (
            <Ionicons name="checkmark-circle" size={16} color="#00C1B2" />
          )}
        </View>
        {completed ? (
          <Badge
            backgroundColor="rgba(0, 193, 178, 0.15)"
            rounded="full"
            colorScheme="primary.400"
            marginTop="6px"
            _text={{
              fontSize: 14,
              fontWeight: 600,
              paddingRight: '12px',
              paddingLeft: '12px',
            }}
          >
            Completed
          </Badge>
        ) : (
          <View
            flexDirection="row"
            alignItems="center"
            w="100%"
            justifyContent="space-between"
            flex={1}
          >
            <View flexGrow={1}>
              <Progress
                w={'100%'}
                size="1.5"
                value={progress}
                bg="secondary.25"
                _filledTrack={{
                  bg: 'primary.500',
                }}
              />
            </View>

            <Text marginLeft="12px" color="black">
              {progress}%
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
