import { DataSnapshot } from "firebase/database";
import { FirebaseAPI } from "..";
import { IFormFeedItem } from "../types";
import { getUserFormsFeedPath, raiseAnalytics } from "./analytics";

let cleanups: (() => void)[] = [];

function getUserFormsFeedRef(fbAPI: FirebaseAPI) {
  const path = getUserFormsFeedPath(fbAPI);
  return fbAPI.getNodeRef(path);
}

function getFormNuggetCategories(
  fbAPI: FirebaseAPI,
  nuggetId: string,
  cb: (nuggetCategories: Record<string, boolean>) => void
) {
  const path = fbAPI.getMultiPath(`forms/nuggetCategories/${nuggetId}`);
  return fbAPI.listenOnNodeRef(fbAPI.getNodeRef(path), "value", (snap) => {
    cb(snap.val());
  });
}

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

export function subscribeToUserForms(
  fbAPI: FirebaseAPI,
  cb: (feedId: string, feedItem: IFormFeedItem | null) => void
) {
  const userFeedRef = getUserFormsFeedRef(fbAPI);
  const orderByQueryRef = fbAPI.queryOrderByChild(userFeedRef, "createdAt");

  function listenerHandler(listenerEvenType: string) {
    return async (feedItemSnapShot: DataSnapshot) => {
      const feed: IFormFeedItem | null = (await feedItemSnapShot.val()) || null;
      const id = feedItemSnapShot.key;
      if (!id) {
        return;
      }
      if (listenerEvenType === typeCR) {
        return cb(id, null);
      } else if (feed && id && feed.isShared === true) {
        if (!feed.isReceived && listenerEvenType === typeCA) {
          // Raise received analytics...
          raiseAnalytics({
            fbAPI,
            type: "received",
            formItem: { ...feed, id },
          });
        }

        cleanups.push(
          getFormNuggetCategories(fbAPI, id, (nuggetCategories) => {
            const folderIds = Object.keys(nuggetCategories || {}).filter(
              (categoryId) => nuggetCategories[categoryId]
            );
            cb(id, { ...feed, id, folderIds });
          })
        );

        return;
      } else if (id) {
        return cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(orderByQueryRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(orderByQueryRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(orderByQueryRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const getFormNugget = (fbAPI: FirebaseAPI, formId: string): any => {
  const formNuggetRef = fbAPI.getNodeRef(fbAPI.getCommonPath("formNugget"));

  return fbAPI.getValue(fbAPI.getChild(formNuggetRef, formId));
};

export const cleanUpSubscribedFormsCategories = () => {
  for (const c of cleanups) {
    c();
  }
  cleanups = [];
};
