import React from 'react';
import { Text, useBreakpointValue } from '@know/ui';
import { Trans } from '@know/i18n';

export function QRCodeHelperText({
  remainingSeconds,
}: {
  remainingSeconds: number;
}) {
  const textSize = useBreakpointValue({
    base: 18,
    md: 20,
    lg: 22,
  });

  return (
    <Text fontSize={textSize}>
      <Trans
        i18nKey={'QRCodeExpireText'}
        count={remainingSeconds}
        ns="messages"
      >
        QR Code expires in
        <Text
          py={'sm'}
          color={remainingSeconds > 10 ? 'primary.500' : 'warning.500'}
          fontWeight={600}
          fontSize={textSize}
        >
          {{ remainingSeconds }}
        </Text>
        seconds
      </Trans>
    </Text>
  );
}
