import React from 'react';
import { KNOWModal } from '../../../Modals';
import { View, Text, Button } from '@know/ui';

const Modal = ({
  showModal,
  handleToggleModal,
  title = '',
  description = '',
}: {
  showModal: boolean;
  handleToggleModal: () => void;
  title: string;
  description: string;
}) => {
  return (
    <KNOWModal
      size="md"
      modalVisible={showModal}
      backdropVisible={true}
      backDropCss={{ opacity: 0.7 }}
      handleToggleModal={handleToggleModal}
      bgColor={'white'}
      marginTop="50px"
      borderRadius={8}
    >
      <View paddingX="4px" paddingTop="8px" paddingBottom="4px">
        <Text fontSize="18px" fontWeight="semibold">
          {title}
        </Text>
        <Text
          color="gray.400"
          fontWeight={'normal'}
          fontSize="14px"
          marginTop="4px"
        >
          {description}
        </Text>
        <Button
          marginTop="12px"
          size={'sm'}
          colorScheme={'primary'}
          onPress={handleToggleModal}
        >
          <Text fontWeight={'semibold'} color={'white'}>
            OK
          </Text>
        </Button>
      </View>
    </KNOWModal>
  );
};

export default Modal;
