import { Platform } from 'react-native';
export const openPDFFromHTML = (htmlContent: string) => {
  if (Platform.OS === 'web') {
    const win = window.open('', '_blank', '');
    if (win) {
      win.document.open();

      win.document.write(htmlContent);

      const script1 = document.createElement('script');
      script1.text =
        'function loadHandler(){window.print()}function waitForImages(){var e=[].slice.call(document.images);console.log(e),e.filter((function(e){return console.log(e.complete),!e.complete})).length?setTimeout((function(){waitForImages()}),100):loadHandler()}function showThumbnail(e){var t=document.createElement("canvas");t.width=200,t.height=t.width*(e.videoHeight/e.videoWidth),t.getContext("2d").drawImage(e,0,0,t.width,t.height),e.style.display="none",e.parentElement.appendChild(t)}var videoElements=Array.from(document.querySelectorAll("video"));function waitForVideos(){console.log(videoElements),videoElements.reduce((function(e,t){return e+=4===t.readyState?1:0}),0)===videoElements.length?(videoElements.map(showThumbnail),waitForImages()):setTimeout(waitForVideos,100)}';
      win.document.head.appendChild(script1);

      const script3 = document.createElement('script');
      script3.text =
        'function waitForContent(){setTimeout(function(){waitForMaps()}, 500)};waitForContent()';
      win.document.head.appendChild(script3);

      const script4 = document.createElement('script');
      script4.text =
        'function initMapTimeout(){ setTimeout(function (){ initMap()}, 500)}';
      win.document.head.appendChild(script4);

      const mapScript = document.createElement('script');
      mapScript.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyC3AFp66yHrTS5OzC1KogV88iklAAcYF5Q&callback=initMapTimeout';
      win.document.head.appendChild(mapScript);

      const scrollScript = document.createElement('script');
      scrollScript.text =
        "function onLoadScroll(){document.body.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}";
      win.document.head.appendChild(scrollScript);

      win.document.close();
    }
  }
};
