import React from 'react';

import { Text } from './';

export interface ICollapsibleSectionHeaderProps {
  id: string;
  title: string;
  hasCustomPadding?: boolean;
  customPadding?: string;
}

export function CollapsibleSectionHeader({
  title,
  hasCustomPadding = false,
  customPadding,
}: ICollapsibleSectionHeaderProps) {
  return (
    <Text
      paddingLeft={hasCustomPadding ? customPadding : '0'}
      color={'gray.950'}
      fontFamily={'heading'}
      fontWeight={'bolder'}
      fontSize={'md'}
      display={'flex'}
      alignItems={'center'}
    >
      {title}
    </Text>
  );
}
