import React from 'react';
import {
  Button,
  ScrollView,
  Text,
  View,
  FlatList,
  Pressable,
  useBreakpointValue,
  KNOWModal,
  IKNOWModal,
} from '@know/ui';

import { StyleSheet, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMediaQuery } from 'native-base';

const styles = StyleSheet.create({
  scrollView: {
    paddingTop: '30px',
  },
  contentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: '4px',
  },
});

interface ILanguageSelectorView {
  title: string;
  description: string;
  submitBtnTitle: string;
  data: any[];
  handleToggleModal: (isAPICall: boolean) => void;
  handleChangeLanguage: (name: string) => void;
  selectedLanguage: string | undefined;
}

const LanguageSelectorView: React.FC<ILanguageSelectorView> = ({
  title,
  description,
  submitBtnTitle,
  data,
  handleToggleModal,
  handleChangeLanguage,
  selectedLanguage,
}) => {
  const [media] = useMediaQuery({ maxWidth: 320 });
  const [baseWidth] = useMediaQuery({ maxWidth: 345 });
  const columnCount = useBreakpointValue({
    base: baseWidth ? '1' : '2',
    sm: '2',
    md: '3',
    lg: '3',
    xl: '5',
  });
  const proceedBtnWdth = useBreakpointValue({
    base: '100%',
    sm: '150px',
    md: '150px',
    lg: '150px',
    xl: '150px',
  });

  const totalWidth = Dimensions.get('window').width;

  const viewMinWidth = useBreakpointValue({
    base: baseWidth ? totalWidth - 20 : totalWidth / 2 - 20,
    sm: '150px',
    md: '200px',
    lg: '200px',
    xl: '200px',
  });
  const flatListStyle = baseWidth
    ? { marginVertical: '12px' }
    : { marginVertical: '32px' };

  return (
    <React.Fragment>
      <ScrollView style={styles.scrollView}>
        <Text
          fontSize={['20px', '20px', '24px', '24px', '24px']}
          fontWeight="semibold"
          textAlign="center"
          paddingX="12px"
        >
          {title}
        </Text>
        <View paddingX="12px">
          <Text
            fontSize={media ? '14px' : '16px'}
            color="black.100"
            fontWeight="400"
            textAlign="center"
          >
            {description}
          </Text>
        </View>

        <FlatList
          key={columnCount}
          style={flatListStyle}
          scrollEnabled={false}
          numColumns={columnCount}
          keyExtractor={(item) => '_' + item.locale}
          contentContainerStyle={styles.contentContainerStyle}
          data={data}
          renderItem={({ item }) => (
            <Pressable
              marginX="8px"
              marginY="8px"
              onPress={() => handleChangeLanguage(item.value)}
              minWidth={viewMinWidth}
            >
              <View
                borderColor={
                  selectedLanguage === item.value
                    ? 'primary.500'
                    : 'secondary.50'
                }
                borderWidth={2}
                overflow="hidden"
                borderRadius={10}
              >
                <View padding={baseWidth ? '8px' : '12px'}>
                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontWeight="600" fontSize="15px" color="black">
                      {item.locale}
                    </Text>
                    {selectedLanguage === item.value && (
                      <Ionicons
                        name="checkmark-circle"
                        size={20}
                        color="#00C1B2"
                      />
                    )}
                  </View>
                  <Text fontSize="15px" color="secondary.400">
                    {item.name}
                  </Text>
                </View>
              </View>
            </Pressable>
          )}
        />
      </ScrollView>
      <View alignItems={'center'} paddingX="12px">
        <Button
          minW={'150px'}
          w={proceedBtnWdth}
          bgColor={selectedLanguage ? 'primary.500' : 'gray.300'}
          onPress={() => (selectedLanguage ? handleToggleModal(true) : null)}
        >
          <Text
            fontWeight="semibold"
            fontSize="16px"
            paddingY="4px"
            color="white"
            paddingX="24px"
          >
            {submitBtnTitle}
          </Text>
        </Button>
      </View>
    </React.Fragment>
  );
};

export const LanguageSelectorModal: React.FC<
  Pick<IKNOWModal, 'modalVisible' | 'handleToggleModal'> & {
    data: any[];
    selectedLanguage: string | undefined;
    handleChangeLanguage: (name: string) => void;
  }
> = ({
  modalVisible,
  handleToggleModal,
  data,
  handleChangeLanguage,
  selectedLanguage,
}) => {
  const modalStyle = { margin: 0, padding: 0 };

  return (
    <KNOWModal
      size="full"
      modalVisible={modalVisible}
      backdropVisible={false}
      handleToggleModal={handleToggleModal}
      minHeight="100%"
      bgColor={'white'}
      marginTop="0"
      borderRadius={0}
      styles={modalStyle}
      bodyStyle={modalStyle}
    >
      <LanguageSelectorView
        title="Select Language"
        description="Please select a language that you can read and understand comfortably."
        submitBtnTitle="Proceed"
        handleToggleModal={handleToggleModal}
        data={data}
        handleChangeLanguage={handleChangeLanguage}
        selectedLanguage={selectedLanguage}
      />
    </KNOWModal>
  );
};
