import { useCallback, useEffect, useState } from 'react';
import {
  useNavigation,
  useRecoilValue,
  CommonActions,
  StackActions,
} from '@know/ui';
import {
  getProgressBeforeResetAtom,
  journeyCoursePayloadAtom,
  lessonDetailsAtom,
  progressLMSOfCourseShareSelector,
} from '../state';
import type { ILessonDetails, ILMSProgressCourse } from '../types';
import { get } from 'lodash';
import { useCourseFeed } from '../MyCoursesPage/useCourseFeed';

export const useLessonDetails = (
  courseId: string,
  lessonId: string,
  _cardId?: string,
  journeyId?: string
) => {
  const navigation = useNavigation();
  const lessons: any =
    useRecoilValue<{ [key: string]: ILessonDetails[] }>(lessonDetailsAtom);
  const lesson = lessons[lessonId];
  const [hideNextLessonBtn, setHideNextLessonBtn] = useState<boolean>(false);
  const journeyCoursePayload = useRecoilValue(journeyCoursePayloadAtom);
  const progressLMS = useRecoilValue<ILMSProgressCourse>(
    progressLMSOfCourseShareSelector(journeyId || courseId)
  );
  const progressBeforeResetLMS = useRecoilValue<ILMSProgressCourse>(
    getProgressBeforeResetAtom
  );
  const sortedCards = Object.values(lesson?.cards).sort(
    (a: any, b: any) => a.seq - b.seq
  );
  const sortedLesson = Object.values(lessons).sort(
    (a: any, b: any) => a.seq - b.seq
  );

  const { cardInfo } = useCourseFeed(journeyId || courseId);

  const handleNextLesson = async (replace = false) => {
    let nextLesson: any = {};
    sortedLesson?.forEach((ln: any, index: number) => {
      if (ln?.id === lessonId) {
        if (index !== sortedLesson?.length - 1) {
          nextLesson = sortedLesson[index + 1];
        }
      }
    });
    const nextCard: any = Object.values(nextLesson?.cards).find(
      (card: any) => card.seq === 0
    );

    const routeName = 'card' as never;
    const routePayload = {
      courseId,
      lessonId: nextLesson?.id,
      cardId: nextCard?.id,
      journeyId,
    } as never;

    if (replace) {
      return navigation.dispatch(StackActions.replace(routeName, routePayload));
    }
    navigation.navigate(routeName, routePayload);
  };

  const isJourneyCompleted = useCallback(() => {
    const totalCourses = Object.keys(journeyCoursePayload)?.length || 0;
    const _courseProgress = get(
      Object.keys(progressLMS || {}).length > 0
        ? progressLMS
        : progressBeforeResetLMS,
      cardInfo?.shareId,
      {}
    );
    const consumedCourses =
      Object.values(_courseProgress).filter((course: any) =>
        course?.hasOwnProperty('consumedAt')
      )?.length || 0;
    if (totalCourses === consumedCourses && totalCourses > 0) {
      return true;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressBeforeResetLMS]);

  const courseDetailsNavigation = () => {
    navigation.navigate(
      'course-details' as never,
      {
        courseId,
        journeyId,
      } as never
    );
  };

  const handleCoursePreview = useCallback(async () => {
    if (hideNextLessonBtn || !cardInfo?.isSequence) {
      let resetAction;
      if (journeyId) {
        const shareId =
          Object.keys(progressLMS || {})?.[0] || cardInfo?.shareId;
        const totalCourses = Object.keys(journeyCoursePayload)?.length || 0;
        const _courseProgress = get(
          Object.keys(progressLMS || {}).length > 0
            ? progressLMS
            : progressBeforeResetLMS,
          shareId,
          {}
        );
        const consumedCourses =
          (Object.values(_courseProgress || {})?.length > 0 &&
            Object.values(_courseProgress || {})?.filter((course: any) =>
              course?.hasOwnProperty('consumedAt')
            )?.length) ||
          0;

        if (totalCourses > 0 && totalCourses === consumedCourses) {
          resetAction = CommonActions.reset({
            index: 0,
            routes: [
              {
                name: 'list',
                state: { routes: [{ name: 'completed-courses' }] },
              },
              {
                name: 'journey-details',
                params: {
                  journeyId,
                } as never,
              },
              {
                name: 'course-details',
                params: {
                  courseId,
                  journeyId,
                } as never,
              },
            ],
          });
          navigation.dispatch(resetAction);
        } else {
          courseDetailsNavigation();
        }
      } else {
        const shareId =
          Object.keys(progressBeforeResetLMS).filter(
            (a) => a !== 'consumedAt'
          )?.[0] || cardInfo?.shareId;
        const totalLessons = sortedLesson.length;
        const lessonProgress = get(progressBeforeResetLMS, shareId, {});
        const consumedLessons =
          (Object.values(lessonProgress || {}).length > 0 &&
            Object.values(lessonProgress || {}).filter((_lesson: any) =>
              _lesson?.hasOwnProperty('consumedAt')
            )?.length) ||
          0;
        if (totalLessons === consumedLessons && totalLessons > 0) {
          resetAction = CommonActions.reset({
            index: 0,
            routes: [
              {
                name: 'list',
                state: { routes: [{ name: 'completed-courses' }] },
              },
              {
                name: 'course-details',
                params: {
                  courseId,
                  journeyId,
                } as never,
              },
            ],
          });
          navigation.dispatch(resetAction);
        } else {
          courseDetailsNavigation();
        }
      }
    } else {
      courseDetailsNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressBeforeResetLMS, progressLMS]);

  useEffect(() => {
    const currentLessonIndex = sortedLesson.findIndex(
      (ln: any) => ln.id === lessonId
    );
    if (currentLessonIndex + 1 === sortedLesson.length) {
      setHideNextLessonBtn(true);
    }
  }, [lessonId, sortedLesson]);

  return {
    cards: sortedCards,
    title: lesson?.title,
    hideNextLessonBtn,
    handleNextLesson,
    handleCoursePreview,
    isJourneyCompleted,
  };
};
