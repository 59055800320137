import React from 'react';
import { createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const GalleryIcon = createIcon({
  viewBox: '0 0 32 32',
  path: [
    <Path
      d="M28 25.8333V7.16667C28 5.7 26.8 4.5 25.3333 4.5H6.66667C5.2 4.5 4 5.7 4 7.16667V25.8333C4 27.3 5.2 28.5 6.66667 28.5H25.3333C26.8 28.5 28 27.3 28 25.8333ZM11.3333 18.5L14.6667 22.5133L19.3333 16.5L25.3333 24.5H6.66667L11.3333 18.5Z"
      fill="#00C1B2"
    />,
  ],
});
