import type { FirebaseAPI } from "../FirebaseAPI"

export async function searchUsersRPC(dbAPI: FirebaseAPI, searchString: string) {
  const options = {
    include: ['avatar', 'hasPhone', 'location'],
    view: 'form_sent_next',
  }
  const response = await dbAPI.callRPCWithoutTimeout("RPC/search", {
    ...options,
    query: searchString,
    type: "users",
    isMobile: true,
  });

  return response.results ?? [];
}
