import React from 'react';
import { getDateParts } from './utils';
import moment from 'moment';
import { Text, Box } from '@know/ui';

export const TaskDeadline = ({
  deadline,
  isCompleted,
}: {
  deadline?: number;
  isCompleted?: boolean;
}) => {
  if (!deadline) {
    return (
      <Text fontFamily={'tableCell'} fontWeight={400} color={'gray.700'}>
        {''}
      </Text>
    );
  }

  const [day, time] = getDateParts(deadline);

  const today = +moment().startOf('day');
  const isPast = deadline < today;
  const isToday = today === +moment(deadline).startOf('day');

  return (
    <Box
      px={'6px'}
      py={'2px'}
      bg={
        isCompleted
          ? 'gray.200'
          : isPast
          ? 'red.400'
          : isToday
          ? 'orange.500'
          : 'gray.200'
      }
    >
      <Text
        fontFamily={'tableCell'}
        fontWeight={600}
        color={
          isCompleted ? 'gray.700' : isPast || isToday ? 'white' : 'gray.700'
        }
      >
        {day}
        {day === 'Yesterday' || day === 'Tomorrow' || day === 'Today'
          ? `, ${time}`
          : ''}
      </Text>
    </Box>
  );
};
