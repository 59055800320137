import { useState, useCallback, useContext, useEffect } from 'react';
import { KNOWDBContext, useRecoilState, useRecoilValue } from '@know/ui';

import {
  currentCourseTabAtom,
  languageModalAtom,
  userCompletedCourseFeedSelector,
  userCourseFeedSelector,
} from '../state';

export const useLanguageSwitch = (
  courseId: string,
  cardInfo: {
    hasDefaultLanguage: boolean;
    languages: string[];
    selectedLanguage: string;
    journeyId: string | null;
    shareId: string;
  } = {
    hasDefaultLanguage: false,
    languages: [],
    selectedLanguage: '',
    journeyId: null,
    shareId: '',
  }
) => {
  const { currentDBApi } = useContext(KNOWDBContext);

  const [modalVisible, setModalVisible] =
    useRecoilState<boolean>(languageModalAtom);

  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const [userFeed, setUserFeedObject] = useRecoilState(
    currentCourseTab === 'completed-courses'
      ? userCompletedCourseFeedSelector(courseId)
      : userCourseFeedSelector(courseId)
  );

  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    userFeed?.lang
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleModal = async (isAPICall: boolean) => {
    let isProgressReset = false;
    setIsLoading(true);
    const payload = {
      courseId,
      selectedLanguage,
      feedType:
        currentCourseTab === 'completed-courses' ? 'completedFeed' : 'userFeed',
      shareId: cardInfo.shareId,
    };
    if (currentDBApi && isAPICall && payload.feedType === 'userFeed') {
      await currentDBApi?.setUserFeedCourseInfo('SetLanguage', payload);
      //FIXME: need to add completed condition here
      if (userFeed?.lang !== selectedLanguage) {
        // journeyId and isLearningPath && !isLessonCompleted
        isProgressReset = true;
        await currentDBApi.resetCourseProgress({
          courseId,
          isLanguageSwitch: isProgressReset,
        });
      }
      setUserFeedObject((prevState: any) => ({
        ...prevState,
        lang: selectedLanguage,
        finishedCount: isProgressReset ? 0 : prevState.finishedCount,
      }));
    }
    setModalVisible((prevState: any) => !prevState);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!cardInfo.journeyId) {
      if (
        currentCourseTab !== 'completed-courses' &&
        Object.keys(userFeed || {})?.length > 0
      ) {
        if (!cardInfo?.hasDefaultLanguage && cardInfo?.languages.length > 1) {
          setSelectedLanguage(cardInfo?.selectedLanguage);
          setModalVisible(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo?.hasDefaultLanguage]);

  const handleChangeLanguage = useCallback(
    (value: string) => {
      setSelectedLanguage(value);
    },
    [setSelectedLanguage]
  );

  return {
    modalVisible,
    selectedLanguage,
    handleToggleModal,
    handleChangeLanguage,
    isLoading,
  };
};
