import React from 'react';
import {
  Modal,
  Progress,
  Box,
  UploadImageIcon,
  UploadSignatureIcon,
  Text,
  UploadVideoIcon,
  UploadFileIcon,
} from '@know/ui';
import type { UploadProgressType } from './types';

interface UploadProgressModalProps {
  isOpen: boolean;
  progress: number;
  type: UploadProgressType;
  numberOfFiles: number;
}

const getText = (type: UploadProgressType, numberOfFiles: number) => {
  switch (type) {
    case 'image':
      if (numberOfFiles > 1) {
        return `Uploading ${numberOfFiles} images`;
      }
      return 'Uploading image';
    case 'video':
      if (numberOfFiles > 1) {
        return `Uploading ${numberOfFiles} videos`;
      }
      return 'Uploading video';
    case 'file':
      if (numberOfFiles > 1) {
        return `Uploading ${numberOfFiles} files`;
      }
      return 'Uploading file';
    case 'signature':
      if (numberOfFiles > 1) {
        return `Uploading ${numberOfFiles} signatures`;
      }
      return 'Uploading signature';
    default:
      return null;
  }
};

const getIcon = (type: UploadProgressType) => {
  switch (type) {
    case 'image':
      return <UploadImageIcon size="48px" mr="8px" />;
    case 'video':
      return <UploadVideoIcon size="48px" mr="8px" />;
    case 'file':
      return <UploadFileIcon size="48px" mr="8px" />;
    case 'signature':
      return <UploadSignatureIcon size="48px" mr="8px" />;

    default:
      return null;
  }
};

export const UploadProgressModal = ({
  isOpen,
  progress,
  type,
  numberOfFiles,
}: UploadProgressModalProps) => {
  return (
    <Modal isOpen={isOpen} size="xl">
      <Modal.Content>
        <Modal.Body>
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {getIcon(type)}
            <Box display={'flex'} flexDir={'column'} h={'100%'} flex={1}>
              <Box
                display={'flex'}
                flexDir={'row'}
                justifyContent={'space-between'}
              >
                <Text fontWeight={700} fontSize={'18px'}>
                  {getText(type, numberOfFiles)}
                </Text>
                <Text fontSize={'16px'}>{progress}%</Text>
              </Box>
              <Progress
                value={progress}
                mt={'6px'}
                _filledTrack={{
                  bgColor: 'primary.500',
                  h: '8px',
                }}
              />
            </Box>
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
