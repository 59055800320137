import { atom, selector, selectorFamily, IANS } from '@know/ui';

import type {
  ICourseDetails,
  ILMSProgressCourse,
  IUserFeed,
  ICoursePayload,
  ILessonDetails,
  IJourneyCourses,
  CourseTabType,
} from './types';

export const userCoursesFeedAtom = atom<{ [key: string]: IUserFeed }>({
  key: 'users.courses.feeds',
  default: {},
});

export const userCompletedCoursesFeedAtom = atom<{ [key: string]: IUserFeed }>({
  key: 'users.completedCourses.feeds',
  default: {},
});

export const userCourseFeedSelector = selectorFamily<IUserFeed, string>({
  key: 'users.courses.feedItem',
  get:
    (courseId: string) =>
    ({ get }: any) => {
      const feedItems = get(userCoursesFeedAtom);
      return feedItems[courseId] || {};
    },
  set:
    (courseId: string) =>
    ({ set }, newValue) => {
      set(userCoursesFeedAtom, (prevValue: any) => {
        return {
          ...prevValue,
          [courseId]: newValue,
        };
      });
    },
});

export const userCompletedCourseFeedSelector = selectorFamily<
  IUserFeed,
  string
>({
  key: 'users.completedCourses.feedItem',
  get:
    (courseId: string) =>
    ({ get }) => {
      const feedItems = get(userCompletedCoursesFeedAtom);
      return feedItems[courseId] || {};
    },
  set:
    (courseId: string) =>
    ({ set }, newValue) => {
      set(userCompletedCoursesFeedAtom, (prevValue: any) => {
        return {
          ...prevValue,
          [courseId]: newValue,
        };
      });
    },
});

export const myCoursesSelector = selector<IUserFeed[]>({
  key: 'users.courses.list',
  get: ({ get }: any) => {
    const feed = get(userCoursesFeedAtom) as { [key: string]: IUserFeed };
    return Object.keys(feed)
      .map((courseId: string) => {
        return {
          courseId,
          ...feed[courseId],
        };
      })
      .filter(({ createdAt }) => createdAt)
      .sort((a, b) => b.createdAt - a.createdAt);
  },
});

export const completedCoursesSelector = selector<IUserFeed[]>({
  key: 'users.completedCourse.list',
  get: ({ get }: any) => {
    const feed = get(userCompletedCoursesFeedAtom) as {
      [key: string]: IUserFeed;
    };
    return Object.keys(feed)
      .map((courseId: string) => {
        return {
          courseId,
          ...feed[courseId],
        };
      })
      .filter(({ createdAt }) => createdAt)
      .sort((a, b) => b.createdAt - a.createdAt);
  },
});

export const progressLMSMapAtom = atom<{
  [courseShareId: string]: ILMSProgressCourse;
}>({
  key: 'users.courses.progressLMS',
  default: {},
});

export const progressQuizLMSMapAtom = atom<{
  [courseShareId: string]: ILMSProgressCourse;
}>({
  key: 'users.courses.progressQuizLMS',
  default: {},
});

export const progressLMSOfCourseShareSelector = selectorFamily<
  ILMSProgressCourse,
  string
>({
  key: 'users.courses.progressLMS.courseShare',
  get:
    (courseId: string) =>
    ({ get }) => {
      const key = `${courseId}`;
      const progressLMSMap = get(progressLMSMapAtom);
      return progressLMSMap[key] || {};
    },
  set:
    (courseId: string) =>
    ({ set }, newValue) => {
      const key = `${courseId}`;
      set(progressLMSMapAtom, (prevValue: any) => {
        return {
          ...(prevValue || {}),
          [key]: newValue,
        };
      });
    },
});

export const progressQuizLMSOfCourseShareSelector = selectorFamily<
  ILMSProgressCourse,
  string
>({
  key: 'users.courses.progressQuizLMS.courseShare',
  get:
    (courseId: string) =>
    ({ get }) => {
      const key = `${courseId}`;
      const progressQuizLMSMap = get(progressQuizLMSMapAtom);
      return progressQuizLMSMap[key] || {};
    },
  set:
    (courseId: string) =>
    ({ set }, newValue) => {
      const key = `${courseId}`;
      set(progressQuizLMSMapAtom, (prevValue: any) => {
        return {
          ...(prevValue || {}),
          [key]: newValue,
        };
      });
    },
});

const coursesDetailsAtom = atom<{ [courseId: string]: ICourseDetails }>({
  key: 'courses.details',
  default: {},
});

export const courseDetailsSelector = selectorFamily<ICourseDetails, string>({
  key: 'course.details',
  get:
    (courseId) =>
    ({ get }) => {
      const courses = get(coursesDetailsAtom);
      return courses[courseId] || {};
    },
  set:
    (courseId) =>
    ({ set }, newValue) => {
      set(coursesDetailsAtom, (prevValue: any) => {
        return {
          ...(prevValue || {}),
          [courseId]: {
            ...(prevValue[courseId] || {}),
            ...newValue,
          },
        };
      });
    },
});

const coursesPayloadAtom = atom<{ [courseId: string]: ICoursePayload }>({
  key: 'courses.payload',
  default: {},
});

export const coursePayloadSelector = selectorFamily<ICoursePayload, string>({
  key: 'course.payload',
  get:
    (courseId) =>
    ({ get }) => {
      const courses = get(coursesPayloadAtom);
      return courses[courseId] || {};
    },
  set:
    (courseId) =>
    ({ set }, newValue) => {
      set(coursesPayloadAtom, (prevValue: any) => {
        return {
          ...(prevValue || {}),
          [courseId]: {
            ...(prevValue[courseId] || {}),
            ...newValue,
          },
        };
      });
    },
});

export const languageModalAtom = atom<boolean>({
  key: 'modal.language.isOpen',
  default: false,
});
export const ljLanguageModalAtom = atom<boolean>({
  key: 'modal.ljlanguage.isOpen',
  default: false,
});

export const journeyLanguagesAtom = atom<{
  hasDefaultLanguage: boolean;
  languages: any[];
  selectedLanguage: string;
}>({
  key: 'journey.languages',
  default: {
    hasDefaultLanguage: false,
    languages: [],
    selectedLanguage: 'eng',
  },
});

export const lessonDetailsAtom = atom<{ [key: string]: ILessonDetails[] }>({
  key: 'course.lessons',
  default: {},
});

export const quizResultAtom = atom<IANS[]>({
  key: 'quiz.results',
  default: [],
});

export const sessionIdAtom = atom<string>({
  key: 'session.id',
  default: `${Date.now()}`,
});

export const currentCourseTabAtom = atom<CourseTabType>({
  key: 'course.type',
  default: 'my-courses',
});

export const scromProgressAtom = atom<{ [key: string]: any }>({
  key: 'courses.scrom.progress',
  default: {
    progress: {},
    isScromLoaded: false,
  },
});

export const isVideoAutoPlayedAtom = atom<boolean>({
  key: 'isAutoPlayVideo',
  default: false,
});

export const journeyCoursesAtom = atom<IJourneyCourses>({
  key: 'journey.courses',
  default: {},
});

export const journeyCoursesFeedItemsAtom = atom<{
  [courseId: string]: ICoursePayload;
}>({
  key: 'journey.courses.feedItems',
  default: {},
});

export const journeyCoursePayloadAtom = atom<any>({
  key: 'journey.course.feedItem',
  default: {},
});

export const lmsReportsPayloadAtom = atom<{
  [id: string]: any;
}>({
  key: 'lms.reports.payload',
  default: {},
});

export const getProgressBeforeResetAtom = atom<any>({
  key: 'users.courses.progressQuizLMSBeforeReset',
  default: {},
});

export const lastSeenCardIdAtom = atom<string>({
  key: 'users.courses.lastSeenCardIdAtom',
  default: '',
});

export const quizCardsAttemptsCountAtom = atom<{ [cardId: string]: number }>({
  key: 'users.courses.quizCardsAttemptsCount',
  default: {},
});

export const isLessonConsumedAtom = atom<boolean>({
  key: 'users.courses.isLessonConsumed',
  default: false,
});

export const isJourneyConsumedAtom = atom<boolean>({
  key: 'users.courses.isJourneyConsumed',
  default: false,
});
