//TODO: make a native app friendly version
import React, { useCallback, useEffect, useRef } from 'react';
import { KNOWButton as Button, Modal, View } from '@know/ui';
import SignatureCanvas from 'react-signature-canvas';
import { getFileFromBase64 } from './utils';

interface SignatureCanvasModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit?: (response: File | undefined) => void;
}

export const SignatureCanvasModal = ({
  isOpen,
  close,
  onSubmit,
}: SignatureCanvasModalProps) => {
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const canvasDataRef = useRef<SignaturePad.Point[][] | null>(null);

  const clearCanvas = useCallback(() => {
    canvasRef.current?.clear();
    canvasDataRef.current = null;
  }, []);

  const _onClose = () => {
    clearCanvas();
    close();
  };

  const _onSubmit = async () => {
    const trimmedCanvas = canvasRef.current?.getTrimmedCanvas();
    const response = trimmedCanvas?.toDataURL();
    if (response) {
      const file = await getFileFromBase64(
        response,
        'image/png',
        `signature-${Date.now()}.png`
      );
      onSubmit && onSubmit(file);
      _onClose();
    }
  };

  const resizeCanvas = useCallback(() => {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = canvasRef.current?.getCanvas();

    if (!canvas) return;

    // This part causes the canvas to be cleared
    setTimeout(() => {
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);

      const data = canvasDataRef.current;
      if (data) {
        canvasRef.current?.fromData(data);
      } else {
        clearCanvas();
      }
    });
  }, [clearCanvas]);

  useEffect(() => {
    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, [resizeCanvas]);

  useEffect(() => {
    if (isOpen) {
      resizeCanvas();
    }
  }, [isOpen, resizeCanvas]);

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={_onClose} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header pb={0} borderBottomWidth={0}>
          Signature Required
        </Modal.Header>
        <Modal.Body>
          <View display="block" width="100%" height="300px">
            <SignatureCanvas
              ref={canvasRef}
              penColor="black"
              canvasProps={{
                className: 'sigCanvas',
                style: {
                  border: '2px dashed #C4CAC9',
                  borderRadius: '8px',
                  width: '100%',
                  height: '100%',
                },
              }}
              onEnd={() => {
                canvasDataRef.current = canvasRef.current?.toData() ?? null;
              }}
            />
          </View>
        </Modal.Body>
        <Modal.Footer borderTopWidth={0}>
          <Button
            colorScheme="secondary"
            onPress={clearCanvas}
            flex={1}
            mr="10px"
          >
            Clear
          </Button>
          <Button colorScheme="primary" onPress={_onSubmit} flex={1}>
            Save
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
