import { extendTheme, v33xTheme } from 'native-base';

declare global {
  interface Window {
    theme?: any;
  }
}

const extendWith = (obj1: any, obj2: any) => {
  return Object.fromEntries(
    Object.keys(obj1).map((key: string) => {
      return [key, Object.assign(obj1[key], obj2[key] || {})];
    })
  );
};

export default extendTheme(
  extendWith(v33xTheme, {
    colors: {
      default: {
        50: '#d9fff7',
        100: '#adffec',
        200: '#7dffe4',
        300: '#4dffdf',
        400: '#26ffde',
        500: '#00C1B2',
        600: '#00b3a5',
        700: '#00806e',
        800: '#004e3d',
        900: '#09292f',
      },
      primary: {
        50: '#d9fff7',
        100: '#adffec',
        200: '#7dffe4',
        300: '#4dffdf',
        400: '#26ffde',
        500: '#00C1B2',
        600: '#00b3a5',
        700: '#00806e',
        800: '#0A3841',
        900: '#09292f',
      },
      green: {
        100: '#E3FCF1',
        400: '#0EBF75',
      },
      teal: {
        100: '#E3FCF1',
        400: '#0EBF75',
        700: '#104D58',
      },
      secondary: {
        25: '#e5e7eb',
        50: '#f8f0f3',
        100: '#d9d9d9',
        200: '#bfbfbf',
        300: '#a6a6a6',
        400: '#8c8c8c',
        500: '#E5E5E5',
        600: '#595959',
        700: '#404040',
        800: '#262626',
        900: '#120b11',
      },
      danger: {
        50: '#ffe3e6',
        100: '#ffb3b9',
        200: '#fc8388',
        300: '#f95353',
        400: '#f6222f',
        500: '#F95757',
        600: '#ac0627',
        700: '#7c0224',
        800: '#4c001a',
        900: '#1f000d',
      },
      gray: {
        100: '#f6f9fa',
        150: '#f8f8f8',
        200: '#EFF2F2',
        250: '#E5E9E8',
        300: '#e5e5e5',
        350: '#D9DDDD',
        400: '#B9B9B9',
        450: '#A4ACAB',
        500: '#9d9fad',
        550: '#C4CAC9',
        600: '#78807E',
        700: '#4E5656',
        750: '#51525A',
        800: '#9d9fad',
        900: '#151c2e',
        950: '#222C2A',
        1000: '#151C2E',
        1050: '#111514',
      },
      red: {
        100: '#FEEEEE',
        300: '#F95757',
        400: '#F95757',
        500: '#F95757',
        600: '#E90202',
        700: '#D82828',
        1000: '#AC0000',
      },
      purple: {
        400: '#7B61FF',
      },
      orange: {
        400: '#FFB546',
        500: '#FB9A08',
      },
      trasparent: {
        100: 'rgba(0, 0, 0, 0.0)',
        200: '#ffffff33',
        300: 'rgba(0, 0, 0, 0.4)',
      },
      navbar: {
        primary: '#0A3841',
        secondary: '#104D58',
        secondoryTransparent: 'rgba(32, 50, 72, 0)',
        primaryTransparent: 'rgba(32, 50, 72, 0.8)',
      },
      error: {
        100: '#F95757',
        200: '#F95757',
        300: '#F95757',
        400: '#F95757',
        500: '#F95757',
        600: '#F95757',
        700: '#F95757',
        800: '#F95757',
      },
    },
    fontConfig: {
      Inter: {
        100: {
          normal: 'Inter_100Thin',
        },
        200: {
          normal: 'Inter_200ExtraLight',
        },
        300: {
          normal: 'Inter_300Light',
        },
        400: {
          normal: 'Inter_400Regular',
        },
        500: {
          normal: 'Inter_500Medium',
        },
        600: {
          normal: 'Inter_600SemiBold',
        },
        700: {
          normal: 'Inter_700Bold',
        },
        800: {
          normal: 'Inter_800ExtraBold',
        },
        900: {
          normal: 'Inter_900Black',
        },
      },
      OpenSans: {
        300: {
          normal: 'OpenSans_300Light',
        },
        400: {
          normal: 'OpenSans_400Regular',
        },
        500: {
          normal: 'OpenSans_500Medium',
        },
        600: {
          normal: 'OpenSans_600SemiBold',
        },
        700: {
          normal: 'OpenSans_700Bold',
        },
        800: {
          normal: 'OpenSans_800ExtraBold',
        },
      },
    },
    fonts: {
      openSemiBold: 'OpenSans',
      open: 'OpenSans',
      heading: 'Inter',
      body: 'Inter',
      mono: 'Inter',
      listItem: 'OpenSans',
      listHeading: 'OpenSans',
      OpenSans: 'OpenSans',
      badge: 'OpenSans',
      tableHeader: 'Inter',
      tableCell: 'OpenSans',
    },
    black: {
      50: '#151c2e1a',
      100: '#51525a',
      200: '#3f3f46',
    },
    config: {
      // Changing initialColorMode to 'dark'
      initialColorMode: 'light',
    },
    components: {
      Button: {
        // Can simply pass default props to change default behaviour of components.
        baseStyle: {
          rounded: 'md',
          _text: {
            fontWeight: '400',
            fontSize: 'sm',
          },
        },
        defaultProps: {
          size: 'sm',
          colorScheme: 'primary',
          variant: 'solid',
        },
      },
      Heading: {
        // Can pass also function, giving you access theming tools
        baseStyle: ({ colorMode }: any) => {
          return {
            color: colorMode === 'dark' ? 'red.300' : 'blue.300',
            fontWeight: 'normal',
          };
        },
      },
      ModalContent: {
        baseStyle: () => {
          return {
            bg: 'white',
            _light: {
              bg: 'white',
            },
          };
        },
      },
      ModalHeader: {
        baseStyle: () => {
          return {
            bg: 'white',
            _light: {
              bg: 'white',
            },
          };
        },
      },
      ModalBody: {
        baseStyle: () => {
          return {
            bg: 'white',
            _light: {
              bg: 'white',
            },
          };
        },
      },
      ModalFooter: {
        baseStyle: () => {
          return {
            bg: 'white',
            _light: {
              bg: 'white',
            },
          };
        },
      },
      ModalCloseButton: {
        baseStyle: () => {
          return {
            _icon: {
              size: '14px',
            },
          };
        },
      },
      Progress: {
        baseStyle: () => {
          return {
            bg: 'gray.300',
          };
        },
      },
      Radio: {
        baseStyle: () => {
          return {
            _focusVisible: {
              _web: {
                style: {
                  outlineWidth: '0px',
                },
              },
            },
          };
        },
      },
      Select: {
        baseStyle: () => {
          return {
            _invalid: {
              borderColor: 'error.600',
            },
          };
        },
      },
      Input: {
        baseStyle: () => {
          return {
            _invalid: {
              borderColor: 'error.600',
            },
          };
        },
      },
      FormControlErrorMessage: {
        baseStyle: () => {
          return {
            _text: {
              color: 'error.600',
            },
          };
        },
      },
      FormControlLabel: {
        baseStyle: () => {
          return {
            _text: {
              color: 'gray.900',
              fontWeight: 600,
            },
            _astrick: {
              color: 'error.500',
            },
          };
        },
      },
    },
  })
);
