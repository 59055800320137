import React from 'react';
import {
  View,
  WithKNOWLoader,
  useRoute,
  Text,
  Topbar,
  Box,
  KNOWButton as Button,
  TaskStatus,
  TaskDeadline,
  ExpoIonicIcon,
  TaskUpdate,
  TaskActionDialog,
  UploadProgressModal,
  ExpoMAIcon,
  TaskRemarkInput,
  UploadImageModal,
  UploadVideoModal,
  UploadFileHandler,
  ViewUsersModal,
  // NBFlatList as FlatList,
  ScrollView,
  Pressable,
  ExpoEvilIcon,
} from '@know/ui';
import { useTaskDetails } from './useTaskDetails';
import { getDateFormat } from '../utils';

export const linking = {
  path: 'tasks/details/:taskId',
};

interface IContainerListProps {
  isSmallScreen: boolean;
  containerHeight: number;
  topBarHeight: number;
  containerListRef: React.Ref<any>;
}

const ContainerList: React.FC<IContainerListProps> = ({
  isSmallScreen,
  containerHeight,
  topBarHeight,
  containerListRef,
  children,
}) => {
  if (isSmallScreen) {
    return (
      <ScrollView
        flex={1}
        display={'flex'}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        bg={'white'}
        h={isSmallScreen ? undefined : `${containerHeight - topBarHeight}px`}
        position={'relative'}
        ref={containerListRef}
      >
        {children}
      </ScrollView>
    );
  } else {
    return (
      <View
        flex={1}
        display={'flex'}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        bg={'white'}
        h={`${containerHeight - topBarHeight}px`}
        position={'relative'}
      >
        {children}
      </View>
    );
  }
};

export const TaskDetailsPage = () => {
  const route = useRoute();
  // @ts-ignore
  const taskId = route?.params?.taskId!;
  // @ts-ignore
  const shareId = route?.params?.shareId!;
  // @ts-ignore
  const formId = route?.params?.formId!;
  // @ts-ignore
  const responseId = route?.params?.responseId!;
  // @ts-ignore
  const initiatorId = route?.params?.initiatorId!;

  const {
    isLoading,
    taskNugget,
    taskUpdates,
    isSmallScreen,
    taskStatus,
    actionButtonText,
    isActionLoading,
    isCompleteTaskDialogOpen,
    isReopenTaskDialogOpen,
    isUploading,
    uploadProgress,
    uploadProgressProps,
    isFormUnSubmitted,
    isPhoneSize,
    menuOptions,
    uploadImageModalRef,
    uploadVideoModalRef,
    uploadFileHandlerRef,
    isTaskUsersDialogOpen,
    isTaskUsersLoading,
    taskUsers,
    listRef,
    containerHeight,
    topBarHeight,
    detailsContainerWidth,
    containerListRef,
    setContainerHeight,
    setTopBarHeight,
    setDetailsContainerHeight,
    setDetailsContainerWidth,
    openTaskUsersDialog,
    closeTaskUsersDialog,
    closeCompleteTaskDialog,
    closeReopenTaskDialog,
    onCompleteTask,
    onReopenTask,
    goBack,
    onClickImage,
    onClickPayload,
    onClickActionButton,
    uploadFiles,
    onSendRemark,
    onClickPdf,
    onAddImages,
    onAddVideos,
    onAddFiles,
    onClickShare,
    onClickAuditDetails,
  } = useTaskDetails(taskId, shareId, { formId, responseId, initiatorId });

  return (
    <View
      h={isSmallScreen ? 'calc(100vh - 80px)' : '100vh'}
      bg={'white'}
      onLayout={(e) => {
        setContainerHeight(e.nativeEvent.layout.height);
      }}
      position={'relative'}
    >
      <WithKNOWLoader
        isLoading={isLoading}
        color="primary"
        type="horizontal"
        size="lg"
      >
        <Topbar
          goBack={goBack}
          onLayout={(e) => {
            setTopBarHeight(e.nativeEvent.layout.height);
          }}
        />
        {taskNugget && Object.keys(taskNugget).length ? (
          <ContainerList
            isSmallScreen={isSmallScreen}
            containerHeight={containerHeight}
            topBarHeight={topBarHeight}
            containerListRef={containerListRef}
          >
            <View
              w={isSmallScreen ? '100%' : '25%'}
              maxW={isSmallScreen ? '100%' : '350px'}
              h={isSmallScreen ? undefined : '100%'}
              px={'16px'}
              py={'20px'}
              overflow={isSmallScreen ? undefined : 'auto'}
              onLayout={(e) => {
                setDetailsContainerWidth(e.nativeEvent.layout.width);
                setDetailsContainerHeight(e.nativeEvent.layout.height);
              }}
            >
              <Box>
                <Text fontWeight={'600'} fontSize={'18px'}>
                  {`${taskNugget.extId ? `${taskNugget.extId}: ` : ''}${
                    taskNugget.name
                  }`}
                </Text>
                <Text
                  fontWeight={'400'}
                  color={'gray.700'}
                  fontSize={'14px'}
                  lineHeight={'19.6px'}
                >
                  {taskNugget.notes}
                </Text>
              </Box>
              {isFormUnSubmitted && taskStatus === 'inProgress' ? (
                <View
                  display={'flex'}
                  flexDir={'row'}
                  alignItems={'flex-start'}
                  justifyContent={'flex-start'}
                  mt={'20px'}
                  borderRadius={'6px'}
                  pt="8px"
                  pr="12px"
                  pb="8px"
                  pl="8px"
                  bg={'gray.200'}
                >
                  <ExpoMAIcon
                    name="info"
                    color={'gray.900'}
                    size={'18px'}
                    mt={'2px'}
                    mr={'6px'}
                  />
                  <Text flex={1} fontFamily={'tableCell'} fontSize={'14px'}>
                    The task can be completed only after you've submitted the
                    assigned form.
                  </Text>
                </View>
              ) : null}
              <Box my={'20px'}>
                <Button
                  isLoading={isActionLoading}
                  colorScheme="primary"
                  onPress={onClickActionButton}
                >
                  {actionButtonText}
                </Button>
              </Box>
              {taskNugget.auditDetails ? (
                <Box mb={'17px'} alignItems={'flex-start'}>
                  <Text fontWeight={'600'} fontSize={'14px'}>
                    Created From:
                  </Text>
                  <Pressable onPress={onClickAuditDetails}>
                    {({ isHovered }) => (
                      <Text fontFamily={'tableCell'}>
                        <Text
                          fontWeight={'400'}
                          fontSize={'14px'}
                          color={isHovered ? 'gray.900' : 'gray.700'}
                          textDecorationLine={'underline'}
                        >
                          {taskNugget.auditDetails?.name}
                        </Text>
                        <ExpoEvilIcon
                          name={'link-external'}
                          size={'15px'}
                          color={isHovered ? 'gray.900' : 'gray.700'}
                          ml="6px"
                        />
                      </Text>
                    )}
                  </Pressable>
                </Box>
              ) : null}
              <View
                display={'flex'}
                flexDir={isSmallScreen && !isPhoneSize ? 'row' : 'column'}
              >
                <Box mb={'17px'} alignItems={'flex-start'} flex={1}>
                  <Text fontWeight={'600'} fontSize={'14px'} mb={'6px'}>
                    Status
                  </Text>
                  <TaskStatus status={taskStatus} />
                </Box>
                {taskNugget.plannedStartDate ? (
                  <Box mb={'17px'} alignItems={'flex-start'} flex={1}>
                    <Text fontWeight={'600'} fontSize={'14px'} mb={'2px'}>
                      Planned Start
                    </Text>
                    <Text
                      color={'gray.700'}
                      fontSize={'14px'}
                      fontFamily={'tableCell'}
                    >
                      {getDateFormat(taskNugget.plannedStartDate)}
                    </Text>
                  </Box>
                ) : null}
                {taskNugget.deadline ? (
                  <Box mb={'17px'} alignItems={'flex-start'} flex={1}>
                    <Text fontWeight={'600'} fontSize={'14px'} mb={'2px'}>
                      Due Date
                    </Text>
                    <TaskDeadline
                      deadline={taskNugget.deadline}
                      isCompleted={taskStatus === 'completed'}
                    />
                  </Box>
                ) : null}
              </View>
              <Box
                display={'flex'}
                flexDir={'row'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                {taskStatus === 'completed' ? null : (
                  <Button
                    colorScheme="secondary"
                    onPress={onClickShare}
                    leftIcon={
                      <ExpoIonicIcon
                        name="share-social"
                        size={'16px'}
                        mt={'2px'}
                      />
                    }
                  >
                    Share
                  </Button>
                )}
                <Button
                  colorScheme="secondary"
                  borderWidth={0}
                  onPress={openTaskUsersDialog}
                  leftIcon={
                    <ExpoIonicIcon
                      name="information-circle-outline"
                      size={'16px'}
                      mt={'2px'}
                    />
                  }
                >
                  More Info
                </Button>
              </Box>
            </View>
            {isSmallScreen ? (
              <View
                h={isSmallScreen ? undefined : '100%'}
                w={isSmallScreen ? '100%' : undefined}
                flex={1}
                overflow={isSmallScreen ? undefined : 'auto'}
                bg={'gray.100'}
                py={'20px'}
                px={'16px'}
                pb={isSmallScreen ? '20px' : '90px'}
              >
                {taskUpdates.map((update, index) => (
                  <TaskUpdate
                    key={`${update.taskUpdateId}`}
                    update={update}
                    isFirst={index === 0}
                    onClickImage={onClickImage}
                    onClickPayload={onClickPayload}
                    onClickPdf={onClickPdf}
                  />
                ))}
                {isSmallScreen ? <Box h={'90px'} /> : null}
              </View>
            ) : (
              <ScrollView
                h={isSmallScreen ? undefined : '100%'}
                w={isSmallScreen ? '100%' : undefined}
                flex={1}
                overflow={isSmallScreen ? undefined : 'auto'}
                bg={'gray.100'}
                py={'20px'}
                px={'16px'}
                pb={isSmallScreen ? '20px' : '90px'}
                ref={listRef}
              >
                {taskUpdates.map((update, index) => (
                  <TaskUpdate
                    key={`${update.taskUpdateId}`}
                    update={update}
                    isFirst={index === 0}
                    onClickImage={onClickImage}
                    onClickPayload={onClickPayload}
                    onClickPdf={onClickPdf}
                  />
                ))}
                {isSmallScreen ? <Box h={'90px'} /> : null}
              </ScrollView>
            )}
          </ContainerList>
        ) : null}
        <Box
          position={'absolute'}
          bottom={'20px'}
          right={'16px'}
          left={isSmallScreen ? '20px' : `${detailsContainerWidth + 20}px`}
        >
          <TaskRemarkInput onSend={onSendRemark} menuOptions={menuOptions} />
        </Box>
        <TaskActionDialog
          isOpen={isCompleteTaskDialogOpen}
          cancel={closeCompleteTaskDialog}
          isActionLoading={isActionLoading}
          uploadFiles={uploadFiles}
          onClickImage={onClickImage}
          type="complete"
          onSubmit={onCompleteTask}
        />
        <TaskActionDialog
          isOpen={isReopenTaskDialogOpen}
          cancel={closeReopenTaskDialog}
          isActionLoading={isActionLoading}
          uploadFiles={uploadFiles}
          onClickImage={onClickImage}
          type="reopen"
          onSubmit={onReopenTask}
        />
        <UploadImageModal
          isOpen={false}
          close={console.log}
          limit={10}
          onSubmit={onAddImages}
          ref={uploadImageModalRef}
          showAlertToast={true}
          hideUploadButtonInTakePhotoModal={true}
        />
        <UploadVideoModal
          isOpen={false}
          close={console.log}
          limit={1}
          onSubmit={onAddVideos}
          ref={uploadVideoModalRef}
          showAlertToast={true}
          hideUploadButtonInRecordVideoModal={true}
        />
        <UploadFileHandler
          ref={uploadFileHandlerRef}
          limit={1}
          onSubmit={onAddFiles}
          accept="application/pdf"
          acceptName="PDF"
        />
        {isUploading && (
          <UploadProgressModal
            isOpen={isUploading}
            progress={uploadProgress}
            {...uploadProgressProps}
          />
        )}
        <ViewUsersModal
          isOpen={isTaskUsersDialogOpen}
          onClose={closeTaskUsersDialog}
          options={taskUsers}
          isLoading={isTaskUsersLoading}
          title="Members"
        />
      </WithKNOWLoader>
    </View>
  );
};
