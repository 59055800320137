import React from 'react';
import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

export const UploadVideoPlayButtonIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 21 20">
    <Path
      d="M5 3C4.33696 3 3.70107 3.26339 3.23223 3.73223C2.76339 4.20107 2.5 4.83696 2.5 5.5V14.5C2.5 15.163 2.76339 15.7989 3.23223 16.2678C3.70107 16.7366 4.33696 17 5 17H16C16.663 17 17.2989 16.7366 17.7678 16.2678C18.2366 15.7989 18.5 15.163 18.5 14.5V5.5C18.5 4.83696 18.2366 4.20107 17.7678 3.73223C17.2989 3.26339 16.663 3 16 3H5ZM9.265 7.076L13.265 9.578C13.3358 9.6224 13.3943 9.68383 13.4352 9.75668C13.4761 9.82952 13.4981 9.91146 13.4992 9.995C13.5003 10.0785 13.4805 10.161 13.4415 10.2349C13.4025 10.3088 13.3456 10.3718 13.276 10.418L9.276 13.067C9.20065 13.1169 9.11318 13.1454 9.02291 13.1496C8.93263 13.1537 8.84293 13.1333 8.76332 13.0905C8.68372 13.0477 8.6172 12.9842 8.57083 12.9066C8.52446 12.8291 8.49999 12.7404 8.5 12.65V7.5C8.5 7.41075 8.52389 7.32312 8.56919 7.24622C8.61449 7.16932 8.67955 7.10594 8.75762 7.06268C8.83568 7.01941 8.9239 6.99783 9.01312 7.00017C9.10235 7.00251 9.18931 7.0287 9.265 7.076Z"
      fill="currentColor"
    />
  </Icon>
);
