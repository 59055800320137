import React, { useEffect, useMemo, useState } from 'react';
import { View, Pressable, Image, Text } from '@know/ui';
import type { IHotspotTraining } from './types';

const INIT_DIMENSIONS = {
  width: '100%',
  height: '100%',
};
export const HotspotTraining: React.FC<IHotspotTraining> = ({
  hotspotTrainingObj,
  handleHotspotConsumed,
}) => {
  const hotspotImagesPayload = hotspotTrainingObj?.payload?.hotspotImages?.[0];
  const hotspots = hotspotImagesPayload?.hotspots;
  const [isLanscapeMode, setIsLandscapeMode] = useState(true);
  const [newCoordinates, setNewCoordinates] = useState(hotspots?.[0]);
  const [imageDimensions, setImageDimensions] = useState(INIT_DIMENSIONS);
  const [imageWrapperDimensions, setImageWrapperDimensions] =
    useState(INIT_DIMENSIONS);

  const handlePress = (evt: any) => {
    const xCord = evt.nativeEvent.offsetX;
    const yCord = evt.nativeEvent.offsetY;
    let xCords: any = [];
    let yCords: any = [];

    if (newCoordinates.length) {
      newCoordinates?.forEach((ht: any) => {
        if (!xCords.includes(ht.x)) {
          xCords.push(ht.x);
        }
        if (!yCords.includes(ht.y)) {
          yCords.push(ht.y);
        }
      });

      xCords.sort((a: number, b: number) => a - b);
      yCords.sort((a: number, b: number) => a - b);

      if (
        xCords[0] <= xCord &&
        xCords[1] >= xCord &&
        yCords[0] <= yCord &&
        yCords[1] >= yCord
      ) {
        handleHotspotConsumed();
      }
    }
  };

  const aspectRatio = useMemo(() => {
    const width =
      (+imageWrapperDimensions.height * +imageDimensions.width) /
      +imageDimensions.height;
    const height =
      (+imageWrapperDimensions.width * +imageDimensions.height) /
      +imageDimensions.width;

    return {
      width: isLanscapeMode ? imageWrapperDimensions.width : width,
      height: isLanscapeMode ? height : imageWrapperDimensions.height,
    };
  }, [
    imageDimensions.height,
    imageDimensions.width,
    imageWrapperDimensions.height,
    imageWrapperDimensions.width,
    isLanscapeMode,
  ]);

  useEffect(() => {
    Image.getSize(
      hotspotImagesPayload?.url,
      // @ts-ignore
      (width: string, height: string) => {
        setImageDimensions({ width, height });
        setIsLandscapeMode(+width > +height ? true : false);

        const newWidth = aspectRatio.width;
        const newHeight = aspectRatio.height;
        const oldWidth = imageDimensions.width;
        const oldHeight = imageDimensions.height;

        const mappedCords = hotspots?.[0].map((ht: any) => {
          const x = ht.x * (+newWidth / +oldWidth);
          const y = ht.y * (+newHeight / +oldHeight);
          return {
            x,
            y,
          };
        });
        setNewCoordinates(mappedCords);
      }
    );
  }, [
    hotspotImagesPayload?.url,
    hotspots,
    imageDimensions.height,
    imageDimensions.width,
    isLanscapeMode,
    imageWrapperDimensions.height,
    imageWrapperDimensions.width,
    aspectRatio,
  ]);

  return (
    <View flex={1} justifyContent="space-between">
      <View />
      <View
        flex={1}
        bgColor="black"
        justifyContent="center"
        alignItems="center"
        onLayout={(e) => {
          const { height, width }: any = e.nativeEvent.layout;
          setImageWrapperDimensions({
            width,
            height,
          });
        }}
      >
        <Pressable
          justifyContent="center"
          position="relative"
          alignItems="center"
          onPress={(evt) => handlePress(evt)}
          width={aspectRatio.width}
          height={aspectRatio.height}
        >
          <Image
            alt="full screen image"
            width={aspectRatio.width}
            height={aspectRatio.height}
            source={{
              uri: hotspotImagesPayload?.url,
            }}
            resizeMode="contain"
          />
        </Pressable>
      </View>
      <View
        backgroundColor="black"
        padding="16px"
        fontSize="18px"
        maxW="full"
        justifyContent="center"
      >
        <Text color="white">{hotspotImagesPayload?.instruction}</Text>
      </View>
    </View>
  );
};
