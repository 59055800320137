import React, { useCallback } from 'react';
import { Box, ExpoFAIcon, IconButton, Image, Pressable, Text } from '@know/ui';
import { Platform } from 'react-native';

export const ImageCard = ({
  url,
  onDelete,
}: {
  url: string;
  onDelete: () => any;
}) => {
  return (
    <Box
      borderRadius={'6px'}
      shadow={1}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      w={'100%'}
    >
      <Box w={'100%'} h={'200px'}>
        <Image
          resizeMode="cover"
          source={{
            uri: url,
          }}
          h={'100%'}
          borderTopRadius={'6px'}
        />
      </Box>
      <Box px={'8px'} py={'6px'}>
        <IconButton
          icon={
            <ExpoFAIcon
              color={'error.500'}
              name="trash-o"
              size={'20px'}
              textAlign={'center'}
            />
          }
          onPress={onDelete}
          p={0}
          _hover={{
            bg: 'none',
          }}
          _pressed={{
            bg: 'none',
          }}
        />
      </Box>
    </Box>
  );
};

export const VideoCard = ({
  url,
  onDelete,
}: {
  url: string;
  onDelete: () => any;
}) => {
  return (
    <Box
      borderRadius={'6px'}
      shadow={1}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      w={'100%'}
    >
      <Box w={'100%'} h={'200px'}>
        <video
          src={url}
          controls
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            height: '100%',
          }}
        />
      </Box>
      <Box px={'8px'} py={'6px'}>
        <IconButton
          icon={
            <ExpoFAIcon
              color={'error.500'}
              name="trash-o"
              size={'20px'}
              textAlign={'center'}
            />
          }
          onPress={onDelete}
          p={0}
          _hover={{
            bg: 'none',
          }}
          _pressed={{
            bg: 'none',
          }}
        />
      </Box>
    </Box>
  );
};

export const PDFFileCard = ({
  url,
  name,
  onDelete,
}: {
  url: string;
  name: string;
  onDelete: () => any;
}) => {
  const onClickFile = useCallback(() => {
    if (Platform.OS === 'web') {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    }
  }, [url]);
  return (
    <Pressable onPress={onClickFile} w={'100%'}>
      <Box
        w={'100%'}
        borderRadius={'8px'}
        bg={'gray.200'}
        py={'11px'}
        px={'12px'}
        display={'flex'}
        flexDir={'row'}
        alignItems={'center'}
      >
        <ExpoFAIcon
          name="file-pdf-o"
          color={'error.500'}
          size={'20px'}
          mr={'8px'}
        />
        <Text flex={1} noOfLines={1} fontSize={'14px'} mr={'12px'}>
          {name}
        </Text>
        <IconButton
          icon={
            <ExpoFAIcon
              color={'error.500'}
              name="trash-o"
              size={'20px'}
              textAlign={'center'}
            />
          }
          onPress={onDelete}
          p={0}
          _hover={{
            bg: 'none',
          }}
          _pressed={{
            bg: 'none',
          }}
        />
      </Box>
    </Pressable>
  );
};
