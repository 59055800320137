import React from 'react';
import { createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const ReturnIcon = createIcon({
  viewBox: '0 0 20 20',
  path: [
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5009 4C16.6335 4 16.7607 4.05268 16.8544 4.14645C16.9482 4.24021 17.0009 4.36739 17.0009 4.5V9.3C17.0009 9.6283 16.9362 9.95339 16.8106 10.2567C16.685 10.56 16.5008 10.8356 16.2687 11.0678C16.0365 11.2999 15.7609 11.4841 15.4576 11.6097C15.1543 11.7353 14.8292 11.8 14.5009 11.8H4.70789L8.05489 15.146C8.14878 15.2399 8.20153 15.3672 8.20153 15.5C8.20153 15.6328 8.14878 15.7601 8.05489 15.854C7.96101 15.9479 7.83367 16.0006 7.70089 16.0006C7.56812 16.0006 7.44078 15.9479 7.34689 15.854L3.14689 11.654C3.10033 11.6076 3.06339 11.5524 3.03818 11.4916C3.01297 11.4309 3 11.3658 3 11.3C3 11.2342 3.01297 11.1691 3.03818 11.1084C3.06339 11.0476 3.10033 10.9924 3.14689 10.946L7.14689 6.946C7.24078 6.85211 7.36812 6.79937 7.50089 6.79937C7.63367 6.79937 7.76101 6.85211 7.85489 6.946C7.94878 7.03989 8.00153 7.16722 8.00153 7.3C8.00153 7.43278 7.94878 7.56011 7.85489 7.654L4.70789 10.8H14.5009C14.8987 10.8 15.2802 10.642 15.5616 10.3607C15.8429 10.0794 16.0009 9.69782 16.0009 9.3V4.5C16.0009 4.36739 16.0536 4.24021 16.1473 4.14645C16.2411 4.05268 16.3683 4 16.5009 4Z"
      fill="#4E5656"
      stroke="#4E5656"
      strokeWidth="0.5"
    />,
  ],
});
