import React from 'react';
import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

export const WFFormIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 16 18">
    <Path
      d="M12.85 6.65L9.35 3.15C9.25 3.05 9.15 3 9 3H4C3.45 3 3 3.45 3 4V16C3 16.55 3.45 17 4 17H12C12.55 17 13 16.55 13 16V7C13 6.85 12.95 6.75 12.85 6.65ZM9 4.2L11.8 7H9V4.2ZM12 16H4V4H8V7C8 7.55 8.45 8 9 8H12V16Z"
      fill="#222C2A"
    />
    <Path d="M5 13H11V14H5V13Z" fill="#222C2A" />
    <Path d="M5 10H11V11H5V10Z" fill="#222C2A" />
  </Icon>
);

export const RepeatIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 16 19">
    <Path
      d="M2.92308 5.95H2.92302C2.66369 5.95028 2.41595 6.06204 2.23389 6.25927C2.05196 6.45637 1.95026 6.72281 1.95 6.99995V7V10.5V10.55H2H2.92308H2.97308V10.5V7.05H12.119L10.5414 8.75909L10.5101 8.793L10.5414 8.82691L11.194 9.53391L11.2308 9.57372L11.2675 9.53391L14.0367 6.53391L14.068 6.5L14.0367 6.46609L11.2675 3.46609L11.2308 3.42628L11.194 3.46609L10.5414 4.17309L10.5101 4.207L10.5414 4.24091L12.119 5.95H2.92308Z"
      fill="#222C2A"
      stroke="#222C2A"
      strokeWidth="0.1"
    />
    <Path
      d="M5.45587 12.9998L5.49533 12.9631L5.45587 12.9265L4.80325 12.3205L4.76923 12.2889L4.73521 12.3205L1.96598 14.8919L1.92652 14.9286L1.96598 14.9652L4.73521 17.5366L4.76923 17.5682L4.80325 17.5366L5.45587 16.9306L5.49533 16.894L5.45587 16.8574L3.8941 15.4071H13.0769H13.077C13.3338 15.4069 13.5807 15.3121 13.7634 15.1425C13.9462 14.9728 14.0497 14.7418 14.05 14.5001V14.5V11.5V11.45H14H13.0769H13.0269V11.5V14.45H3.8941L5.45587 12.9998Z"
      fill="#222C2A"
      stroke="#222C2A"
      strokeWidth="0.1"
    />
  </Icon>
);

export const ClockIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 19 19">
    <Path
      d="M9.75 15.125C12.8566 15.125 15.375 12.6066 15.375 9.5C15.375 6.3934 12.8566 3.875 9.75 3.875C6.6434 3.875 4.125 6.3934 4.125 9.5C4.125 12.6066 6.6434 15.125 9.75 15.125Z"
      stroke="#F95757"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={'none'}
    />
    <Path
      d="M9.75 9.5H11.9375M9.75 6.375V9.5"
      stroke="#F95757"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={'none'}
    />
  </Icon>
);
