import {
  Text,
  HStack,
  KNOWIcon,
  KNPressable,
  VStack,
  ResponseStatus,
  View,
  Box,
} from '..';

import React from 'react';
import { IReceivedResponseItem, ISectionDataProps } from '@know/transformers';
import { useReceivedFormItemData } from './useReceivedFormItemData';
import { DateTime } from '../DateTime';
import { useKNOWValueContextProvider } from '@know/ui';

interface IReceivedFormResponseItemProps {
  item: IReceivedResponseItem;
  type: string;
  section: ISectionDataProps<IReceivedResponseItem>;
  onPress: (
    item: IReceivedResponseItem,
    type: string,
    section?: ISectionDataProps<IReceivedResponseItem>
  ) => void;
}

export function ReceivedFormResponseItem({
  item,
  section,
  type,
  onPress,
}: IReceivedFormResponseItemProps) {
  const { isWorkflow, status, byText, userName, displayText, time } =
    useReceivedFormItemData(item, section);

  function handleOnPress() {
    onPress && onPress(item, type, section);
  }

  const { isPhoneSize, isLargePhoneSize } = useKNOWValueContextProvider();

  const isMobile = isPhoneSize || isLargePhoneSize;

  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        flex={1}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        pr={'20px'}
        pl={'24px'}
        py={'8px'}
      >
        <KNOWIcon
          color={'gray.900'}
          mr={2}
          mt={'2px'}
          name={'filetext1'}
          size="4"
          textAlign="center"
        />
        <Box
          flex={1}
          display={'flex'}
          justifyContent={isMobile ? 'flex-start' : 'space-between'}
          flexDir={isMobile ? 'column' : 'row'}
        >
          <VStack flex={1}>
            <HStack alignItems={'center'}>
              <Text
                fontFamily={'open'}
                display={'flex'}
                alignItems={'center'}
                color={'gray.900'}
              >
                {displayText}
              </Text>
              {status === 'pending' ? (
                <View
                  ml={1.5}
                  size={2}
                  bg={'primary.500'}
                  borderRadius={'full'}
                />
              ) : null}
            </HStack>
            <HStack mt={'6px'} alignItems={'center'}>
              {isWorkflow ? (
                <View mr={1}>
                  <ResponseStatus status={status} />
                </View>
              ) : null}
              <Text color={'gray.700'} mr={1}>
                {byText}
              </Text>
              <Text
                color={'gray.700'}
                fontFamily={'openSemiBold'}
                fontWeight={600}
                noOfLines={1}
              >
                {userName}
              </Text>
            </HStack>
          </VStack>
          <Text
            fontFamily={'open'}
            color={'gray.700'}
            fontSize={'14px'}
            mt={isMobile ? '6px' : undefined}
          >
            <DateTime time={time as number} />
          </Text>
        </Box>
      </HStack>
    </KNPressable>
  );
}
