import React from 'react';

import type {
  ISectionDataProps,
  IReceivedFormsItem,
  IReceivedResponseItem,
} from '@know/transformers';

import {
  CollapsibleSectionHeader,
  HStack,
  useSelectableSectionsView,
  Text,
  KNPressable,
  ReceivedFormResponseItem,
  KNOWIcon,
  Badge,
} from '@know/ui';

import type { JSXElementConstructor, ReactElement } from 'react';

export const keyExtractor = function (item: IReceivedFormsItem): string {
  return item.id;
};

export const getSectionHeaderComponent = (
  info: {
    section: ISectionDataProps<IReceivedFormsItem>;
  },
  onPress?: (id: string) => void
): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return (
    <KNPressable flex={1} onPress={handleOnPress}>
      <HStack alignItems={'center'}>
        <CollapsibleSectionHeader
          id={info.section.id || info.section.type}
          title={info.section.title}
        />
        {info.section.pending ? (
          <Badge
            ml={2}
            bg={'primary.500'}
            w={'25px'}
            h={'18px'}
            borderRadius={'2xl'}
          >
            <Text fontFamily="badge" color={'white'} fontWeight={'bolder'}>
              {info.section.pending}
            </Text>
          </Badge>
        ) : null}
      </HStack>
    </KNPressable>
  );
  function handleOnPress() {
    onPress && onPress(info.section.id || info.section.type);
  }
};

export const ItemComponent = (info: {
  item: IReceivedResponseItem;
  section: ISectionDataProps<IReceivedResponseItem>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  const { onClickItem } = useSelectableSectionsView();

  return (
    <HStack alignItems={'center'} flex={1} justifyContent={'space-between'}>
      {info.item.type === 'hasMore' ? (
        <KNPressable
          onPress={handleOnPress}
          flex={1}
          _hover={{
            bg: 'gray.200',
          }}
        >
          <HStack
            alignItems={'center'}
            justifyContent={'flex-end'}
            pr={'20px'}
            py={'8px'}
          >
            <Text
              fontWeight={700}
              fontFamily={'open'}
              color={'purple.400'}
              mr={'6px'}
            >
              View All
            </Text>
            <KNOWIcon color={'purple.400'} size={'20px'} name="arrowright" />
          </HStack>
        </KNPressable>
      ) : (
        <ReceivedFormResponseItem
          onPress={handleOnPress}
          item={info.item as any}
          type={info.section.type}
          section={info.section as any}
        />
      )}
    </HStack>
  );

  function handleOnPress() {
    onClickItem && onClickItem(info.item, info.section.type, info.section);
  }
};
