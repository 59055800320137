import { atom, selector } from '@know/ui';
import moment from 'moment';
import { keys, sortBy } from 'lodash';

export interface ShiftInterface {
  authorId: string;
  authorName: string;
  breakDuration: number;
  endTime: number;
  leaveTypeId?: string;
  locationId: string;
  location: string;
  notes: string;
  openShiftId: string;
  shiftDay: string;
  shiftSlot: {
    id: string;
    slot: {
      name: string;
    };
  };
  startTime: number;
  userId: string;
  userName: string;
  isLeaveRequest?: boolean;
  isCheckedIn?: boolean;
  clockin?: number;
  clockout?: number;
  attInRefKey?: string;
  attOutRefKey?: string;
}

export interface ShiftWithIdInterface extends ShiftInterface {
  shiftId: string;
}

export interface ShiftByDatesInterface {
  [shiftDay: string]: {
    [shiftId: string]: ShiftInterface;
  };
}

interface DateRangeInterface {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export type AttendanceEventType = 'checkIn' | 'checkOut';
export interface AttendanceEventInterface {
  createdAt: object | number;
  clockInReferenceKey?: string | null;
  event: 'checkIn' | 'checkOut';
  deviceTime: number;
  st: object | number;
  shiftId: string;
  actionDate: number;
  shiftDay: string;
}

export const shiftsByDatesState = atom<ShiftByDatesInterface>({
  key: 'shiftByDates',
  default: {},
});

export const shiftListState = selector<Array<ShiftWithIdInterface>>({
  key: 'shiftsList',
  get: ({ get }: { get: (state: any) => any }): Array<ShiftWithIdInterface> => {
    const shiftByDates: ShiftByDatesInterface = get(shiftsByDatesState);
    const dateRange: DateRangeInterface = get(dateRangeState);
    const shiftsFromAttendanceCache: {
      [key: string]: ShiftWithIdInterface;
    } = get(shiftsFromAttendanceCacheState);

    const startTimeRange = +dateRange.startDate;
    const endTimeRange = +dateRange.endDate;

    const shiftsData = keys(shiftByDates).reduce<{
      [key: string]: ShiftWithIdInterface;
    }>((acc, shiftDay) => {
      const shifts = shiftByDates[shiftDay];

      for (const shiftId in shifts) {
        const shift = shifts[shiftId];
        const { isLeaveRequest, startTime } = shift;
        if (
          startTime >= startTimeRange &&
          startTime <= endTimeRange &&
          !isLeaveRequest
        ) {
          acc[shiftId] = {
            ...shift,
            shiftId,
          };
        }
      }

      return acc;
    }, {});

    return sortBy(
      {
        ...shiftsFromAttendanceCache,
        ...shiftsData,
      },
      (shift) => {
        const { startTime, endTime, shiftId } = shift;
        const isPassed = endTime < +moment();

        if (
          +moment(startTime).startOf('day') === +moment().startOf('day') ||
          shiftsFromAttendanceCache[shiftId]
          // if shift on same day or is being checked in
        ) {
          if (shiftsFromAttendanceCache[shiftId]) {
            // if shift is being checked in, we want to be first priority
            return [1, startTime];
          } else if (isPassed) {
            // if end time has passed (cannot checkin anymore), we want to be second priority
            return [2, startTime];
          }

          return [1, startTime]; // if not being checked in but still can be, we also want to be first
        } else {
          return [3, startTime]; // else third priority
        }
      }
    );
  },
});

export const dateRangeState = atom<DateRangeInterface>({
  key: 'dateRange',
  default: {
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek'),
  },
});

export const isLoadingShiftsByDatesState = atom<{
  [shiftDay: string]: boolean;
}>({
  key: 'isLoadingShiftsByDates',
  default: { default: true },
});

export const isLoadingShiftCacheState = atom<boolean>({
  key: 'isLoadingShiftCache',
  default: true,
});

export const attendanceCacheState = atom<{
  [key: string]: AttendanceEventInterface;
} | null>({
  key: 'attendanceShiftCache',
  default: null,
});

export const shiftsFromAttendanceCacheState = atom<{
  [key: string]: ShiftWithIdInterface;
}>({
  key: 'shiftsFromAttendanceCache',
  default: {},
});

export const isLoadingShiftsState = selector<boolean>({
  key: 'isLoadingShifts',
  get: ({ get }) => {
    const isLoadingByDates = get(isLoadingShiftsByDatesState);

    const datesStillLoading = keys(isLoadingByDates).filter(
      (date) => isLoadingByDates[date]
    );

    return !!datesStillLoading.length;
  },
});

export const isWritingAttendanceState = atom<boolean>({
  key: 'isWritingAttendanceState',
  default: true,
});

export const isDoingAttendanceState = atom<boolean>({
  key: 'isDoingAttendanceState',
  default: false,
});

export interface DailyUserUpdateInterface {
  [userId: string]: {
    startTime: number;
    endTime: number;
    userName: string;
  };
}
