import React, { Fragment, useState, useEffect } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { ScrollView, View, Image, ContentNotSupported } from '@know/ui';
import type { IQuizMCQ } from '../types';

import NextPreviousButton from './NextPreviousButton';
import Modal from './Modal';
import Timer from './Timer';
import QuestionDetail from './QuestionDetail';
import { CheckBox } from './CheckBox';
import { SingleSelectText } from './SingleSelectText';
import { SingleSelectImage } from './SingleSelectImage';

const isSelected = (selectedOption: any, index: any) => {
  if (selectedOption === index) {
    return 'primary.500';
  }
  return 'gray.300';
};

export const QuizMCQ: React.FC<IQuizMCQ> = ({
  card,
  handleSubmitQuiz,
  handleQuizResponses,
}) => {
  const questions = card?.payload?.questions;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [fadeAnim] = useState<any>(new Animated.Value(1));
  const [remainingTime, setRemainingTime] = useState<number | undefined>(
    questions[selectedIndex].timeInSec
  );
  const timerRef: { current: NodeJS.Timer | null } = React.useRef(null);
  const [quizProgress, setQuizProgress] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [multiSelectQuizRes, setMultiSelectQuizRes] = useState<any>([]);
  const [hotspotIcons, setHotspotIcons] = useState<any>([]);
  const [hotspotIconsQuizRes, setHotspotIconsQuizRes] = useState<any>([]);
  const questionType = questions[selectedIndex].type;
  const styles = StyleSheet.create({
    animatedView: {
      opacity: fadeAnim,
      flex: 1,
      backgroundColor: 'white',
    },
    imageFromReactNative: { height: '15px', width: '15px' },
  });
  const [, settopImageHeight] = useState(0);

  const modalDetails = () => {
    let modalDet = { title: '', description: '' };
    if (questionType === 'hotspots') {
      modalDet.title = 'Max number of markers created.';
      modalDet.description =
        'Please clear some of your markers in order to create new ones.';
    } else if (questionType === 'checkbox') {
      modalDet.title = 'Max number of options selected.';
      modalDet.description =
        'Please remove one of the selected options before you select another.';
    }
    return modalDet;
  };

  const handleNextQuestion = (payload: any, notSupportedContent?: boolean) => {
    if (!notSupportedContent) {
      handleQuizResponses(payload);
    }
    if (selectedIndex < questions.length - 1) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start();
      setTimeout(() => {
        setSelectedIndex(selectedIndex + 1);
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start();
      }, 200);
    } else if (selectedIndex + 1 === questions?.length) {
      handleSubmitQuiz();
    }
  };

  useEffect(() => {
    clearInterval(timerRef.current as NodeJS.Timer);
    if (card?.isTimer && questions[selectedIndex]?.timeInSec) {
      if (remainingTime! <= 0) {
        setRemainingTime(questions[selectedIndex]?.timeInSec);
      }
      setRemainingTime(questions[selectedIndex]?.timeInSec);
      timerRef.current = setInterval(() => {
        setRemainingTime((prevState: number | undefined) => prevState! - 1);
      }, 1000);
    }

    if (questionType === 'hotspots') {
      setHotspotIcons(quizProgress[selectedIndex] || []);
      setHotspotIconsQuizRes([]);
    } else if (questionType === 'checkbox') {
      setMultiSelectQuizRes(quizProgress[selectedIndex] || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  useEffect(() => {
    if (card?.isTimer && questions[selectedIndex]?.timeInSec) {
      if (remainingTime! <= 0) {
        const payload = {
          selectedIndex,
          selectedOption: quizProgress?.[selectedIndex] || '',
        };
        handleNextQuestion(payload);
        return clearInterval(timerRef.current as NodeJS.Timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  let renderQuizQuestion;
  let notSupportedContent = false;
  let isHotspotType = false;
  switch (questions[selectedIndex].type) {
    case 'mcq':
      renderQuizQuestion = ({ item, index }: any) => (
        <SingleSelectText
          selectedIndex={selectedIndex}
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
          styles={styles}
          isSelected={isSelected}
          index={index}
          item={item}
          handleNextQuestion={handleNextQuestion}
        />
      );
      break;
    case 'mcq-image':
      renderQuizQuestion = ({ item, index }: any) => (
        <SingleSelectImage
          selectedIndex={selectedIndex}
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
          isSelected={isSelected}
          index={index}
          item={item}
          handleNextQuestion={handleNextQuestion}
        />
      );
      break;
    case 'checkbox':
      renderQuizQuestion = ({ item, index }: any) => (
        <CheckBox
          multiSelectQuizRes={multiSelectQuizRes}
          setMultiSelectQuizRes={setMultiSelectQuizRes}
          selectedIndex={selectedIndex}
          handleQuizResponses={handleQuizResponses}
          setQuizProgress={setQuizProgress}
          quizProgress={quizProgress}
          styles={styles}
          isSelected={isSelected}
          index={index}
          item={item}
          selectedQuestion={questions[selectedIndex]}
          setShowModal={setShowModal}
        />
      );
      break;
    case 'hotspots':
      isHotspotType = true;
      break;
    default:
      notSupportedContent = true;
  }

  const isDisabledNextButton = () => {
    if (notSupportedContent) {
      return false;
    }
    if (isHotspotType) {
      if (questions[selectedIndex].allowMultiple) {
        if (hotspotIcons.length === questions[selectedIndex].options.length) {
          return false;
        } else {
          return true;
        }
      } else if (hotspotIcons.length > 0) {
        return false;
      }
      return true;
    } else {
      if (questions[selectedIndex].type === 'checkbox') {
        if (questions[selectedIndex].isLimit) {
          if (
            quizProgress?.[selectedIndex] &&
            Object.keys(quizProgress?.[selectedIndex]).length &&
            Object.keys(quizProgress?.[selectedIndex]).length <=
              questions[selectedIndex].answer.split(',').length
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (
            quizProgress?.[selectedIndex] &&
            Object.keys(quizProgress?.[selectedIndex]).length
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        if (quizProgress?.[selectedIndex]) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  useEffect(() => {
    showModal && handleToggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const handleToggleModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    Image.getSize(
      questions[selectedIndex]?.imageURL,
      // @ts-ignore
      (width: string, height: string) => {
        const imageHeight = (450 * +height) / +width;
        settopImageHeight(imageHeight);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions[selectedIndex]?.imageURL]);

  const showButtons = () => {
    const isShow: boolean =
      questions[selectedIndex].type !== 'mcq' ||
      questions[selectedIndex].type !== 'mcq-image';

    return !card.isTimer ? true : isShow;
  };

  return (
    <Fragment>
      <Animated.View style={styles.animatedView}>
        <ScrollView flex={1}>
          {!!questions[selectedIndex]?.imageURL && (
            <Image
              alt="image quiz mcq"
              source={{ uri: questions[selectedIndex].imageURL }}
              h="30vh"
              resizeMode="contain"
              backgroundColor="black"
              height="275px"
              width="550px"
            />
          )}
          <View>
            {card?.isTimer && (
              <Timer
                remainingTime={remainingTime}
                questions={questions}
                selectedIndex={selectedIndex}
              />
            )}
            {notSupportedContent ? (
              <ContentNotSupported />
            ) : (
              <QuestionDetail
                isTimer={card?.isTimer}
                selectedIndex={selectedIndex}
                questions={questions}
                quizProgress={quizProgress}
                renderQuestion={renderQuizQuestion}
                isHotspotType={isHotspotType}
                setHotspotIcons={setHotspotIcons}
                hotspotIcons={hotspotIcons}
                hotspotIconsQuizRes={hotspotIconsQuizRes}
                setHotspotIconsQuizRes={setHotspotIconsQuizRes}
                setQuizProgress={setQuizProgress}
                setShowModal={setShowModal}
              />
            )}
            {showButtons() && (
              <View paddingX="15px">
                <NextPreviousButton
                  selectedIndex={selectedIndex}
                  handleNextQuestion={handleNextQuestion}
                  timerRef={timerRef}
                  fadeAnim={fadeAnim}
                  setSelectedIndex={setSelectedIndex}
                  questions={questions}
                  quizProgress={quizProgress}
                  handleSubmitQuiz={handleSubmitQuiz}
                  isDisabledNextButton={isDisabledNextButton}
                  hotspotIconsQuizRes={hotspotIconsQuizRes}
                  isHotspotType={isHotspotType}
                  notSupportedContent={notSupportedContent}
                  isTimer={card?.isTimer}
                />
              </View>
            )}
            <Modal
              showModal={showModal}
              title={modalDetails().title}
              description={modalDetails().description}
              handleToggleModal={handleToggleModal}
            />
          </View>
        </ScrollView>
      </Animated.View>
    </Fragment>
  );
};
