//TODO: make a native app friendly version
import React, { useCallback, useState, useMemo } from 'react';
import {
  KNOWButton as Button,
  Modal,
  Text,
  View,
  TextArea,
  Pressable,
  ExpoEntypoIcon,
  useModalParam,
  UploadProgressType,
  Image,
  Box,
  ExpoIonicIcon,
  IconButton,
} from '@know/ui';

import { useTranslation } from '@know/i18n';
import { UploadImageModal } from '../Forms/UploadImageModal';
import { UploadFileResultType } from '@know/db';
import { FormControl } from 'native-base';

interface IssueActionButtonProps {
  type: 'reopen' | 'close';
  remark: string;
  isActionLoading: boolean;
  onClickSubmit: () => void;
  onClose: () => void;
  t: (strToTranslate: string) => string;
}

interface ClosureReportActionButton {
  t: (strToTranslate: string) => string;
  onClose: () => void;
  openClosureReport: () => void;
}

interface IssueActionDialogProps {
  hasClosureReport?: boolean;
  type?: 'reopen' | 'close';
  isOpen: boolean;
  isActionLoading: boolean;
  cancel: () => void;
  openClosureReport?: () => void;
  onSubmit?: (params: { remark: string; image?: UploadFileResultType }) => void;
  uploadFiles: (
    files: File[],
    uploadType: UploadProgressType
  ) => Promise<UploadFileResultType[]>;
  onClickImage?: (url: string) => any;
}

const ActionButton = ({
  type,
  remark,
  isActionLoading,
  onClickSubmit,
  onClose,
  t,
}: IssueActionButtonProps) => (
  <>
    <Button
      colorScheme="primary"
      onPress={onClickSubmit}
      w={'100%'}
      borderWidth={'0px'}
      isDisabled={!remark}
      isLoading={isActionLoading}
    >
      {t(type === 'close' ? 'Close Issue' : 'Reopen Issue')}
    </Button>
    <Button
      colorScheme="cancel"
      borderWidth={'0px'}
      onPress={onClose}
      w={'100%'}
      mt={'10px'}
    >
      {t('Cancel')}
    </Button>
  </>
);

const ClosureReportActionButton = ({
  t,
  onClose,
  openClosureReport,
}: ClosureReportActionButton) => {
  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
      <Button
        colorScheme="cancel"
        borderWidth={'0px'}
        onPress={onClose}
        py={'12px'}
        px={'32px'}
      >
        {t('Cancel')}
      </Button>
      <Button
        colorScheme="primary"
        onPress={openClosureReport}
        borderWidth={'0px'}
        ml={'12px'}
        py={'12px'}
        px={'32px'}
      >
        {t('Close Issue')}
      </Button>
    </Box>
  );
};

export const IssueActionDialog = ({
  hasClosureReport,
  isOpen,
  cancel,
  type,
  openClosureReport,
  onSubmit,
  isActionLoading,
  uploadFiles,
}: IssueActionDialogProps) => {
  const { t } = useTranslation(['tasks']);
  const [remark, setRemark] = useState<string>('');
  const [image, setImage] = useState<UploadFileResultType | undefined>();

  const onClickSubmit = useCallback(() => {
    onSubmit?.({ remark, image });
  }, [image, onSubmit, remark]);

  const [
    isUploadImageModalOpen,
    uploadImageModalProps,
    openUploadImageModal,
    closeUploadImageModal,
  ] = useModalParam<{
    onSubmit?: (response: File[] | undefined) => void;
    limit: number;
    restrictImagePicker: boolean;
  }>(false, { limit: 1, restrictImagePicker: false });

  const onClickAttachPhoto = useCallback(() => {
    openUploadImageModal({
      restrictImagePicker: false,
      limit: 1,
      onSubmit: async (files) => {
        if (files && files.length) {
          const images = await uploadFiles(files, 'image');
          if (images.length) {
            setImage(images[0]);
          }
        }
      },
    });
  }, [openUploadImageModal, uploadFiles]);

  const onClose = useCallback(() => {
    setRemark('');
    setImage(undefined);
    cancel();
  }, [cancel]);

  const handleOpenClosureReport = useCallback(() => {
    if (openClosureReport) {
      openClosureReport();
    }
  }, [openClosureReport]);

  const issueDialogText = useMemo(() => {
    if (hasClosureReport) {
      return type === 'close'
        ? 'Proceed only if issue is really closed.'
        : 'Add a remark to close issue';
    } else {
      return type === 'close'
        ? 'Add a remark to close issue'
        : 'Add a remark to reopen issue';
    }
  }, [hasClosureReport, type]);

  return (
    <>
      <Modal isOpen={isOpen} closeOnOverlayClick onClose={onClose} size="xl">
        <Modal.Content>
          <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
            <View
              display={'flex'}
              flexDir={'column'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Text fontWeight={600} fontSize={'18px'}>
                {t(
                  type === 'close'
                    ? 'Close issue for everyone'
                    : 'Reopen issue and notify everyone?'
                )}
              </Text>
              <Text fontSize={'16px'} color={'gray.700'}>
                {t(issueDialogText)}
              </Text>
            </View>
          </Modal.Header>
          {((type === 'close' && !hasClosureReport) || type === 'reopen') && (
            <Modal.Body py={0}>
              <FormControl>
                <TextArea
                  placeholder={t(
                    type === 'close'
                      ? 'Enter remark (Required)'
                      : 'Reason for reopening (Required)'
                  )}
                  onChangeText={setRemark}
                  value={remark}
                  numberOfLines={4}
                  mt={'12px'}
                  autoCompleteType={null}
                  _focus={{
                    bg: 'white',
                  }}
                  fontSize={'14px'}
                />
              </FormControl>
              <View
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                mt={'10px'}
              >
                {image ? (
                  <Box position={'relative'}>
                    <IconButton
                      position={'absolute'}
                      right={0}
                      top={0}
                      w="20px"
                      h="20px"
                      borderRadius={'full'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      bg={'white'}
                      zIndex={100}
                      borderColor={'gray.400'}
                      borderWidth={'1px'}
                      onPress={() => setImage(undefined)}
                      icon={
                        <ExpoIonicIcon
                          name="close"
                          size="14px"
                          color="gray.700"
                        />
                      }
                      _icon={{
                        color: 'gray.700',
                      }}
                      _hover={{
                        bg: 'white',
                      }}
                    />
                    <Pressable onPress={onClickAttachPhoto}>
                      <Image
                        source={{
                          uri: image.url,
                        }}
                        borderRadius={'4px'}
                        resizeMode="cover"
                        w="120px"
                        h="120px"
                        m={'10px'}
                        ml={0}
                      />
                    </Pressable>
                  </Box>
                ) : (
                  <Pressable onPress={onClickAttachPhoto} display={'flex'}>
                    {({ isHovered }) => (
                      <View
                        display={'flex'}
                        flexDir={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                      >
                        <ExpoEntypoIcon
                          name="attachment"
                          mr={'4px'}
                          size={'16px'}
                          color={isHovered ? 'primary.700' : 'primary.500'}
                          mt={'2px'}
                        />
                        <Text
                          fontWeight={600}
                          color={isHovered ? 'primary.700' : 'primary.500'}
                          fontSize={'14px'}
                          textDecorationLine={'underline'}
                          fontFamily={'tableCell'}
                        >
                          {t('Attach Photo')}
                        </Text>
                      </View>
                    )}
                  </Pressable>
                )}
              </View>
            </Modal.Body>
          )}
          <Modal.Footer
            borderTopWidth={0}
            display={'flex'}
            flexDirection={'column'}
            pt={'12px'}
          >
            {(type === 'close' && !hasClosureReport) || type === 'reopen' ? (
              <ActionButton
                type={type}
                remark={remark}
                isActionLoading={isActionLoading}
                onClose={onClose}
                onClickSubmit={onClickSubmit}
                t={t}
              />
            ) : (
              <ClosureReportActionButton
                openClosureReport={handleOpenClosureReport}
                onClose={onClose}
                t={t}
              />
            )}
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {isUploadImageModalOpen && (
        <UploadImageModal
          isOpen={isUploadImageModalOpen}
          close={closeUploadImageModal}
          {...uploadImageModalProps}
        />
      )}
    </>
  );
};
