import React from 'react';
import { createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const UndoIcon = createIcon({
  viewBox: '0 0 24 25',
  path: [
    <Path
      d="M7 19.375V17.375H14.1C15.15 17.375 16.0627 17.0417 16.838 16.375C17.6133 15.7083 18.0007 14.875 18 13.875C18 12.875 17.6123 12.0417 16.837 11.375C16.0617 10.7083 15.1493 10.375 14.1 10.375H7.8L10.4 12.975L9 14.375L4 9.375L9 4.375L10.4 5.775L7.8 8.375H14.1C15.7167 8.375 17.1043 8.9 18.263 9.95C19.4217 11 20.0007 12.3083 20 13.875C20 15.4417 19.4207 16.75 18.262 17.8C17.1033 18.85 15.716 19.375 14.1 19.375H7Z"
      fill="#222C2A"
    />,
  ],
});
