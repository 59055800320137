import React, { useCallback, useRef, useState } from 'react';
import {
  Modal,
  Box,
  Text,
  Avatar,
  VStack,
  Pressable,
  FlatList,
  WithKNOWLoader,
  // useIsMobile,
  InputFilter,
} from '@know/ui';

import type { SelectUsersItem } from './types';

interface SelectUsersModalProps {
  isOpen: boolean;
  title?: string;
  isLoading?: boolean;
  options?: SelectUsersItem[];
  isMulti?: boolean;
  onClose: () => void;
  onFilterChange?: (
    filter: string,
    filterChangeAdditionalProps?: any
  ) => void | Promise<void>;
  onSubmit?: (items: SelectUsersItem[]) => void;
}

const SelectUsersItemComponent = ({
  item,
  onPress,
}: {
  item: SelectUsersItem;
  onPress: () => void;
}) => {
  return (
    <Pressable
      display={'flex'}
      flexDir={'row'}
      alignItems={'center'}
      py={'10px'}
      px={'16px'}
      onPress={onPress}
      _hover={{
        bg: 'gray.250',
      }}
    >
      <Avatar
        size={'48px'}
        source={{ uri: item.avatar }}
        bg={'primary.500'}
        mr={'10px'}
      >
        <Text color={'white'} fontWeight={600}>
          {item.firstName.charAt(0).toUpperCase()}
          {item.lastName.charAt(0).toUpperCase()}
        </Text>
      </Avatar>
      <VStack>
        <Text fontWeight={600} fontSize={'14px'}>
          {item.userName}
        </Text>
        <Text fontSize={'12px'}>
          {item.designation}, {item.department}
        </Text>
        <Text fontSize={'12px'}>{item.location ?? 'No Location'}</Text>
      </VStack>
    </Pressable>
  );
};

const Separator = () => <Box h={'1px'} w={'100%'} bg={'gray.300'} />;

export const SelectUsersModal = ({
  isOpen,
  title,
  options = [],
  isLoading = false,
  isMulti = false,
  onClose,
  onFilterChange,
  onSubmit,
}: SelectUsersModalProps) => {
  // const isMobile = useIsMobile();
  const [filter, setFilter] = useState<string>('');

  const _onFilterChange = useCallback(
    (f: string) => {
      setFilter(f);
      onFilterChange && onFilterChange(f);
    },
    [onFilterChange]
  );

  const onPressItem = (item: SelectUsersItem) => {
    if (!isMulti) {
      onSubmit && onSubmit([item]);
      onClose();
    }
  };

  const renderItem = ({ item }: { item: SelectUsersItem }) => {
    return (
      <SelectUsersItemComponent item={item} onPress={() => onPressItem(item)} />
    );
  };

  const focusRef = useRef<any>(null);

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
      closeOnOverlayClick
      initialFocusRef={focusRef}
    >
      <Modal.Content maxH={'80%'}>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0}>
          {title || 'Search users'}
        </Modal.Header>
        <Modal.Footer>
          <Box w={'100%'}>
            <InputFilter
              hasFilter={true}
              onChange={_onFilterChange}
              filterChangeDelay={300}
              inputRef={focusRef}
            />
          </Box>
        </Modal.Footer>
        <Modal.Body px={0} pt={0}>
          <WithKNOWLoader isLoading={isLoading} py={'12px'}>
            {!options.length ? (
              <Box
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
                px={'16px'}
              >
                {filter ? (
                  <Text textAlign={'center'} flex={1}>
                    No results found.
                  </Text>
                ) : (
                  <Text fontWeight={600} textAlign={'center'} flex={1}>
                    Search by name, department or designation
                  </Text>
                )}
              </Box>
            ) : (
              <Box w={'100%'}>
                <FlatList
                  data={options}
                  renderItem={renderItem}
                  keyExtractor={(item, index) =>
                    item.userId + Date.now() + index
                  }
                  ItemSeparatorComponent={Separator}
                />
              </Box>
            )}
          </WithKNOWLoader>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
