//TODO: make a native app friendly version
import React from 'react';
import {
  Box,
  KNOWButton as Button,
  ExpoFA5Icon,
  LocationLeaderIcon,
  Modal,
  // ShareIcon,
  SuccessIcon,
  Text,
  View,
} from '@know/ui';

interface SuccessModalProps {
  isOpen: boolean;
  cancel?: () => void;
  share?: () => void;
  print?: () => void;
  shareToLocationLeader?: () => void;
  isSharingToLocationLeader?: boolean;
}

export const SuccessModal = ({
  isOpen,
  cancel,
  // share,
  print,
  shareToLocationLeader,
  isSharingToLocationLeader,
}: SuccessModalProps) => {
  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={cancel} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
          <Box display={'flex'} flexDir={'row'} alignItems={'center'}>
            <SuccessIcon mr="6px" />{' '}
            <Text fontWeight={600} fontSize={'18px'}>
              Success!
            </Text>
          </Box>
        </Modal.Header>
        <Modal.Body py={0}>
          <View width="100%">
            <Text fontSize={'16px'} color={'gray.700'}>
              The form has been successfully submitted.
            </Text>
          </View>
        </Modal.Body>
        <Modal.Footer
          borderTopWidth={0}
          display={'flex'}
          flexDirection={'column'}
        >
          <Button
            colorScheme="primary"
            onPress={cancel}
            w={'100%'}
            borderWidth={'0px'}
          >
            OK
          </Button>
          {/* {share ? (
            <Button
              colorScheme="primary"
              onPress={share}
              w={'100%'}
              mb={'10px'}
              leftIcon={<ShareIcon />}
            >
              Share
            </Button>
          ) : null} */}
          {print ? (
            <Button
              colorScheme="secondary"
              onPress={print}
              w={'100%'}
              // mb={'10px'}
              leftIcon={
                <ExpoFA5Icon name="file-pdf" color="primary.500" mr={'-6px'} />
              }
              mt={'10px'}
            >
              Make PDF
            </Button>
          ) : null}
          {shareToLocationLeader ? (
            <Button
              colorScheme="secondary"
              onPress={shareToLocationLeader}
              w={'100%'}
              // mb={'10px'}
              leftIcon={
                <LocationLeaderIcon
                  size="20px"
                  color="primary.500"
                  mr={'-6px'}
                />
              }
              mt={'10px'}
              isLoading={isSharingToLocationLeader}
            >
              Share to Location Leaders
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
