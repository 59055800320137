import { Icon, IIconProps } from 'native-base';
import React from 'react';
import {
  FontAwesome,
  FontAwesome5,
  AntDesign,
  MaterialIcons,
  MaterialCommunityIcons,
  Ionicons,
  Entypo,
} from '@expo/vector-icons';

export const ExpoFAIcon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={FontAwesome} {...props} />;
};

export const ExpoFA5Icon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={FontAwesome5} {...props} />;
};

export const ExpoADIcon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={AntDesign} {...props} />;
};

export const ExpoMAIcon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={MaterialIcons} {...props} />;
};

export const ExpoMCIcon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={MaterialCommunityIcons} {...props} />;
};

export const ExpoIonicIcon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={Ionicons} {...props} />;
};

export const ExpoEntypoIcon = (
  props: IIconProps & React.RefAttributes<unknown>
) => {
  return <Icon as={Entypo} {...props} />;
};
