import { selector } from '@know/ui';
import { prepareUserDraftsData } from '@know/transformers';

import {
  userFormFeedItemsAtom,
  formDraftsAtom,
  draftSectionsExpandStateAtom,
  draftsListFilterTextAtom,
} from './atoms';

import type { ISectionDataProps, IDraftItem } from '@know/transformers';

type Item = IDraftItem;

// Define the selector
// export const userFormDraftsDataSelector = selector<ISectionDataProps<Item>[]>({
//   key: 'userFormDraftsDataSelector',
//   get: ({ get }) => {
//     const formFeedItems = get(userFormFeedItemsAtom);
//     const userFormDrafts = get(formDraftsAtom);
//     const filterText = get(draftsListFilterTextAtom);

//     return prepareUserDraftsData({
//       formFeedItems,
//       userFormDrafts,
//       filterText,
//     });
//   },
// });

export const userDraftsSectionDataSelectorWithToggleState = selector<
  ISectionDataProps<Item>[]
>({
  key: 'userDraftsSectionDataSelectorWithToggleState',
  get: ({ get }) => {
    const formFeedItems = get(userFormFeedItemsAtom);
    const userFormDrafts = get(formDraftsAtom);
    const filterText = get(draftsListFilterTextAtom);

    const userDraftSectionData = prepareUserDraftsData({
      formFeedItems,
      userFormDrafts,
      filterText,
    });
    const toggleState = get(draftSectionsExpandStateAtom);

    return userDraftSectionData.map((sectionItem) => {
      return {
        ...sectionItem,
        isExpanded: toggleState[sectionItem.id || sectionItem.type],
      };
    });
  },
});
