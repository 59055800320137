import React, { useState } from 'react';
import {
  useRoute,
  View,
  FormWebview,
  WithKNOWLoader,
  Text,
  IconButton,
  CloseIcon,
  SaveDraftModal,
  SuccessModal,
  LocationPermissionModal,
  EllipsisMenu,
  ReturnFormModal,
} from '@know/ui';

import { useFormWebView } from './useFormWebview';

export const linking = {
  path: 'forms/webview/:formId/:type/:initiatorId/:responseId',
};

export function FormWebViewPage() {
  const route = useRoute();
  //@ts-ignore
  const formId = route?.params?.formId!;
  //@ts-ignore
  const type = route?.params?.type!;
  //@ts-ignore
  const responseId = type === 'new' ? undefined : route?.params?.responseId!;
  //@ts-ignore
  const initiatorId = type === 'new' ? undefined : route?.params?.initiatorId!;
  //@ts-ignore
  const parentId = route?.params?.parentId!;
  //@ts-ignore
  const parentShareId = route?.params?.parentShareId!;
  //@ts-ignore
  const parentType = route?.params?.parentType!;
  //@ts-ignore
  const groupId = route?.params?.groupId!;
  //@ts-ignore
  const questionId = route?.params?.questionId!;
  //@ts-ignore
  const skipDraft = route?.params?.skipDraft!;

  const {
    formNugget,
    isLoading,
    buttonLabels,
    locations,
    userInfo,
    webViewUrl,
    isDraftModalOpen,
    draftModalParams,
    formWebViewRef,
    isSuccessModalOpen,
    reviewMode,
    formResponse,
    isLocationPermissionModalOpen,
    locationPermissionModalProps,
    ellipsisOptions,
    isReturnFormModalOpen,
    isReturningFormResponse,
    returnFormModalProps,
    canShareForm,
    isSharingToLocationLeader,
    taskDetails,
    closeReturnFormModalParams,
    goBack,
    uploadFiles,
    submitResponse,
    writeResponses,
    searchUsers,
    sendToNextResponse,
    onDataWrite,
    onConfirmSuccessModal,
    onPrintSuccessModal,
    shareToLocationLeader,
    openTask,
  } = useFormWebView(formId, {
    responseId,
    initiatorId,
    type,
    parentId,
    parentType,
    parentShareId,
    groupId,
    questionId,
    skipDraft: !!skipDraft,
  });

  const [topBarWidth, setTopBarWidth] = useState<number>(0);

  return (
    <View
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      bg={'white'}
    >
      <WithKNOWLoader isLoading={isLoading} size="lg">
        <View
          p={'12px'}
          bg={'white'}
          w={'100%'}
          position={'relative'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          borderBottomColor={'gray.300'}
          borderBottomWidth={'1px'}
          onLayout={(e) => {
            setTopBarWidth(e.nativeEvent.layout.width);
          }}
        >
          <IconButton
            width={'30px'}
            height={'30px'}
            onPress={goBack}
            icon={<CloseIcon size={'12px'} />}
            position={'absolute'}
            left={'12px'}
            _icon={{
              color: 'gray.900',
            }}
          />
          <Text
            fontSize={'16px'}
            fontWeight={600}
            textAlign={'center'}
            noOfLines={1}
            maxW={`${topBarWidth - 30 - 12 - 30 - 12 - 10}px`}
          >
            {formNugget?.name ?? ''}
          </Text>
          <View
            position={'absolute'}
            right={'12px'}
            width={'30px'}
            display={'flex'}
            justifyContent={'center'}
            flexDir={'row'}
          >
            <EllipsisMenu options={ellipsisOptions} />
          </View>
        </View>
        <FormWebview
          url={webViewUrl}
          userInfo={userInfo}
          formDefinition={formNugget}
          formResponses={formResponse}
          buttonLabels={buttonLabels}
          locations={locations}
          reviewMode={reviewMode}
          uploadFiles={uploadFiles}
          writeResponses={writeResponses}
          submit={submitResponse}
          getUsersOptions={searchUsers}
          sendToNext={sendToNextResponse}
          ref={formWebViewRef}
          onDataWrite={onDataWrite}
          openTask={openTask}
          taskDetails={taskDetails}
        />
        {isDraftModalOpen ? (
          <SaveDraftModal isOpen={isDraftModalOpen} {...draftModalParams} />
        ) : null}
        {isSuccessModalOpen ? (
          <SuccessModal
            isOpen={isSuccessModalOpen}
            cancel={onConfirmSuccessModal}
            share={canShareForm ? onConfirmSuccessModal : undefined}
            print={canShareForm ? onPrintSuccessModal : undefined}
            shareToLocationLeader={
              canShareForm ? shareToLocationLeader : undefined
            }
            isSharingToLocationLeader={isSharingToLocationLeader}
          />
        ) : null}
        {isLocationPermissionModalOpen ? (
          <LocationPermissionModal
            isOpen={isLocationPermissionModalOpen}
            {...locationPermissionModalProps}
          />
        ) : null}
        {isReturnFormModalOpen ? (
          <ReturnFormModal
            isOpen={isReturnFormModalOpen}
            isLoading={isReturningFormResponse}
            cancel={closeReturnFormModalParams}
            {...returnFormModalProps}
          />
        ) : null}
      </WithKNOWLoader>
    </View>
  );
}
