import { Text, HStack, KNOWIcon, KNPressable, Pluralize, Box } from '.';

import React from 'react';
import { useKNOWValueContextProvider } from '..';

export interface IFolderItem {
  count?: number;
  id: string;
  name: string;
}

export interface IFormFolderItemProps {
  type: string;
  item: IFolderItem;
  onPress?: (item: IFolderItem, type: string) => void;
}

export function FormFolderItem({ onPress, item, type }: IFormFolderItemProps) {
  function handleOnPress() {
    onPress && onPress(item, type);
  }

  const { isDesktopSize } = useKNOWValueContextProvider();

  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        pl="24px"
        pr={'20px'}
        py={'12px'}
      >
        <KNOWIcon
          md
          color={'gray.700'}
          mr={2}
          name={'folder'}
          size="5"
          textAlign="center"
        />
        <Box
          display={'flex'}
          flexDir={isDesktopSize ? 'row' : 'column'}
          alignItems={isDesktopSize ? 'flex-start' : undefined}
          flex={1}
        >
          <Text
            fontFamily={'openSemiBold'}
            fontWeight={'semibold'}
            display={'flex'}
            alignItems={'center'}
            color={'gray.900'}
            mr={isDesktopSize ? '12px' : undefined}
          >
            {item.name}
          </Text>
          <Text
            fontFamily={'openSemiBold'}
            mt={isDesktopSize ? undefined : '2px'}
            display={'flex'}
            alignItems={'center'}
            color={'gray.700'}
            fontWeight={400}
            w={'100px'}
          >
            <Pluralize singular="Item" count={item.count} />
          </Text>
        </Box>
      </HStack>
    </KNPressable>
  );
}
