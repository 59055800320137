import React from 'react';

import type { IDraftItem, ISectionDataProps, Item } from '@know/transformers';
import {
  CollapsibleSectionHeader,
  HStack,
  useSelectableSectionsView,
  FormDraftItem,
} from '@know/ui';

import type { JSXElementConstructor, ReactElement } from 'react';

export const keyExtractor = function (item: Item): string {
  return item.id;
};

export const getSectionHeaderComponent = (info: {
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return (
    <CollapsibleSectionHeader
      id={info.section.id || info.section.type}
      title={info.section.title}
    />
  );
};

export const ItemComponent = (info: {
  item: IDraftItem;
  section: ISectionDataProps<Item>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  const { onClickItem, onClickDelete } = useSelectableSectionsView();

  return (
    <HStack alignItems={'center'} flex={1} justifyContent={'flex-start'}>
      <FormDraftItem
        onPress={handleOnClick}
        item={info.item}
        type={info.section.type}
        onDelete={() => onClickDelete && onClickDelete(info.item, info.section)}
      />
    </HStack>
  );
  function handleOnClick() {
    onClickItem(info.item, info.section.type, info.section);
  }
};
