import React from 'react';

import { KNOWRouterContenxt, IModuleProps } from '@know/ui';
import { MyCoursesNavigator, linking } from './MyCoursesNavigator';

export const CoursesScreen = () => {
  const { registerScreen } = React.useContext(KNOWRouterContenxt);

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'lms',
      name: 'Learn',
      priority: 1,
      component: MyCoursesNavigator,
      icon: 'book',
      linking,
    };
    registerScreen(module);
  }, [registerScreen]);

  return null;
};
