import moment from 'moment';

function getOS() {
  if (navigator.userAgent.match(/Linux/i)) {
    return 'Linux';
  } else if (navigator.userAgent.match(/Windows/i)) {
    return 'Windows';
  } else if (navigator.userAgent.match(/Mac/i)) {
    return 'MacOS';
  }
}

function convertToSeconds(duration: number) {
  const toDecimals = Math.floor(
    (duration / 60 - Math.floor(duration / 60)) * 60
  );
  return {
    totalDuration: Math.floor(duration / 60) + toDecimals / 100,
    min: Math.floor(duration / 60),
    sec: toDecimals < 10 ? `0${toDecimals}` : toDecimals,
  };
}

function getFormattedTime(duration: number, format?: string) {
  return moment()
    .startOf('day')
    .seconds(duration)
    .format(format ?? getFormat(duration));
}

const getFormat = (sec: number) => {
  if (sec > 3599) {
    return 'hh:mm';
  } else if (sec > 59) {
    return 'm:ss';
  }
  return 'ss';
};

const getTimeString = (sec: number) => {
  if (sec > 3599) {
    return 'hours';
  } else if (sec > 59) {
    return 'minutes';
  }
  return 'seconds';
};

const sleep = (ms: number) =>
  new Promise(async (res) => {
    setTimeout(res, ms);
  });

export {
  getOS,
  getFormattedTime,
  getFormat,
  getTimeString,
  convertToSeconds,
  sleep,
};
