import React from 'react';
import {
  TextOnly,
  QuizIntro,
  QuizMCQ,
  TextImagePDF,
  FullScreenImage,
  QuizResult,
  IScoreCard,
  VideoTextPDF,
  FullScreenVideo,
  YoutubeVideoTextPDF,
  AudioImageTextPDF,
  SCORMOnly,
  ContentNotSupported,
  useRecoilValue,
  HotspotTraining,
  Survey,
} from '@know/ui';
import type { CourseTabType, ILessonCard } from '../types';
import { isVideoAutoPlayedAtom } from '../state';

export const LessonTypes: React.FC<{
  card: ILessonCard;
  isQuizIntroSeen: boolean;
  isQuizFinished: boolean;
  attempted: number;
  scoreCard: IScoreCard;
  quizResultAnswers: any;
  handleSubmitQuiz: (action?: 'retry') => Promise<void>;
  handleImageConsumed: (index: number) => void;
  handleVideoConsumed: ({
    consumeTime,
    isVideoEnd,
  }: {
    consumeTime: number;
    isVideoEnd: boolean;
  }) => void;
  handleAudioConsumed: ({
    consumeTime,
    isAudioEnd,
  }: {
    consumeTime: number;
    isAudioEnd: boolean;
  }) => void;
  currentCourseTab: CourseTabType;
  handlePDFConsumed: (index: number) => void;
  handleHotspotConsumed: () => void;
  handleQuizResponses: (params: {
    selectedIndex: number;
    selectedOption: number | any[];
  }) => void;
  handleScromProgress: (event: {
    data: {
      eventType: 'progress' | 'completed';
      progress: string;
    };
  }) => Promise<void>;
  scromProgress: any;
  handleSurveyResponses: (answer: any) => void;
}> = ({
  card,
  isQuizIntroSeen,
  isQuizFinished,
  attempted,
  scoreCard,
  quizResultAnswers,
  scromProgress,
  currentCourseTab,
  handleImageConsumed,
  handleVideoConsumed,
  handleAudioConsumed,
  handleSubmitQuiz,
  handleQuizResponses,
  handlePDFConsumed,
  handleScromProgress,
  handleHotspotConsumed,
  handleSurveyResponses,
}) => {
  const isVideoAutoPlayed = useRecoilValue(isVideoAutoPlayedAtom);
  if (card.type === 'text-only') {
    return (
      <TextOnly textOnlyObj={card} handlePDFConsumed={handlePDFConsumed} />
    );
  }

  if (card.type === 'quiz') {
    if (isQuizIntroSeen && !isQuizFinished) {
      return (
        <QuizMCQ
          card={card}
          handleSubmitQuiz={handleSubmitQuiz}
          handleQuizResponses={handleQuizResponses}
        />
      );
    }
    if (isQuizFinished) {
      return (
        <QuizResult
          card={card}
          attempted={attempted}
          scoreCard={scoreCard}
          quizResultAnswers={quizResultAnswers}
          handleSubmitQuiz={handleSubmitQuiz}
          handleQuizResponses={handleQuizResponses}
          hidePassFailStatus={card?.disableInstruction}
        />
      );
    }

    if (card.disableInstruction) {
      return (
        <QuizMCQ
          card={card}
          handleSubmitQuiz={handleSubmitQuiz}
          handleQuizResponses={handleQuizResponses}
        />
      );
    }

    return <QuizIntro card={card} attempted={attempted} />;
  }

  if (card.type === 'image-text') {
    return (
      <TextImagePDF
        textImagePdfObj={card}
        handleImageConsumed={handleImageConsumed}
        handlePDFConsumed={handlePDFConsumed}
      />
    );
  }

  if (card.type === 'banner-image') {
    return (
      <FullScreenImage
        fullScreenImageObj={card}
        handleImageConsumed={handleImageConsumed}
      />
    );
  }

  if (card.type === 'audio-only' || card.type === 'image-audio') {
    return (
      <AudioImageTextPDF
        audioImageTextPdfObj={card}
        handleAudioConsumed={handleAudioConsumed}
        handleImageConsumed={handleImageConsumed}
        handlePDFConsumed={handlePDFConsumed}
      />
    );
  }

  if (card.type === 'video-text') {
    return (
      <VideoTextPDF
        videoTextPdfObj={card}
        isVideoAutoPlayed={isVideoAutoPlayed}
        handleVideoConsumed={handleVideoConsumed}
        handlePDFConsumed={handlePDFConsumed}
      />
    );
  }

  if (card.type === 'video-only') {
    return (
      <FullScreenVideo
        fullScreenVideoObj={card}
        isVideoAutoPlayed={isVideoAutoPlayed}
        handleVideoConsumed={handleVideoConsumed}
      />
    );
  }

  if (card.type === 'youtube') {
    return (
      <YoutubeVideoTextPDF
        youtubeVideoTextPdfObj={card}
        isVideoAutoPlayed={isVideoAutoPlayed}
        handleVideoConsumed={handleVideoConsumed}
        handlePDFConsumed={handlePDFConsumed}
      />
    );
  }

  if (card.type === 'scorm-lms') {
    return (
      <SCORMOnly
        scromObj={card}
        handleScromProgress={handleScromProgress}
        scromProgress={scromProgress}
      />
    );
  }

  if (card.type === 'hotspot') {
    return (
      <HotspotTraining
        hotspotTrainingObj={card}
        handleHotspotConsumed={handleHotspotConsumed}
      />
    );
  }

  if (card.type === 'survey') {
    return (
      <Survey
        surveyObj={card}
        handleSurveyResponses={handleSurveyResponses}
        isCompletedTab={currentCourseTab === 'completed-courses'}
      />
    );
  }

  return <ContentNotSupported />;
};
