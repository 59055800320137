import { useContext, useEffect } from 'react';

import {
  KNOWDBContext,
  useKNOWValueContextProvider,
  useNavigation,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from '@know/ui';

import {
  formsCategoriesAtom,
  newFormSectionDataSelectorWithToggleState,
  sectionsExpandStateAtom,
  newFormFilterTextAtom,
  isFormFeedLoadingAtom,
} from '../store';

import { raiseAnalytics } from '@know/db';
import type { Item } from '@know/transformers';

export function useNewForms() {
  const navigation = useNavigation();
  const { orgConfig, currentDBApi } = useContext(KNOWDBContext);
  const { isTabletSize, isLargePhoneSize, isPhoneSize } =
    useKNOWValueContextProvider();

  const isDataLoading = useRecoilValue(isFormFeedLoadingAtom);

  const setFormCategories = useSetRecoilState(formsCategoriesAtom);
  const setSectionsExpandState = useSetRecoilState(sectionsExpandStateAtom);
  const [filterText, onFilterTextChange] = useRecoilState(
    newFormFilterTextAtom
  );

  const newFormSectionData = useRecoilValue(
    newFormSectionDataSelectorWithToggleState
  );

  useEffect(() => {
    setFormCategories(orgConfig?.formCategories ?? {});
  }, [orgConfig?.formCategories, setFormCategories]);

  function onToggleSection(id: string) {
    setSectionsExpandState((state) => {
      return {
        ...state,
        [id]: !state[id],
      };
    });
  }

  function onClickItem(item: Item, type: string) {
    if (type === 'folder') {
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'folder',
          params: {
            folderId: item.id,
          },
          initial: false,
        } as never
      );
    } else if (type === ('feed' as never)) {
      if (currentDBApi) {
        //
        // Handling isRead writing for now.
        // For any enhancements we can do inside raiseAnalytics function
        //
        raiseAnalytics({
          fbAPI: currentDBApi,
          formItem: item,
          type: 'opened',
        } as any);
      }
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'webview',
          params: {
            formId: item.id,
            type: 'new',
            responseId: '-',
            initiatorId: '-',
          },
          initial: false,
        } as never
      );
    }
    // Handle onClickEvent
  }

  return {
    isDataLoading,
    newFormSectionData,
    filterText,
    isSmallScreen: isTabletSize || isLargePhoneSize || isPhoneSize,
    onToggleSection,
    onClickItem,
    onFilterTextChange,
  };
}
