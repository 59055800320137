import React, { useRef, useState } from 'react';
import {
  View,
  Text,
  useBreakpointValue,
  StackHeaderProps,
  WithKNOWLoader,
  ScrollView,
  FlatList,
  JourneyCourseList,
  useRecoilValue,
  LanguageSelectorModal,
  useMediaQuery,
} from '@know/ui';
import moment from 'moment';
import { get } from 'lodash';

import { useJourneyDetailsFeed } from './useJourneyDetailsFeed';
import { useCourseFeed } from '../MyCoursesPage/useCourseFeed';
import { Pressable } from 'react-native';
import { currentCourseTabAtom, isJourneyConsumedAtom } from '../state';
import { JourneyDetailsScreenHeader } from './JourneyDetailsScreenHeader';
import { useLanguageSwitch } from './useLanguageSwitch';

export const ljLinking = {
  path: 'lms/journey-details/journey/:journeyId',
};

const INIT_TOOLTIP_STATE = {
  tooltipIndex: null,
  tooltipTitle: '',
  tooltipDescription: '',
};
export const JourneyDetailsScreen = ({ route }: StackHeaderProps) => {
  const params: any = route?.params ?? {};
  const journeyId = params.journeyId;
  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const isJourneyConsumed = useRecoilValue(isJourneyConsumedAtom);
  const showLockFlag = useRef<number>(0);
  const [tooltipClicked, setTooltipClicked] = useState<any>(INIT_TOOLTIP_STATE);
  const [resumeButtonTooltip, setResumeButtonTooltip] = useState<any>(false);
  const { cardInfo }: any = useCourseFeed(journeyId);

  const {
    isLoading,
    languagesInfo,
    journeyCoursePayload,
    handleResumeCourse,
    progressLMS,
    isJourneyBegin,
    handleJourney,
  } = useJourneyDetailsFeed(cardInfo, journeyId);

  const journeyProgress = progressLMS || {};

  const hPadding = useBreakpointValue({
    base: '12px',
    sm: '12px',
    md: '12px',
    lg: '15%',
    xl: '20%',
  });

  const courses: any =
    Object.values(journeyCoursePayload)?.sort(
      (a: any, b: any): number => a?.seq - b?.seq
    ) || [];

  const showLockTooltip = (
    index: number,
    isBeforeCurrentTime: boolean,
    unlockDate: string
  ) => {
    if (isBeforeCurrentTime) {
      setTooltipClicked({
        ...tooltipClicked,
        tooltipIndex: index,
        tooltipTitle: 'This course is not available yet.',
        tooltipDescription: `This course will only be available on ${unlockDate}`,
      });
    } else {
      setTooltipClicked({
        ...tooltipClicked,
        tooltipIndex: index,
        tooltipTitle: 'Previous lessons are incomplete',
        tooltipDescription:
          'Please complete all the previous lessons in order to access this lesson.',
      });
    }

    setTimeout(() => {
      setTooltipClicked(INIT_TOOLTIP_STATE);
    }, 3000);
  };

  const {
    ljModalVisible,
    handleChangeLanguage,
    handleToggleModal,
    selectedLanguage,
  } = useLanguageSwitch(journeyId, languagesInfo);

  const [isSmallDevice] = useMediaQuery({ maxWidth: 430 });

  const showLock = (
    course: any,
    _isCompleted: boolean,
    formattedCurrentTime: string,
    formattedCurrentDate: string
  ) => {
    if (course) {
      const { seq, unlockTime, unlockValue } = course;
      const formatedUnlockTime = moment(
        new Date(cardInfo?.userFeedCreatedAt)
      ).format('YYYYMMDD');

      const unlockDay = moment(formatedUnlockTime)
        .add(unlockValue, 'days')
        .format('YYYYMMDD');

      if (showLockFlag.current === 1) {
        return true;
      }
      if (unlockDay > formattedCurrentDate) {
        showLockFlag.current = 1;
        return true;
      }
      if (
        unlockDay === formattedCurrentDate &&
        unlockTime > formattedCurrentTime
      ) {
        showLockFlag.current = 1;
        return true;
      }
      if (seq === 0) {
        if (!_isCompleted) {
          showLockFlag.current = 1;
        }
        return false;
      }
      if (!_isCompleted) {
        if (showLockFlag.current === 1) {
          return true;
        } else {
          showLockFlag.current = 1;
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  };

  const isCompleted = (course: any) => {
    const courseProgress = get(
      journeyProgress,
      `${cardInfo?.shareId}.${course?.courseId}`,
      {}
    );

    return (
      currentCourseTab === 'completed-courses' ||
      courseProgress?.hasOwnProperty('consumedAt')
    );
  };

  const handleShowLock = () => {
    let mappedCourses: any = courses;
    const formattedCurrentTime = moment(new Date()).format('HH:mm');
    const formattedCurrentDate = moment(new Date()).format('YYYYMMDD');
    for (const course of courses) {
      mappedCourses[course?.seq] = {
        ...course,
        showLock: showLock(
          course,
          isCompleted(course),
          formattedCurrentTime,
          formattedCurrentDate
        ),
      };
    }
    showLockFlag.current = 0;
    return mappedCourses;
  };

  return (
    <WithKNOWLoader
      isLoading={isLoading || isJourneyConsumed}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <ScrollView flex={1} bgColor="white">
        <JourneyDetailsScreenHeader
          cardInfo={cardInfo}
          journeyId={journeyId}
          langauges={languagesInfo.languages}
          handleJourney={handleJourney}
          isJourneyBegin={isJourneyBegin}
          resumeButtonTooltip={resumeButtonTooltip}
        />
        <View flex={1} marginTop="10px" backgroundColor="white">
          <View style={{ paddingHorizontal: hPadding }}>
            {!!cardInfo?.description && (
              <View>
                {' '}
                <View marginTop="5px" marginBottom="15px">
                  <Text fontWeight="semibold" color="black" fontSize="lg">
                    About the learning journey
                  </Text>
                </View>
                {!!cardInfo?.description && (
                  <Text color="black" fontSize="16px">
                    {cardInfo?.description}
                  </Text>
                )}
              </View>
            )}

            <View marginTop="15px" marginBottom="15px">
              <Text fontWeight="semibold" color="black" fontSize="lg">
                Courses
              </Text>
            </View>

            <FlatList
              scrollEnabled={false}
              data={handleShowLock()}
              keyExtractor={(item: any) => item?.courseId}
              renderItem={({ item, index }: any) => {
                const courseProgress = get(
                  journeyProgress,
                  `${cardInfo?.shareId}.${item?.courseId}`,
                  {}
                );
                const completedLessons = Object.values(courseProgress)?.filter(
                  (course: any) => course?.hasOwnProperty('consumedAt')
                );
                let progress = 0;
                if (
                  completedLessons?.length > 0 &&
                  journeyCoursePayload[item?.courseId]?.totalLessons > 0
                ) {
                  progress = Math.round(
                    (completedLessons?.length /
                      journeyCoursePayload[item?.courseId]?.totalLessons) *
                      100
                  );
                }
                const prevItemCourseId = handleShowLock()[index - 1]?.courseId;
                const isPreviousCompletedStatus = get(
                  journeyProgress,
                  `${cardInfo?.shareId}.${prevItemCourseId}`,
                  {}
                )?.hasOwnProperty('consumedAt');

                const formatedUnlockTime = moment(
                  new Date(cardInfo.userFeedCreatedAt)
                ).format('YYYYMMDD');

                const unlockDay = moment(formatedUnlockTime)
                  .add(item?.unlockValue, 'days')
                  .format('DD-MMM');

                const formattedCurrentDate = moment(new Date()).format(
                  'DD-MMM, HH:mm'
                );
                const isBeforeCurrentTime = moment(
                  formattedCurrentDate
                ).isBefore(`${unlockDay}, ${item?.unlockTime}`);

                if (
                  item.showLock &&
                  ((prevItemCourseId && isPreviousCompletedStatus) ||
                    !prevItemCourseId) &&
                  !resumeButtonTooltip
                ) {
                  setResumeButtonTooltip({
                    title: 'This course is not available yet.',
                    description: `This course will only be available on ${unlockDay}, ${item?.unlockTime}`,
                  });
                }

                return (
                  <Pressable
                    key={item.id}
                    onPress={() =>
                      item?.showLock !== true
                        ? handleResumeCourse({
                            courses: { [item?.courseId]: item },
                            progress: progress,
                          })
                        : showLockTooltip(
                            index,
                            isBeforeCurrentTime,
                            `${unlockDay}, ${item?.unlockTime}`
                          )
                    }
                  >
                    <JourneyCourseList
                      index={index}
                      course={item}
                      isSmallDevice={isSmallDevice}
                      completed={isCompleted(item)}
                      progress={progress}
                      previousCompletedStatus={isPreviousCompletedStatus}
                      showLockTooltip={tooltipClicked}
                      isLast={handleShowLock().length - 1 === index}
                      unLockDate={`${unlockDay}, ${item?.unlockTime}`}
                      isBeforeCurrentTime={isBeforeCurrentTime}
                    />
                  </Pressable>
                );
              }}
            />
          </View>
          <LanguageSelectorModal
            modalVisible={ljModalVisible}
            handleToggleModal={handleToggleModal}
            data={languagesInfo.languages}
            selectedLanguage={selectedLanguage}
            handleChangeLanguage={handleChangeLanguage}
          />
        </View>
      </ScrollView>
    </WithKNOWLoader>
  );
};
