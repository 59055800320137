import { get } from 'lodash';
import { useEffect, useContext, useState, useCallback } from 'react';
import {
  useRecoilState,
  KNOWDBContext,
  useSetRecoilState,
  useNavigation,
  useRecoilValue,
  useIsFocused,
} from '@know/ui';

import {
  currentCourseTabAtom,
  getProgressBeforeResetAtom,
  isJourneyConsumedAtom,
  journeyCoursePayloadAtom,
  journeyCoursesAtom,
  journeyLanguagesAtom,
  lmsReportsPayloadAtom,
  progressLMSOfCourseShareSelector,
  sessionIdAtom,
  userCompletedCourseFeedSelector,
  userCourseFeedSelector,
} from '../state';

import type { IJourneyCourses, ILMSProgressCourse } from '../types';
import useLMSAnalyticsCallback from '../CourseDetailsPage/useLMSAnalyticsCallback';

export const useJourneyDetailsFeed = (
  cardInfo: {
    miniThumbnail: string;
    title: string;
    totalCount: number;
    progress: number;
    type: string;
    languages: { description: string; locale: string; seq: 0; title: string }[];
    courseId: string;
    journeyId: string;
    description: string;
    selectedLanguage: string;
    shareId: string;
    finishedLessonsCount: number;
  },
  journeyId: string
) => {
  const { currentDBApi } = useContext(KNOWDBContext);
  const navigation = useNavigation();

  const currentCourseTab = useRecoilValue(currentCourseTabAtom);
  const isPageFocused = useIsFocused();

  const [isLoading, setIsLoading] = useState(true);
  const setJourneyCourses =
    useSetRecoilState<IJourneyCourses>(journeyCoursesAtom);
  const [progressLMS, setProgressLMS] = useRecoilState<ILMSProgressCourse>(
    progressLMSOfCourseShareSelector(journeyId)
  );
  const [isJourneyConsumed, setIsJourneyConsumed] = useRecoilState<boolean>(
    isJourneyConsumedAtom
  );
  const setProgressBeforeReset = useSetRecoilState<ILMSProgressCourse>(
    getProgressBeforeResetAtom
  );
  const [languagesInfo, setLanguages] = useRecoilState(journeyLanguagesAtom);
  const [journeyCoursePayload, setJourneyCoursePayload] = useRecoilState(
    journeyCoursePayloadAtom
  );
  const sessionId = useRecoilValue(sessionIdAtom);
  const courseProgress: any = get(progressLMS, [cardInfo?.shareId], {});
  const isJourneyBegin = Object.keys(courseProgress).length ?? 0;
  const userFeed = useRecoilValue(
    currentCourseTab === 'completed-courses'
      ? userCompletedCourseFeedSelector(journeyId)
      : userCourseFeedSelector(journeyId)
  );

  useEffect(() => {
    let journeyPayload: any = {};
    const getCoursePayload = async () => {
      if (currentDBApi) {
        const journeyCoursesValue = await currentDBApi.getLMSJourneyCourses(
          journeyId
        );
        setJourneyCourses(journeyCoursesValue?.courses);
        for (const key in journeyCoursesValue?.courses) {
          const feedItem = await currentDBApi.getLMSCourseDetails(key);
          let feedItemDetails = get(
            feedItem,
            ['details', 'languages', cardInfo.selectedLanguage],
            {}
          );
          const feedItemLanguages = get(feedItem, ['details', 'languages'], []);

          if (Object.keys(feedItemDetails).length === 0) {
            if (Object.keys(feedItemLanguages).includes('eng')) {
              feedItemDetails = get(
                feedItem,
                ['details', 'languages', 'eng'],
                {}
              );
            } else {
              feedItemDetails = get(
                feedItem,
                ['details', 'languages', Object.keys(feedItemLanguages)[0]],
                {}
              );
            }
          }

          const feedItemPayload = get(
            feedItem,
            ['payload', cardInfo.selectedLanguage, 'modules'],
            {}
          );
          const isSequence = get(feedItem, [
            'payload',
            cardInfo.selectedLanguage,
            'isSequence',
          ]);

          Object.assign(journeyPayload, {
            [key]: {
              ...feedItemDetails,
              ...journeyCoursesValue?.courses?.[key],
              courseId: key,
              isSequence,
              feedItemLanguages,
              createdAt: journeyCoursesValue?.createdAt,
              totalLessons: Object.keys(feedItemPayload)?.length,
              details: { ...journeyCoursesValue },
            },
          });
        }
        setJourneyCoursePayload(journeyPayload);
      }
      setIsLoading(false);
      const courses: any =
        Object.values(journeyPayload)?.sort(
          (a: any, b: any): number => a?.seq - b?.seq
        ) || [];
      if (courses.length > 0) {
        const details = journeyPayload?.[courses?.[0]?.courseId];
        const courseDetails = {
          ...details,
          details: details?.details,
        };
        const languages = get(details, ['feedItemLanguages'], {});
        const isLangExists = Object.keys(languages).includes(userFeed?.lang!);
        const language = isLangExists
          ? userFeed?.lang
          : courseDetails?.details?.defaultLanguage ?? 'eng';
        const languageObj = {
          languages: Object.values(languages).sort(
            (a: any, b: any) => a.seq - b.seq
          ),
          hasDefaultLanguage: ['', null, undefined].some(
            (el: any) => userFeed?.lang?.includes(el) || false
          ),
          selectedLanguage: language,
        };
        setLanguages(languageObj);
      }
    };
    getCoursePayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setJourneyCourses, setJourneyCoursePayload, setLanguages]);

  useEffect(() => {
    const totalCourses = Object.keys(journeyCoursePayload)?.length || 0;
    if (journeyId && totalCourses > 0 && !isLoading) {
      const getProgressLMS = async () => {
        await currentDBApi?.listenForProgressLMS(async (data: any) => {
          const [progressType, journeyProgress] = data;
          if (progressType === 'progressLMS') {
            if (Object.keys(journeyProgress || {}).length > 0) {
              setProgressBeforeReset(journeyProgress);
            }
            setProgressLMS(journeyProgress);
            const shareId =
              Object.keys(journeyProgress || {})?.[0] || cardInfo?.shareId;
            const _courseProgress = get(journeyProgress, [shareId], {});

            const consumedCourses =
              Object.values(_courseProgress || {}).filter((course: any) =>
                course.hasOwnProperty('consumedAt')
              )?.length || 0;

            if (
              isJourneyConsumed &&
              Object.keys(journeyProgress || {}).length > 0 &&
              totalCourses === consumedCourses &&
              totalCourses > 0 &&
              !journeyProgress?.hasOwnProperty('consumedAt')
            ) {
              const payload = {
                dt: Date.now(),
                lang: cardInfo?.selectedLanguage,
                sessionId,
                shareId: cardInfo?.shareId,
                journeyId,
              };
              currentDBApi?.setUserFeedCourseInfo('JourneyConsumed', payload);
              currentDBApi?.setUserFeedCourseInfo('JourneyCompleted', payload);
              currentDBApi?.setUserFeedCourseInfo(
                'MoveUserFeedToCompletedFeed',
                {
                  journeyId,
                  userFeed,
                }
              );
              setIsJourneyConsumed(false);
            }
          }
        }, journeyId);
      };
      getProgressLMS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProgressLMS, journeyCoursePayload]);

  const handleJourney = () => {
    // Begin Journey
    if (isJourneyBegin === 0) {
      let firstCourse: any = Object.values(journeyCoursePayload)
        .sort((a: any, b: any) => a.seq - b.seq)
        ?.find((ln: any) => ln.seq === 0);
      navigateCourse(firstCourse?.courseId);
    } else {
      // Resume Journey
      let courseAndProgressObj: any = { courses: {}, progress: {} };
      for (const [key, lessonValue] of Object.entries(
        journeyCoursePayload
      ) as any) {
        const progressValue = courseProgress[key];
        const course = lessonValue;
        const progress = get(progressValue, 'progress', {});

        // if course not started yet
        if (progressValue === undefined) {
          courseAndProgressObj = {
            courses: {
              ...courseAndProgressObj?.courses,
              [key]: course,
            },
            progress: {
              ...courseAndProgressObj?.progress,
            },
          };
        } else {
          // if course is not completed
          if (!progressValue?.hasOwnProperty('consumedAt')) {
            courseAndProgressObj = {
              courses: {
                ...courseAndProgressObj?.courses,
                [key]: course,
              },
              progress: {
                ...courseAndProgressObj?.progress,
                ...progress,
              },
            };
          }
        }
      }
      handleResumeCourse(courseAndProgressObj);
    }
  };

  const handleResumeCourse = async (courseAndProgressObj: any) => {
    let navigateToCourse: any = {};
    const sortedCourse: any =
      Object.values(courseAndProgressObj?.courses).length > 0
        ? Object.values(courseAndProgressObj?.courses).sort(
            (a: any, b: any) => a?.seq - b?.seq
          )
        : undefined;

    navigateToCourse = sortedCourse?.[0] ?? {};

    if (navigateToCourse !== undefined) {
      navigateCourse(navigateToCourse?.courseId);
    }
  };

  // LMS Analytics Reports Callback
  const {
    getTotalCards,
    progressLMS: progressLMSReport,
    isCourseCompleted,
  } = useLMSAnalyticsCallback(cardInfo);

  const [reportsPayload, setReportsPayload] = useRecoilState(
    lmsReportsPayloadAtom
  );

  const handleJourneyCourses = async (id: string) => {
    const payload = {};

    const courseDetailsById = await currentDBApi?.getLMSCourseDetails(id);
    const coursePayload = get(
      courseDetailsById,
      ['payload', cardInfo.selectedLanguage, 'modules'],
      {}
    );
    Object.assign(payload, { ...coursePayload });
    return payload;
  };
  const handleReportsPayload = async (_courseId: string) => {
    const { hasCourseProgress, totalCardsConsumed } = progressLMSReport(
      true,
      _courseId
    );
    let courseStatus: 'inProgress' | 'notStarted' | 'completed';
    switch (hasCourseProgress) {
      case true:
        courseStatus = 'inProgress';
        break;
      case false:
        courseStatus = 'notStarted';
        break;
      default:
        courseStatus = 'notStarted';
        break;
    }

    if (isCourseCompleted(_courseId, true)) {
      courseStatus = 'completed';
    }
    const data = await handleJourneyCourses(_courseId);
    const totalCards = getTotalCards(data);
    const payload = {
      courseId: _courseId,
      journeyId: cardInfo?.journeyId || null,
      shareId: cardInfo?.shareId,
      totalCards,
      languageSelected: cardInfo?.selectedLanguage,
      courseStatus, // inProgress, notStarted, completed
      totalCardsConsumed,
      sessionId,
      sessionStartTime: Date.now(),
      type: 'callback',
      cardsCompletedInCurrentSession: 0,
      cardsCompletedInCurrentSessionIds: {},
      dt: Date.now(),
    };
    setReportsPayload((prevState) => {
      const state = { ...prevState };
      state[_courseId] = payload;
      return state;
    });
  };

  const navigateCourse = async (_courseId: string) => {
    if (currentCourseTab !== 'completed-courses' && _courseId) {
      handleReportsPayload(_courseId);
    }
    navigation.navigate(
      'course-details' as never,
      {
        journeyId,
        courseId: _courseId,
        journeyLanguage: cardInfo?.selectedLanguage,
      } as never
    );
  };

  const handleLMSReportsPayload = useCallback(async () => {
    if (Object.keys(reportsPayload)?.length) {
      for (const iterator in reportsPayload) {
        await currentDBApi?.setUserFeedCourseInfo('LMSAnalyticsCallback', {
          analyticsCBPayload: {
            ...reportsPayload[iterator],
            sessionEndTime: Date.now(),
          },
        });
        setReportsPayload((prevState: any) => {
          const state = { ...prevState };
          delete state[iterator];
          return state;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsPayload]);

  useEffect(() => {
    if (isPageFocused && currentCourseTab !== 'completed-courses') {
      handleLMSReportsPayload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageFocused]);

  return {
    journeyCoursePayload,
    isLoading,
    isJourneyBegin,
    progressLMS,
    languagesInfo,
    handleJourney,
    handleResumeCourse,
  };
};
