import React from 'react';
import {
  View,
  Text,
  Image,
  FlatList,
  ScrollView,
  Button,
  AspectRatio,
} from '@know/ui';
import type { IQuizMCQ } from './types';
import Images from '../../../Images';
import { useBreakpointValue } from 'native-base';
import { HotspotQuestionView } from './Quiz/HotspotQuestionView';
import { isEqual, sortBy } from 'lodash';

export interface IANS {
  c: number;
  r: number;
}

export const QuizResult: React.FC<IQuizMCQ> = ({
  scoreCard,
  card,
  quizResultAnswers,
  handleSubmitQuiz,
  attempted = 0,
  hidePassFailStatus,
}) => {
  const isFailed = scoreCard?.correct! < card.passMark;
  const {
    showAnswer,
    payload: { questions },
  } = card || { showAnswer: false, payload: { questions: [] } };
  const remainingAttempts = card.attempts - attempted;

  const questionFontSize = useBreakpointValue({
    base: '13px',
    sm: '13px',
    md: '14px',
    lg: '15px',
    xl: '15px',
  });

  const verticalDivider = useBreakpointValue({
    base: '40px',
    sm: '40px',
    md: '40px',
    lg: '42px',
    xl: '42px',
  });

  const getImageSource = (
    isShowAnswer: boolean,
    optionIndex: number,
    answer: any,
    index: number,
    isCheckbox?: boolean
  ) => {
    if (isShowAnswer && remainingAttempts === 0) {
      if (isCheckbox) {
        const ansArray = answer.split(',');
        if (ansArray.includes(String(optionIndex + 1))) {
          return Images.CheckboxSelectedGreen;
        } else {
          return Images.CheckboxButton;
        }
      } else {
        if (String(optionIndex + 1) === answer) {
          return Images.RadioSelectedGreenBtn;
        }
        return Images.RadioBtn;
      }
    } else {
      if (
        isCheckbox &&
        Array.isArray(quizResultAnswers?.[index]?.r) &&
        quizResultAnswers?.[index]?.r?.includes(optionIndex + 1)
      ) {
        return Images.CheckboxSelectedBlack;
      } else {
        if (quizResultAnswers?.[index]?.r === String(optionIndex + 1)) {
          return Images.RadioBlackBtn;
        }
      }
      return isCheckbox ? Images.CheckboxButton : Images.RadioBtn;
    }
  };

  const getColor = (
    isShowAnswer: boolean,
    optionIndex: number,
    answer: any,
    isCheckbox: boolean = false
  ) => {
    if (isCheckbox) {
      const ansArray = answer.split(',');
      if (
        isShowAnswer &&
        remainingAttempts === 0 &&
        ansArray.includes(String(optionIndex + 1))
      ) {
        return 'green.100';
      } else {
        return 'white';
      }
    } else {
      if (
        isShowAnswer &&
        remainingAttempts === 0 &&
        String(optionIndex + 1) === answer
      ) {
        return 'green.100';
      }
      return 'white';
    }
  };

  let renderImageMCQ = ({
    item,
    index,
    answer,
    optionIndex,
  }: {
    item: any;
    index: number;
    optionIndex: number;
    answer: any;
  }) => {
    return (
      <View
        flexDirection="row"
        alignItems="center"
        paddingX="15px"
        bgColor={getColor(showAnswer, optionIndex, answer)}
      >
        <Image
          alt="mcq image"
          h="15px"
          w="15px"
          marginRight="10px"
          source={getImageSource(showAnswer, optionIndex, answer, index)}
        />
        <View
          marginY="10px"
          h={['90px', '100px', '110px', '110px', '110px']}
          w={['110px', '130px', '130px', '140px', '140px']}
        >
          <Image
            borderRadius="5px"
            overflow="hidden"
            flex={1}
            source={{ uri: item }}
            alt="mcq-image"
          />
        </View>
      </View>
    );
  };

  let renderTextMCQ = ({
    item,
    index,
    answer,
    optionIndex,
    type,
  }: {
    item: any;
    index: number;
    answer: any;
    optionIndex: number;
    type: string;
  }) => {
    return (
      <View
        flex={1}
        bgColor={getColor(showAnswer, optionIndex, answer, type === 'checkbox')}
      >
        <View flex={1} margin="2px" paddingX="15px">
          <View flexDirection="row" alignItems="center" paddingY="2px">
            <Image
              h="15px"
              w="15px"
              source={getImageSource(
                showAnswer,
                optionIndex,
                answer,
                index,
                type === 'checkbox'
              )}
              alt="selected-radio-button"
            />
            <Text
              marginLeft="5px"
              color="gray.900"
              fontSize={['13px', '13px', '14px', '15px', '15px']}
            >
              {item}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const RenderOptions = ({
    question,
    index,
  }: {
    question: any;
    index: number;
  }) => {
    if (question?.type === 'hotspots') {
      return (
        <View
          height="300px"
          width="100%"
          padding="15px"
          alignSelf="center"
          overflow="hidden"
        >
          <HotspotQuestionView
            question={question}
            showAnswer={showAnswer}
            quizResultAnswers={quizResultAnswers}
            index={index}
            remainingAttempts={remainingAttempts}
          />
        </View>
      );
    } else {
      return (
        <FlatList
          scrollEnabled={false}
          data={question.options}
          keyExtractor={(_evt, i) => `${i}`}
          renderItem={({ item, index: optionsIndex }) => {
            if (question?.type === 'mcq' || question?.type === 'checkbox') {
              return renderTextMCQ({
                item: item,
                index: index,
                answer: question?.answer,
                optionIndex: optionsIndex,
                type: question.type,
              });
            } else {
              return renderImageMCQ({
                item: item,
                index: index,
                answer: question?.answer,
                optionIndex: optionsIndex,
              });
            }
          }}
        />
      );
    }
  };

  const checkCorrect = (question: any, correctAnswer: any) => {
    switch (question.type) {
      case 'checkbox':
        const stringAnsArr = question?.answer?.split(',');
        const numberAnsArr = stringAnsArr.map(function (b: any) {
          return isNaN(Number(b)) ? b : Number(b);
        });
        const _iscorrect = isEqual(sortBy(correctAnswer), sortBy(numberAnsArr));
        if (_iscorrect) {
          return true;
        } else {
          return false;
        }
      case 'hotspots':
        let correctHotspot = [];
        question.options?.forEach((_opt: any, index: number) => {
          const hasCorrectHotspot = (correctAnswer || [])?.find(
            (subA: any) => subA?.hotspot === index
          );
          if (hasCorrectHotspot) {
            correctHotspot.push(hasCorrectHotspot);
          }
        });
        if (correctHotspot.length === question.options.length) {
          return true;
        } else {
          return false;
        }
      case 'mcq':
      case 'mcq-image':
        const iscorrect = String(correctAnswer) === question.answer;
        if (iscorrect) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  const totalQuestions = card?.payload?.questions?.length;
  const flatListStyle = { paddingTop: '100px' };

  return (
    <View flex={1}>
      <ScrollView flex={1}>
        <View
          bgColor={isFailed ? 'red.500' : 'green.500'}
          h="100px"
          borderBottomRadius="10px"
        >
          <Text
            color="white"
            marginX="15px"
            marginY="20px"
            fontWeight="bold"
            fontSize={['20px', '20px', '20px', '22px', '22px']}
          >
            {card?.title}
          </Text>
        </View>
        <View
          overflow="visible"
          position="absolute"
          top="60px"
          bgColor="white"
          borderRadius="10px"
          shadow={2}
          marginX="15px"
          w={['90%', '93%', '94%', '94%', '94%']}
        >
          <View padding="10px">
            <View flexDirection="row" alignItems="center">
              <View
                borderRadius="1000"
                flexDirection="row"
                bgColor={isFailed ? 'red.500' : 'green.500'}
                marginRight="10px"
              >
                <Image
                  margin="8px"
                  source={Images.QuizBadge}
                  w="24px"
                  h="24px"
                  alt="quiz-badge"
                />
              </View>
              <View>
                {!hidePassFailStatus && (
                  <Text
                    color={isFailed ? 'red.500' : 'green.500'}
                    fontWeight="semibold"
                    fontSize={['15px', '15px', '16px', '18px', '18px']}
                  >
                    {isFailed ? 'Fail' : 'Pass'}
                  </Text>
                )}
                <Text>
                  Score: <Text>{scoreCard?.correct}</Text> /{' '}
                  <Text>{totalQuestions}</Text>
                </Text>
              </View>
            </View>
            <View w="100%" h="1px" bgColor="gray.300" marginY="10px" />
            <View flexDirection="row">
              <QuizColumnWithInfo
                description="Correct"
                title={`${scoreCard?.correct} / ${totalQuestions}`}
                iconPath={Images.RadioSelectedGreenBtn}
              />
              <View
                h={verticalDivider}
                w="1px"
                bgColor="gray.300"
                marginX="10px"
              />

              {card.isTimer && (
                <QuizColumnWithInfo
                  description="Skipped"
                  title={`${scoreCard?.skipped} / ${totalQuestions}`}
                  iconPath={Images.RadioBtn}
                />
              )}
              {card.isTimer && (
                <View
                  h={verticalDivider}
                  w="1px"
                  bgColor="gray.300"
                  marginX="10px"
                />
              )}
              <QuizColumnWithInfo
                description="Wrong"
                title={`${scoreCard?.wrong} / ${totalQuestions}`}
                iconPath={Images.RadioCrossBtn}
              />
            </View>
          </View>
        </View>
        <View>
          <FlatList
            style={flatListStyle}
            ItemSeparatorComponent={() => <View h="10px" />}
            scrollEnabled={false}
            data={questions}
            keyExtractor={(_evt, index) => `${index}`}
            renderItem={({ item: question, index }: any) => {
              return (
                <View key={Math.random() + index}>
                  <View
                    flex={1}
                    flexDirection="row"
                    paddingX="15px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      fontSize="13px"
                      color="gray.500"
                      marginTop={index === 0 ? '12px' : '0px'}
                    >
                      Question {index + 1}
                    </Text>

                    {showAnswer && remainingAttempts === 0 && (
                      <View
                        borderRadius="4px"
                        bgColor={
                          checkCorrect(question, quizResultAnswers?.[index]?.r)
                            ? 'green.500'
                            : 'red.500'
                        }
                        alignItems="center"
                        width="70px"
                      >
                        <View paddingY="2px" paddingX="8px">
                          <Text color="white" fontSize="10px">
                            {checkCorrect(
                              question,
                              quizResultAnswers?.[index]?.r
                            )
                              ? 'CORRECT'
                              : 'WRONG'}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>

                  {question?.text && (
                    <Text
                      paddingX="15px"
                      fontWeight="semibold"
                      fontSize={questionFontSize}
                    >
                      {question?.text}
                    </Text>
                  )}
                  {!!question?.imageURL && (
                    <View paddingX="15px" width="155px">
                      <AspectRatio
                        borderRadius="4px"
                        borderColor="#D9DDDD"
                        borderStyle="solid"
                        borderWidth="1px"
                        backgroundColor="linear-gradient(0deg, rgba(0, 193, 178, 0.05), rgba(0, 193, 178, 0.05))"
                        ratio={{
                          base: 4 / 3,
                        }}
                      >
                        <Image
                          source={question?.imageURL}
                          flex={1}
                          alt="question-banner-image"
                          borderRadius="4px"
                        />
                      </AspectRatio>
                    </View>
                  )}
                  <Text
                    paddingX="15px"
                    marginTop="5px"
                    fontSize="12px"
                    color="gray.700"
                  >
                    {showAnswer && remainingAttempts === 0
                      ? 'Correct Answer:'
                      : 'My Answer:'}
                  </Text>
                  <RenderOptions question={question} index={index} />
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
      {isFailed && remainingAttempts > 0 && (
        <View marginX="15px" marginBottom="10px">
          <View
            borderWidth="1px"
            borderRadius="6px"
            borderColor="orange.500"
            overflow="hidden"
          >
            <View padding="6px" bgColor="pink.50">
              <Text fontSize={['13px', '13px', '14px', '15px', '15px']}>
                You have{' '}
                <Text fontWeight="semibold" textDecorationLine="underline">
                  {remainingAttempts < 0 ? 0 : remainingAttempts}
                </Text>{' '}
                more attempts left to pass.
              </Text>
            </View>
          </View>

          {remainingAttempts > 0 && (
            <Button
              marginTop="10px"
              w="100%"
              borderColor="primary.500"
              onPress={() => {
                handleSubmitQuiz('retry');
              }}
            >
              <Text fontWeight="semibold" color="white">
                Retry
              </Text>
            </Button>
          )}
        </View>
      )}
    </View>
  );
};

const QuizColumnWithInfo: React.FC<{
  iconPath: any;
  title: string;
  description: string;
}> = ({ iconPath, description = '', title = '' }) => {
  return (
    <View flex={1}>
      <View flexDirection="row" alignItems="center" marginBottom="5px">
        <Image
          marginRight="5px"
          source={iconPath}
          alt={title}
          w="20px"
          h="20px"
        />
        <Text
          fontSize={['13px', '13px', '14px', '14px', '14px']}
          fontWeight="semibold"
        >
          {title}
        </Text>
      </View>
      <Text
        fontSize={['11px', '11px', '12px', '13px', '13px']}
        color="gray.400"
      >
        {description}
      </Text>
    </View>
  );
};
