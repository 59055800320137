import { selector, selectorFamily } from '@know/ui';
import {
  prepareNewFormsData,
  getFolderNameFromCategories,
  prepareFolderFormsData,
} from '@know/transformers';

import {
  userFormFeedItemsAtom,
  userFormFeedFoldersAtom,
  formsCategoriesAtom,
  sectionsExpandStateAtom,
  newFormFilterTextAtom,
} from './atoms';

import type {
  ISectionDataProps,
  IFolderItem,
  IFormItem,
} from '@know/transformers';

type Item = IFolderItem | IFormItem;

// Define the selector
// weird bug is happening when we pushing the data from this selector to newFormSectionDataSelectorWithToggleState
// Data is not getting updated properly
// For now we are using it in one selector
// export const newFormSectionDataSelector = selector<ISectionDataProps<Item>[]>({
//   key: 'newFormSectionDataSelector',
//   get: ({ get }) => {
//     const formFeedItems = get(userFormFeedItemsAtom);
//     const formFeedFolders = get(userFormFeedFoldersAtom);
//     const categories = get(formsCategoriesAtom);
//     const filterText = get(newFormFilterTextAtom);

//     console.log('newFormSectionDataSelector', {
//       formFeedItems,
//       formFeedFolders,
//       categories,
//       filterText,
//     });

//     return prepareNewFormsData({
//       formFeedItems,
//       formFeedFolders,
//       categories,
//       filterText,
//     });
//   },
// });

export const newFormSectionDataSelectorWithToggleState = selector<
  ISectionDataProps<Item>[]
>({
  key: 'newFormSectionDataSelectorWithToggleState',
  get: ({ get }) => {
    const formFeedItems = get(userFormFeedItemsAtom);
    const formFeedFolders = get(userFormFeedFoldersAtom);
    const categories = get(formsCategoriesAtom);
    const filterText = get(newFormFilterTextAtom);

    const newFormSectionData = prepareNewFormsData({
      formFeedItems,
      formFeedFolders,
      categories,
      filterText,
    });

    const toggleState = get(sectionsExpandStateAtom);

    return newFormSectionData.map((sectionItem) => {
      return {
        ...sectionItem,
        isExpanded: toggleState[sectionItem.type],
      };
    });
  },
});

export const formFolderNameSelector = selectorFamily({
  key: 'formFolderName',
  get:
    (folderId: string) =>
    ({ get }) => {
      const categories = get(formsCategoriesAtom);
      return getFolderNameFromCategories({ categories, folderId });
    },
});

export const formFolderFormsSelector = selectorFamily({
  key: 'formFolderNameSelector',
  get:
    (folderId: string) =>
    ({ get }) => {
      const formFeedItems = get(userFormFeedItemsAtom);
      const formFeedFolders = get(userFormFeedFoldersAtom);
      const categories = get(formsCategoriesAtom);

      return prepareFolderFormsData({
        formFeedItems,
        formFeedFolders,
        categories,
        folderId,
      });
    },
});
