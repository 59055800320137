import React from 'react';
import { Icon } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const AngleDoubleUpIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 14 15">
    <G clipPath="url(#clip0_104_4715)">
      <Path
        d="M11.2777 6.84781C11.2266 6.84811 11.1758 6.8383 11.1284 6.81895C11.0811 6.79959 11.038 6.77108 11.0016 6.73503L6.99996 2.73337L2.99829 6.73503C2.92389 6.79875 2.8282 6.83204 2.73032 6.82826C2.63245 6.82447 2.53961 6.7839 2.47035 6.71464C2.40109 6.64538 2.36052 6.55254 2.35674 6.45467C2.35295 6.35679 2.38625 6.2611 2.44996 6.1867L6.99996 1.63281L11.5538 6.1867C11.6074 6.24129 11.6436 6.31045 11.658 6.38552C11.6725 6.4606 11.6644 6.53826 11.635 6.6088C11.6055 6.67934 11.5559 6.73963 11.4924 6.78215C11.4288 6.82466 11.3542 6.8475 11.2777 6.84781Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <Path
        d="M11.2777 12.2931C11.2266 12.2934 11.1758 12.2836 11.1284 12.2643C11.0811 12.2449 11.038 12.2164 11.0016 12.1803L6.99996 8.17868L2.99829 12.1803C2.92389 12.2441 2.8282 12.2773 2.73032 12.2736C2.63245 12.2698 2.53961 12.2292 2.47035 12.16C2.40109 12.0907 2.36052 11.9979 2.35674 11.9C2.35295 11.8021 2.38625 11.7064 2.44996 11.632L6.99996 7.07812L11.5538 11.632C11.6074 11.6866 11.6436 11.7558 11.658 11.8308C11.6725 11.9059 11.6644 11.9836 11.635 12.0541C11.6055 12.1247 11.5559 12.1849 11.4924 12.2275C11.4288 12.27 11.3542 12.2928 11.2777 12.2931Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_104_4715">
        <Rect width="14" height="14" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
);
