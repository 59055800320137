import { useCallback, useContext, useEffect, useState } from 'react';

import {
  // getUserFormDraft,
  deleteDraft,
  listenToUserFormDrafts,
} from '@know/db';
import {
  KNOWDBContext,
  useKNOWToast,
  useKNOWValueContextProvider,
  useModalParam,
  useNavigation,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from '@know/ui';

import {
  draftSectionsExpandStateAtom,
  draftsListFilterTextAtom,
  formDraftsAtom,
  isFormFeedLoadingAtom,
  userDraftsSectionDataSelectorWithToggleState,
} from '../store';

import { IDraftItem, ISectionDataProps, Item } from '@know/transformers';
import { keys } from 'lodash';

const EMPTY_FUNCTION = () => {};

export function useUserDrafts() {
  const navigation = useNavigation();
  const { currentDBApi, getCurrentUserUserId } = useContext(KNOWDBContext);
  const { isTabletSize, isLargePhoneSize, isPhoneSize } =
    useKNOWValueContextProvider();

  const setUserDrafts = useSetRecoilState(formDraftsAtom);
  const setSectionsExpandState = useSetRecoilState(
    draftSectionsExpandStateAtom
  );
  const userDrafts = useRecoilValue(
    userDraftsSectionDataSelectorWithToggleState
  );
  const [filterText, onFilterChange] = useRecoilState(draftsListFilterTextAtom);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const isDataFeedLoading = useRecoilValue(isFormFeedLoadingAtom);

  const [
    isConfirmDeleteModalOpen,
    confirmDeleteModalProps,
    openConfirmDeleteModalProps,
    closeConfirmDeleteModalProps,
  ] = useModalParam<{ onConfirm: () => void }>();

  const [isDeleting, setIsDeleting] = useState(false);
  const { successToast } = useKNOWToast();

  useEffect(() => {
    if (currentDBApi) {
      return listenToUserFormDrafts(currentDBApi, (drafts) => {
        setUserDrafts(drafts);
        // To show all forms in expanded state by default
        setSectionsExpandState((currentExpanded) => {
          return Object.fromEntries(
            keys(drafts).map((id) => [id, currentExpanded[id] ?? true])
          );
        });
        setTimeout(() => setIsDataLoading(false), 1000);
      });
    }

    return EMPTY_FUNCTION;
  }, [currentDBApi, setSectionsExpandState, setUserDrafts]);

  function onToggleSection(id: string) {
    setSectionsExpandState((state) => {
      return {
        ...state,
        [id]: !state[id],
      };
    });
  }

  function onClickItem(item: IDraftItem, type: string, section?: any) {
    console.log(type);
    navigation.navigate(
      'Forms' as never,
      {
        screen: 'webview',
        params: {
          formId: section.id,
          responseId: item.id,
          initiatorId: getCurrentUserUserId(),
          type: 'draft',
        },
      } as never
    );
  }

  const onClickDeleteItem = useCallback(
    (item: IDraftItem, section?: ISectionDataProps<Item>) => {
      if (currentDBApi && section && section.id) {
        openConfirmDeleteModalProps({
          onConfirm: async () => {
            setIsDeleting(true);
            const formId = section.id ?? 'form-id';
            const responseId = item.id;
            await deleteDraft(currentDBApi, formId, responseId);
            closeConfirmDeleteModalProps();
            successToast({
              header: 'Draft form has been successfully deleted!',
            });
            setIsDeleting(false);
          },
        });
      }
    },
    [
      currentDBApi,
      openConfirmDeleteModalProps,
      closeConfirmDeleteModalProps,
      successToast,
    ]
  );

  return {
    isDataLoading: isDataFeedLoading || isDataLoading,
    userDrafts,
    isConfirmDeleteModalOpen,
    confirmDeleteModalProps,
    isDeleting,
    filterText,
    isSmallScreen: isTabletSize || isLargePhoneSize || isPhoneSize,
    onClickDeleteItem,
    onToggleSection,
    onClickItem,
    onFilterChange,
    closeConfirmDeleteModalProps,
  };
}
