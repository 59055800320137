import { KNOWDBContext, useNavigation, useRecoilValue } from '@know/ui';
import { sentFormSelector } from '../store';
import { useContext, useEffect, useState } from 'react';
import {
  ISectionDataProps,
  ISentFormsItem,
  ISentResponseItem,
  prepareUserSentFormsListData,
} from '@know/transformers';
import { cleanUpSubscribeSentForms, getUserSentResponses } from '@know/db';

const EMPTY_FUNCTION = () => {};

export function useFormSentItems(formId: string) {
  const navigation = useNavigation();
  const { currentDBApi } = useContext(KNOWDBContext);

  const [isLoading, setIsLoading] = useState(true);

  const sentForm = useRecoilValue(sentFormSelector(formId));
  const [sentFormItems, setSentFormItems] = useState<
    ISectionDataProps<ISentResponseItem>[]
  >([]);

  function onToggleSection(id: string) {
    console.log(`${id} section is toggled`);
  }

  useEffect(() => {
    if (currentDBApi && sentForm) {
      getUserSentResponses(currentDBApi, formId, false, (data) => {
        const userSentForm: ISentFormsItem = {
          ...sentForm,
          id: formId,
          hasMoreData: false,
          data,
        } as unknown as ISentFormsItem;

        const userSentForms = {
          [formId]: userSentForm,
        };
        setSentFormItems(
          prepareUserSentFormsListData({
            userSentForms,
            filterText: '',
          }).map((a) => ({ ...a, disableToggle: true }))
        );
        setTimeout(() => setIsLoading(false), 1000);
      });

      return cleanUpSubscribeSentForms;
    }
    return EMPTY_FUNCTION;
  }, [formId, currentDBApi, sentForm, setSentFormItems]);

  function onClickItem(item: ISentResponseItem, _type: string, section?: any) {
    navigation.navigate(
      'Forms' as never,
      {
        screen: 'webview',
        params: {
          formId: section.id,
          responseId: item.id,
          initiatorId: item.userId,
          type: 'sent',
        },
      } as never
    );
  }

  return {
    title: sentForm.name,
    folderFormSectionItems: [],
    onToggleSection,
    onClickItem,
    sentFormItems,
    isLoading,
  };
}
