import React from 'react';

import { KNOWRouterContenxt, IModuleProps } from '@know/ui';
import { TasksNavigator, linking } from './TasksNavigator';

export const TasksScreen = () => {
  const { registerScreen } = React.useContext(KNOWRouterContenxt);

  React.useEffect(() => {
    const module: IModuleProps = {
      id: 'tasklist',
      name: 'Tasks',
      priority: 1,
      component: TasksNavigator,
      icon: 'check-square',
      linking,
    };
    registerScreen(module);
    console.log('registering tasks screen');
  }, [registerScreen]);

  return null;
};
