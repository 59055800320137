import React from 'react';
import {
  Box,
  FlatListWithFilter,
  NoDataForms,
  Table,
  Text,
  WithKNOWLoader,
} from '@know/ui';
import { useFormActionRequired } from './useFormActionRequired';

export const ActionRequiredPage = () => {
  const {
    isLoading,
    feedItems,
    columns,
    onClick,
    initialState,
    setFilterText,
    FlatListItem,
    filterText,
    isSmallScreen,
  } = useFormActionRequired();
  return (
    <WithKNOWLoader
      isLoading={isLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      {isSmallScreen ? (
        <Box h={'100%'} mt={'12px'}>
          <FlatListWithFilter
            keyExtractor={(item) => `${item.formId}-${item.responseId}`}
            data={feedItems}
            hasFilterText={!!filterText}
            renderItem={FlatListItem}
            onFilterChange={setFilterText}
            NoDataComponent={
              <NoDataForms text="Looks like you don't have any forms that require your action." />
            }
            NoResultsFoundComponent={
              <Box
                my={'24px'}
                w={'100%'}
                display={'flex'}
                flexDir={'row'}
                justifyContent={'center'}
              >
                <Text textAlign={'center'}>No results found.</Text>
              </Box>
            }
          />
        </Box>
      ) : (
        <Table
          columns={columns}
          data={feedItems}
          onRowClick={onClick}
          initialState={initialState}
          enableGlobalFilter={true}
          onChangeGlobalFilter={setFilterText}
          isSearching={!!filterText}
          NoDataComponent={
            <NoDataForms text="Looks like you don't have any forms that require your action." />
          }
          NoResultsFoundComponent={
            <Box
              my={'24px'}
              w={'100%'}
              display={'flex'}
              flexDir={'row'}
              justifyContent={'center'}
            >
              <Text textAlign={'center'}>No results found.</Text>
            </Box>
          }
        />
      )}
    </WithKNOWLoader>
  );
};
