import React, { useEffect } from 'react';

import {
  LessonCard,
  StackHeaderProps,
  useRecoilState,
  WithKNOWLoader,
} from '@know/ui';
import { useLessonDetails } from './useLessonDetails';
import { useLessonCard } from './useLessonCard';
import { LessonTypes } from './LessonTypes';
import { lastSeenCardIdAtom } from '../state';

export const LessonCardPage = ({ route, navigation }: StackHeaderProps) => {
  // @ts-ignore
  const cardId = route?.params?.cardId!;
  // @ts-ignore
  const courseId = route?.params?.courseId!;
  // @ts-ignore
  const lessonId = route?.params?.lessonId!;
  // @ts-ignore
  const journeyId = route?.params?.journeyId!;
  const [lastSeenCardId, setLastSeenCardId] =
    useRecoilState(lastSeenCardIdAtom);

  // useEffect(() => {
  //   return () => {
  //     if (isLastSeen) {
  //       setLastSceen(false);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setLastSceen]);

  const { cards, title } = useLessonDetails(
    courseId,
    lessonId,
    cardId,
    journeyId
  );
  const {
    currentCard,
    currentSeq,
    totalSteps,
    isQuizIntroSeen,
    isQuizFinish,
    attempted,
    scoreCard,
    hideTooltip,
    disableNextBtn,
    isLoading,
    quizResultAnswers,
    scromProgress,
    isHidePrevNext,
    showPrevBtnFirstTime,
    currentCourseTab,
    courseTitle,
    handlePreviousCard,
    handleNextCard,
    handleSubmitQuizCard,
    handleImageConsumed,
    handleQuizResponses,
    handleQuizAttempt,
    handleVideoConsumed,
    handleAudioConsumed,
    handlePDFConsumed,
    handleScromProgress,
    handleHotspotConsumed,
    handleSurveyResponses,
  } = useLessonCard(
    courseId,
    lessonId,
    cardId,
    cards,
    journeyId,
    lastSeenCardId,
    setLastSeenCardId
  );

  useEffect(() => {
    navigation.setOptions({
      title: courseTitle,
    });
  }, [courseTitle, navigation]);

  return (
    <LessonCard
      title={title}
      totalSteps={totalSteps}
      currentSeq={currentSeq}
      handlePrev={handlePreviousCard}
      handleNext={handleNextCard}
      cardId={cardId}
      cards={cards}
      isQuizIntroSeen={isQuizIntroSeen}
      hideTooltip={hideTooltip}
      disableNextBtn={disableNextBtn}
      handleQuizAttempt={handleQuizAttempt}
      isQuizFinish={isQuizFinish}
      isHidePrevNext={isHidePrevNext}
      showPrevBtnFirstTime={showPrevBtnFirstTime}
      isLoaded={true}
    >
      <WithKNOWLoader
        isLoading={isLoading}
        color="primary"
        type="horizontal"
        size="lg"
      >
        <LessonTypes
          card={currentCard}
          key={currentCard.id}
          /* @ts-ignore */
          attempted={attempted}
          isQuizFinished={isQuizFinish}
          isQuizIntroSeen={isQuizIntroSeen}
          scoreCard={scoreCard}
          quizResultAnswers={quizResultAnswers}
          scromProgress={scromProgress}
          currentCourseTab={currentCourseTab}
          handleSubmitQuiz={handleSubmitQuizCard}
          handleImageConsumed={handleImageConsumed}
          handleQuizResponses={handleQuizResponses}
          handleVideoConsumed={handleVideoConsumed}
          handleAudioConsumed={handleAudioConsumed}
          handlePDFConsumed={handlePDFConsumed}
          handleScromProgress={handleScromProgress}
          handleQuizAttempt={handleQuizAttempt}
          handleHotspotConsumed={handleHotspotConsumed}
          handleSurveyResponses={handleSurveyResponses}
        />
      </WithKNOWLoader>
    </LessonCard>
  );
};
