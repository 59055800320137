import React, { useEffect } from 'react';
import {
  View,
  WithKNOWLoader,
  useRoute,
  Text,
  Topbar,
  Box,
  KNOWButton as Button,
  IssueStatus,
  IssueSeverityType,
  ExpoIonicIcon,
  IssueUpdate,
  UploadProgressModal,
  TaskRemarkInput,
  UploadImageModal,
  UploadVideoModal,
  UploadFileHandler,
  ViewUsersModal,
  ScrollView,
  MuteIcon,
  IssueActionDialog,
  SelectItemModal,
} from '@know/ui';
import { issueSeverities } from '../CreateIssuePage/useCreateIssue';
import { SeverityType } from '@know/db';
import { useIssueDetails } from './useIssueDetails';
import { getDateFormat } from '../utils';

export const linking = {
  path: 'issues/details/:issueId',
};

interface IContainerListProps {
  isSmallScreen: boolean;
  containerHeight: number;
  topBarHeight: number;
  containerListRef: React.Ref<any>;
}

const ContainerList: React.FC<IContainerListProps> = ({
  isSmallScreen,
  containerHeight,
  topBarHeight,
  containerListRef,
  children,
}) => {
  if (isSmallScreen) {
    return (
      <ScrollView
        flex={1}
        display={'flex'}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        bg={'white'}
        h={isSmallScreen ? undefined : `${containerHeight - topBarHeight}px`}
        position={'relative'}
        ref={containerListRef}
      >
        {children}
      </ScrollView>
    );
  } else {
    return (
      <View
        flex={1}
        display={'flex'}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        bg={'white'}
        h={`${containerHeight - topBarHeight}px`}
        position={'relative'}
      >
        {children}
      </View>
    );
  }
};

export const IssueDetailsPage = () => {
  const route = useRoute();
  // @ts-ignore
  const issueId = route?.params?.issueId!;
  // @ts-ignore
  const shareId = route?.params?.shareId!;
  // @ts-ignore
  const shareWorkflow = route?.params?.share!;

  const {
    isLoading,
    isIssueMuted,
    issueNugget,
    issueUpdates,
    issueHasClosureReport,
    isSmallScreen,
    actionButtonText,
    isActionLoading,
    isUploading,
    uploadProgress,
    uploadProgressProps,
    isPhoneSize,
    menuOptions,
    uploadImageModalRef,
    uploadVideoModalRef,
    uploadFileHandlerRef,
    isIssueUsersDialogOpen,
    isIssueUsersLoading,
    issueUsers,
    listRef,
    containerHeight,
    topBarHeight,
    detailsContainerWidth,
    containerListRef,
    isUpdateSeverityOpen,
    onUpdateSeverityClose,
    ellipsisOptions,
    onClickChangeSeverity,
    setContainerHeight,
    setTopBarHeight,
    setDetailsContainerHeight,
    setDetailsContainerWidth,
    openIssueUsersDialog,
    closeIssueUsersDialog,
    isCloseIssueDialogOpen,
    isReopenIssueDialogOpen,
    onCloseIssueDialogClose,
    onReopenIssueClose,
    onCloseIssue,
    onReopenIssue,
    goBack,
    onClickImage,
    onClickPayload,
    onClickActionButton,
    uploadFiles,
    onSendRemark,
    onClickPdf,
    onAddImages,
    onAddVideos,
    onAddFiles,
    onClickShare,
    _getItemComponentWithSelectedCheck,
    openClosureReport,
  } = useIssueDetails(issueId, shareId);

  useEffect(() => {
    if (shareWorkflow) {
      onClickShare();
    }
  }, [onClickShare, shareWorkflow]);

  return (
    <View
      h={isSmallScreen ? 'calc(100vh - 80px)' : '100vh'}
      bg={'white'}
      onLayout={(e: any) => {
        setContainerHeight(e.nativeEvent.layout.height);
      }}
      position={'relative'}
    >
      <WithKNOWLoader
        isLoading={isLoading}
        color="primary"
        type="horizontal"
        size="lg"
      >
        {/* only for open issues we should show ellipsis */}
        <Topbar
          goBack={goBack}
          onLayout={(e: any) => {
            setTopBarHeight(e.nativeEvent.layout.height);
          }}
          ellipsisOptions={ellipsisOptions}
        />
        <SelectItemModal
          isOpen={isUpdateSeverityOpen}
          title={'Update Severity'}
          options={issueSeverities}
          onClose={onUpdateSeverityClose}
          getItemComponent={_getItemComponentWithSelectedCheck}
          onSubmit={onClickChangeSeverity}
          keyExtractor={(item: any) => item.value}
          showFilter={false}
        />
        {issueNugget && Object.keys(issueNugget).length ? (
          <ContainerList
            isSmallScreen={isSmallScreen}
            containerHeight={containerHeight}
            topBarHeight={topBarHeight}
            containerListRef={containerListRef}
          >
            <View
              w={isSmallScreen ? '100%' : '25%'}
              maxW={isSmallScreen ? '100%' : '350px'}
              h={isSmallScreen ? undefined : '100%'}
              px={'16px'}
              py={'20px'}
              overflow={isSmallScreen ? undefined : 'auto'}
              onLayout={(e: any) => {
                setDetailsContainerWidth(e.nativeEvent.layout.width);
                setDetailsContainerHeight(e.nativeEvent.layout.height);
              }}
            >
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontWeight={'600'} fontSize={'18px'}>
                    {issueNugget.ticketId ? `${issueNugget.ticketId}: ` : ''}
                    {issueNugget.name}
                  </Text>
                  {isIssueMuted ? (
                    <MuteIcon fontSize={'18px'} color={'#4E5656'} />
                  ) : null}
                </Box>

                {issueNugget.notes && (
                  <Text
                    fontWeight={'400'}
                    color={'gray.700'}
                    fontSize={'14px'}
                    lineHeight={'19.6px'}
                  >
                    {issueNugget.notes}
                  </Text>
                )}
              </Box>
              <View
                display={'flex'}
                flexDir={isSmallScreen && !isPhoneSize ? 'row' : 'column'}
              >
                <Box mt={'20px'} mb={'12px'} alignItems={'flex-start'} flex={1}>
                  <Text fontWeight={'600'} fontSize={'14px'} mb={'6px'}>
                    Status
                  </Text>
                  <IssueStatus type={issueNugget?.status?.toLowerCase()} />
                </Box>
              </View>
              <Box>
                <Button
                  isLoading={isActionLoading}
                  colorScheme="primary"
                  onPress={onClickActionButton}
                >
                  {actionButtonText}
                </Button>
              </Box>

              <View
                display={'flex'}
                flexDir={isSmallScreen && !isPhoneSize ? 'row' : 'column'}
              >
                <Box mt={'20px'} mb={'14px'} alignItems={'flex-start'} flex={1}>
                  <Text fontWeight={'600'} fontSize={'14px'}>
                    Severity:
                  </Text>

                  <IssueSeverityType
                    type={issueNugget?.severity?.toLowerCase() as SeverityType}
                  />
                </Box>

                <Box
                  mt={isSmallScreen && !isPhoneSize ? '20px' : null}
                  mb={isSmallScreen && !isPhoneSize ? '14px' : '16px'}
                  alignItems={'flex-start'}
                  flex={1}
                >
                  <Text fontWeight={'600'} fontSize={'14px'} mb={'2px'}>
                    Issue Type
                  </Text>
                  <Text
                    color={'gray.700'}
                    fontSize={'14px'}
                    fontFamily={'tableCell'}
                  >
                    {issueNugget.incidentType}
                  </Text>
                </Box>

                <Box
                  mt={isSmallScreen && !isPhoneSize ? '20px' : null}
                  mb={isSmallScreen && !isPhoneSize ? '14px' : '16px'}
                  alignItems={'flex-start'}
                  flex={1}
                >
                  <Text fontWeight={'600'} fontSize={'14px'} mb={'2px'}>
                    Location
                  </Text>
                  <Text
                    color={'gray.700'}
                    fontSize={'14px'}
                    fontFamily={'tableCell'}
                  >
                    {issueNugget.incidentLocation}
                  </Text>
                </Box>

                <Box
                  mt={isSmallScreen && !isPhoneSize ? '20px' : null}
                  mb={isSmallScreen && !isPhoneSize ? '14px' : '16px'}
                  alignItems={'flex-start'}
                  flex={1}
                >
                  <Text fontWeight={'600'} fontSize={'14px'} mb={'2px'}>
                    Reported On
                  </Text>
                  <Text
                    color={'gray.700'}
                    fontSize={'14px'}
                    fontFamily={'tableCell'}
                  >
                    {issueNugget.createdAt
                      ? getDateFormat(issueNugget.createdAt)
                      : null}
                  </Text>
                </Box>
              </View>

              <Box
                display={'flex'}
                flexDir={'row'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                {issueNugget?.status?.toLowerCase() === 'open' && (
                  <Button
                    colorScheme="secondary"
                    onPress={onClickShare}
                    leftIcon={
                      <ExpoIonicIcon
                        name="share-social"
                        size={'16px'}
                        mt={'2px'}
                      />
                    }
                  >
                    Share
                  </Button>
                )}
                <Button
                  colorScheme="secondary"
                  borderWidth={0}
                  onPress={openIssueUsersDialog}
                  leftIcon={
                    <ExpoIonicIcon
                      name="information-circle-outline"
                      size={'16px'}
                      mt={'2px'}
                    />
                  }
                >
                  More Info
                </Button>
              </Box>
            </View>
            {isSmallScreen ? (
              <View
                h={isSmallScreen ? undefined : '100%'}
                w={isSmallScreen ? '100%' : undefined}
                flex={1}
                overflow={isSmallScreen ? undefined : 'auto'}
                bg={'gray.100'}
                py={'20px'}
                px={'16px'}
                pb={isSmallScreen ? '20px' : '90px'}
              >
                {issueUpdates.map((update, index) => (
                  <IssueUpdate
                    key={`${update.issueUpdateId}`}
                    isLastUpdate={index === issueUpdates.length - 1}
                    update={update}
                    issue={issueNugget}
                    isFirst={index === 0}
                    onClickImage={onClickImage}
                    onClickPayload={onClickPayload}
                    onClickPdf={onClickPdf}
                  />
                ))}
                {isSmallScreen ? <Box h={'90px'} /> : null}
              </View>
            ) : (
              <ScrollView
                h={isSmallScreen ? undefined : '100%'}
                w={isSmallScreen ? '100%' : undefined}
                flex={1}
                overflow={isSmallScreen ? undefined : 'auto'}
                bg={'gray.100'}
                py={'20px'}
                px={'16px'}
                pb={isSmallScreen ? '20px' : '90px'}
                ref={listRef}
              >
                {issueUpdates.map((update, index) => (
                  <IssueUpdate
                    key={`${update.issueUpdateId}`}
                    isLastUpdate={index === issueUpdates.length - 1}
                    update={update}
                    issue={issueNugget}
                    isFirst={index === 0}
                    onClickImage={onClickImage}
                    onClickPayload={onClickPayload}
                    onClickPdf={onClickPdf}
                  />
                ))}
                {isSmallScreen ? <Box h={'90px'} /> : null}
              </ScrollView>
            )}
          </ContainerList>
        ) : null}
        <Box
          position={'absolute'}
          bottom={'20px'}
          right={'16px'}
          left={isSmallScreen ? '20px' : `${detailsContainerWidth + 20}px`}
        >
          <TaskRemarkInput onSend={onSendRemark} menuOptions={menuOptions} />
        </Box>
        <IssueActionDialog
          isOpen={isCloseIssueDialogOpen}
          hasClosureReport={issueHasClosureReport}
          openClosureReport={openClosureReport}
          cancel={onCloseIssueDialogClose}
          isActionLoading={isActionLoading}
          uploadFiles={uploadFiles}
          onClickImage={onClickImage}
          type="close"
          onSubmit={onCloseIssue}
        />
        <IssueActionDialog
          isOpen={isReopenIssueDialogOpen}
          cancel={onReopenIssueClose}
          isActionLoading={isActionLoading}
          uploadFiles={uploadFiles}
          onClickImage={onClickImage}
          type="reopen"
          onSubmit={onReopenIssue}
        />
        <UploadImageModal
          isOpen={false}
          close={console.log}
          limit={10}
          onSubmit={onAddImages}
          ref={uploadImageModalRef}
          showAlertToast={true}
          hideUploadButtonInTakePhotoModal={true}
        />
        <UploadVideoModal
          isOpen={false}
          close={console.log}
          limit={1}
          onSubmit={onAddVideos}
          ref={uploadVideoModalRef}
          showAlertToast={true}
          hideUploadButtonInRecordVideoModal={true}
        />
        <UploadFileHandler
          ref={uploadFileHandlerRef}
          limit={1}
          onSubmit={onAddFiles}
          accept="application/pdf"
          acceptName="PDF"
        />
        {isUploading && (
          <UploadProgressModal
            isOpen={isUploading}
            progress={uploadProgress}
            {...uploadProgressProps}
          />
        )}
        <ViewUsersModal
          isOpen={isIssueUsersDialogOpen}
          onClose={closeIssueUsersDialog}
          options={issueUsers}
          isLoading={isIssueUsersLoading}
          title="Members"
        />
      </WithKNOWLoader>
    </View>
  );
};
