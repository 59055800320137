import { DataSnapshot, query, limitToLast } from "firebase/database";
import { ISentFormsItem, ISentResponseItem } from "@know/transformers";
import { FirebaseAPI } from "..";

function getUserSentForms(fbAPI: FirebaseAPI) {
  const userId = fbAPI.getLoggedInUserId();
  const path = fbAPI.getMultiPath(`/forms/Lookups/${userId}/submittedForms`);
  return fbAPI.getNodeRef(path);
}

export function getUserSentResponses(
  fbAPI: FirebaseAPI,
  nuggetId: string,
  limit = false,
  cb: (data: ISentResponseItem[]) => any
) {
  const userId = fbAPI.getLoggedInUserId();
  const path = fbAPI.getMultiPath(
    `/forms/Lookups/${userId}/submittedResponses/${nuggetId}`
  );
  const pathRef = fbAPI.getNodeRef(path);
  const orderByQueryRef = fbAPI.queryOrderByChild(pathRef, "createdAt");
  const endAtQueryRef = fbAPI.queryValue(orderByQueryRef, "endAt", Date.now());
  const limitToLastQueryRef = limit
    ? query(endAtQueryRef, limitToLast(5))
    : endAtQueryRef;
  const handler = (snap: DataSnapshot) => {
    const recentSentResponses = snap.val() ?? {};
    const data: ISentResponseItem[] = Object.entries(recentSentResponses)
      .map(([responseId, response]: any) => {
        return {
          id: responseId,
          ...response,
        } as ISentResponseItem;
      })
      .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
    cb(data);
  };
  return fbAPI.listenOnNodeRef(limitToLastQueryRef, "value", handler);
}

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: (() => void)[] = [];

export function subscribeToUserSentForms(
  fbAPI: FirebaseAPI,
  cb: (formId: string, feedItem: ISentFormsItem | null) => void
) {
  const userFeedRef = getUserSentForms(fbAPI);
  const orderByQueryRef = fbAPI.queryOrderByChild(userFeedRef, "receivedAt");

  function listenerHandler(_listenerEvenType: string) {
    return async (sentFormItemSnapShot: DataSnapshot) => {
      const sentForm: any = (await sentFormItemSnapShot.val()) || null;
      const formId = sentFormItemSnapShot.key as string;
      if (!formId) {
        return;
      } else if (_listenerEvenType === typeCR) {
        return cb(formId, null);
      }
      if (sentForm) {
        cleanups.push(
          getUserSentResponses(
            fbAPI,
            formId,
            true,
            submittedResponsesListenerHandler(formId, sentForm)
          )
        );
      } else {
        cb(formId, null);
      }
    };
  }

  const submittedResponsesListenerHandler =
    (formId: string, sentForm: any) => (data: ISentResponseItem[]) => {
      const sentFormItem: ISentFormsItem = {
        id: formId,
        hasMoreData: data.length > 4,
        ...sentForm,
        data: data.splice(0, 4), // Get max four only and rest will be shown in paginated view.
      };
      cb(formId, sentFormItem);
    };

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(orderByQueryRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(orderByQueryRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(orderByQueryRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribeSentForms = () => {
  for (const c of cleanups) {
    c();
  }
  cleanups = [];
};
