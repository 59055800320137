export default {
  logo: require('./logo.png'),
  logoGreen: require('./logo_green.svg'),
  logoLearn: require('./LearnIllustration.svg'),
  completedLesson: require('./CompletedLesson.svg'),
  completedCourse: require('./CompletedCourse.svg'),
  QuizAttempt: require('./QuizAttempt.svg'),
  QuizPass: require('./QuizPass.svg'),
  QuizTimeLimit: require('./QuizTimeLimit.svg'),
  QuizAttemptInfinite: require('./QuizAttempInfinite.svg'),
  QuizBadge: require('./QuizBadge.svg'),
  QuizNoTimeLimit: require('./QuizNoTimeLimit.svg'),
  RadioBtn: require('./RadioBtn.svg'),
  RadioSelectedBtn: require('./RadioSelectedBtn.svg'),
  RadioSelectedGreenBtn: require('./RadioSelectedGreenBtn.svg'),
  RadioCrossBtn: require('./RadioCrossBtn.svg'),
  FileIcon: require('./FileIcon.svg'),
  RadioBlackBtn: require('./RadioBlackBtn.svg'),
  WhitePlayButton: require('./WhitePlayBtn.svg'),
  WhitePauseButton: require('./WhitePauseBtn.svg'),
  ContentNotSupported: require('./ContentNotSupported.svg'),
  LockOverlay: require('./LockOverlay.svg'),
  NoCourses: require('./NoCourses.svg'),
  FullScreen: require('./FullScreen.svg'),
  ReplayButton: require('./ReplayButton.svg'),
  CheckboxButton: require('./Checkbox.svg'),
  SelectedCheckboxButton: require('./SelectedCheckbox.svg'),
  ClickHandIcon: require('./Vector.svg'),
  CheckboxSelectedGreen: require('./CheckboxSelectedGreen.svg'),
  CheckboxSelectedBlack: require('./CheckboxSelectedBlack.svg'),
  BackArrowIcon: require('./BackArrow.svg'),
  InitalSurvey: require('./InitalSurvey.svg'),
  SadEmoji: require('./SadEmoji.svg'),
  NeutralEmoji: require('./NeutralEmoji.svg'),
  HappyEmoji: require('./HappyEmoji.svg'),
  SurveyTitle: require('./SurveyTitle.svg'),
  SurveyIcon: require('./SurveyIcon.svg'),
  WhiteLockIcon: require('./WhiteLock.svg'),
  NPSIllustration: require('./NPSIllustration.svg'),
  Forms: require('./Forms.svg'),
  ChecklistCircle: require('./ChecklistCircle.svg'),
  Issues: require('./Issues.svg'),
};
