//TODO: make a native app friendly version
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  ExpoADIcon,
  ExpoFAIcon,
  Menu,
  Modal,
  Pressable,
  Text,
  KNOWButton as Button,
  UploadIcon,
  Image,
  UndoIcon,
} from '@know/ui';
import Webcam from 'react-webcam';
import { getFileFromBase64 } from './utils';
import { Image as RNImage } from 'react-native';

interface TakePhotoModalProps {
  isOpen: boolean;
  restrictImagePicker?: boolean;
  hideUploadButton?: boolean;
  close: () => void;
  onSubmit?: (response: File) => void;
  onClickUpload?: () => void;
}

export const TakePhotoModal = ({
  isOpen,
  close,
  onSubmit,
  onClickUpload: _onClickUpload,
  restrictImagePicker,
  hideUploadButton,
}: TakePhotoModalProps) => {
  const [permissionAllowed, setIsPermissionAllowed] = useState(false);
  const [noCameraFound, setNoCameraFound] = useState(false);
  const [webcamSize, setWebcamSize] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(
    null
  );
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const [takenPhoto, setTakenPhoto] = useState<string | null>(null);
  const [imageSize, setImageSize] = useState<{
    width?: number;
    height?: number;
  }>({});

  const [modalWidth, setModalWidth] = useState<number>(0);

  const [isConvertingImage, setIsConvertingImage] = useState<boolean>(false);

  const webcamRef = useRef<Webcam | null>(null);

  const onClose = useCallback(() => {
    close();
  }, [close]);

  const onClickTakePhoto = useCallback(() => {
    setTakenPhoto(webcamRef.current?.getScreenshot() ?? null);
  }, []);

  useEffect(() => {
    if (permissionAllowed) {
      navigator.mediaDevices.enumerateDevices().then((_devices) => {
        const videoDevices = _devices.filter(
          ({ kind }) => kind === 'videoinput'
        );
        if (!videoDevices.length) {
          setNoCameraFound(true);
        } else {
          setSelectedDevice(videoDevices[0]);
          setDevices(videoDevices);
        }
      });
    }
  }, [permissionAllowed]);

  const onClickSubmit = useCallback(async () => {
    if (takenPhoto) {
      setIsConvertingImage(true);
      const file = await getFileFromBase64(
        takenPhoto,
        'image/png',
        `captured-image-${Date.now()}.png`
      );
      setIsConvertingImage(false);
      onSubmit && onSubmit(file);
    }
    onClose();
  }, [onClose, onSubmit, takenPhoto]);

  const onClickUpload = useCallback(() => {
    onClose();
    _onClickUpload && _onClickUpload();
  }, [_onClickUpload, onClose]);

  useEffect(() => {
    if (takenPhoto)
      RNImage.getSize(takenPhoto, (width, height) => {
        setImageSize({
          width: modalWidth,
          height: Math.round(modalWidth * (height / width)),
        });
      });
  }, [modalWidth, takenPhoto]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={!isConvertingImage}
      onClose={onClose}
      size="xl"
    >
      <Modal.Content>
        <Modal.CloseButton isDisabled={isConvertingImage} />
        <Modal.Header borderBottomWidth={0}>Take Photo</Modal.Header>
        <Modal.Body display={'flex'} flexDirection={'column'}>
          <Box
            onLayout={(e) => {
              setWebcamSize({
                width: e.nativeEvent.layout.width,
                height: Math.round(e.nativeEvent.layout.width / 1.7),
              });
              setModalWidth(e.nativeEvent.layout.width);
            }}
            w={'100%'}
            display={'flex'}
            position={'relative'}
            bg={permissionAllowed ? null : 'black'}
            borderRadius={'6px'}
          >
            {takenPhoto ? (
              <Image
                source={{ uri: takenPhoto }}
                width={'100%'}
                height={`${imageSize.height}px`}
                borderRadius={'6px'}
                resizeMode="cover"
              />
            ) : (
              <Webcam
                audio={false}
                ref={webcamRef}
                onUserMedia={() => setIsPermissionAllowed(true)}
                onUserMediaError={() => setIsPermissionAllowed(false)}
                width={webcamSize.width}
                videoConstraints={
                  selectedDevice ? { deviceId: selectedDevice.deviceId } : true
                }
                // eslint-disable-next-line react-native/no-inline-styles
                style={{ borderRadius: '6px' }}
              />
            )}
            {noCameraFound ? (
              <Box
                position={'absolute'}
                display={'flex'}
                top={0}
                left={0}
                justifyContent={'center'}
                alignItems={'center'}
                w="100%"
                h="100%"
              >
                <Text
                  fontWeight={600}
                  color={'white'}
                  fontSize={'18px'}
                  mb={'6px'}
                  mt={'6px'}
                >
                  No Camera Available
                </Text>
              </Box>
            ) : null}
            {permissionAllowed || noCameraFound ? null : (
              <Box
                position={'absolute'}
                display={'flex'}
                top={0}
                left={0}
                justifyContent={'center'}
                alignItems={'center'}
                w="100%"
                h="100%"
              >
                <Box
                  w={'100%'}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Box h={'100%'} mr={'8px'}>
                    <Box
                      bg={'gray.900'}
                      borderRadius={'50%'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      h={'40px'}
                      w={'40px'}
                    >
                      <ExpoADIcon
                        size={'18px'}
                        name="arrowleft"
                        color={'white'}
                        style={{ transform: [{ rotate: '45deg' }] }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    w={'50%'}
                  >
                    <Text
                      fontWeight={600}
                      color={'white'}
                      fontSize={'18px'}
                      mb={'6px'}
                      mt={'6px'}
                    >
                      Allow Camera
                    </Text>
                    <Text color={'white'} fontSize={'14px'} mb={'6px'}>
                      To take photos, click 'Allow' above to give KNOW access to
                      your camera.
                    </Text>
                    <Text color={'white'} fontSize={'14px'} mb={'10px'}>
                      If you accidentally denied permission, go to your browser
                      settings to change it.
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {permissionAllowed && !takenPhoto ? (
            <Box mt={'10px'} flex={0} alignSelf={'flex-start'}>
              <Menu
                trigger={(triggerProps) => (
                  <Pressable {...triggerProps}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      bg={'gray.200'}
                      borderRadius={'17px'}
                      padding="10px 12px"
                      w={'100%'}
                      h={'100%'}
                    >
                      <ExpoFAIcon
                        name="video-camera"
                        color={'gray.700'}
                        mr={'6px'}
                        size={'14px'}
                        mt={'2px'}
                      />{' '}
                      <Text
                        fontSize={'14px'}
                        color={'gray.700'}
                        isTruncated={true}
                      >
                        {selectedDevice?.label}
                      </Text>
                      <ExpoFAIcon
                        size={'14px'}
                        name="caret-down"
                        color={'gray.700'}
                        ml={'6px'}
                        mt={'2px'}
                      />
                    </Box>
                  </Pressable>
                )}
              >
                {devices.map((dev) => (
                  <Menu.Item
                    key={dev.deviceId}
                    onPress={() => setSelectedDevice(dev)}
                  >
                    <Box
                      display={'flex'}
                      flexDir={'row'}
                      justifyContent={'space-between'}
                      w={'100%'}
                      alignItems={'center'}
                    >
                      <Text
                        color={
                          dev.deviceId === selectedDevice?.deviceId
                            ? 'primary.500'
                            : 'gray.900'
                        }
                        fontSize={'14px'}
                      >
                        {dev.label}
                      </Text>
                      {dev.deviceId === selectedDevice?.deviceId ? (
                        <ExpoADIcon
                          name="check"
                          ml={'40px'}
                          color={'primary.500'}
                          size={'18px'}
                          mt={'2px'}
                        />
                      ) : null}
                    </Box>
                  </Menu.Item>
                ))}
              </Menu>
            </Box>
          ) : null}
          {takenPhoto ? (
            <Box mt={'12px'} display={'flex'} flexDir={'row'}>
              <Pressable
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                onPress={() => setTakenPhoto(null)}
                ml={'auto'}
                mr={'auto'}
              >
                <UndoIcon mr="4px" size="24px" />
                <Text fontWeight={600} color={'gray.900'} fontSize={'16px'}>
                  Retake
                </Text>
              </Pressable>
            </Box>
          ) : null}
        </Modal.Body>
        <Modal.Footer
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {takenPhoto ? (
            <Button
              _text={{ fontSize: '16px' }}
              colorScheme="primary"
              isDisabled={!permissionAllowed}
              flex={1}
              onPress={onClickSubmit}
              isLoading={isConvertingImage}
            >
              Upload Photo
            </Button>
          ) : (
            <>
              {restrictImagePicker || hideUploadButton ? null : (
                <Pressable
                  h={'100%'}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  onPress={onClickUpload}
                >
                  <UploadIcon mr="4px" size="24px" />
                  <Text fontWeight={600} color={'gray.900'} fontSize={'16px'}>
                    Upload Image
                  </Text>
                </Pressable>
              )}
              <Button
                _text={{ fontSize: '16px', fontFamily: 'OpenSans' }}
                colorScheme="primary"
                isDisabled={!permissionAllowed}
                onPress={onClickTakePhoto}
                flex={restrictImagePicker ? 1 : undefined}
                ml={'auto'}
              >
                Take Photo
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
