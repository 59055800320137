import React from 'react';
import {
  Pressable,
  View,
  Text,
  useBreakpointValue,
  useNavigation,
} from '@know/ui';
import { Progress, Spinner, useMediaQuery } from 'native-base';
import { Dimensions, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { noop } from 'lodash';

interface IProps {
  showPrevBtnFirstTime: boolean;
  title: string;
  totalSteps: number;
  currentSeq: number;
  cardId: string;
  cards: any[];
  children: React.ReactNode;
  handlePrev: (seq: number, cardId: string) => void;
  handleNext: (seq: number, cardId: string, currentType: any) => void;
  handleQuizAttempt: () => void;
  isQuizIntroSeen: boolean;
  isQuizFinish: boolean;
  hideTooltip: {
    isHidden: boolean;
    text: string;
  };
  disableNextBtn: boolean;
  isHidePrevNext: boolean;
  isLoaded: boolean;
}

export const LessonCard: React.FC<IProps> = ({
  currentSeq,
  totalSteps,
  children,
  handleNext,
  handlePrev,
  handleQuizAttempt,
  cardId,
  cards,
  isQuizIntroSeen,
  isQuizFinish,
  hideTooltip,
  disableNextBtn,
  isHidePrevNext,
  showPrevBtnFirstTime,
  isLoaded,
}) => {
  const navigation = useNavigation();
  const currentType = cards?.find((item) => item?.id === cardId)?.type!;
  const hPadding = useBreakpointValue({
    base: '10px',
    sm: '10px',
    md: '8%',
    lg: '15%',
    xl: '20%',
  });

  const cardHorizontalPadding = useBreakpointValue({
    base: '10px',
    sm: '10px',
    md: '2%',
    lg: '5%',
    xl: '10%',
  });

  const fullHeight = Dimensions.get('window').height;
  const styles = StyleSheet.create({
    tooltipTriangle: {
      transform: [{ rotate: '180deg' }],
    },
  });

  const showBtnText = () => {
    if (currentType === 'quiz') {
      if (currentSeq + 1 === totalSteps && (isQuizIntroSeen || isQuizFinish)) {
        return 'Finish';
      }

      return 'Next';
    } else {
      if (currentSeq + 1 === totalSteps) {
        return 'Finish';
      }
      return 'Next';
    }
  };

  const onPressNext = () => {
    handleNext(currentSeq, cardId, currentType);
    if (!isQuizIntroSeen && currentType === 'quiz' && isQuizFinish) {
      handleQuizAttempt();
    }
  };

  const [isLargeScreen] = useMediaQuery({ minWidth: 1441 });

  return (
    <View
      flex={1}
      flexShrink="inherit"
      bgColor="navbar.primary"
      paddingX={hPadding}
    >
      <View flexDirection="row" marginTop="15px">
        <View
          flex={1}
          color="white"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Ionicons
            name="close"
            size={24}
            color="white"
            onPress={() => navigation.goBack()}
          />
          {/* <View fontWeight="600" fontSize="20px" marginLeft={2}>
            <Text color="white">{title}</Text>
          </View> */}
        </View>
        <View
          flex={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Text alignSelf="center" color="white" fontWeight="600">
            {currentSeq + 1}/{totalSteps}
          </Text>
        </View>
        <View flex={1} />
      </View>
      <View marginY="15px">
        <Progress
          w="100%"
          size="2.5"
          value={((currentSeq + 1) / totalSteps) * 100}
          bg="secondary.25"
          _filledTrack={{
            bg: 'primary.500',
          }}
        />
      </View>

      <View
        paddingX={cardHorizontalPadding}
        alignItems="center"
        justifyContent="center"
      >
        <View
          maxWidth={isLargeScreen ? '550px' : '450px'}
          height={fullHeight - 190}
          width="100%"
          backgroundColor="white"
          borderRadius="10px"
          overflow="hidden"
          marginBottom="10px"
        >
          {children}
          {(!isHidePrevNext || !isQuizIntroSeen) && (
            <View
              borderTopWidth="1px"
              borderTopColor="gray.350"
              flexDirection="row"
              h="40px"
            >
              {!showPrevBtnFirstTime && (
                <Pressable
                  onPress={() => handlePrev(currentSeq, cardId)}
                  flex={1}
                  bgColor="white"
                  disabled={currentSeq === 0}
                >
                  <View
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="row"
                  >
                    <Ionicons name="chevron-back" size={18} color="#00C1B2" />

                    <Text marginLeft="5px" color="primary.500">
                      Previous
                    </Text>
                  </View>
                </Pressable>
              )}
              <Pressable onPress={isLoaded ? onPressNext : noop} flex={1}>
                {!hideTooltip?.isHidden && (
                  <View
                    position="absolute"
                    bottom="35px"
                    right="25px"
                    left="30px"
                    zIndex={10}
                  >
                    <View
                      bgColor="white"
                      borderColor="secondary.500"
                      borderWidth="1px"
                      padding="10px"
                      borderRadius="4px"
                      shadow="5"
                    >
                      <Text fontWeight="600" fontSize="16px" lineHeight={22}>
                        {hideTooltip?.text}
                      </Text>
                    </View>
                    <View
                      width={0}
                      height={0}
                      backgroundColor="transparent"
                      borderStyle="solid"
                      borderLeftWidth={10}
                      borderRightWidth={10}
                      borderBottomWidth={10}
                      borderLeftColor="transparent"
                      borderRightColor="transparent"
                      borderBottomColor="white"
                      zIndex={1}
                      position="absolute"
                      bottom="-8px"
                      alignItems="center"
                      alignSelf="center"
                      flex={1}
                      style={styles.tooltipTriangle}
                    />
                  </View>
                )}
                <View
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  position="relative"
                  bgColor="primary.500"
                  opacity={!isLoaded || disableNextBtn ? '0.5' : '1'}
                >
                  {!isLoaded ? (
                    <Spinner color={'white'} />
                  ) : (
                    <>
                      <Text marginRight="5px" color="white">
                        {showBtnText()}
                      </Text>
                      <Ionicons
                        name="chevron-forward"
                        size={18}
                        color="white"
                      />
                    </>
                  )}
                </View>
              </Pressable>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
