import { DataSnapshot } from "firebase/database";
// import { TaskFeedItem, TaskNugget } from "@know/transformers";
import { FirebaseAPI } from "..";
// import { FBTaskFeedItem } from "./types";
import { raiseNuggetAnalytics } from "../Analytics";

export function listenToIssueMuteStatus(
  fbAPI: FirebaseAPI,
  issueId: string,
  cb: (isIssueMuted: boolean) => any
) {
  const pathRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("mutedIssues")),
    `${issueId}`
  );

  const handler = (snap: DataSnapshot) => {
    const isIssueMuted = snap.val() ? true : false;
    cb(isIssueMuted);
  };

  return fbAPI.listenOnNodeRef(pathRef, "value", handler);
}

export function listenToIssueNugget(
  fbAPI: FirebaseAPI,
  nuggetId: string,
  cb: (data: any | null) => any
) {
  const pathRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("nuggets")),
    `tasklist/${nuggetId}`
  );

  const handler = (snap: DataSnapshot) => {
    const issueNugget = snap.val() ?? {};
    cb(issueNugget);
  };
  return fbAPI.listenOnNodeRef(pathRef, "value", handler);
}

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: {
  [id: string]: () => void;
} = {};

export function subscribeToUserIssuesFeed(
  fbAPI: FirebaseAPI,
  cb: (nugId: string, feedItem: any | null) => void
) {
  const userFeedRef = fbAPI.getChild(
    fbAPI.getNodeRef(fbAPI.getCommonPath("currentUserFeed")),
    "tasklist"
  );

  function listenerHandler(_listenerEvenType: string) {
    return async (userFeedSnapshot: DataSnapshot) => {
      const issueFeed: any = (await userFeedSnapshot.val()) || null;

      const id = userFeedSnapshot.key as string;
      if (!id) {
        return;
      } else if (_listenerEvenType === typeCR) {
        cleanups[id]?.();
        return cb(id, null);
      }
      if (issueFeed?.createdAt) {
        if (!issueFeed.isReceived && _listenerEvenType === typeCA) {
          // Raise received analytics...
          raiseNuggetAnalytics({
            fbAPI,
            type: "received",
            ...issueFeed,
            nuggetId: id,
            classificationType: "tasklist",
          });
        }

        const author = await fbAPI.getUserDetails(issueFeed.author);
        cleanups[id]?.();
        cleanups[id] = listenToIssueNugget(fbAPI, id, (nugget) => {
          if (nugget) {
            cb(id, {
              ...issueFeed,
              author,
              nugget,
              id,
            });
          } else {
            cb(id, null);
          }
        });
      } else {
        cb(id, null);
      }
    };
  }

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(userFeedRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(userFeedRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(userFeedRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribeIssuesNuggets = () => {
  for (const c of Object.keys(cleanups)) {
    cleanups[c]?.();
  }
  cleanups = {};
};
