import { FirebaseAPI, addTaskProgressFormDraft, raiseAnalytics, removeTaskProgressUpdate, updateTaskNuggetFormSubmitStatus, closeIssue, addIssueFormProgressUpdate } from "..";
import { shareNugget } from "../Share";
import {
  checkForLocationPermission,
  getLocation,
  getTimezoneIdentifier,
  getUtcOffsetSec,
} from "../utils";

export { getLocation };

export const addDraftForParentNugget = async (
  fbApi: FirebaseAPI,
  formId: string,
  formName: string,
  responseId: string,
  parentId: string,
  parentType: string,
  userName: string
) => {
  if (parentType === 'tasks'){
    await addTaskProgressFormDraft(fbApi, parentId, formId, formName, responseId, userName)
  }
}


export const initiateNewResponse = async (
  dbAPi: FirebaseAPI,
  formId: string,
  options?: { parentId?: string; parentType?: string, skipDraft?: boolean }
): Promise<string> => {
  const newResponseRef = await dbAPi.pushToNode(
    `${dbAPi.getCommonPath("currentUserFormResponses")}/${formId}`,
    {
      startedAt: dbAPi.getServerTimestamp(),
      ...{
        parentId: options?.parentId ?? null,
        parentType: options?.parentType ?? null,
      },
    }
  );

  const responseId = newResponseRef.key ?? Date.now() + "";

  if (!options?.skipDraft){
  await dbAPi.fbSet(
      `${dbAPi.getCommonPath("currentUserFormDrafts")}/${formId}/${responseId}`,
      dbAPi.getServerTimestamp()
    );
  }

  return responseId;
};

export const writeFormResponses = async (
  dbAPi: FirebaseAPI,
  formId: string,
  responseId: string,
  userId: string,
  response: any
) => {
  await dbAPi.fbUpdate(
    `${dbAPi.getCommonPath(
      "formUserResponses"
    )}/${userId}/${formId}/${responseId}/responses`,
    response
  );
};

const updateUserFormResponse = async (
  dbApi: FirebaseAPI,
  formId: string,
  responseId: string,
  userId: string,
  update: any
) => {
  const responsePath = `${dbApi.getCommonPath(
    "formUserResponses"
  )}/${userId}/${formId}/${responseId}`;

  return dbApi.fbUpdate(responsePath, update);
};

const setUserFormResponse = async (
  dbApi: FirebaseAPI,
  formId: string,
  responseId: string,
  userId: string,
  update: any
) => {
  const responsePath = `${dbApi.getCommonPath(
    "formUserResponses"
  )}/${userId}/${formId}/${responseId}`;

  return dbApi.fbSet(responsePath, update);
};

const removeDraft = async (
  dbApi: FirebaseAPI,
  formId: string,
  responseId: string
) => {

  const responsePath = `${dbApi.getCommonPath(
    "currentUserFormResponses"
  )}/${formId}/${responseId}`;

  const [parentId, parentType] = await Promise.all([
    dbApi.getValue(
      dbApi.getNodeRef(`${responsePath}/parentId`)
    ),
    dbApi.getValue(
      dbApi.getNodeRef(`${responsePath}/parentType`)
    ),
  ])

  if (parentId && parentType){
    if (parentType === 'tasks'){
      await removeTaskProgressUpdate(dbApi, parentId, responseId)
    }
  }

  const draftPath = `${dbApi.getCommonPath(
    "currentUserFormDrafts"
  )}/${formId}/${responseId}`;


  return dbApi.fbSet(draftPath, null);
};

export const deleteDraft = async (
  dbApi: FirebaseAPI,
  formId: string,
  responseId: string
) => {
  await removeDraft(dbApi, formId, responseId)
  await setUserFormResponse(
    dbApi,
    formId,
    responseId,
    dbApi.getLoggedInUserId() ?? "user-id",
    null
  )
};

const updateActionOn = (
  dbApi: FirebaseAPI,
  {
    formId,
    responseId,
    isWF = false,
  }: { formId: string; responseId: string; isWF?: boolean }
) => {
  if (!isWF) {
    const actionOnPath = `${dbApi.getCommonPath(
      "currentUserFormLookups"
    )}/actionOn`;

    return dbApi.fbSet(
      `${actionOnPath}/${formId}/lastFilled`,
      dbApi.getServerTimestamp()
    );
  } else {
    const actionOnPath = `${dbApi.getCommonPath(
      "currentUserFormLookups"
    )}/actionOn/${formId}-${responseId}`;

    return dbApi.fbSet(actionOnPath, null);
  }
};

const removeReceivedResponses = (
  dbApi: FirebaseAPI,
  formId: string,
  responseId: string
) => {
  const receivedResponsesPath = `${dbApi.getCommonPath(
    "currentUserFormReceivedResponses"
  )}/${formId}/${responseId}`;

  return dbApi.fbSet(receivedResponsesPath, null);
};

const removeReceivedForms = (
  dbApi: FirebaseAPI,
  formId: string,
  responseId: string
) => {
  const receivedFormsPath = `${dbApi.getCommonPath(
    "currentUserFormReceivedForms"
  )}/${formId}/status/${responseId}`;

  return dbApi.fbSet(receivedFormsPath, null);
};

export const submitFormResponses = async (
  dbAPi: FirebaseAPI,
  formId: string,
  responseId: string,
  {
    requireLocation,
    isRejected = false,
    isWF = false,
    initiatorId: _initiatorId,
    parentId,
    parentType,
    formName,
    authorDetails,
    onLocationAttempt,
    onLocationAcquired,
    onLocationRejected,
  }: {
    requireLocation: boolean;
    isRejected?: boolean;
    isWF?: boolean;
    initiatorId?: string;
    parentId?: string;
    parentType?: string;
    formName: string;
    authorDetails: any;
    onLocationAttempt?: () => void;
    onLocationAcquired?: () => void;
    onLocationRejected?: () => void;
  }
) => {
  const update: any = {
    isSubmitted: true,
    tzOffset: getUtcOffsetSec(),
    submittedAt: dbAPi.getServerTimestamp(),
    lastSubmitterId: dbAPi.getLoggedInUserId(),
    st: dbAPi.getServerTimestamp(),
    tz: getTimezoneIdentifier(),
    rejectedBy: isRejected ? dbAPi.getLoggedInUserId() : null,
  };
  if (requireLocation) {
    try {
      const [currentState] = await checkForLocationPermission({
        onGranted: onLocationAcquired,
      });
      if (currentState === "prompt") {
        onLocationAttempt && onLocationAttempt();
      }
      if (currentState === "denied") {
        throw new Error("LocationNotAllowed");
      }
      const location = await getLocation();
      update.location = location;
      onLocationAcquired && onLocationAcquired();
    } catch (err: any) {
      if (err.message === "LocationNotAvailable") {
        update.location = {
          message: "No location util found",
        };
      } else {
        if (err.message === "LocationNotAllowed") {
          onLocationRejected && onLocationRejected();
        }
        throw err;
      }
    }
  }

  const initiatorId = _initiatorId ?? dbAPi.getLoggedInUserId() ?? "user-id";

  await Promise.all([
    updateUserFormResponse(dbAPi, formId, responseId, initiatorId, update),
    removeDraft(dbAPi, formId, responseId),
    updateActionOn(dbAPi, { formId, isWF, responseId }),
    dbAPi.raiseGamification("submitted", "form", formId),
    raiseAnalytics({
      fbAPI: dbAPi,
      type: "consumed",
      formItem: {
        id: formId,
        createdAt: Date.now(),
        category: "",
        name: "",
      },
    }),
    removeReceivedResponses(dbAPi, formId, responseId),
    removeReceivedForms(dbAPi, formId, responseId),
  ]);

  if (parentId && parentType){
    if (parentType === 'tasks'){
      await updateTaskNuggetFormSubmitStatus(dbAPi, parentId, true)
    } else if (parentType === 'issues') {
      const issuePath = dbAPi.getMultiPath(`nuggets/tasklist/${parentId}`);
      const issueNugget = await dbAPi.getValue(dbAPi.getNodeRef(issuePath));

      if (issueNugget?.status?.toLowerCase() === 'open'){
        const closeFormOptions = {
          formId, 
          responseId,
          title: formName,
          userName: authorDetails.userName
        }

        await Promise.all([
          // add closure report submission to issue updates
          addIssueFormProgressUpdate(dbAPi, parentId, 'close_form', closeFormOptions),
          // closing issue without remarks and images
          closeIssue(dbAPi, parentId, authorDetails, issueNugget, {})
        ])
      }
            
    }
  }
};

export const sendToNextFormResponse = async (
  dbAPi: FirebaseAPI,
  formId: string,
  responseId: string,
  shareRequest: any,
  {
    sectionId,
    requireLocation,
    initiatorId: _initiatorId,
    isWF = false,
    parentId,
    parentType,
    onLocationAttempt,
    onLocationAcquired,
    onLocationRejected,
  }: {
    sectionId?: string;
    requireLocation: boolean;
    initiatorId?: string;
    isWF?: boolean;
    parentId?: string;
    parentType?: string;
    onLocationAttempt?: () => void;
    onLocationAcquired?: () => void;
    onLocationRejected?: () => void;
  }
) => {
  const update: any = {
    tzOffset: getUtcOffsetSec(),
    userId: dbAPi.getLoggedInUserId(),
    st: dbAPi.getServerTimestamp(),
    lastSubmittedAt: dbAPi.getServerTimestamp(),
    sectionId: sectionId ?? null,
    tz: getTimezoneIdentifier(),
  };

  if (requireLocation) {
    try {
      onLocationAttempt && onLocationAttempt();
      const location = await getLocation();
      update[`locations/${sectionId}`] = location;
      onLocationAcquired && onLocationAcquired();
    } catch (err: any) {
      if (err.message === "LocationNotAvailable") {
        update[`locations/${sectionId}`] = {
          message: "No location util found",
        };
      } else {
        if (err.message === "LocationNotAllowed") {
          onLocationRejected && onLocationRejected();
        }
        throw err;
      }
    }
  }

  const initiatorId = _initiatorId ?? dbAPi.getLoggedInUserId() ?? "user-id";

  const sharePayload = {
    initiatorId,
    responseId,
    ...shareRequest,
  };

  await Promise.all([
    updateUserFormResponse(dbAPi, formId, responseId, initiatorId, update),
    updateActionOn(dbAPi, { formId, isWF, responseId }),
    removeDraft(dbAPi, formId, responseId),
    dbAPi.raiseGamification("sendToNext", "form", formId),
    removeReceivedResponses(dbAPi, formId, responseId),
    removeReceivedForms(dbAPi, formId, responseId),
  ])

  await shareNugget(dbAPi, "form", formId, dbAPi.getLoggedInUserId() ?? '-', sharePayload);

  if (parentId && parentType){
    if (parentType === 'tasks'){
      await updateTaskNuggetFormSubmitStatus(dbAPi, parentId, true)
    }
  }
};

export const getFormResponse = (
  fbAPI: FirebaseAPI,
  formId: string,
  initiatorId: string,
  responseId: string
): any => {
  const formResponseRef = fbAPI.getNodeRef(
    fbAPI.getCommonPath("formUserResponses")
  );

  return fbAPI.getValue(
    fbAPI.getChild(formResponseRef, `${initiatorId}/${formId}/${responseId}`)
  );
};

export const getFormPaperTrail = (
  fbAPI: FirebaseAPI,
  formId: string,
  responseId: string
): Promise<any> => {
  const formResponseRef = fbAPI.getNodeRef(
    fbAPI.getCommonPath("formPaperTrails")
  );

  return fbAPI.getValue(
    fbAPI.getChild(formResponseRef, `${formId}/${responseId}`)
  );
};

export const raiseShareFormResponseToLocationLeaderRequest = (
  fbAPI: FirebaseAPI,
  formId: string,
  responseId: string,
  { userId, userName }: { userId: string; userName: string }
): any => {
  const payload = {
    type: "form",
    status: "notifyLeaders",
    userId,
    nuggetId: formId,
    responseId: responseId,
    userName,
    organization: fbAPI.organization,
  };

  return fbAPI.pushToNode(`statusChangeRequests`, payload);
};

export const raiseReturnFormResponseRequest = (
  fbAPI: FirebaseAPI,
  formId: string,
  responseId: string,
  {
    userId,
    returnMessage,
    sectionId,
  }: { userId: string; returnMessage: string; sectionId: string }
): any => {
  const payload = {
    createdAt: fbAPI.getServerTimestamp(),
    formId,
    organization: fbAPI.organization,
    responseId,
    returnMessage,
    userId,
    sectionId,
  };

  return fbAPI.pushToNode(`/RPC/forms/request`, payload);
};