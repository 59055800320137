import core from './core';
import buttons from './buttons';
import forms from './forms';
import menus from './menus';
import messages from './messages';

export default {
  core,
  forms,
  menus,
  messages,
  buttons,
};
