import React from 'react';
import { Icon, createIcon } from 'native-base';
import { Path } from 'react-native-svg';

export const LowSeverityIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="-2 -4 19 16">
    <Path
      d="M0.4403 0.565299C0.48094 0.524557 0.529218 0.492231 0.58237 0.470176C0.635522 0.44812 0.692503 0.436768 0.75005 0.436768C0.807596 0.436768 0.864577 0.44812 0.917729 0.470176C0.970881 0.492231 1.01916 0.524557 1.0598 0.565299L6.00005 5.50642L10.9403 0.565299C10.981 0.524622 11.0293 0.492356 11.0824 0.470341C11.1356 0.448327 11.1925 0.436997 11.25 0.436997C11.3076 0.436997 11.3645 0.448327 11.4177 0.470341C11.4708 0.492356 11.5191 0.524622 11.5598 0.565299C11.6005 0.605976 11.6327 0.654267 11.6548 0.707414C11.6768 0.760561 11.6881 0.817524 11.6881 0.87505C11.6881 0.932575 11.6768 0.989538 11.6548 1.04269C11.6327 1.09583 11.6005 1.14412 11.5598 1.1848L6.3098 6.4348C6.26916 6.47554 6.22088 6.50787 6.16773 6.52992C6.11458 6.55198 6.0576 6.56333 6.00005 6.56333C5.9425 6.56333 5.88552 6.55198 5.83237 6.52992C5.77922 6.50787 5.73094 6.47554 5.6903 6.4348L0.4403 1.1848C0.399557 1.14416 0.367232 1.09588 0.345176 1.04273C0.323121 0.989577 0.311768 0.932596 0.311768 0.87505C0.311768 0.817503 0.323121 0.760522 0.345176 0.70737C0.367232 0.654218 0.399557 0.605939 0.4403 0.565299Z"
      fill="currentColor"
      stroke={'currentColor'}
      strokeWidth={0.5}
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </Icon>
);

export const MutedIcon = createIcon({
  viewBox: '0 0 15 15',
  path: [
    <Path
      d="M1.1538 0.731421C1.04119 0.751802 0.936485 0.803184 0.851462 0.879793C0.766438 0.956403 0.704463 1.0552 0.672499 1.1651C0.640535 1.27499 0.639849 1.39162 0.670518 1.50188C0.701187 1.61214 0.761996 1.71166 0.846112 1.78927L13.1538 14.097C13.2081 14.1639 13.2759 14.2188 13.3527 14.2579C13.4296 14.297 13.5138 14.3195 13.5999 14.324C13.686 14.3284 13.7721 14.3148 13.8526 14.2838C13.9331 14.2529 14.0062 14.2054 14.0671 14.1444C14.1281 14.0834 14.1755 14.0103 14.2064 13.9298C14.2373 13.8493 14.2509 13.7632 14.2464 13.6771C14.2418 13.5909 14.2192 13.5067 14.1801 13.4299C14.1409 13.3531 14.0861 13.2854 14.019 13.2311L9.4615 8.67419V1.53881C9.4615 0.760344 8.7815 0.565267 8.2498 1.09696L5.03811 4.25081L1.71196 0.923421C1.64844 0.855765 1.57044 0.803352 1.4838 0.770103C1.39716 0.736853 1.30412 0.72363 1.21165 0.731421C1.19238 0.730514 1.17308 0.730514 1.1538 0.731421ZM2.07688 5.07788C1.7378 5.07788 1.4615 5.35357 1.4615 5.69327V9.38557C1.4615 9.72404 1.7378 10.001 2.07688 10.001H4.19257L8.23073 13.9425C8.84611 14.5579 9.4615 14.2428 9.4615 13.404V11.8268L2.71134 5.07788H2.07688Z"
      fill="currentColor"
    />,
  ],
});

export const MediumIcon = (props: any) => {
  return (
    <Icon {...props} viewBox="0 0 14 15" fill="none">
      {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    > 
    </svg> */}
      <Path
        d="M3.0625 9.25H10.9375M3.0625 6H10.9375"
        stroke="#EA750A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ;
    </Icon>
  );
};

export const UpwardsChevron = (props: any) => {
  return (
    <Icon {...props} viewBox="0 0 14 15" fill="none">
      {/* <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      > */}
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69025 4.56506C6.73089 4.52431 6.77917 4.49199 6.83232 4.46993C6.88548 4.44788 6.94246 4.43652 7 4.43652C7.05755 4.43652 7.11453 4.44788 7.16768 4.46993C7.22083 4.49199 7.26911 4.52431 7.30975 4.56506L12.5598 9.81506C12.6419 9.89721 12.6881 10.0086 12.6881 10.1248C12.6881 10.241 12.6419 10.3524 12.5598 10.4346C12.4776 10.5167 12.3662 10.5629 12.25 10.5629C12.1338 10.5629 12.0224 10.5167 11.9403 10.4346L7 5.49343L2.05975 10.4346C1.9776 10.5167 1.86618 10.5629 1.75 10.5629C1.63382 10.5629 1.5224 10.5167 1.44025 10.4346C1.3581 10.3524 1.31195 10.241 1.31195 10.1248C1.31195 10.0086 1.3581 9.89721 1.44025 9.81506L6.69025 4.56506Z"
        fill="#F95757"
        stroke="#F95757"
        strokeWidth="0.5"
      />
    </Icon>
  );
};

export const MuteIcon = (props: any) => {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M10 3.33333L8.25833 5.075L10 6.81667M3.55833 2.5L2.5 3.55833L6.44167 7.5H2.5V12.5H5.83333L10 16.6667V11.0583L13.5417 14.6083C12.9833 15.0333 12.3583 15.3833 11.6667 15.5833V17.3083C12.8167 17.0417 13.8583 16.5167 14.7333 15.8L16.4417 17.5L17.5 16.4417L10 8.94167M15.8333 10C15.8333 10.7833 15.6667 11.5167 15.3833 12.2L16.6417 13.4583C17.2041 12.3922 17.4986 11.2053 17.5 10C17.5 6.43333 15 3.45 11.6667 2.69167V4.40833C14.075 5.125 15.8333 7.35833 15.8333 10ZM13.75 10C13.75 8.525 12.9167 7.25833 11.6667 6.64167V8.48333L13.7083 10.525C13.75 10.3583 13.75 10.175 13.75 10Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const UnmuteIcon = (props: any) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M9.66667 0.691406V2.40807C12.075 3.12474 13.8333 5.35807 13.8333 7.99974C13.8333 10.6414 12.075 12.8664 9.66667 13.5831V15.3081C13 14.5497 15.5 11.5664 15.5 7.99974C15.5 4.43307 13 1.44974 9.66667 0.691406ZM11.75 7.99974C11.75 6.52474 10.9167 5.25807 9.66667 4.64141V11.3331C10.9167 10.7414 11.75 9.46641 11.75 7.99974ZM0.5 5.49974V10.4997H3.83333L8 14.6664V1.33307L3.83333 5.49974H0.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const NotifyLocationLeader = (props: any) => {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M14.375 9.99916C14.8722 9.99916 15.3492 10.1967 15.7008 10.5483C16.0524 10.9 16.25 11.3769 16.25 11.8742V12.4992C16.25 14.3867 14.885 16.3179 12.48 17.1167C12.5246 16.8055 12.4884 16.488 12.3747 16.1948C12.2611 15.9017 12.0739 15.6427 11.8312 15.4429L10.6237 14.4204V12.4992C10.6237 11.5879 10.38 10.7342 9.95496 9.99916H14.375ZM7.95371 8.07416C8.13746 8.17166 8.31621 8.27916 8.48496 8.39916C9.27836 8.79059 10.1921 8.86105 11.0362 8.59588C11.8802 8.33071 12.5895 7.75034 13.0165 6.9755C13.4435 6.20067 13.5553 5.29104 13.3286 4.43586C13.102 3.58068 12.5544 2.8458 11.7997 2.38411C11.045 1.92243 10.1414 1.76949 9.27684 1.95711C8.41227 2.14473 7.65332 2.65846 7.15789 3.39143C6.66246 4.1244 6.46871 5.02016 6.61693 5.89236C6.76516 6.76455 7.24396 7.54601 7.95371 8.07416ZM1.87121 12.4992C1.87121 11.5046 2.2663 10.5508 2.96956 9.84751C3.67282 9.14425 4.62665 8.74916 5.62121 8.74916C6.61577 8.74916 7.5696 9.14425 8.27286 9.84751C8.97612 10.5508 9.37121 11.5046 9.37121 12.4992V14.9992L11.0212 16.3979C11.1186 16.4807 11.1883 16.5914 11.2209 16.715C11.2534 16.8386 11.2473 16.9693 11.2033 17.0893C11.1593 17.2093 11.0795 17.313 10.9748 17.3863C10.87 17.4596 10.7453 17.499 10.6175 17.4992H0.626213C0.498369 17.499 0.373661 17.4596 0.268909 17.3863C0.164157 17.313 0.0843994 17.2093 0.0403925 17.0893C-0.00361447 16.9693 -0.0097537 16.8386 0.0228027 16.715C0.0553592 16.5914 0.125045 16.4807 0.222463 16.3979L1.87246 14.9992V12.4992H1.87121ZM5.62121 19.9992C5.23356 19.9989 4.8555 19.8786 4.539 19.6548C4.2225 19.431 3.9831 19.1146 3.85371 18.7492H7.38996C7.26066 19.1149 7.02111 19.4315 6.70433 19.6554C6.38754 19.8793 6.00912 19.9994 5.62121 19.9992Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const UpdateSeverityIcon = (props: any) => {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M14.1668 10.8307V5.83073H15.8334V10.8307H14.1668ZM14.1668 14.1641V12.4974H15.8334V14.1641H14.1668ZM10.8334 10.8307V4.16406C10.8334 2.7474 9.75011 1.66406 8.33345 1.66406C6.91678 1.66406 5.83345 2.7474 5.83345 4.16406V10.8307C4.00011 12.2474 3.58345 14.8307 5.00011 16.6641C6.41678 18.4974 9.00011 18.9141 10.8334 17.4974C12.6668 16.0807 13.0834 13.4974 11.6668 11.6641C11.4168 11.3307 11.1668 11.0807 10.8334 10.8307ZM8.33345 3.33073C8.83345 3.33073 9.16678 3.66406 9.16678 4.16406V6.66406H7.50011V4.16406C7.50011 3.66406 7.83345 3.33073 8.33345 3.33073Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const IssueUpdateCommentIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 17 17">
    <Path
      d="M2.5 4.04478C2.5 3.6185 2.66934 3.20969 2.97076 2.90826C3.27219 2.60684 3.681 2.4375 4.10728 2.4375H13.751C14.1773 2.4375 14.5861 2.60684 14.8875 2.90826C15.1889 3.20969 15.3583 3.6185 15.3583 4.04478V10.4739C15.3583 10.9002 15.1889 11.309 14.8875 11.6104C14.5861 11.9119 14.1773 12.0812 13.751 12.0812H6.04727C5.83415 12.0812 5.62977 12.1659 5.4791 12.3167L3.18631 14.6095C3.13016 14.6657 3.05857 14.7041 2.98061 14.7197C2.90264 14.7353 2.8218 14.7274 2.74832 14.697C2.67485 14.6667 2.61203 14.6152 2.56783 14.5491C2.52362 14.483 2.50002 14.4053 2.5 14.3258V4.04478ZM5.31274 4.84842C5.20617 4.84842 5.10397 4.89076 5.02861 4.96611C4.95326 5.04147 4.91092 5.14367 4.91092 5.25024C4.91092 5.35681 4.95326 5.45902 5.02861 5.53437C5.10397 5.60973 5.20617 5.65207 5.31274 5.65207H12.5455C12.6521 5.65207 12.7543 5.60973 12.8296 5.53437C12.905 5.45902 12.9473 5.35681 12.9473 5.25024C12.9473 5.14367 12.905 5.04147 12.8296 4.96611C12.7543 4.89076 12.6521 4.84842 12.5455 4.84842H5.31274ZM5.31274 6.85753C5.20617 6.85753 5.10397 6.89986 5.02861 6.97522C4.95326 7.05057 4.91092 7.15278 4.91092 7.25935C4.91092 7.36592 4.95326 7.46812 5.02861 7.54348C5.10397 7.61883 5.20617 7.66117 5.31274 7.66117H12.5455C12.6521 7.66117 12.7543 7.61883 12.8296 7.54348C12.905 7.46812 12.9473 7.36592 12.9473 7.25935C12.9473 7.15278 12.905 7.05057 12.8296 6.97522C12.7543 6.89986 12.6521 6.85753 12.5455 6.85753H5.31274ZM5.31274 8.86663C5.20617 8.86663 5.10397 8.90896 5.02861 8.98432C4.95326 9.05968 4.91092 9.16188 4.91092 9.26845C4.91092 9.37502 4.95326 9.47723 5.02861 9.55258C5.10397 9.62794 5.20617 9.67027 5.31274 9.67027H9.33095C9.43752 9.67027 9.53972 9.62794 9.61508 9.55258C9.69044 9.47723 9.73277 9.37502 9.73277 9.26845C9.73277 9.16188 9.69044 9.05968 9.61508 8.98432C9.53972 8.90896 9.43752 8.86663 9.33095 8.86663H5.31274Z"
      fill="currentColor"
    />
  </Icon>
);

export const IssueAdditionalInfoIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 17 16" width="17" height="16">
    <Path
      d="M11.1667 1.33203L14.5 4.66536V14.004C14.4998 14.1795 14.43 14.3477 14.3059 14.4717C14.1817 14.5957 14.0135 14.6654 13.838 14.6654H3.162C2.98692 14.6641 2.81934 14.5941 2.69548 14.4704C2.57161 14.3466 2.5014 14.1791 2.5 14.004V1.99336C2.5 1.62803 2.79667 1.33203 3.162 1.33203H11.1667ZM7.83333 4.66536V5.9987H9.16667V4.66536H7.83333ZM7.83333 7.33203V11.332H9.16667V7.33203H7.83333Z"
      fill="currentColor"
    />
  </Icon>
);

export const DeleteAttachmentIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 20 20" width="20" height="20">
    <Path
      d="M15.7292 6.09375H13.6458V4.79167C13.6458 4.21712 13.1787 3.75 12.6042 3.75H7.39583C6.82129 3.75 6.35417 4.21712 6.35417 4.79167V6.09375H4.27083C3.98275 6.09375 3.75 6.3265 3.75 6.61458V7.13542C3.75 7.20703 3.80859 7.26562 3.88021 7.26562H4.86328L5.2653 15.778C5.29134 16.333 5.75033 16.7708 6.30534 16.7708H13.6947C14.2513 16.7708 14.7087 16.3346 14.7347 15.778L15.1367 7.26562H16.1198C16.1914 7.26562 16.25 7.20703 16.25 7.13542V6.61458C16.25 6.3265 16.0173 6.09375 15.7292 6.09375ZM12.474 6.09375H7.52604V4.92187H12.474V6.09375Z"
      fill="#F95757"
    />
  </Icon>
);

export const ReportButtonPlusIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 24 24" width="24" height="24">
    <Path
      d="M12 19.5V12V19.5ZM12 12V4.5V12ZM12 12H19.5H12ZM12 12H4.5H12Z"
      fill="white"
    />
    <Path
      d="M12 19.5V12M12 12V4.5M12 12H19.5M12 12H4.5"
      stroke="white"
      strokeWidth={'1.5'}
      strokeLinecap="round"
    />
  </Icon>
);
