import { useCallback, useContext, useEffect, useState } from 'react';

import { KNOWDBContext, useDebounce, useSetRecoilState } from '@know/ui';

import {
  userFormFeedFoldersAtom,
  userFormFeedItemsAtom,
  isFormFeedLoadingAtom,
} from './store';

import {
  IFormFeedItem,
  cleanUpSubscribedFormsCategories,
  subscribeToUserForms,
} from '@know/db';
import { fromPairs, omit, toPairs } from 'lodash';
import { IFormFolderItem } from '@know/transformers';

const EMPTY_FUNCTION = () => {};

export function useSubscribeToFormFeed() {
  const { currentDBApi } = useContext(KNOWDBContext);

  const setIsDataLoading = useSetRecoilState(isFormFeedLoadingAtom);

  const setUserFormFeedItems = useSetRecoilState(userFormFeedItemsAtom);
  const setUserFormFolders = useSetRecoilState(userFormFeedFoldersAtom);

  const [dataLastUpdated, setDataLastUpdated] = useState<number>(0);

  const debouncedDataLastUpdated = useDebounce(dataLastUpdated, 700);

  useEffect(() => {
    if (debouncedDataLastUpdated) {
      setIsDataLoading(false);
    }
  }, [debouncedDataLastUpdated, setIsDataLoading]);

  const [localFeedItems, setLocalFeedItems] = useState<
    Record<string, IFormFeedItem>
  >({});

  const debouncedLocalFeedItems = useDebounce(localFeedItems, 500);

  const [localFolderItems, setLocalFolderItems] = useState<IFormFolderItem>({});

  const debouncedLocalFolderItems = useDebounce(localFolderItems, 500);

  useEffect(() => {
    setUserFormFeedItems(debouncedLocalFeedItems);
  }, [debouncedLocalFeedItems, setUserFormFeedItems]);

  useEffect(() => {
    setUserFormFolders(debouncedLocalFolderItems);
  }, [debouncedLocalFolderItems, setUserFormFolders]);

  const subScriptionHandler = useCallback(
    (formId: string, feedItem: IFormFeedItem | null) => {
      setDataLastUpdated(Date.now());
      setLocalFeedItems((feedItems) => {
        // // don't want to add to form feed list if it is in a folder already
        // if (feedItem && (feedItem?.folderIds ?? []).length) {
        //   return feedItems;
        // }
        let newItems;
        if (feedItem) {
          newItems = {
            ...feedItems,
            [formId]: feedItem,
          };
        } else {
          newItems = omit(feedItems, [formId]);
        }
        return newItems;
      });

      setLocalFolderItems((folders) => {
        setDataLastUpdated(Date.now());
        let newFolders = { ...folders };
        if (feedItem) {
          const folderIds = feedItem.folderIds || [];
          // handle form removed from folder
          newFolders = fromPairs(
            toPairs(newFolders).map(([folderId, folderItems]) => {
              if (folderIds.includes(folderId)) {
                return [
                  folderId,
                  {
                    ...folderItems,
                    [formId]: true,
                  },
                ];
              } else {
                return [folderId, omit(folderItems, [formId])];
              }
            })
          );
          folderIds.forEach((folderId) => {
            newFolders = {
              ...newFolders,
              [folderId]: {
                ...(newFolders[folderId] || {}),
                [formId]: true,
              },
            };
          });
        } else {
          Object.keys(newFolders).forEach((folderId) => {
            newFolders[folderId] = omit(newFolders[folderId], [formId]);
            // If the folder is empty after deleting the formId, delete the folder from newFormFolders state
            if (Object.keys(newFolders[folderId]).length === 0) {
              newFolders = omit(newFolders, [folderId]);
            }
          });
        }
        return newFolders;
      });
    },
    []
  );

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribe = subscribeToUserForms(
        currentDBApi,
        subScriptionHandler
      );
      setTimeout(() => {
        setDataLastUpdated(Date.now());
      }, 2000);
      // setTimeout(() => setIsDataLoading(false), 2000);
      // Return statement has un listening callback.
      return () => {
        unSubScribe();
        cleanUpSubscribedFormsCategories();
      };
    }
    return EMPTY_FUNCTION;
  }, [currentDBApi, setIsDataLoading, subScriptionHandler]);
}
