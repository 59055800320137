import React from 'react';
import {
  useDebounceLoading,
  useDebounceSetData,
  useKNOWDBContext,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  Text,
  // TaskStatus,
  // TaskDeadline,
  useNavigation,
  // TaskStatus,
  useKNOWValueContextProvider,
  StackActions,
} from '@know/ui';
import {
  sentIssueAtom,
  sentIssueFilterTextAtom,
  sentIssuesItemsSelector,
} from './state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  cleanUpSubscribeToSentIssuesNuggets,
  subscribeToUserSentIssuesFeed,
} from '@know/db';
import { TaskFeedItem, TransformedIssueItem } from '@know/transformers';
import { omit } from 'lodash';
import { compareIssueSeverity, getDateParts } from '../utils';

import { IssueStatus } from '@know/ui';
import { IssueSeverityType } from '@know/ui';

const EMPTY_FUNCTION = () => {};

export const useSentIssues = () => {
  const { currentDBApi } = useKNOWDBContext();
  const navigation = useNavigation();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [filterText, onFilterTextChange] = useRecoilState(
    sentIssueFilterTextAtom
  );

  const setSentIssuesFeedItemsAtom = useSetRecoilState(sentIssueAtom);

  const issueItems = useRecoilValue(sentIssuesItemsSelector);

  const setSentIssueFeedItems = useDebounceSetData(
    {},
    setSentIssuesFeedItemsAtom,
    500
  );

  const stopLoading = useCallback(() => {
    setIsDataLoading(false);
  }, []);

  const setDataLastUpdated = useDebounceLoading(stopLoading, 700);

  const { isTabletSize, isPhoneSize, isLargePhoneSize } =
    useKNOWValueContextProvider();
  const isSmallScreen = isTabletSize || isPhoneSize || isLargePhoneSize;

  const subScriptionHandler = useCallback(
    (nugId: string, issueFeedItem: TaskFeedItem | null) => {
      setSentIssueFeedItems((feedItems: any) => {
        setDataLastUpdated(Date.now());
        let newItems;
        if (issueFeedItem) {
          newItems = {
            ...feedItems,
            [nugId]: issueFeedItem,
          };
        } else {
          newItems = omit(feedItems, [nugId]);
        }
        return newItems;
      });
    },
    [setDataLastUpdated, setSentIssueFeedItems]
  );

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribe = subscribeToUserSentIssuesFeed(
        currentDBApi,
        subScriptionHandler
      );
      setTimeout(() => setDataLastUpdated(Date.now()), 2000);
      // Return statement has un listening callback.
      return () => {
        unSubScribe();
        cleanUpSubscribeToSentIssuesNuggets();
      };
    }
    return EMPTY_FUNCTION;
  }, [currentDBApi, setDataLastUpdated, subScriptionHandler]);

  const columns = useMemo(
    () => [
      {
        id: 'issueId',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            ID
          </Text>
        ),
        accessor: 'issueId', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const id = row?.values?.issueId;
          if (!id) {
            return (
              <Text fontFamily={'tableCell'} fontWeight={400}>
                {''}
              </Text>
            );
          }

          return (
            <Text fontFamily={'tableCell'} fontWeight={400} mr={'12px'}>
              {id}
            </Text>
          );
        },
      },
      {
        id: 'title',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Title
          </Text>
        ),
        accessor: 'title', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          return (
            <Text fontFamily={'tableCell'} fontWeight={400} mr={'12px'}>
              {row?.values?.title}
            </Text>
          );
        },
        flex: 4,
      },
      {
        id: 'issueType',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Issue Type
          </Text>
        ),
        accessor: 'issueType', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          const issueType = row?.values?.issueType;
          return (
            <Text fontFamily={'tableCell'} mr={'12px'} maxW={'100%'}>
              {issueType}
            </Text>
          );
        },
        flex: 2,
        minWidth: 160,
      },
      {
        id: 'severity',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Severity
          </Text>
        ),
        accessor: 'severity', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => (
          <IssueSeverityType type={row?.values?.severity?.toLowerCase()} />
        ),
        enableSorting: false,
        flex: 2,
        minWidth: 110,
        sortType: compareIssueSeverity,
      },
      {
        id: 'dateReported',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Date Reported
          </Text>
        ),
        accessor: 'dateReported',
        Cell: ({ row }: { row: any }) => {
          const [day, time] = getDateParts(row?.values?.dateReported);

          return (
            <Text fontFamily={'tableCell'} fontWeight={400} color={'gray.700'}>
              {day}, {time}
            </Text>
          );
        },
        flex: 2,
      },
      {
        id: 'status',
        Header: (
          <Text fontWeight={700} fontFamily={'tableHeader'}>
            Status
          </Text>
        ),
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ row }: { row: any }) => {
          // const resolvedAt = row?.values?.resolvedAt;
          return <IssueStatus type={row?.values?.status.toLowerCase()} />;
        },
        flex: 2,
      },
    ],
    []
  );

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'dateReported', desc: true }],
    }),
    []
  );

  const onClick = useCallback(
    (item: TransformedIssueItem) => {
      // we have to do this to fix a weird bug related to focus ref
      // https://knownuggets.atlassian.net/browse/KNOW-1279
      // 9. From Task list page - filter form A and open it - now tap on complete button - control is not passing to alert ( without filter if i directly open the task means, i can able to complete the task) --> Same for re-open task also from completed tab
      navigation.dispatch(
        StackActions.replace(
          'issues-details' as never,
          {
            issueId: item.nuggetId,
            shareId: item.shareId,
          } as never
        )
      );
    },
    [navigation]
  );

  return {
    isDataLoading,
    filterText,
    onFilterTextChange,
    issueItems,
    initialState,
    columns,
    onClick,
    isSmallScreen,
  };
};
