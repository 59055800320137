import { Avatar, Box } from 'native-base';
import React, { ReactElement } from 'react';

import images from '../Images';

import type { IBoxProps } from 'native-base';
interface Props extends IBoxProps {
  size?: '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | string | number;
  color?: string;
}

export const Logo = ({
  size = 'md',
  position,
  color = '#ffffff00',
}: Props): ReactElement => {
  return (
    <Box position={position}>
      <Avatar
        backgroundColor={color}
        size={size}
        source={{ uri: images.logo }}
      />
    </Box>
  );
};
