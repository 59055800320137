export const pluralize = ({
  singular,
  plural,
  count,
  showCount,
  zero,
}: {
  singular: string;
  plural?: string;
  count: number;
  showCount?: boolean;
  zero?: string;
}): string => {
  if (count === 0 && zero) return zero;

  const output = count === 1 ? singular : plural || `${singular}s`;

  return showCount ? `${count} ${output}` : output;
};
