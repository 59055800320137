import {
  Text,
  HStack,
  KNOWIcon,
  KNPressable,
  VStack,
  ResponseStatus,
  View,
  Box,
} from '..';

import { useKNOWValueContextProvider } from '@know/ui';

import React from 'react';
import { ISentResponseItem, ISectionDataProps } from '@know/transformers';
import { useSentFormItemData } from './useSentFormItemData';
import { DateTime } from '../DateTime';

interface ISentFormResponseItemProps {
  item: ISentResponseItem;
  type: string;
  section: ISectionDataProps<ISentResponseItem>;
  onPress: (
    item: ISentResponseItem,
    type: string,
    section?: ISectionDataProps<ISentResponseItem>
  ) => void;
}

export function SentFormResponseItem({
  item,
  section,
  type,
  onPress,
}: ISentFormResponseItemProps) {
  const {
    isWorkflow,
    editedText,
    status,
    byText,
    userName,
    displayText,
    time,
  } = useSentFormItemData(item, section);

  function handleOnPress() {
    onPress && onPress(item, type, section);
  }

  const { isPhoneSize, isLargePhoneSize } = useKNOWValueContextProvider();

  const isMobile = isPhoneSize || isLargePhoneSize;

  if (isWorkflow) {
    return (
      <KNPressable
        onPress={handleOnPress}
        flex={1}
        _hover={{
          bg: 'gray.200',
        }}
      >
        <HStack
          flex={1}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          pr={'20px'}
          pl={'24px'}
          py={'8px'}
        >
          <KNOWIcon
            color={'gray.900'}
            mr={2}
            mt={'2px'}
            name={'filetext1'}
            size="4"
            textAlign="center"
          />
          <Box
            flex={1}
            display={'flex'}
            justifyContent={isMobile ? 'flex-start' : 'space-between'}
            flexDir={isMobile ? 'column' : 'row'}
            flexWrap={'wrap'}
          >
            <VStack>
              <HStack>
                <Text
                  fontFamily={'openSemiBold'}
                  display={'flex'}
                  alignItems={'center'}
                  color={'purple.400'}
                  fontWeight={600}
                  mr={'4px'}
                >
                  {editedText}
                </Text>
                <Text
                  fontFamily={'open'}
                  display={'flex'}
                  alignItems={'center'}
                  color={'gray.900'}
                >
                  {displayText}
                </Text>
              </HStack>
              <HStack mt={'6px'} alignItems={'center'}>
                <View>
                  <ResponseStatus status={status} />
                </View>
                <Text color={'gray.700'} mx={1}>
                  {byText}
                </Text>
                <Text
                  color={'gray.700'}
                  fontFamily={'openSemiBold'}
                  fontWeight={600}
                  noOfLines={1}
                >
                  {userName}
                </Text>
              </HStack>
            </VStack>
            <Text
              fontFamily={'open'}
              color={'gray.700'}
              fontSize={'14px'}
              mt={isMobile ? '6px' : undefined}
            >
              <DateTime time={time as number} />
            </Text>
          </Box>
        </HStack>
      </KNPressable>
    );
  }
  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        flex={1}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent={'space-between'}
        pr={'20px'}
        pl={'24px'}
        py={'12px'}
      >
        <KNOWIcon
          color={'gray.900'}
          mr={2}
          mt={isMobile ? '2px' : undefined}
          name={'filetext1'}
          size="4"
          textAlign="center"
        />
        <Box
          flex={1}
          display={'flex'}
          justifyContent={isMobile ? 'flex-start' : 'space-between'}
          flexDir={isMobile ? 'column' : 'row'}
        >
          <HStack>
            <Text
              fontFamily={'openSemiBold'}
              display={'flex'}
              alignItems={'center'}
              color={'purple.400'}
              fontWeight={600}
              mr={'4px'}
            >
              {editedText}
            </Text>
            <Text
              fontFamily={'open'}
              display={'flex'}
              alignItems={'center'}
              color={'gray.900'}
            >
              {displayText}
            </Text>
          </HStack>
          <Text fontFamily={'open'} color={'gray.700'} fontSize={'14px'}>
            <DateTime time={time as number} />
          </Text>
        </Box>
      </HStack>
    </KNPressable>
  );
}
