import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Pressable,
  Box,
  Text,
  ExpoEntypoIcon,
  useKNOWValueContextProvider,
} from '@know/ui';
import { useItemSize } from './useItemSize';
import { FlatList } from 'native-base';
import { StyleSheet, TouchableWithoutFeedback, ViewToken } from 'react-native';
import { last } from 'lodash';
interface HorizontalListViewProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactElement | null;
  keyExtractor: (item: T) => string;
  title?: string;
  onClickItem?: (item: T, index: number) => void;
  itemMargin?: number;
  getListHeight?: (width: number) => number;
  disableNavButtons?: boolean;
}

export function HorizontalListView<Item>({
  items,
  renderItem,
  keyExtractor,
  title,
  onClickItem,
  itemMargin = 12,
  getListHeight,
  disableNavButtons = false,
}: HorizontalListViewProps<Item>) {
  const listRef = useRef<any>(null);

  const { w, h, numberOfItems, rawW, listMargin } = useItemSize(
    itemMargin,
    getListHeight
  );

  const { isPhoneSize, isLargePhoneSize, isTabletSize } =
    useKNOWValueContextProvider();

  const [currentViewableItems, setCurrentViewableItems] = useState<ViewToken[]>(
    []
  );
  const [pageConfig, setPageConfig] = useState<{
    isFirstPage: boolean;
    leftPage: number;
    rightPage: number;
    isLastPage: boolean;
  }>({ isFirstPage: true, leftPage: 0, rightPage: 1, isLastPage: false });

  const viewabilityConfig = useMemo(
    () => ({
      itemVisiblePercentThreshold: 30,
    }),
    []
  );

  const onViewableItemsChanged = useCallback(({ viewableItems }) => {
    setCurrentViewableItems(viewableItems);
  }, []);

  useEffect(() => {
    const firstViewableItem = currentViewableItems[0];
    if (firstViewableItem) {
      const firstViewableItemIndex = firstViewableItem.index;
      if (firstViewableItemIndex !== null) {
        const leftPage = Math.floor(firstViewableItemIndex / numberOfItems);
        let rightPage = leftPage + 1;

        const isFirstPage = firstViewableItemIndex === 0;
        let isLastPage = false;

        const lastViewableItem = last(currentViewableItems);
        if (lastViewableItem) {
          const lastViewableItemIndex = lastViewableItem.index;

          if (lastViewableItemIndex !== null) {
            if (lastViewableItemIndex === items.length - 1) {
              isLastPage = true;
            }
            rightPage = Math.floor(lastViewableItemIndex / numberOfItems);
          }
        }

        setPageConfig({ isFirstPage, isLastPage, leftPage, rightPage });
      }
    }
  }, [currentViewableItems, items.length, numberOfItems]);

  const getScrollOffset = useCallback(
    (page) => {
      const offsetNumberOfItems = page * numberOfItems;
      const offset =
        rawW * offsetNumberOfItems + itemMargin * offsetNumberOfItems;
      return offset;
    },
    [itemMargin, numberOfItems, rawW]
  );

  const goNextPage = useCallback(() => {
    const nextPage = pageConfig.leftPage + 1;
    const offset = getScrollOffset(nextPage);

    listRef.current?.scrollToOffset?.({ offset });
  }, [pageConfig.leftPage, getScrollOffset]);

  const goPreviousPage = useCallback(() => {
    const prevPage = Math.max(pageConfig.rightPage - 1, 0);
    const offset = getScrollOffset(prevPage);

    listRef.current?.scrollToOffset?.({ offset });
  }, [getScrollOffset, pageConfig.rightPage]);

  return (
    <Box w={'full'}>
      {title ? (
        <Text
          fontFamily={'heading'}
          color={'gray.900'}
          fontSize={isPhoneSize || isLargePhoneSize ? '16px' : '18px'}
          fontWeight={700}
          mb={isPhoneSize || isLargePhoneSize || isTabletSize ? '6px' : '10px'}
          ml={`${listMargin}px`}
        >
          {title}
        </Text>
      ) : null}
      <Pressable>
        {({ isHovered }) => (
          <Box position={'relative'} h={h} w={'full'}>
            <FlatList
              ref={listRef}
              w={'full'}
              h={h}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={items}
              viewabilityConfig={viewabilityConfig}
              onViewableItemsChanged={onViewableItemsChanged}
              renderItem={({ item, index }) => (
                <Box
                  h={h}
                  w={w}
                  mr={
                    index === items.length - 1
                      ? `${listMargin}px`
                      : `${itemMargin}px`
                  }
                  ml={index === 0 ? `${listMargin}px` : 0}
                >
                  <TouchableWithoutFeedback
                    style={styles.button}
                    onPress={() => {
                      onClickItem?.(item, index);
                    }}
                  >
                    {renderItem(item, index)}
                  </TouchableWithoutFeedback>
                </Box>
              )}
              keyExtractor={keyExtractor}
            />
            {isHovered && !disableNavButtons ? (
              <>
                {pageConfig.isFirstPage ? null : (
                  <TouchableWithoutFeedback
                    onPress={goPreviousPage}
                    disabled={pageConfig.isFirstPage}
                  >
                    <Box
                      position={'absolute'}
                      h={'full'}
                      w={listMargin}
                      top={0}
                      left={0}
                      style={styles.bg}
                      display={'flex'}
                      flexDir={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      borderRadius={'6px'}
                    >
                      <Text>
                        <ExpoEntypoIcon
                          // 70% of the button
                          size={`${Math.round((listMargin * 70) / 100)}px`}
                          color={'gray.900'}
                          name="chevron-left"
                        />
                        {isHovered}
                      </Text>
                    </Box>
                  </TouchableWithoutFeedback>
                )}
                {pageConfig.isLastPage ? null : (
                  <TouchableWithoutFeedback
                    onPress={goNextPage}
                    disabled={pageConfig.isLastPage}
                  >
                    <Box
                      position={'absolute'}
                      h={'full'}
                      w={listMargin}
                      top={0}
                      right={0}
                      style={styles.bg}
                      display={'flex'}
                      flexDir={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      borderRadius={'6px'}
                    >
                      <ExpoEntypoIcon
                        size={`${Math.round((listMargin * 70) / 100)}px`}
                        color={'gray.900'}
                        name="chevron-right"
                      />
                    </Box>
                  </TouchableWithoutFeedback>
                )}
              </>
            ) : null}
          </Box>
        )}
      </Pressable>
    </Box>
  );
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    height: '100%',
  },
  navigationButtonGradient: {
    width: '4%',
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  leftNavigationButtonGradient: {
    left: 0,
  },
  rightNavigationButtonGradient: {
    right: 0,
    transform: [{ rotate: '180deg' }],
  },
  bg: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
});
