import React from 'react';

import { createDrawerNavigator } from '@react-navigation/drawer';

import { withModuleName } from './CustomDrawerContent';
import type {
  ISideBarNavigatorProps,
  ISideBarNavigatorWithDrawerProps,
} from './types';
import { useDrawerScreenOptions } from './useDrawerScreenOptions';

export function SideBarNavigator({
  module,
  items,
  isResponsive,
  Drawer,
  onRouteChange,
  actionButton,
}: Readonly<ISideBarNavigatorWithDrawerProps>) {
  const { screenOptions, isSmallScreen } = useDrawerScreenOptions({
    isResponsive,
    actionButton,
  });
  return (
    <Drawer.Navigator
      screenOptions={screenOptions}
      drawerContent={withModuleName(module, {
        isSmallScreen,
        onRouteChange,
        actionButton,
      })}
    >
      {items.map((item) => (
        <Drawer.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={item.options}
        />
      ))}
    </Drawer.Navigator>
  );
}

export const createSideBarNavigator = () => {
  const drawerNavigator = createDrawerNavigator();

  return (props: ISideBarNavigatorProps) => (
    <SideBarNavigator {...props} Drawer={drawerNavigator} />
  );
};
