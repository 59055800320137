import { TransformedIssueItem, prepareIssueFeed } from '@know/transformers';
import { selector, atom } from '@know/ui';
import { globalMutedIssueAtom } from '../state';

export const mutedIssueFilterTextAtom = atom<string | null>({
  key: 'mutedIssueFilterTextAtom',
  default: null,
});

export const mutedIssuesItemsSelector = selector<TransformedIssueItem[]>({
  key: 'mutedIssuesItemsSelector',
  get: ({ get }) => {
    const mutedIssueFeed = get(globalMutedIssueAtom);
    const filterText = get(mutedIssueFilterTextAtom);

    return prepareIssueFeed(
      mutedIssueFeed,
      filterText,
      {},
      'dateReported',
      'desc'
    );
  },
});
