export default {
  'QRCodeExpireText_one':
    'QR Code expires in <1>{{remainingSeconds}}</1> second',
  'QRCodeExpireText_other':
    'QR Code expires in <1>{{remainingSeconds}}</1> seconds',

  'download-app-text': 'Get the full experience with our app!',
  'No courses found': 'No courses found',

  'You’ve not completed a course yet.': 'You’ve not completed a course yet.',
  "Looks like you don't have any forms yet.":
    "Looks like you don't have any forms yet.",
  'This SCORM content is not supported. Please contact support team at support@knownuggets.com':
    'This SCORM content is not supported. Please contact support team at support@knownuggets.com',
};
