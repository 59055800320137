import React from 'react';
import { Modal, KNOWLoader } from '@know/ui';

interface SpinnerModalProps {
  isOpen: boolean;
}

export const SpinnerModal = ({ isOpen }: SpinnerModalProps) => {
  return (
    <Modal isOpen={isOpen} size="xl">
      <Modal.Content>
        <Modal.Body>
          <KNOWLoader paddingTop={'24px'} paddingBottom={'24px'} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
