import { FirebaseAPI } from '..';
import { query, limitToLast, orderByChild } from 'firebase/database';
import { LibrarySection, LibrarySectionItem } from '@know/transformers';

interface ISectionItemObject {
  [key: string]: {
    [key: string]: LibrarySectionItem
  }
}

interface ISectionObject {
  [key: string]: LibrarySection
}

export async function getUserAssignedCourseLibraryId({ fbAPI, userId }: {
  fbAPI: FirebaseAPI;
  userId?: string
}) {
  // we only display the latest assigned library if a user is assigned multiple libraries
  const userLibraryRef = fbAPI.getMultiPath(`userFeed/${userId}/courseLibrary`);
  const assignedUserLibrariesRef = query(fbAPI.getNodeRef(userLibraryRef), orderByChild("createdAt"), limitToLast(1));
  const assignedLibrary = await fbAPI.getValue(assignedUserLibrariesRef) || {}

  return Object.keys(assignedLibrary)?.[0]
}

export function getOrgDefaultCourseLibraryId({ fbAPI }: {
  fbAPI: FirebaseAPI;
}) {
  const orgLibraryRef = fbAPI.getMultiPath('config/defaultCourseLibrary');
  return fbAPI.getValue(fbAPI.getNodeRef(orgLibraryRef));
}

export async function getConfigLibraryId({ fbAPI, userId }: {
  fbAPI: FirebaseAPI;
  userId?: string
}) {
  const userCourseLibraryId = await getUserAssignedCourseLibraryId({
    fbAPI,
    userId
  });

  if (userCourseLibraryId) {
    return userCourseLibraryId;
  }

  const orgDefaultLibraryId = await getOrgDefaultCourseLibraryId({
    fbAPI
  });

  return orgDefaultLibraryId || null;
}

export const getCourseLibraryCategories = async (fbAPI: FirebaseAPI, libraryId: string): Promise<any[]> => {
  const sectionRef = fbAPI.getMultiPath(`nuggets/courseLibrary/${libraryId}/payload/categories`)
  return fbAPI.getValue(fbAPI.getNodeRef(sectionRef))
}

export const getCategoryDetails = (fbAPI: FirebaseAPI, categoryId: string) => {
  const categoryRef = fbAPI.getMultiPath(`nuggets/courseCategory/${categoryId}`)
  return fbAPI.getValue(fbAPI.getNodeRef(categoryRef))
}

export async function getLMSNuggetDetails({ fbAPI, nuggetId }: {
  fbAPI: FirebaseAPI;
  nuggetId: string
})  {
  const feedRef = fbAPI.getMultiPath(`nuggets/courses/${nuggetId}/details`);
  const details = await fbAPI.getValue(fbAPI.getNodeRef(feedRef));
  if (details) {
    return {
      id: nuggetId,
      ...details
    }
  }
  return null;
}

export const getLibraryDetails = async (fbAPI: FirebaseAPI, userId?: string): Promise<{ sections: ISectionObject, sectionItems: ISectionItemObject }> => {
  const libraryId = await getConfigLibraryId({ fbAPI, userId })

  // user is not assigned any library and there is no default pre-configured library at the organization level
  if (!libraryId) {
    return {
      sections: {},
      sectionItems: {}
    }
  }

  const categoriesUnderLibrary = await getCourseLibraryCategories(fbAPI, libraryId) || []
  const fetchCategoriesPromises = categoriesUnderLibrary.map(category => getCategoryDetails(fbAPI, category.id))
  const categories = await Promise.all(fetchCategoriesPromises)

  // prepare categories in the old sections format
  const sectionObject: ISectionObject = {}
  categories.forEach(category => {
    const { id: categoryId } = category
    sectionObject[categoryId] = category
  })

  // courses under each category
  const sectionItemObject: ISectionItemObject = {}
  categories.forEach(category => {
    const { id: categoryId, payload } = category
    if (!sectionItemObject[categoryId]) {
      sectionItemObject[categoryId] = {};
    }

    if (payload?.courses?.length) {
      payload.courses.forEach((course: any) => {
        const { id: courseId } = course
        sectionItemObject[categoryId][courseId] = {
          id: courseId,
        }
      })
    }
  })

  return {
    sections: sectionObject,
    sectionItems: sectionItemObject
  }
}
