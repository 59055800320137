import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-locize-backend';
// import LastUsed from 'locize-lastused';
// import { locizePlugin } from 'locize';
import { DateTime } from 'luxon';
import resources from './locals';

const isProduction = process.env.NODE_ENV === 'production';
export const defaultNS = 'core';

// const locizeOptions = {
//   projectId: 'f0e23b29-65f0-4e70-b9cc-a5c75cce455f',
//   apiKey: '139db73e-e283-4cff-a827-3c76f67e5bb5', // YOU should not expose your apps API key to production!!!
//   referenceLng: '[eng]',
// };

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  // i18n.use(LastUsed);
}

i18n
  // locize-editor
  // InContext Editor of locize
  // .use(locizePlugin)
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    fallbackLng: 'en',
    defaultNS,
    ns: 'core',
    supportedLngs: Object.keys(resources),
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      // format: (value, format, lng) => { // legacy usage
      //   if (value instanceof Date) {
      //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
      //   }
      //   return value;
      // }
    },
    // backend: locizeOptions,
    // locizeLastUsed: locizeOptions,
    // saveMissing: !isProduction, // you should not use saveMissing in production
  })
  .then(console.log)
  .catch(console.warn);

// new usage
i18n.services.formatter?.add('DATE_HUGE', (value, lng, _options) => {
  return DateTime.fromJSDate(value)
    .setLocale(lng as string)
    .toLocaleString(DateTime.DATE_HUGE);
});

export default i18n;
