//TODO: make a native app friendly version
import React, { useCallback, useState } from 'react';
import {
  KNOWButton as Button,
  Modal,
  Text,
  View,
  TextArea,
  Pressable,
  ExpoEntypoIcon,
  useModalParam,
  UploadProgressType,
  Image,
  Box,
  ExpoIonicIcon,
  IconButton,
} from '@know/ui';

import { useTranslation } from '@know/i18n';
import { UploadImageModal } from '../Forms/UploadImageModal';
import { UploadFileResultType } from '@know/db';
import { FormControl } from 'native-base';

interface TaskActionDialogProps {
  type?: 'complete' | 'reopen';
  isOpen: boolean;
  isActionLoading: boolean;
  cancel: () => void;
  onSubmit?: (params: { remark: string; image?: UploadFileResultType }) => void;
  uploadFiles: (
    files: File[],
    uploadType: UploadProgressType
  ) => Promise<UploadFileResultType[]>;
  onClickImage?: (url: string) => any;
}

export const TaskActionDialog = ({
  isOpen,
  cancel,
  type,
  onSubmit,
  isActionLoading,
  uploadFiles,
}: TaskActionDialogProps) => {
  const { t } = useTranslation(['tasks']);
  const [remark, setRemark] = useState<string>('');
  const [image, setImage] = useState<UploadFileResultType | undefined>();

  const onClickSubmit = useCallback(() => {
    onSubmit?.({ remark, image });
  }, [image, onSubmit, remark]);

  const [
    isUploadImageModalOpen,
    uploadImageModalProps,
    openUploadImageModal,
    closeUploadImageModal,
  ] = useModalParam<{
    onSubmit?: (response: File[] | undefined) => void;
    limit: number;
    restrictImagePicker: boolean;
  }>(false, { limit: 1, restrictImagePicker: false });

  const onClickAttachPhoto = useCallback(() => {
    openUploadImageModal({
      restrictImagePicker: false,
      limit: 1,
      onSubmit: async (files) => {
        if (files && files.length) {
          const images = await uploadFiles(files, 'image');
          if (images.length) {
            setImage(images[0]);
          }
        }
      },
    });
  }, [openUploadImageModal, uploadFiles]);

  const onClose = useCallback(() => {
    setRemark('');
    setImage(undefined);
    cancel();
  }, [cancel]);

  return (
    <>
      <Modal isOpen={isOpen} closeOnOverlayClick onClose={onClose} size="xl">
        <Modal.Content>
          <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
            <View
              display={'flex'}
              flexDir={'column'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Text fontWeight={600} fontSize={'18px'}>
                {t(
                  type === 'complete'
                    ? 'Complete task for everyone'
                    : 'Reopen task and notify everyone?'
                )}
              </Text>
              <Text fontSize={'16px'} color={'gray.700'}>
                {t(
                  type === 'complete'
                    ? 'Add a remark to complete task'
                    : 'Add a remark to reopen task'
                )}
              </Text>
            </View>
          </Modal.Header>
          <Modal.Body py={0}>
            <FormControl>
              <TextArea
                placeholder={t(
                  type === 'complete'
                    ? 'Enter remark (Required)'
                    : 'Reason for reopening (Required)'
                )}
                onChangeText={setRemark}
                value={remark}
                numberOfLines={4}
                mt={'12px'}
                autoCompleteType={null}
                _focus={{
                  bg: 'white',
                }}
                fontSize={'14px'}
              />
            </FormControl>
            <View
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              mt={'10px'}
            >
              {image ? (
                <Box position={'relative'}>
                  <IconButton
                    position={'absolute'}
                    right={0}
                    top={0}
                    w="20px"
                    h="20px"
                    borderRadius={'full'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bg={'white'}
                    zIndex={100}
                    borderColor={'gray.400'}
                    borderWidth={'1px'}
                    onPress={() => setImage(undefined)}
                    icon={
                      <ExpoIonicIcon
                        name="close"
                        size="14px"
                        color="gray.700"
                      />
                    }
                    _icon={{
                      color: 'gray.700',
                    }}
                    _hover={{
                      bg: 'white',
                    }}
                  />
                  {/* <Pressable onPress={() => setImage(undefined)}>
                    <Box
                      position={'absolute'}
                      right={0}
                      top={0}
                      w="20px"
                      h="20px"
                      borderRadius={'full'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      bg={'white'}
                      zIndex={100}
                      borderColor={'gray.400'}
                      borderWidth={'1px'}
                    >
                      <ExpoIonicIcon
                        name="close"
                        size="14px"
                        color="gray.700"
                      />
                    </Box>
                  </Pressable> */}
                  <Pressable onPress={onClickAttachPhoto}>
                    <Image
                      source={{
                        uri: image.url,
                      }}
                      borderRadius={'4px'}
                      resizeMode="cover"
                      w="120px"
                      h="120px"
                      m={'10px'}
                      ml={0}
                    />
                  </Pressable>
                </Box>
              ) : (
                <Pressable onPress={onClickAttachPhoto} display={'flex'}>
                  {({ isHovered }) => (
                    <View
                      display={'flex'}
                      flexDir={'row'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                    >
                      <ExpoEntypoIcon
                        name="attachment"
                        mr={'4px'}
                        size={'16px'}
                        color={isHovered ? 'primary.700' : 'primary.500'}
                        mt={'2px'}
                      />
                      <Text
                        fontWeight={600}
                        color={isHovered ? 'primary.700' : 'primary.500'}
                        fontSize={'14px'}
                        textDecorationLine={'underline'}
                        fontFamily={'tableCell'}
                      >
                        {t('Attach Photo')}
                      </Text>
                    </View>
                  )}
                </Pressable>
              )}
            </View>
          </Modal.Body>
          <Modal.Footer
            borderTopWidth={0}
            display={'flex'}
            flexDirection={'column'}
            pt={'24px'}
          >
            <Button
              colorScheme="primary"
              onPress={onClickSubmit}
              w={'100%'}
              borderWidth={'0px'}
              isDisabled={!remark}
              isLoading={isActionLoading}
            >
              {t(type === 'complete' ? 'Complete Task' : 'Reopen Task')}
            </Button>
            <Button
              colorScheme="cancel"
              borderWidth={'0px'}
              onPress={onClose}
              w={'100%'}
              mt={'10px'}
            >
              {t('Cancel')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {isUploadImageModalOpen && (
        <UploadImageModal
          isOpen={isUploadImageModalOpen}
          close={closeUploadImageModal}
          {...uploadImageModalProps}
        />
      )}
    </>
  );
};
