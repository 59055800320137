import { DataSnapshot, query, limitToLast } from "firebase/database";
import { IReceivedFormsItem, IReceivedResponseItem } from "@know/transformers";
import { FirebaseAPI } from "..";

function getUserReceivedForms(fbAPI: FirebaseAPI) {
  const userId = fbAPI.getLoggedInUserId();
  const path = fbAPI.getMultiPath(`/forms/Lookups/${userId}/receivedForms`);
  return fbAPI.getNodeRef(path);
}

export const isResponseStillInUserReceivedTab = async (fbAPI: FirebaseAPI, nuggetId: string, responseId: string) => {
  const userId = fbAPI.getLoggedInUserId();
  const path = fbAPI.getMultiPath(
    `/forms/Lookups/${userId}/receivedResponses/${nuggetId}/${responseId}`
  );

  const value = await fbAPI.getValue(
    fbAPI.getNodeRef(path)
  )

  return !!value
}

export function getUserReceivedResponses(
  fbAPI: FirebaseAPI,
  nuggetId: string,
  limit = false,
  cb: (data: IReceivedResponseItem[]) => any
) {
  const userId = fbAPI.getLoggedInUserId();
  const path = fbAPI.getMultiPath(
    `/forms/Lookups/${userId}/receivedResponses/${nuggetId}`
  );
  const pathRef = fbAPI.getNodeRef(path);
  const orderByQueryRef = fbAPI.queryOrderByChild(pathRef, "createdAt");
  const endAtQueryRef = fbAPI.queryValue(orderByQueryRef, "endAt", Date.now());
  const limitToLastQueryRef = limit
    ? query(endAtQueryRef, limitToLast(5))
    : endAtQueryRef;
  const handler = (snap: DataSnapshot) => {
    const recentReceivedResponses = snap.val() ?? {};
    const data: IReceivedResponseItem[] = Object.entries(
      recentReceivedResponses
    )
      .map(([responseId, response]: any) => {
        return {
          id: responseId,
          ...response,
        } as IReceivedResponseItem;
      })
      .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
    cb(data);
  };
  return fbAPI.listenOnNodeRef(limitToLastQueryRef, "value", handler);
}

const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";

let cleanups: (() => void)[] = [];

export function subscribeToUserReceivedForms(
  fbAPI: FirebaseAPI,
  cb: (formId: string, feedItem: IReceivedFormsItem | null) => void
) {
  const userFeedRef = getUserReceivedForms(fbAPI);
  const orderByQueryRef = fbAPI.queryOrderByChild(userFeedRef, "receivedAt");

  function listenerHandler(_listenerEvenType: string) {
    return async (receivedFormItemSnapShot: DataSnapshot) => {
      const receivedForm: any = receivedFormItemSnapShot.val();
      const formId = receivedFormItemSnapShot.key as string;
      if (!formId) {
        return;
      }
      if (_listenerEvenType === typeCR) {
        return cb(formId, null);
      } else if (receivedForm) {
        cleanups.push(
          getUserReceivedResponses(
            fbAPI,
            formId,
            true,
            receivedResponsesListenerHandler(formId, receivedForm)
          )
        );
      } else {
        cb(formId, null);
      }
    };
  }

  const receivedResponsesListenerHandler =
    (formId: string, receivedForm: any) => (data: IReceivedResponseItem[]) => {
      const sentFormItem: IReceivedFormsItem = {
        id: formId,
        hasMoreData: data.length > 4,
        ...receivedForm,
        pending: Object.keys(receivedForm.status || {}).filter(
          (id: string) => receivedForm?.status?.[id] === true
        ).length,
        data: data.splice(0, 4), // Get max four only and rest will be shown in paginated view.
      };
      cb(formId, sentFormItem);
    };

  const listenerHandlerCC = listenerHandler(typeCC);
  const listenerHandlerCA = listenerHandler(typeCA);
  const listenerHandlerCR = listenerHandler(typeCR);

  fbAPI.listenOnNodeRef(orderByQueryRef, typeCC, listenerHandlerCC);
  fbAPI.listenOnNodeRef(orderByQueryRef, typeCA, listenerHandlerCA);
  fbAPI.listenOnNodeRef(orderByQueryRef, typeCR, listenerHandlerCR);

  return () =>
    Promise.all([
      fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
      fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
      fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}

export const cleanUpSubscribedReceivedForms = () => {
  for (const c of cleanups) {
    c();
  }
  cleanups = [];
};