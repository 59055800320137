import React from 'react';
import { View, Pressable, Image } from '@know/ui';
import Images from '../../../../Images';

type ISingleText = {
  selectedIndex: any;
  setQuizProgress: (progress: any) => void;
  quizProgress: string[];
  isSelected: (
    selectedOption: any,
    item: any,
    isHovered: boolean
  ) => 'primary.500' | 'gray.400' | 'gray.300';
  index: any;
  item: any;
  handleNextQuestion: (payload: any) => void;
};
export const SingleSelectImage = ({
  selectedIndex,
  setQuizProgress,
  quizProgress,
  isSelected,
  index,
  item,
  handleNextQuestion,
}: ISingleText) => {
  return (
    <Pressable
      w="50%"
      onPress={() => {
        const payload = {
          selectedIndex,
          selectedOption: String(index + 1),
        };
        setQuizProgress((prevState: any) => {
          const state = [...prevState];
          state[selectedIndex] = String(index + 1);
          return state;
        });
        handleNextQuestion(payload);
      }}
    >
      {({ isHovered }) => {
        const selectedOption = quizProgress[selectedIndex];
        return (
          <View
            flex={1}
            overflow="hidden"
            marginY={['4px', '4px', '6px', '6px', '6px']}
            marginLeft={
              index % 2 === 0 ? '0' : ['4px', '4px', '6px', '6px', '6px']
            }
            marginRight={
              index % 2 === 0 ? ['4px', '4px', '6px', '6px', '6px'] : '0'
            }
            borderColor={isSelected(
              selectedOption,
              String(index + 1),
              isHovered
            )}
            borderWidth="2px"
            borderRadius="5px"
          >
            <Image
              alt="quiz image"
              w="100%"
              h={['130px', '150px', '170px', '170px', '170px']}
              source={{ uri: item }}
            />

            {selectedOption === String(index + 1) && (
              <Image
                alt="quiz image"
                source={Images.RadioSelectedBtn}
                position="absolute"
                h="17px"
                w="17spx"
                top="10px"
                left="10px"
              />
            )}
          </View>
        );
      }}
    </Pressable>
  );
};
