import React, { useState } from 'react';

import { View, KNOWList, WithKNOWLoader } from '@know/ui';

import { useCompletedCourses } from './useCompletedCourses';
import { CourseFeedItem } from '../MyCoursesPage/CourseFeedItem';

export const CompletedCoursesPage = () => {
  const [loadMore, setLoadMore] = useState(true);
  const {
    userFeed,
    noOfColumns,
    parentDivInfo,
    childDivInfo,
    getUserFeed,
    isLoading,
  } = useCompletedCourses(loadMore, setLoadMore);
  return (
    <WithKNOWLoader
      isLoading={isLoading}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <View
        bgColor="white"
        onLayout={(event: any) => {
          let { width, height } = event.nativeEvent.layout;
          parentDivInfo.current = { height, width };
        }}
        flex={1}
        alignItems="center"
        w="full"
      >
        <KNOWList
          data={userFeed}
          component={CourseFeedItem}
          endReachedThreshold={0.9}
          onEndReached={() => {
            getUserFeed();
          }}
          childDivInfo={childDivInfo}
          isCompleted={true}
          noOfColumns={noOfColumns}
        />
      </View>
    </WithKNOWLoader>
  );
};
