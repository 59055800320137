import React from 'react';
import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

export const SendIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 21 20">
    <Path
      d="M3.22078 2.05138L18.5758 9.61737C18.6595 9.65864 18.73 9.72252 18.7793 9.80178C18.8286 9.88104 18.8548 9.97253 18.8548 10.0659C18.8548 10.1592 18.8286 10.2507 18.7793 10.33C18.73 10.4092 18.6595 10.4731 18.5758 10.5144L3.21978 18.0804C3.13376 18.1226 3.03739 18.1393 2.94217 18.1283C2.84695 18.1174 2.75689 18.0793 2.68271 18.0186C2.60853 17.9579 2.55335 17.8771 2.52376 17.786C2.49416 17.6948 2.4914 17.5971 2.51578 17.5044L4.03678 11.7594C4.06144 11.6664 4.11238 11.5825 4.18351 11.5178C4.25463 11.4531 4.34292 11.4102 4.43778 11.3944L11.3188 10.2474C11.3598 10.2406 11.3985 10.2237 11.4313 10.1982C11.4641 10.1727 11.4901 10.1394 11.5068 10.1014L11.5248 10.0414C11.5341 9.98576 11.5244 9.92863 11.4972 9.87925C11.4699 9.82987 11.4268 9.79115 11.3748 9.76938L11.3188 9.75338L4.39978 8.60038C4.3051 8.58437 4.21703 8.54144 4.1461 8.47671C4.07516 8.41198 4.02437 8.3282 3.99978 8.23537L2.51578 2.62838C2.49114 2.53561 2.49372 2.43772 2.52321 2.34639C2.55269 2.25505 2.60784 2.17413 2.68208 2.11329C2.75631 2.05245 2.84648 2.01426 2.94183 2.00328C3.03718 1.9923 3.13367 2.009 3.21978 2.05138H3.22078Z"
      fill="currentColor"
    />
  </Icon>
);
