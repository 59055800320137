import React, { useRef, useState } from 'react';
import {
  View,
  Image,
  Button,
  Slider,
  Text,
  useBreakpointValue,
  WithKNOWLoader,
  convertToSeconds,
} from '@know/ui';
import Images from '../../../Images';

export const AudioJs = ({ url, consumeTime, handleAudioConsumed }: any) => {
  // eslint-disable-next-line no-undef
  const audioRef = useRef<any>(new Audio(url));
  let durationTime = React.useRef(0);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showAudioComponent, setShowAudioComponent] = useState(false);
  let [currentTime, setCurrentTime] = useState(0);
  let isConsumedCalled = useRef(false);

  const sliderSize = useBreakpointValue({
    'base': '60%',
    'sm': '75%',
    'md': '80%',
    'lg': '80%',
    'xl': '80%',
    '2xl': '80%',
  });

  const onseeking = (updatedTime: any) => {
    if (updatedTime < currentTime) {
      setCurrentTime(updatedTime);
      audioRef.current.currentTime = updatedTime;
    }
  };

  const onTimeUpdate = (e: any) => {
    if (!e.target?.paused) {
      if (
        !isConsumedCalled?.current &&
        ((consumeTime && e.target.currentTime > consumeTime) ||
          Math.floor((e.target.currentTime / audioRef.current.duration) * 100) >
            75)
      ) {
        isConsumedCalled.current = true;
        handleAudioConsumed({
          consumeTime: e.target.currentTime,
          isAudioEnd: false,
        });
      }
      setCurrentTime(e.target.currentTime);
    }
  };

  const onEnded = () => {
    setCurrentTime(0);
    if (!isConsumedCalled?.current) {
      isConsumedCalled.current = true;
      handleAudioConsumed({
        consumeTime: 0,
        isAudioEnd: true,
      });
    }
    setShowPlayButton(true);
  };

  const onLoadAudio = (e: any) => {
    setShowAudioComponent(true);
    durationTime.current = e.target?.duration;
  };

  const toggleAudio = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setShowPlayButton(false);
    } else {
      audioRef.current.pause();
      setShowPlayButton(true);
    }
  };

  return (
    <>
      <audio
        controls={false}
        src={url}
        onTimeUpdate={onTimeUpdate}
        onEnded={onEnded}
        onLoadedData={onLoadAudio}
        ref={audioRef}
      />
      <WithKNOWLoader isLoading={!showAudioComponent} size="lg">
        <View borderRadius="15px" bgColor="gray.200" flex={1}>
          <View
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            bgColor="gray.200"
            paddingX="10px"
            paddingY="5px"
            width={'100%'}
          >
            <Button
              onPress={toggleAudio}
              bgColor="primary.500"
              borderRadius="50%"
              width="35px"
              height="35px"
              justifyContent="center"
              alignItems="center"
            >
              <View
                bgColor="primary.500"
                borderRadius="50%"
                width="35px"
                height="35px"
                justifyContent="center"
                alignItems="center"
              >
                <View padding="20px">
                  <Image
                    height="20px"
                    width="20px"
                    resizeMode="cover"
                    rounded={'full'}
                    alt={'Play Audio'}
                    source={{
                      uri: showPlayButton
                        ? Images.WhitePlayButton
                        : Images.WhitePauseButton,
                    }}
                  />
                </View>
              </View>
            </Button>
            <Slider
              width={sliderSize}
              marginX={'10px'}
              size="lg"
              step={0.0166}
              minValue={0}
              accessibilityLabel="Audio seek"
              value={+currentTime}
              maxValue={+durationTime.current}
              onChange={onseeking}
            >
              <Slider.Track>
                <Slider.FilledTrack />
              </Slider.Track>
            </Slider>
            <Text width="10" justifyContent="flex-end" display="flex">{`${
              convertToSeconds(durationTime.current).min
            }:${convertToSeconds(durationTime.current).sec}`}</Text>
          </View>
        </View>
      </WithKNOWLoader>
    </>
  );
};

export default AudioJs;
