import { Box, Text } from 'native-base';
import React from 'react';

type TaskStatus = 'notStarted' | 'inProgress' | 'completed';

const getText = (status: TaskStatus) => {
  if (status === 'notStarted') {
    return 'Not Started';
  }
  if (status === 'inProgress') {
    return 'In Progress';
  }
  if (status === 'completed') {
    return 'Completed';
  }
};

export const TaskStatus = ({
  status,
  text,
  background,
  paddingX,
  paddingY,
}: {
  status: TaskStatus;
  text?: string;
  background?: string;
  paddingX?: string;
  paddingY?: string;
}) => {
  return (
    <Box
      display={'block'}
      bg={
        background
          ? background
          : status === 'completed'
          ? 'rgba(14, 191, 117, 0.1)'
          : status === 'inProgress'
          ? 'rgba(0, 164, 255, 0.1)'
          : 'gray.200'
      }
      px={paddingX || '6px'}
      py={paddingY || '2px'}
      borderRadius={'4px'}
    >
      <Text
        fontSize={text ? '14px' : '12px'}
        fontWeight={700}
        fontFamily={'tableCell'}
        color={
          status === 'completed'
            ? '#0EBF75'
            : status === 'inProgress'
            ? '#0263BD'
            : 'gray.900'
        }
        textTransform={text ? undefined : 'uppercase'}
      >
        {text || getText(status)}
      </Text>
    </Box>
  );
};
