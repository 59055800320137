import React from 'react';
import { Box, KNOWButton as Button, ExpoADIcon, Modal, Text } from '@know/ui';

interface LocationPermissionModalProps {
  isOpen: boolean;
  close?: () => void;
}

export const LocationPermissionModal = ({
  isOpen,
  close,
}: LocationPermissionModalProps) => {
  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={close} size="xl">
      <Modal.Content>
        <Modal.Body p="16px">
          <Box
            w={'100%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'flex-start'}
          >
            <Box h={'100%'} mr={'8px'}>
              <Box
                bg={'gray.900'}
                borderRadius={'50%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                p="8px"
              >
                <ExpoADIcon
                  size={'24px'}
                  name="arrowleft"
                  color={'white'}
                  style={{ transform: [{ rotate: '45deg' }] }}
                />
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              flex={1}
            >
              <Text fontWeight={600} fontSize={'18px'} mb={'6px'} mt={'6px'}>
                Allow Location
              </Text>
              <Text fontSize={'14px'} mb={'6px'}>
                This form requires your GPS location for submission, click
                'Allow' above to give KNOW access to your location.
              </Text>
              <Text fontSize={'14px'} mb={'10px'}>
                If you accidentally denied permission, go to your browser
                settings to change it.
              </Text>
            </Box>
          </Box>
        </Modal.Body>
        {close ? (
          <Modal.Footer
            borderTopWidth={0}
            display={'flex'}
            flexDirection={'column'}
            pt={0}
          >
            <Button
              colorScheme="primary"
              onPress={close}
              w={'100%'}
              mb={'10px'}
            >
              OK
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};
