import React, { useState } from 'react';
import { useTranslation } from '@know/i18n';
import {
  View,
  Text,
  EllipsisMenuOption,
  EllipsisMenu,
  useKNOWValueContextProvider,
  Box,
  ExpoADIcon,
  Pressable,
} from '@know/ui';
import { LayoutChangeEvent } from 'react-native';

interface TopbarProps {
  goBack: () => void;
  title?: string;
  ellipsisOptions?: EllipsisMenuOption[];
  onLayout?: (e: LayoutChangeEvent) => void;
  actionButton?: JSX.Element;
}

export const Topbar = ({
  goBack,
  title,
  ellipsisOptions = [],
  onLayout,
  actionButton,
}: TopbarProps) => {
  const [topBarWidth, setTopBarWidth] = useState<number>(0);
  const { isLargePhoneSize, isPhoneSize } = useKNOWValueContextProvider();

  const { t } = useTranslation('menus');

  return (
    <View
      p={title ? '10px' : '6px'}
      bg={'white'}
      w={'100%'}
      position={'relative'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={title ? 'center' : 'flex-start'}
      borderBottomColor={'gray.300'}
      borderBottomWidth={'1px'}
      onLayout={(e) => {
        setTopBarWidth(e.nativeEvent.layout.width);
        onLayout?.(e);
      }}
    >
      <Pressable
        onPress={goBack}
        position={title ? 'absolute' : 'relative'}
        left={title ? '12px' : undefined}
      >
        {({ isHovered }) => (
          <Box
            display={'flex'}
            flexDir={'row'}
            alignItems={'center'}
            bg={isHovered ? 'gray.100' : undefined}
            p={'6px'}
            borderRadius={'6px'}
          >
            <ExpoADIcon
              name="arrowleft"
              size={'16px'}
              color={'gray.900'}
              mr={'2px'}
              mt={'1px'}
            />
            {isPhoneSize || isLargePhoneSize ? null : (
              <Text ml={'8px'} fontSize={'14px'} fontWeight={'500'}>
                {t('Back')}
              </Text>
            )}
          </Box>
        )}
      </Pressable>
      {title ? (
        <Text
          fontSize={'16px'}
          fontWeight={600}
          textAlign={'center'}
          noOfLines={1}
          maxW={`${topBarWidth - 30 - 12 - 30 - 12 - 10}px`}
        >
          {title}
        </Text>
      ) : null}
      {ellipsisOptions.length ? (
        <View
          position={'absolute'}
          right={'12px'}
          width={'30px'}
          display={'flex'}
          justifyContent={'center'}
          flexDir={'row'}
        >
          <EllipsisMenu options={ellipsisOptions} />
        </View>
      ) : null}
      {actionButton ? (
        <View
          position={'absolute'}
          right={'12px'}
          display={'flex'}
          justifyContent={'center'}
          flexDir={'row'}
        >
          {actionButton}
        </View>
      ) : null}
    </View>
  );
};
