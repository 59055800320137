import {
  TaskFeedItem,
  TransformedTaskItem,
  prepareTaskFeed,
} from '@know/transformers';
import { selector, atom } from '@know/ui';

export const completedTaskFilterTextAtom = atom<string | null>({
  key: 'completedTaskFilterTextAtom',
  default: null,
});

export const completedTaskAtom = atom<Record<string, TaskFeedItem>>({
  key: 'completedTaskAtom',
  default: {},
});

export const completedTasksItemsSelector = selector<TransformedTaskItem[]>({
  key: 'completedTasksItemsSelector',
  get: ({ get }) => {
    const taskFeed = get(completedTaskAtom);
    const filterText = get(completedTaskFilterTextAtom);

    return prepareTaskFeed(taskFeed, filterText, 'resolvedAt', 'desc');
  },
});
