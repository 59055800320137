import React from 'react';

import {
  Button as NBButton,
  HamburgerIcon,
  IButtonProps,
  IconButton,
} from 'native-base';

import { useTranslation, Trans } from '@know/i18n';

type ColorScheme =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'danger'
  | 'dangerFilled'
  | 'hollow'
  | 'cancel';

const defaults: { [colorScheme in ColorScheme]: IButtonProps } = {
  primary: {
    _text: {
      fontSize: 'sm',
      fontWeight: 600,
    },
    borderRadius: '8px',
  },
  secondary: {
    _text: {
      fontSize: 'sm',
      color: 'primary.500',
      fontWeight: 600,
    },
    borderRadius: '8px',
    backgroundColor: 'white',
    borderColor: 'gray.300',
    borderWidth: '1px',
    _hover: {
      backgroundColor: 'gray.200',
    },
    _spinner: {
      color: 'primary.500',
    },
    _icon: {
      color: 'primary.500',
    },
    // _pressed: {
    //   _text: {
    //     color: 'white',
    //   },
    // },
  },
  default: {
    variant: 'outline',
    _text: {
      color: 'gray',
      fontSize: 'sm',
    },
  },
  danger: {
    variant: 'ghost',
    _text: {
      fontWeight: '600',
      fontSize: 'sm',
      color: 'red.400',
    },
  },
  dangerFilled: {
    background: 'red.500',
    _text: {
      fontWeight: '600',
      fontSize: 'sm',
      color: 'white',
    },
  },
  hollow: {
    // variant: 'link',
    bgColor: 'white',
    borderColor: 'gray.300',
    borderWidth: '1px',
    borderRadius: '8px',
    _text: {
      fontWeight: '700',
      fontSize: '14px',
      color: 'primary.500',
    },
    _icon: {
      color: 'primary.500',
      size: '24px',
    },
    _hover: {
      bgColor: 'gray.200',
    },
  },
  cancel: {
    _text: {
      fontSize: 'sm',
      color: 'gray.900',
      fontWeight: 600,
    },
    borderRadius: '8px',
    backgroundColor: 'white',
    borderColor: 'gray.300',
    borderWidth: '1px',
    _hover: {
      backgroundColor: 'gray.200',
    },
    _spinner: {
      color: 'primary.500',
    },
    _icon: {
      color: 'primary.500',
    },
    // _pressed: {
    //   _text: {
    //     color: 'white',
    //   },
    // },
  },
};

export interface ButtonProps extends IButtonProps {
  colorScheme?: ColorScheme;
  children: React.ReactChild;
}

export const KNOWButton = ({
  colorScheme = 'primary',
  children,
  ...props
}: ButtonProps) => {
  // @ts-ignore
  const allProps: IButtonProps = {
    ...(defaults[colorScheme] ?? {}),
    ...props,
    _text: {
      ...(defaults[colorScheme]._text ?? {}),
      ...(props._text ?? {}),
    },
  };

  return (
    <NBButton colorScheme={colorScheme} {...allProps}>
      {typeof children === 'string' ? (
        children
      ) : (
        <Trans ns="buttons">{children}</Trans>
      )}
    </NBButton>
  );
};

export const HoverButton = () => {
  const t = useTranslation('buttons');

  return (
    // Here you can see _hover will be overrided by _pressed
    <KNOWButton
      _hover={{
        bg: 'red.500',
      }}
      _pressed={{
        bg: 'green.500',
      }}
      onPress={() => {}}
    >
      {t('press-and-hover')}
    </KNOWButton>
  );
};

export const DrawerToggleButton = (props: any) => {
  return (
    <IconButton
      {...props}
      icon={<HamburgerIcon color="gray.500" size="sm" />}
    />
  );
};

export const ButtonGroup = NBButton.Group;
