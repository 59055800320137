import React from 'react';
import { createStackNavigator } from '@know/ui';

import {
  ListPageNavigator,
  linking as listScreenLinking,
} from './ListPageNavigator';
import {
  CoursesDetailsScreen,
  courseLinking,
  LJCourseLinking,
} from './CourseDetailsPage';
import { LessonCardPage } from './LessonCardPage';
import { LessonCompletedPage } from './LessonCardPage/LessonCompletedPage';
import { JourneyDetailsScreen, ljLinking } from './JourneyDetailsPage';
import {
  LaunchPublicCourseScreen,
  linking as launchPublicCourseLinking,
  linkingWithKnow as launchKNOWPublicCourseLinking,
} from './LaunchPublicCourse';

const CoursesStack = createStackNavigator();

export const linking = {
  initialRouteName: 'list',
  screens: {
    'list': listScreenLinking,
    'course-details': courseLinking,
    'journey-course-details': LJCourseLinking,
    'journey-details': ljLinking,
    'LaunchPublicCourse': launchPublicCourseLinking,
    'LaunchKNOWPublicCourse': launchKNOWPublicCourseLinking,
  },
};

const tmpRoutes: { [key: string]: boolean } = {
  LaunchPublicCourse: true,
  LaunchKNOWPublicCourse: true,
};

/*
      detachInactiveScreens
      screenOptions={{
        detachPreviousScreen: true,
      }}
*/
export const MyCoursesNavigator = () => {
  return (
    <CoursesStack.Navigator
      initialRouteName="list"
      screenListeners={({ navigation }) => ({
        state: (e: any) => {
          // Do something with the state
          console.log('state changed', e.data);
          const state = e?.data?.state;
          const routesLength = state?.routes?.length;
          const previousRoute = state?.routes[routesLength - 2];
          if (tmpRoutes[previousRoute?.name]) {
            const newRoutes = state?.routes.filter(
              ({ name }: any) => !tmpRoutes[name]
            );
            const newState = {
              ...state,
              routes: newRoutes,
              index: newRoutes.length - 1,
            };
            console.log(newState);
            navigation.reset(newState);
          }
          // Do something with the `navigation` object
          if (!navigation.canGoBack()) {
            console.log("we're on the initial screen");
          }
        },
      })}
    >
      <CoursesStack.Screen
        name="list"
        component={ListPageNavigator}
        options={{
          headerShown: false,
        }}
      />
      <CoursesStack.Screen
        name="course-details"
        component={(props) => <CoursesDetailsScreen {...props} />}
        options={{
          headerShown: false,
        }}
      />
      <CoursesStack.Screen
        name="journey-course-details"
        component={(props) => <CoursesDetailsScreen {...props} />}
        options={{
          headerShown: false,
        }}
      />
      <CoursesStack.Screen
        name="journey-details"
        component={(props) => <JourneyDetailsScreen {...props} />}
        options={{
          headerShown: false,
        }}
      />
      <CoursesStack.Screen
        name="card"
        component={(props) => <LessonCardPage {...props} />}
        options={{
          headerShown: false,
          detachPreviousScreen: true,
        }}
      />
      <CoursesStack.Screen
        name="lesson-completed"
        component={(props) => <LessonCompletedPage {...props} />}
        options={{
          headerShown: false,
        }}
      />
      <CoursesStack.Screen
        name="LaunchPublicCourse"
        component={(props) => <LaunchPublicCourseScreen {...props} />}
        options={{
          headerShown: false,
        }}
      />
      <CoursesStack.Screen
        name="LaunchKNOWPublicCourse"
        component={(props) => <LaunchPublicCourseScreen {...props} />}
        options={{
          headerShown: false,
        }}
      />
    </CoursesStack.Navigator>
  );
};
