import { selector, selectorFamily } from '@know/ui';
import {
  IReceivedResponseItem,
  prepareUserReceivedFormsListData,
} from '@know/transformers';

import {
  userReceivedFormsAtom,
  receivedListFilterTextAtom,
  receivedSectionsExpandStateAtom,
} from './atoms';

import type { ISectionDataProps } from '@know/transformers';

// Define the selector
// export const userFormReceivedFormsDataSelector = selector<
//   ISectionDataProps<IReceivedResponseItem>[]
// >({
//   key: 'userFormReceivedFormsDataSelector',
//   get: ({ get }) => {
//     const userReceivedForms = get(userReceivedFormsAtom);
//     const filterText = get(receivedListFilterTextAtom);

//     return prepareUserReceivedFormsListData({
//       userReceivedForms,
//       filterText,
//     });
//   },
// });

export const userReceivedSectionDataSelectorWithToggleState = selector<
  ISectionDataProps<IReceivedResponseItem>[]
>({
  key: 'userReceivedSectionDataSelectorWithToggleState',
  get: ({ get }) => {
    const userReceivedForms = get(userReceivedFormsAtom);
    const filterText = get(receivedListFilterTextAtom);

    const userReceivedSectionData = prepareUserReceivedFormsListData({
      userReceivedForms,
      filterText,
    });
    const toggleState: Record<string, boolean> = get(
      receivedSectionsExpandStateAtom
    );

    return userReceivedSectionData.map((sectionItem) => {
      return {
        ...sectionItem,
        isExpanded: toggleState[sectionItem.id || sectionItem.type],
      };
    });
  },
});

export const receivedFormSelector = selectorFamily({
  key: 'receivedFormSelector',
  get:
    (formId: string) =>
    ({ get }) => {
      const userForm = get(userReceivedFormsAtom);
      return userForm[formId];
    },
});
