import React from 'react';
import { createIcon } from 'native-base';
import { Rect } from 'react-native-svg';

export const QRScannerIcon = createIcon({
  viewBox: '0 0 350 350',
  path: [
    <Rect width="40" height="7" fill="white" />,
    <Rect
      x="8"
      width="40"
      height="8"
      transform="rotate(90 8 0)"
      fill="white"
    />,
    <Rect
      x="350"
      width="40"
      height="7"
      transform="rotate(90 350 0)"
      fill="white"
    />,
    <Rect
      x="350"
      y="8"
      width="40"
      height="8"
      transform="rotate(-180 350 8)"
      fill="white"
    />,
    <Rect
      x="350"
      y="350"
      width="40"
      height="7"
      transform="rotate(180 350 350)"
      fill="white"
    />,
    <Rect
      x="342"
      y="350"
      width="40"
      height="8"
      transform="rotate(-90 342 350)"
      fill="white"
    />,
    <Rect
      x="0.000244141"
      y="350"
      width="40"
      height="7"
      transform="rotate(-90 0.000244141 350)"
      fill="white"
    />,
    <Rect x="0.000488281" y="342" width="40" height="8" fill="white" />,
  ],
});
