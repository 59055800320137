import React, { useRef } from 'react';
import {
  subscribeToIssueUpdates,
  listenToIssueNugget,
  listenToIssueMuteStatus,
  IssueUpdateType,
  closeIssue,
  reopenIssue,
  addIssueProgressRemark,
  addIssueStatusProgressAttachment,
  getIssueUsers as getIssueUsersFromDB,
  updateIssueSeverity,
  raiseIssueStatusChangeRequest,
  UploadFileResultType,
  addIssueSeverityUpdate,
  toggleIssueMuteStatus,
  getIssueCustomForms,
  SeverityType,
} from '@know/db';
import { IssueNugget, prepareUsersDetails } from '@know/transformers';
import {
  Box,
  Text,
  useDebounceLoading,
  useHideHeader,
  useKNOWDBContext,
  useKNOWValueContextProvider,
  useNavigation,
  useKNOWToast,
  useDebounceSetData,
  IssueStatusOnClickPayloadType,
  UploadProgressType,
  EllipsisMenuOption,
  ExpoFAIcon,
  UploadImageModalRef,
  ExpoIonicIcon,
  ExpoMCIcon,
  UploadVideoModalRef,
  UploadFileHandlerRef,
  useDisclose,
  // useRecoilValue,
  UploadVideoPlayButtonIcon,
  ExtendedUserDetailsInterface,
  useRecoilValue,
  CommonActions,
  SelectItemType,
  MuteIcon,
  UnmuteIcon,
  NotifyLocationLeader,
  UpdateSeverityIcon,
} from '@know/ui';
import { chunk, keys, map, omit, orderBy, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { Dimensions } from 'react-native';
import { issueLastOpenTabAtom, issueRouteAtom } from '../state';

const windowDimensions = Dimensions.get('window');

const EMPTY_FUNCTION = () => {};

export const useIssueDetails = (
  issueId: string,
  shareId: string | undefined
) => {
  const {
    currentDBApi,
    getCurrentUserUserId,
    getCurrentUserName,
    uploadFilesWithFilesDetails: uploadFilesDB,
    userDetails,
  } = useKNOWDBContext();
  const { isTabletSize, isLargePhoneSize, isPhoneSize, openShareDialog } =
    useKNOWValueContextProvider();
  const [isLoading, setIsLoading] = useState(true);
  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const lastIssueTabOpened = useRecoilValue(issueLastOpenTabAtom);

  const isSmallScreen = isTabletSize || isLargePhoneSize || isPhoneSize;

  const [screenHeight, setScreenHeight] = useState(windowDimensions.height);

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [topBarHeight, setTopBarHeight] = useState<number>(0);

  const [detailsContainerWidth, setDetailsContainerWidth] = useState<number>(0);
  const [detailsContainerHeight, setDetailsContainerHeight] =
    useState<number>(0);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setScreenHeight(window.height);
    });
    return () => subscription?.remove();
  }, []);

  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);

  const setDataLastUpdated = useDebounceLoading(stopLoading, 700);

  // eslint-disable-next-line prettier/prettier
  const [issueUpdates, setIssueUpdates] = useState<Record<string, IssueUpdateType>>({});
  const [issueNugget, setIssueNugget] = useState<IssueNugget | null>(null);
  const [isIssueMuted, setIsIssueMuted] = useState<boolean>(false);

  const setIssueUpdatesDebounce = useDebounceSetData(
    issueUpdates,
    setIssueUpdates,
    200,
    true
  );

  const [raisedErrors, setRaisedErrors] = useState<boolean>(false);

  const navigation = useNavigation();

  const issueRoutes = useRecoilValue(issueRouteAtom);

  const { successToast, errorToast } = useKNOWToast();

  const {
    isOpen: isCloseIssueDialogOpen,
    onClose: onCloseIssueDialogClose,
    onOpen: onCloseIssueDialogOpen,
  } = useDisclose();

  const {
    isOpen: isReopenIssueDialogOpen,
    onClose: onReopenIssueClose,
    onOpen: onReopenIssueOpen,
  } = useDisclose();

  const {
    isOpen: isUpdateSeverityOpen,
    onClose: onUpdateSeverityClose,
    onOpen: onUpdateSeverityOpen,
  } = useDisclose();

  const listRef = useRef<any>(null);
  const containerListRef = useRef<any>(null);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadProgressProps, setUploadProgressProps] = useState<{
    type: UploadProgressType;
    numberOfFiles: number;
  }>({ type: 'signature', numberOfFiles: 1 });

  const uploadImageModalRef = useRef<UploadImageModalRef | null>(null);
  const uploadVideoModalRef = useRef<UploadVideoModalRef | null>(null);
  const uploadFileHandlerRef = useRef<UploadFileHandlerRef | null>(null);

  const [issueUsers, setIssueUsers] = useState<ExtendedUserDetailsInterface[]>(
    []
  );
  const [isFetchedIssuesUsers, setIsFetchedIssuesUsers] =
    useState<boolean>(false);
  const [isIssueUsersLoading, setIsIssueUsersLoading] = useState<boolean>(true);

  const {
    isOpen: isIssueUsersDialogOpen,
    onClose: closeIssueUsersDialog,
    onOpen: _openIssueUsersDialog,
  } = useDisclose(false);

  const onNewIssueUpdateWithDebounced = useCallback(
    (issueUpdateId, issueUpdateItem) => {
      setDataLastUpdated(Date.now());
      setIssueUpdatesDebounce((currentIssueUpdates: any) => {
        if (issueUpdateItem) {
          return {
            ...currentIssueUpdates,
            [issueUpdateId]: issueUpdateItem,
          };
        } else {
          return omit(currentIssueUpdates, [issueUpdateId]);
        }
      });
    },
    [setDataLastUpdated, setIssueUpdatesDebounce]
  );

  const onNewIssueUpdate = useCallback(
    (issueUpdateId, issueUpdateItem) => {
      setDataLastUpdated(Date.now());
      setIssueUpdates((currentIssueUpdates: any) => {
        if (issueUpdateItem) {
          return {
            ...currentIssueUpdates,
            [issueUpdateId]: issueUpdateItem,
          };
        } else {
          return omit(currentIssueUpdates, [issueUpdateId]);
        }
      });
    },
    [setDataLastUpdated]
  );

  useEffect(() => {
    if (currentDBApi) {
      const unSubScribeIssueUpdates = subscribeToIssueUpdates(
        currentDBApi,
        issueId,
        onNewIssueUpdateWithDebounced
      );

      const unSubToIssueNugget = listenToIssueNugget(
        currentDBApi,
        issueId,
        (issue: IssueNugget) => {
          setIssueNugget(issue);
          setIsActionLoading(false);
        }
      );
      const unSubToIssueMuteStatus = listenToIssueMuteStatus(
        currentDBApi,
        issueId,
        setIsIssueMuted
      );
      setTimeout(() => setDataLastUpdated(Date.now()), 2000);
      // Return statement has un listening callback.
      return () => {
        unSubScribeIssueUpdates();
        unSubToIssueNugget();
        unSubToIssueMuteStatus();
      };
    }
    return EMPTY_FUNCTION;
  }, [
    currentDBApi,
    issueId,
    onNewIssueUpdateWithDebounced,
    setDataLastUpdated,
  ]);

  useEffect(() => {
    if (
      (!issueNugget || !keys(issueNugget).length) &&
      !isLoading &&
      !raisedErrors
    ) {
      setRaisedErrors(true);
      errorToast({ header: "Issue doesn't exist or was deleted!" });
    }
  }, [isLoading, issueNugget, raisedErrors, errorToast]);

  useHideHeader();

  const goBack = useCallback(() => {
    const routes = issueRoutes.map((name: string) => ({
      name: name as never,
    }));
    const filteredRoutes = routes.filter(
      ({ name }) => name === lastIssueTabOpened
    );

    const resetPayload = {
      routes: [
        {
          name: 'Issues',
          state: {
            routes: [
              {
                name: 'issues-list',
                state: {
                  routes: filteredRoutes,
                },
              },
            ],
          },
        },
      ],
    };

    navigation.dispatch(CommonActions.reset(resetPayload));
  }, [issueRoutes, lastIssueTabOpened, navigation]);

  const actionButtonText = useMemo(() => {
    if (issueNugget?.status?.toLowerCase() === 'open') {
      return 'Close Issue';
    }
    if (issueNugget?.status?.toLowerCase() === 'closed') {
      return 'Reopen Issue';
    }

    // reopened issue
    return 'Close Issue';
  }, [issueNugget?.status]);

  const sortedIssueUpdates = useMemo(
    () =>
      orderBy(
        map(issueUpdates, (u, issueUpdateId) => ({ ...u, issueUpdateId })),
        'timestamp',
        'desc'
      ),
    [issueUpdates]
  );

  const onClickPayload: IssueStatusOnClickPayloadType = useCallback(
    (payload: any, payloadType: any) => {
      if (payloadType === 'form') {
        const formId = payload.formId;
        const responseId = payload.responseId;
        const initiatorId = payload.userId;

        navigation.navigate(
          'Forms' as never,
          {
            screen: 'webview',
            params: {
              formId,
              responseId,
              initiatorId,
              type: 'sent',
              parentId: issueId,
              parentType: 'issues',
              parentShareId: shareId,
            },
          } as never
        );
      }
    },
    [issueId, navigation, shareId]
  );

  const onClickChangeSeverity = useCallback(
    async (items: SelectItemType[]) => {
      onUpdateSeverityClose();
      const { value: severity, color: severityColor } = items[0];
      if (currentDBApi) {
        updateIssueSeverity(currentDBApi, issueId, severity as SeverityType);

        await raiseIssueStatusChangeRequest(
          currentDBApi,
          issueId,
          'updated',
          getCurrentUserName()
        );

        await addIssueSeverityUpdate(
          currentDBApi,
          issueId,
          severity as SeverityType,
          severityColor,
          getCurrentUserName(),
          onNewIssueUpdate
        );
      }
    },
    [
      currentDBApi,
      issueId,
      getCurrentUserName,
      onNewIssueUpdate,
      onUpdateSeverityClose,
    ]
  );

  const onMuteIssue = useCallback(() => {
    if (currentDBApi) {
      toggleIssueMuteStatus(currentDBApi, issueId, successToast);
    }
  }, [currentDBApi, issueId, successToast]);

  const onNotifyLocationLeader = useCallback(async () => {
    if (currentDBApi) {
      await raiseIssueStatusChangeRequest(
        currentDBApi,
        issueId,
        'notifyLeaders',
        getCurrentUserName()
      );

      successToast({ header: 'Location leaders have been notified!' });
    }
  }, [currentDBApi, getCurrentUserName, issueId, successToast]);

  const onClickImage = useCallback((url: string) => {
    if (Platform.OS === 'web') {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const onClickPdf = useCallback((url: string) => {
    if (Platform.OS === 'web') {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const [customForms, setCustomForms] = useState<any>(null);

  useEffect(() => {
    if (currentDBApi && issueNugget) {
      const fetchCustomForms = async () => {
        if (currentDBApi && issueNugget) {
          const forms = await getIssueCustomForms(
            currentDBApi,
            issueNugget.incidentID
          );
          setCustomForms(forms);
        }
      };
      fetchCustomForms();
    }
  }, [currentDBApi, issueNugget]);

  const issueHasClosureReport = useMemo(() => {
    return customForms && customForms.close ? true : false;
  }, [customForms]);

  const openClosureReport = useCallback(() => {
    // when an issue has a closure report, clicking submit opens up closure report webview
    if (issueHasClosureReport) {
      const { id: formId } = customForms?.close;
      navigation.navigate(
        'Forms' as never,
        {
          screen: 'webview',
          params: {
            formId,
            type: 'new',
            responseId: '-',
            initiatorId: '-',
            parentId: issueId,
            parentType: 'issues',
            parentShareId: shareId,
            skipDraft: true,
          },
          initial: false,
        } as never
      );
    }
  }, [customForms?.close, issueHasClosureReport, issueId, navigation, shareId]);

  const onClickActionButton = useCallback(() => {
    if (currentDBApi && issueNugget) {
      if (issueNugget?.status?.toLowerCase() === 'open') {
        onCloseIssueDialogOpen();
      } else if (issueNugget?.status?.toLowerCase() === 'closed') {
        onReopenIssueOpen();
      }
    }
  }, [currentDBApi, issueNugget, onCloseIssueDialogOpen, onReopenIssueOpen]);

  const onCloseIssue = useCallback(
    async (params: {
      remark: string;
      image?: UploadFileResultType | undefined;
    }) => {
      if (currentDBApi && issueNugget) {
        setIsActionLoading(true);
        onCloseIssueDialogClose();
        await closeIssue(
          currentDBApi,
          issueId,
          userDetails,
          issueNugget,
          params
        );
      }
    },
    [currentDBApi, issueNugget, onCloseIssueDialogClose, issueId, userDetails]
  );

  const onReopenIssue = useCallback(
    async (params: {
      remark: string;
      image?: UploadFileResultType | undefined;
    }) => {
      if (currentDBApi && issueNugget) {
        setIsActionLoading(true);
        onReopenIssueClose();
        await reopenIssue(currentDBApi, issueId, userDetails, params);
      }
    },
    [onReopenIssueClose, currentDBApi, issueId, issueNugget, userDetails]
  );

  const uploadFiles = useCallback(
    async (files: File[], uploadType: UploadProgressType) => {
      setUploadProgress(0);
      setUploadProgressProps({ numberOfFiles: files.length, type: uploadType });
      setIsUploading(true);
      const path = `${currentDBApi?.organization ?? 'na'}/${
        currentDBApi?.getLoggedInUserId() ?? 'user-id'
      }/${issueId}`;
      const results = await uploadFilesDB(path, files, (progress: any) => {
        setUploadProgress(progress);
      });
      setUploadProgress(100);
      setIsUploading(false);
      setUploadProgress(0);

      return results ?? [];
    },
    [currentDBApi, issueId, uploadFilesDB]
  );

  const onSendRemark = useCallback(
    async (text: string) => {
      if (currentDBApi) {
        await addIssueProgressRemark(
          currentDBApi,
          issueId,
          text,
          getCurrentUserName(),
          onNewIssueUpdate
        );

        await raiseIssueStatusChangeRequest(
          currentDBApi,
          issueId,
          'updated',
          getCurrentUserName()
        );

        if (isSmallScreen) {
          containerListRef.current?.scrollTo({
            x: 0,
            y: detailsContainerHeight,
            animated: true,
          });
        } else {
          listRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }
      }
    },
    [
      currentDBApi,
      detailsContainerHeight,
      getCurrentUserName,
      isSmallScreen,
      onNewIssueUpdate,
      issueId,
    ]
  );

  const menuOptions = useMemo<EllipsisMenuOption[][]>(() => {
    return [
      [
        {
          id: ' take-photo',
          Icon: <ExpoFAIcon name="camera" size={'18px'} color="gray.700" />,
          label: 'Take Photo',
          onClick: () => {
            uploadImageModalRef.current?.openCamera?.();
          },
        },
        {
          id: ' upload-photo',
          Icon: <ExpoIonicIcon size="18px" name="image" color="gray.700" />,
          label: 'Upload Photo',
          onClick: () => {
            uploadImageModalRef.current?.openUpload?.();
          },
        },
      ],
      [
        {
          id: ' record-video',
          Icon: (
            <ExpoIonicIcon
              name="videocam-sharp"
              size={'18px'}
              color="gray.700"
            />
          ),
          label: 'Record Video',
          onClick: () => {
            uploadVideoModalRef.current?.openCamera?.();
          },
        },
        {
          id: ' upload-video',
          Icon: <UploadVideoPlayButtonIcon size="18px" color="gray.700" />,
          label: 'Upload Video',
          onClick: () => {
            uploadVideoModalRef.current?.openUpload?.();
          },
        },
      ],
      [
        {
          id: ' upload-pdf',
          Icon: <ExpoMCIcon name="file" size="18px" color="gray.700" />,
          label: 'Upload Document',
          onClick: () => {
            uploadFileHandlerRef.current?.onClick?.();
          },
        },
        // {
        //   id: ' record-audio',
        //   Icon: <ExpoMCIcon name="microphone" size="18px" color="gray.700" />,
        //   label: 'Record Audio',
        //   onClick: () => {
        //     uploadFileHandlerRef.current?.onClick?.();
        //   },
        // },
      ],
    ];
  }, []);

  const onAddAttachment = useCallback(
    async (files: File[], uploadType: UploadProgressType) => {
      if (currentDBApi && uploadType !== 'signature') {
        const uploadedFiles = await uploadFiles(files, uploadType);
        const chunkLength = uploadType === 'image' ? 10 : 1;
        const chunks = chunk(uploadedFiles, chunkLength);

        await Promise.all(
          chunks.map(async (chunkedFiles: any) => {
            await addIssueStatusProgressAttachment(
              currentDBApi,
              issueId,
              uploadType === 'file' ? 'pdf' : uploadType,
              chunkedFiles,
              getCurrentUserName(),
              onNewIssueUpdate
            );
          })
        );

        await raiseIssueStatusChangeRequest(
          currentDBApi,
          issueId,
          'updated',
          getCurrentUserName()
        );

        if (isSmallScreen) {
          containerListRef.current?.scrollTo({
            x: 0,
            y: detailsContainerHeight,
            animated: true,
          });
        } else {
          listRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }
      }

      return [];
    },
    [
      currentDBApi,
      uploadFiles,
      isSmallScreen,
      issueId,
      getCurrentUserName,
      onNewIssueUpdate,
      detailsContainerHeight,
    ]
  );

  const onAddImages = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'image');
    },
    [onAddAttachment]
  );

  const onAddVideos = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'video');
    },
    [onAddAttachment]
  );

  const onAddFiles = useCallback(
    (files: File[]) => {
      return onAddAttachment(files, 'file');
    },
    [onAddAttachment]
  );

  const onClickShare = useCallback(() => {
    if (issueId) {
      openShareDialog(
        'tasklist',
        issueId,
        issueNugget?.author ?? getCurrentUserUserId() ?? '-'
      );
    }
  }, [getCurrentUserUserId, openShareDialog, issueId, issueNugget?.author]);

  const getIssuesUsers = useCallback(async () => {
    if (currentDBApi && issueId) {
      setIsIssueUsersLoading(true);
      setIsFetchedIssuesUsers(true);
      const users = await getIssueUsersFromDB(currentDBApi, issueId);
      setIssueUsers(sortBy(users.map(prepareUsersDetails), 'userName'));
      setIsIssueUsersLoading(false);
    }
  }, [currentDBApi, issueId]);

  const openIssueUsersDialog = useCallback(() => {
    _openIssueUsersDialog();
    if (!isFetchedIssuesUsers) {
      getIssuesUsers();
    }
  }, [getIssuesUsers, isFetchedIssuesUsers, _openIssueUsersDialog]);

  const getItemComponent = useCallback(
    (
      item: any,
      { isHovered, isSelected }: { isHovered?: boolean; isSelected?: boolean }
    ) => {
      return (
        <Box
          bgColor={
            isSelected ? 'primary.100' : isHovered ? 'gray.200' : 'white'
          }
          w={'100%'}
          h={'100%'}
          p="12px"
          display={'flex'}
          flexDir={'row'}
          alignItems={'center'}
        >
          {item.icon}
          <Text
            ml={'6px'}
            color={item.color}
            fontWeight={600}
            fontSize={'14px'}
          >
            {item.value}
          </Text>
        </Box>
      );
    },
    []
  );

  const _getItemComponentWithSelectedCheck = useCallback(
    (item: SelectItemType, isHovered?: boolean) =>
      getItemComponent(item, {
        isSelected: item.value === issueNugget?.severity,
        isHovered,
      }),
    [getItemComponent, issueNugget?.severity]
  );

  const ellipsisOptions = useMemo(() => {
    if (issueNugget?.status?.toLowerCase() === 'closed') {
      return [];
    }

    return [
      {
        id: isIssueMuted ? 'Unmute' : 'Mute',
        label: isIssueMuted ? 'Unmute' : 'Mute',
        Icon: isIssueMuted ? (
          <UnmuteIcon size={'20px'} color={'#4E5656'} />
        ) : (
          <MuteIcon size={'20px'} color={'#4E5656'} />
        ),
        onClick: onMuteIssue,
      },
      {
        id: 'Notify Location Leaders',
        label: 'Notify Location Leaders',
        Icon: <NotifyLocationLeader size={'20px'} color={'#4E5656'} />,
        onClick: onNotifyLocationLeader,
      },
      {
        id: 'Update Severity',
        label: 'Update Severity',
        Icon: <UpdateSeverityIcon size={'20px'} color={'#4E5656'} />,
        onClick: onUpdateSeverityOpen,
      },
    ];
  }, [
    isIssueMuted,
    issueNugget?.status,
    onMuteIssue,
    onNotifyLocationLeader,
    onUpdateSeverityOpen,
  ]);

  return {
    isLoading,
    issueHasClosureReport,
    isIssueMuted,
    screenHeight,
    issueUpdates: sortedIssueUpdates,
    issueNugget,
    isSmallScreen,
    actionButtonText,
    isActionLoading,
    isUploading,
    uploadProgress,
    uploadProgressProps,
    isPhoneSize,
    menuOptions,
    uploadImageModalRef,
    uploadVideoModalRef,
    uploadFileHandlerRef,
    issueUsers,
    isIssueUsersLoading,
    isIssueUsersDialogOpen,
    listRef,
    containerListRef,
    containerHeight,
    topBarHeight,
    detailsContainerWidth,
    detailsContainerHeight,
    isCloseIssueDialogOpen,
    isReopenIssueDialogOpen,
    isUpdateSeverityOpen,
    ellipsisOptions,
    setContainerHeight,
    setTopBarHeight,
    setDetailsContainerWidth,
    setDetailsContainerHeight,
    closeIssueUsersDialog,
    openIssueUsersDialog,
    onCloseIssueDialogClose,
    onReopenIssueClose,
    onCloseIssueDialogOpen,
    onReopenIssueOpen,
    goBack,
    onClickChangeSeverity,
    onClickImage,
    onClickPayload,
    onClickActionButton,
    uploadFiles,
    onSendRemark,
    onClickPdf,
    onAddImages,
    onAddVideos,
    onAddFiles,
    onClickShare,
    onCloseIssue,
    onReopenIssue,
    onUpdateSeverityClose,
    onUpdateSeverityOpen,
    onMuteIssue,
    onNotifyLocationLeader,
    _getItemComponentWithSelectedCheck,
    openClosureReport,
  };
};
