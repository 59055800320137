import { serverTimestamp } from "firebase/database";
import { FirebaseAPI, IFormFeedItem } from "..";
import { getFormsAnalyticsPayload } from "@know/transformers";

export const FORMS_ANALYTICS_NODE_PATH = "/analyticsRequest";

interface IRaiseAnalyticsProps {
  fbAPI: FirebaseAPI;
  type: string;
  formItem: IFormFeedItem;
}

export function getUserFormsFeedPath(fbAPI: FirebaseAPI, formId: string = '') {
  const userId = fbAPI.getLoggedInUserId();
  return fbAPI.getMultiPath(`forms/user_forms/${userId}/${formId}`);
}

export async function raiseAnalytics({
  fbAPI,
  ...props
}: IRaiseAnalyticsProps) {
  const userId = fbAPI.getLoggedInUserId();
  if (userId !== null) {
    if (props.type === 'opened') {
      if (!props.formItem.isRead) {
        return await fbAPI.fbUpdate(getUserFormsFeedPath(fbAPI, props.formItem.id), {
          isRead: true
        });
      }
    }
    else {
      await fbAPI.pushToNode(
        FORMS_ANALYTICS_NODE_PATH,
        getFormsAnalyticsPayload({
          ...props,
          userId: userId || "",
          organization: fbAPI.organization,
          serverTimestamp,
        })
      );
    }
  }
}
