import { useEffect } from 'react';

import { useRouterContext } from '../KNOWProvider/RouteProvider';

import type { IModuleProps } from '../KNOWProvider/RouteProvider';

export const useRoutes = (
  hideDownload: boolean
): { userRoutes: Array<IModuleProps>; servingFrom?: string } => {
  const { userScreens, setHideDownload, servingFrom } = useRouterContext();

  useEffect(
    () => setHideDownload(!hideDownload),
    [setHideDownload, hideDownload]
  );
  return { userRoutes: userScreens, servingFrom };
};
