import React, { useCallback, useEffect, useMemo } from 'react';
import {
  View,
  Text,
  ScrollView,
  WithKNOWLoader,
  WebView,
  Center,
} from '@know/ui';
import { Trans } from '@know/i18n';

const Buffer = require('buffer').Buffer;

import type { ISCORMOnly } from './types';

export const SCORMOnly: React.FC<ISCORMOnly> = ({
  scromObj,
  scromProgress,
  handleScromProgress,
}) => {
  const progress = new Buffer(
    JSON.stringify(scromProgress.progress || {})
  ).toString('base64');

  console.log(progress);
  const url = useMemo(() => {
    if (scromObj?.payload?.url?.includes(window.location.hostname)) {
      return scromObj?.payload?.url;
    }
    // To serve from same origin domain.
    return scromObj?.payload?.url?.replace('https://', '/webapp-scorms/');
  }, [scromObj?.payload?.url]);

  const SetSCORMCompleted = useCallback(
    async (payload: string) => {
      console.log('SCORMOnly::SetSCORMCompleted', payload);
      await handleScromProgress({
        data: { eventType: 'completed', progress: payload },
      });
    },
    [handleScromProgress]
  );

  const SetSCORMProgress = useCallback(
    async (payload: string) => {
      console.log('SCORMOnly::SetSCORMProgress', payload);
      await handleScromProgress({
        data: { eventType: 'progress', progress: payload },
      });
    },
    [handleScromProgress]
  );

  useEffect(() => {
    (window as any).isWebapp = true;
    (window as any).KnowJSInterface = {
      SetSCORMCompleted,
      SetSCORMProgress,
    };
  });

  const onWebviewLoad = useCallback(
    (iframe: HTMLIFrameElement | null) => {
      if (!iframe?.contentWindow) return;
      const iframeWindow = iframe.contentWindow.window as any;
      iframeWindow.playSCORM(progress);
    },
    [progress]
  );

  return (
    <WithKNOWLoader
      isLoading={!scromProgress?.isScromLoaded}
      color="primary"
      type="horizontal"
      size="lg"
    >
      <ScrollView flex={1}>
        <View fontSize="2xl" fontWeight="600" padding="16px" height="720px">
          <Text>{scromObj?.title}</Text>
          {url ? (
            <WebView url={url} onLoad={onWebviewLoad} />
          ) : (
            <Center height={'50%'}>
              <Text variant={'body'}>
                <Trans ns="messages">
                  This SCORM content is not supported. Please contact support
                  team at support@knownuggets.com
                </Trans>
              </Text>
            </Center>
          )}
        </View>
      </ScrollView>
    </WithKNOWLoader>
  );
};
