import { atom } from '@know/ui';
import { TaskFeedItem } from '@know/transformers';

export const globalMutedIssueAtom = atom<Record<string, TaskFeedItem>>({
  key: 'globalMutedIssueAtom',
  default: {},
});

export const issueLastOpenTabAtom = atom<string>({
  key: 'issueLastOpenTabAtom',
  default: 'issue-feed',
});

export const issueRouteAtom = atom<string[]>({
  key: 'issueRouteAtom',
  default: [],
});
