import React, { useCallback, useContext, useEffect } from 'react';
import {
  StackHeaderProps,
  LessonCompleted,
  KNOWDBContext,
  useRecoilState,
} from '@know/ui';
import { useLessonDetails } from './useLessonDetails';
import { lmsReportsPayloadAtom } from '../state';

export const LessonCompletedPage = ({ route }: StackHeaderProps) => {
  const params = route?.params as any;
  const { cardId, courseId, lessonId, journeyId } = params;

  const { currentDBApi } = useContext(KNOWDBContext);

  const [reportsPayload, setReportsPayload] = useRecoilState(
    lmsReportsPayloadAtom
  );

  const { hideNextLessonBtn, handleNextLesson, handleCoursePreview } =
    useLessonDetails(courseId, lessonId, cardId, journeyId);

  const handleLMSReportsPayload = useCallback(async () => {
    if (Object.keys(reportsPayload)?.length) {
      for (const iterator in reportsPayload) {
        // @ts-ignore
        await currentDBApi?.setUserFeedCourseInfo('LMSAnalyticsCallback', {
          analyticsCBPayload: {
            ...reportsPayload[iterator],
            cardsCompletedInCurrentSessionIds: {},
            sessionEndTime: Date.now(),
          },
        });
        if (reportsPayload[iterator]?.courseStatus === 'completed') {
          setReportsPayload((prev) => {
            const state = Object.fromEntries(
              Object.keys(prev)
                .filter((id) => id !== iterator)
                .map((id) => [id, prev[id]])
            );
            return state;
          });
        }
      }
    }
  }, [currentDBApi, reportsPayload, setReportsPayload]);

  useEffect(() => {
    handleLMSReportsPayload();
  }, [handleLMSReportsPayload]);

  const handleNextLessonWithReplace = useCallback(() => {
    handleNextLesson(true);
  }, [handleNextLesson]);

  return (
    <LessonCompleted
      hideNextLessonBtn={hideNextLessonBtn}
      handleNextLesson={handleNextLessonWithReplace}
      handleCoursePreview={handleCoursePreview}
    />
  );
};
