import React, { useMemo } from 'react';

import { Box, HStack, KNPressable, Spinner, Text } from '..';

export interface ITabButtonProps {
  isLoading?: boolean;
  id: string;
  title: string;
  onPress?: (id: string) => void;
  isActive: boolean;
}

export function TabButton({
  id,
  title,
  onPress,
  isActive = false,
  isLoading = false,
}: ITabButtonProps) {
  const activeStyles = useMemo(() => {
    if (isActive) {
      return {
        color: 'primary.500',
        borderBottomWidth: 2,
        borderBottomColor: 'currentColor',
      };
    }
    return {
      color: 'gray.700',
      borderBottomWidth: 1,
      borderBottomColor: 'gray.350',
    };
  }, [isActive]);

  return (
    <Box {...activeStyles} flex={1} pb={2} justifyContent={'center'}>
      <KNPressable alignItems={'center'} onPress={() => onPress?.(id)}>
        <HStack space={1}>
          {isLoading ? <Spinner /> : null}
          <Text
            color={'currentColor'}
            fontWeight={isActive ? 'bold' : 'normal'}
            textAlign={'center'}
            lineHeight={'18.2px'}
          >
            {title}
          </Text>
        </HStack>
      </KNPressable>
    </Box>
  );
}
