import { useKNOWValueContextProvider } from '@know/ui';
import { useMemo, useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

const windowDimensions = Dimensions.get('window');

const getItemSize = (
  screenWidth: number,
  numberOfItems: number,
  margin: number,
  listMargin: number
) => {
  const widthWithoutSpace =
    screenWidth - margin * numberOfItems - listMargin * 2;
  return widthWithoutSpace / numberOfItems;
};

export const useItemSize = (
  itemMargin: number,
  getListHeight?: (width: number) => number
) => {
  const {
    isPhoneSize,
    isLargePhoneSize,
    isTabletSize,
    isLargeTabletSize,
    isDesktopSize,
  } = useKNOWValueContextProvider();

  const [screenWidth, setScreenWidth] = useState(windowDimensions.width);

  const listMargin = useMemo(() => {
    if (isDesktopSize) {
      return 60;
    }

    return Math.round((screenWidth * 4) / 100);
  }, [isDesktopSize, screenWidth]);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setScreenWidth(window.width);
    });
    return () => subscription?.remove();
  }, []);

  const results = useMemo(() => {
    let numberOfItems = 3;
    let defaultHeight = '162px';

    if (isPhoneSize) {
      // we want to show 2 and a part of the 3rd course here
      numberOfItems = 2.75;
      defaultHeight = '162px';
    } else if (isLargePhoneSize) {
      // we want to show 2 and a part of the 3rd course here
      numberOfItems = 3;
      defaultHeight = '186px';
    } else if (isTabletSize) {
      // we want to show 4 and a part of the 5th course here
      numberOfItems = 4;
      defaultHeight = '186px';
    } else if (isLargeTabletSize) {
      // we want to show 4 and a part of the 5th course here
      numberOfItems = 4;
      defaultHeight = '216px';
    } // doing large desktop first since large desktop is inclusive in desktop size
    else if (isDesktopSize) {
      // we want to show 6 and a part of the 7th course here
      numberOfItems = 6;
      defaultHeight = '278px';
    }

    const rawW = getItemSize(
      screenWidth,
      numberOfItems,
      itemMargin,
      listMargin
    );

    return {
      w: `${rawW}px`,
      h: getListHeight ? `${getListHeight(rawW)}px` : defaultHeight,
      numberOfItems,
      rawW,
      listMargin,
    };
  }, [
    screenWidth,
    isDesktopSize,
    isLargePhoneSize,
    isLargeTabletSize,
    isPhoneSize,
    isTabletSize,
    itemMargin,
    listMargin,
    getListHeight,
  ]);

  return {
    ...results,
    screenWidth,
  };
};
