import React from 'react';

import type {
  ISectionDataProps,
  ISentFormsItem,
  ISentResponseItem,
} from '@know/transformers';

import {
  CollapsibleSectionHeader,
  HStack,
  useSelectableSectionsView,
  Text,
  KNPressable,
  SentFormResponseItem,
  KNOWIcon,
} from '@know/ui';

import type { JSXElementConstructor, ReactElement } from 'react';

export const keyExtractor = function (item: ISentFormsItem): string {
  return item.id;
};

export const getSectionHeaderComponent = (
  info: {
    section: ISectionDataProps<ISentFormsItem>;
  },
  onPress?: (id: string) => void
): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return (
    <KNPressable flex={1} onPress={handleOnPress}>
      <CollapsibleSectionHeader
        id={info.section.id || info.section.type}
        title={info.section.title}
      />
    </KNPressable>
  );
  function handleOnPress() {
    onPress && onPress(info.section.id || info.section.type);
  }
};

export const ItemComponent = (info: {
  item: ISentResponseItem;
  section: ISectionDataProps<ISentResponseItem>;
}): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  const { onClickItem } = useSelectableSectionsView();

  return (
    <HStack alignItems={'center'} flex={1} justifyContent={'space-between'}>
      {info.item.type === 'hasMore' ? (
        <KNPressable
          onPress={handleOnPress}
          flex={1}
          _hover={{
            bg: 'gray.200',
          }}
        >
          <HStack
            alignItems={'center'}
            justifyContent={'flex-end'}
            pr={'20px'}
            py={'8px'}
          >
            <Text
              fontWeight={700}
              color={'purple.400'}
              fontFamily={'open'}
              mr={'6px'}
            >
              View All
            </Text>
            <KNOWIcon color={'purple.400'} size={'20px'} name="arrowright" />
          </HStack>
        </KNPressable>
      ) : (
        <SentFormResponseItem
          onPress={handleOnPress}
          item={info.item as any}
          type={info.section.type}
          section={info.section as any}
        />
      )}
    </HStack>
  );

  function handleOnPress() {
    onClickItem && onClickItem(info.item, info.section.type, info.section);
  }
};
