import {
  Text,
  HStack,
  KNOWIcon,
  KNPressable,
  Box,
  IconButton,
  ExpoFAIcon,
} from '.';
import type { IDraftItem } from '@know/transformers';

import React from 'react';
import { DateTime } from './DateTime';
import { useKNOWValueContextProvider } from '..';

export interface IFormDraftItemProps {
  type: string;
  item: IDraftItem;
  onPress?: (item: IDraftItem, type: string) => void;
  onDelete?: () => void;
}

export function FormDraftItem({
  onPress,
  item,
  type,
  onDelete,
}: IFormDraftItemProps) {
  function handleOnPress() {
    onPress && onPress(item, type);
  }

  const { isPhoneSize } = useKNOWValueContextProvider();
  return (
    <KNPressable
      onPress={handleOnPress}
      flex={1}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <HStack
        alignItems={'center'}
        justifyContent={'space-between'}
        pl={'24px'}
        pr={'20px'}
        py={'8px'}
      >
        <Box display={'flex'} flexDir={isPhoneSize ? 'column' : 'row'}>
          <HStack alignItems={'center'}>
            <KNOWIcon
              color={'gray.900'}
              mr={'6px'}
              name={'filetext1'}
              size="4"
              textAlign="center"
            />
            <Text
              fontFamily={'OpenSans'}
              fontWeight={600}
              display={'flex'}
              alignItems={'center'}
              color={'purple.400'}
            >
              [Draft]
            </Text>
          </HStack>
          <Text
            fontFamily={'open'}
            display={'flex'}
            alignItems={'center'}
            color={'gray.700'}
            ml={isPhoneSize ? undefined : '6px'}
            mt={isPhoneSize ? '2px' : undefined}
          >
            <DateTime time={item.createdAt as number} />
          </Text>
        </Box>
        {onDelete && (
          <IconButton
            icon={
              <ExpoFAIcon
                color={'gray.900'}
                name="trash-o"
                size={'20px'}
                textAlign={'center'}
              />
            }
            size={'xs'}
            ml={5}
            onPress={onDelete}
            _hover={{
              bg: 'red.100',
            }}
            _pressed={{
              bg: 'red.100',
            }}
            _focus={{
              bg: 'red.100',
            }}
            variant={'ghost'}
          />
        )}
      </HStack>
    </KNPressable>
  );
}
