import React from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  HStack,
  KNPressable,
  Text,
  VStack,
} from '..';

import type { IShareGroup } from '../ShareDialogue/types';
import { useGroup } from './useGroup';
import { useTranslation } from '@know/i18n';

export interface IGroupListItemListItemProps {
  group: IShareGroup;
  onToggle: (group: IShareGroup) => void;
  onPressViewMembers: (group: IShareGroup) => void;
}

export function GroupListItem({
  group,
  onToggle,
  onPressViewMembers,
}: IGroupListItemListItemProps) {
  const { id, name, avatar, avatarText, noOfUsers } = useGroup(group);
  const { t } = useTranslation('buttons');

  return (
    <HStack alignItems="center" space={2}>
      <KNPressable onPress={() => onToggle(group)}>
        <Avatar size="md" bg={'secondary.100'} source={{ uri: avatar }}>
          {avatarText}
        </Avatar>
      </KNPressable>
      <KNPressable flex={1} onPress={() => onToggle(group)}>
        <VStack color={'gray.900'} flex={1}>
          <Text
            fontFamily={'OpenSans'}
            color={'currentColor'}
            fontWeight={'semibold'}
            fontSize={'sm'}
          >
            {name}
          </Text>
          <HStack space={1} alignItems={'center'}>
            <Text
              fontFamily={'OpenSans'}
              color={'currentColor'}
              fontSize={'xs'}
            >
              {noOfUsers} {t('members')}
            </Text>
            <Text color={'gray.350'}>|</Text>
            <Button
              padding={'none'}
              variant={'link'}
              colorScheme={'purple.400'}
              onPress={(e) => {
                e.preventDefault();
                onPressViewMembers(group);
              }}
            >
              <Text
                fontSize={'xs'}
                color={'purple.400'}
                fontFamily={'OpenSans'}
                underline
              >
                {t('View Members')}
              </Text>
            </Button>
          </HStack>
        </VStack>
      </KNPressable>
      <Checkbox
        borderRadius={1}
        borderWidth={1}
        borderColor={'gray.700'}
        mr={4}
        boxSize={4}
        onChange={(_isSelected) => onToggle(group)}
        value={id}
        isChecked={group.isSelected}
      />
    </HStack>
  );
}
