import React, { useState } from 'react';
import { View, Text, ScrollView, Pressable, Box } from '@know/ui';
import { StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import type { ITextImagePDF } from './types';
import { PDFFileAttachment } from './PDFFileAttachment';
import { StyleInnerHTML } from './StyleInnerHTML';
import { ImageWithLoader } from './ImageWithLoader';

export const TextImagePDF: React.FC<ITextImagePDF> = ({
  textImagePdfObj,
  handleImageConsumed,
  handlePDFConsumed,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const imagesPayload = textImagePdfObj?.payload?.images;
  const pdfPayload = textImagePdfObj?.payload?.pdf;

  const styles = StyleSheet.create({
    // @ts-ignore -> For webview need to remove translateZ in transform
    scrollViewTransform: { transform: 'none' },
  });

  return (
    <ScrollView style={styles.scrollViewTransform} flex={1}>
      {imagesPayload?.length > 0 && (
        <View backgroundColor={'black'}>
          {imagesPayload?.length > 1 && (
            <Box
              position="absolute"
              zIndex={1}
              right={0}
              left={0}
              bottom={0}
              h={'80px'}
              bg={{
                linearGradient: {
                  colors: ['trasparent.100', 'trasparent.300'],
                  start: [0, 0],
                  end: [0, 1],
                },
              }}
            />
          )}
          {imagesPayload?.length > 1 && (
            <Box
              position="absolute"
              zIndex={1}
              left={0}
              top={0}
              bottom={0}
              w={'80px'}
              bg={{
                linearGradient: {
                  colors: ['trasparent.100', 'trasparent.300'],
                  start: [1, 0],
                  end: [0, 0],
                },
              }}
            />
          )}
          {imagesPayload?.length > 1 && (
            <Box
              position="absolute"
              zIndex={1}
              top={0}
              right={0}
              bottom={0}
              w={'80px'}
              bg={{
                linearGradient: {
                  colors: ['trasparent.100', 'trasparent.300'],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
            />
          )}
          <ImageWithLoader
            w="100%"
            source={{
              uri: imagesPayload[selectedImageIndex]?.url,
            }}
            h="25vh"
            resizeMode="contain"
            alt="text-image"
          />
          <View
            zIndex={2}
            style={StyleSheet.absoluteFill}
            position="absolute"
            justifyContent="center"
          >
            <View
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              {selectedImageIndex > 0 ? (
                <View marginLeft="10px">
                  <ArrowRoundedBtn
                    iconName="chevron-back"
                    onPress={() => {
                      setSelectedImageIndex(selectedImageIndex - 1);
                    }}
                  />
                </View>
              ) : (
                <View />
              )}

              {selectedImageIndex < imagesPayload.length - 1 ? (
                <View marginRight="10px">
                  <ArrowRoundedBtn
                    iconName="chevron-forward"
                    onPress={() => {
                      handleImageConsumed(selectedImageIndex + 1);
                      setSelectedImageIndex(selectedImageIndex + 1);
                    }}
                  />
                </View>
              ) : (
                <View />
              )}
            </View>
          </View>
          <View
            position="absolute"
            bottom="10px"
            flexDirection="row"
            w="100%"
            justifyContent="center"
            zIndex={2}
          >
            {imagesPayload?.length > 1 &&
              imagesPayload.map((_item, index) => {
                return (
                  <View
                    marginX="4px"
                    bgColor={
                      index === selectedImageIndex ? 'primary.500' : 'white'
                    }
                    w="8px"
                    h="8px"
                    key={index}
                    borderRadius={10}
                  />
                );
              })}
          </View>
        </View>
      )}
      <View margin="15px">
        {pdfPayload?.length > 0 && (
          <PDFFileAttachment
            pdfPayload={pdfPayload}
            handlePDFConsumed={handlePDFConsumed}
          />
        )}
        {!!textImagePdfObj?.title && (
          <Text
            marginTop="10px"
            fontWeight="semibold"
            fontSize={['18px', '18px', '18px', '20px', '20px']}
          >
            {textImagePdfObj?.title}
          </Text>
        )}

        <Text
          fontSize={['14px', '14px', '14px', '15px', '15px']}
          color="gray.700"
        >
          <StyleInnerHTML data={textImagePdfObj?.notes} />
        </Text>
      </View>
    </ScrollView>
  );
};

export const ArrowRoundedBtn = ({
  iconName,
  onPress,
}: {
  iconName: any;
  onPress: () => void;
}) => {
  return (
    <Pressable
      onPress={onPress}
      borderRadius="1000"
      bgColor="white"
      shadow="3"
      overflow="hidden"
    >
      <View padding="4px">
        <Ionicons name={iconName} size={20} color="gray.700" />
      </View>
    </Pressable>
  );
};
