import React from 'react';
import { useCourseFeed } from './useCourseFeed';

import { CourseItemCard, CourseFeedItemLoadingSkeleton } from '@know/ui';
import type { IDivInfo } from '../types';

interface ICourseFeedItemProps {
  courseId: string;
  childDivInfo: React.MutableRefObject<IDivInfo>;
  noOfColumn: number;
  isCompleted: boolean;
}

export const CourseFeedItem = ({
  courseId,
  childDivInfo,
  noOfColumn,
  isCompleted,
}: ICourseFeedItemProps) => {
  const { cardInfo, navigateToCourseDetails, isLoading } =
    useCourseFeed(courseId);
  if (isLoading) {
    return <CourseFeedItemLoadingSkeleton />;
  }
  return (
    <CourseItemCard
      noOfColumn={noOfColumn}
      childDivInfo={childDivInfo}
      onPress={navigateToCourseDetails}
      isCompleted={isCompleted}
      {...cardInfo}
    />
  );
};
