import React, { useRef } from 'react';
import {
  View,
  Text,
  Image,
  Progress,
  useBreakpointValue,
  Badge,
  Popover,
  Pressable,
} from '@know/ui';
import { Ionicons } from '@expo/vector-icons';
import { StyleSheet } from 'react-native';
import { LockLesson } from './LockLesson';
import { Tag } from '../Tag';

// const INIT_DIMENSIONS = {
//   width: 0,
//   height: 0,
// };
export const JourneyCourseList: React.FC<any> = ({
  course,
  completed,
  previousCompletedStatus,
  index,
  isLast,
  progress,
  showLockTooltip,
  unLockDate,
  isBeforeCurrentTime,
  isSmallDevice,
}) => {
  const id = course?.courseId;
  const image = course.miniThumbnail;
  const courseTitle = course.title;
  const totalLessons = course.totalLessons;
  const isLessonLock = course?.showLock ?? false;

  const imageSize = useBreakpointValue({
    'base': { height: '78px', width: '130px' },
    'sm': { height: '78px', width: '130px' },
    'md': { height: '108px', width: '180px' },
    'lg': { height: '108px', width: '180px' },
    'xl': { height: '108px', width: '180px' },
    '2xl': { height: '108px', width: '180px' },
  });
  const parentDivInfo = useRef(0);

  return (
    <View flexDir="row" flexWrap="wrap" alignItems="center" key={id}>
      <View alignItems="center" justifyItems="center" marginRight="12px">
        <View
          bgColor={
            index !== 0
              ? completed || previousCompletedStatus
                ? 'primary.500'
                : 'gray.400'
              : 'transparent'
          }
          w="1px"
          height={parentDivInfo.current - 2}
        />
        <View
          w="16px"
          h="16px"
          bgColor={completed ? 'primary.500' : 'gray.700'}
          borderRadius="full"
          borderWidth="1.5px"
          borderColor="white"
          shadow={1}
        />
        <View
          bgColor={
            isLast ? 'transparent' : completed ? 'primary.500' : 'gray.400'
          }
          alignSelf="center"
          w="1px"
          h={parentDivInfo.current}
        />
      </View>
      <View
        flex={1}
        borderBottomColor={isLast ? 'transparent' : 'gray.300'}
        borderBottomWidth="1px"
      >
        <View
          flexDir="row"
          flexWrap="wrap"
          flex={1}
          alignItems="center"
          paddingBottom="12px"
          onLayout={(event: any) => {
            let { height } = event.nativeEvent.layout;

            parentDivInfo.current = height / 2;
          }}
        >
          {isLessonLock && (
            <View
              style={StyleSheet.absoluteFill}
              bgColor="white"
              opacity={0.5}
              position="absolute"
              zIndex={2}
            />
          )}
          {isLessonLock && index === showLockTooltip?.tooltipIndex ? (
            <Popover
              placement={isSmallDevice ? 'top' : 'right'}
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    colorScheme="danger"
                    alignSelf="center"
                    {...triggerProps}
                  >
                    <View
                      borderColor="gray.350"
                      borderWidth="1"
                      borderRadius="10"
                      overflow="hidden"
                    >
                      <View>
                        <Image
                          style={imageSize}
                          resizeMode="cover"
                          source={{
                            uri: image,
                          }}
                          blurRadius={isLessonLock ? 1 : 0}
                          alt={courseTitle}
                        />
                        {isLessonLock && <LockLesson />}
                      </View>
                    </View>
                  </Pressable>
                );
              }}
              // isOpen={isLessonLock && index === showLockTooltip.tooltipIndex}
              isOpen={true}
              // onClose={() => setIsOpen(!isOpen)}
            >
              <Popover.Content
                backgroundColor="white"
                marginRight="15px"
                marginLeft={isSmallDevice ? '15px' : 0}
              >
                <Popover.Arrow />
                <Popover.Body backgroundColor="white">
                  <View maxWidth="315px" paddingTop="4px" paddingX="1px">
                    {!!showLockTooltip?.tooltipTitle && (
                      <Text
                        color="gray.900"
                        fontSize="16px"
                        fontWeight="semibold"
                      >
                        {showLockTooltip?.tooltipTitle}
                      </Text>
                    )}
                    {!!showLockTooltip?.tooltipDescription && (
                      <Text color="gray.700" fontSize="14px">
                        {showLockTooltip?.tooltipDescription}
                      </Text>
                    )}
                  </View>
                </Popover.Body>
              </Popover.Content>
            </Popover>
          ) : (
            <View
              borderColor="gray.350"
              borderWidth="1"
              borderRadius="10"
              overflow="hidden"
            >
              <View>
                <Image
                  style={imageSize}
                  resizeMode="cover"
                  source={{
                    uri: image,
                  }}
                  blurRadius={isLessonLock ? 1 : 0}
                  alt={courseTitle}
                />
                {isLessonLock && <LockLesson />}
              </View>
            </View>
          )}

          <View flex={1} alignItems="flex-start" px="10px" lineHeight="20px">
            <View flexDirection="column" marginBottom="5px">
              <View flexDirection="row" alignItems="center">
                <Text
                  marginRight="8px"
                  fontWeight="600"
                  fontSize="16px"
                  color="gray.900"
                >
                  {courseTitle}
                </Text>
                {completed && (
                  <Ionicons name="checkmark-circle" size={16} color="#00C1B2" />
                )}
              </View>
              {!!totalLessons && (
                <Text fontSize="14px" color="gray.400">
                  {totalLessons + ` Lesson${totalLessons > 1 ? 's' : ''}`}
                </Text>
              )}
            </View>
            {isLessonLock && isBeforeCurrentTime && (
              <Tag label={`Unlocks on ${unLockDate}`} variant="normal" />
            )}
            {completed ? (
              <Badge
                backgroundColor="rgba(0, 193, 178, 0.15)"
                rounded="full"
                colorScheme="primary.400"
                marginTop="6px"
                _text={{
                  fontSize: 14,
                  fontWeight: 600,
                  paddingRight: '12px',
                  paddingLeft: '12px',
                }}
              >
                Completed
              </Badge>
            ) : (
              <View
                flexDirection="row"
                alignItems="center"
                w="full"
                justifyContent="space-between"
                flex={1}
              >
                <View flexGrow={1}>
                  <Progress
                    width="100%"
                    size="1.5"
                    value={progress}
                    bg="secondary.25"
                    _filledTrack={{
                      bg: 'primary.500',
                    }}
                  />
                </View>

                <Text marginLeft="12px" color="black">
                  {progress}%
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
