import React from 'react';
import {
  PressableProps,
  Dimensions,
  Pressable,
  StyleSheet,
} from 'react-native';

import {
  Box,
  View,
  Image,
  useBreakpointValue,
  Progress,
  Text,
  VStack,
  Tag,
} from '@know/ui';

import { Skeleton, Stack, useMediaQuery } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { noop } from 'lodash';

export interface KNOWCardProps extends PressableProps {
  miniThumbnail: string;
  title: string;
  totalCount: number;
  progress: number | undefined;
  // subLevelType: string;
  noOfColumn: number;
  isCompleted?: boolean;
  childDivInfo?: React.MutableRefObject<{
    height: null | number;
    width: null | number;
  }>;
  type: string;
}

export const CourseFeedItemLoadingSkeleton = () => {
  const hFlatListPadding = useBreakpointValue({
    base: 6,
    sm: 6,
    md: 8,
    lg: 16,
    xl: 16,
  });

  const totalWidth = Dimensions.get('window').width;

  const _imageWidth = useBreakpointValue({
    base: totalWidth / 3,
    sm: totalWidth / 3,
    md: totalWidth / 4 - (hFlatListPadding * 2 + 4),
    lg: totalWidth / 4 - (hFlatListPadding * 2 + 8),
    xl: totalWidth / 4 - (hFlatListPadding * 2 + 8),
  });
  const [isLargeScreen] = useMediaQuery({ minWidth: 1440 });

  const imageWidth = isLargeScreen ? 310 : _imageWidth;

  const courseWidth = useBreakpointValue({
    base:
      totalWidth > hFlatListPadding * 2
        ? totalWidth - hFlatListPadding * 2
        : 300,
    sm:
      totalWidth > hFlatListPadding * 2
        ? totalWidth - hFlatListPadding * 2
        : 450,
    md: totalWidth / 4 - (hFlatListPadding * 2 + 4),
    lg: totalWidth / 4 - (hFlatListPadding * 2 + 8),
    xl: totalWidth / 4 - (hFlatListPadding * 2 + 8),
  });

  // const imageHeight = useBreakpointValue({
  //   'base': '75px',
  //   'sm': '90px',
  //   'md': '105px',
  //   'lg': '137px',
  //   'xl': '196px',
  //   '2xl': '196px',
  // });
  const imageHeight = Math.round((imageWidth * 3) / 5);

  return (
    <VStack
      w={isLargeScreen ? '310px' : courseWidth}
      borderWidth="1"
      space={8}
      overflow="hidden"
      rounded="md"
      margin={['12px', '12px', '8px', '16px', '16px']}
      _dark={{
        borderColor: 'coolGray.500',
      }}
      _light={{
        borderColor: 'coolGray.200',
      }}
    >
      <View
        flexDirection={['row', 'row', 'column', 'column', 'column']}
        justifyContent={[
          'space-between',
          'space-between',
          'start',
          'start',
          'start',
        ]}
      >
        <Skeleton w={`${imageWidth}px`} h={`${imageHeight}px`} />
        <Skeleton.Text alignSelf="center" marginY="5px" px="4" />
      </View>
    </VStack>
  );
};

export const CourseItemCard: React.FC<KNOWCardProps> = ({
  miniThumbnail,
  title,
  totalCount,
  progress,
  onPress,
  noOfColumn,
  type,
  isCompleted = false,
}) => {
  const hFlatListPadding = useBreakpointValue({
    base: 6,
    sm: 6,
    md: 8,
    lg: 16,
    xl: 16,
  });
  const [isLargeScreen] = useMediaQuery({ minWidth: 1440 });

  const totalWidth = Dimensions.get('window').width;

  const _imageWidth = Math.floor(
    useBreakpointValue({
      base: totalWidth / 3,
      sm: totalWidth / 3,
      md: totalWidth / noOfColumn - (hFlatListPadding * 2 + 4),
      lg: totalWidth / noOfColumn - (hFlatListPadding * 2 + 8),
      xl: totalWidth / noOfColumn - (hFlatListPadding * 2 + 8),
    })
  );

  const imageWidth = isLargeScreen ? 310 : _imageWidth;

  const courseWidth = useBreakpointValue({
    base:
      totalWidth > hFlatListPadding * 2
        ? totalWidth - hFlatListPadding * 2
        : 300,
    sm:
      totalWidth > hFlatListPadding * 2
        ? totalWidth - hFlatListPadding * 2
        : 450,
    md: totalWidth / noOfColumn - (hFlatListPadding * 2 + 4),
    lg: totalWidth / noOfColumn - (hFlatListPadding * 2 + 8),
    xl: totalWidth / noOfColumn - (hFlatListPadding * 2 + 8),
  });

  // const imageHeight = useBreakpointValue({
  //   'base': '75px',
  //   'sm': '90px',
  //   'md': '105px',
  //   'lg': '137px',
  //   'xl': '196px',
  //   '2xl': '196px',
  // });
  const imageHeight = Math.floor((imageWidth * 3) / 5);
  const [media] = useMediaQuery({ minWidth: 1024 });
  const [isMobileScreen] = useMediaQuery({ maxWidth: 480 });

  const rowTextWidth = courseWidth - (imageWidth + 8);

  return (
    <Box
      marginY="12px"
      marginX={['12px', '12px', '8px', '16px', '16px']}
      justifyContent="space-between"
      overflow="hidden"
      w={isLargeScreen ? '310px' : courseWidth}
    >
      <View
        flexDirection={['row', 'row', 'column', 'column', 'column']}
        justifyContent={[
          'space-between',
          'space-between',
          'start',
          'start',
          'start',
        ]}
        flex="1"
      >
        <Box
          rounded="lg"
          borderColor="secondary.500"
          borderWidth="1"
          overflow="hidden"
          height={`${imageHeight}px`}
          w={`${imageWidth}px`}
        >
          <Pressable onPress={onPress}>
            {({ hovered }: any) => {
              return (
                <View>
                  <Image
                    resizeMode="cover"
                    w={`${imageWidth}px`}
                    h={`${imageHeight}px`}
                    source={{
                      uri: miniThumbnail,
                    }}
                    blurRadius={hovered ? 1 : 0}
                    alt={`thumbnail for ${title}`}
                  />
                  {hovered && (
                    <View
                      style={StyleSheet.absoluteFill}
                      alignItems="center"
                      justifyItems="center"
                      justifyContent="center"
                    >
                      <View
                        style={StyleSheet.absoluteFill}
                        bgColor="white"
                        opacity={0.3}
                      />
                      <View zIndex={1}>
                        <View bgColor="white" borderRadius="6px">
                          <View
                            paddingX={['20px', '22px', '24px', '26px', '26px']}
                            paddingY={['12px', '14px', '14px', '16px', '16px']}
                          >
                            <Text
                              fontWeight="semibold"
                              color="primary.500"
                              fontSize={[
                                '12px',
                                '12px',
                                '12px',
                                '14px',
                                '14px',
                              ]}
                            >
                              {type === 'course'
                                ? 'View Course'
                                : 'View Learning Journey'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              );
            }}
          </Pressable>
        </Box>

        <View
          flex="1"
          marginLeft={noOfColumn === 1 ? '8px' : 0}
          w={[`${rowTextWidth}px`, `${rowTextWidth}px`, '100%', '100%', '100%']}
          justifyContent={[
            'center',
            'center',
            'space-between',
            'space-between',
            'space-between',
          ]}
        >
          <Stack marginTop={[0, 0, '10px', '10px', '10px']}>
            <Pressable onPress={isMobileScreen ? onPress : noop}>
              <View
                flexDirection="row"
                alignItems="baseline"
                justifyContent="space-between"
              >
                <Text
                  marginRight="8px"
                  fontWeight="600"
                  fontSize={['14px', '14px', '16px', '16px', '16px']}
                  lineHeight="19px"
                  color="gray.900"
                >
                  {title}
                </Text>
                {isCompleted && (
                  <Ionicons
                    name="checkmark-circle"
                    size={media ? 20 : 16}
                    color="#00C1B2"
                  />
                )}
              </View>
            </Pressable>
            <Text
              fontWeight="400"
              color="gray.700"
              fontSize={['14px', '14px', '16px', '16px', '16px']}
            >
              {totalCount} {type === 'course' ? 'Lessons' : 'Courses'}
            </Text>
          </Stack>
          <View>
            {/* if not completed show progress bar */}
            {!isCompleted && (
              <Box
                w="100%"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                marginTop="8px"
              >
                <Progress
                  flex={1}
                  size="1.5"
                  value={progress}
                  bg="secondary.25"
                  _filledTrack={{
                    bg: 'primary.500',
                  }}
                />
                <Text marginLeft="12px">{progress}%</Text>
              </Box>
            )}
            <View marginTop="8px">
              <Tag label={type} variant="normal" />
            </View>
          </View>
        </View>
      </View>
    </Box>
  );
};
