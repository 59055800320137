import { ConfigFormCategoriesType, atom } from '@know/ui';
import type {
  FormDraftsDataType,
  IFormFolderItem,
  IFormFeedItem,
  ISentFormsItem,
  IReceivedFormsItem,
} from '@know/transformers';

import { ExtendedUserDetailsInterface } from '@know/transformers/dist/Users/types';

export const formsCategoriesAtom = atom<ConfigFormCategoriesType>({
  key: 'formsCategoriesAtom',
  default: {},
});

export const userFormFeedItemsAtom = atom<Record<string, IFormFeedItem>>({
  key: 'userFormFeedItemsAtom',
  default: {},
});

export const userFormFeedFoldersAtom = atom<IFormFolderItem>({
  key: 'userFormFeedFoldersAtom',
  default: {},
});

export const newFormFilterTextAtom = atom<string | null>({
  key: 'newFOrmFilterTextAtom',
  default: null,
});

export const sectionsExpandStateAtom = atom<Record<string, boolean>>({
  key: 'sectionsExpandStateAtom',
  default: {
    folder: true,
    feed: true,
  },
});

export const isFormFeedLoadingAtom = atom<boolean>({
  key: 'isFormFeedLoadingAtom',
  default: true,
});

// Drafts
export const draftsListFilterTextAtom = atom<string>({
  key: 'draftsListFilterTextAtom',
  default: '',
});

export const formDraftsAtom = atom<FormDraftsDataType>({
  key: 'formDraftsAtom',
  default: {},
});

export const draftSectionsExpandStateAtom = atom<Record<string, boolean>>({
  key: 'draftSectionsExpandStateAtom',
  default: {},
});

// Sent Atoms
export const sentListFilterTextAtom = atom<string | null>({
  key: 'sentListFilterTextAtom',
  default: null,
});

export const sentSectionsExpandStateAtom = atom<Record<string, boolean>>({
  key: 'sentSectionsExpandStateAtom',
  default: {},
});

export const userSentFormsAtom = atom<Record<string, ISentFormsItem>>({
  key: 'userSentFormsAtom',
  default: {},
});

// Received Atoms
export const receivedListFilterTextAtom = atom<string | null>({
  key: 'receivedListFilterTextAtom',
  default: null,
});

export const receivedSectionsExpandStateAtom = atom<Record<string, boolean>>({
  key: 'receivedSectionsExpandStateAtom',
  default: {},
});

export const userReceivedFormsAtom = atom<Record<string, IReceivedFormsItem>>({
  key: 'userReceivedFormsAtom',
  default: {},
});

// Actions
export const groupMembersAtom = atom<{
  [groupId: string]: ExtendedUserDetailsInterface[];
}>({
  key: 'groupMembersAtom',
  default: {},
});

export const userDetailsAtom = atom<{
  [userId: string]: ExtendedUserDetailsInterface;
}>({
  key: 'userDetailsAtom',
  default: {},
});
