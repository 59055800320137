import {
  TaskFeedItem,
  TransformedIssueItem,
  prepareIssueFeed,
} from '@know/transformers';
import { selector, atom } from '@know/ui';

export const completedIssueFilterTextAtom = atom<string | null>({
  key: 'completedIssueFilterTextAtom',
  default: null,
});

export const completedIssueAtom = atom<Record<string, TaskFeedItem>>({
  key: 'completedIssueAtom',
  default: {},
});

export const completedIssuesItemsSelector = selector<TransformedIssueItem[]>({
  key: 'completedIssuesItemsSelector',
  get: ({ get }) => {
    const completedIssueFeed = get(completedIssueAtom);
    const filterText = get(completedIssueFilterTextAtom);

    return prepareIssueFeed(
      completedIssueFeed,
      filterText,
      {},
      'dateReported',
      'desc'
    );
  },
});
