import React from 'react';
import { Icon } from 'native-base';
import {
  Path,
  Circle,
  Rect,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';

export const UploadVideoIcon = (props: any) => (
  <Icon {...props} fill="none" viewBox="0 0 40 40">
    <Circle cx="20" cy="20" r="20" fill="#00C1B2" />
    <Circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.8" />
    <Rect x="15" y="15" width="11" height="10" fill="white" />
    <Path
      d="M10 15.5C10 14.3065 10.439 13.1619 11.2204 12.318C12.0018 11.4741 13.0616 11 14.1667 11H25.8333C26.9384 11 27.9982 11.4741 28.7796 12.318C29.561 13.1619 30 14.3065 30 15.5V24.5C30 25.6935 29.561 26.8381 28.7796 27.682C27.9982 28.5259 26.9384 29 25.8333 29H14.1667C13.0616 29 12.0018 28.5259 11.2204 27.682C10.439 26.8381 10 25.6935 10 24.5V15.5ZM17.5 16.076V23.924C17.5 24.374 17.9567 24.6494 18.3117 24.4154L23.7433 20.8298C23.8751 20.743 23.9839 20.6211 24.0594 20.4758C24.135 20.3305 24.1747 20.1666 24.1747 20C24.1747 19.8334 24.135 19.6695 24.0594 19.5242C23.9839 19.3789 23.8751 19.257 23.7433 19.1702L18.31 15.5864C18.2292 15.5334 18.1368 15.5046 18.0423 15.5029C17.9478 15.5012 17.8545 15.5266 17.7721 15.5766C17.6897 15.6266 17.6211 15.6994 17.5733 15.7875C17.5255 15.8755 17.5002 15.9757 17.5 16.0778V16.076Z"
      fill="url(#paint0_linear_41_652)"
    />
    <Path
      d="M10 15.5C10 14.3065 10.439 13.1619 11.2204 12.318C12.0018 11.4741 13.0616 11 14.1667 11H25.8333C26.9384 11 27.9982 11.4741 28.7796 12.318C29.561 13.1619 30 14.3065 30 15.5V24.5C30 25.6935 29.561 26.8381 28.7796 27.682C27.9982 28.5259 26.9384 29 25.8333 29H14.1667C13.0616 29 12.0018 28.5259 11.2204 27.682C10.439 26.8381 10 25.6935 10 24.5V15.5ZM17.5 16.076V23.924C17.5 24.374 17.9567 24.6494 18.3117 24.4154L23.7433 20.8298C23.8751 20.743 23.9839 20.6211 24.0594 20.4758C24.135 20.3305 24.1747 20.1666 24.1747 20C24.1747 19.8334 24.135 19.6695 24.0594 19.5242C23.9839 19.3789 23.8751 19.257 23.7433 19.1702L18.31 15.5864C18.2292 15.5334 18.1368 15.5046 18.0423 15.5029C17.9478 15.5012 17.8545 15.5266 17.7721 15.5766C17.6897 15.6266 17.6211 15.6994 17.5733 15.7875C17.5255 15.8755 17.5002 15.9757 17.5 16.0778V16.076Z"
      stroke="#00C1B2"
      fill={'none'}
    />
    <Path
      d="M10 15.5C10 14.3065 10.439 13.1619 11.2204 12.318C12.0018 11.4741 13.0616 11 14.1667 11H25.8333C26.9384 11 27.9982 11.4741 28.7796 12.318C29.561 13.1619 30 14.3065 30 15.5V24.5C30 25.6935 29.561 26.8381 28.7796 27.682C27.9982 28.5259 26.9384 29 25.8333 29H14.1667C13.0616 29 12.0018 28.5259 11.2204 27.682C10.439 26.8381 10 25.6935 10 24.5V15.5ZM17.5 16.076V23.924C17.5 24.374 17.9567 24.6494 18.3117 24.4154L23.7433 20.8298C23.8751 20.743 23.9839 20.6211 24.0594 20.4758C24.135 20.3305 24.1747 20.1666 24.1747 20C24.1747 19.8334 24.135 19.6695 24.0594 19.5242C23.9839 19.3789 23.8751 19.257 23.7433 19.1702L18.31 15.5864C18.2292 15.5334 18.1368 15.5046 18.0423 15.5029C17.9478 15.5012 17.8545 15.5266 17.7721 15.5766C17.6897 15.6266 17.6211 15.6994 17.5733 15.7875C17.5255 15.8755 17.5002 15.9757 17.5 16.0778V16.076Z"
      stroke="black"
      strokeOpacity="0.2"
      fill={'none'}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_41_652"
        x1="20"
        y1="11"
        x2="20"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#88F4EC" />
        <Stop offset="1" stopColor="#00C1B2" />
      </LinearGradient>
    </Defs>
  </Icon>
);
