import React from 'react';
import { Avatar, Checkbox, HStack, KNPressable, Text, VStack } from '..';

import type { IShareContact } from '../ShareDialogue/types';
import { useContact } from './useContact';

export interface IUserContactListItemProps {
  user: IShareContact;
  onToggle?: (user: IShareContact) => void;
}

export function UserContactListItem({
  user,
  onToggle,
}: IUserContactListItemProps) {
  const { id, name, avatar, avatarText, department, designationAndLocation } =
    useContact(user);

  return (
    <HStack alignItems="center" space={2}>
      <KNPressable onPress={() => onToggle?.(user)} disabled={!onToggle}>
        <Avatar size="md" bg={'primary.800'} source={{ uri: avatar }}>
          {avatarText}
        </Avatar>
      </KNPressable>
      <KNPressable
        onPress={() => onToggle?.(user)}
        flex={1}
        disabled={!onToggle}
      >
        <VStack color={'gray.900'}>
          <Text
            fontFamily={'OpenSans'}
            color={'currentColor'}
            fontWeight={'semibold'}
            fontSize={'sm'}
          >
            {name}
          </Text>
          <Text fontSize={'xs'} fontFamily={'OpenSans'} color={'currentColor'}>
            {designationAndLocation}
          </Text>
          <Text fontSize={'xs'} fontFamily={'OpenSans'} color={'currentColor'}>
            {department}
          </Text>
        </VStack>
      </KNPressable>
      {onToggle && (
        <Checkbox
          borderRadius={1}
          borderWidth={1}
          borderColor={'gray.700'}
          mr={4}
          boxSize={4}
          onChange={(_isSelected) => onToggle?.(user)}
          value={id}
          isChecked={user.isSelected}
        />
      )}
    </HStack>
  );
}
