import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import { KNOWDBContext } from './DBProvider';
import { get } from 'lodash';

import type { ComponentType } from 'react';
import type { OrgDetailsInterface, UserDetailsInterface } from './DBProvider';
import type { PathConfigMap } from '@react-navigation/native';

export interface IKNOWRouterContextInterface {
  servingFrom?: string;
  // isAuthenticated?: () => boolean;
  hasAccessToModule?: (module: string, role: string) => boolean;
}

export type IModuleProps = {
  id: string;
  name: string;
  priority: number;
  component: ComponentType<any>;
  children?: Array<IModuleProps>; // This is to render content inside RouterProvider context not related to modules.
  role?: 'read' | 'write' | 'delete' | 'admin' | 'share' | 'unshare';
  isPrivate?: boolean;
  hasAccess?: boolean;
  icon: string;
  headerShown?: boolean;
  linking?: PathConfigMap<ReactNavigation.RootParamList>;
};

interface AppContextInterface {
  registerScreen: (module: IModuleProps) => void;
  hasAccessToModule: (module: string) => boolean;
  userScreens: Array<IModuleProps>;
  setHideDownload: React.Dispatch<React.SetStateAction<boolean>>;
  hideDownload: boolean;
  servingFrom?: string;
}

export const KNOWRouterContenxt = createContext<AppContextInterface>({
  registerScreen: () => false,
  userScreens: [],
  setHideDownload: () => {},
  hideDownload: false,
  servingFrom: '',
  hasAccessToModule: () => false,
});

export const useRouterContext = () => useContext(KNOWRouterContenxt);

const KNOWRouterContenxtProvider = KNOWRouterContenxt.Provider;

const hasAccessToModuleForEachDetails = (
  details: object | null,
  module: string
): boolean => {
  const appBottomTab: Array<string> = get(
    details,
    'preferences.appBottomTab',
    []
  );

  const moduleIndex = appBottomTab.indexOf(module);

  if (moduleIndex !== -1) return true;

  const moreTabConfig: { [key: string]: Array<string> } = get(
    details,
    'preferences.moreTabConfig',
    []
  );

  for (const moreTabKey in moreTabConfig) {
    const moreTabs = moreTabConfig[moreTabKey];

    const moreTabModuleIndex = moreTabs.indexOf(module);

    if (moreTabModuleIndex !== -1) return true;
  }

  return false;
};

const checkUserHasAccessToModule = (
  userDetails: UserDetailsInterface | null,
  orgDetails: OrgDetailsInterface | null,
  module: string
): boolean => {
  return get(userDetails, 'preferences.appBottomTab')
    ? hasAccessToModuleForEachDetails(userDetails, module)
    : hasAccessToModuleForEachDetails(orgDetails, module);
};

export const KNOWRouteProvider: React.FC<IKNOWRouterContextInterface> = ({
  servingFrom,
  children,
  // isAuthenticated = noop,
  // hasAccessToModule = noop,
}) => {
  const { currentUser, userDetails, orgDetails } = useContext(KNOWDBContext);

  const isAuthenticated = useMemo(() => !!currentUser, [currentUser]);

  const [screens, setScreens] = useState<Array<IModuleProps>>([]);
  const [hideDownload, setHideDownload] = useState<boolean>(false);

  const userScreens = useMemo<Array<IModuleProps>>(() => {
    return screens
      .filter(
        ({ id, isPrivate }) =>
          !isPrivate || checkUserHasAccessToModule(userDetails, orgDetails, id)
      )
      .sort((a, b) => a.priority - b.priority);
  }, [screens, orgDetails, userDetails]);

  const registerScreen = useCallback(
    (module: IModuleProps) => {
      const { id, isPrivate = true } = module;
      if (isPrivate === false || isAuthenticated) {
        setScreens((existingScreen) => {
          return [
            ...existingScreen.filter((screen) => screen.id !== id),
            { ...module, hasAccess: true, isPrivate },
          ];
        });
      }
    },
    [isAuthenticated]
  );

  const hasAccessToModule = useCallback(
    (module: string) => {
      return checkUserHasAccessToModule(userDetails, orgDetails, module);
    },
    [orgDetails, userDetails]
  );

  return (
    <KNOWRouterContenxtProvider
      value={{
        registerScreen,
        userScreens,
        hideDownload,
        setHideDownload,
        servingFrom,
        hasAccessToModule,
      }}
    >
      {children}
    </KNOWRouterContenxtProvider>
  );
};
