import React from 'react';
import { Text } from '@know/ui';
import { pluralize } from './utils';

interface PluralProps {
  // style?: StyleSheet;
  singular: string;
  plural?: string;
  count: number;
  showCount?: boolean;
  zero?: string | undefined;
}

export function Pluralize(props: PluralProps) {
  return <Text>{pluralize(props)}</Text>;
}

Pluralize.defaultProps = {
  count: 1,
  showCount: true,
  // style: {},
  zero: undefined,
};
