import { atom } from '@know/ui';

export const taskLastOpenTabAtom = atom<string>({
  key: 'taskLastOpenTabAtom',
  default: 'task-feed',
});

export const taskRoutesAtom = atom<string[]>({
  key: 'taskRoutesAtom',
  default: [],
});
