import React from 'react';
import { createIcon } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const LocationLeaderIcon = createIcon({
  viewBox: '0 0 20 20',
  path: [
    <G clipPath="url(#clip0_573_8353)">
      <Path
        d="M14.375 10C14.8722 10 15.3492 10.1976 15.7008 10.5492C16.0524 10.9008 16.25 11.3777 16.25 11.875V12.5C16.25 14.3875 14.885 16.3188 12.48 17.1175C12.5246 16.8063 12.4884 16.4888 12.3747 16.1957C12.2611 15.9026 12.0739 15.6436 11.8312 15.4438L10.6237 14.4213V12.5C10.6237 11.5888 10.38 10.735 9.95496 10H14.375ZM7.95371 8.07502C8.13746 8.17252 8.31621 8.28002 8.48496 8.40002C9.27836 8.79144 10.1921 8.8619 11.0362 8.59673C11.8802 8.33157 12.5895 7.75119 13.0165 6.97636C13.4435 6.20152 13.5553 5.29189 13.3286 4.43671C13.102 3.58153 12.5544 2.84665 11.7997 2.38497C11.045 1.92328 10.1414 1.77034 9.27684 1.95796C8.41227 2.14558 7.65332 2.65932 7.15789 3.39229C6.66246 4.12526 6.46871 5.02102 6.61693 5.89321C6.76516 6.76541 7.24396 7.54687 7.95371 8.07502ZM1.87121 12.5C1.87121 11.5055 2.2663 10.5516 2.96956 9.84837C3.67282 9.14511 4.62665 8.75002 5.62121 8.75002C6.61577 8.75002 7.5696 9.14511 8.27286 9.84837C8.97612 10.5516 9.37121 11.5055 9.37121 12.5V15L11.0212 16.3988C11.1186 16.4816 11.1883 16.5922 11.2209 16.7159C11.2534 16.8395 11.2473 16.9701 11.2033 17.0902C11.1593 17.2102 11.0795 17.3138 10.9748 17.3871C10.87 17.4604 10.7453 17.4998 10.6175 17.5H0.626213C0.498369 17.4998 0.373661 17.4604 0.268909 17.3871C0.164157 17.3138 0.0843994 17.2102 0.0403925 17.0902C-0.00361447 16.9701 -0.0097537 16.8395 0.0228027 16.7159C0.0553592 16.5922 0.125045 16.4816 0.222463 16.3988L1.87246 15V12.5H1.87121ZM5.62121 20C5.23356 19.9998 4.8555 19.8795 4.539 19.6556C4.2225 19.4318 3.9831 19.1154 3.85371 18.75H7.38996C7.26066 19.1157 7.02111 19.4324 6.70433 19.6562C6.38754 19.8801 6.00912 20.0002 5.62121 20Z"
        fill="currentColor"
      />
    </G>,
    <Defs>
      <ClipPath id="clip0_573_8353">
        <Rect width="20" height="20" fill="white" />
      </ClipPath>
    </Defs>,
  ],
});
