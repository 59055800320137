import {
  TaskFeedItem,
  TransformedIssueItem,
  prepareIssueFeed,
} from '@know/transformers';
import { selector, atom } from '@know/ui';

export const sentIssueFilterTextAtom = atom<string | null>({
  key: 'sentIssueFilterTextAtom',
  default: null,
});

export const sentIssueAtom = atom<Record<string, TaskFeedItem>>({
  key: 'sentIssueAtom',
  default: {},
});

export const sentIssuesItemsSelector = selector<TransformedIssueItem[]>({
  key: 'sentIssuesItemsSelector',
  get: ({ get }) => {
    const sentIssueFeed = get(sentIssueAtom);
    const filterText = get(sentIssueFilterTextAtom);

    return prepareIssueFeed(
      sentIssueFeed,
      filterText,
      {},
      'dateReported',
      'desc'
    );
  },
});
