//TODO: make a native app friendly version
import React, { useState } from 'react';
import {
  KNOWButton as Button,
  Modal,
  TextArea,
  Text,
  View,
  Radio,
  VStack,
  CircleIcon,
} from '@know/ui';

export interface ReturnFormSectionInfo {
  sectionId: string;
  name: string;
  senderName: string;
}
interface ReturnFormProps {
  isOpen: boolean;
  cancel?: () => void;
  submit?: (message: string, sectionId: string) => void | Promise<void>;
  isLoading?: boolean;
  sections?: ReturnFormSectionInfo[];
}

export const ReturnFormModal = ({
  isOpen,
  cancel,
  submit,
  isLoading,
  sections = [],
}: ReturnFormProps) => {
  const [returnMessage, setReturnMessage] = useState<string>('');
  const [selectedSectionId, setSelectedSectionId] = useState<
    string | undefined
  >(sections.length === 1 ? sections[0].sectionId : undefined);

  const onSubmit = () => {
    if (selectedSectionId) {
      submit && submit(returnMessage, selectedSectionId);
    }
  };
  return (
    <Modal isOpen={isOpen} size="xl">
      <Modal.Content>
        <Modal.Header borderBottomWidth={0} paddingBottom={'4px'}>
          <Text fontSize={'18px'} fontWeight={700}>
            Return form and discard changes?
          </Text>
        </Modal.Header>
        <Modal.Body py={0}>
          <View width="100%" display={'flex'} flexDir={'column'}>
            <Text fontSize={'16px'} color={'gray.700'} lineHeight={'22px'}>
              This form will be returned back. Any changes you made will not be
              saved.
            </Text>
            {sections.length > 1 ? (
              <Radio.Group
                name="returnSectionId"
                accessibilityLabel="select section to return to"
                defaultValue={selectedSectionId}
                onChange={(value) => {
                  setSelectedSectionId(value || '');
                }}
                my={'12px'}
              >
                {sections.map(({ sectionId, name, senderName }, index) => (
                  <Radio
                    value={sectionId}
                    isFocusVisible={false}
                    _stack={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                    size={'16px'}
                    mt={'3px'}
                    p={'2px'}
                    icon={<CircleIcon size="16px" color="primary.500" />}
                  >
                    <VStack
                      mb={index === sections.length - 1 ? undefined : '12px'}
                    >
                      <Text
                        fontFamily={'open'}
                        fontSize={'14px'}
                        fontWeight={600}
                      >
                        {name}
                      </Text>
                      <Text
                        fontFamily={'open'}
                        fontSize={'14px'}
                        color={'gray.700'}
                      >
                        {senderName}
                      </Text>
                    </VStack>
                  </Radio>
                ))}
              </Radio.Group>
            ) : null}
            <TextArea
              h={20}
              placeholder="Message for sender"
              onChangeText={setReturnMessage}
              w={'100%'}
              autoCompleteType={false}
              value={returnMessage}
              _focus={{
                bg: 'white',
                borderBottomWidth: '1px',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderColor: 'primary.500',
              }}
              mt={sections.length > 1 ? undefined : '10px'}
              size={'14px'}
            />
          </View>
        </Modal.Body>
        <Modal.Footer
          borderTopWidth={0}
          display={'flex'}
          flexDirection={'column'}
        >
          <Button
            colorScheme="primary"
            onPress={onSubmit}
            w={'100%'}
            borderWidth={'0px'}
            mb={'10px'}
            isDisabled={!returnMessage || !selectedSectionId}
            isLoading={isLoading}
          >
            Return Form
          </Button>
          <Button
            colorScheme="secondary"
            onPress={cancel}
            w={'100%'}
            borderWidth={0}
            _text={{
              color: 'gray.900',
              fontWeight: 600,
            }}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
