import {
  LibraryFeedItem,
  LibrarySection,
  LibrarySectionItem,
} from '@know/transformers';
import { atom } from '@know/ui';

export const courseLibrarySectionsAtom = atom<{
  [key: string]: LibrarySection;
}>({
  key: 'users.courses.library.sections',
  default: {},
});

export const courseLibrarySectionsItemsAtom = atom<{
  [key: string]: { [key: string]: LibrarySectionItem };
}>({
  key: 'users.courses.library.sectionItems',
  default: {},
});

export const libraryFeedItemsAtom = atom<{
  [key: string]: LibraryFeedItem;
}>({
  key: 'library.nuggets',
  default: {},
});

export const libraryFilterTextAtom = atom({
  key: 'library.filtertext',
  default: '',
});
