import React from 'react';
import {
  Modal,
  Heading,
  VStack,
  Text,
  Button,
  Center,
  ButtonProps,
} from '@know/ui';

export interface ConfirmModalProps {
  title?: string;
  description?: string;
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void>;
  confirmButtonVariant?: ButtonProps['colorScheme'];
  confirmText?: string;
  isOpen: boolean;
}

export const ConfirmModal = ({
  title,
  description,
  onCancel,
  onConfirm,
  confirmButtonVariant = 'danger',
  isOpen,
  confirmText = 'OK',
}: ConfirmModalProps) => {
  return (
    <Modal isOpen={isOpen} closeOnOverlayClick onClose={onCancel}>
      {title && description && onCancel && onConfirm ? (
        <Modal.Content>
          <Modal.Body p="20px">
            <Center>
              <VStack space={3} alignItems="center">
                <Heading fontWeight="500" color="gray.700" textAlign="center">
                  {title}
                </Heading>
                <Text textAlign="center">{description}</Text>
              </VStack>
            </Center>
          </Modal.Body>
          <Modal.Footer justifyContent="space-between">
            <Button
              colorScheme="secondary"
              onPress={onCancel}
              px="20px"
              variant="ghost"
              _text={{ fontWeight: 600, fontSize: 'sm', color: 'gray.900' }}
            >
              Cancel
            </Button>
            <Button
              colorScheme={confirmButtonVariant}
              onPress={onConfirm}
              px="20px"
            >
              {confirmText}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      ) : null}
    </Modal>
  );
};
